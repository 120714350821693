import { Button, Grid, Typography } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import React from 'react';
import CommonButton from '../../../components/atom/CommonButton';

export default function SurveyFooterNew({ onPrev, onNext, showSave }) {
    return (
        <Grid item xs={12}>
            <Grid container item xs={12} justifyContent="space-between" sx={{ p: 2 }}>
                {!onPrev && (
                    <Grid item xs={6} sx={{ mt: 1 }}>
                        <Typography variant="body2">
                            When you are done, click on &quot;Next&quot;.
                        </Typography>
                    </Grid>
                )}
                {onPrev && (
                    <Grid item xs={6}>
                        <Button variant="text" onClick={onPrev}>
                            <KeyboardArrowLeft />
                            Previous
                        </Button>
                    </Grid>
                )}
                {showSave && (
                    <Grid item>
                        <CommonButton
                            fullWidth
                            type="submit"
                            label="Save survey"
                            buttonType="primary"
                        />
                    </Grid>
                )}
                {onNext && (
                    <Grid item>
                        <Button fullWidth variant="text" color="primary" onClick={onNext}>
                            Next
                            <KeyboardArrowRight />
                        </Button>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
}
