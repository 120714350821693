import { Grid, TextField } from '@mui/material';
import React, { useEffect } from 'react';
import { useImpSurveyStep, withFormikContext } from './ImpSurveyStep';
import SurveyQuestionHeadline from '../../survey/components/SurveyQuestionHeadline';
import ImpSurveySection from './ImpSurveySection';
import SurveyQuestionSubheader from './SurveyQuestionSubheader';

function QGenericQuestion({ values, setFieldValue, sectionIndex, formikKey, label, q, subheader }) {
    const { step, addFieldIntoStep } = useImpSurveyStep();
    useEffect(() => {
        addFieldIntoStep(`answers.${formikKey}`, sectionIndex + 1);
    }, [addFieldIntoStep, formikKey, sectionIndex]);

    if (step !== sectionIndex + 1) {
        return null;
    }

    const currentValue = typeof values.answers === 'object' ? values.answers[formikKey] : '';
    return (
        <ImpSurveySection>
            <Grid item xs={12}>
                <SurveyQuestionHeadline>{q}</SurveyQuestionHeadline>
                {subheader && <SurveyQuestionSubheader subheader={subheader} />}
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    value={currentValue}
                    label={label}
                    autoComplete="off"
                    onChange={(e) => {
                        setFieldValue(`answers.${formikKey}`, e.target.value);
                    }}
                    multiline
                />
            </Grid>
        </ImpSurveySection>
    );
}

export default withFormikContext(QGenericQuestion);
