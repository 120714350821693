import { Grid, Step, StepLabel, Stepper } from '@mui/material';
import React from 'react';
import { useFormikContext } from 'formik';
import { useSurveyStep } from './SurveyStep';

function SurveyStepper({ SURVEY_STEPS, step, setStep }) {
    const { errors } = useFormikContext();
    const { fieldInStep } = useSurveyStep();

    const errorPerStep = {};
    Object.entries(fieldInStep).forEach(([stepNumber, fields]) => {
        errorPerStep[stepNumber] = fields.filter((field) => {
            return typeof errors[field] !== 'undefined';
        }).length;
    });

    // extra care for first step, since errorPerStep[1] is undefined if we step over it
    if (step > 1) {
        errorPerStep[1] = 0;
    }

    return (
        <Grid item xs={12} md={3}>
            <Stepper activeStep={step - 1} orientation="vertical">
                {SURVEY_STEPS.map((label, index) => (
                    <Step
                        key={label}
                        completed={errorPerStep[index + 1] === 0 && step > index + 1}
                        active={step === index + 1}
                        last={index === SURVEY_STEPS.length - 1}
                        onClick={() => {
                            setStep(index + 1);
                        }}
                    >
                        <StepLabel
                            optional={
                                errorPerStep[index + 1] > 0 &&
                                step >= index + 1 && (
                                    <span style={{ color: 'red' }}>
                                        {errorPerStep[index + 1]} question
                                        {errorPerStep[index + 1] > 1 ? 's' : ''} remaining
                                    </span>
                                )
                            }
                        >
                            {label}
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Grid>
    );
}
export default SurveyStepper;
