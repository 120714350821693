import React from 'react';
import { format } from 'date-fns';
import {
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip,
} from '@mui/material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import SkeletonTable from '../../../components/atom/SkeletonTable';
import { TABLE_ROW_COUNT_OPTIONS } from '../../../helpers/constans';
import SortableTableCell from '../../../components/atom/SortableTableCell';
import { MODAL_TYPE, useModals } from '../../../hooks/useModal';
import { handleChangeRowsPerPage, handlePageChange } from '../../../helpers/handleTableFunctions';

const EmailLogsTable = ({
    rows,
    limit,
    page,
    setParams,
    total,
    loading,
    sort,
    sortDir,
    setSorting,
    reloadData,
}) => {
    const { showModal } = useModals();

    return (
        <Grid item xs={12}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>User name</TableCell>
                        <SortableTableCell
                            arrow={sort === 'to' ? sortDir : ''}
                            onClick={() => setSorting('to')}
                        >
                            To
                        </SortableTableCell>
                        <SortableTableCell
                            arrow={sort === 'subject' ? sortDir : ''}
                            onClick={() => setSorting('subject')}
                        >
                            Subject
                        </SortableTableCell>
                        <SortableTableCell
                            arrow={sort === 'result' ? sortDir : ''}
                            onClick={() => setSorting('result')}
                        >
                            Result
                        </SortableTableCell>
                        <TableCell align="center">Content</TableCell>
                        <SortableTableCell
                            arrow={sort === 'created_at' ? sortDir : ''}
                            onClick={() => setSorting('created_at')}
                        >
                            Created
                        </SortableTableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading ? (
                        <SkeletonTable rows={limit} cells={6} />
                    ) : (
                        rows?.map((row) => (
                            <React.Fragment key={row.id}>
                                <TableRow>
                                    <TableCell>{row.user_name || '-'}</TableCell>
                                    <TableCell>{row.to}</TableCell>
                                    <TableCell>{row.subject}</TableCell>
                                    <TableCell>{row.result || '-'}</TableCell>
                                    <TableCell align="center">
                                        <Tooltip title="View email">
                                            <IconButton
                                                onClick={() => {
                                                    showModal(MODAL_TYPE.EMAIL_LOGS, {
                                                        title: 'Mail',
                                                        content: row,
                                                        reloadData: () => reloadData(),
                                                    });
                                                }}
                                            >
                                                <RemoveRedEyeIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell>
                                        {format(new Date(row.created_at), 'MM/dd/yyyy HH:mm:ss a')}
                                    </TableCell>
                                </TableRow>
                            </React.Fragment>
                        ))
                    )}
                </TableBody>
            </Table>
            <TablePagination
                count={total || 0}
                rowsPerPage={limit}
                component="div"
                page={page}
                onPageChange={(_, newPage) => handlePageChange(newPage, setParams, limit)}
                rowsPerPageOptions={TABLE_ROW_COUNT_OPTIONS}
                onRowsPerPageChange={(event) => handleChangeRowsPerPage(event, setParams)}
            />
        </Grid>
    );
};

export default EmailLogsTable;
