import { Autocomplete, FormControl, Grid, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CommonButton from '../../../components/atom/CommonButton';

// Function to calculate distance between two airports
const calculateDistance = (airportFrom, airportTo, airportData, setDistance) => {
    const entry = airportData.find(
        (e) => e.ORIGIN === airportFrom.ORIGIN && e.DEST === airportTo.DEST
    );

    if (entry) {
        setDistance(entry['DISTANCE IN MILES']);
    } else {
        setDistance(null);
    }
};

// error message in case of input
const errorMessage = (airportFrom, airportTo, distance) => {
    if (!airportFrom && !airportTo) {
        return 'Select both cities / airports';
    }
    if (!airportFrom) {
        return 'Select origin';
    }
    if (!airportTo) {
        return 'Select destination';
    }
    if (!distance) {
        return 'Distance not found';
    }
    return '';
};

const AirportDistanceCalculator = ({ setAmount, setFieldValue, notes = null, setShowNotes }) => {
    const [airportFrom, setAirportFrom] = useState('');
    const [airportTo, setAirportTo] = useState('');
    const [distance, setDistance] = useState(null);
    const [airportData, setAirportData] = useState([]);
    const [originOptions, setOriginOptions] = useState([{}]);
    const [destOptions, setDestOptions] = useState([{}]);

    // runs once when the component is mounted
    useEffect(() => {
        fetch('/assets/airport_distance.json')
            .then((res) => res.json())
            .then((airdata) => {
                setAirportData(airdata);
                const uniqueOriginList = [];
                const uniqDestList = [];
                const originList = airdata.map((entry) => {
                    return { ORIGIN: entry.ORIGIN, ORIGIN_CITY: entry.ORIGIN_CITY };
                });
                const destList = airdata.map((entry) => {
                    return { DEST: entry.DEST, DEST_CITY: entry.DEST_CITY };
                });
                originList.forEach((element) => {
                    if (!uniqueOriginList.some((e) => e.ORIGIN === element.ORIGIN)) {
                        uniqueOriginList.push(element);
                    }
                });
                destList.forEach((element) => {
                    if (!uniqDestList.some((e) => e.DEST === element.DEST)) {
                        uniqDestList.push(element);
                    }
                });
                setOriginOptions(uniqueOriginList);
                setDestOptions(uniqDestList);
            });
    }, []);

    // calculate distance if both airports are selected
    useEffect(() => {
        if (airportFrom && airportTo) {
            calculateDistance(airportFrom, airportTo, airportData, setDistance);
        }
    }, [airportFrom, airportTo, airportData]);

    return (
        <Grid container item xs={12}>
            <Grid item xs={3}>
                <FormControl fullWidth>
                    <Autocomplete
                        options={originOptions}
                        fullWidth
                        getOptionLabel={(option) => {
                            const label = option.ORIGIN_CITY
                                ? `${option.ORIGIN} - (${option.ORIGIN_CITY})`
                                : option.ORIGIN;

                            return option.ORIGIN ? label : '';
                        }}
                        filterOptions={(options, state) => {
                            const searchTerm = state.inputValue?.toLowerCase();
                            return options.filter(
                                (option) =>
                                    option.ORIGIN?.toLowerCase().includes(searchTerm) ||
                                    option.ORIGIN_CITY?.toLowerCase().includes(searchTerm)
                            );
                        }}
                        renderInput={(params) => <TextField {...params} label="Origin Airport" />}
                        onChange={(_, newValue) => {
                            setAirportFrom(newValue);
                            setDistance(null);
                        }}
                        value={airportFrom}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={3}>
                <FormControl fullWidth>
                    <Autocomplete
                        options={destOptions}
                        fullWidth
                        getOptionLabel={(option) => {
                            const label = option.DEST_CITY
                                ? `${option.DEST} - (${option.DEST_CITY})`
                                : option.DEST;

                            return option.DEST ? label : '';
                        }}
                        filterOptions={(options, state) => {
                            const searchTerm = state.inputValue?.toLowerCase();
                            return options.filter(
                                (option) =>
                                    option.DEST?.toLowerCase().includes(searchTerm) ||
                                    option.DEST_CITY?.toLowerCase().includes(searchTerm)
                            );
                        }}
                        renderInput={(params) => (
                            <TextField {...params} label="Destination Airport" />
                        )}
                        onChange={(_, newValue) => {
                            setAirportTo(newValue);
                            setDistance(null);
                        }}
                        value={airportTo}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={3} sx={{ alignSelf: 'center', textAlign: 'center' }}>
                <b>Distance:</b>{' '}
                {distance ? `${distance} miles` : errorMessage(airportFrom, airportTo, distance)}
            </Grid>
            <Grid item xs={3} sx={{ alignSelf: 'center', textAlign: 'center' }}>
                <CommonButton
                    fullWidth
                    label="Set Distance as Usage"
                    buttonType="outlined"
                    disabled={!distance}
                    onClick={() => {
                        setAmount(distance);
                        setShowNotes(true);
                        setFieldValue(
                            'notes',
                            `${
                                notes
                                    ? `${notes} - Distance between ${airportFrom.ORIGIN} and ${airportTo.DEST} is ${distance} miles.`
                                    : `Distance between ${airportFrom.ORIGIN} and ${airportTo.DEST} is ${distance} miles.`
                            }`
                        );
                    }}
                />
            </Grid>
        </Grid>
    );
};

export default AirportDistanceCalculator;
