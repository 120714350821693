import React from 'react';
import { Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { formatNumber } from '../../../helpers/convertUnits';
import ComponentTitle from '../../../components/ComponentTitle';

function SurveyProductTable({ survey }) {
    // did not provide product data, just return null
    if (survey?.details?.hasPerProductEmissions !== true) {
        return null;
    }

    // iterate on each product
    return (
        <>
            <ComponentTitle variant="h5" title="Product based breakdown of CO2e (mt)" />
            <Grid item xs={12}>
                <Typography variant="body1">
                    The company did provide product based breakdown of CO<sub>2</sub>e (mt). The
                    system does not validate that the sum of the products is equal to the total CO
                    <sub>2</sub>e (mt).
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell align="right">
                                Total CO<sub>2</sub>e (mt)
                            </TableCell>
                            <TableCell align="right">Units</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(survey?.details?.perProductEmissions || []).map((product, idx) => (
                            <TableRow key={idx}>
                                <TableCell>{product?.name}</TableCell>
                                <TableCell align="right">{formatNumber(product?.total)}</TableCell>
                                <TableCell align="right">{product?.units}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Grid>
        </>
    );
}

export default SurveyProductTable;
