import {
    Checkbox,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
} from '@mui/material';
import React from 'react';
import SurveyQuestionHeadline from '../components/SurveyQuestionHeadline';
import {
    withFormikContext,
    withStepAndValidation,
    withSurveySection,
} from '../components/SurveyStep';

function QNetzeroTargetScopes({ values, setFieldValue, year }) {
    const defaultYear = year + 1; // survey year for now, instead of new Date().getUTCFullYear() + 1;

    const setValueToScope = (scope, yearOnTarget) => {
        setFieldValue(
            'netzeroTargets',
            values.netzeroTargets.map((line) => {
                if (line.scope !== scope) {
                    return line;
                }
                return {
                    scope: line.scope,
                    year: yearOnTarget,
                };
            })
        );
    };

    return (
        <>
            <Grid item xs={12}>
                <SurveyQuestionHeadline>
                    Please select the scopes for which you have a net zero target.
                </SurveyQuestionHeadline>
            </Grid>
            <Grid item xs={12}>
                <Table size="small" style={{ width: '100%' }}>
                    <TableHead>
                        <TableRow>
                            <TableCell width="10%">&nbsp;</TableCell>
                            <TableCell width="45%">Scope</TableCell>
                            <TableCell width="45%">Target year</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {values.netzeroTargets.map(({ scope, year: yearOnTarget }) => {
                            return (
                                <TableRow key={scope}>
                                    <TableCell>
                                        <Checkbox
                                            checked={yearOnTarget !== ''}
                                            onChange={(ev) => {
                                                const newYear = ev.target.checked
                                                    ? defaultYear
                                                    : '';
                                                // turning on a year will get the default year
                                                // turning off a year will set year to ''
                                                setValueToScope(scope, newYear);
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        {yearOnTarget !== '' ? scope : <s>{scope}</s>}
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            fullWidth
                                            type="number"
                                            autoComplete="off"
                                            value={yearOnTarget}
                                            required
                                            InputProps={{
                                                inputProps: { min: defaultYear },
                                            }}
                                            onChange={(ev) => {
                                                setValueToScope(scope, ev.target.value);
                                            }}
                                        />
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </Grid>
        </>
    );
}

export default withStepAndValidation(
    withSurveySection(withFormikContext(QNetzeroTargetScopes)),
    // step
    2,
    // fields in this question
    ['netzeroTargets'],
    // validation
    (values, errors) => {
        if (values.netzeroTargets.filter((e) => e.year !== '').length === 0) {
            // eslint-disable-next-line no-param-reassign
            errors.netzeroTargets = 'At least one target year should be set.';
        }
        return errors;
    },
    // active check
    (values) => values?.netzero === true
);
