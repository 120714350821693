import React from 'react';
import { Alert, Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { doSwaggerCall, useApi } from '../../hooks/useApi';
import useNotify from '../../hooks/useNotify';
import PageWithTitle from '../../components/atom/PageWithTitle';
import EsgQuestionForm from './components/EsgQuestionForm';

const validateSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    esg_disclosure_id: Yup.string().required('Required'),
});

const EsgQuestionEditPage = () => {
    const { notifyError, notifySuccess } = useNotify();
    const { esgQuestionId } = useParams();
    const navigate = useNavigate();
    const [data, loading, error, setData, reloadData] = useApi('EsgQuestions', 'getEsgQuestion', {
        id: esgQuestionId,
    });
    if (error) {
        notifyError(error);
        navigate('/esgquestions');
    }

    if (loading) {
        return null;
    }

    const onSubmit = async (values) => {
        const req = doSwaggerCall(
            'EsgQuestions',
            'editEsgQuestion',
            { id: esgQuestionId },
            {
                name: values.name.trim(),
                esgDisclosureId: parseInt(values.esg_disclosure_id, 10),
                fields: values.fields,
                guideNote: values.guide_note,
            }
        );
        req.then(() => {
            reloadData();
            notifySuccess('ESG question edited');
        }).catch((err) => notifyError(err));
    };

    return (
        <PageWithTitle title={`${data?.name || 'Loading...'}`}>
            <Formik
                onSubmit={onSubmit}
                enableReinitialize
                initialValues={data}
                validationSchema={validateSchema}
            >
                {({ isSubmitting, values, touched, errors, setFieldValue }) => (
                    <Form>
                        <Grid container spacing={2} sx={{ mt: 1 }}>
                            {Object.keys(errors).length > 0 && (
                                <Grid item xs={12}>
                                    <Alert severity="error">
                                        There are errors in the form!
                                        {/* TODO: better error handling */}
                                        <br />
                                        <div>{JSON.stringify(errors)}</div>
                                    </Alert>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <EsgQuestionForm
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    loading={loading}
                                    touched={touched}
                                    isSubmitting={isSubmitting}
                                    errors={errors}
                                />
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </PageWithTitle>
    );
};

export default EsgQuestionEditPage;
