import {
    Grid,
    Paper,
    Container,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Typography,
    CircularProgress,
} from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryParam, withDefault, StringParam, NumberParam } from 'use-query-params';
import ComponentTitle from '../../../components/ComponentTitle';
import { doSwaggerCall, useApi } from '../../../hooks/useApi';
import { useAuth } from '../../../hooks/useAuth';
import useNotify from '../../../hooks/useNotify';
import CommonButton from '../../../components/atom/CommonButton';

const CarbonCreditPurchasePage = () => {
    const { notifyError } = useNotify();
    const [productId] = useQueryParam('id', withDefault(StringParam, ''));
    const [productAmount] = useQueryParam('amount', withDefault(NumberParam, 0));
    const { user } = useAuth();
    const [data, loading, error, reloadData] = useApi('CarbonCredit', 'getProduct', {
        id: productId,
    });
    const navigate = useNavigate();

    const onSubmit = (values, { setSubmitting }) => {
        setSubmitting(true);
        doSwaggerCall(
            'CarbonCredit',
            'doPurchase',
            {},
            {
                consumer: {
                    firstName: values.firstName,
                    lastName: values.lastName,
                    company: values.company,
                    email: values.email,
                },
                currency: values.currency,
                volume: {
                    unit: data.credits.stock.unit,
                    quantity: Math.round(productAmount),
                },
                criteria: {
                    ids: [data.id],
                },
            }
        )
            .then((res) => {
                window.open(res.webUrl, '_blank');
            })
            .catch((err) => {
                notifyError(err);
                setSubmitting(false);
            });
    };

    return (
        <Formik
            onSubmit={onSubmit}
            initialValues={{
                firstName: user.name.split(' ')[0] || '',
                lastName: user.name.split(' ')[1] || '',
                company: '',
                email: user.email || '',
            }}
        >
            {({ isSubmitting, values, setFieldValue }) => (
                <Form>
                    <Container spacing={2}>
                        <Grid container item xs={12} spacing={2}>
                            <Grid container item xs={12} spacing={2}>
                                <Grid item xs={6}>
                                    <Paper sx={{ p: 2 }}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <ComponentTitle
                                                    variant="h4"
                                                    title="Purchase credit"
                                                />
                                            </Grid>
                                            <Grid item xs={6} spacing={2}>
                                                <TextField
                                                    fullWidth
                                                    required
                                                    disabled={isSubmitting}
                                                    label="First name"
                                                    value={values.firstName}
                                                    onChange={(ev) => {
                                                        setFieldValue('firstName', ev.target.value);
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={6} spacing={2}>
                                                <TextField
                                                    fullWidth
                                                    required
                                                    disabled={isSubmitting}
                                                    label="Last name"
                                                    value={values.lastName}
                                                    onChange={(ev) => {
                                                        setFieldValue('lastName', ev.target.value);
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} spacing={2}>
                                                <TextField
                                                    fullWidth
                                                    required
                                                    disabled={isSubmitting}
                                                    label="Company name"
                                                    value={values.company}
                                                    onChange={(ev) => {
                                                        setFieldValue('company', ev.target.value);
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} spacing={2}>
                                                <TextField
                                                    fullWidth
                                                    required
                                                    disabled={isSubmitting}
                                                    type="email"
                                                    label="Email"
                                                    value={values.email}
                                                    onChange={(ev) => {
                                                        setFieldValue('email', ev.target.value);
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} spacing={2}>
                                                <FormControl fullWidth required>
                                                    <InputLabel id="currency-select">
                                                        Currency
                                                    </InputLabel>
                                                    <Select
                                                        disabled={isSubmitting}
                                                        fullWidth
                                                        labelId="currency-select"
                                                        id="demo-simple-select"
                                                        label="Currency"
                                                        onChange={(ev) => {
                                                            setFieldValue(
                                                                'currency',
                                                                ev.target.value
                                                            );
                                                        }}
                                                    >
                                                        <MenuItem value="EUR">EUR</MenuItem>
                                                        <MenuItem value="USD">USD</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <CommonButton
                                                    fullWidth
                                                    onClick={() => navigate(`/carboncredit`)}
                                                    label="Back to Carbon Credits"
                                                    buttonType="secondary"
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <CommonButton
                                                    fullWidth
                                                    disabled={isSubmitting}
                                                    type="submit"
                                                    label="Purchase"
                                                    buttonType="primary"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                                <Grid item xs={4}>
                                    <Paper sx={{ p: 1.5 }}>
                                        {loading ? (
                                            <CircularProgress color="inherit" size={25} />
                                        ) : (
                                            <Grid>
                                                <Typography variant="h5" sx={{ mt: 0.5, mb: 2 }}>
                                                    Your cart:
                                                </Typography>
                                                <Typography>
                                                    <b>Product:</b> {data?.name || '-'}
                                                </Typography>
                                                <Typography>
                                                    <b>Volume:</b>
                                                    {` ${Math.round(productAmount) || '-'}${
                                                        data?.credits.stock.unit || ''
                                                    }`}
                                                </Typography>
                                            </Grid>
                                        )}
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                </Form>
            )}
        </Formik>
    );
};

export default CarbonCreditPurchasePage;
