import { Button, Grid, Typography } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import React from 'react';
import CommonButton from '../../../components/atom/CommonButton';

export default function ImpSurveyFooter({ onPrev, onNext, saveStep, values }) {
    return (
        <Grid item xs={12}>
            <Grid container item xs={12} justifyContent="space-between" sx={{ p: 2 }}>
                {!onPrev && (
                    <Grid item xs={6} sx={{ mt: 1 }}>
                        <Typography variant="body2">
                            When you are done, click on Save & Continue.
                        </Typography>
                    </Grid>
                )}
                {onPrev && (
                    <Grid item xs={6}>
                        <Button variant="text" onClick={onPrev}>
                            <KeyboardArrowLeft />
                            Previous
                        </Button>
                    </Grid>
                )}
                <Grid item>
                    {onNext ? (
                        <Button
                            variant="text"
                            color="primary"
                            onClick={() => saveStep(values, false, true)}
                        >
                            Save & Continue
                            <KeyboardArrowRight />
                        </Button>
                    ) : (
                        <>
                            <CommonButton
                                buttonType="text"
                                label="Save as Draft"
                                onClick={() => saveStep(values, false, false)}
                            />
                            <CommonButton
                                label="Save & Mark as Completed"
                                buttonType="primary"
                                style={{ marginLeft: '10px' }}
                                onClick={() => saveStep(values, true, false)}
                            />
                        </>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
}
