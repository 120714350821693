import React from 'react';
import {
    DialogActions,
    DialogContentText,
    DialogContent,
    DialogTitle,
    Dialog,
    Grid,
} from '@mui/material';
import CommonButton from '../components/atom/CommonButton';

const ConfirmationSendSurveyModal = ({
    title,
    content,
    contactsData,
    confirm,
    showModal,
    MODAL_TYPE,
}) => {
    const closeAction = () => showModal(MODAL_TYPE.NONE);
    return (
        <Dialog
            open
            onClose={closeAction}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
                <DialogContentText id="alert-dialog-warning">
                    {contactsData.map((contactData) => {
                        return (
                            <Grid>
                                <p>
                                    <b>{contactData.name}</b>
                                </p>
                                {contactData.contacts.map((contact, idx) => (
                                    <ul>
                                        {idx === 0 ? 'to: ' : 'cc: '}
                                        {contact.name} - {contact.email}
                                    </ul>
                                ))}
                            </Grid>
                        );
                    })}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <CommonButton
                    onClick={closeAction}
                    autoFocus
                    label="Cancel"
                    buttonType="secondary"
                />
                <CommonButton
                    onClick={() => {
                        confirm();
                        showModal(MODAL_TYPE.NONE);
                    }}
                    label="Yes"
                    buttonType="primary"
                />
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationSendSurveyModal;
