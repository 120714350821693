import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useNotify from '../../../hooks/useNotify';
import { TABLE_ROW_COUNT_DEFAULT } from '../../../helpers/constans';
import useSorting from '../../../hooks/useSorting';
import { doSwaggerCall, useApi } from '../../../hooks/useApi';
import PageWithTitle from '../../../components/atom/PageWithTitle';
import CommonButton from '../../../components/atom/CommonButton';
import EsgHierarchyTable from '../components/EsgHierarchyTable';
import EsgEditorSwitcher from '../components/EsgEditorSwitcher';
import SearchInput from '../../../components/atom/SearchInput';

const EsgSubcategoriesPage = () => {
    const navigate = useNavigate();
    const { notifyError, notifySuccess } = useNotify();
    const { esgCategoryId } = useParams();
    const [search, setSearch] = useState('');
    const [{ limit, page }, setParams] = useState({ limit: TABLE_ROW_COUNT_DEFAULT, page: 0 });
    const { sort, sortDir, setSorting } = useSorting(['created_at', 'ASC']);
    const [data, loading, error, setData, reloadData] = useApi(
        'EsgSubcategories',
        'getEsgSubcategories',
        {
            esgCategoryId,
            search,
            limit,
            offset: page * limit,
            sort,
            sortDir,
        }
    );

    const deleteSubcategory = (id) => {
        doSwaggerCall('EsgSubcategories', 'deleteEsgSubcategory', { id })
            .then(() => {
                notifySuccess('ESG subcategory deleted');
                reloadData();
            })
            .catch((err) => {
                notifyError(err);
            });
    };

    useEffect(() => {
        if (error) {
            notifyError(error);
        }
    }, [error, notifyError]);

    return (
        <>
            <EsgEditorSwitcher />
            <PageWithTitle
                title="ESG Subcategories"
                headline={[
                    <Grid
                        item
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        <Grid item>
                            <SearchInput
                                search={search}
                                setSearch={setSearch}
                                setParams={setParams}
                                limit={limit}
                            />
                        </Grid>
                        <Grid item>
                            <CommonButton
                                onClick={() => navigate(`/esgcategories`)}
                                label="Back"
                                buttonType="secondary"
                                sx={{ mr: 0.625 }}
                            />
                            <CommonButton
                                onClick={() =>
                                    navigate(`/esgcategories/${esgCategoryId}/esgsubcategories/new`)
                                }
                                label="Add new ESG subcategory"
                                buttonType="primary"
                            />
                        </Grid>
                    </Grid>,
                ]}
            >
                <EsgHierarchyTable
                    title="ESG Subcategories"
                    rows={data?.esgSubcategories}
                    limit={limit}
                    page={page}
                    setParams={setParams}
                    total={data?.total}
                    loading={loading}
                    sort={sort}
                    sortDir={sortDir}
                    setSorting={setSorting}
                    deleteRow={deleteSubcategory}
                />
            </PageWithTitle>
        </>
    );
};

export default EsgSubcategoriesPage;
