import { FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import FloatInput from '../../../components/atom/FloatInput';

function getSelectedTab(calculationType, conversionLogic, noBaseUnit, setSelectedLogic) {
    // default logic (or base unit) is always the first tab
    if (calculationType === null || !conversionLogic) {
        if (noBaseUnit) {
            setSelectedLogic(1);
            return 1;
        }
        return 0;
    }
    const logicIds = conversionLogic.map((e) => `${e.id}`);
    const selected = logicIds.indexOf(`${calculationType}`);
    if (selected === -1) {
        return 0;
    }
    return selected + 1;
}
function SurveyDatalineFormulaPickerDropdown({
    conversionLogic,
    fields,
    calculationType,
    setCalculationType,
    baseUnit,
    amount,
    setAmount,
    setFields,
    // disables the "base unit" option
    noBaseUnit = false,
}) {
    // pick the one logic that is currently used
    const setSelectedLogic = useCallback(
        (tab) => {
            if (tab === 0) {
                // base unit one
                return setCalculationType(null);
            }
            return setCalculationType(conversionLogic[tab - 1].id);
        },
        [conversionLogic, setCalculationType, baseUnit]
    );
    const selectedLogic = getSelectedTab(
        calculationType,
        conversionLogic,
        noBaseUnit,
        setSelectedLogic
    );

    // handle a case where there should not be a base unit
    useEffect(() => {
        if (noBaseUnit === false || calculationType !== null) {
            return;
        }
        if (conversionLogic.length === 0) {
            return;
        }
        setCalculationType(conversionLogic[1]?.id);
    }, [noBaseUnit, calculationType, setCalculationType]);

    if (!baseUnit) {
        return <span>Pick an emission source first!</span>;
    }
    if (conversionLogic.length === 0 && noBaseUnit) {
        return <span>No conversion logic available</span>;
    }

    return (
        <Grid container spacing={2} style={{ alignItems: 'center' }}>
            <Grid item xs={3}>
                <FormControl fullWidth>
                    <InputLabel id="logic-select">Unit of measurements</InputLabel>
                    <Select
                        fullWidth
                        labelId="logic-select"
                        id="demo-simple-select"
                        value={selectedLogic || 0}
                        label="Unit of measurements"
                        onChange={(e) => {
                            setSelectedLogic(e.target.value);
                        }}
                        required
                    >
                        {!noBaseUnit && <MenuItem value={0}>{`${baseUnit} (base unit)`}</MenuItem>}
                        {conversionLogic.map((logic, idx) => {
                            return (
                                <MenuItem key={idx} value={idx + 1}>
                                    {logic?.name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={9}>
                <Grid container>
                    {!noBaseUnit && selectedLogic === 0 && (
                        <Grid item xs={12}>
                            <FloatInput
                                fullWidth
                                label="Usage"
                                type="number"
                                required
                                value={amount}
                                onChange={(ev) => setAmount(ev.target.rawValue)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">{baseUnit}</InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                    )}
                    {selectedLogic !== 0 &&
                        conversionLogic[selectedLogic - 1]?.fields.map((oneField) => {
                            return (
                                <Grid
                                    item
                                    key={oneField.id}
                                    xs={12 / conversionLogic[selectedLogic - 1].fields.length}
                                >
                                    <FloatInput
                                        fullWidth
                                        label={oneField.label}
                                        type="number"
                                        required
                                        value={fields[oneField.field_id] || ''}
                                        onChange={(ev) => {
                                            // set fields
                                            const newFields = { ...fields };
                                            newFields[oneField.field_id] = ev.target.rawValue;
                                            setFields(newFields);
                                        }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    {oneField.prefix}
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    {oneField.postfix}
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                            );
                        })}
                </Grid>
            </Grid>
        </Grid>
    );
}

export default SurveyDatalineFormulaPickerDropdown;
