import { useAuth } from '../hooks/useAuth';
import { PERMISSIONS } from './constans';

function navigateByPermission() {
    const { userPermissions } = useAuth();

    let navigateUrl = '/user/me';
    if (userPermissions.includes(PERMISSIONS.VIEW_DASHBOARD)) {
        navigateUrl = '/dashboard';
    } else if (userPermissions.includes(PERMISSIONS.VIEW_DATALINES)) {
        navigateUrl = '/datalines';
    } else if (userPermissions.includes(PERMISSIONS.VIEW_ASSIGNED_TO)) {
        navigateUrl = '/datalines/assignedtome';
    } else if (userPermissions.includes(PERMISSIONS.VIEW_OWNED)) {
        navigateUrl = '/datalines/ownedbyme';
    } else if (userPermissions.includes(PERMISSIONS.VIEW_REQUIREMENTS)) {
        navigateUrl = '/progresstracker';
    }

    return navigateUrl;
}

export default navigateByPermission;
