import React, { useCallback, useEffect, useState } from 'react';
import { Grid, TextField, Typography, IconButton } from '@mui/material';
import { Form, Formik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import { doSwaggerCall } from '../../../hooks/useApi';
import useNotify from '../../../hooks/useNotify';
import { MODAL_TYPE, useModals } from '../../../hooks/useModal';
import { useGlobalFilters } from '../../../hooks/useGlobalFilters';
import SourcePicker from '../availablesources/components/SourcePicker';
import CommonButton from '../../../components/atom/CommonButton';

function EditCompanyTagPage({ newCompanyTag }) {
    const { year } = useGlobalFilters();
    const { companyTagId } = useParams();
    const { showModal } = useModals();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [companyTag, setCompanyTag] = useState(null);
    const { notifyError, notifySuccess } = useNotify();
    const [checkedData, setCheckedData] = useState([]);
    const [companyTagEdit, setCompanyTagEdit] = useState(false);

    const defaultCompanyTag = {
        name: 'New Company Tag',
    };

    const loadCompanyTag = useCallback(() => {
        setLoading(true);
        doSwaggerCall('CompanyTags', 'getCompanyTag', { id: companyTagId })
            .then((res) => {
                setCompanyTag({
                    id: res.id,
                    name: res.name,
                    available_sources: res.available_sources,
                });
                setCheckedData(res.available_sources);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                navigate('/companytags');
                notifyError(err);
            });
    }, [companyTagId, year]);

    useEffect(() => {
        if (!newCompanyTag) {
            loadCompanyTag();
        } else {
            setLoading(false);
        }
    }, [companyTagId, year]);

    const onEdit = (values) => {
        showModal(MODAL_TYPE.CONFIRMATION, {
            title: `Are you sure you want to edit this company tag?`,
            confirm: () => {
                doSwaggerCall(
                    'CompanyTags',
                    'editCompanyTag',
                    { id: companyTagId },
                    {
                        name: values.name,
                        availableSources: checkedData,
                    }
                )
                    .then(() => {
                        loadCompanyTag();
                        navigate('/companytags');
                        notifySuccess('Company tag updated');
                    })
                    .catch((err) => {
                        notifyError(err);
                    });
            },
        });
    };

    const onSubmit = async (values) => {
        if (!newCompanyTag) {
            return onEdit(values);
        }
        try {
            await doSwaggerCall(
                'CompanyTags',
                'addCompanyTag',
                {},
                {
                    name: values.name,
                    availableSources: checkedData,
                }
            );
            navigate('/companytags');
            notifySuccess('Company tag added');
        } catch (error) {
            notifyError(error);
        }
    };

    if (loading) {
        return true;
    }

    return (
        <Formik onSubmit={onSubmit} initialValues={companyTag || { ...defaultCompanyTag }}>
            {({ isSubmitting, values, setFieldValue, touched, errors }) => (
                <Form>
                    {companyTagEdit ? (
                        <Grid item xs={6} sx={{ pb: 2 }}>
                            <TextField
                                fullWidth
                                autoComplete="off"
                                label="Name"
                                value={values.name}
                                onChange={(ev) => setFieldValue('name', ev.target.value)}
                                autoFocus
                                error={touched.name && Boolean(errors.name)}
                                onBlur={() => setCompanyTagEdit(false)}
                                helperText={touched.name ? errors.name : ''}
                            />
                        </Grid>
                    ) : (
                        <Grid item xs={12} style={{ alignContent: 'center' }}>
                            <Typography
                                variant="h5"
                                style={{
                                    display: 'inline-block',
                                    fontWeight: 'bold',
                                }}
                            >
                                {values.name}
                            </Typography>
                            <IconButton
                                disabled={isSubmitting}
                                onClick={() => setCompanyTagEdit(true)}
                            >
                                <EditIcon />
                            </IconButton>
                        </Grid>
                    )}
                    <Grid container>
                        <Grid item xs={12} />
                        <Grid item container xs={12} justifyContent="flex-end">
                            <Grid item xs={2} md={2}>
                                <CommonButton
                                    fullWidth
                                    onClick={() => navigate('/companytags')}
                                    label="Cancel"
                                    buttonType="secondary"
                                />
                            </Grid>
                            <Grid item xs={2} md={2}>
                                <CommonButton
                                    fullWidth
                                    disabled={isSubmitting}
                                    type="submit"
                                    label="Save"
                                    buttonType="primary"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <SourcePicker
                        year={year}
                        checkedData={checkedData}
                        setCheckedData={setCheckedData}
                    />
                </Form>
            )}
        </Formik>
    );
}

export default EditCompanyTagPage;
