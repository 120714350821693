import React, { useCallback, useEffect, useState } from 'react';
import {
    DialogActions,
    DialogContent,
    DialogTitle,
    Dialog,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    DialogContentText,
    CircularProgress,
    Tooltip,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { doSwaggerCall } from '../hooks/useApi';
import useNotify from '../hooks/useNotify';
import { useGlobalFilters } from '../hooks/useGlobalFilters';
import SiteFilter from './components/SiteFilter';
import SettingsPanel from './components/SettingsPanel';
import { useAuth } from '../hooks/useAuth';
import CommonButton from '../components/atom/CommonButton';

const CopyDatasetModal = ({ title, companyId, isDataline = true, showModal, MODAL_TYPE }) => {
    const closeAction = () => showModal(MODAL_TYPE.NONE);
    const { notifySuccess, notifyError } = useNotify();
    const [copyFromYear, setCopyFromYear] = useState();
    const [destinationYear, setDestinationYear] = useState();
    const [siteIdList, setSiteIdList] = useState([]);
    const [settings, setSettings] = useState([]);
    const [loading, setLoading] = useState(false);
    const [displayIncorrect, setDisplayIncorrect] = useState(false);
    const [incorrectDatalines, setIncorrectDatalines] = useState();
    const [availableYears, setAvailableYears] = useState([]);

    const navigate = useNavigate();
    const { setSwitchIntoCompany, setAccessToken, user } = useAuth();
    const { setSourceFilters, setYear } = useGlobalFilters();

    const disableCopyButton = () => {
        return typeof copyFromYear !== 'number' || typeof destinationYear !== 'number';
    };

    const getAvailableYears = async () => {
        try {
            const result = await doSwaggerCall('CompanyYears', 'getCompanyYears', {
                companyId,
            });
            setAvailableYears(result?.companyYears?.map((year) => year.year) || []);
        } catch (error) {
            notifyError(error);
        }
    };

    const copyData = async () => {
        setLoading(true);
        const successMessage = isDataline ? 'Datalines copy successful' : 'Esgs copy successful';
        try {
            const result = await doSwaggerCall(
                isDataline ? 'Datalines' : 'Esgs',
                isDataline ? 'copyDatalines' : 'copyEsgs',
                {
                    copyFromYear,
                    year: destinationYear,
                    siteIdList,
                    companyId,
                },
                { settings }
            );

            if (isDataline) {
                setIncorrectDatalines(result.incorrectDatalinesCount);
                setDisplayIncorrect(true);
            } else {
                showModal(MODAL_TYPE.NONE);
            }
            notifySuccess(`${successMessage} from ${copyFromYear} to ${destinationYear}`);
        } catch (error) {
            notifyError(error);
        } finally {
            setLoading(false);
        }
    };

    const switchAndNavigate = useCallback(
        (year) => {
            doSwaggerCall('Auth', 'switchCompany', {
                companyId,
            })
                .then((data) => {
                    const { token, ...otherUserData } = data.user;
                    setSwitchIntoCompany(otherUserData);
                    setAccessToken(token);
                    setSourceFilters({
                        scopeFilter: null,
                        categoryFilter: null,
                        subcategoryFilter: null,
                        emissionSourceFilter: null,
                    });
                    navigate('/datalines/incorrect');
                    showModal(MODAL_TYPE.NONE);
                    setYear(year);
                    if (companyId !== user.company_id) {
                        notifySuccess('Switched into company');
                    }
                })
                .catch((error) => {
                    notifyError(error);
                });
        },
        [setSwitchIntoCompany, setAccessToken]
    );

    useEffect(() => {
        getAvailableYears();
    }, [companyId]);

    return (
        <Dialog
            open
            onClose={closeAction}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="md"
            fullWidth
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            {!loading ? (
                <DialogContent>
                    {!displayIncorrect ? (
                        <>
                            <DialogContentText id="alert-dialog-description">
                                This action is irreversible and may take some time to complete.
                            </DialogContentText>
                            <Grid container xs={12} sx={{ mt: 2 }}>
                                <Grid item xs={6}>
                                    <FormControl fullWidth>
                                        <InputLabel id="year-select" required>
                                            Copy from
                                        </InputLabel>
                                        <Select
                                            fullWidth
                                            labelId="year-select"
                                            id="demo-simple-select"
                                            value={copyFromYear}
                                            size="small"
                                            label="Copy from"
                                            onChange={(e) => {
                                                setCopyFromYear(e.target.value);
                                            }}
                                        >
                                            {availableYears?.map((yearItem) => (
                                                <MenuItem key={yearItem} value={yearItem}>
                                                    {yearItem}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl fullWidth>
                                        <InputLabel id="year-select" required>
                                            Copy to
                                        </InputLabel>
                                        <Select
                                            fullWidth
                                            labelId="year-select"
                                            id="demo-simple-select"
                                            value={destinationYear}
                                            size="small"
                                            label="Copy to"
                                            onChange={(e) => {
                                                setDestinationYear(e.target.value);
                                            }}
                                        >
                                            {availableYears?.map((yearItem) => (
                                                <MenuItem key={yearItem} value={yearItem}>
                                                    {yearItem}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <SiteFilter
                                        siteIdList={siteIdList}
                                        setSiteIdList={setSiteIdList}
                                        companyId={companyId}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <SettingsPanel
                                        settings={settings}
                                        setSettings={setSettings}
                                        isDataline={isDataline}
                                    />
                                </Grid>
                            </Grid>
                        </>
                    ) : (
                        <DialogContentText id="alert-dialog-description">
                            <CommonButton
                                onClick={() => {
                                    switchAndNavigate(destinationYear);
                                }}
                                label={`In ${destinationYear} you have ${incorrectDatalines} incorrect datalines.`}
                                buttonType="text"
                            />
                        </DialogContentText>
                    )}
                </DialogContent>
            ) : (
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <CircularProgress />
                    </DialogContentText>
                </DialogContent>
            )}
            <DialogActions>
                <CommonButton
                    onClick={closeAction}
                    autoFocus
                    label="Cancel"
                    buttonType="secondary"
                />
                {!displayIncorrect &&
                    (disableCopyButton() ? (
                        <Tooltip title="Please select copy from and copy to year" placement="left">
                            <span>
                                <CommonButton disabled label="Yes" buttonType="primary" />
                            </span>
                        </Tooltip>
                    ) : (
                        <CommonButton
                            onClick={() => {
                                copyData();
                            }}
                            label="Yes"
                            buttonType="primary"
                        />
                    ))}
            </DialogActions>
        </Dialog>
    );
};

export default CopyDatasetModal;
