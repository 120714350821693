/**
 * Helper recursive function to extend scenario data with baseline sum and optional target sum emission for all level and modify hierarchy data
 *
 * @param item sourceHierarchy
 * @param reduction reduction percentage
 */
const recursiveSumChildrenForScenario = (item, reduction) => {
    // don't have children
    if (!Array.isArray(item.children)) {
        return {
            id: item.id,
            name: item.name,
            locked: false,
            baseline: item.sum / 1000000 || 0,
            target: (item.sum / 1000000) * (1 - reduction / 100) || 0,
            base_unit: item.base_unit,
            baseline_usage: item.sumBaseline || 0,
        };
    }

    const newChildren = item.children.map((e) => recursiveSumChildrenForScenario(e, reduction));
    const sum = newChildren.reduce((a, b) => a + b.baseline, 0);
    return {
        id: item.id,
        name: item.name,
        locked: false,
        baseline: sum,
        target: sum * (1 - reduction / 100),
        children: newChildren,
        order: item.order,
    };
};

export default recursiveSumChildrenForScenario;
