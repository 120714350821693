import { Tooltip } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import React from 'react';
import { useDarkMode } from '../../hooks/useDarkMode';
import { useGuideBoxSidebar } from '../../hooks/useGuideBoxSidebar';

const HelperIcon = ({ title, guideNote }) => {
    const { darkMode } = useDarkMode();
    const { openSidebar } = useGuideBoxSidebar();
    return (
        <sup>
            <Tooltip
                title="Click here to open guidance"
                sx={{ cursor: 'pointer' }}
                placement="right"
                onClick={() => openSidebar({ title, content: guideNote })}
            >
                <HelpOutlineIcon
                    style={{
                        fontSize: '15px',
                        color: !darkMode ? 'grey' : 'white',
                    }}
                />
            </Tooltip>
        </sup>
    );
};

export default HelperIcon;
