import {
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip,
} from '@mui/material';
import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import SkeletonTable from '../../../components/atom/SkeletonTable';
import { MODAL_TYPE, useModals } from '../../../hooks/useModal';
import { doSwaggerCall } from '../../../hooks/useApi';
import useNotify from '../../../hooks/useNotify';
import { useGlobalFilters } from '../../../hooks/useGlobalFilters';
import SortableTableCell from '../../../components/atom/SortableTableCell';
import { TABLE_ROW_COUNT_OPTIONS } from '../../../helpers/constans';
import { handleChangeRowsPerPage, handlePageChange } from '../../../helpers/handleTableFunctions';

const CategoriesTable = ({
    rows,
    limit,
    page,
    setParams,
    total,
    loading,
    reloadData,
    sort,
    sortDir,
    setSorting,
}) => {
    const { showModal } = useModals();
    const { notifyError, notifySuccess } = useNotify();
    const { setSourceFilters } = useGlobalFilters();

    const deleteCategory = useCallback((id) => {
        doSwaggerCall('Categories', 'deleteCategory', { id })
            .then(() => {
                notifySuccess('Category deleted');
                setSourceFilters({
                    scopeFilter: null,
                    categoryFilter: null,
                    subcategoryFilter: null,
                    emissionSourceFilter: null,
                });
                reloadData();
            })
            .catch(() => {
                notifyError('Cannot delete category');
            });
    }, []);

    return (
        <Grid item xs={12}>
            <Table>
                <TableHead>
                    <TableRow>
                        <SortableTableCell
                            arrow={sort === 'name' ? sortDir : ''}
                            onClick={() => setSorting('name')}
                        >
                            Name
                        </SortableTableCell>
                        <SortableTableCell
                            arrow={sort === 'scope' ? sortDir : ''}
                            onClick={() => setSorting('scope')}
                        >
                            Scope
                        </SortableTableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading ? (
                        <SkeletonTable rows={limit} cells={3} />
                    ) : (
                        rows?.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell>{row.name}</TableCell>
                                <TableCell>{row.scope_name}</TableCell>
                                <TableCell align="right">
                                    <Link to={`/categories/${row.id}/subcategories`}>
                                        <Tooltip title="Subcategories">
                                            <IconButton>
                                                <SubdirectoryArrowRightIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Link>
                                    <Link to={`/categories/edit/${row.id}`}>
                                        <Tooltip title="Edit">
                                            <IconButton>
                                                <EditIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Link>
                                    <IconButton
                                        onClick={() => {
                                            showModal(MODAL_TYPE.CONFIRMATION, {
                                                title: `Are you sure you want to delete this category?`,
                                                content: 'This action is irreversible.',
                                                confirm: () => deleteCategory(row.id),
                                            });
                                        }}
                                    >
                                        <Tooltip title="Delete">
                                            <DeleteIcon />
                                        </Tooltip>
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))
                    )}
                </TableBody>
            </Table>
            <TablePagination
                count={total || 0}
                rowsPerPage={limit}
                component="div"
                page={page}
                onPageChange={(_, newPage) => handlePageChange(newPage, setParams, limit)}
                rowsPerPageOptions={TABLE_ROW_COUNT_OPTIONS}
                onRowsPerPageChange={(event) => handleChangeRowsPerPage(event, setParams)}
            />
        </Grid>
    );
};

export default CategoriesTable;
