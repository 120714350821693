import { ResponsivePie } from '@nivo/pie';
import React, { useEffect } from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import { formatNumber } from '../../../../helpers/convertUnits';
import { LegendsContextProvider, useLegends } from '../../../../hooks/useLegends';
import {
    PIECHART_PROPS,
    customNivoTheme,
    formatChartNumber,
} from '../../../../helpers/chartStyleHelper';
import { useDarkMode } from '../../../../hooks/useDarkMode';
import PieLegendDisplay from '../PieLegendDisplay';

const PieLegendSink = ({ dataWithArc }) => {
    const { setPieLegends } = useLegends();

    useEffect(() => {
        setPieLegends(dataWithArc);
    }, [setPieLegends, dataWithArc]);
    return null;
};

const ScopeOrCategoryOrSubcatOrSitePieChart = ({ data, year, type }) => {
    const { darkMode } = useDarkMode();

    let typeText = '';
    switch (type) {
        case 'scope':
            typeText = `Scope: ${data.scopeName}`;
            break;
        case 'category':
            typeText = `Category: ${data.categoryName}`;
            break;
        case 'subcategory':
            typeText = `Subcategory: ${data.subcategoryName}`;
            break;
        case 'site':
            typeText = `Site: ${data.siteName}`;
            break;
        default:
            break;
    }
    return (
        <Grid item xs={12}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <LegendsContextProvider>
                        <Paper sx={{ borderColor: 'transparent' }}>
                            <Grid item xs={12}>
                                <>
                                    <Typography variant="h5">
                                        {typeText} - {year}
                                    </Typography>
                                    <Typography variant="h4" fontWeight="bolder">
                                        {formatNumber(data.sum)} CO2e (mt)
                                    </Typography>
                                </>
                            </Grid>
                            <Grid item xs={12} sx={{ aspectRatio: '2/1', flex: '1 1 0' }}>
                                <ResponsivePie
                                    data={data.data}
                                    theme={customNivoTheme(darkMode)}
                                    colors={PIECHART_PROPS.colors}
                                    activeInnerRadiusOffset={PIECHART_PROPS.activeInnerRadiusOffset}
                                    activeOuterRadiusOffset={PIECHART_PROPS.activeOuterRadiusOffset}
                                    innerRadius={PIECHART_PROPS.innerRadius}
                                    borderWidth={PIECHART_PROPS.borderWidth}
                                    enableArcLabels={PIECHART_PROPS.enableArcLabels}
                                    padAngle={PIECHART_PROPS.padAngle}
                                    cornerRadius={PIECHART_PROPS.cornerRadius}
                                    borderColor={PIECHART_PROPS.borderColor}
                                    padding={PIECHART_PROPS.padding}
                                    animate={false}
                                    colorBy="value"
                                    margin={{ top: 15, right: 15, bottom: 15, left: 15 }}
                                    // display both value and % value
                                    valueFormat={(value) =>
                                        `${Number(value).toLocaleString('en-US', {
                                            minimumFractionDigits: 2,
                                        })} - ${
                                            Number.isNaN((value / data.sum) * 100)
                                                ? 0
                                                : Math.round((value / data.sum) * 100)
                                        } %`
                                    }
                                    // display only value
                                    arcLabel={(e) => formatChartNumber(e)}
                                    enableArcLinkLabels={false}
                                    motionConfig="stiff"
                                    layers={['arcLinkLabels', 'arcs', 'arcLabels', PieLegendSink]}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <PieLegendDisplay />
                            </Grid>
                        </Paper>
                    </LegendsContextProvider>
                </Grid>
            </Grid>
            <Grid
                style={{
                    textAlign: 'right',

                    marginTop: '10px',
                }}
            >
                <Typography
                    variant="body2"
                    sx={{
                        fontStyle: 'italic',
                    }}
                >
                    All values are in CO2e (mt)
                </Typography>
            </Grid>
        </Grid>
    );
};

export default ScopeOrCategoryOrSubcatOrSitePieChart;
