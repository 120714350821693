import { TextField } from '@mui/material';
import React from 'react';
import { scopeAutocomplete } from '../../autocomplete';
import { useGlobalFilters } from '../../hooks/useGlobalFilters';
import AutocompleteSelect from '../atom/AutoCompleteSelect';

function GlobalScopeFilter() {
    const { scope, setSourceFilters, setEdited } = useGlobalFilters();
    return (
        <AutocompleteSelect
            initCall={scopeAutocomplete.init}
            autocompleteCall={scopeAutocomplete.search}
            value={scope}
            setValue={(ev, getItem) => {
                const item = getItem(ev);
                setSourceFilters({
                    scopeFilter: item?.id || null,
                    scopeNameFilter: item?.name || null,
                    categoryFilter: null,
                    categoryNameFilter: null,
                    subcategoryFilter: null,
                    emissionSourceFilter: null,
                });
                setEdited(false);
            }}
        >
            <TextField label="Scope" fullWidth size="small" />
        </AutocompleteSelect>
    );
}

export default GlobalScopeFilter;
