import React, { useEffect, useRef, useState } from 'react';
import {
    Alert,
    Button,
    Grid,
    IconButton,
    LinearProgress,
    TableCell,
    TableRow,
    TextField,
    Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Edit } from '@mui/icons-material';
import PsychologyIcon from '@mui/icons-material/Psychology';
import { doSwaggerCall } from '../../../hooks/useApi';
import useNotify from '../../../hooks/useNotify';

function NoteLineEdit({ noteEdit, setNote, setNoteEdit, id, note, currentPath, setShowNote }) {
    const noteFieldRef = useRef();
    useEffect(() => {
        noteFieldRef.current?.focus();
    }, [noteEdit === `${id}-${currentPath}`, noteFieldRef.current]);

    return noteEdit === `${id}-${currentPath}` ? (
        <TextField
            inputRef={noteFieldRef}
            name={note}
            size="small"
            autoComplete="off"
            label="Note"
            value={note}
            onChange={(ev) => setNote(ev.target.value)}
            onBlur={() => {
                if (typeof note === 'undefined' || note === '') {
                    setShowNote(false);
                }
                setNoteEdit(false);
            }}
            disabled={noteEdit !== `${id}-${currentPath}`}
            sx={{
                m: 3.75,
                width: '70%',
            }}
        />
    ) : (
        <>
            <Typography variant="body3">{note}</Typography>
            <IconButton size="small" onClick={() => setNoteEdit(`${id}-${currentPath}`)}>
                <Edit />
            </IconButton>
        </>
    );
}

const SOURCE_LEVEL_LENGTH_TO_TYPE = ['scope', 'category', 'subcategory', 'source'];
function ScenarioLineActions({ note, setNote, id, currentPath, sourceLevel, scenarioItem }) {
    const [showNote, setShowNote] = useState(!!note);
    const [noteEdit, setNoteEdit] = useState(false);
    const [aiSuggestion, setAiSuggestion] = useState(false);
    const [aiSuggestionLoading, setAiSuggestionLoading] = useState(false);
    const { notifyError } = useNotify();

    const isNoteEditing = noteEdit === `${id}-${currentPath}`;

    const getSuggestions = () => {
        setAiSuggestionLoading(true);
        doSwaggerCall(
            'Scenarios',
            'getScenarioSuggestion',
            {},
            {
                id,
                type: SOURCE_LEVEL_LENGTH_TO_TYPE[currentPath.length],
                name: scenarioItem?.name,
                baselineInCO2: scenarioItem?.baseline,
                targetInCO2: scenarioItem?.target,
                baselineInBaseUnit: scenarioItem?.baseline_usage || undefined,
                targetInBaseUnit:
                    (scenarioItem?.target / scenarioItem?.baseline) *
                        scenarioItem?.baseline_usage || undefined,
                baseUnit: scenarioItem?.base_unit || undefined,
            }
        )
            .then((res) => {
                setAiSuggestion(res.suggestion);
                setAiSuggestionLoading(false);
            })
            .catch((err) => {
                console.log(err);
                notifyError('Cannot generate suggestions!');
                setAiSuggestionLoading(false);
            });
    };
    return (
        <>
            <TableRow>
                <TableCell colSpan={sourceLevel ? 7 : 5}>
                    <Grid
                        item
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        sx={{
                            pl: isNoteEditing ? 0 : 2.5,
                        }}
                        style={{
                            height: isNoteEditing && showNote ? '50px' : '20px',
                            alignContent: 'center',
                        }}
                    >
                        {!showNote ? (
                            <Button
                                variant="text"
                                size="small"
                                startIcon={<AddIcon />}
                                onClick={() => {
                                    setShowNote(true);
                                    setNoteEdit(`${id}-${currentPath}`);
                                }}
                            >
                                Note
                            </Button>
                        ) : (
                            <NoteLineEdit
                                noteEdit={noteEdit}
                                setNote={setNote}
                                setNoteEdit={setNoteEdit}
                                id={id}
                                note={note}
                                currentPath={currentPath}
                                setShowNote={setShowNote}
                            />
                        )}
                        {aiSuggestion === false && (
                            <Button
                                variant="text"
                                size="small"
                                startIcon={<AddIcon />}
                                onClick={getSuggestions}
                            >
                                Get AI assisted reduction suggestions
                            </Button>
                        )}
                    </Grid>
                </TableCell>
            </TableRow>
            {(aiSuggestion !== false || aiSuggestionLoading === true) && (
                <TableRow>
                    <TableCell colSpan={sourceLevel ? 7 : 5}>
                        <Grid container>
                            <Grid item xs={2} style={{ position: 'relative', fontSize: 0 }}>
                                <div
                                    style={{
                                        position: 'absolute',
                                        left: '50%',
                                        top: '50%',
                                        transform: 'translate(-50%, -50%)',
                                    }}
                                >
                                    <PsychologyIcon style={{ fontSize: '80px', opacity: '0.2' }} />
                                </div>
                            </Grid>
                            <Grid item xs={10}>
                                <Typography variant="body3">AI Suggestion:</Typography>
                                {aiSuggestionLoading && <LinearProgress sx={{ mt: 6, mb: 6 }} />}
                                {!aiSuggestionLoading &&
                                    aiSuggestion?.split('\n').map((line) => (
                                        <Typography
                                            variant="body2"
                                            style={{ fontSize: '14px', lineHeight: '32px' }}
                                        >
                                            {/* Lines can include urls, we should make them clickable */}
                                            {line.split(' ').map((word) => {
                                                if (
                                                    word.includes('https://') ||
                                                    word.includes('http://')
                                                ) {
                                                    // urls are sometimes are between ()
                                                    let url = word;
                                                    try {
                                                        if (word.includes('(')) {
                                                            url = word.split('(')[1];
                                                        }
                                                        if (word.includes(')')) {
                                                            url = url.split(')')[0];
                                                        }
                                                    } catch (e) {
                                                        console.log(e);
                                                    }
                                                    return (
                                                        <a
                                                            href={url}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            {word}{' '}
                                                        </a>
                                                    );
                                                }
                                                return `${word} `;
                                            })}
                                        </Typography>
                                    ))}
                                <Alert sx={{ mt: 1 }} severity="warning">
                                    Be Advised: Our AI suggestions strive for accuracy, but they can
                                    still contain errors or misleading information. Always
                                    cross-check critical information with reliable sources before
                                    making any decisions based on the responses.
                                </Alert>
                            </Grid>
                        </Grid>
                    </TableCell>
                </TableRow>
            )}
        </>
    );
}

export default ScenarioLineActions;
