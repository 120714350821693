import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ComponentTitle from '../../components/ComponentTitle';
import { useApi } from '../../hooks/useApi';
import useNotify from '../../hooks/useNotify';
import LocationsTable from './components/LocationsTable';
import useSorting from '../../hooks/useSorting';
import { parseXlsxSheets, runImport } from '../../helpers/excelImportLocationsHelpers';
import { MODAL_TYPE, useModals } from '../../hooks/useModal';
import { TABLE_ROW_COUNT_DEFAULT, WARNING_CONTENT_RECALCULATE_TEXT } from '../../helpers/constans';
import CommonButton from '../../components/atom/CommonButton';
import ImportExportButtonGroup from '../../components/atom/ImportExportButtonGroup';

const LocationsPage = () => {
    const navigate = useNavigate();
    const { parentId } = useParams();
    const { notifyError, notifySuccess } = useNotify();
    const { sort, sortDir, setSorting } = useSorting(['created_at', 'ASC']);
    const [importingXlsx, setImportingXlsx] = useState(false);
    const [{ limit, page }, setParams] = useState({ limit: TABLE_ROW_COUNT_DEFAULT, page: 0 });
    const { showModal } = useModals();
    const [data, loading, error, setData, reloadData] = useApi(
        'Locations',
        'getLocationsByParentId',
        {
            id: parentId,
            limit,
            offset: page * limit,
            sort,
            sortDir,
        }
    );
    const [parentLocation, loadingParent, errorParent] = useApi('Locations', 'getLocation', {
        id: parentId,
    });

    useEffect(() => {
        if (error) {
            notifyError(error);
        }
        if (errorParent) {
            notifyError(errorParent);
        }
    }, [error, errorParent, notifyError]);

    const handleImport = async (e) => {
        try {
            return parseXlsxSheets(e.target.files[0], (parsedData) => {
                runImport(parsedData, true).then((dryRunResult) => {
                    const dryRunFlat = Object.entries(dryRunResult).reduce(
                        (sum, item) => [...sum, ...item[1]],
                        []
                    );
                    showModal(MODAL_TYPE.CONFIRMATION_IMPORT_LOCATIONS, {
                        title: `Are you sure you want to import this xlsx?`,
                        content: 'This action is irreversible.',
                        warningContent: WARNING_CONTENT_RECALCULATE_TEXT,
                        data: dryRunFlat,
                        setImportingXlsx,
                        confirm: async () => {
                            await runImport(parsedData, false);
                            notifySuccess('Import successful');
                            setImportingXlsx(false);
                            reloadData();
                        },
                    });
                });
            });
        } catch (err) {
            setImportingXlsx(false);
            notifyError(err);
        }
    };

    return (
        <Grid container alignContent="flex-start">
            <Grid container item xs={12} justifyContent="space-between" spacing={0}>
                <Grid item>
                    <ComponentTitle variant="h4" title="Locations" />
                </Grid>
                <Grid item>
                    <Grid
                        item
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        {parentId !== '1' ? (
                            <Grid item>
                                <CommonButton
                                    onClick={() =>
                                        navigate(`/locations/${parentLocation.parent_id}`)
                                    }
                                    disabled={!parentLocation}
                                    label="Back"
                                    buttonType="secondary"
                                />
                            </Grid>
                        ) : null}
                        <ImportExportButtonGroup
                            type="locations"
                            handleImport={handleImport}
                            importingXlsx={importingXlsx}
                            setImportingXlsx={setImportingXlsx}
                            navigate={async () => navigate(`/locations/${parentId}/new`)}
                            parentLocation={parentLocation}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <LocationsTable
                rows={data?.locations}
                limit={limit}
                page={page}
                setParams={setParams}
                total={data?.total}
                loading={loading}
                loadingParent={loadingParent}
                reloadData={reloadData}
                parentId={parentId}
                parentLocationName={parentLocation?.name}
                sort={sort}
                sortDir={sortDir}
                setSorting={setSorting}
            />
        </Grid>
    );
};

export default LocationsPage;
