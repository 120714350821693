import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useApi } from '../../hooks/useApi';
import useNotify from '../../hooks/useNotify';
import ConversionLogicTemplatesTable from './components/ConversionLogicTemplatesTable';
import useSorting from '../../hooks/useSorting';
import PageWithTitle from '../../components/atom/PageWithTitle';
import { MODAL_TYPE, useModals } from '../../hooks/useModal';
import { TABLE_ROW_COUNT_DEFAULT } from '../../helpers/constans';
import CommonButton from '../../components/atom/CommonButton';

const ConversionLogicTemplatesPage = () => {
    const { notifyError } = useNotify();
    const { showModal } = useModals();
    const { sort, sortDir, setSorting } = useSorting(['created_at', 'ASC']);
    const [{ limit, page }, setParams] = useState({ limit: TABLE_ROW_COUNT_DEFAULT, page: 0 });
    const [data, loading, error, setData, reloadData] = useApi(
        'ConversionTemplates',
        'getConversionTemplates',
        {
            limit,
            offset: page * limit,
            sort,
            sortDir,
        }
    );

    useEffect(() => {
        if (error) {
            notifyError(error);
        }
    }, [error, notifyError]);

    return (
        <PageWithTitle
            title="Conversion Logic Templates"
            headline={[
                <Grid item>
                    <CommonButton
                        fullWidth
                        onClick={() =>
                            showModal(MODAL_TYPE.ADD_LOGIC_TEMPLATE, {
                                title: `Create new template`,
                                content: 'Select a unique name that is not already in the system.',
                            })
                        }
                        label="Create new template"
                        buttonType="primary"
                    />
                </Grid>,
            ]}
        >
            <ConversionLogicTemplatesTable
                rows={data?.templates}
                limit={limit}
                page={page}
                setParams={setParams}
                total={data?.total}
                loading={loading}
                reloadData={reloadData}
                sort={sort}
                sortDir={sortDir}
                setSorting={setSorting}
            />
        </PageWithTitle>
    );
};

export default ConversionLogicTemplatesPage;
