import React, { useState } from 'react';
import {
    DialogActions,
    DialogContent,
    DialogTitle,
    Dialog,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    DialogContentText,
    Alert,
    TextField,
    Tooltip,
} from '@mui/material';
import { doSwaggerCall } from '../hooks/useApi';
import useNotify from '../hooks/useNotify';
import AutocompleteSelect from '../components/atom/AutoCompleteSelect';
import { companyAutocomplete, siteInCompanyAutocomplete } from '../autocomplete';
import CommonButton from '../components/atom/CommonButton';

const CopyFromAnotherYearModal = ({
    title,
    content,
    descriptionType,
    emissionSourceId,
    companyId,
    siteId,
    year,
    availableYears,
    reloadData,
    warningContent = false,
    showModal,
    MODAL_TYPE,
}) => {
    const closeAction = () => showModal(MODAL_TYPE.NONE);
    const { notifySuccess, notifyError } = useNotify();
    const [copyFromYear, setCopyFromYear] = useState(new Date().getFullYear() - 1);
    const [copyFromCompany, setCopyFromCompany] = useState(parseInt(companyId, 10));
    const [copyFromSite, setCopyFromSite] = useState(0);
    const [availableSelectedCompanyYears, setAvailableSelectedCompanyYears] =
        useState(availableYears);

    const copyFactors = async () => {
        try {
            await doSwaggerCall('EmissionFactors', 'copyEmissionFactors', {
                id: emissionSourceId,
                year,
                copyFromYear,
            });
            notifySuccess(`Factors copy successful from ${copyFromYear} to ${year}`);
            reloadData();
        } catch (error) {
            notifyError(error);
        }
    };

    const copyRequirements = async () => {
        try {
            await doSwaggerCall('Requirements', 'copyRequirements', {
                id: companyId,
                siteId: siteId || 0,
                year,
                copyFromYear,
                copyFromCompany,
                copyFromSite,
            });
            notifySuccess(`Inventory Boundary copy successful`);
            reloadData();
        } catch (error) {
            notifyError(error);
        }
    };

    return (
        <Dialog
            open
            onClose={closeAction}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="xs"
            fullWidth
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
                <DialogContentText id="alert-dialog-description">
                    Existing {descriptionType} will be removed.
                </DialogContentText>
                {warningContent && (
                    <DialogContentText id="alert-dialog-warning">
                        <Alert sx={{ mt: 0.5 }} severity="warning">
                            {warningContent}
                        </Alert>
                    </DialogContentText>
                )}
                {descriptionType === 'requirements' && (
                    <>
                        <Grid item xs={12} sx={{ mt: 2 }}>
                            <AutocompleteSelect
                                size="small"
                                initCall={companyAutocomplete.init}
                                autocompleteCall={companyAutocomplete.search}
                                value={copyFromCompany}
                                setValue={(ev, getItem) => {
                                    const item = getItem(ev);
                                    setAvailableSelectedCompanyYears(item?.company_years || []);
                                    setCopyFromCompany(ev);
                                    setCopyFromSite(0);
                                }}
                            >
                                <TextField label="Copy from Company" required />
                            </AutocompleteSelect>
                        </Grid>
                        <Grid item xs={12} sx={{ mt: 2 }}>
                            <AutocompleteSelect
                                size="small"
                                initCall={siteInCompanyAutocomplete.init}
                                autocompleteCall={siteInCompanyAutocomplete.search}
                                value={copyFromSite}
                                setValue={(ev) => {
                                    setCopyFromSite(ev);
                                }}
                                searchParams={{
                                    companyId: copyFromCompany,
                                }}
                            >
                                <TextField label="Copy from Site" required />
                            </AutocompleteSelect>
                        </Grid>
                    </>
                )}
                <Grid item xs={12} sx={{ mt: 2 }}>
                    <FormControl fullWidth>
                        <InputLabel id="year-select" required>
                            Copy from year
                        </InputLabel>
                        <Select
                            fullWidth
                            labelId="year-select"
                            id="demo-simple-select"
                            value={copyFromYear}
                            size="small"
                            label="Copy from year"
                            onChange={(e) => {
                                setCopyFromYear(e.target.value);
                            }}
                        >
                            {availableSelectedCompanyYears?.map((yearItem) => (
                                <MenuItem key={yearItem} value={yearItem}>
                                    {yearItem}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </DialogContent>
            <DialogActions>
                <CommonButton
                    onClick={closeAction}
                    autoFocus
                    label="Cancel"
                    buttonType="secondary"
                />
                {typeof copyFromCompany !== 'number' || typeof copyFromSite !== 'number' ? (
                    <Tooltip title="Pick a company and a site first!" placement="left">
                        <span>
                            <CommonButton
                                sx={{ mr: 1 }}
                                disabled
                                label="Yes"
                                buttonType="primary"
                            />
                        </span>
                    </Tooltip>
                ) : (
                    <CommonButton
                        onClick={() => {
                            if (descriptionType === 'factors') {
                                copyFactors();
                            }
                            if (descriptionType === 'requirements') {
                                copyRequirements();
                            }
                            showModal(MODAL_TYPE.NONE);
                        }}
                        label="Yes"
                        buttonType="primary"
                    />
                )}
            </DialogActions>
        </Dialog>
    );
};

export default CopyFromAnotherYearModal;
