// helper functions to check item name and children name (with search)
const hasChildrenWithSearch = (item, searchLowercase) => {
    if (item.name.toLowerCase().includes(searchLowercase)) {
        return true;
    }
    if (!Array.isArray(item.children)) {
        return false;
    }

    return !!item.children.find((e) => hasChildrenWithSearch(e, searchLowercase));
};

export default function recursiveTreeFilter(list, searchLowercase) {
    return list
        .map((item) => {
            // keep the whole subtree
            if (item.name.toLowerCase().includes(searchLowercase)) {
                return item;
            }

            if (!hasChildrenWithSearch(item, searchLowercase)) {
                return false;
            }

            if (!item.children) {
                return item;
            }

            return {
                ...item,
                children: recursiveTreeFilter(item.children, searchLowercase),
            };
        })
        .filter((e) => e);
}
