import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { doSwaggerCall } from '../../../hooks/useApi';
import useNotify from '../../../hooks/useNotify';
import EsgEditForm from '../components/EsgEditForm';

function EditEsgDisclosurePage({ newEsgDisclosure }) {
    const { esgCategoryId, esgSubcategoryId, esgDisclosureId } = useParams();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [disclosure, setDisclosure] = useState(null);
    const { notifyError, notifySuccess } = useNotify();

    const defaultDisclosure = {
        name: 'New Esg Disclosure',
        rank: 1,
    };

    const navigateBack = `/esgcategories/${esgCategoryId}/esgsubcategories/${esgSubcategoryId}/esgdisclosures`;

    const loadCategory = useCallback(() => {
        setLoading(true);
        doSwaggerCall('EsgDisclosures', 'getEsgDisclosure', { id: esgDisclosureId })
            .then((res) => {
                setDisclosure({
                    id: res.id,
                    name: res.name,
                    rank: res.rank,
                });
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                notifyError(err);
                navigate(navigateBack);
            });
    }, []);

    useEffect(() => {
        if (!newEsgDisclosure) {
            loadCategory();
        } else {
            setLoading(false);
        }
    }, [esgSubcategoryId]);

    const onSubmit = async (values, { setSubmitting }) => {
        try {
            if (newEsgDisclosure) {
                await doSwaggerCall(
                    'EsgDisclosures',
                    'addEsgDisclosure',
                    {},
                    {
                        esgSubcategoryId: parseInt(esgSubcategoryId, 10),
                        name: values.name.trim(),
                        rank: parseInt(values.rank, 10),
                    }
                );
                navigate(navigateBack);
                notifySuccess('Esg disclosure added');
            } else {
                await doSwaggerCall(
                    'EsgDisclosures',
                    'editEsgDisclosure',
                    { id: esgDisclosureId },
                    {
                        esgSubcategoryId: parseInt(esgSubcategoryId, 10),
                        name: values.name.trim(),
                        rank: parseInt(values.rank, 10),
                    }
                );
                loadCategory();
                setSubmitting(false);
                navigate(navigateBack);
                notifySuccess('Esg disclosure updated');
            }
        } catch (err) {
            notifyError(err);
            setSubmitting(false);
        }
    };

    if (loading) {
        return true;
    }

    return (
        <EsgEditForm
            initialValues={disclosure || { ...defaultDisclosure }}
            onSubmit={onSubmit}
            title="ESG Disclosure"
            isNew={newEsgDisclosure}
            navigateBack={navigateBack}
        />
    );
}

export default EditEsgDisclosurePage;
