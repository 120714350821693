import React from 'react';
import { Drawer } from './Drawer';
import { useAuth } from '../../hooks/useAuth';
import { useGuideBoxSidebar } from '../../hooks/useGuideBoxSidebar';

const RightSidebarDrawerSpace = () => {
    const { user } = useAuth();
    const { sidebarOpen } = useGuideBoxSidebar();
    const isSurvey = window.location.pathname.includes('survey/'); // TODO: need a better solution

    return <Drawer variant="permanent" open={user && !isSurvey && sidebarOpen} anchor="right" />;
};

export default RightSidebarDrawerSpace;
