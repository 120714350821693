import {
    Checkbox,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useImpSurveyStep, withFormikContext } from './ImpSurveyStep';
import SurveyQuestionHeadline from '../../survey/components/SurveyQuestionHeadline';
import ImpSurveySection from './ImpSurveySection';
import SurveyQuestionSubheader from './SurveyQuestionSubheader';

// merge fields with categories and sort them by name
function mergeFieldsWithCategories(fields, categories) {
    const mergedSet = new Set([...fields, ...categories]);
    return Array.from(mergedSet).sort((a, b) =>
        a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' })
    );
}

function QListOfCategories({
    values,
    setFieldValue,
    sectionIndex,
    formikKey,
    subheader,
    q,
    categories,
    scope,
}) {
    const { step, addFieldIntoStep } = useImpSurveyStep();
    useEffect(() => {
        addFieldIntoStep(`answers.${formikKey}`, sectionIndex + 1);
    }, [addFieldIntoStep, formikKey, sectionIndex]);

    if (step !== sectionIndex + 1) {
        return null;
    }

    // create a list from values.answers[formikKey].fields keys
    const fieldsListFromKeys =
        (typeof values?.answers?.[formikKey]?.fields === 'object' &&
            Object.keys(values?.answers?.[formikKey]?.fields)) ||
        [];

    // categories by scope
    const categoryByScope = categories.filter((category) => category.scope_id === scope);

    const mergedCategories = mergeFieldsWithCategories(
        fieldsListFromKeys,
        categoryByScope.map((c) => c.name)
    );

    const handleSelectAll = (e) => {
        if (e.target.checked) {
            setFieldValue(`answers.${formikKey}`, {
                fields: mergedCategories.reduce((acc, category) => {
                    acc[category] = '';
                    return acc;
                }, {}),
            });
        } else {
            const newFields = { ...values.answers[formikKey].fields };
            mergedCategories.forEach((category) => {
                delete newFields[category];
            });
            setFieldValue(`answers.${formikKey}`, {
                fields: Object.keys(newFields).length > 0 ? newFields : undefined,
            });
        }
    };

    return (
        <ImpSurveySection>
            <Grid item xs={12}>
                <SurveyQuestionHeadline>{q}</SurveyQuestionHeadline>
                {subheader && <SurveyQuestionSubheader subheader={subheader} />}
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell width="5%">
                                <Checkbox
                                    checked={
                                        values.answers[formikKey].fields !== undefined &&
                                        Object.keys(values.answers[formikKey].fields).length ===
                                            mergedCategories.length
                                    }
                                    onChange={(ev) => handleSelectAll(ev)}
                                    indeterminate={
                                        values.answers[formikKey].fields !== undefined &&
                                        Object.keys(values.answers[formikKey].fields).length !==
                                            mergedCategories.length &&
                                        Object.keys(values.answers[formikKey].fields).length > 0
                                    }
                                />
                            </TableCell>
                            <TableCell width="35%">Category</TableCell>
                            <TableCell width="60%">Description</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {mergedCategories.map((category) => (
                            <TableRow key={category}>
                                <TableCell>
                                    <Checkbox
                                        checked={
                                            values.answers[formikKey].fields?.[category] !==
                                            undefined
                                        }
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                setFieldValue(`answers.${formikKey}`, {
                                                    fields: {
                                                        ...values.answers[formikKey].fields,
                                                        [category]: '',
                                                    },
                                                });
                                            } else {
                                                const newFields = {
                                                    ...values.answers[formikKey].fields,
                                                };
                                                delete newFields[category];
                                                setFieldValue(`answers.${formikKey}`, {
                                                    fields: newFields,
                                                });
                                            }
                                        }}
                                    />
                                </TableCell>
                                <TableCell>{category}</TableCell>
                                <TableCell>
                                    <TextField
                                        fullWidth
                                        autoComplete="off"
                                        disabled={
                                            values.answers[formikKey].fields?.[category] ===
                                            undefined
                                        }
                                        value={values.answers[formikKey].fields?.[category] || ''}
                                        onChange={(e) => {
                                            setFieldValue(
                                                `answers.${formikKey}.fields.${category}`,
                                                e.target.value
                                            );
                                        }}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                        <TableRow>
                            <TableCell colSpan={3}>
                                <TextField
                                    fullWidth
                                    autoComplete="off"
                                    label="Other Remarks"
                                    value={values.answers[formikKey].others || ''}
                                    onChange={(e) => {
                                        setFieldValue(
                                            `answers.${formikKey}.others`,
                                            e.target.value
                                        );
                                    }}
                                />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Grid>
        </ImpSurveySection>
    );
}

export default withFormikContext(QListOfCategories);
