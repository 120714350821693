import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { doSwaggerCall } from '../../../hooks/useApi';
import useNotify from '../../../hooks/useNotify';
import EsgEditForm from '../components/EsgEditForm';

function EditEsgframeworkPage({ newEsgFramework }) {
    const { esgFrameworkId } = useParams();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [esgFramework, setEsgFramework] = useState(null);
    const { notifyError, notifySuccess } = useNotify();

    const defaultFramework = {
        name: 'New Esg Framework',
    };

    const navigateBack = `/esgframeworks`;

    const loadCategory = useCallback(() => {
        setLoading(true);
        doSwaggerCall('EsgFrameworks', 'getEsgFramework', { id: esgFrameworkId })
            .then((res) => {
                setEsgFramework({
                    id: res.id,
                    name: res.name,
                });
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                notifyError(err);
                navigate(navigateBack);
            });
    }, []);

    useEffect(() => {
        if (!newEsgFramework) {
            loadCategory();
        } else {
            setLoading(false);
        }
    }, [newEsgFramework]);

    const onSubmit = async (values, { setSubmitting }) => {
        try {
            if (newEsgFramework) {
                await doSwaggerCall(
                    'EsgFrameworks',
                    'addEsgFramework',
                    {},
                    {
                        name: values.name.trim(),
                    }
                );
                navigate(navigateBack);
                notifySuccess('Esg framework added');
            } else {
                await doSwaggerCall(
                    'EsgFrameworks',
                    'editEsgFramework',
                    { id: esgFrameworkId },
                    {
                        name: values.name.trim(),
                    }
                );
                loadCategory();
                setSubmitting(false);
                navigate(navigateBack);
                notifySuccess('Esg framework updated');
            }
        } catch (err) {
            notifyError(err);
            setSubmitting(false);
        }
    };

    if (loading) {
        return true;
    }

    return (
        <EsgEditForm
            initialValues={esgFramework || { ...defaultFramework }}
            onSubmit={onSubmit}
            title="ESG Framework"
            isNew={newEsgFramework}
            navigateBack={navigateBack}
        />
    );
}

export default EditEsgframeworkPage;
