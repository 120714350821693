import { useFormikContext } from 'formik';
import debounce from 'just-debounce-it';
import React, { useCallback, useEffect, useState } from 'react';

const AutoSaveFormik = ({ debounceMs }) => {
    const formik = useFormikContext();
    const [lastSaved, setLastSaved] = useState(null);
    const debouncedSubmit = useCallback(
        debounce(
            () =>
                formik
                    .submitForm()
                    .then(() => setLastSaved(new Date().toLocaleTimeString('en-US'))),
            debounceMs
        ),
        [debounceMs, formik.submitForm]
    );

    useEffect(() => {
        debouncedSubmit();
    }, [debouncedSubmit, formik.values]);

    let message = null;
    if (formik.isSubmitting) {
        message = 'Saving...';
    } else if (lastSaved !== null) {
        message = `Last Saved: ${lastSaved}`;
    }

    return <small style={{ color: 'gray', fontSize: 11 }}>{message}</small>;
};

export default AutoSaveFormik;
