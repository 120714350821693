import React from 'react';
import { Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import ComponentTitle from '../../../components/ComponentTitle';
import { formatNumber } from '../../../helpers/convertUnits';
import SurveyScopeThreeBreakdown from './SurveyScopeThreeBreakdown';
import SurveyDatalines from './SurveyDatalines';

function SurveyResultScopes({ survey }) {
    let sumForScope3 = 0;
    const calcFrom3 = [];
    if (survey?.details?.hasScope3Cat1Report === true) {
        sumForScope3 += parseFloat(survey?.details?.scope3Cat1);
        calcFrom3.push('Scope 3 Category 1');
    }
    if (survey?.details?.hasScope3Cat5Report === true) {
        sumForScope3 += parseFloat(survey?.details?.scope3Cat5);
        calcFrom3.push('Scope 3 Category 5');
    }

    // we only have partial scop3 data, datalines will give us the scope3
    const datalines = survey?.details?.datalines || [];
    sumForScope3 += datalines.reduce((acc, curr) => {
        // if ignored is true, we don't count it
        if (curr.ignored === true) {
            return acc;
        }
        // datalines for other scopes should have 0 as co2e
        return acc + parseFloat(curr.co2e);
    }, 0);
    if (datalines.length > 0) {
        calcFrom3.push('Datalines');
    }

    return (
        <>
            <ComponentTitle
                variant="h5"
                title="Supplier provided a report and percentage of revenue"
            />
            <Grid item xs={12}>
                <Typography variant="body1">
                    The supplier stated, that the percentage of revenue allocated to use is{' '}
                    <b>{survey?.details?.scopePercentage}%</b>
                </Typography>
                {calcFrom3.includes('Datalines') && (
                    <Typography variant="body1">
                        Datalines are already normalized with{' '}
                        <b>{survey?.details?.scopePercentage}%</b> based on the supplier report.
                    </Typography>
                )}
            </Grid>
            <Grid item xs={12}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Supplier report scope</TableCell>
                            <TableCell align="right">CO2e (mt)</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>Scope 1</TableCell>
                            <TableCell align="right">
                                {formatNumber(survey?.details?.scope1Sum)}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Scope 2</TableCell>
                            <TableCell align="right">
                                {formatNumber(survey?.details?.scope2Sum)}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Scope 3 - Calculated from {calcFrom3.join(', ')}</TableCell>
                            <TableCell align="right">{formatNumber(sumForScope3)}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Grid>
            {(survey?.details?.hasScope3Cat1Report === true ||
                survey?.details?.hasScope3Cat5Report === true) && (
                <SurveyScopeThreeBreakdown survey={survey} />
            )}
            {survey?.details?.datalines?.length > 0 && (
                <>
                    <ComponentTitle
                        variant="h5"
                        title="For the missing Scope 3 categories, the following Datalines are used"
                    />
                    <Grid item xs={12}>
                        <Typography variant="body1">
                            Datalines are already normalized with {' '}
                            <b>{survey?.details?.scopePercentage}%</b> based on the supplier report.
                        </Typography>
                    </Grid>
                    <SurveyDatalines survey={survey} />
                </>
            )}
        </>
    );
}

export default SurveyResultScopes;
