import { Button, ButtonGroup, CircularProgress, Grid, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import AddIcon from '@mui/icons-material/Add';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import DownloadIcon from '@mui/icons-material/Download';
import { MODAL_TYPE, useModals } from '../../hooks/useModal';
import { ESG_GROUPBY_OPTIONS, ESG_GROUPS, transformEsgHierarchyToItem } from '../../helpers/groups';
import { useEsgFilters } from '../../hooks/useEsgFilters';
import useNotify from '../../hooks/useNotify';
import { useAuth } from '../../hooks/useAuth';
import { useGlobalFilters } from '../../hooks/useGlobalFilters';
import SearchInput from '../../components/atom/SearchInput';
import { doSwaggerCall, doSwaggerCallForPdf } from '../../hooks/useApi';
import EsgGroup from './components/EsgGroup';
import { BACKEND_URL } from '../../config';
import GroupByComponent from '../../components/atom/GroupByComponent';
import { allEsgsExport } from '../../helpers/excelExportEsgsHelpers';
import { useEsgFrameworkFilters } from '../../hooks/useEsgFrameworkFilters';
import { WITHOUT_UNIT } from '../../helpers/esgUnitConversions';
import { exportToXlsx } from '../../helpers/excelExportHelpers';

const EXPORT_LIMIT_CHUNK = 2;

const EsgPage = () => {
    const { user } = useAuth();
    const { showModal } = useModals();
    const { esgCategory, esgSubcategory, esgDisclosure, esgQuestion, edited, setEdited } =
        useEsgFilters();
    const { esgFramework } = useEsgFrameworkFilters();
    const { site, year } = useGlobalFilters();
    const [search, setSearch] = useQueryParam('search', withDefault(StringParam, ''));
    const [groupby, setGroupby] = useQueryParam(
        'groupby',
        withDefault(StringParam, ESG_GROUPS.HIERARCHY)
    );
    const [groupOpen, setGroupOpen] = useState([0]);
    const [dataGroups, setDataGroups] = useState([]);
    const { notifyError, notifyInfo, notifySuccess } = useNotify();
    const [generatingXlsx, setGeneratingXlsx] = useState(false);
    const responsibility = null; // TODO: responsibility from app param? {responsibility} - assignedToMe
    const [generatingEsgXlsx, setGeneratingEsgXlsx] = useState(false);
    const [generatingPdf, setGeneratingPdf] = useState(false);

    // create a group props (category,subcategory,disclosure,question,site)
    useEffect(() => {
        const baseline = {
            esgCategory,
            esgSubcategory,
            esgDisclosure,
            esgQuestion,
            site,
            sectionHeader: '',
        };
        if (groupby === ESG_GROUPS.NONE) {
            let sectionHeader = 'Esgs';
            if (responsibility === 'assignedToMe') {
                sectionHeader = 'Esgs assigned to me';
            }
            if (responsibility === 'ownedByMe') {
                sectionHeader = 'Esgs owned by me';
            }
            setDataGroups([
                {
                    id: 0,
                    name: 'All Esgs',
                    section: { ...baseline, sectionHeader },
                    type: 'all',
                },
            ]);
            setGroupOpen(['0-all']);
            return;
        }

        if (groupby === ESG_GROUPS.SITE) {
            doSwaggerCall('Sites', 'getSitesInCompany', {
                id: user.company_id,
                // TODO: fix meeee
                limit: 1000,
                offset: 0,
            })
                .then((res) => {
                    // filter sites on the group if any of them are selected
                    const filteredSites =
                        site.length === 0
                            ? res.sites
                            : res.sites.filter((e) => site.includes(e.id));
                    if (site.length === 0 || site.includes(0)) {
                        filteredSites.push({ id: 0 });
                    }
                    setDataGroups(
                        filteredSites.map((oneSite) => {
                            const sectionName =
                                oneSite.id === 0 ? 'Corporate' : `Site ${oneSite.name}`;
                            return {
                                id: oneSite.id,
                                name: sectionName,
                                section: {
                                    ...baseline,
                                    site: [oneSite.id],
                                    sectionHeader: sectionName,
                                },
                                type: 'site',
                            };
                        })
                    );
                    // open first 3 sites
                    setGroupOpen(
                        [...filteredSites, { id: 0 }].slice(0, 3).map((e) => `${e.id}-site`)
                    );
                })
                .catch((error) => notifyError(error));
            return;
        }
        if (groupby === ESG_GROUPS.HIERARCHY) {
            doSwaggerCall('EsgQuestions', 'getEsgQuestionHierarchy', {
                esgCategoryId: esgCategory,
                esgSubcategoryId: esgSubcategory,
                esgDisclosureId: esgDisclosure,
                esgQuestionId: esgQuestion,
                responsibility,
                siteIdList: site,
                year,
                hasanyesgs: true,
                esgFrameworkId: esgFramework,
            })
                .then((hierarchy) => {
                    const transformResult = hierarchy
                        .map((e) =>
                            transformEsgHierarchyToItem(
                                e,
                                esgCategory,
                                esgSubcategory,
                                esgDisclosure,
                                null, // question
                                baseline
                            )
                        )
                        // one more sort here since the transformEsgHierarchyToItem only sort children
                        .sort((a, b) =>
                            a.name.localeCompare(b.name, undefined, {
                                numeric: true,
                                sensitivity: 'base',
                            })
                        );
                    setDataGroups(transformResult);
                    if (transformResult.length === 0) {
                        let sectionHeader = 'Esgs';
                        if (responsibility === 'assignedToMe') {
                            sectionHeader = 'Esgs assigned to me';
                        }
                        if (responsibility === 'ownedByMe') {
                            sectionHeader = 'Esgs owned by me';
                        }
                        setDataGroups([
                            {
                                id: 0,
                                name: 'All Esgs assigned to me',
                                section: { ...baseline, sectionHeader },
                                type: 'allAssigned',
                            },
                        ]);
                        setGroupOpen(['0-allAssigned']);
                    } else {
                        // open scopes
                        const dataGroupOpen = [];
                        transformResult.forEach((dataCategory) => {
                            if (dataCategory.type === 'category') {
                                dataGroupOpen.push(`${dataCategory.id}-${dataCategory.type}`);
                            }
                            // if there is only one question, we open the section
                            if (!transformResult[0].children && transformResult.length === 1) {
                                dataGroupOpen.push(`${dataCategory.id}-${dataCategory.type}`);
                            }
                        });
                        setGroupOpen(dataGroupOpen);
                    }
                })
                .catch((error) => {
                    notifyError(error);
                });
        }
    }, [
        user,
        groupby,
        esgCategory,
        esgSubcategory,
        esgDisclosure,
        esgQuestion,
        site,
        setDataGroups,
        responsibility,
        year,
        esgFramework,
    ]);

    const getValues = (esgQuestionFields, esgValues) => {
        const mappedValues = esgQuestionFields.map((value) => {
            const foundValues = esgValues.find((e) => e.id === value.id);
            const measure =
                foundValues?.measure && foundValues.measure !== WITHOUT_UNIT
                    ? `${foundValues.measure}`
                    : '';
            return `${value.label}: ${foundValues ? `${foundValues.value} ${measure}` : ''}`;
        });
        return mappedValues;
    };

    // export all esgs
    const allExport = async () => {
        let esgs = [];
        let total = EXPORT_LIMIT_CHUNK + 1;
        for (let page = 0; page * EXPORT_LIMIT_CHUNK < total; page++) {
            const esgChunk = await doSwaggerCall('Esgs', 'getEsgs', {
                year,
                siteIdList: site,
                limit: EXPORT_LIMIT_CHUNK,
                offset: page * EXPORT_LIMIT_CHUNK,
            });
            total = esgChunk.total;
            esgs = [...esgs, ...esgChunk.esgs];
        }

        return esgs.map((esg) => ({
            id: esg.id,
            site:
                esg.site_name === '' || typeof esg.site_name === 'undefined'
                    ? 'Corporate'
                    : esg.site_name,
            location: esg.location_name,
            question: esg.esg_question,
            disclosure: esg.esg_disclosure,
            subcategory: esg.esg_subcategory,
            category: esg.esg_category,
            notes: esg.notes,
            mark_completed: esg.mark_completed ? 'Yes' : 'No',
            completion: `${esg.field_filled_out}/${esg.field_count}`,
            values: getValues(esg.esg_question_fields, esg.values).join('\n'),
        }));
    };

    const esgReportXlsx = async (
        frameworkId,
        siteIdList,
        selectedEsgCategory,
        selectedEsgSubcategory,
        selectedEsgDisclosure,
        selectedEsgQuestion,
        selectedYear
    ) => {
        const reportRequest = await doSwaggerCall('Reports', 'esgReportRequest', {
            year: selectedYear,
        });
        const params = {
            token: reportRequest.token,
        };
        if (frameworkId && frameworkId !== 0) {
            params.reportByFramework = frameworkId;
        }
        if (selectedEsgCategory) {
            params.esgCategoryId = selectedEsgCategory;
        }
        if (selectedEsgSubcategory) {
            params.esgSubcategoryId = selectedEsgSubcategory;
        }
        if (selectedEsgDisclosure) {
            params.esgDisclosureId = selectedEsgDisclosure;
        }
        if (selectedEsgQuestion) {
            params.esgQuestionId = selectedEsgQuestion;
        }
        if (siteIdList && siteIdList.length > 0) {
            params.siteIdList = siteIdList;
        }
        const esgs = await doSwaggerCall('Reports', 'getXlsxEsgReport', {
            ...params,
        });

        return esgs.map((esg) => ({
            id: esg.id,
            site: esg.site,
            question: esg.question,
            disclosure: esg.disclosure,
            subcategory: esg.subcategory,
            category: esg.category,
            notes: esg.notes,
            documents: esg.documents,
            mark_completed: esg.mark_completed,
            completion: esg.completion,
            values: esg.values,
        }));
    };

    const xlsxConfirm = async (
        frameworkId,
        siteIdList,
        selectedEsgCategory,
        selectedEsgSubcategory,
        selectedEsgDisclosure,
        selectedEsgQuestion,
        selectedYear
    ) => {
        setGeneratingEsgXlsx(true);
        let listToExport = [];
        try {
            listToExport = await esgReportXlsx(
                frameworkId,
                siteIdList,
                selectedEsgCategory,
                selectedEsgSubcategory,
                selectedEsgDisclosure,
                selectedEsgQuestion,
                selectedYear
            );
        } catch (err) {
            notifyError(err);
        }
        if (listToExport.length === 0) {
            notifyInfo('No data for selected report!');
            setGeneratingEsgXlsx(false);
            return;
        }
        exportToXlsx(
            listToExport,
            ...[`esg_report_${selectedYear}`, false, false, false, false, null, true]
        );
        setGeneratingEsgXlsx(false);
        notifySuccess('Reports exported');
    };

    const pdfExport = async (
        frameworkId,
        siteIdList,
        selectedEsgCategory,
        selectedEsgSubcategory,
        selectedEsgDisclosure,
        selectedEsgQuestion,
        selectedYear,
        auditReportFormat
    ) => {
        setGeneratingPdf(true);
        let reportRequest = null;
        const params = {
            year: selectedYear,
        };
        if (frameworkId && frameworkId !== 0) {
            params.reportByFramework = frameworkId;
        }
        if (selectedEsgCategory) {
            params.esgCategoryId = selectedEsgCategory;
        }
        if (selectedEsgSubcategory) {
            params.esgSubcategoryId = selectedEsgSubcategory;
        }
        if (selectedEsgDisclosure) {
            params.esgDisclosureId = selectedEsgDisclosure;
        }
        if (selectedEsgQuestion) {
            params.esgQuestionId = selectedEsgQuestion;
        }
        if (siteIdList && siteIdList.length > 0) {
            params.siteIdList = siteIdList;
        }
        if (auditReportFormat) {
            params.auditReportFormat = auditReportFormat;
        }
        try {
            reportRequest = await doSwaggerCallForPdf('Reports', 'esgReportRequestPdf', {
                ...params,
            });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(reportRequest);
            link.download = `esg_report_${selectedYear}.pdf`;
            link.click();
        } catch (err) {
            notifyError(err);
            setGeneratingPdf(false);
        } finally {
            setGeneratingPdf(false);
        }
    };

    const esgExport = async (
        frameworkId,
        siteIdList,
        selectedEsgCategory,
        selectedEsgSubcategory,
        selectedEsgDisclosure,
        selectedEsgQuestion,
        selectedYear,
        auditReportFormat
    ) => {
        try {
            const reportRequest = await doSwaggerCall('Reports', 'esgReportRequest', {
                year: selectedYear,
            });
            let url = `${BACKEND_URL}/1.0/reports/esgdownload?token=${reportRequest.token}`;
            if (frameworkId && frameworkId !== 0) {
                url += `&reportByFramework=${frameworkId}`;
            }
            if (selectedEsgCategory) {
                url += `&esgCategoryId=${selectedEsgCategory}`;
            }
            if (selectedEsgSubcategory) {
                url += `&esgSubcategoryId=${selectedEsgSubcategory}`;
            }
            if (selectedEsgDisclosure) {
                url += `&esgDisclosureId=${selectedEsgDisclosure}`;
            }
            if (selectedEsgQuestion) {
                url += `&esgQuestionId=${selectedEsgQuestion}`;
            }
            if (siteIdList && siteIdList.length > 0) {
                const encodedSiteIdList = encodeURIComponent(siteIdList);
                url += `&siteList=${encodedSiteIdList}`;
            }
            if (auditReportFormat) {
                url += `&auditReportFormat=${auditReportFormat}`;
            }
            window.open(url, '_blank', 'noopener,noreferrer');
        } catch (err) {
            notifyError(err);
        }
    };

    // different confirm function for esg report by reportType
    const confirmFunction = (
        frameworkId,
        siteIdList,
        reportType,
        selectedEsgCategory,
        selectedEsgSubcategory,
        selectedEsgDisclosure,
        selectedEsgQuestion,
        selectedYear,
        auditReportFormat
    ) => {
        const commonParams = {
            frameworkId,
            siteIdList,
            selectedEsgCategory,
            selectedEsgSubcategory,
            selectedEsgDisclosure,
            selectedEsgQuestion,
            selectedYear,
            auditReportFormat,
        };
        const handleExport = async (exportFunction) => {
            try {
                await exportFunction(...Object.values(commonParams));
            } catch (err) {
                notifyError(err);
            }
        };
        switch (reportType) {
            case 'XLSX':
                return handleExport(xlsxConfirm);
            case 'PDF':
                return handleExport(pdfExport);
            case 'HTML':
                return handleExport(esgExport);
            default:
                return () => {
                    notifyError('Something went wrong');
                };
        }
    };

    const loadDocumentsForDownload = async (structured = false) => {
        try {
            const { token } = await doSwaggerCall('Files', 'documentsRequest', {
                year,
                siteIdList: site,
                documentType: 'esg',
            });
            const url = `${BACKEND_URL}/1.0/documents/fordownload?token=${token}&structured=${structured}`;
            window.open(url, '_blank', 'noopener,noreferrer');
        } catch (documentError) {
            notifyError(documentError);
        }
    };

    return (
        <Grid container alignContent="flex-start">
            <Grid item xs={12}>
                <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end">
                    <Grid item xs={1}>
                        <Tooltip title="Add new ESG">
                            <Button
                                onClick={() => {
                                    showModal(MODAL_TYPE.ESG_FILTER, {
                                        title: `ESG Filter`,
                                        setGroupOpen,
                                        setGroupby,
                                    });
                                }}
                                fullWidth
                            >
                                <AddIcon />
                            </Button>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={5}>
                        <SearchInput
                            fullWidth
                            search={search}
                            setSearch={setSearch}
                            groupby={groupby}
                            setGroupby={setGroupby}
                        />
                    </Grid>
                    <GroupByComponent
                        groupBy={groupby}
                        setGroupBy={setGroupby}
                        search={search}
                        groupByOptions={ESG_GROUPBY_OPTIONS}
                    />
                    <Grid item xs={2}>
                        <Button
                            fullWidth
                            onClick={() => {
                                showModal(MODAL_TYPE.SELECT_ESG_REPORT, {
                                    confirm: (
                                        frameworkId,
                                        siteIdList,
                                        reportType,
                                        selectedEsgCategory,
                                        selectedEsgSubcategory,
                                        selectedEsgDisclosure,
                                        selectedEsgQuestion,
                                        selectedYear,
                                        auditReportFormat
                                    ) => {
                                        confirmFunction(
                                            frameworkId,
                                            siteIdList,
                                            reportType,
                                            selectedEsgCategory,
                                            selectedEsgSubcategory,
                                            selectedEsgDisclosure,
                                            selectedEsgQuestion,
                                            selectedYear,
                                            auditReportFormat
                                        ).catch((err) => {
                                            notifyError(err);
                                        });
                                    },
                                });
                            }}
                            disabled={generatingEsgXlsx || generatingPdf}
                        >
                            {generatingEsgXlsx || generatingPdf ? (
                                <CircularProgress color="inherit" size={20} />
                            ) : (
                                'Generate Esg Report'
                            )}
                            <ArrowDropDownIcon />
                        </Button>
                    </Grid>
                    <Grid item xs={1}>
                        <Tooltip title="Export to xlsx">
                            <Button
                                sx={{ height: '30px' }}
                                fullWidth
                                onClick={async () => {
                                    setGeneratingXlsx(true);
                                    let listToExport = [];
                                    try {
                                        listToExport = await allExport();
                                    } catch (err) {
                                        notifyError(err);
                                    }
                                    if (listToExport.length === 0) {
                                        notifyInfo('No data for selected report!');
                                        setGeneratingXlsx(false);
                                        return;
                                    }
                                    allEsgsExport(listToExport, `all_esgs_${year}`);
                                    setGeneratingXlsx(false);
                                    notifySuccess('Reports exported');
                                }}
                            >
                                {generatingXlsx ? (
                                    <CircularProgress color="inherit" size={25} />
                                ) : (
                                    <FileDownloadIcon />
                                )}
                            </Button>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={1}>
                        <ButtonGroup color="primary" fullWidth variant="contained">
                            <Tooltip title="Download all source data in a structured format">
                                <Button
                                    fullWidth
                                    variant="contained"
                                    sx={{ mr: 0.2 }}
                                    onClick={() => {
                                        loadDocumentsForDownload(true).catch((err) => {
                                            notifyError(err);
                                        });
                                    }}
                                >
                                    <FolderZipIcon />
                                </Button>
                            </Tooltip>
                            <Tooltip title="Download all source data">
                                <Button
                                    fullWidth
                                    variant="contained"
                                    onClick={() => {
                                        loadDocumentsForDownload().catch((err) => {
                                            notifyError(err);
                                        });
                                    }}
                                >
                                    <DownloadIcon />
                                </Button>
                            </Tooltip>
                        </ButtonGroup>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={0.5}>
                    {dataGroups.map((item, idx) => {
                        return (
                            <EsgGroup
                                key={idx}
                                groupby={groupby}
                                search={search}
                                toc={[idx + 1]}
                                setGroupOpen={setGroupOpen}
                                responsibility={responsibility}
                                groupOpen={groupOpen}
                                item={item}
                                edited={edited}
                                setEdited={setEdited}
                            />
                        );
                    })}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default EsgPage;
