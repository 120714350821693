import {
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
} from '@mui/material';
import React, { useCallback } from 'react';
import Add from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import FloatInput from '../atom/FloatInput';

const FieldEditor = ({ values, setFieldValue, isSuperAdmin }) => {
    const { default_field } = values;
    const setField = useCallback(
        (newValue) => {
            setFieldValue('default_field', newValue);
        },
        [setFieldValue]
    );
    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell width="48%">Name</TableCell>
                    <TableCell width="48%">Value</TableCell>
                    <TableCell width="4%">&nbsp;</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {default_field.map(({ id, name, field }) => (
                    <TableRow key={id}>
                        <TableCell>
                            <TextField
                                fullWidth
                                type="text"
                                autoComplete="off"
                                value={name}
                                label="default field name"
                                required
                                onChange={(ev) => {
                                    setField(
                                        default_field.map((line) => {
                                            if (line.id === id) {
                                                return {
                                                    id,
                                                    name: ev.target.value,
                                                    field: line.field,
                                                };
                                            }
                                            return line;
                                        })
                                    );
                                }}
                                disabled={!isSuperAdmin}
                            />
                        </TableCell>
                        <TableCell>
                            <FloatInput
                                fullWidth
                                label={`${name} value`}
                                type="number"
                                required
                                value={field}
                                onChange={(ev) => {
                                    setField(
                                        default_field.map((line) => {
                                            if (line.id === id) {
                                                return {
                                                    id,
                                                    name: line.name,
                                                    field: ev.target.rawValue,
                                                };
                                            }
                                            return line;
                                        })
                                    );
                                }}
                                disabled={!isSuperAdmin}
                            />
                        </TableCell>
                        <TableCell>
                            <IconButton
                                onClick={() => {
                                    setField(default_field.filter((e) => e.id !== id));
                                }}
                                disabled={!isSuperAdmin}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                ))}
                <TableRow>
                    <TableCell colSpan={2} />
                    <TableCell>
                        <IconButton
                            onClick={() => {
                                setField([
                                    ...default_field,
                                    {
                                        id: `${Math.random()}`.substring(2),
                                        name: '',
                                        field: '',
                                    },
                                ]);
                            }}
                            disabled={!isSuperAdmin}
                        >
                            <Add />
                        </IconButton>
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
};

export default FieldEditor;
