import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material';
import React from 'react';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Conversion from './Conversion';
import SkeletonForm from '../../../components/atom/SkeletonForm';
import BlockWithTitle from '../../../components/atom/BlockWithTitle';
import CommonButton from '../../../components/atom/CommonButton';

const ConversionLogicForm = ({
    loading,
    addNewConversionLogic,
    removeConversionLogic,
    values,
    setFieldValue,
    isChild = false,
    isConnectedToTemplate = false,
}) => {
    return (
        <BlockWithTitle title="Conversion logic">
            {!isChild && !isConnectedToTemplate && (
                <Grid item>
                    <CommonButton
                        onClick={async () => {
                            addNewConversionLogic(values);
                        }}
                        label="Add new logic"
                        buttonType="primary"
                    />
                </Grid>
            )}
            {loading ? (
                <SkeletonForm rows={2} />
            ) : (
                <Grid item xs={12}>
                    {values?.unit_conversion_logic
                        // filter out pure co2e logic
                        ?.filter((e) => e.id !== -1)
                        .map((_, idx) => (
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>
                                        {values.unit_conversion_logic[idx].name}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Conversion
                                        idx={idx}
                                        values={values}
                                        setFieldValue={setFieldValue}
                                        removeConversionLogic={removeConversionLogic}
                                        isChild={isChild}
                                        isConnectedToTemplate={isConnectedToTemplate}
                                    />
                                </AccordionDetails>
                            </Accordion>
                        ))}
                </Grid>
            )}
        </BlockWithTitle>
    );
};

export default ConversionLogicForm;
