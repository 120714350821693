import { TextField } from '@mui/material';
import React from 'react';
import { esgSubcategoryAutocomplete } from '../../../autocomplete';
import { useEsgFilters } from '../../../hooks/useEsgFilters';
import AutocompleteSelect from '../../../components/atom/AutoCompleteSelect';
import { groupEsgSubcategory } from '../../../helpers/groups';

function EsgSubcategoryFilter() {
    const { esgSubcategory, setEsgFilters, esgCategory, setEdited } = useEsgFilters();

    return (
        <AutocompleteSelect
            grouping={groupEsgSubcategory}
            initCall={esgSubcategoryAutocomplete.init}
            autocompleteCall={esgSubcategoryAutocomplete.search}
            value={esgSubcategory}
            setValue={(ev, getItem) => {
                const item = getItem(ev);
                setEsgFilters({
                    esgCategoryFilter: item?.category_id,
                    esgCategoryNameFilter: item?.category_name || null,
                    esgSubcategoryFilter: item?.id || null,
                    esgSubcategoryNameFilter: item?.name || null,
                    esgDisclosureFilter: null,
                    esgQuestionFilter: null,
                });
                setEdited(false);
            }}
            searchParams={{
                esgCategoryId: esgCategory,
            }}
        >
            <TextField label="Esg Subcategory" fullWidth size="small" />
        </AutocompleteSelect>
    );
}

export default EsgSubcategoryFilter;
