import { Grid, TextField } from '@mui/material';
import React from 'react';
import SurveyQuestionHeadline from '../components/SurveyQuestionHeadline';
import {
    withFormikContext,
    withStepAndValidation,
    withSurveySection,
} from '../components/SurveyStep';

function QMethodology({ values, setFieldValue }) {
    return (
        <>
            <Grid item xs={12}>
                <SurveyQuestionHeadline>
                    Provide a description of the methodologies used to quantify emissions and a
                    description of the data sources used (including emission factors and GWP
                    values):
                </SurveyQuestionHeadline>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    value={values.notes?.methodologies}
                    label="Methodologies"
                    autoComplete="off"
                    onChange={(e) => {
                        setFieldValue('notes.methodologies', e.target.value);
                    }}
                    multiline
                />
            </Grid>
        </>
    );
}

export default withStepAndValidation(
    withSurveySection(withFormikContext(QMethodology)),
    6,
    ['notes.methodologies']
    // no validation
    // always active, no need to set that check
);
