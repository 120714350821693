import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApi } from '../../hooks/useApi';
import useNotify from '../../hooks/useNotify';
import CategoriesTable from './components/CategoriesTable';
import useSorting from '../../hooks/useSorting';
import PageWithTitle from '../../components/atom/PageWithTitle';
import { TABLE_ROW_COUNT_DEFAULT } from '../../helpers/constans';
import CommonButton from '../../components/atom/CommonButton';

const CategoriesPage = () => {
    const navigate = useNavigate();
    const { notifyError } = useNotify();
    const [{ limit, page }, setParams] = useState({ limit: TABLE_ROW_COUNT_DEFAULT, page: 0 });
    const { sort, sortDir, setSorting } = useSorting(['created_at', 'ASC']);
    const [data, loading, error, setData, reloadData] = useApi('Categories', 'getCategories', {
        limit,
        offset: page * limit,
        sort,
        sortDir,
    });

    useEffect(() => {
        if (error) {
            notifyError(error);
        }
    }, [error, notifyError]);

    return (
        <PageWithTitle
            title="Categories"
            headline={[
                <Grid item>
                    <CommonButton
                        fullWidth
                        onClick={() => navigate('/categories/new')}
                        label="Add new category"
                        buttonType="primary"
                    />
                </Grid>,
            ]}
        >
            <CategoriesTable
                rows={data?.categories}
                limit={limit}
                page={page}
                setParams={setParams}
                total={data?.total}
                loading={loading}
                reloadData={reloadData}
                sort={sort}
                sortDir={sortDir}
                setSorting={setSorting}
            />
        </PageWithTitle>
    );
};

export default CategoriesPage;
