import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';
import { useGlobalFilters } from '../../../hooks/useGlobalFilters';

const YearSelector = ({ panelConfig, setPanelConfig, multiSelect }) => {
    const { availableYears } = useGlobalFilters();

    return (
        <Grid item xs={12}>
            {multiSelect ? (
                <FormControl fullWidth>
                    <InputLabel id="year-select">Years</InputLabel>
                    <Select
                        fullWidth
                        multiple
                        labelId="year-select"
                        id="demo-simple-select"
                        value={panelConfig?.years || []}
                        size="small"
                        label="Years"
                        onChange={(e) => {
                            setPanelConfig({
                                ...panelConfig,
                                years: [...e.target.value],
                            });
                        }}
                    >
                        {availableYears.map((yearItem) => (
                            <MenuItem key={yearItem.year} value={yearItem.year}>
                                {yearItem.year}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            ) : (
                <FormControl fullWidth>
                    <InputLabel id="year-select">Year</InputLabel>
                    <Select
                        fullWidth
                        labelId="year-select"
                        id="demo-simple-select"
                        value={panelConfig?.year || ''}
                        size="small"
                        label="Year"
                        onChange={(e) => {
                            setPanelConfig({
                                ...panelConfig,
                                year: e.target.value,
                            });
                        }}
                    >
                        {availableYears.map((yearItem) => (
                            <MenuItem key={yearItem.year} value={yearItem.year}>
                                {yearItem.year}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )}
        </Grid>
    );
};

export default YearSelector;
