import {
    Autocomplete,
    Button,
    ButtonGroup,
    Grid,
    Paper,
    TextField,
    Typography,
} from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import SkeletonForm from '../../../components/atom/SkeletonForm';
import { doSwaggerCall, useApi } from '../../../hooks/useApi';
import { useModals, MODAL_TYPE } from '../../../hooks/useModal';
import useNotify from '../../../hooks/useNotify';
import CommonButton from '../../../components/atom/CommonButton';
import { WARNING_CONTENT_RECALCULATE_TEXT } from '../../../helpers/constans';

const ConversionLogicTemplates = ({ reloadData, conversionLogics, baseUnit }) => {
    const { emissionSourceId } = useParams();
    const { notifyError, notifySuccess } = useNotify();
    const { showModal } = useModals();
    const [autocompleteRenderValue, setAutocompleteRenderValue] = useState('');
    const [templates, loading, error, setTemplates, reloadTemplates] = useApi(
        'ConversionTemplates',
        'getConversionTemplates'
    );
    const saveButtonDisabledCondition =
        autocompleteRenderValue === '' ||
        typeof autocompleteRenderValue === 'undefined' ||
        templates?.templates.map((template) => template.name).includes(autocompleteRenderValue);

    const loadAndDeleteButtonDisabledCondition = (templateNameValue) => {
        return (
            autocompleteRenderValue === '' ||
            templateNameValue === '' ||
            typeof templateNameValue === 'undefined' ||
            !templates?.templates.map((template) => template.name).includes(templateNameValue) ||
            !templates?.templates.map((template) => template.name).includes(autocompleteRenderValue)
        );
    };

    const defaultTemplate = {
        template: {
            id: null,
            name: '',
            conversion_logics: [],
            base_unit: '',
        },
    };

    useEffect(() => {
        if (error) {
            notifyError(error);
        }
    }, [error, notifyError]);

    const trimFieldIds = async (unitConversionLogic, fromTemplate) => {
        const trimmedUnitConversionLogic = fromTemplate
            ? unitConversionLogic.conversion_logics
            : unitConversionLogic;
        trimmedUnitConversionLogic.map((logic, logicIdx) => {
            logic.fields.map((field, fieldIdx) => {
                trimmedUnitConversionLogic[logicIdx].fields[fieldIdx].field_id =
                    field.field_id.trim();
                return true;
            });
            return true;
        });
        return trimmedUnitConversionLogic;
    };

    const addConversionTemplate = async (setFieldValue) => {
        const trimmedUnitConversionLogic = await trimFieldIds(conversionLogics, false);
        showModal(MODAL_TYPE.CONFIRMATION, {
            title: `Are you sure you want to add this template?`,
            warningContent: WARNING_CONTENT_RECALCULATE_TEXT,
            confirm: () => {
                doSwaggerCall(
                    'ConversionTemplates',
                    'addConversionTemplate',
                    {},
                    {
                        name: autocompleteRenderValue,
                        conversionLogics: trimmedUnitConversionLogic,
                        baseUnit,
                        sourceId: parseInt(emissionSourceId, 10),
                    }
                )
                    .then(() => {
                        reloadTemplates();
                        setFieldValue('template', defaultTemplate.template);
                        notifySuccess('Conversion template added');
                    })
                    .catch((err) => notifyError(err));
            },
        });
    };

    const loadConversionTemplate = async (values, setFieldValue) => {
        const trimmedUnitConversionLogic = await trimFieldIds(values.template, true);
        showModal(MODAL_TYPE.CONFIRMATION, {
            title: `Are you sure you want to load this template?`,
            content: `Selected template will override the current logics.`,
            warningContent: WARNING_CONTENT_RECALCULATE_TEXT,
            confirm: () => {
                doSwaggerCall(
                    'EmissionSources',
                    'editEmissionSourceConversionLogic',
                    { id: emissionSourceId },
                    {
                        unitConversionLogic: trimmedUnitConversionLogic,
                        conversionTemplateId: values.template.id,
                    }
                )
                    .then(() => {
                        reloadData();
                        setFieldValue('template', defaultTemplate.template);
                        notifySuccess('Emission source conversion logic changed');
                    })
                    .catch((err) => notifyError(err));
            },
        });
    };

    const deleteConversionTemplate = async (values) => {
        showModal(MODAL_TYPE.CONFIRMATION, {
            title: `Are you sure you want to delete this template?`,
            warningContent: WARNING_CONTENT_RECALCULATE_TEXT,
            confirm: () => {
                doSwaggerCall('ConversionTemplates', 'deleteConversionTemplate', {
                    id: values.template.id,
                })
                    .then(() => {
                        reloadTemplates();
                        notifySuccess('Conversion template deleted');
                    })
                    .catch((err) => notifyError(err));
            },
        });
    };

    return (
        <Grid container sx={{ pt: 2 }}>
            <Grid item xs={12}>
                {loading ? (
                    <SkeletonForm rows={1} />
                ) : (
                    <Paper sx={{ p: 2 }}>
                        <Typography variant="h5">Conversion templates</Typography>
                        <Formik onSubmit={() => {}} initialValues={{ ...defaultTemplate }}>
                            {({ values, setFieldValue }) => (
                                <Form>
                                    <Grid container alignItems="end">
                                        <Grid item xs={6}>
                                            <Autocomplete
                                                freeSolo
                                                options={templates?.templates.map(
                                                    (template) => template
                                                )}
                                                value={values?.template}
                                                onChange={(_, newValue) => {
                                                    if (typeof newValue === 'string') {
                                                        setFieldValue('template.name', newValue);
                                                        setAutocompleteRenderValue(newValue);
                                                    } else {
                                                        setFieldValue('template', newValue);
                                                        setAutocompleteRenderValue(newValue?.name);
                                                    }
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        onChange={(ev) => {
                                                            setAutocompleteRenderValue(
                                                                ev.target.value
                                                            );
                                                            return params.inputProps.onChange(ev);
                                                        }}
                                                        size="small"
                                                        label="Conversion template"
                                                        placeholder="Press enter to add new"
                                                        fullWidth
                                                    />
                                                )}
                                                getOptionLabel={(option) => {
                                                    return `${option?.name}`;
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <ButtonGroup fullWidth className="darkModeGroup">
                                                <CommonButton
                                                    onClick={async () => {
                                                        deleteConversionTemplate(values);
                                                    }}
                                                    disabled={loadAndDeleteButtonDisabledCondition(
                                                        values.template?.name
                                                    )}
                                                    label="Delete"
                                                    buttonType="primary"
                                                />
                                                <Button
                                                    onClick={async () => {
                                                        loadConversionTemplate(
                                                            values,
                                                            setFieldValue
                                                        );
                                                    }}
                                                    color="primary"
                                                    disabled={loadAndDeleteButtonDisabledCondition(
                                                        values.template?.name
                                                    )}
                                                >
                                                    Load
                                                </Button>
                                                <CommonButton
                                                    onClick={async () => {
                                                        addConversionTemplate(setFieldValue);
                                                    }}
                                                    disabled={saveButtonDisabledCondition}
                                                    label="Save"
                                                    buttonType="primary"
                                                />
                                            </ButtonGroup>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    </Paper>
                )}
            </Grid>
        </Grid>
    );
};

export default ConversionLogicTemplates;
