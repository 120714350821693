import React, { useEffect, useState } from 'react';
import { Grid, LinearProgress } from '@mui/material';
import useNotify from '../../../hooks/useNotify';
import { doSwaggerCall } from '../../../hooks/useApi';
import SiteBarChart from './charts/SiteBarChart';

function collectAllKeys(arrayOfObjects) {
    const keys = [];
    arrayOfObjects.forEach((obj) => {
        Object.keys(obj).forEach((key) => {
            keys.push(key);
        });
    });

    // we remove 'site' from keys since it is the id (label)
    return [...new Set(keys.filter((key) => key !== 'site'))];
}
const BySelectedSites = ({ sites, year, categories, scopes, setChartData }) => {
    const { notifyError } = useNotify();
    const [siteBarData, setSiteBarData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [siteBarKeys, setSiteBarKeys] = useState();

    useEffect(() => {
        doSwaggerCall('Dashboards', 'getDataBySitesForBarChart', {
            siteIdList: sites,
            year,
            categories,
            scopes,
        })
            .then((res) => {
                setSiteBarData(res);
                setSiteBarKeys(collectAllKeys(res.siteData));
                setChartData(res.siteData);
                setLoading(false);
            })
            .catch((error) => {
                notifyError(error);
            });
    }, [setLoading, setSiteBarData, setSiteBarKeys, sites, year, categories, scopes, setChartData]);

    return (
        <Grid item xs={12}>
            {siteBarData?.length === 0 ? (
                <Grid item>
                    {loading ? <LinearProgress sx={{ mt: 0.25 }} /> : <h5>No data available</h5>}
                </Grid>
            ) : (
                <SiteBarChart
                    data={siteBarData.siteData}
                    keys={siteBarKeys}
                    year={year}
                    indexBy="site"
                    axisLeftLegend="CO2e (mt)"
                />
            )}
        </Grid>
    );
};

export default BySelectedSites;
