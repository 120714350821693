import { saveAs } from 'file-saver';
import { utils, write } from 'xlsx';
import { doSwaggerCall } from '../hooks/useApi';
import { jsonConstants, WITHOUT_UNIT } from './esgUnitConversions';
import { ROLES } from './constans';
import { setComment } from './excelExportHelpers';

const ESG_EXPORT_LIMIT_CHUNK = 10000;

const ESG_MASTER_SHEET_HEADER_MAPPING = {
    category: 'Category',
    subcategory: 'Subcategory',
    disclosure: 'Disclosure',
    question_id: 'Question_id',
    question: 'Question',
    numeric_field_label1: 'Numeric field label',
    default_measure_numeric_value1: 'Default measure numeric value 1',
    numeric_field_label2: 'Numeric field 2 label',
    default_measure_numeric_value2: 'Default measure numeric value 2',
    numeric_field_label3: 'Numeric field 3 label',
    default_measure_numeric_value3: 'Default measure numeric value 3',
    numeric_field_label4: 'Numeric field 4 label',
    default_measure_numeric_value4: 'Default measure numeric value 4',
    numeric_field_label5: 'Numeric field 5 label',
    default_measure_numeric_value5: 'Default measure numeric value 5',
    numeric_field_label6: 'Numeric field 6 label',
    default_measure_numeric_value6: 'Default measure numeric value 6',
    text_field_label1: 'Text field label',
    text_field_label2: 'Text field 2 label',
    text_field_label3: 'Text field 3 label',
    text_field_label4: 'Text field 4 label',
    text_field_label5: 'Text field 5 label',
    text_field_label6: 'Text field 6 label',
    marked_for_deletion: 'Marked for deletion',
};
const ESG_MASTER_SHEET_FRAMEWORKS_HEADER_MAPPING = {
    framework_name: 'Framework name',
    standard: 'Standard',
    disclosure: 'Disclosure',
    question_id: 'Question id',
    internal_question: 'Internal question',
    internal_category: 'Internal category',
    internal_subcategory: 'Internal subcategory',
    internal_disclosure: 'Internal disclosure',
    guidance: 'Guidance',
};

// esg questions master export

/**
 * Generate xlsx esg data from an array of objects
 * @param {array} data array of objects to convert to xlsx rows
 * @returns header fields from object keys and rows from values
 */
export const generateXlsxEsgData = (data, secondFrameworkTab = false, thirdUnitsTab = false) => {
    // blank sheet for frameworks
    if (secondFrameworkTab && (!data || data.length === 0)) {
        return [Object.values(ESG_MASTER_SHEET_FRAMEWORKS_HEADER_MAPPING)];
    }
    if (!data || data.length === 0) {
        return [];
    }

    const newData = data;
    const headers = Object.keys(newData[0]).map((object) => {
        if (secondFrameworkTab) {
            return ESG_MASTER_SHEET_FRAMEWORKS_HEADER_MAPPING[object];
        }
        if (thirdUnitsTab) {
            return object;
        }
        return ESG_MASTER_SHEET_HEADER_MAPPING[object];
    });
    const rows = newData.map((element) => {
        return Object.values(element).map((value) => {
            if (Array.isArray(value)) {
                return value.map((e) => e.name).join('\n');
            }
            return value;
        });
    });

    return [headers, ...rows];
};

/**
 * Generate xlsx file and initiate download
 * @param {array} data array of objects to convert to xlsx rows
 */
export const exportToXlsxEsg = (data, name) => {
    const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    // 2 sheets: internal structure and frameworks
    const ws = utils.aoa_to_sheet(generateXlsxEsgData(data.mappedEsgQuestions));
    const ws2 = utils.aoa_to_sheet(generateXlsxEsgData(data.mappedEsgFrameworkQuestions, true));
    // sheet for esg conversion options (name, type)
    const esgUnitConversions = jsonConstants.map((unit) => ({
        name: unit.name,
        type: unit.type,
    }));
    const ws3 = utils.aoa_to_sheet(generateXlsxEsgData(esgUnitConversions, false, true));
    utils.sheet_add_aoa(
        ws3,
        [['This is a helper tab to list all available esg unit conversions.']],
        {
            origin: 'D1',
        }
    );
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Internal structure');
    utils.book_append_sheet(wb, ws2, 'Frameworks');
    utils.book_append_sheet(wb, ws3, 'Unit conversions');
    const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' });

    const file = new Blob([excelBuffer], { type: fileType });
    saveAs(file, `${name}${fileExtension}`);
};

// esgs export

/**
 * Generate xlsx data from an array of objects
 * @param {array} data array of objects to convert to xlsx rows
 * @returns header fields from object keys and rows from values
 */
const generateEsgXlsx = (data) => {
    if (!data || data.length === 0) {
        return [];
    }

    const newData = data;
    const headers = Object.keys(newData[0]).map((object) => object);

    const rows = newData.map((element) => {
        return Object.values(element).map((value) => {
            if (Array.isArray(value)) {
                return value.map((e) => e.name).join('\n');
            }
            return value;
        });
    });

    return [headers, ...rows];
};

// Helper function to create sheet from calculation logic
function createSheetFromEsgs(wb, esgs, fields) {
    const data = [];

    if (esgs.length === 0) {
        const line = {
            id: '',
            site: '',
            location: '',
            values: '',
            question: '',
            disclosure: '',
            subcategory: '',
            category: '',
            notes: '',
            mark_completion: '',
            completion: '',
            assigned_to: '',
            owner: '',
        };
        if (fields) {
            fields.forEach((field) => {
                line[field.label] = '';
            });
        }
        line.Marked_for_deletion = '';
        data.push(line);
    }

    for (let i = 0; i < esgs.length; i++) {
        const esg = esgs[i];
        const line = {
            id: esg.id,
            site: esg.site_name || 'Corporate',
            location: esg.location_name,
            question: esg.esg_question,
            disclosure: esg.esg_disclosure,
            subcategory: esg.esg_subcategory,
            category: esg.esg_category,
            notes: esg.notes,
            mark_completed: esg.mark_completed ? 'Yes' : 'No',
            completion: `${esg.field_filled_out}/${esg.field_count}`,
            assigned_to: esg.assignedUser?.user_email,
            owner: esg.ownerUser?.user_email,
        };
        if (fields) {
            fields.forEach((field) => {
                const foundField = esg.values.find((v) => v.id === field.id);
                const measure =
                    foundField?.measure && foundField.measure !== WITHOUT_UNIT
                        ? foundField.measure
                        : '';
                const value = foundField?.value ? foundField.value : '';
                line[field.label] = foundField ? `${value} ${measure}` : '';
            });
        }
        line.Marked_for_deletion = '';
        data.push(line);
    }

    const ws = utils.aoa_to_sheet(generateEsgXlsx(data));
    setComment(ws, 'J1', 'Ignore this field, it calculates automatically');
    setComment(ws, 'K1', 'Use email address');
    setComment(ws, 'L1', 'Use email address');
    utils.book_append_sheet(wb, ws, `ESGs`);
}

// Helper function to collect esgs for esg question
export async function getEsgsForOneQuestion(year, esgQuestion, siteIdList) {
    let esgs = [];
    let total = ESG_EXPORT_LIMIT_CHUNK + 1;

    for (let page = 0; page * ESG_EXPORT_LIMIT_CHUNK < total; page++) {
        const esgChunk = await doSwaggerCall('Esgs', 'getEsgs', {
            year,
            esgQuestionId: esgQuestion,
            limit: ESG_EXPORT_LIMIT_CHUNK,
            offset: page * ESG_EXPORT_LIMIT_CHUNK,
            siteIdList,
        });
        total = esgChunk.total;
        esgs = [...esgs, ...esgChunk.esgs];
    }
    return esgs;
}

// Generate xlsx file and initiate download
export async function esgExport(esgQuestion, year, siteIdList, companyId) {
    const question = await doSwaggerCall('EsgQuestions', 'getEsgQuestion', {
        id: esgQuestion,
    });
    const esgs = await getEsgsForOneQuestion(year, esgQuestion, siteIdList);

    // cannot exceed 31 chars, need truncate with substring
    let truncatedName = `${year}_${question.name}`;
    if (truncatedName.length > 31) {
        truncatedName = truncatedName.substring(0, 31);
    }
    // check ' / ' in sheet name, need replace to '-'
    let replacedName = truncatedName;
    if (truncatedName.includes(' / ')) {
        replacedName = truncatedName.replace(/ \/ /g, '-');
    }

    const wb = utils.book_new();
    createSheetFromEsgs(wb, esgs, question.fields);

    // sheet for esg conversion options (name, type)
    const esgUnitConversions = jsonConstants.map((unit) => ({
        name: unit.name,
        type: unit.type,
    }));
    const ws2 = utils.aoa_to_sheet(generateXlsxEsgData(esgUnitConversions, false, true));
    utils.sheet_add_aoa(
        ws2,
        [['This is a helper tab to list all available esg unit conversions.']],
        {
            origin: 'D1',
        }
    );
    utils.book_append_sheet(wb, ws2, 'Unit conversions');

    // extra helper sheets for sites and users
    // get sites for helper tab
    const sites = await doSwaggerCall('Sites', 'getSitesInCompany', {
        id: companyId,
        limit: 1000,
    });

    const users = await doSwaggerCall('Users', 'getAllUsers', { pageSize: 1000 });
    const sitesData = sites.sites
        .map((site) => ({ name: site.name }))
        .sort((a, b) => a.name.localeCompare(b.name))
        .concat({ name: 'Corporate' });

    const usersData = users.users
        .filter((user) => user.role !== ROLES.SUPERADMIN)
        .map((user) => ({
            name: user.name,
            email: user.email,
        }))
        .sort((a, b) => a.name.localeCompare(b.name));
    const sheets = [
        {
            data: sitesData,
            name: 'Sites',
            helperText: 'This is a helper tab to list all available sites.',
        },
        {
            data: usersData,
            name: 'Users',
            helperText: 'This is a helper tab to list all available users.',
        },
    ];
    sheets.forEach((sheet) => {
        const ws = utils.aoa_to_sheet(generateEsgXlsx(sheet.data));
        utils.sheet_add_aoa(ws, [[sheet.helperText]], { origin: 'D1' });
        utils.book_append_sheet(wb, ws, sheet.name);
    });

    const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' });
    const file = new Blob([excelBuffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    });
    saveAs(file, `${replacedName}.xlsx`);
}

// all ESGs export

/**
 * Generate xlsx file and initiate download
 * @param {array} data array of objects to convert to xlsx rows
 */
export const allEsgsExport = (data, name) => {
    const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const ws = utils.aoa_to_sheet(generateEsgXlsx(data));

    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'ESGs');
    const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' });

    const file = new Blob([excelBuffer], { type: fileType });
    saveAs(file, `${name}${fileExtension}`);
};
