import { FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ComponentTitle from '../../components/ComponentTitle';
import { useApi } from '../../hooks/useApi';
import useNotify from '../../hooks/useNotify';
import useSorting from '../../hooks/useSorting';
import VarianceTrackerTable from './components/VarianceTrackerTable';
import SearchInput from '../../components/atom/SearchInput';
import { useGlobalFilters } from '../../hooks/useGlobalFilters';

const VarianceTrackerPage = () => {
    const { companyId } = useParams();
    const { notifyError } = useNotify();
    const { sort, sortDir, setSorting } = useSorting(['created_at', 'ASC']);
    const [fromYear, setFromYear] = useState(2022);
    const [toYear, setToYear] = useState(2023);
    const [search, setSearch] = useState('');
    const { availableYears } = useGlobalFilters();

    const [data, loading, error, setData, reloadData] = useApi('Categories', 'getVarianceTracker', {
        id: companyId,
        years: [fromYear, toYear],
        search,
    });

    useEffect(() => {
        if (error) {
            notifyError(error);
        }
    }, [error, notifyError]);

    return (
        <Grid container alignContent="flex-start">
            <Grid item xs={12} style={{ flex: '1 1 0' }}>
                <SearchInput fullWidth search={search} setSearch={setSearch} />
            </Grid>
            <Grid container item xs={12} justifyContent="space-between" spacing={0}>
                <Grid item sx={{ mb: 2 }}>
                    <ComponentTitle variant="h4" title="Variance Tracker" />
                </Grid>
                <Grid item>Flag limit by company: {data?.flagLimit} %</Grid>
                <Grid container item xs={12} spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            Categories and subcategories different from year to year
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <InputLabel id="year-select">From year</InputLabel>
                            <Select
                                fullWidth
                                labelId="year-select"
                                id="demo-simple-from-select"
                                value={fromYear}
                                size="small"
                                label="Year"
                                onChange={(e) => {
                                    setFromYear(parseInt(e.target.value, 10));
                                }}
                            >
                                {availableYears.map((yearItem) => (
                                    <MenuItem key={yearItem.year} value={yearItem.year}>
                                        {yearItem.year}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <InputLabel id="to-year-select">To year</InputLabel>
                            <Select
                                fullWidth
                                labelId="to-year-select"
                                id="demo-simple-to-select"
                                value={toYear}
                                size="small"
                                label="Year"
                                onChange={(e) => {
                                    setToYear(parseInt(e.target.value, 10));
                                }}
                            >
                                {availableYears.map((yearItem) => (
                                    <MenuItem key={yearItem.year} value={yearItem.year}>
                                        {yearItem.year}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                {fromYear === toYear && (
                    <Grid container item xs={12} spacing={2}>
                        <Grid item>
                            The same year is selected for both, please choose a different one.
                        </Grid>
                    </Grid>
                )}
            </Grid>
            <VarianceTrackerTable
                rows={data?.varianceTrackerData}
                loading={loading}
                reloadData={reloadData}
                sort={sort}
                sortDir={sortDir}
                setSorting={setSorting}
            />
        </Grid>
    );
};

export default VarianceTrackerPage;
