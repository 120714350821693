import { Grid, Typography } from '@mui/material';
import React from 'react';
import EmissionBox from '../../../components/atom/EmissionBox';

const SurveyResultSummary = ({ survey, status }) => {
    return (
        <>
            <Grid item xs={12}>
                <Typography variant="subtitle1">{`Survey status: ${status}`}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <EmissionBox key="overall" title="Total CO2e" co2={survey?.co2e} />
                    {(survey?.perScope || []).map((e) => (
                        <EmissionBox key={e.scopeId} title={`Scope ${e.scopeId}`} co2={e.co2e} />
                    ))}
                </Grid>
            </Grid>
        </>
    );
};

export default SurveyResultSummary;
