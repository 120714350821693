import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import TargetPickerCategoryLevel from './TargetPickerCategoryLevel';
import CustomTreeItem from '../../../../../components/atom/CustomTreeItem';

function TargetPickerScopeLevel({ checkedData, scope, setCheckedData }) {
    return (
        <CustomTreeItem
            nodeId={`scope - ${scope.id}`}
            label={
                <FormControlLabel
                    label={scope.name}
                    labelPlacement="end"
                    control={
                        <Checkbox
                            key={`${scope.id} - ${scope.name}`}
                            checked={
                                !!checkedData.find((c) => c.id === scope.id && c.type === 'scope')
                            }
                            onChange={() => {
                                setCheckedData((old) => {
                                    const findCurrent = old.find(
                                        (item) => item.id === scope.id && item.type === 'scope'
                                    );
                                    if (findCurrent) {
                                        return old.filter(
                                            (data) =>
                                                data !== findCurrent &&
                                                data.scopeId !== findCurrent.id
                                        );
                                    }

                                    return [
                                        ...old,
                                        {
                                            type: 'scope',
                                            id: scope.id,
                                        },
                                        ...scope.children
                                            .map((e) => ({
                                                type: 'category',
                                                id: e.id,
                                                scopeId: scope.id,
                                            }))
                                            .filter(
                                                (f) =>
                                                    !old
                                                        .filter((o) => o.scopeId === f.scopeId)
                                                        .map((i) => i.id)
                                                        .includes(f.id)
                                            ),
                                    ];
                                });
                            }}
                        />
                    }
                />
            }
        >
            {scope.children.map((category) => (
                <TargetPickerCategoryLevel
                    key={`${category.id} - ${category.name}`}
                    checkedData={checkedData}
                    category={category}
                    scope={scope}
                    setCheckedData={setCheckedData}
                />
            ))}
        </CustomTreeItem>
    );
}

export default TargetPickerScopeLevel;
