import { Grid, TextField } from '@mui/material';
import React from 'react';
import SurveyQuestionHeadline from '../components/SurveyQuestionHeadline';
import {
    withFormikContext,
    withStepAndValidation,
    withSurveySection,
} from '../components/SurveyStep';

function QNote({ values, setFieldValue }) {
    return (
        <>
            <Grid item xs={12}>
                <SurveyQuestionHeadline>
                    Is there any other relevant information you’d like to provide?
                </SurveyQuestionHeadline>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    value={values.notes?.note}
                    label="Notes"
                    autoComplete="off"
                    onChange={(e) => {
                        setFieldValue('notes.note', e.target.value);
                    }}
                    multiline
                />
            </Grid>
        </>
    );
}

export default withStepAndValidation(
    withSurveySection(withFormikContext(QNote)),
    6,
    ['notes.note']
    // no validation
    // always active, no need to set that check
);
