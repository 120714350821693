import React, { useEffect, useState } from 'react';
import { IconButton, InputAdornment, Slider, TableCell, TableRow, Tooltip } from '@mui/material';
import { LockOpen as LockOpenIcon, Lock as LockIcon } from '@mui/icons-material';
import WarningIcon from '@mui/icons-material/Warning';
import { formatNumber } from '../../../helpers/convertUnits';
import FloatInput from '../../../components/atom/FloatInput';
import ScenarioLineActions from './ScenarioLineActions';

function ScenarioLine({
    id,
    name,
    baseline,
    target,
    setTarget,
    locked,
    setLocked,
    navigateToChildren,
    sourceLevel,
    baselineUsage,
    baseUnit,
    note,
    setNote,
    currentPath,
    scenarioItem,
}) {
    // Percentages are calculated here, since we only show this for the user.
    // Please keep it here, do not try to move it out from here :D
    const [percentage, setPercentage] = useState(() => {
        if (baseline === 0) {
            return 0;
        }
        return (target / baseline) * 100;
    });
    const [percentageSliderIntermediateValue, setPercentageSliderIntermediateValue] =
        useState(percentage);

    function setPercentageSliderIntermediateValueFromTarget(newTarget) {
        if (baseline === 0) {
            return setPercentageSliderIntermediateValue(0);
        }
        setPercentageSliderIntermediateValue((newTarget / baseline) * 100);
    }

    // on the target / baseline change, we should recalculate the percentage
    useEffect(() => {
        if (baseline === 0) {
            setPercentage(0);
            setPercentageSliderIntermediateValue(0);
            return;
        }
        setPercentageSliderIntermediateValue((target / baseline) * 100);
        return setPercentage((target / baseline) * 100);
    }, [target, baseline, setPercentage, setPercentageSliderIntermediateValue]);

    const targetUsage = (baselineUsage / baseline) * target || 0;
    const nameTableCellWidth = sourceLevel ? '20%' : '30%';
    const tableCellWidth = sourceLevel ? '10%' : '15%';
    const reductionTableCellWidth = sourceLevel ? '10%' : '15%';
    const sliderTableCellWidth = sourceLevel ? '20%' : '25%';

    return (
        <>
            <TableRow key={id} className="removeBorderBottom">
                <TableCell width={nameTableCellWidth}>
                    <IconButton
                        onClick={() => {
                            setLocked(!locked);
                        }}
                        title={locked ? 'locked' : 'unlocked'}
                    >
                        {locked ? <LockIcon /> : <LockOpenIcon />}
                    </IconButton>
                    <span
                        style={{ cursor: navigateToChildren ? 'pointer' : 'auto' }}
                        onClick={navigateToChildren === false ? () => {} : navigateToChildren}
                    >
                        {name}
                    </span>
                    {100 - percentage < 0 && (
                        <Tooltip title="Reduction percentage is negative" placement="left">
                            <IconButton disableRipple>
                                <WarningIcon style={{ color: 'red' }} />
                            </IconButton>
                        </Tooltip>
                    )}
                </TableCell>
                <TableCell width={tableCellWidth}>{formatNumber(baseline)}</TableCell>
                <TableCell width={tableCellWidth}>{formatNumber(target)}</TableCell>
                {sourceLevel && (
                    <>
                        <TableCell width={tableCellWidth}>
                            {formatNumber(baselineUsage)} ({baseUnit})
                        </TableCell>
                        <TableCell width={tableCellWidth}>
                            {formatNumber(targetUsage)} ({baseUnit})
                        </TableCell>
                    </>
                )}
                <TableCell width={reductionTableCellWidth}>
                    <FloatInput
                        fullWidth
                        label="Reduction"
                        type="number"
                        value={Math.round((100 - percentageSliderIntermediateValue) * 10) / 10}
                        positiveOnly={false}
                        onChange={(ev) => {
                            if (Number.isNaN(parseFloat(ev.target.rawValue))) {
                                return;
                            }
                            setPercentageSliderIntermediateValue(
                                100 - parseFloat(ev.target.rawValue)
                            );
                        }}
                        onBlur={() => {
                            const newTarget = setTarget(
                                (baseline * percentageSliderIntermediateValue) / 100
                            );
                            setPercentageSliderIntermediateValueFromTarget(newTarget);
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            disabled: locked,
                        }}
                    />
                </TableCell>
                <TableCell width={sliderTableCellWidth} sx={{ p: '20px 25px 20px 15px' }}>
                    <Slider
                        aria-label="Default"
                        valueLabelDisplay="auto"
                        value={100 - percentageSliderIntermediateValue}
                        valueLabelFormat={(value) => {
                            return Math.round(value);
                        }}
                        min={0}
                        max={100}
                        onChange={(ev) => {
                            setPercentageSliderIntermediateValue(100 - ev.target.value);
                        }}
                        onChangeCommitted={(ev, value) => {
                            // to avoid setting it from an effect, since it can lead to a loop
                            const newTarget = setTarget(
                                (baseline * (100 - parseFloat(value))) / 100
                            );
                            setPercentageSliderIntermediateValueFromTarget(newTarget);
                        }}
                        disabled={locked}
                    />
                </TableCell>
            </TableRow>
            <ScenarioLineActions
                scenarioItem={scenarioItem}
                note={note}
                setNote={setNote}
                id={id}
                currentPath={currentPath}
                sourceLevel={sourceLevel}
            />
        </>
    );
}

export default ScenarioLine;
