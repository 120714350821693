import { Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import React from 'react';

const SurveyResultNetzero = ({ survey }) => {
    return (
        <>
            <Grid item xs={12}>
                <Typography variant="subtitle1">The company has netzero target?</Typography>
                <Typography variant="body1">{survey.netzero ? 'Yes' : 'No'}</Typography>
            </Grid>
            {survey.netzero && (
                <Grid item xs={12} sx={{ pt: 2 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Scope</TableCell>
                                <TableCell>Year</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {survey.netzeroTargets.map((target) => (
                                <TableRow>
                                    <TableCell>{target.scope}</TableCell>
                                    <TableCell>{target.year}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Grid>
            )}
        </>
    );
};

export default SurveyResultNetzero;
