import React, { useContext, useState, useCallback, useEffect, useRef, useMemo } from 'react';
import { doSwaggerCall } from './useApi';
import { useAuth } from './useAuth';

const NotificationsContext = React.createContext();

export function NotificationsContextProvider({ children }) {
    const [drawerOpen, setDrawerOpen] = useState(false);

    // storage for dynamic user settings (e.g. results view mode on different pages)
    const [config, setConfig] = useState({});

    // header notifications (the bell with the count badge)
    const { user } = useAuth();
    const [notifications, setNotifications] = useState(false);
    const checkVisibility = () => document.visibilityState === 'visible';
    const notifTimer = useRef();

    // is the current browser tab active?
    const [isTabVisible, setIsTabVisible] = useState(checkVisibility);
    useEffect(() => {
        const onVisibilityChange = () => setIsTabVisible(checkVisibility());
        document.addEventListener('visibilitychange', onVisibilityChange);
        return () => {
            document.removeEventListener('visibilitychange', onVisibilityChange);
        };
    }, [setIsTabVisible]);

    const loadNotifications = useCallback(() => {
        if (!user.role) {
            return;
        }

        clearTimeout(notifTimer.current);

        doSwaggerCall('Notifications', 'getNotifications', { onlyUnseen: true })
            .then((res) => {
                // just to make sure there are no overlapping timing events
                clearTimeout(notifTimer.current);
                // repeat call every 15sec (after the last received response)
                notifTimer.current = setTimeout(loadNotifications, 15000);

                setNotifications((n) => {
                    if (JSON.stringify(n) === JSON.stringify(res)) {
                        // nothing new... (return the old, no new render will be triggered)
                        return n;
                    }
                    return res;
                });
            })
            .catch(() => console.log('Unable to fetch notifications.'));
    }, [user, notifTimer]);

    useEffect(() => {
        if (isTabVisible) {
            // first load (or once when tab gets visible again)
            loadNotifications();
        } else {
            // browser window or tab turned inactive
            clearTimeout(notifTimer.current);
        }
        // unmount
        return () => clearTimeout(notifTimer.current);
    }, [loadNotifications, isTabVisible, notifTimer]);

    // the return of this context memoized
    const shared = useMemo(() => {
        const getConf = (key, def = null) => (key in config ? config[key] : def);
        const setConf = (key, val) => setConfig((oldConfig) => ({ ...oldConfig, [key]: val }));

        return {
            drawerOpen,
            setDrawerOpen,
            getConf,
            setConf,
            notifications,
            loadNotifications,
            isTabVisible,
        };
    }, [config, drawerOpen, isTabVisible, loadNotifications, notifications, setDrawerOpen]);

    return <NotificationsContext.Provider value={shared}>{children}</NotificationsContext.Provider>;
}

NotificationsContext.displayName = 'NotificationsContext';

/**
 * Notifications context
 * @returns {{notifications, setNotifications}|unknown}
 */
export function useNotifications() {
    return useContext(NotificationsContext);
}
