import React from 'react';
import { Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { formatNumber } from '../../../helpers/convertUnits';

function mapDatalineToFields(dataline, source) {
    // calculationType is empty, it's the basic case
    if (dataline.calculationType === null) {
        return `${formatNumber(dataline.amount)} ${source?.baseUnit}`;
    }

    return Object.entries(dataline.fields).map((field) => {
        return (
            <p>
                {field[0]}: {field[1]}
            </p>
        );
    });
}
function SurveyDatalines({ survey }) {
    const dataLines = survey?.details?.datalines || [];

    if (dataLines.length === 0) {
        return null;
    }

    return (
        <Grid item xs={12}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Source</TableCell>
                        <TableCell>Fields</TableCell>
                        <TableCell>Location</TableCell>
                        <TableCell align="right">C02e (mt)</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {dataLines.map((dataline, idx) => {
                        const source = survey?.emissionSources?.find((s) => {
                            return s.id === dataline.emissionSourceId;
                        });
                        const location = survey?.locations?.find((l) => {
                            return l.id === dataline.locationId;
                        });
                        return (
                            <TableRow
                                key={idx}
                                style={dataline?.ignored === true ? { opacity: 0.4 } : {}}
                            >
                                <TableCell>
                                    {source ? source?.name : `Source #${dataline.emissionSourceId}`}
                                    <br />
                                    <small>{source?.hierarchy}</small>
                                </TableCell>
                                <TableCell>{mapDatalineToFields(dataline, source)}</TableCell>
                                <TableCell>
                                    {location ? location?.name : `Unknown #${dataline.locationId}`}
                                </TableCell>
                                <TableCell align="right">
                                    {dataline?.ignored === true
                                        ? 'Ignored'
                                        : formatNumber(dataline.co2e)}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </Grid>
    );
}

export default SurveyDatalines;
