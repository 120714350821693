/**
 * Sorts an array of objects by a numeric property
 * @param {Array} sortBy - Array of objects to sort
 * @returns {Array} - Sorted array
 */

export default function numericSort(sortBy) {
    return sortBy.sort((a, b) =>
        a.name.localeCompare(b.name, undefined, {
            numeric: true,
            sensitivity: 'base',
        })
    );
}
