import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { ModalContextProvider } from './hooks/useModal';
import { AuthContextProvider } from './hooks/useAuth';
import { GlobalFilterContextProvider } from './hooks/useGlobalFilters';
import theme from './theme';
import { DarkModeContextProvider, useDarkMode } from './hooks/useDarkMode';
import { NotificationsContextProvider } from './hooks/useNotifications';
import { EsgFilterContextProvider } from './hooks/useEsgFilters';
import { EsgFrameworkFilterContextProvider } from './hooks/useEsgFrameworkFilters';
import { GuideBoxSidebarContextProvider } from './hooks/useGuideBoxSidebar';

const CustomThemeProvider = ({ children }) => {
    const { darkMode } = useDarkMode();
    const customTheme = theme({ darkMode });
    return <ThemeProvider theme={customTheme}>{children}</ThemeProvider>;
};

const Providers = ({ children }) => {
    return (
        /*  <React.StrictMode> */ // TODO: doublecheck issue with beautiful dnd react v18
        <DarkModeContextProvider>
            <CustomThemeProvider>
                <CssBaseline />
                <BrowserRouter>
                    <QueryParamProvider adapter={ReactRouter6Adapter}>
                        <SnackbarProvider>
                            <GlobalFilterContextProvider>
                                <EsgFilterContextProvider>
                                    <EsgFrameworkFilterContextProvider>
                                        <GuideBoxSidebarContextProvider>
                                            <AuthContextProvider>
                                                <NotificationsContextProvider>
                                                    <ModalContextProvider>
                                                        {children}
                                                    </ModalContextProvider>
                                                </NotificationsContextProvider>
                                            </AuthContextProvider>
                                        </GuideBoxSidebarContextProvider>
                                    </EsgFrameworkFilterContextProvider>
                                </EsgFilterContextProvider>
                            </GlobalFilterContextProvider>
                        </SnackbarProvider>
                    </QueryParamProvider>
                </BrowserRouter>
            </CustomThemeProvider>
        </DarkModeContextProvider>
        /*         </React.StrictMode>
         */
    );
};

export default Providers;
