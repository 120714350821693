/**
 * Helper function to filter data by selected scopes
 *
 * @param selected selected scope ids
 * @param targetScopes target scopes
 */
export default function filterTargetScopes(selected, targetScopes) {
    const filteredTargetScopes = [];
    selected.forEach((s) => {
        const foundScope = targetScopes.find((e) => e.id === s);
        if (typeof foundScope !== 'undefined') {
            filteredTargetScopes.push({
                ...foundScope,
            });
        }
    });
    return filteredTargetScopes.sort((a, b) => a.order - b.order);
}
