import {
    Button,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
} from '@mui/material';
import React, { useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useImpSurveyStep, withFormikContext } from './ImpSurveyStep';
import SurveyQuestionHeadline from '../../survey/components/SurveyQuestionHeadline';
import ImpSurveySection from './ImpSurveySection';
import { MODAL_TYPE, useModals } from '../../../hooks/useModal';
import SurveyQuestionSubheader from './SurveyQuestionSubheader';

const WIDTH_FOR_ROWS = [45, 50, 5];

function QListOfRoles({ values, setFieldValue, sectionIndex, formikKey, subheader, columns, q }) {
    const { step, addFieldIntoStep } = useImpSurveyStep();
    const { showModal } = useModals();
    useEffect(() => {
        addFieldIntoStep(`answers.${formikKey}`, sectionIndex + 1);
    }, [addFieldIntoStep, formikKey, sectionIndex]);

    if (step !== sectionIndex + 1) {
        return null;
    }

    const deleteRow = (idx) => {
        const newFields = [...values.answers[formikKey].fields];
        newFields.splice(idx, 1);
        setFieldValue(`answers.${formikKey}`, {
            fields: newFields,
            others: values.answers[formikKey].others,
        });
    };

    return (
        <ImpSurveySection>
            <Grid item xs={12}>
                <SurveyQuestionHeadline>{q}</SurveyQuestionHeadline>
                {subheader && <SurveyQuestionSubheader subheader={subheader} />}
                <Table>
                    <TableHead>
                        <TableRow>
                            {columns.map((column, i) => (
                                <TableCell key={column} width={`${WIDTH_FOR_ROWS[i]}%`}>
                                    {column}
                                </TableCell>
                            ))}
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {values?.answers[formikKey].fields?.map((person, idx) => (
                            <TableRow key={idx}>
                                <TableCell>
                                    <TextField
                                        fullWidth
                                        autoComplete="off"
                                        value={person.name}
                                        label={columns[0]}
                                        onChange={(e) => {
                                            setFieldValue(
                                                `answers.${formikKey}.fields[${idx}].name`,
                                                e.target.value
                                            );
                                        }}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        fullWidth
                                        autoComplete="off"
                                        value={person.responsibilities}
                                        label={columns[1]}
                                        onChange={(e) => {
                                            setFieldValue(
                                                `answers.${formikKey}.fields[${idx}].responsibilities`,
                                                e.target.value
                                            );
                                        }}
                                    />
                                </TableCell>
                                <TableCell>
                                    <IconButton
                                        onClick={() => {
                                            showModal(MODAL_TYPE.CONFIRMATION, {
                                                title: `Are you sure you want to remove this row?`,
                                                content: 'This action is irreversible.',
                                                confirm: () => deleteRow(idx),
                                            });
                                        }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                        <TableCell
                            colSpan={3}
                            sx={{
                                pb: 0,
                            }}
                        >
                            <Button
                                variant="text"
                                startIcon={<AddIcon />}
                                onClick={() => {
                                    setFieldValue(`answers.${formikKey}`, {
                                        fields: [
                                            ...(values.answers[formikKey]?.fields ?? []),
                                            {
                                                name: '',
                                                responsibilities: '',
                                            },
                                        ],
                                        others: values.answers[formikKey]?.others,
                                    });
                                }}
                            >
                                Add Row
                            </Button>
                        </TableCell>
                        <TableRow>
                            <TableCell colSpan={3}>
                                <TextField
                                    fullWidth
                                    value={values.answers[formikKey].others}
                                    label="Other Remarks"
                                    autoComplete="off"
                                    onChange={(e) => {
                                        setFieldValue(
                                            `answers.${formikKey}.others`,
                                            e.target.value
                                        );
                                    }}
                                />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Grid>
        </ImpSurveySection>
    );
}

export default withFormikContext(QListOfRoles);
