import { Divider, Grid, IconButton, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import Add from '@mui/icons-material/Add';
import {
    withFormikContext,
    withStepAndValidation,
    withSurveySection,
} from '../components/SurveyStep';
import FourthStepRow from '../components/FourthStepRow';
import { doSwaggerCall } from '../../../hooks/useApi';

function QCalculator({ values, setFieldValue, token }) {
    const datalines = values?.details?.datalines || [];
    const [emissionSources, setEmissionSources] = useState([]);
    const [missingEmissionSourceIds, setMissingEmissionSourceIds] = useState([]);
    const [allCategorySubcategory, setAllCategorySubcategory] = useState([]);

    // scope 1 and 2 are filled in step 3, so we need to fill scope 3
    const onlyNeedScope3 =
        (values?.details?.hasScope3Cat1Report === false ||
            values?.details?.hasScope3Cat5Report === false) &&
        values?.details?.hasScope12FullReport === true;
    // questions for scope 3 from previous step, separate two questions for category 1 and category 5
    const onlyNeedScope3Cat1 =
        values?.details?.hasScope3Cat1Report === false &&
        values?.details?.hasScope3Cat5Report === true;
    const onlyNeedScope3Cat5 =
        values?.details?.hasScope3Cat1Report === true &&
        values?.details?.hasScope3Cat5Report === false;
    let catId = null;
    if (onlyNeedScope3Cat1) {
        catId = 1;
    }
    if (onlyNeedScope3Cat5) {
        catId = 5;
    }

    // get all sources
    useEffect(() => {
        doSwaggerCall('EmissionSources', 'getEmissionSourcesForSurvey', {
            token,
            catId,
        }).then((e) => {
            // group response by category and subcategory
            const grouped = {};
            // depending on answers from the previous step, we need to filter the sources
            const sourcesData = onlyNeedScope3
                ? e.emissionSources.filter((s) => s.scope_id === 3)
                : e.emissionSources;
            sourcesData.forEach((source) => {
                if (!grouped[`${source.category_id}`]) {
                    grouped[`${source.category_id}`] = [];
                }
                grouped[`${source.category_id}`].push(source.id);
            });

            const onlyOnceId = [];
            setAllCategorySubcategory(
                sourcesData
                    .map((f) => {
                        const key = `${f.category_id}`;
                        if (onlyOnceId.includes(key)) {
                            return null;
                        }
                        onlyOnceId.push(key);
                        return {
                            id: `${f.category_id}`,
                            name: `${f.category_name}`,
                            emissionSources: grouped[`${f.category_id}`],
                            categoryId: f.category_id,
                            scopeId: f.scope_id,
                            scopeName: f.scope_name,
                        };
                    })
                    .filter((s) => s)
            );
        });
    }, [token, setAllCategorySubcategory, onlyNeedScope3]);

    // id list
    useEffect(() => {
        setMissingEmissionSourceIds(
            datalines
                .map((e) => e.emissionSourceId)
                .filter((e) => !emissionSources.map((f) => f.id).includes(e))
                .sort()
        );
    }, [datalines, setMissingEmissionSourceIds, emissionSources]);

    const searchParamMemo = useMemo(() => {
        return {
            token,
        };
    }, [token]);

    // set emission sources
    useEffect(() => {
        // missing detailed data on these source: missingEmissionSourceData.id
        if (missingEmissionSourceIds.length === 0 || missingEmissionSourceIds.includes(null)) {
            return;
        }
        doSwaggerCall('EmissionSources', 'getEmissionSourcesForSurvey', {
            token,
            idList: missingEmissionSourceIds,
            catId,
        }).then((res) => {
            // we need to check if everything from the missing list is in the response
            // if something is not in the response, we need to "mock" it with a null
            // so that the datalines can be rendered
            const missingIds = missingEmissionSourceIds.filter(
                (e) => !res.emissionSources.map((f) => f.id).includes(e)
            );
            setEmissionSources((old) => [
                ...old,
                ...res.emissionSources,
                ...missingIds.map((e) => ({ id: e })),
            ]);
        });
    }, [missingEmissionSourceIds, setEmissionSources, datalines]);

    return (
        <Grid item xs={12}>
            {allCategorySubcategory.map((oneCategorySubcategory, index) => {
                return (
                    <>
                        {oneCategorySubcategory.scopeId !==
                            allCategorySubcategory[index - 1]?.scopeId && (
                            <Grid item xs={12} sx={{ pt: 1, pb: 3 }}>
                                <Typography variant="h4" style={{ fontWeight: 'bold' }}>
                                    {oneCategorySubcategory.scopeName}
                                </Typography>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Typography variant="h6">{oneCategorySubcategory.name}</Typography>
                        </Grid>
                        {datalines
                            ?.filter((e) => oneCategorySubcategory.categoryId === e.categoryId)
                            .map(
                                (
                                    {
                                        id,
                                        calculationType,
                                        fields,
                                        amount,
                                        emissionSourceId,
                                        locationId,
                                    },
                                    idx
                                ) => {
                                    const emissionSource = emissionSources.find(
                                        (e) => e.id === emissionSourceId
                                    );
                                    return (
                                        <FourthStepRow
                                            idx={idx}
                                            datalines={datalines}
                                            id={id}
                                            calculationType={calculationType}
                                            fields={fields}
                                            amount={amount}
                                            emissionSourceId={emissionSourceId}
                                            locationId={locationId}
                                            emissionSource={emissionSource}
                                            setFieldValue={setFieldValue}
                                            searchParamMemo={searchParamMemo}
                                            categoryId={oneCategorySubcategory.categoryId}
                                        />
                                    );
                                }
                            )}
                        <Grid sx={{ mt: 2, mb: 0 }}>
                            <Divider sx={{ mt: 2, mb: 0, ml: -3, mr: -3 }}>
                                <IconButton
                                    style={{ background: 'rgba(0, 0, 0, 0.08)' }}
                                    onClick={() => {
                                        setFieldValue('details.datalines', [
                                            ...datalines,
                                            {
                                                id: `${Math.random()}`.substring(2),
                                                calculationType: null,
                                                fields: [],
                                                amount: 0,
                                                emissionSourceId: null,
                                                locationId: null,
                                                scopeId: oneCategorySubcategory.scopeId,
                                                categoryId: oneCategorySubcategory.categoryId,
                                            },
                                        ]);
                                    }}
                                >
                                    <Add />
                                </IconButton>
                            </Divider>
                        </Grid>
                    </>
                );
            })}
        </Grid>
    );
}

export default withStepAndValidation(
    withSurveySection(withFormikContext(QCalculator)),
    5,
    ['details.datalines'], // no fields
    (values, errors) => {
        const datalines = values?.details?.datalines || [];
        if (datalines.some((e) => e.locationId === null)) {
            // eslint-disable-next-line no-param-reassign
            errors['details.datalines'] = 'Please select a location.';
        }
        if (datalines.some((e) => e.emissionSourceId === null)) {
            // eslint-disable-next-line no-param-reassign
            errors['details.datalines'] = 'Please select an emission source.';
        }
        if (datalines.length === 0) {
            // eslint-disable-next-line no-param-reassign
            errors['details.datalines'] = 'Please add at least one emission source.';
        }
        const uniqueEmissionSources = datalines.filter(
            (v, i, a) =>
                a.findIndex(
                    (t) =>
                        t.emissionSourceId === v.emissionSourceId && t.locationId === v.locationId
                ) === i
        );
        if (uniqueEmissionSources.length !== datalines.length) {
            // eslint-disable-next-line no-param-reassign
            errors['details.datalines'] =
                'Please select unique emission sources with same location.';
        }

        return errors;
    },
    (values) => {
        // Quanitity based report is filled out fully
        const filledUpScopeBasedReport =
            values?.details?.hasScope3Cat1Report === true &&
            values?.details?.hasScope3Cat5Report === true &&
            values?.details?.hasScope12FullReport === true;
        // Directly associated emission is given
        const haveExactData = values?.details?.haveExactData === true;

        // none of the previous steps are not fully filled out
        const anyOfItIsUndefined = [
            // hasScope3Cat1Report and hasScope3Cat5Report answer only needs to be validated if hasScope12FullReport is set
            values?.details?.hasScope12FullReport === true
                ? values?.details?.hasScope3Cat1Report
                : true,
            values?.details?.hasScope12FullReport === true
                ? values?.details?.hasScope3Cat5Report
                : true,
            values?.details?.hasScope12FullReport,
            values?.details?.haveExactData,
        ].some((e) => typeof e === 'undefined');

        return (
            filledUpScopeBasedReport === false &&
            haveExactData === false &&
            anyOfItIsUndefined === false
        );
    }
);
