import React from 'react';
import {
    Button,
    Container,
    Divider,
    Grid,
    IconButton,
    LinearProgress,
    Paper,
    Typography,
} from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import TestResponsiveRadialBar from './components/TestResponsiveRadialBar';
import TestResponsiveLine from './components/TestResponsiveLine';

export default function NewThemeTestPage() {
    return (
        <Container>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container spacing={2} alignContent="flex-start">
                        <Grid container item xs={12} justifyContent="space-between" spacing={0}>
                            <Grid item>
                                <Typography variant="h4">Good morning</Typography>
                            </Grid>
                            <Grid item>
                                <Grid
                                    item
                                    container
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                >
                                    <Grid item>
                                        <Button>Create Group</Button>
                                    </Grid>
                                    <Grid item>
                                        <Button>Add User</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid
                        container
                        spacing={2}
                        sx={{
                            height: '35rem',
                        }}
                    >
                        <Grid item xs={8}>
                            <Paper sx={{ p: 2, height: '30rem' }}>
                                <Typography variant="subtitle1">Network throughput</Typography>
                                <TestResponsiveLine />
                            </Paper>
                        </Grid>
                        <Grid item xs={4}>
                            <Paper sx={{ p: 2, height: '30rem' }}>
                                <Typography variant="subtitle1">Wifi points</Typography>
                                <TestResponsiveRadialBar testStatus={false} />
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <Paper sx={{ p: 2, height: '35rem' }}>
                                <Typography variant="subtitle1">Device status</Typography>
                                <TestResponsiveRadialBar testStatus />
                            </Paper>
                        </Grid>
                        <Grid item xs={4}>
                            <Paper sx={{ p: 2, height: '35rem' }}>
                                <Typography variant="subtitle1">Storage</Typography>
                                <Grid item>
                                    <BusinessIcon /> Available
                                </Grid>
                                <Typography variant="h2">83%</Typography>
                                <LinearProgress color="success" value={80} />
                                <Typography variant="h5" sx={{ pt: 5 }}>
                                    That's 0.9% higher than your daily average
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={4}>
                            <Paper sx={{ p: 2, height: '35rem' }}>
                                <Typography variant="subtitle1">Device status</Typography>
                                <Grid item xs={12} sx={{ display: 'flex' }}>
                                    <Grid item xs={6}>
                                        <IconButton>
                                            <CheckCircleOutlineIcon color="success" />
                                        </IconButton>
                                        Online
                                        <Typography variant="h2">264</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <IconButton>
                                            <CancelOutlinedIcon color="error" />
                                        </IconButton>
                                        Offline
                                        <Typography variant="h2">12</Typography>
                                    </Grid>
                                </Grid>
                                <Divider />
                                <Typography variant="h5" sx={{ pt: 5 }}>
                                    You've fixed 1,245 critical errors in the past month.
                                </Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
}
