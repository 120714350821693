import React, { useState } from 'react';
import {
    DialogActions,
    DialogContentText,
    DialogContent,
    DialogTitle,
    Dialog,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    Checkbox,
    Grid,
    Chip,
    Button,
    Alert,
} from '@mui/material';
import CommonButton from '../components/atom/CommonButton';
import { doSwaggerCall, uploadDocument } from '../hooks/useApi';
import useNotify from '../hooks/useNotify';
import { BACKEND_URL } from '../config';

const AttachDocumentsToDatalinesModal = ({
    title,
    content,
    showModal,
    MODAL_TYPE,
    datalines,
    reloadData,
}) => {
    const closeAction = () => showModal(MODAL_TYPE.NONE);
    const [selectedDatalines, setSelectedDatalines] = useState([]);
    const { notifyInfo, notifyError, notifySuccess } = useNotify();
    const [documents, setDocuments] = useState([]);
    const [showAlert, setShowAlert] = useState(false);

    const handleSelectAll = (ev) => {
        if (ev.target.checked) {
            setSelectedDatalines(datalines);
            return;
        }
        setSelectedDatalines([]);
    };

    const openDocument = async (documentId) => {
        const document = await doSwaggerCall('Files', 'getDocument', {
            id: documentId,
        });
        const documentUrl = !document.url
            ? null
            : `${BACKEND_URL}/s3proxy?url=${encodeURIComponent(document.url)}`;
        await window.open(documentUrl);
    };

    const handleDelete = (chipToDelete) => () => {
        setDocuments(documents?.filter((e) => e.id !== chipToDelete));
    };

    const uploadEventHandler = async (event) => {
        if (!event.target.files || !event.target.files.length) {
            return;
        }
        const f = event.target.files[0];
        try {
            notifyInfo(`Upload started for ${f.name}`);
            const newDocument = await uploadDocument(f);
            notifyInfo(`Upload finished for ${f.name}`);
            setDocuments([...documents, newDocument]);
        } catch (error) {
            notifyError(`Upload failed for ${f.name}: ${error}`);
            setDocuments([]);
        }
        setShowAlert(false);
    };

    const attachDocumentsToDatalines = async () => {
        try {
            await doSwaggerCall('Datalines', 'attachDocumentsToDatalines', {
                selectedIdList: selectedDatalines.map((e) => e.id),
                documentIdList: documents?.map((e) => e.id),
            });
            notifySuccess('Documents attached to datalines');
            setDocuments([]);
            setSelectedDatalines([]);
            setShowAlert(false);
        } catch (e) {
            notifyError('Error attaching documents to datalines');
        }
    };

    return (
        <Dialog
            open
            onClose={closeAction}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth="md"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <Grid item xs={12}>
                    <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
                    <DialogContentText>
                        {showAlert && (
                            <Alert severity="warning">
                                There are still present documents that are not attached to any
                                dataline. If you would like to attach them simply select from the
                                table and click &quot;Attach to datalines&quot; button.
                            </Alert>
                        )}
                    </DialogContentText>
                    <Grid item container direction="row" spacing={1} sx={{ pt: 1 }}>
                        <Grid item>
                            <Button fullWidth>
                                <input
                                    onChange={(e) => {
                                        uploadEventHandler(e).catch(console.log);
                                    }}
                                    type="file"
                                    id="upload-document"
                                    style={{
                                        opacity: 0,
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0,
                                    }}
                                />
                                Upload document
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => attachDocumentsToDatalines()}
                                disabled={selectedDatalines.length === 0 || documents?.length === 0}
                            >
                                Attach to datalines
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item container direction="row" spacing={1} sx={{ pt: 1, pb: 1 }}>
                        {documents?.map((document) => (
                            <Grid item key={document.id}>
                                <Chip
                                    label={document.name}
                                    onClick={() => {
                                        openDocument(document.id).catch(console.log);
                                    }}
                                    onDelete={handleDelete(document.id)}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>

                <TableContainer component={Paper}>
                    <div style={{ maxHeight: '350px', overflowY: 'auto' }}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Checkbox
                                            checked={
                                                datalines?.length > 0 &&
                                                selectedDatalines.length === datalines.length
                                            }
                                            onChange={(ev) => handleSelectAll(ev)}
                                            indeterminate={
                                                selectedDatalines.length > 0 &&
                                                selectedDatalines.length < datalines.length
                                            }
                                        />
                                    </TableCell>
                                    <TableCell>Description</TableCell>
                                    <TableCell>Site</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {datalines.map((row) => (
                                    <TableRow key={row.id}>
                                        <TableCell>
                                            <Checkbox
                                                checked={selectedDatalines
                                                    .map((e) => e.id)
                                                    ?.includes(row.id)}
                                                onChange={() =>
                                                    setSelectedDatalines(
                                                        selectedDatalines
                                                            ?.map((e) => e.id)
                                                            .includes(row.id)
                                                            ? selectedDatalines.filter(
                                                                  (e) => e.id !== row.id
                                                              )
                                                            : [
                                                                  ...selectedDatalines,
                                                                  {
                                                                      id: row.id,
                                                                  },
                                                              ]
                                                    )
                                                }
                                            />
                                        </TableCell>
                                        <TableCell>{row.emission_description}</TableCell>
                                        <TableCell>{row.site_name || 'Corporate'}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <CommonButton
                    onClick={closeAction}
                    autoFocus
                    label="Cancel"
                    buttonType="secondary"
                />
                <CommonButton
                    onClick={() => {
                        if (documents?.length !== 0) {
                            setShowAlert(true);
                        }
                        if (showAlert || documents?.length === 0) {
                            showModal(MODAL_TYPE.NONE);
                            reloadData();
                        }
                    }}
                    label="Finish import"
                    buttonType="primary"
                />
            </DialogActions>
        </Dialog>
    );
};

export default AttachDocumentsToDatalinesModal;
