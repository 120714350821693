import { Grid, TextField } from '@mui/material';
import React from 'react';
import BlockWithTitle from '../../../components/atom/BlockWithTitle';
import AutocompleteSelect from '../../../components/atom/AutoCompleteSelect';
import FieldEditor from '../../../components/fieldeditor/FieldEditor';
import { locationAutocomplete } from '../../../autocomplete';
import FreeSoloAutoComplete from '../../../components/atom/FreeSoloAutoComplete';

const BasicSettings = ({ values, setFieldValue, touched, errors, companyId, isSuperAdmin }) => {
    return (
        <>
            <Grid item xs={8}>
                <BlockWithTitle title="Site settings">
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Name"
                            autoComplete="off"
                            required
                            value={values.name}
                            onChange={(ev) => setFieldValue('name', ev.target.value)}
                            error={touched.name && Boolean(errors.name)}
                            helperText={touched.name ? errors.name : ''}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <AutocompleteSelect
                            initCall={locationAutocomplete.init}
                            autocompleteCall={locationAutocomplete.search}
                            value={values?.location_id}
                            setValue={(ev) => setFieldValue('location_id', ev)}
                        >
                            <TextField label="Location" required />
                        </AutocompleteSelect>
                    </Grid>
                    <Grid item xs={12}>
                        <FreeSoloAutoComplete
                            fullWidth
                            label="Tags"
                            value={values?.tags}
                            setValue={(newValue) => setFieldValue('tags', newValue)}
                            companyId={companyId}
                        >
                            <TextField label="Tags" required />
                        </FreeSoloAutoComplete>
                    </Grid>
                </BlockWithTitle>
            </Grid>
            <Grid item xs={8}>
                <BlockWithTitle title="Field defaults">
                    <Grid item xs={12}>
                        <FieldEditor
                            values={values}
                            setFieldValue={setFieldValue}
                            isSuperAdmin={isSuperAdmin}
                        />
                    </Grid>
                </BlockWithTitle>
            </Grid>
        </>
    );
};

export default BasicSettings;
