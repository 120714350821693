import { Button, ButtonGroup, CircularProgress, Grid, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { doSwaggerCall, useApi } from '../../hooks/useApi';
import useNotify from '../../hooks/useNotify';
import SearchInput from '../../components/atom/SearchInput';
import { MODAL_TYPE, useModals } from '../../hooks/useModal';
import useSorting from '../../hooks/useSorting';
import PageWithTitle from '../../components/atom/PageWithTitle';
import { TABLE_ROW_COUNT_DEFAULT } from '../../helpers/constans';
import EsgQuestionsTable from './components/EsgQuestionsTable';
import InputButton from '../../components/atom/InputButton';
import handleEsgImport from '../../helpers/excelImportEsgQuestionsHelpers';
import { exportToXlsxEsg } from '../../helpers/excelExportEsgsHelpers';
import EsgEditorSwitcher from '../esghierarchy/components/EsgEditorSwitcher';

// transform esg question fields
const transformEsgQuestionFields = (fields) => {
    // collect data from fields and transform it to new format
    const i = [0, 0];
    const transformedFields = fields.reduce((acc, field) => {
        let newFieldType = false;
        let newDefaultMeasure = false;
        if (field.type === 'number') {
            newFieldType = 'Numeric';
        } else if (field.type === 'text') {
            newFieldType = 'Text';
        }
        if (field.defaultMeasure) {
            newDefaultMeasure = field.defaultMeasure;
        }
        if (newDefaultMeasure && field.type === 'number') {
            const defaultMeasureKey = `default_measure_${newFieldType.toLowerCase()}_value${
                i[0] + 1
            }`;
            acc[defaultMeasureKey] = newDefaultMeasure;
        }

        if (newFieldType) {
            const fieldTypeKey = `${newFieldType.toLowerCase()}_field_label`;
            const fieldTypeCount = i[newFieldType === 'Numeric' ? 0 : 1];
            const fieldTypeLabel = `${fieldTypeKey}${fieldTypeCount + 1}`;

            acc[fieldTypeLabel] = field.label;
            i[newFieldType === 'Numeric' ? 0 : 1] += 1;
        }

        return acc;
    }, {});

    // fill each type with empty strings if there are less than 6 fields
    if (i[0] < 6) {
        for (let n = i[0] + 1; n < 7; n++) {
            const fieldTypeLabel = `numeric_field_label${n}`;
            const defaultMeasureValue = `default_measure_numeric_value${n}`;
            transformedFields[fieldTypeLabel] = '';
            transformedFields[defaultMeasureValue] = '';
        }
    }
    if (i[1] < 6) {
        for (let t = i[1] + 1; t < 7; t++) {
            const fieldTypeLabel = `text_field_label${t}`;
            transformedFields[fieldTypeLabel] = '';
        }
    }

    // sort transformFields by key
    const sortedKeys = Object.keys(transformedFields).sort();
    const sortedTransformedFields = sortedKeys.reduce((acc, key) => {
        acc[key] = transformedFields[key];
        return acc;
    }, {});

    return sortedTransformedFields;
};

const EsgQuestionsPage = () => {
    const { notifyError, notifySuccess } = useNotify();
    const [search, setSearch] = useState('');
    const { sort, sortDir, setSorting } = useSorting(['created_at', 'ASC']);
    const [{ limit, page }, setParams] = useState({ limit: TABLE_ROW_COUNT_DEFAULT, page: 0 });
    const { showModal } = useModals();
    const [generatingXlsx, setGeneratingXlsx] = useState(false);
    const [importingXlsx, setImportingXlsx] = useState(false);

    const [data, loading, error, setData, reloadData] = useApi('EsgQuestions', 'getEsgQuestions', {
        search,
        limit,
        offset: page * limit,
        sort,
        sortDir,
    });

    const collectAllData = async () => {
        // esg questions tab
        const allEsgQuestions = await doSwaggerCall('EsgQuestions', 'getAllEsgQuestions', {
            pageSize: 10000,
        });
        const mappedEsgQuestions = allEsgQuestions.esgQuestions.map((question) => ({
            category: question.category_name,
            subcategory: question.subcategory_name,
            disclosure: question.disclosure_name,
            question_id: question.id,
            question: question.name,
            ...transformEsgQuestionFields(question.fields),
            marked_for_deletion: '',
        }));

        // frameworks tab
        const allFrameworkQuestions = await doSwaggerCall(
            'EsgFrameworks',
            'getEsgFrameworksWithQuestions',
            {}
        );
        const mappedEsgFrameworkQuestions = allFrameworkQuestions.frameworksWithQuestions.map(
            (f) => ({
                framework_name: f.name,
                standard: f.standard,
                disclosure: f.disclosure,
                question_id: f.question_id,
                internal_question: f.internal_question,
                internal_category: f.internal_category,
                internal_subcategory: f.internal_subcategory,
                internal_disclosure: f.internal_disclosure,
                guidance: f.guide_note,
            })
        );
        return { mappedEsgQuestions, mappedEsgFrameworkQuestions };
    };

    const handleImportEsgs = async (file) => {
        try {
            await handleEsgImport(file, true).then((res) => {
                showModal(MODAL_TYPE.CONFIRMATION_IMPORT_ESG_QUESTIONS, {
                    title: `Are you sure you want to import esg questions from this xlsx?`,
                    content: 'This action is irreversible.',
                    data: res,
                    setImportingXlsx,
                    confirm: async () => {
                        await handleEsgImport(file, false);
                        notifySuccess('Import esg questions successful');
                        setImportingXlsx(false);
                        reloadData();
                    },
                });
            });
        } catch (err) {
            setImportingXlsx(false);
            notifyError(err);
        }
    };

    useEffect(() => {
        if (error) {
            notifyError(error);
        }
    }, [error, notifyError]);

    if (error) {
        return null;
    }

    return (
        <>
            <EsgEditorSwitcher />
            <PageWithTitle
                title="ESG Questions"
                headline={[
                    <Grid
                        item
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        <Grid item>
                            <SearchInput
                                search={search}
                                setSearch={setSearch}
                                setParams={setParams}
                                limit={limit}
                            />
                        </Grid>
                        <Grid item>
                            <ButtonGroup color="primary">
                                <Tooltip title="Add ESG question">
                                    <Button
                                        fullWidth
                                        onClick={() => {
                                            showModal(MODAL_TYPE.ADD_ESG_QUESTION);
                                        }}
                                    >
                                        <AddIcon />
                                    </Button>
                                </Tooltip>
                                <Tooltip title="Export ESG master sheet">
                                    <Button
                                        fullWidth
                                        onClick={async () => {
                                            setGeneratingXlsx(true);

                                            let listToExport = [];
                                            try {
                                                listToExport = await collectAllData();
                                            } catch (err) {
                                                notifyError(err);
                                            }
                                            exportToXlsxEsg(listToExport, `master_esg_sheet`);
                                            setGeneratingXlsx(false);
                                            notifySuccess('ESG master sheet exported');
                                        }}
                                    >
                                        {generatingXlsx ? (
                                            <CircularProgress color="inherit" size={20} />
                                        ) : (
                                            <FileDownloadIcon />
                                        )}
                                    </Button>
                                </Tooltip>
                                <InputButton
                                    id="esg-list-upload"
                                    onChange={(e) => {
                                        if (e.target.files.length === 0) {
                                            return;
                                        }
                                        setImportingXlsx(true);
                                        handleImportEsgs(e.target.files[0]).catch((err) => {
                                            notifyError(err);
                                        });
                                        e.target.value = '';
                                    }}
                                    accept=".csv, .xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    title="Import ESG master sheet"
                                    type="file"
                                    loading={importingXlsx}
                                    fullWidth
                                />
                            </ButtonGroup>
                        </Grid>
                    </Grid>,
                ]}
            >
                <Grid item xs={12}>
                    <EsgQuestionsTable
                        rows={data?.esgQuestions}
                        limit={limit}
                        page={page}
                        setParams={setParams}
                        total={data?.total}
                        loading={loading}
                        reloadData={reloadData}
                        sort={sort}
                        sortDir={sortDir}
                        setSorting={setSorting}
                    />
                </Grid>
            </PageWithTitle>
        </>
    );
};

export default EsgQuestionsPage;
