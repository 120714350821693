import {
    Alert,
    FormControl,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material';
import React, { useCallback } from 'react';
import { NOTIFICATION_LABELS } from '../../../helpers/constans';

const NotificationSettingsPicker = ({ values, setFieldValue }) => {
    const frequencies = ['daily', 'weekly', 'instant', 'off'];
    const { notification_settings } = values;
    const setField = useCallback(
        (newValue) => {
            setFieldValue('notification_settings', newValue);
        },
        [setFieldValue]
    );

    return notification_settings.length > 0 ? (
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell width="50%">Notification type</TableCell>
                    <TableCell width="50%">Frequency</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {notification_settings.map(({ id, type, frequency }) => (
                    <TableRow key={id}>
                        <TableCell>{NOTIFICATION_LABELS[type]}</TableCell>
                        <TableCell>
                            <FormControl fullWidth>
                                <Select
                                    value={frequency}
                                    required
                                    onChange={(ev) => {
                                        setField(
                                            notification_settings.map((line) => {
                                                if (line.id === id) {
                                                    return {
                                                        id,
                                                        type: line.type,
                                                        frequency: ev.target.value,
                                                    };
                                                }
                                                return line;
                                            })
                                        );
                                    }}
                                >
                                    {frequencies.map((e) => {
                                        return <MenuItem value={e}>{e}</MenuItem>;
                                    })}
                                </Select>
                            </FormControl>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    ) : (
        <Alert severity="warning">You are not a member of this company!</Alert>
    );
};

export default NotificationSettingsPicker;
