import React, { useEffect, useState } from 'react';
import { Grid, LinearProgress } from '@mui/material';
import useNotify from '../../../hooks/useNotify';
import { doSwaggerCall } from '../../../hooks/useApi';
import CategoryOrTagOrSourceOrSupplierBarChart from './charts/CategoryOrTagOrSourceOrSupplierBarChart';

const BySelectedSource = ({ source, years, setChartData }) => {
    const { notifyError } = useNotify();
    const [sourceBarData, setSourceBarData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        doSwaggerCall('Dashboards', 'getDataByBaseUnitForSourceBarChart', {
            source,
            years,
        })
            .then((res) => {
                setSourceBarData(res);
                setChartData(res.sourceData);
                setLoading(false);
            })
            .catch((error) => {
                notifyError(error);
            });
    }, [source, years, setLoading, setSourceBarData, setChartData]);

    return (
        <Grid item xs={12}>
            {sourceBarData?.length === 0 ? (
                <Grid item>
                    {loading ? <LinearProgress sx={{ mt: 0.25 }} /> : <h5>No data available</h5>}
                </Grid>
            ) : (
                <CategoryOrTagOrSourceOrSupplierBarChart
                    data={sourceBarData.sourceData}
                    keys={years}
                    indexBy="source"
                    axisLeftLegend={sourceBarData.sourceData[0]?.base_unit}
                    legendOffset={-55}
                />
            )}
        </Grid>
    );
};

export default BySelectedSource;
