import { Grid, IconButton, Typography } from '@mui/material';
import React from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ScenarioSection from '../../pages/scenarios/components/ScenarioSection';
import SupplierSection from '../../pages/suppliers/components/SupplierSection';

// use this component for supplier and scenario group
function CommonGroup({ groupOpen, item, setGroupOpen, typeFlag, ...restProps }) {
    const open = groupOpen.includes(`${item.id}-${item.type}`);
    const sectionHeader = `${item.section?.sectionHeader}`;
    const typography = `${item.section?.sectionHeader}`;

    const setOpen = (newOpen) => {
        if (newOpen === true) {
            setGroupOpen((old) => [...old, `${item.id}-${item.type}`]);
        } else {
            setGroupOpen((old) => old.filter((e) => e !== `${item.id}-${item.type}`));
        }
    };

    if (!open) {
        return (
            <Grid item xs={12}>
                <Typography
                    variant="h4"
                    sx={{
                        cursor: 'pointer',
                    }}
                    onClick={() => setOpen(!open)}
                >
                    {typography}
                    <IconButton onClick={() => setOpen(!open)}>
                        <KeyboardArrowDownIcon />
                    </IconButton>
                </Typography>
            </Grid>
        );
    }

    if (item.section && open) {
        return typeFlag === 'scenario' ? (
            <ScenarioSection
                {...restProps}
                {...item.section}
                sectionHeader={item.section?.sectionHeader === '' ? '' : sectionHeader}
                groupOpen={groupOpen}
                item={item}
                open={open}
                setOpen={() => setOpen(!open)}
            />
        ) : (
            <SupplierSection
                {...restProps}
                {...item.section}
                sectionHeader={item.section?.sectionHeader === '' ? '' : sectionHeader}
                groupOpen={groupOpen}
                item={item}
                open={open}
                setOpen={() => setOpen(!open)}
            />
        );
    }

    return null;
}

export default CommonGroup;
