import React from 'react';
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Container,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableFooter,
    TableHead,
    TableRow,
    TextField,
} from '@mui/material';

function MyCard({ label }) {
    return (
        <Grid item xs={6} md={12}>
            <Card>
                <CardMedia
                    component="img"
                    src={`https://picsum.photos/400/200?r=${Math.random()}`}
                    height="200"
                />
                <CardContent>{label}</CardContent>
                <CardActions>
                    <Button fullWidth>Details</Button>
                </CardActions>
            </Card>
        </Grid>
    );
}

function TableLine({ data }) {
    return (
        <TableRow>
            {data.map((e, idx) => {
                return <TableCell id={idx}>{e}</TableCell>;
            })}
        </TableRow>
    );
}

function TableComponent() {
    return (
        <div
            style={{
                width: '100%',
                overflowX: 'auto',
            }}
            className="MuiTableContainer-root"
        >
            <Paper>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Equipment</TableCell>
                            <TableCell>Taken by</TableCell>
                            <TableCell>Subject</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableLine data={['Canon EOS 6D Mark II', 'Joe', 'Tree']} />
                        <TableLine data={['Canon EOS 6D Mark II', 'Joe', 'House']} />
                        <TableLine data={['Pentax K-70', 'Mark', 'Old lady']} />
                    </TableBody>
                    <TableFooter>
                        <TableCell>Equipment</TableCell>
                        <TableCell>Taken by</TableCell>
                        <TableCell>Subject</TableCell>
                    </TableFooter>
                </Table>
            </Paper>
        </div>
    );
}

export default function Screen1() {
    return (
        <Container>
            <Grid container spacing={2}>
                <Grid item md={8} xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TableComponent />
                        </Grid>
                        <Grid item xs={12}>
                            <Paper sx={{ p: 2 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <TextField label="Equipment" fullWidth />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">
                                                Taken by
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                label="Taken by"
                                            >
                                                <MenuItem value={10}>Joe</MenuItem>
                                                <MenuItem value={20}>Mark</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField label="Subject" fullWidth />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Button fullWidth>Add new image</Button>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Button fullWidth color="error">
                                            Cancel
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={4} xs={12}>
                    <Grid container spacing={2}>
                        <MyCard label="Interesting photo 1" />
                        <MyCard label="Interesting photo 2" />
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
}
