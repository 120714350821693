import React, { useState } from 'react';
import {
    DialogActions,
    DialogContentText,
    DialogContent,
    DialogTitle,
    Dialog,
    Alert,
    Grid,
    FormControlLabel,
    Checkbox,
    Button,
    Chip,
} from '@mui/material';
import CommonButton from '../components/atom/CommonButton';
import { doSwaggerCall, uploadAuditDocument } from '../hooks/useApi';
import useNotify from '../hooks/useNotify';
import { BACKEND_URL } from '../config';

const AuditCompanyYearModal = ({
    title,
    content,
    auditUrl,
    audited,
    year,
    yearId,
    companyId,
    showModal,
    reloadData,
    MODAL_TYPE,
}) => {
    const closeAction = () => showModal(MODAL_TYPE.NONE);
    const [auditedState, setAuditedState] = useState(audited);
    const [uploadedDocument, setUploadedDocument] = useState(auditUrl);
    const { notifyError, notifyInfo, notifySuccess } = useNotify();

    const auditYear = async () => {
        try {
            await doSwaggerCall(
                'CompanyYears',
                'auditCompanyYear',
                { year, companyId },
                { audited: auditedState, clearDocument: !uploadedDocument }
            );
            notifySuccess(`Audit updated for year ${year}`);
        } catch (error) {
            notifyError(`Audit failed for year ${year}: ${error}`);
        }
    };

    const uploadEventHandler = async (event) => {
        if (!event.target.files || !event.target.files.length) {
            return;
        }
        const f = event.target.files[0];
        try {
            notifyInfo(`Upload started for ${f.name}`);
            const newDocument = await uploadAuditDocument(f, yearId);

            notifyInfo(`Upload finished for ${f.name}`);
            setUploadedDocument(newDocument.url);
        } catch (error) {
            notifyError(`Upload failed for ${f.name}: ${error}`);
        }
    };

    function getUploadedDocumentUrl(uploadedDoc) {
        return `${BACKEND_URL}/s3proxy?url=${encodeURIComponent(uploadedDoc)}`;
    }

    return (
        <Dialog
            open
            fullWidth
            maxWidth="sm"
            onClose={closeAction}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
                <DialogContentText id="alert-dialog-warning">
                    <Alert sx={{ mt: 0.5 }} severity="warning">
                        {audited
                            ? 'This year is already audited. Uploading a new document will overwrite the old one.'
                            : 'If you audit this year, the year will be closed permanently.'}
                    </Alert>
                </DialogContentText>
                <Grid item container direction="row" spacing={1} sx={{ pt: 1 }}>
                    <Grid item>
                        <Button fullWidth>
                            <input
                                onChange={(e) => {
                                    uploadEventHandler(e).catch(console.log);
                                }}
                                type="file"
                                id="upload-document"
                                style={{
                                    opacity: 0,
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                }}
                            />
                            Upload audit document
                        </Button>
                    </Grid>
                    <Grid item container direction="row" spacing={1} sx={{ pt: 1, pb: 1 }}>
                        {uploadedDocument && (
                            <Grid item>
                                <Chip
                                    label={`Audit document for ${year}`}
                                    onClick={() => {
                                        window.open(
                                            getUploadedDocumentUrl(uploadedDocument),
                                            '_blank'
                                        );
                                    }}
                                    onDelete={() => {
                                        setUploadedDocument(null);
                                    }}
                                />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={auditedState}
                                onChange={(e) => setAuditedState(!!e.target.checked)}
                            />
                        }
                        label="Audit year"
                        labelPlacement="end"
                    />
                </Grid>
            </DialogContent>
            <DialogActions>
                <CommonButton
                    onClick={closeAction}
                    autoFocus
                    label="Cancel"
                    buttonType="secondary"
                />
                <CommonButton
                    onClick={() => {
                        auditYear();
                        showModal(MODAL_TYPE.NONE);
                        reloadData();
                    }}
                    label="Save"
                    buttonType="primary"
                />
            </DialogActions>
        </Dialog>
    );
};

export default AuditCompanyYearModal;
