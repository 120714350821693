import React, { useCallback, useContext, useEffect, useState } from 'react';
import { ACCESS_TOKEN_KEY, LOGGED_IN_USER_KEY } from '../config';
import { useGlobalFilters } from './useGlobalFilters';
import { doSwaggerCall } from './useApi';

const AuthContext = React.createContext();
/**
 * Grab access token from local storage, or if not found, return false
 * @returns {string|boolean}
 */
function getAccessTokenFromStorage() {
    try {
        const token = JSON.parse(localStorage.getItem(ACCESS_TOKEN_KEY));
        if (token === null) {
            return false;
        }
        return token;
    } catch (err) {
        console.log(err);
        return false;
    }
}

/**
 * Grab user from local storage, or if not found, return false
 * @returns {string|boolean}
 */
function getUserFromStorage() {
    try {
        const user = JSON.parse(localStorage.getItem(LOGGED_IN_USER_KEY));
        if (user === null) {
            return false;
        }

        // 2023.05.01 Change due to introduction of roles
        // force reload data from backend due to no permissions in the localstorage
        if (!Array.isArray(user?.permissions)) {
            return false;
        }
        // force reload data from backend due to old role name
        if (user?.role === 'admin') {
            return false;
        }
        return user;
    } catch (err) {
        console.log(err);
        return false;
    }
}

export function AuthContextProvider({ children }) {
    const [user, setUserInternal] = useState(getUserFromStorage);
    const [accessToken, setAccessTokenInternal] = useState(getAccessTokenFromStorage);
    const { setSiteFilters, setSelectedTags, setSelectedSites, setYear, setAvailableYears } =
        useGlobalFilters();

    const setAccessToken = useCallback(
        (newToken, fromLocalStorage = false) => {
            setAccessTokenInternal(newToken);
            // a simple set won't fire a storage call, so we need to fire it manually if that's needed
            if (!fromLocalStorage) {
                if (newToken === false) {
                    localStorage.removeItem(ACCESS_TOKEN_KEY);
                } else {
                    localStorage.setItem(ACCESS_TOKEN_KEY, JSON.stringify(newToken));
                }
            }
        },
        [setAccessTokenInternal]
    );

    const setUser = useCallback(
        (newUser, fromLocalStorage = false, yearParam = false) => {
            (async () => {
                let featureSet = [];
                let companyYears = [];
                let companyLogo = null;
                if (newUser !== false) {
                    // permissions
                    const res = await doSwaggerCall('Users', 'getUserPermissions', {
                        id: newUser.id,
                        companyId: newUser.company_id,
                    });
                    featureSet = res.feature_set;

                    // company available years
                    const yearsRes = await doSwaggerCall('CompanyYears', 'getCompanyYears', {
                        companyId: newUser.company_id,
                    });
                    // company logo
                    if (newUser.role !== 'superadmin') {
                        companyLogo = await doSwaggerCall('Users', 'getMe', {}).then((result) => {
                            return (
                                result.companies.find((e) => e.id === newUser.company_id)?.logo ||
                                null
                            );
                        });
                    } else {
                        companyLogo = await doSwaggerCall('Companies', 'getCompany', {
                            id: newUser.company_id,
                            year: new Date().getFullYear(),
                        }).then((result) => {
                            return result.logo || null;
                        });
                    }
                    companyYears = yearsRes.companyYears
                        .map((e) => ({ year: e.year, state: e.state }))
                        .sort((a, b) => a.year - b.year);
                }
                setUserInternal(
                    newUser === false
                        ? false
                        : {
                              ...newUser,
                              permissions: featureSet,
                              availableYears: companyYears,
                              companyLogo,
                          }
                );
                setAvailableYears(companyYears);

                // set year in case of incorrect datalines
                if (yearParam) {
                    setYear(yearParam);
                } else {
                    // set year to the greatest available open year
                    const openYears = companyYears.filter((e) => e.state === 'open');
                    if (openYears.length > 0) {
                        setYear(openYears[openYears.length - 1].year);
                    } else {
                        setYear(new Date().getFullYear() - 1);
                    }
                }

                // a simple set won't fire a storage call, so we need to fire it manually if that's needed
                if (!fromLocalStorage) {
                    if (newUser === false) {
                        localStorage.removeItem(LOGGED_IN_USER_KEY);
                    } else {
                        localStorage.setItem(
                            LOGGED_IN_USER_KEY,
                            JSON.stringify({
                                ...newUser,
                                permissions: featureSet,
                                availableYears: companyYears,
                                companyLogo,
                            })
                        );
                    }
                }
            })().catch((err) => {
                console.log(err);
            });
        },
        [setUserInternal, setAvailableYears, setYear]
    );

    const setSwitchIntoCompany = useCallback(
        (newUser, yearModify = false) => {
            setUser(newUser, false, yearModify);
            setSiteFilters({ siteFilter: [], siteNamesFilter: [] });
            setSelectedTags([]);
            setSelectedSites([]);
        },
        [setUser, setSiteFilters, setSelectedTags, setSelectedSites]
    );

    const logoutUser = useCallback(() => {
        setUser(false);
        setAccessToken(false);
        setSiteFilters({ siteFilter: [], siteNamesFilter: [] });
        setSelectedTags([]);
        setSelectedSites([]);
    }, [setUser, setSiteFilters, setSelectedTags, setSelectedSites]);

    // listen to localstorage changes, so we can invalidate token from anywhere
    useEffect(() => {
        const checkAccessToken = (e) => {
            if (e.key === ACCESS_TOKEN_KEY) {
                const newToken = getAccessTokenFromStorage();
                if (accessToken === newToken) {
                    return;
                }
                if (newToken === false) {
                    logoutUser();
                } else {
                    setAccessToken(newToken, true);
                }
                return;
            }
            if (e.key === LOGGED_IN_USER_KEY) {
                setUser(getUserFromStorage(), true);
            }
        };

        // this is needed if you have more windows open
        window.addEventListener('storage', checkAccessToken);
        return () => {
            window.removeEventListener('storage', checkAccessToken);
        };
    }, [accessToken, setAccessToken]);

    return (
        <AuthContext.Provider
            value={{
                user,
                setUser,
                logoutUser,
                setAccessToken,
                setSwitchIntoCompany,
                userPermissions: user?.permissions || [],
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}

AuthContext.displayName = 'AuthContext';

/**
 * Auth context
 * @returns {{logoutUser: logoutUser, setUser: setUser, userPermissions: userPermissions, setSwitchIntoCompany: setSwitchIntoCompany, setAccessToken: setAccessToken, user: {}}}
 */
export function useAuth() {
    return useContext(AuthContext);
}
