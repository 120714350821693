import { IconButton, Typography } from '@mui/material';
import React from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import HelperIcon from '../../../components/atom/HelperIcon';

export function TypographyAndGuideNote({ item, typography, filterCount, open, setOpen, onClick }) {
    const paddingLeftBase = 15;
    let paddingLeftMultiplier = 0;
    let paddingLeft = '0px';
    switch (item.type) {
        case 'category':
            paddingLeftMultiplier = 2;
            break;
        case 'subcategory':
            paddingLeftMultiplier = 3;
            break;
        case 'source':
            paddingLeftMultiplier = 4;
            break;
        default:
            paddingLeftMultiplier = 1;
            break;
    }
    paddingLeft = `${(paddingLeftMultiplier - (filterCount + 1)) * paddingLeftBase}px`;
    return (
        <Typography
            variant="h5"
            sx={{
                fontWeight: item.type === 'scope' ? 'bold' : 'normal',
                cursor: 'pointer',
                userSelect: 'none',
                paddingLeft,
            }}
            onClick={() => setOpen(!open)}
        >
            {typography}
            {item.guide_note && <HelperIcon title={item.name} guideNote={item.guide_note} />}
            <IconButton onClick={() => onClick}>
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
        </Typography>
    );
}

export default TypographyAndGuideNote;
