import { useEffect, useState } from 'react';

/**
 * Return uniqueStates, stateToSubgrid, zipToSubgrid from egrid data
 * @returns {[unknown[],{},{}]}
 */
export default function useEgridData() {
    const [uniqueStates, setUniqueStates] = useState([]);
    const [stateToSubgrid, setStateToSubgrid] = useState({});
    const [zipToSubgrid, setZipToSubgrid] = useState({});

    useEffect(() => {
        fetch('/assets/egridpicker.json')
            .then((res) => res.json())
            .then((egridpicker) => {
                // Unique state list
                setUniqueStates([...new Set(egridpicker.map((e) => e.state))]);

                // State map calculation
                const stateMap = {};
                egridpicker.forEach((item) => {
                    if (!stateMap[item.state]) {
                        stateMap[item.state] = [];
                    }
                    item.subregion.forEach((region) => {
                        if (!stateMap[item.state].includes(region)) {
                            stateMap[item.state].push(region);
                        }
                    });
                });
                setStateToSubgrid(stateMap);

                // Zip map calculation
                const zipMap = {};
                egridpicker.forEach((item) => {
                    if (!zipMap[item.zip]) {
                        zipMap[item.zip] = [];
                    }
                    item.subregion.forEach((region) => {
                        if (!zipMap[item.zip].includes(region)) {
                            zipMap[item.zip].push(region);
                        }
                    });
                });
                setZipToSubgrid(zipMap);
            });
    }, []);

    return [uniqueStates, stateToSubgrid, zipToSubgrid];
}
