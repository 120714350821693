import { Grid, Paper } from '@mui/material';
import React from 'react';

export default function ImpSurveySection({ children }) {
    return (
        <Grid item xs={12} style={{ position: 'relative' }}>
            <Paper
                sx={{ p: 4 }}
                style={{
                    overflow: 'hidden',
                    border: '1px solid #ccc',
                    boxShadow: '2px 2px 2px rgba(0,0,0,0.05)',
                }}
            >
                <Grid container style={{ opacity: 1 }}>
                    {children}
                </Grid>
            </Paper>
        </Grid>
    );
}
