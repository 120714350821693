import { Button, Grid, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import navigateByPermission from '../../helpers/navigateByPermission';

const NotFoundPage = () => {
    const navigate = useNavigate();
    const navigateUrl = navigateByPermission();
    return (
        <Grid container justifyContent="center" alignContent="center" alignItems="center">
            <Grid item xs={5}>
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <Typography variant="h1" align="right">
                            404
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="h3" align="right">
                            Page not found
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body1" align="right">
                            The page you are looking for <b>does not exist.</b>
                            <br />
                            We tried to find it really hard, but we couldn&apos;t.
                            <br />
                            <br />
                        </Typography>
                        <Typography variant="body1" align="right">
                            If you think this is a mistake, please contact us.
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body1" align="right">
                            <Button onClick={() => navigate(navigateUrl)} variant="text">
                                Back to the homepage
                            </Button>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={7}>
                <img
                    src="/assets/404.png"
                    alt="404"
                    style={{ maxHeight: '50vh', maxWidth: '100%' }}
                />
            </Grid>
        </Grid>
    );
};

export default NotFoundPage;
