/* Generic ideas
    - tree should rebalance itself, if that's possible
    - minimal mutation should be in the tree, only replace elements that are absolutely needed
 */

class RebalanceError extends Error {
    constructor(message, offBy) {
        super(message);
        this.offValue = offBy;
    }

    getOff() {
        return this.offValue;
    }
}
/**
 * The scope level has no parent, produce one, so we can run the algorithm
 * @param tree
 */
export function extendTreeWithFirstLevelParent(tree) {
    // grab the sum from the old values
    const oldSumBaseline = tree.reduce((sum, item) => sum + item.target, 0);
    const oldSumTarget = tree.reduce((sum, item) => sum + item.target, 0);

    return {
        id: 0,
        name: 'root',
        baseline: oldSumBaseline,
        target: oldSumTarget,
        locked: false,
        extendedLock: false,
        children: tree,
    };
}

/**
 * Set the min target (the subtree cannot go below that value) and extendedLock (all children locked OR item locked)
 *      - extended lock: all children or self locked
 *      - minTarget: all the sum of locked children lock
 * @param tree
 * @returns {{children}|*|{locked}}
 */
function extendWithExtendedLock(tree) {
    // generate extended lock layer (or update it)
    if (!tree.children) {
        tree.minTarget = tree.locked ? tree.target : 0;
        tree.extendedLock = tree.extendedLock || tree.locked;
        return tree;
    }
    // recursive apply to children
    let hasUnlocked = false;
    let minChildTarget = 0;
    tree.children = tree.children.map((e) => {
        const f = extendWithExtendedLock(e);
        if (f.extendedLock === false) {
            hasUnlocked = true;
        }
        minChildTarget += f.minTarget;
        return f;
    });
    tree.extendedLock = tree.extendedLock || tree.locked || !hasUnlocked;
    tree.minTarget = tree.extendedLock ? tree.target : minChildTarget;
    return tree;
}

/**
 * Remove the extra layer we introduced on top of the tree
 * @param tree
 * @returns {{children}|*}
 */
function removeExtendedLock(tree) {
    delete tree.extendedLock;
    delete tree.minTarget;
    if (!tree.children) {
        return tree;
    }
    tree.children = tree.children.map((item) => {
        return removeExtendedLock(item);
    });
    return tree;
}

/**
 * Helper function to to get the parent element on the path
 * @param path
 * @returns {*}
 */
function getParentRoot(path) {
    return path.slice(0, path.length - 1);
}

/**
 * Get a subtree using a path
 * @param root
 * @param path
 * @returns {*}
 */
export function getSubTree(root, path) {
    const innerPath = [...path];
    let pointer = root;
    while (innerPath.length > 0) {
        pointer = pointer.children[innerPath.shift()];
    }
    return pointer;
}

/**
 * This function recalculates a possible solution to the "level" problem
 *      distributing change in one (even imaginary) items extra emission to the others
 *      returning a list of new targets AND "extra" which should be distributed upper in the chain
 * @param children
 * @param changeIdx the index of the item changing during the process (-1 if we just dump extra target values in there)
 * @param change the diff of the change
 */
function calculateOneLevel(children, changeIdx, change) {
    // we need to get a list of the current situation, a bool flag list of items that can be changed
    const unlockedItems = children.map((e, itemIdx) => itemIdx !== changeIdx && !e.extendedLock);
    let extra = change;
    // we need to distribute as much "extra" as we can, either in the plus or the minus side
    // calculate the "weight" of items in a particular child list
    const unlockedBaselineSum = children
        .filter((_item, idx) => unlockedItems[idx])
        .reduce((sum, item) => sum + item.baseline, 0);

    // we need to take care in the case where baseline sum is 0, the weight is going to be even
    const unlockedWeights = children.map((e) =>
        unlockedBaselineSum === 0 ? 1 / children.length : e.baseline / unlockedBaselineSum
    );

    const newTargets = children.map((item) => item.target);

    let didSomeChange = true;
    let reasonableRounds = 10000;
    while (didSomeChange === true) {
        reasonableRounds--;
        if (reasonableRounds === 0) {
            break;
        }
        // TODO: maybe we need a reasonable amount of rounds, and after that set the weights to 1
        didSomeChange = false;

        // try to distribute the extra with the weights until we have nothing left (or everything reached the minimum target)
        const roundExtras = unlockedWeights.map((e) => e * extra);
        for (let i = 0; i < children.length; i++) {
            // items that we cannot modify should be skipped
            if (!unlockedItems[i]) {
                continue;
            }
            let wantedDiff = roundExtras[i];
            // only the "decrease" part should be interesting, we can increase anything anywhere
            if (wantedDiff < 0) {
                // grab either the one that will help reach the minimum, or the wantedDiff (both negative values)
                wantedDiff = Math.max(children[i].minTarget - newTargets[i], wantedDiff);
            }
            if (wantedDiff === 0) {
                continue;
            }
            extra -= wantedDiff;
            newTargets[i] += wantedDiff;
            didSomeChange = true;
        }
    }
    // Sometimes I do hate javascript
    if (Math.abs(extra) < 0.001) {
        extra = 0;
    }

    return { newTargets, extra };
}

/**
 * We know that our values should not stretch into the "upper tree", we can simplify the algorithm
 * @param root
 * @param parent
 * @param parentSum
 */
function simpleSafeDownPropagation(root, parent, parentSum) {
    const subtree = getSubTree(root, parent);

    // nothing to change, leave the subtree alone
    if (subtree.target === parentSum) {
        return;
    }
    // no children, just set the value
    if (typeof subtree.children === 'undefined' || subtree.children.length === 0) {
        subtree.target = parentSum;
        return;
    }

    const { newTargets, extra } = calculateOneLevel(
        subtree.children,
        -1,
        parentSum - subtree.target
    );
    // SHOULD NEVER HAPPEN, this is just a failsafe if we did something wrong
    if (extra > 0) {
        // FIXME: should die on this error
        /*
        throw new Error([
            extra,
            'simpleSafeDownPropagation freaked out',
            JSON.stringify(subtree.children),
            parentSum - subtree.target,
        ]);
         */
    }
    for (let i = 0; i < subtree.children.length; i++) {
        simpleSafeDownPropagation(root, [...parent, i], newTargets[i]);
    }
    subtree.target = parentSum;
}

/**
 * Up propagation calculates the sum of children and set the partent, this one goes up till the root is reached
 * @param root
 * @param path
 */
function simpleSafeUpPropagation(root, path) {
    // root
    if (path.length === 0) {
        return;
    }
    const subtree = getSubTree(root, path);

    if (subtree?.children.length > 0) {
        const newTarget = subtree.children.reduce((sum, item) => sum + item.target, 0);
        if (subtree.target !== newTarget && subtree.locked) {
            throw new RebalanceError('Need to unlock parent!');
        }
        subtree.target = newTarget;
    } else {
        subtree.target = newTarget;
    }
    // propagate up
    simpleSafeUpPropagation(root, getParentRoot(path));
}

function internalRebalance(root, path, newTarget) {
    const lastIndex = path[path.length - 1];
    const parent = getParentRoot(path);
    const subtree = getSubTree(root, parent);

    // recalculate everything above
    // const limitedTarget = Math.max(newTarget, subtree.minTarget);

    simpleSafeDownPropagation(root, path, newTarget);
    subtree.children[lastIndex].target = newTarget;
    // TODO: maybe we need to recalculate the whole tree
    simpleSafeUpPropagation(root, parent);
    /*

    // the simulation does not modify the actual path item, we need to do it later (!!!!)
    const { newTargets, extra } = calculateOneLevel(
        subtree.children,
        lastIndex,
        subtree.children[lastIndex].target - newTarget
    );

    // once the calculation is done, just lock the current item, to not calculate with it
    subtree.extendedLock = true;

    // copy the results and lock the children (except the currently modified one, since we don't know the actual change volume there)
    for (let i = 0; i < subtree.children.length; i++) {
        const neededTarget = i === lastIndex ? newTarget : newTargets[i];
        simpleSafeDownPropagation(root, [...parent, i], neededTarget);
    }

    // we have extra items, try to distribute it one hierarchy above
    if (extra !== 0) {
        if (parent.length === 0) {
            throw new RebalanceError(`Cannot do it, off by: ${extra}`, extra);
        }

        internalRebalance(root, parent, subtree.target - extra, false);
    } */
}

export default function rebalanceTree(originalTree, path, newTarget) {
    let possibleTarget = newTarget;
    // keep a reasonable iteration
    for (let i = 0; i < 5; i++) {
        try {
            const deepCopyTree = JSON.parse(JSON.stringify(originalTree));
            const root = extendWithExtendedLock(extendTreeWithFirstLevelParent(deepCopyTree));
            internalRebalance(root, path, possibleTarget);
            const cleanRoot = removeExtendedLock(root);
            return cleanRoot.children;
        } catch (err) {
            if (err instanceof RebalanceError) {
                possibleTarget += err.getOff();
            } else {
                // well, something else broke, forward it to an upper level
                throw err;
            }
        }
    }
    // we cannot modify anything
    return originalTree;
}
