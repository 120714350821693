import { read, utils } from 'xlsx';
import { doSwaggerCall } from '../hooks/useApi';

// check existing location name
async function checkExistingLocation(name) {
    let existingLocationFlag = true;
    let existingLocation = null;
    try {
        // not case-sensitive check
        existingLocation = await doSwaggerCall('Locations', 'getLocationByName', {
            search: name,
        });
    } catch (error) {
        existingLocationFlag = false;
    }
    return { existingLocationFlag, existingLocationId: existingLocation?.id };
}

// helper function to check rows
async function checkData(data) {
    const locationData = data;
    for (let i = 0; i < data.length; i++) {
        // check marked for deletion
        if (
            typeof data[i].Marked_for_deletion !== 'undefined' &&
            data[i].Marked_for_deletion !== ''
        ) {
            locationData[i].marked_for_deletion = true;
        }
        // check incorrect data (empty cells)
        if (
            typeof data[i].Name === 'undefined' ||
            data[i].Name === 'World' ||
            typeof data[i].Parent_name === 'undefined'
        ) {
            locationData[i].wrong = 'Wrong data';
        }

        // check parent exists
        try {
            const parentLocation = await doSwaggerCall('Locations', 'getLocationByName', {
                search: data[i].Parent_name,
            });
            locationData[i].parent_location_id = parentLocation?.id;
        } catch (error) {
            locationData[i].wrong = 'No parent ';
            console.log(`No parent: ${data[i].Parent_name}`);
        }

        // check existing location name
        if (
            typeof data[i].Marked_for_deletion === 'undefined' ||
            data[i].Marked_for_deletion === ''
        ) {
            const { existingLocationFlag, existingLocationId } = await checkExistingLocation(
                data[i].Name
            );
            if (
                existingLocationFlag &&
                (locationData[i].Id === '' || typeof locationData[i].Id === 'undefined')
            ) {
                // create new location
                locationData[i].wrong = 'Existing location';
                console.log(`Existing location: ${data[i].Name}`);
            } else if (existingLocationFlag && existingLocationId !== locationData[i].Id) {
                // update location
                locationData[i].wrong = 'Existing location';
                console.log(`Existing location: ${data[i].Name}`);
            }
        }
    }
    // check duplicates
    locationData.map((oneFactorData, index) => {
        locationData.find((fdata, idx) => {
            if (
                fdata.Name === oneFactorData.Name &&
                idx !== index &&
                typeof fdata.wrong === 'undefined' &&
                typeof oneFactorData.wrong === 'undefined' &&
                !fdata.marked_for_deletion &&
                !oneFactorData.marked_for_deletion
            ) {
                locationData[index].wrong = 'Duplicate';
                locationData[idx].wrong = 'Duplicate';
                return true;
            }
            return false;
        });
        return locationData;
    });

    const checkedData = locationData.map((row) => ({
        id: row.Id,
        name: row.Name,
        parent_name: row.Parent_name,
        marked_for_deletion: row.marked_for_deletion,
        wrong: row.wrong,
    }));

    return checkedData;
}

// Parse xlsx sheets
export const parseXlsxSheets = (fileName, cb, failCb = () => {}) => {
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(fileName);
    fileReader.onload = (e) => {
        const bufferArray = e?.target.result;
        const wb = read(bufferArray, { type: 'buffer' });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];

        const data = utils.sheet_to_json(ws);
        checkData(data)
            .then((checkedData) => {
                cb(checkedData);
            })
            .catch((err) => {
                console.log(err);
                failCb(err);
            });
    };
};

// Handle import
export const runImport = (parsedData, dryRun) => {
    let filteredLocations = parsedData;
    if (!dryRun) {
        // filter wrong data, duplicate and no parent name rows
        filteredLocations = parsedData.filter((d) => typeof d.wrong === 'undefined');
    }

    // import only good rows
    try {
        return doSwaggerCall('Locations', 'importLocations', { dryRun }, { filteredLocations });
    } catch (error) {
        throw new Error(error);
    }
};
