import React, { useEffect } from 'react';
import { ResponsiveLine } from '@nivo/line';
import { FormHelperText, Grid } from '@mui/material';
import { useDarkMode } from '../../../../hooks/useDarkMode';
import { useLegends, LegendsContextProvider } from '../../../../hooks/useLegends';
import {
    LINECHART_PROPS,
    customAxisColor,
    customNivoTheme,
} from '../../../../helpers/chartStyleHelper';
import LineLegendDisplay from '../LineLegendDisplay';
import { formatNumber } from '../../../../helpers/convertUnits';
import getPaperBottom from '../helpers/getPaperBottom';

const LineLegendSink = ({ series }) => {
    const { setLineLegends } = useLegends();
    useEffect(() => {
        setLineLegends(series);
    }, [setLineLegends, series]);
    return null;
};
const LineChart = ({
    data,
    selectedScopes,
    isMultiple,
    scenarioName,
    baselineYear,
    targetYear,
    reduction,
    targetYearData = false,
}) => {
    const { darkMode } = useDarkMode();
    let errorMessage = '';
    const lineData = [
        { id: 'Current emission', data: [] },
        { id: scenarioName, data: [] },
    ];
    const multipleData = []; // array for multiple scenario data
    const realMultiple = { id: 'Current emission', data: [] };
    // add or edit scenario
    if (!isMultiple) {
        if (baselineYear >= targetYear || Math.abs(baselineYear - targetYear) > 100) {
            errorMessage = 'Target year is not greater than baseline year!';
        }
        if (Number.isNaN(baselineYear) || Number.isNaN(targetYear) || Number.isNaN(reduction)) {
            errorMessage = 'Baseline year, target year or target reduction goal is not a number!';
        }
        if (selectedScopes.length === 0) {
            errorMessage = 'You should pick at least one scope or category!';
        }
        if (errorMessage.length > 0) {
            return <FormHelperText sx={{ color: '#066387' }}>{errorMessage}</FormHelperText>;
        }

        // we collect all the datalines that matches the selected scopes
        for (let i = 0; i < data.datalines.length; i++) {
            const x = data.datalines[i].year;
            const y = data.datalines[i].sumOfCO2e / 1000000;
            const scopeId = data.datalines[i].scope_id;
            if (selectedScopes.includes(scopeId)) {
                if (lineData[0].data.find((d) => d.x === x)) {
                    const foundIndex = lineData[0].data.findIndex((d) => d.x === x);
                    lineData[0].data[foundIndex].y += y;
                } else {
                    lineData[0].data.push({ x, y });
                }
            }
        }
        // fill the gaps in years with 0
        const years = lineData[0].data.map((e) => e.x);
        const minYear = Math.min(...years, baselineYear);
        const maxYear = Math.max(...years, targetYear);
        for (let i = minYear; i <= maxYear; i++) {
            const yearExists = lineData[0].data.find((e) => e.x === i);
            if (!yearExists) {
                lineData[0].data.push({
                    x: i,
                    y: 0,
                });
            }
        }
        lineData[0].data.sort((a, b) => a.x - b.x);

        // set baseline data and calculation
        const baselineData = lineData[0].data.find((e) => e.x === baselineYear);
        if (typeof baselineData !== 'undefined') {
            lineData[1].data.push(baselineData);
        }

        const baselineEmissionData = lineData[1].data.find((e) => e.x === baselineYear)?.y;
        const yearlyReduction = reduction / (targetYear - baselineYear);

        let i = 1;
        for (let year = baselineYear + 1; year < targetYear + 1; year++) {
            const x = year;
            const y = baselineEmissionData - (i * (baselineEmissionData * yearlyReduction)) / 100;
            lineData[1].data.push({ x, y });
            i += 1;
        }
        lineData[1].data.sort((a, b) => (a.x > b.x) - (a.x < b.x));

        // use goal when edit scenario
        if (targetYearData !== false) {
            // real reduction is coming from targetYearData
            const baselineSum = targetYearData.reduce((sum, item) => sum + item.baseline, 0);
            const targetSum = targetYearData.reduce((sum, item) => sum + item.target, 0);
            let actualReduction = '?';
            if (baselineSum > 0) {
                actualReduction = Math.round((1 - targetSum / baselineSum) * 100 * 100) / 100;
            }
            const newYearlyReduction = actualReduction / (targetYear - baselineYear);

            let k = 1;
            for (let year = baselineYear + 1; year < targetYear + 1; year++) {
                const yEmission =
                    baselineEmissionData - (k * (baselineEmissionData * newYearlyReduction)) / 100;
                const foundIndex = lineData[1].data.findIndex((d) => d.x === year);
                lineData[1].data[foundIndex].y = yEmission;
                k += 1;
            }
            lineData[1].data.sort((a, b) => (a.x > b.x) - (a.x < b.x));
        }
    } else {
        // multiple scenario -> use one line for current and calculate data for scenarios
        // we need to differ by scenario name
        scenarioName.forEach((name, scenarioIdx) => {
            const scenarioNames = multipleData.map((e) => e.id);
            multipleData.push({
                id: scenarioNames.includes(name) ? `${name} (${scenarioIdx + 1}.)` : name,
                scope: selectedScopes[scenarioIdx],
                baselineYear: baselineYear[scenarioIdx],
                targetYear: targetYear[scenarioIdx],
                reduction: reduction[scenarioIdx],
                data: [],
            });
        });
        // fill lineData with proper year
        for (let i = 0; i < data.datalines.length; i++) {
            const x = data.datalines[i].year;
            const y = data.datalines[i].sumOfCO2e / 1000000;
            multipleData.forEach((line, multipleDataIdx) => {
                if (line.scope.includes(data.datalines[i].scope_id)) {
                    if (line.data.find((d) => d.x === x)) {
                        const foundIndex = line.data.findIndex((d) => d.x === x);
                        multipleData[multipleDataIdx].data[foundIndex].y += y;
                    } else {
                        line.data.push({ x, y });
                    }
                }
            });
        }
        // calculate per scenarios
        multipleData.forEach((scenarioData, multipleIdx) => {
            const baselineEmissionData = scenarioData.data.find(
                (e) => e.x === scenarioData.baselineYear
            )?.y;

            let yearlyReduction =
                scenarioData.reduction / (scenarioData.targetYear - scenarioData.baselineYear);

            // use real data by scenario
            if (targetYearData !== false) {
                // real reduction is coming from targetYearData
                const baselineSum = targetYearData[multipleIdx].reduce(
                    (sum, item) => sum + item.baseline,
                    0
                );
                const targetSum = targetYearData[multipleIdx].reduce(
                    (sum, item) => sum + item.target,
                    0
                );
                let realReduction = '?';
                if (baselineSum > 0) {
                    realReduction = Math.round((1 - targetSum / baselineSum) * 100 * 100) / 100;
                }
                yearlyReduction =
                    realReduction / (scenarioData.targetYear - scenarioData.baselineYear);

                let i = 1;
                for (
                    let year = scenarioData.baselineYear + 1;
                    year < scenarioData.targetYear + 1;
                    year++
                ) {
                    const x = year;
                    const y =
                        baselineEmissionData - (i * (baselineEmissionData * yearlyReduction)) / 100;
                    const existingYear = scenarioData.data.find((e) => e.x === x);
                    // only selected target scopes should have linear optimal yearly reduction
                    // if data exists for year, we should overwrite that with optimal reduction
                    if (existingYear) {
                        existingYear.y = y;
                    } else {
                        scenarioData.data.push({ x, y });
                    }

                    i += 1;
                }
            }
            scenarioData.data.sort((a, b) => (a.x > b.x) - (a.x < b.x));
        });

        // calculate actual (current) emission
        for (let l = 0; l < data.datalines.length; l++) {
            const x = data.datalines[l].year;
            const y = data.datalines[l].sumOfCO2e / 1000000;
            if (realMultiple.data.find((d) => d.x === x)) {
                const foundIndex = realMultiple.data.findIndex((d) => d.x === x);
                realMultiple.data[foundIndex].y += y;
            } else {
                realMultiple.data.push({ x, y });
            }
        }
        multipleData.push(realMultiple);
    }

    // set paper bottom margin
    const lengthConditions = [15, 12, 9, 6, 3];
    const paperBottomValues = ['14vh', '12vh', '10vh', '8vh', '4vh', '2vh'];
    const paperBottom = getPaperBottom(multipleData, lengthConditions, paperBottomValues);

    return (
        <Grid
            item
            xs={12}
            style={{
                height: '40vh',
                marginBottom: paperBottom,
                marginRight: '-25px',
                marginLeft: '5px',
            }}
        >
            <LegendsContextProvider>
                <ResponsiveLine
                    data={isMultiple ? multipleData : lineData}
                    theme={customNivoTheme(darkMode)}
                    curve={LINECHART_PROPS.curve}
                    areaBlendMode={LINECHART_PROPS.areaBlendMode}
                    areaOpacity={LINECHART_PROPS.areaOpacity}
                    enableArea={LINECHART_PROPS.enableArea}
                    pointSize={LINECHART_PROPS.pointSize}
                    pointColor={LINECHART_PROPS.pointColor}
                    pointBorderWidth={LINECHART_PROPS.pointBorderWidth}
                    axisBottom={LINECHART_PROPS.axisBottom}
                    colors={LINECHART_PROPS.colors}
                    pointBorderColor={LINECHART_PROPS.pointBorderColor}
                    lineWidth={LINECHART_PROPS.lineWidth}
                    margin={{ top: 50, right: 30, bottom: 50, left: 80 }}
                    xScale={{ type: 'point' }}
                    yScale={{
                        type: 'linear',
                        min: 0,
                        max: 'auto',
                        reverse: false,
                    }}
                    axisLeft={{
                        legend: 'CO2e (mt)',
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legendPosition: 'middle',
                        legendOffset: -75,
                        renderTick: (props) => {
                            return (
                                <text
                                    style={{
                                        fontWeight: 'bold',
                                    }}
                                    textAnchor="end"
                                    fill={customAxisColor(darkMode)}
                                    fontSize={12}
                                    x={props.x - 4}
                                    y={props.y + 4}
                                >
                                    {formatNumber(props.value)}
                                </text>
                            );
                        },
                    }}
                    enableSlices="x"
                    yFormat={LINECHART_PROPS.yFormat}
                    axisTop={null}
                    axisRight={null}
                    pointLabelYOffset={-12}
                    useMesh
                    animate={false}
                    layers={[
                        'grid',
                        'markers',
                        'axes',
                        'areas',
                        'crosshair',
                        'lines',
                        'points',
                        'slices',
                        'mesh',
                        LineLegendSink,
                    ]}
                />
                <LineLegendDisplay />
            </LegendsContextProvider>
        </Grid>
    );
};

export default LineChart;
