import { Grid, TextField } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import AutocompleteSelect from '../../../components/atom/AutoCompleteSelect';
import { esgDisclosureAutocomplete } from '../../../autocomplete';
import SkeletonForm from '../../../components/atom/SkeletonForm';
import BlockWithTitle from '../../../components/atom/BlockWithTitle';
import CommonButton from '../../../components/atom/CommonButton';
import EsgQuestionFieldEditor from './EsgQuestionFieldEditor';

function groupSubcategoryAndCategory(item) {
    return `${item?.category_name} - ${item?.subcategory_name}`;
}

const EsgQuestionForm = ({ loading, values, setFieldValue, touched, isSubmitting, errors }) => {
    const navigate = useNavigate();

    return (
        <BlockWithTitle title="Edit ESG question">
            <Grid item xs={12}>
                <Grid container>
                    {loading ? (
                        <SkeletonForm rows={6} />
                    ) : (
                        <>
                            <Grid item xs={12}>
                                <TextField
                                    label="Name"
                                    autoComplete="off"
                                    fullWidth
                                    value={values?.name}
                                    onChange={(ev) => setFieldValue('name', ev.target.value)}
                                    error={touched.name && Boolean(errors.name)}
                                    helperText={touched.name ? errors.name : ''}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <AutocompleteSelect
                                    grouping={groupSubcategoryAndCategory}
                                    initCall={esgDisclosureAutocomplete.init}
                                    disableClearable
                                    autocompleteCall={esgDisclosureAutocomplete.search}
                                    value={`${values?.esg_disclosure_id}`}
                                    setValue={(ev) => setFieldValue('esg_disclosure_id', ev)}
                                >
                                    <TextField label="Disclosure" required />
                                </AutocompleteSelect>
                            </Grid>
                            <Grid item xs={12}>
                                <EsgQuestionFieldEditor
                                    values={values}
                                    setFieldValue={setFieldValue}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Note for guidance (optional)"
                                    fullWidth
                                    multiline
                                    value={values?.guide_note}
                                    onChange={(ev) => setFieldValue('guide_note', ev.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CommonButton
                                    fullWidth
                                    onClick={() => navigate(`/esgquestions`)}
                                    label="Back"
                                    buttonType="secondary"
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <CommonButton
                                    type="submit"
                                    disabled={isSubmitting}
                                    fullWidth
                                    label="Save"
                                    buttonType="primary"
                                />
                            </Grid>
                        </>
                    )}
                </Grid>
            </Grid>
        </BlockWithTitle>
    );
};

export default EsgQuestionForm;
