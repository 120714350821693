export default function setCheckedChildren(currentLevel, checkedData) {
    let data = checkedData;

    if (currentLevel.children) {
        const childIds = currentLevel.children.map((child) => child.id);
        const childTypes = currentLevel.children.map((child) => child.type);

        const filteredData = checkedData.filter(
            (e) => !childIds.includes(e.id) || !childTypes.includes(e.type)
        );

        data = filteredData;

        for (let i = 0; i < currentLevel.children.length; i++) {
            data = setCheckedChildren(currentLevel.children[i], data);
        }
    }

    return data;
}
