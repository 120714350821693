import { Button, ButtonGroup, CircularProgress, Grid, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { doSwaggerCall, useApi } from '../../hooks/useApi';
import EmissionSourcesTable from './components/EmissionSourcesTable';
import useNotify from '../../hooks/useNotify';
import SearchInput from '../../components/atom/SearchInput';
import { MODAL_TYPE, useModals } from '../../hooks/useModal';
import handleSourcesImport from '../../helpers/excelImportSourcesHelpers';
import useSorting from '../../hooks/useSorting';
import { exportToXlsx } from '../../helpers/excelExportHelpers';
import { useGlobalFilters } from '../../hooks/useGlobalFilters';
import { convertUnitGToKg } from '../../helpers/convertUnits';
import PageWithTitle from '../../components/atom/PageWithTitle';
import { MULTIVARIABLE_EMISSION_SOURCES } from '../../config';
import { TABLE_ROW_COUNT_DEFAULT } from '../../helpers/constans';
import InputButton from '../../components/atom/InputButton';

const EmissionSourcesPage = () => {
    const { notifyError, notifySuccess } = useNotify();
    const [search, setSearch] = useState('');
    const { sort, sortDir, setSorting } = useSorting(['created_at', 'ASC']);
    const [{ limit, page }, setParams] = useState({ limit: TABLE_ROW_COUNT_DEFAULT, page: 0 });
    const { showModal } = useModals();
    const [generatingXlsx, setGeneratingXlsx] = useState(false);
    const { year } = useGlobalFilters();

    const [data, loading, error, setData, reloadData] = useApi(
        'EmissionSources',
        'getEmissionSources',
        {
            search,
            limit,
            offset: page * limit,
            sort,
            sortDir,
            includeChildren: true,
        }
    );

    const sampleData = [
        {
            source_name: '',
            subcategory: '',
            category: '',
            scope: '',
            base_unit: '',
            co2: '',
            co2_unit: '',
            ch4: '',
            ch4_unit: '',
            n2o: '',
            n2o_unit: '',
            source: '',
            admin_note: '',
            guide_note: '',
            location: '',
            use_as_global: '',
        },
    ];
    if (MULTIVARIABLE_EMISSION_SOURCES) {
        sampleData[0] = {
            ...sampleData[0],
            parent_source_name: '',
            parent_subcategory_name: '',
            parent_category_name: '',
            parent_scope_name: '',
        };
    }

    const handleImportSources = async (file) => {
        try {
            await handleSourcesImport(file, true).then((res) => {
                showModal(MODAL_TYPE.CONFIRMATION_IMPORT_MASTERSOURCE, {
                    data: res,
                    finished: async () => {
                        reloadData();
                    },
                });
            });
        } catch (err) {
            notifyError(err);
        }
    };

    const collectAllData = async () => {
        const allFactors = await doSwaggerCall('EmissionFactors', 'getAllEmissionFactors', {
            year,
        });
        const mappedFactors = allFactors.factors.map((factor) =>
            MULTIVARIABLE_EMISSION_SOURCES
                ? {
                      source_name: factor.emission_source,
                      subcategory: factor.subcategory,
                      category: factor.category,
                      scope: factor.scope,
                      parent_source_name: factor.parent_source_name,
                      parent_subcategory_name: factor.parent_subcategory_name,
                      parent_category_name: factor.parent_category_name,
                      parent_scope_name: factor.parent_scope_name,
                      base_unit: factor.base_unit,
                      co2: convertUnitGToKg(factor.co2_factor),
                      co2_unit: 'kg',
                      ch4: parseFloat(factor.ch4_factor),
                      ch4_unit: 'g',
                      n2o: parseFloat(factor.n2o_factor),
                      n2o_unit: 'g',
                      source: factor.source,
                      admin_note: factor.admin_note,
                      guide_note: factor.guide_note,
                      location: factor.location,
                      use_as_global: '',
                  }
                : {
                      source_name: factor.emission_source,
                      subcategory: factor.subcategory,
                      category: factor.category,
                      scope: factor.scope,
                      base_unit: factor.base_unit,
                      co2: convertUnitGToKg(factor.co2_factor),
                      co2_unit: 'kg',
                      ch4: parseFloat(factor.ch4_factor),
                      ch4_unit: 'g',
                      n2o: parseFloat(factor.n2o_factor),
                      n2o_unit: 'g',
                      source: factor.source,
                      admin_note: factor.admin_note,
                      guide_note: factor.guide_note,
                      location: factor.location,
                      use_as_global: '',
                  }
        );
        return mappedFactors;
    };

    // Helper function to get all locations for export
    const getAllLocationsForExport = async () => {
        const locations = await doSwaggerCall('Locations', 'getAllLocations', {
            pageSize: 10000,
            page: 0,
        });
        return locations.locations.map((l) => ({ name: l.name }));
    };

    useEffect(() => {
        if (error) {
            notifyError(error);
        }
    }, [error, notifyError]);

    if (error) {
        return null;
    }

    return (
        <PageWithTitle
            title="Emission Sources"
            headline={[
                <Grid item container direction="row" justifyContent="flex-end" alignItems="center">
                    <Grid item>
                        <SearchInput
                            search={search}
                            setSearch={setSearch}
                            setParams={setParams}
                            limit={limit}
                        />
                    </Grid>
                    <Grid item>
                        <ButtonGroup color="primary">
                            <Tooltip title="Add emission source">
                                <Button
                                    fullWidth
                                    onClick={() => {
                                        showModal(MODAL_TYPE.ADD_EMISSION_SOURCE);
                                    }}
                                >
                                    <AddIcon />
                                </Button>
                            </Tooltip>
                            <Tooltip title="Master sheet sample">
                                <Button
                                    fullWidth
                                    onClick={async () => {
                                        const allLocations = await getAllLocationsForExport();
                                        exportToXlsx(
                                            sampleData,
                                            'master_sheet_sample',
                                            true,
                                            false,
                                            false,
                                            false,
                                            allLocations
                                        );
                                    }}
                                >
                                    <FileDownloadIcon />
                                </Button>
                            </Tooltip>
                            <Tooltip title="Export master sheet">
                                <Button
                                    fullWidth
                                    onClick={async () => {
                                        setGeneratingXlsx(true);
                                        let listToExport = [];
                                        try {
                                            listToExport = await collectAllData();
                                        } catch (err) {
                                            notifyError(err);
                                        }
                                        const allLocations = await getAllLocationsForExport();
                                        exportToXlsx(
                                            listToExport,
                                            `master_sheet_${year}`,
                                            true,
                                            false,
                                            false,
                                            false,
                                            allLocations
                                        );
                                        setGeneratingXlsx(false);
                                        notifySuccess('Master sheet exported');
                                    }}
                                >
                                    {generatingXlsx ? (
                                        <CircularProgress color="inherit" size={20} />
                                    ) : (
                                        <FileDownloadIcon />
                                    )}
                                </Button>
                            </Tooltip>
                            <InputButton
                                id="master-list-upload"
                                onChange={(e) => {
                                    if (e.target.files.length === 0) {
                                        return;
                                    }
                                    handleImportSources(e.target.files[0]).catch((err) => {
                                        notifyError(err);
                                    });
                                    e.target.value = '';
                                }}
                                accept=".csv, .xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                title="Import master sheet"
                                type="file"
                            />
                        </ButtonGroup>
                    </Grid>
                </Grid>,
            ]}
        >
            <Grid item xs={12}>
                <EmissionSourcesTable
                    rows={data?.emissionSources}
                    limit={limit}
                    page={page}
                    setParams={setParams}
                    total={data?.total}
                    loading={loading}
                    reloadData={reloadData}
                    sort={sort}
                    sortDir={sortDir}
                    setSorting={setSorting}
                />
            </Grid>
        </PageWithTitle>
    );
};

export default EmissionSourcesPage;
