import { CircularProgress, Grid, Paper } from '@mui/material';
import React, { useState, useCallback, useEffect } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeView from '@mui/lab/TreeView';
import useNotify from '../../../../hooks/useNotify';
import { doSwaggerCall } from '../../../../hooks/useApi';
import SourcePickerScopeLevel from './SourcePickerScopeLevel';
import SearchInput from '../../../../components/atom/SearchInput';
import ComponentTitle from '../../../../components/ComponentTitle';
import recursiveTreeFilter from '../../../../helpers/recursiveTreeFilter';
import getCheckedChildrenNumberRecursive from '../../../companies/components/helpers/getCheckedChildrenNumberRecursive';
import setCheckedChildren from '../../../companies/components/helpers/setCheckedChildren';
import { transformHierarchyToItem } from '../../../../helpers/groups';

const SourcePicker = ({ year, checkedData, setCheckedData }) => {
    const [dataGroups, setDataGroups] = useState([]);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const { notifyError } = useNotify();

    const loadHierarchy = useCallback((targetYear) => {
        setLoading(true);
        doSwaggerCall('Hierarchy', 'getSourceHierarchy', {
            year: targetYear,
            hasanydatalines: false, // we need all hierarchy
            hasFactor: false, // we need all hierarchy
        })
            .then((hierarchy) => {
                const transformResult = hierarchy.map((e) => transformHierarchyToItem(e));
                setDataGroups(transformResult);
                setLoading(false);
            })
            .catch((error) => {
                notifyError(error);
            });
    }, []);

    useEffect(() => {
        loadHierarchy(year);
    }, [year]);

    // search all hierarchy
    useEffect(() => {
        if (search === '') {
            setFilteredData(dataGroups);
            return;
        }
        setFilteredData(recursiveTreeFilter(dataGroups, search.toLowerCase()));
    }, [search, dataGroups]);

    const getCheckedChildrenNumber = useCallback(
        (item) => {
            return getCheckedChildrenNumberRecursive(checkedData, item);
        },
        [checkedData]
    );

    return (
        <Grid item xs={12} sx={{ pt: 2 }}>
            <Paper sx={{ p: 2 }} style={{ width: '100%' }}>
                <Grid
                    container
                    item
                    xs={12}
                    justifyContent="space-between"
                    spacing={2}
                    sx={{ pb: 2 }}
                >
                    <Grid item style={{ alignSelf: 'center' }}>
                        <ComponentTitle variant="h5" title="Customize available sources" />
                    </Grid>
                </Grid>
                <Grid container>
                    {loading && (
                        <Grid item xs={12}>
                            <CircularProgress size="1.5rem" />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <SearchInput fullWidth search={search} setSearch={setSearch} />
                    </Grid>
                    {filteredData.map((scope) => (
                        <Grid item xs={12} key={`${scope.id} - ${scope.name}`}>
                            <TreeView
                                key={`${scope.name} - ${scope.id}`}
                                aria-label="icon expansion"
                                defaultCollapseIcon={<ExpandMoreIcon />}
                                defaultExpandIcon={<ChevronRightIcon />}
                                defaultExpanded={[]}
                            >
                                <SourcePickerScopeLevel
                                    key={`${scope.id} - ${scope.name}`}
                                    checkedData={checkedData}
                                    scope={scope}
                                    setCheckedData={setCheckedData}
                                    setCheckedChildren={setCheckedChildren}
                                    getCheckedChildrenNumber={getCheckedChildrenNumber}
                                />
                            </TreeView>
                        </Grid>
                    ))}
                </Grid>
            </Paper>
        </Grid>
    );
};

export default SourcePicker;
