import { Typography } from '@mui/material';
import React from 'react';

export default function SurveyQuestionHeadline({ children }) {
    return (
        <Typography style={{ fontWeight: '500', fontSize: '120%', lineHeight: '120%' }}>
            {children}
        </Typography>
    );
}
