import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import CustomTreeItem from '../../atom/CustomTreeItem';

function SourceLevel({ checkedData, source, setCheckedData }) {
    return (
        <CustomTreeItem
            nodeId={`source - ${source.id}`}
            label={
                <FormControlLabel
                    label={source.name}
                    labelPlacement="end"
                    control={
                        <Checkbox
                            checked={
                                !!checkedData.find((c) => c.id === source.id && c.type === 'source')
                            }
                            onChange={() => {
                                const removeReqSource = checkedData.find(
                                    (item) => item.id === source.id && item.type === 'source'
                                );

                                setCheckedData(
                                    removeReqSource
                                        ? checkedData.filter((data) => data !== removeReqSource)
                                        : [
                                              ...checkedData,
                                              {
                                                  type: 'source',
                                                  id: source.id,
                                              },
                                          ]
                                );
                            }}
                        />
                    }
                />
            }
        />
    );
}

export default SourceLevel;
