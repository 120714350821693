import { Grid, Paper, Typography } from '@mui/material';
import React from 'react';

function BlockWithTitle({ children, title }) {
    return (
        <Paper sx={{ p: 2 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="subtitle1">{title}</Typography>
                </Grid>
                {children}
            </Grid>
        </Paper>
    );
}

export default BlockWithTitle;
