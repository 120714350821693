import { Button, Grid } from '@mui/material';
import React from 'react';
import AddIcon from '@mui/icons-material/Add';

const PanelPlaceholder = ({ onClick }) => {
    return (
        <Grid>
            <Button fullWidth variant="outlined" sx={{ height: '200px' }} onClick={onClick}>
                <AddIcon
                    sx={{
                        position: 'absolute',
                        height: '50px',
                        width: '50px',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                    }}
                />
            </Button>
        </Grid>
    );
};

export default PanelPlaceholder;
