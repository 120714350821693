// helper function to define paperBottom value based on data length
const getPaperBottom = (data, lengthConditions, paperBottomValues) => {
    // last value of paperBottomValues
    let paperBottom = paperBottomValues[paperBottomValues.length - 1];
    for (let i = 0; i < lengthConditions.length; i++) {
        if (data.length > lengthConditions[i]) {
            paperBottom = paperBottomValues[i];
            break;
        }
    }
    return paperBottom;
};

export default getPaperBottom;
