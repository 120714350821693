import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { useEsgFilters } from '../hooks/useEsgFilters';
import EsgCategoryFilter from './components/esg/EsgCategoryFilter';
import EsgSubcategoryFilter from './components/esg/EsgSubcategoryFilter';
import EsgDisclosureFilter from './components/esg/EsgDisclosureFilter';
import EsgQuestionFilter from './components/esg/EsgQuestionFilter';
import CommonButton from '../components/atom/CommonButton';

const GlobalEsgFilterModal = ({ title, showModal, MODAL_TYPE }) => {
    const { setEsgFilters, setEdited } = useEsgFilters();

    const closeAction = () => showModal(MODAL_TYPE.NONE);

    const handleClear = () => {
        setEsgFilters({
            esgCategoryFilter: null,
            esgSubcategoryFilter: null,
            esgDisclosureFilter: null,
            esgQuestionFilter: null,
        });
        setEdited(false);
    };

    return (
        <Dialog open onClose={closeAction} maxWidth="xs" fullWidth>
            <DialogTitle sx={{ alignSelf: 'center' }}>{title}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} sx={{ pt: 1 }}>
                    <Grid item xs={12}>
                        <EsgCategoryFilter />
                    </Grid>
                    <Grid item xs={12}>
                        <EsgSubcategoryFilter />
                    </Grid>
                    <Grid item xs={12}>
                        <EsgDisclosureFilter />
                    </Grid>
                    <Grid item xs={12}>
                        <EsgQuestionFilter />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <CommonButton
                            onClick={closeAction}
                            fullWidth
                            label="Close"
                            buttonType="secondary"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <CommonButton
                            onClick={handleClear}
                            fullWidth
                            label="Clear"
                            buttonType="primary"
                        />
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};

export default GlobalEsgFilterModal;
