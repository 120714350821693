import { saveAs } from 'file-saver';
import { utils, write } from 'xlsx';
import { doSwaggerCall } from '../hooks/useApi';

const HEADER_MAPPING = {
    scope: 'Scope',
    category: 'Category',
    subcategory: 'Subcategory',
    emission_source: 'Emission source',
    siteName: 'Site',
    sumOfCO2e: 'Emission (CO2e mt)',
    location_id: 'Location',
    location: 'Location',
    co2_factor: 'CO2_factor',
    ch4_factor: 'CH4_factor',
    n2o_factor: 'N2O_factor',
    source: 'Source',
    marked_for_deletion: 'Marked_for_deletion',
    parent_name: 'Parent_name',
    id: 'Id',
    name: 'Name',
    tags: 'Tags',
    email: 'Email',
    permission: 'Permission',
};

const SUPPLIER_HEADER_MAPPING = {
    id: 'Id',
    name: 'Name',
    industry: 'Industry',
    location: 'Location',
    scope: 'Scope',
    category: 'Category',
    marked_for_deletion: 'Marked_for_deletion',
};
// generate contact fields from contact number and use it in header mapping
const generateContactFields = (contactNumber) => {
    for (let i = 1; i <= contactNumber; i++) {
        SUPPLIER_HEADER_MAPPING[`contact_name${i}`] = `ContactName${i}`;
        SUPPLIER_HEADER_MAPPING[`contact_email${i}`] = `ContactEmail${i}`;
        SUPPLIER_HEADER_MAPPING[`contact_phone${i}`] = `ContactPhone${i}`;
    }
};

const MASTER_SHEET_HEADER_MAPPING = {
    source_name: 'source_name',
    subcategory: 'subcategory',
    category: 'category',
    scope: 'scope',
    parent_source_name: 'parent_source_name',
    parent_subcategory_name: 'parent_subcategory_name',
    parent_category_name: 'parent_category_name',
    parent_scope_name: 'parent_scope_name',
    base_unit: 'base_unit',
    co2: 'co2',
    co2_unit: 'co2_unit',
    ch4: 'ch4',
    ch4_unit: 'ch4_unit',
    n2o: 'n2o',
    n2o_unit: 'n2o_unit',
    source: 'source',
    admin_note: 'admin_note',
    guide_note: 'guide_note',
    location: 'location',
    use_as_global: 'use_as_global',
};

const AUDIT_REPORT_HEADER_MAPPING = {
    scope: 'Scope',
    category: 'Category',
    subcategory: 'Subcategory',
    emission_source: 'Emission source',
    site: 'Site',
    co2e: 'Co2e (mt)',
    emission_description: 'Description',
    documents: 'Documents',
    notes: 'Notes',
    fields: 'Fields',
    usage: 'Usage',
    unit_of_measure: 'Unit of Measure',
    from_date: 'From date',
    to_date: 'To date',
    is_estimated: 'Estimated',
    logs: 'Calculation',
};

const ESG_REPORT_HEADER_MAPPING = {
    id: 'Id',
    site: 'Site',
    question: 'Question',
    disclosure: 'Disclosure',
    subcategory: 'Subcategory',
    category: 'Category',
    notes: 'Notes',
    documents: 'Documents',
    mark_completed: 'Completed',
    completion: 'Completion',
    values: 'Values',
};

// generate fields
const generateFields = (fieldNumber) => {
    for (let i = 1; i <= fieldNumber; i++) {
        AUDIT_REPORT_HEADER_MAPPING[`Field ${i}`] = `Field ${i}`;
        AUDIT_REPORT_HEADER_MAPPING[`Field ${i} unit`] = `Field ${i} unit`;
    }
};

const EXPORT_LIMIT_CHUNK = 2;

/**
 * Generate xlsx data from an array of objects
 * @param {array} data array of objects to convert to xlsx rows
 * @returns header fields from object keys and rows from values
 */
export const generateXlsxData = (data, exportMasterSheet = false, exportSiteSheet = false) => {
    if (!data || data.length === 0) {
        if (!exportSiteSheet) {
            return [];
        }
        data.push({
            id: '',
            name: '',
            location: '',
            tags: '',
            marked_for_deletion: '',
        });
    }

    const newData = data;
    const headers = Object.keys(newData[0]).map((object) => {
        if (exportMasterSheet) {
            return MASTER_SHEET_HEADER_MAPPING[object];
        }
        return HEADER_MAPPING[object];
    });
    const rows = newData.map((element) => {
        return Object.values(element).map((value) => {
            if (Array.isArray(value)) {
                return value.map((e) => e.name).join('\n');
            }
            return value;
        });
    });

    return [headers, ...rows];
};

/**
 * Generate xlsx data from an array of objects
 * @param {array} data array of objects to convert to xlsx rows
 * @returns header fields from object keys and rows from values
 */
export const generateXlsxSuppliersData = (data) => {
    if (!data || data.length === 0) {
        return [];
    }

    const newData = data;
    // find that row from newData which has most number of keys
    const mostKeysIndex = newData.reduce((acc, curr, index) => {
        if (Object.keys(curr).length > Object.keys(newData[acc]).length) {
            return index;
        }
        return acc;
    }, 0);
    generateContactFields((Object.keys(newData[mostKeysIndex]).length - 5) / 3);
    const headers = Object.keys(newData[mostKeysIndex]).map((object) => {
        return SUPPLIER_HEADER_MAPPING[object];
    });
    const rows = newData.map((element) => {
        return Object.values(element).map((value) => {
            if (Array.isArray(value)) {
                return value.map((e) => e.name).join('\n');
            }
            return value;
        });
    });

    return [headers, ...rows];
};

/**
 * Generate xlsx data from an array of objects
 * @param {array} data array of objects to convert to xlsx rows
 * @returns header fields from object keys and rows from values
 */
export const generateXlsxAuditReportData = (data) => {
    if (!data || data.length === 0) {
        return [];
    }

    const newData = data;
    // find data row which has the highest column number
    const maxLengthData = newData.reduce((acc, curr) => {
        const numColumns = Object.keys(curr).filter((key) => key.startsWith('Field')).length;
        return numColumns > Object.keys(acc).filter((key) => key.startsWith('Field')).length
            ? curr
            : acc;
    }, {});
    // get max fields number from maxLengthData
    const maxFieldsNumber = Object.keys(maxLengthData).filter((key) =>
        key.startsWith('Field')
    ).length;
    // maxFieldsNumber / 2, because we need X, but field columns are like this: { 'Field X', 'Field X unit' }
    generateFields(maxFieldsNumber / 2);

    const headers = Object.keys(maxLengthData).map((object) => {
        return AUDIT_REPORT_HEADER_MAPPING[object];
    });

    const rows = newData.map((element) => {
        return Object.values(element).map((value) => {
            if (Array.isArray(value)) {
                return value.map((e) => e.name).join('\n');
            }
            return value;
        });
    });

    return [headers, ...rows];
};

/**
 * Generate xlsx data from an array of objects
 * @param {array} data array of objects to convert to xlsx rows
 * @returns header fields from object keys and rows from values
 */
export const generateXlsxEsgReportData = (data) => {
    if (!data || data.length === 0) {
        return [];
    }

    const newData = data;
    const headers = Object.keys(newData[0]).map((object) => {
        return ESG_REPORT_HEADER_MAPPING[object];
    });

    const rows = newData.map((element) => {
        return Object.values(element).map((value) => {
            if (Array.isArray(value)) {
                return value.map((e) => e.name).join('\n');
            }
            return value;
        });
    });

    return [headers, ...rows];
};

// Helper function to collect all sites
export async function getSitesForOneCompany(companyId) {
    let sites = [];
    let total = EXPORT_LIMIT_CHUNK + 1;
    for (let page = 0; page * EXPORT_LIMIT_CHUNK < total; page++) {
        const siteChunk = await doSwaggerCall('Sites', 'getSitesInCompany', {
            id: companyId,
            limit: EXPORT_LIMIT_CHUNK,
            offset: page * EXPORT_LIMIT_CHUNK,
        });
        total = siteChunk.total;
        sites = [...sites, ...siteChunk.sites];
    }
    return sites;
}

// Helper function to collect all suppliers
export async function getSuppliersForOneCompany(year) {
    let suppliers = [];
    let total = EXPORT_LIMIT_CHUNK + 1;
    for (let page = 0; page * EXPORT_LIMIT_CHUNK < total; page++) {
        const supplierChunk = await doSwaggerCall('Suppliers', 'getSuppliers', {
            year,
            limit: EXPORT_LIMIT_CHUNK,
            offset: page * EXPORT_LIMIT_CHUNK,
        });
        total = supplierChunk.total;
        suppliers = [...suppliers, ...supplierChunk.suppliers];
    }
    return suppliers;
}

// Helper function to collect all locations
export async function getAllLocations() {
    let locations = [];
    let total = EXPORT_LIMIT_CHUNK + 1;
    for (let page = 0; page * EXPORT_LIMIT_CHUNK < total; page++) {
        const locationChunk = await doSwaggerCall('Locations', 'getAllLocations', {
            page,
            pageSize: EXPORT_LIMIT_CHUNK,
        });
        total = locationChunk.total;
        locations = [...locations, ...locationChunk.locations];
    }
    // get and set parent_name from parent_id
    for (let i = 0; i < locations.length; i++) {
        // remove World from location export
        if (locations[i].parent_id === null) {
            continue;
        }
        const parentLocation = await doSwaggerCall('Locations', 'getLocation', {
            id: locations[i].parent_id,
        });
        locations[i].parent_name = parentLocation.name;
    }
    return locations.filter((l) => l.parent_id !== null);
}

// helper function to collect all users in a company
export async function getAllUsersInCompany(companyId) {
    let users = [];
    let total = EXPORT_LIMIT_CHUNK + 1;
    for (let page = 0; page * EXPORT_LIMIT_CHUNK < total; page++) {
        const userChunk = await doSwaggerCall('Users', 'getUsersInCompany', {
            id: companyId,
            page,
            pageSize: EXPORT_LIMIT_CHUNK,
        });
        total = userChunk.total;
        users = [...users, ...userChunk.users];
    }
    return users;
}

/**
 * Generate xlsx data from an array of objects
 * @param ws
 * @param columnName
 */
const setColumnToNumber = (ws, columnName) => {
    const column = columnName;
    // get the worksheet range (column letter followed by row number)
    const range = ws['!ref'];
    if (!range) {
        return;
    }

    const [, , startRow, , endRow] = range.match(/([A-Z]+)(\d+):([A-Z]+)(\d+)/);
    const firstDataRow = parseInt(startRow, 10) + 1; // Skip header
    for (let i = firstDataRow; i <= endRow; i++) {
        const cellAddress = column + i;
        const cell = ws[cellAddress];

        if (cell) {
            cell.t = 'n'; // Set type to number
            cell.v = Number(cell.v); // Convert value to number format
            cell.w = undefined; // Remove formatted text to allow Excel to auto-format
        }
    }
};

/**
 * Generate xlsx file and initiate download
 * @param {array} data array of objects to convert to xlsx rows
 */
export const exportToXlsx = (
    data,
    name,
    exportMasterSheet = false,
    exportAuditReportSheet = false,
    exportSupplierSheet = false,
    exportSiteSheet = false,
    locations = null,
    exportEsgReportSheet = false
) => {
    const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    let ws = {};
    const wb = utils.book_new();

    if (exportSupplierSheet) {
        ws = utils.aoa_to_sheet(generateXlsxSuppliersData(data));
    } else if (exportAuditReportSheet) {
        ws = utils.aoa_to_sheet(generateXlsxAuditReportData(data));
        ws['!autofilter'] = { ref: `${ws['!ref'].slice(0, -1)}1` };
        // force column F to number
        setColumnToNumber(ws, 'F');
    } else if (exportEsgReportSheet) {
        ws = utils.aoa_to_sheet(generateXlsxEsgReportData(data));
        ws['!autofilter'] = { ref: `${ws['!ref'].slice(0, -1)}1` };
    } else {
        ws = utils.aoa_to_sheet(generateXlsxData(data, exportMasterSheet, exportSiteSheet));
    }
    utils.book_append_sheet(wb, ws, exportMasterSheet ? 'import_this' : 'SheetJS');
    // we just add an extra helper tab here for available locations
    if (exportSupplierSheet || exportMasterSheet || exportSiteSheet) {
        const helperTextRow = ['This is a helper tab to list all available locations.'];
        const ws2 = utils.aoa_to_sheet(generateXlsxData(locations));
        utils.sheet_add_aoa(ws2, [[helperTextRow]], { origin: 'D1' });
        utils.book_append_sheet(wb, ws2, 'Locations');
    }
    const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' });

    const file = new Blob([excelBuffer], { type: fileType });
    saveAs(file, `${name}${fileExtension}`);
};

export function setComment(ws, cell, value) {
    const cellData = ws[cell];
    if (!cellData.c) cellData.c = [];
    cellData.c.hidden = true;
    cellData.c.push({ t: value });
}
