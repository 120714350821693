import {
    Button,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
} from '@mui/material';
import React, { useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useImpSurveyStep, withFormikContext } from './ImpSurveyStep';
import SurveyQuestionHeadline from '../../survey/components/SurveyQuestionHeadline';
import ImpSurveySection from './ImpSurveySection';
import { MODAL_TYPE, useModals } from '../../../hooks/useModal';
import SurveyQuestionSubheader from './SurveyQuestionSubheader';

const WIDTH_FOR_ROWS = [35, 30, 30, 5];

function QListOfFacilities({
    values,
    setFieldValue,
    sectionIndex,
    formikKey,
    subheader,
    columns,
    q,
    status,
}) {
    const { step, addFieldIntoStep } = useImpSurveyStep();
    const { showModal } = useModals();
    useEffect(() => {
        addFieldIntoStep(`answers.${formikKey}`, sectionIndex + 1);
    }, [addFieldIntoStep, formikKey, sectionIndex]);

    if (step !== sectionIndex + 1) {
        return null;
    }

    const deleteRow = (idx) => {
        const newFields = [...values.answers[formikKey].fields];
        newFields.splice(idx, 1);
        setFieldValue(`answers.${formikKey}`, {
            fields: newFields,
            others: values.answers[formikKey].others,
        });
    };

    return (
        <ImpSurveySection>
            <Grid item xs={12}>
                <SurveyQuestionHeadline>{q}</SurveyQuestionHeadline>
                {subheader && <SurveyQuestionSubheader subheader={subheader} />}
                <Table>
                    <TableHead>
                        <TableRow>
                            {columns.map((column, i) => (
                                <TableCell key={column} width={`${WIDTH_FOR_ROWS[i]}%`}>
                                    {column}
                                </TableCell>
                            ))}
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {values?.answers[formikKey].fields?.map((facility, idx) => (
                            <TableRow key={idx}>
                                <TableCell>
                                    <TextField
                                        fullWidth
                                        autoComplete="off"
                                        value={facility.facilityName}
                                        label={columns[0]}
                                        onChange={(e) => {
                                            setFieldValue(
                                                `answers.${formikKey}.fields[${idx}].facilityName`,
                                                e.target.value
                                            );
                                        }}
                                    />
                                </TableCell>
                                <TableCell>
                                    <FormControl fullWidth>
                                        <InputLabel id="status-select">{columns[1]}</InputLabel>
                                        <Select
                                            fullWidth
                                            labelId="status-select"
                                            id="demo-simple-select"
                                            value={facility.ownershipStatus}
                                            size="small"
                                            onChange={(e) => {
                                                setFieldValue(
                                                    `answers.${formikKey}.fields[${idx}].ownershipStatus`,
                                                    e.target.value
                                                );
                                            }}
                                        >
                                            {status.map((s) => (
                                                <MenuItem key={s} value={s}>
                                                    {s}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        fullWidth
                                        autoComplete="off"
                                        value={facility.ownershipPercentage}
                                        type="number"
                                        label={columns[2]}
                                        onChange={(e) => {
                                            setFieldValue(
                                                `answers.${formikKey}.fields[${idx}].ownershipPercentage`,
                                                e.target.value
                                            );
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">%</InputAdornment>
                                            ),
                                        }}
                                    />
                                </TableCell>
                                <TableCell>
                                    <IconButton
                                        onClick={() => {
                                            showModal(MODAL_TYPE.CONFIRMATION, {
                                                title: `Are you sure you want to remove this facility?`,
                                                content: 'This action is irreversible.',
                                                confirm: () => deleteRow(idx),
                                            });
                                        }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                        <TableCell
                            colSpan={4}
                            sx={{
                                pb: 0,
                            }}
                        >
                            <Button
                                variant="text"
                                startIcon={<AddIcon />}
                                onClick={() => {
                                    setFieldValue(`answers.${formikKey}`, {
                                        fields: [
                                            ...(values.answers[formikKey]?.fields ?? []),
                                            {
                                                facilityName: '',
                                                ownershipStatus: '',
                                                ownershipPercentage: '',
                                            },
                                        ],
                                        others: values.answers[formikKey]?.others,
                                    });
                                }}
                            >
                                Add Facility
                            </Button>
                        </TableCell>
                        <TableRow>
                            <TableCell colSpan={4}>
                                <TextField
                                    fullWidth
                                    autoComplete="off"
                                    value={values.answers[formikKey].others}
                                    label="Other Remarks"
                                    onChange={(e) => {
                                        setFieldValue(
                                            `answers.${formikKey}.others`,
                                            e.target.value
                                        );
                                    }}
                                />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Grid>
        </ImpSurveySection>
    );
}

export default withFormikContext(QListOfFacilities);
