import { Alert, Grid } from '@mui/material';
import React from 'react';
import SurveyQuestionHeadline from '../components/SurveyQuestionHeadline';
import { SURVEY_BUTTON_TYPES, SurveyButton } from '../components/SurveyButton';
import {
    withFormikContext,
    withStepAndValidation,
    withSurveySection,
} from '../components/SurveyStep';

function QScopeHas({ values, setFieldValue, year }) {
    const hasScope12FullReport = values?.details?.hasScope12FullReport;
    const setHasScope12FullReport = (newState) =>
        setFieldValue('details.hasScope12FullReport', newState);

    const datalines = values?.details?.datalines;
    const disabledStep3 = datalines?.length > 0 && hasScope12FullReport === false;

    return (
        <>
            <Grid item xs={12}>
                <SurveyQuestionHeadline>
                    Have you quantified the total Scope 1 and Scope 2 CO2mte (carbon dioxide metric
                    tons equivalent) emissions associated with your business in {year}?
                </SurveyQuestionHeadline>
            </Grid>
            {disabledStep3 === true && (
                <Grid item xs={12}>
                    <Alert sx={{ mt: 1 }} severity="warning">
                        You already provided information in the &quot;GHG Emissions calculator&quot;
                        section.
                        <br />
                        If you provide information here, GHG Emissions calculator inputs will be
                        ignored.
                    </Alert>
                </Grid>
            )}
            <Grid item xs={6}>
                <SurveyButton
                    type={SURVEY_BUTTON_TYPES.YES}
                    active={hasScope12FullReport === true}
                    onClick={() => {
                        setHasScope12FullReport(true);
                    }}
                >
                    Yes
                </SurveyButton>
            </Grid>
            <Grid item xs={6}>
                <SurveyButton
                    type={SURVEY_BUTTON_TYPES.NO}
                    active={hasScope12FullReport === false}
                    onClick={() => {
                        setHasScope12FullReport(false);
                    }}
                >
                    No
                </SurveyButton>
            </Grid>
        </>
    );
}

export default withStepAndValidation(
    withSurveySection(withFormikContext(QScopeHas)),
    4,
    ['details.hasScope12FullReport'],
    // no validation
    (values, errors) => {
        if (typeof values?.details?.hasScope12FullReport === 'undefined') {
            // eslint-disable-next-line no-param-reassign
            errors['details.hasScope12FullReport'] = 'Please select an option.';
        }
        return errors;
    },
    // always active
    (values) => values?.details?.haveExactData === false
);
