import React from 'react';
import { Grid, Tab, Tabs } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

const EsgEditorSwitcher = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    let selectedTab = -1;
    if (pathname.startsWith('/esgcategories')) {
        selectedTab = 0;
    }
    if (pathname.startsWith('/esgframeworks')) {
        selectedTab = 1;
    }
    if (pathname.startsWith('/esgquestions')) {
        selectedTab = 2;
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Tabs
                    value={selectedTab}
                    centered
                    TabIndicatorProps={{
                        style: {
                            // remove underline since position not follows sidebar change
                            display: 'none',
                        },
                    }}
                >
                    <Tab
                        label="Category Editor"
                        onClick={(e) => {
                            navigate('/esgcategories');
                            e.stopPropagation();
                        }}
                    />
                    <Tab
                        label="Framework Editor"
                        onClick={(e) => {
                            navigate('/esgframeworks');
                            e.stopPropagation();
                        }}
                    />
                    <Tab
                        label="Question Editor"
                        onClick={(e) => {
                            navigate('/esgquestions');
                            e.stopPropagation();
                        }}
                    />
                </Tabs>
            </Grid>
        </Grid>
    );
};

export default EsgEditorSwitcher;
