import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import CustomTreeItem from '../../../../components/atom/CustomTreeItem';
import SourcePickerSourceLevel from './SourcePickerSourceLevel';

function SourcePickerSubcategoryLevel({
    checkedData,
    setCheckedData,
    scope,
    category,
    subcategory,
    setCheckedChildren,
    getCheckedChildrenNumber,
}) {
    const checkedChildren = getCheckedChildrenNumber(subcategory);
    const subcategoryLabel =
        checkedChildren === 0
            ? `${subcategory.name}`
            : `${subcategory.name} (+ ${checkedChildren} more)`;
    return (
        <CustomTreeItem
            nodeId={`subcategory - ${subcategory.id}`}
            label={
                <FormControlLabel
                    label={subcategoryLabel}
                    labelPlacement="end"
                    control={
                        <Checkbox
                            key={`${subcategory.id} - ${subcategory.name}`}
                            checked={
                                !!checkedData.find(
                                    (c) => c.id === subcategory.id && c.type === 'subcategory'
                                ) || // if category is checked each subcategory is selected
                                !!checkedData.find(
                                    (c) => c.id === category.id && c.type === 'category'
                                ) ||
                                // if scope is checked each category is selected
                                !!checkedData.find((c) => c.id === scope.id && c.type === 'scope')
                            }
                            // disable if one of the parent is selected
                            disabled={
                                !!checkedData.find(
                                    (c) => c.id === scope.id && c.type === 'scope'
                                ) ||
                                !!checkedData.find(
                                    (c) => c.id === category.id && c.type === 'category'
                                )
                            }
                            onChange={() => {
                                setCheckedData((old) => {
                                    const findCurrent = old.find(
                                        (item) =>
                                            item.id === subcategory.id &&
                                            item.type === 'subcategory'
                                    );
                                    if (findCurrent) {
                                        return old.filter((data) => data !== findCurrent);
                                    }
                                    const res = setCheckedChildren(subcategory, old);
                                    return [
                                        ...res,

                                        {
                                            type: 'subcategory',
                                            id: subcategory.id,
                                        },
                                    ];
                                });
                            }}
                        />
                    }
                />
            }
        >
            {subcategory.children.map((source) => (
                <SourcePickerSourceLevel
                    key={`${source.id} - ${source.name}`}
                    checkedData={checkedData}
                    scope={scope}
                    category={category}
                    subcategory={subcategory}
                    source={source}
                    setCheckedData={setCheckedData}
                />
            ))}
        </CustomTreeItem>
    );
}

export default SourcePickerSubcategoryLevel;
