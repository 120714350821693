import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import CommonButton from '../components/atom/CommonButton';
import EsgFrameworkFilter from './components/esg/EsgFrameworkFilter';
import { useEsgFrameworkFilters } from '../hooks/useEsgFrameworkFilters';

const GlobalEsgFrameworkFilterModal = ({ title, showModal, MODAL_TYPE }) => {
    const { setEsgFrameworkFilters, setEdited } = useEsgFrameworkFilters();
    const closeAction = () => showModal(MODAL_TYPE.NONE);

    const handleClear = () => {
        setEsgFrameworkFilters({
            esgFrameworkFilter: null,
            esgFrameworkNameFilter: null,
        });
        setEdited(false);
    };

    return (
        <Dialog open onClose={closeAction} maxWidth="xs" fullWidth>
            <DialogTitle sx={{ alignSelf: 'center' }}>{title}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} sx={{ pt: 1 }}>
                    <Grid item xs={12}>
                        <EsgFrameworkFilter />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <CommonButton
                            onClick={closeAction}
                            fullWidth
                            label="Close"
                            buttonType="secondary"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <CommonButton
                            onClick={handleClear}
                            fullWidth
                            label="Clear"
                            buttonType="primary"
                        />
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};

export default GlobalEsgFrameworkFilterModal;
