import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ComponentTitle from '../../components/ComponentTitle';
import { useApi } from '../../hooks/useApi';
import useNotify from '../../hooks/useNotify';
import CompanyTagsTable from './components/CompanyTagsTable';
import useSorting from '../../hooks/useSorting';
import { TABLE_ROW_COUNT_DEFAULT } from '../../helpers/constans';
import CommonButton from '../../components/atom/CommonButton';

const CompanyTagsPage = () => {
    const navigate = useNavigate();
    const { notifyError } = useNotify();
    const { sort, sortDir, setSorting } = useSorting(['created_at', 'ASC']);
    const [{ limit, page }, setParams] = useState({ limit: TABLE_ROW_COUNT_DEFAULT, page: 0 });
    const [data, loading, error, setData, reloadData] = useApi('CompanyTags', 'getCompanyTags', {
        limit,
        pageSize: limit,
        page,
        sort,
        sortDir,
    });

    useEffect(() => {
        if (error) {
            notifyError(error);
        }
    }, [error, notifyError]);

    return (
        <Grid container alignContent="flex-start">
            <Grid container item xs={12} justifyContent="space-between" spacing={0}>
                <Grid item>
                    <ComponentTitle variant="h4" title="Company tags" />
                </Grid>
                <Grid item>
                    <CommonButton
                        fullWidth
                        onClick={() => navigate('/companytags/new')}
                        label="Add new company tag"
                        buttonType="primary"
                    />
                </Grid>
            </Grid>
            <CompanyTagsTable
                rows={data?.companyTags}
                limit={limit}
                page={page}
                setParams={setParams}
                total={data?.total}
                loading={loading}
                reloadData={reloadData}
                sort={sort}
                sortDir={sortDir}
                setSorting={setSorting}
            />
        </Grid>
    );
};

export default CompanyTagsPage;
