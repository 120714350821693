import { Grid, IconButton, Tooltip, Typography } from '@mui/material';
import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import SkeletonForm from '../../../components/atom/SkeletonForm';
import { MODAL_TYPE, useModals } from '../../../hooks/useModal';
import BlockWithTitle from '../../../components/atom/BlockWithTitle';
import { doSwaggerCall } from '../../../hooks/useApi';
import useNotify from '../../../hooks/useNotify';
import CommonButton from '../../../components/atom/CommonButton';
import { WARNING_CONTENT_RECALCULATE_TEXT } from '../../../helpers/constans';

const EmissionSourceList = ({ data, loading, reloadData, year, templateId }) => {
    const { showModal } = useModals();
    const { notifySuccess, notifyError } = useNotify();
    const emptyLogics = data?.conversionLogics.length === 0;

    const deleteConnection = async (emissionSourceId) => {
        showModal(MODAL_TYPE.CONFIRMATION, {
            title: `Are you sure you want to delete this connection?`,
            warningContent: WARNING_CONTENT_RECALCULATE_TEXT,
            confirm: () => {
                doSwaggerCall('EmissionSources', 'removeEmissionSourceConversionTemplate', {
                    id: emissionSourceId,
                    conversionTemplateId: parseInt(templateId, 10),
                })
                    .then(() => {
                        reloadData();
                        notifySuccess('Connection deleted');
                    })
                    .catch((err) => notifyError(err));
            },
        });
    };

    return (
        <BlockWithTitle title="Emission Sources">
            {data?.emissionSources?.length === 0 || emptyLogics ? (
                <Grid item xs={12} sx={{ pt: 1, pb: 1 }}>
                    {loading ? (
                        <SkeletonForm rows={2} />
                    ) : (
                        <Typography variant="body1">
                            There is no emission source applied to this template
                        </Typography>
                    )}
                </Grid>
            ) : (
                data?.emissionSources?.map((emissionSource) => (
                    <>
                        <Grid item xs={10} sx={{ pt: 1 }}>
                            <Tooltip title={emissionSource.levelHierarchy} placement="top">
                                <Typography>{emissionSource.name}</Typography>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={2} sx={{ pt: 1 }}>
                            <IconButton onClick={() => deleteConnection(emissionSource.id)}>
                                <DeleteIcon />
                            </IconButton>
                        </Grid>
                    </>
                ))
            )}
            <Grid item xs={12}>
                <CommonButton
                    fullWidth
                    onClick={() => {
                        showModal(MODAL_TYPE.ADD_CONVERSION_LOGIC_TEMPLATE_TO_EMISSION_SOURCE, {
                            title: `Add conversion logic template(s) to Emission Source`,
                            content: `Selected template(s) will override the current logics.`,
                            warningContent: WARNING_CONTENT_RECALCULATE_TEXT,
                            unitConversionLogic: data.conversionLogics,
                            reloadData,
                            year,
                            initiallyChecked: data?.emissionSources
                                ? [
                                      ...data.emissionSources.map((e) => ({
                                          type: 'source',
                                          id: e.id,
                                      })),
                                  ]
                                : [],
                            conversionTemplateId: templateId,
                        });
                    }}
                    disabled={emptyLogics}
                    label="Select emission source"
                    buttonType="primary"
                />
            </Grid>
        </BlockWithTitle>
    );
};

export default EmissionSourceList;
