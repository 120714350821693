import {
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip,
} from '@mui/material';
import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SkeletonTable from '../../../components/atom/SkeletonTable';
import { MODAL_TYPE, useModals } from '../../../hooks/useModal';
import { doSwaggerCall } from '../../../hooks/useApi';
import useNotify from '../../../hooks/useNotify';
import SortableTableCell from '../../../components/atom/SortableTableCell';
import { TABLE_ROW_COUNT_OPTIONS } from '../../../helpers/constans';
import { handleChangeRowsPerPage, handlePageChange } from '../../../helpers/handleTableFunctions';

const ConversionLogicTemplatesTable = ({
    rows,
    limit,
    page,
    setParams,
    total,
    loading,
    reloadData,
    sort,
    sortDir,
    setSorting,
}) => {
    const { showModal } = useModals();
    const { notifyError, notifySuccess } = useNotify();

    const deleteConversionLogicTemplate = useCallback((id) => {
        doSwaggerCall('ConversionTemplates', 'deleteConversionTemplate', { id })
            .then(() => {
                notifySuccess('Conversion logic template deleted');
                reloadData();
            })
            .catch(() => {
                notifyError('Cannot delete conversion logic template');
            });
    }, []);

    return (
        <Grid item xs={12}>
            <TableContainer component={Paper}>
                <Table aria-label="custom pagination table">
                    <TableHead>
                        <TableRow>
                            <SortableTableCell
                                arrow={sort === 'name' ? sortDir : ''}
                                onClick={() => setSorting('name')}
                            >
                                Name
                            </SortableTableCell>
                            <TableCell>Conversion logic names</TableCell>
                            <SortableTableCell
                                arrow={sort === 'base_unit' ? sortDir : ''}
                                onClick={() => setSorting('base_unit')}
                            >
                                Base unit
                            </SortableTableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <SkeletonTable rows={limit} cells={4} />
                        ) : (
                            rows?.map((row) => (
                                <TableRow key={row.id}>
                                    <TableCell>{row.name}</TableCell>
                                    <TableCell>
                                        {row.conversion_logics
                                            .map((logic) => logic.name)
                                            .join(', ')}
                                    </TableCell>
                                    <TableCell>{row.base_unit}</TableCell>
                                    <TableCell align="right">
                                        <Link to={`/conversionlogictemplates/edit/${row.id}`}>
                                            <Tooltip title="Edit">
                                                <IconButton>
                                                    <EditIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Link>
                                        <IconButton
                                            onClick={() => {
                                                showModal(MODAL_TYPE.CONFIRMATION, {
                                                    title: `Are you sure you want to remove this conversion logic template?`,
                                                    content: 'This action is irreversible.',
                                                    confirm: () =>
                                                        deleteConversionLogicTemplate(row.id),
                                                });
                                            }}
                                        >
                                            <Tooltip title="Delete">
                                                <DeleteIcon />
                                            </Tooltip>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                count={total || 0}
                rowsPerPage={limit}
                component="div"
                page={page}
                onPageChange={(_, newPage) => handlePageChange(newPage, setParams, limit)}
                rowsPerPageOptions={TABLE_ROW_COUNT_OPTIONS}
                onRowsPerPageChange={(event) => handleChangeRowsPerPage(event, setParams)}
            />
        </Grid>
    );
};

export default ConversionLogicTemplatesTable;
