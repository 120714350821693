import { Checkbox, FormControlLabel, Grid, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import AutocompleteSelect from '../atom/AutoCompleteSelect';
import {
    categoryAutocomplete,
    datalineTagAutocomplete,
    scopeAutocomplete,
    siteAutocomplete,
    intensityMetricsAutocomplete,
    scenarioAutocomplete,
    sourceAutocomplete,
    supplierAutocomplete,
    subcategoryAutocomplete,
} from '../../autocomplete';
import YearSelector from './components/YearSelector';
import { doSwaggerCall } from '../../hooks/useApi';
import useNotify from '../../hooks/useNotify';
import EsgQuestionSelector from './components/EsgQuestionSelector';
import { groupCategory, groupEmissionSource, groupSubcategory } from '../../helpers/groups';

const ExtraFieldSelector = ({ selectedPanel, panelConfig, setPanelConfig }) => {
    const [esgFields, setEsgFields] = useState(panelConfig?.fields || []);
    const [loading, setLoading] = useState(false);
    const { notifyError } = useNotify();

    useEffect(() => {
        setLoading(false);
        if (selectedPanel === 'esg_question_bar_chart') {
            if (!panelConfig.esgQuestion) {
                setEsgFields([]);
                return;
            }
            setLoading(true);
            doSwaggerCall('EsgQuestions', 'getAllEsgQuestions', {
                idList: [panelConfig.esgQuestion],
            })
                .then((res) => {
                    setEsgFields(
                        res.esgQuestions[0]?.fields.filter((f) => f.type === 'number') || []
                    );
                    setLoading(false);
                })
                .catch((err) => {
                    notifyError(err);
                    setLoading(false);
                });
        }
    }, [selectedPanel, panelConfig?.esgQuestion]);

    return (
        (selectedPanel === 'scope_line_chart' && (
            <Grid item xs={12}>
                <AutocompleteSelect
                    initCall={scopeAutocomplete.init}
                    multiple
                    autocompleteCall={scopeAutocomplete.search}
                    value={panelConfig?.scopes || []}
                    setValue={(ev) => {
                        setPanelConfig({ ...panelConfig, scopes: ev });
                    }}
                >
                    <TextField label="Scopes" fullWidth />
                </AutocompleteSelect>
            </Grid>
        )) ||
        (selectedPanel === 'category_bar_chart' && (
            <>
                <Grid item xs={12}>
                    <AutocompleteSelect
                        grouping={groupCategory}
                        initCall={categoryAutocomplete.init}
                        multiple
                        autocompleteCall={categoryAutocomplete.search}
                        value={panelConfig?.categories || []}
                        setValue={(ev) => {
                            setPanelConfig({ ...panelConfig, categories: ev });
                        }}
                        searchParams={{
                            sourceLimitation: true,
                        }}
                    >
                        <TextField label="Categories" fullWidth />
                    </AutocompleteSelect>
                </Grid>
                <YearSelector
                    panelConfig={panelConfig}
                    setPanelConfig={setPanelConfig}
                    multiSelect
                />
            </>
        )) ||
        (selectedPanel === 'tag_bar_chart' && (
            <>
                <Grid item xs={12}>
                    <AutocompleteSelect
                        initCall={datalineTagAutocomplete.init}
                        multiple
                        autocompleteCall={datalineTagAutocomplete.search}
                        value={panelConfig?.tags || []}
                        setValue={(ev) => {
                            setPanelConfig({ ...panelConfig, tags: ev });
                        }}
                    >
                        <TextField label="Tags" fullWidth />
                    </AutocompleteSelect>
                </Grid>
                <YearSelector
                    panelConfig={panelConfig}
                    setPanelConfig={setPanelConfig}
                    multiSelect
                />
            </>
        )) ||
        (selectedPanel === 'scope_pie_chart' && (
            <>
                <Grid item xs={12}>
                    <AutocompleteSelect
                        initCall={scopeAutocomplete.init}
                        autocompleteCall={scopeAutocomplete.search}
                        value={panelConfig?.scope || ''}
                        setValue={(ev) => {
                            setPanelConfig({ ...panelConfig, scope: ev });
                        }}
                    >
                        <TextField label="Scope" fullWidth />
                    </AutocompleteSelect>
                </Grid>
                <YearSelector
                    panelConfig={panelConfig}
                    setPanelConfig={setPanelConfig}
                    multiSelect={false}
                />
            </>
        )) ||
        (selectedPanel === 'category_pie_chart' && (
            <>
                <Grid item xs={12}>
                    <AutocompleteSelect
                        grouping={groupCategory}
                        initCall={categoryAutocomplete.init}
                        autocompleteCall={categoryAutocomplete.search}
                        value={panelConfig?.category || ''}
                        setValue={(ev) => {
                            setPanelConfig({ ...panelConfig, category: ev });
                        }}
                        searchParams={{
                            sourceLimitation: true,
                        }}
                    >
                        <TextField label="Category" fullWidth />
                    </AutocompleteSelect>
                </Grid>
                <YearSelector
                    panelConfig={panelConfig}
                    setPanelConfig={setPanelConfig}
                    multiSelect={false}
                />
            </>
        )) ||
        (selectedPanel === 'subcategory_pie_chart' && (
            <>
                <Grid item xs={12}>
                    <AutocompleteSelect
                        grouping={groupSubcategory}
                        initCall={subcategoryAutocomplete.init}
                        autocompleteCall={subcategoryAutocomplete.search}
                        value={panelConfig?.subcategory || ''}
                        setValue={(ev) => {
                            setPanelConfig({ ...panelConfig, subcategory: ev });
                        }}
                        searchParams={{
                            sourceLimitation: true,
                        }}
                    >
                        <TextField label="Subcategory" fullWidth />
                    </AutocompleteSelect>
                </Grid>
                <YearSelector
                    panelConfig={panelConfig}
                    setPanelConfig={setPanelConfig}
                    multiSelect={false}
                />
            </>
        )) ||
        (selectedPanel === 'site_pie_chart' && (
            <>
                <Grid item xs={12}>
                    <AutocompleteSelect
                        initCall={siteAutocomplete.init}
                        autocompleteCall={siteAutocomplete.search}
                        value={panelConfig?.site}
                        setValue={(ev) => {
                            setPanelConfig({ ...panelConfig, site: ev });
                        }}
                    >
                        <TextField label="Site" fullWidth />
                    </AutocompleteSelect>
                </Grid>
                <YearSelector
                    panelConfig={panelConfig}
                    setPanelConfig={setPanelConfig}
                    multiSelect={false}
                />
            </>
        )) ||
        (selectedPanel === 'site_bar_chart' && (
            <>
                <Grid item xs={12}>
                    <AutocompleteSelect
                        initCall={siteAutocomplete.init}
                        multiple
                        autocompleteCall={siteAutocomplete.search}
                        value={panelConfig?.sites || []}
                        setValue={(ev) => {
                            setPanelConfig({ ...panelConfig, sites: ev });
                        }}
                    >
                        <TextField label="Sites" fullWidth />
                    </AutocompleteSelect>
                </Grid>
                <Grid item xs={12}>
                    <AutocompleteSelect
                        initCall={scopeAutocomplete.init}
                        multiple
                        autocompleteCall={scopeAutocomplete.search}
                        value={panelConfig?.scopes || []}
                        setValue={(ev) => {
                            setPanelConfig({ ...panelConfig, scopes: ev });
                        }}
                    >
                        <TextField label="Scopes" fullWidth />
                    </AutocompleteSelect>
                </Grid>
                <Grid item xs={12}>
                    <AutocompleteSelect
                        grouping={groupCategory}
                        initCall={categoryAutocomplete.init}
                        multiple
                        autocompleteCall={categoryAutocomplete.search}
                        value={panelConfig?.categories || []}
                        setValue={(ev) => {
                            setPanelConfig({ ...panelConfig, categories: ev });
                        }}
                        searchParams={{
                            sourceLimitation: true,
                        }}
                    >
                        <TextField label="Categories" fullWidth />
                    </AutocompleteSelect>
                </Grid>
                <YearSelector
                    panelConfig={panelConfig}
                    setPanelConfig={setPanelConfig}
                    multiSelect={false}
                />
            </>
        )) ||
        (selectedPanel === 'scenario_line_chart' && (
            <Grid item xs={12}>
                <AutocompleteSelect
                    initCall={scenarioAutocomplete.init} // TODO: later we need some validation for scenario compare
                    multiple
                    autocompleteCall={scenarioAutocomplete.search}
                    value={panelConfig?.scenarios || []}
                    setValue={(ev) => {
                        setPanelConfig({ ...panelConfig, scenarios: ev });
                    }}
                >
                    <TextField label="Scenarios" fullWidth />
                </AutocompleteSelect>
            </Grid>
        )) ||
        (selectedPanel === 'source_bar_chart' && (
            <>
                <Grid item xs={12}>
                    <AutocompleteSelect
                        grouping={groupEmissionSource}
                        initCall={sourceAutocomplete.init}
                        autocompleteCall={sourceAutocomplete.search}
                        value={panelConfig?.source || ''}
                        setValue={(ev) => {
                            setPanelConfig({ ...panelConfig, source: ev });
                        }}
                        searchParams={{
                            sourceLimitation: true,
                        }}
                    >
                        <TextField label="Source" fullWidth />
                    </AutocompleteSelect>
                </Grid>
                <YearSelector
                    panelConfig={panelConfig}
                    setPanelConfig={setPanelConfig}
                    multiSelect
                />
            </>
        )) ||
        (selectedPanel === 'supplier_bar_chart' && (
            <>
                <Grid item xs={12}>
                    <AutocompleteSelect
                        initCall={supplierAutocomplete.init}
                        multiple
                        autocompleteCall={supplierAutocomplete.search}
                        value={panelConfig?.suppliers || []}
                        setValue={(ev) => {
                            setPanelConfig({ ...panelConfig, suppliers: ev });
                        }}
                    >
                        <TextField label="Suppliers" fullWidth />
                    </AutocompleteSelect>
                </Grid>
                <YearSelector
                    panelConfig={panelConfig}
                    setPanelConfig={setPanelConfig}
                    multiSelect
                />
            </>
        )) ||
        (selectedPanel === 'scopes_per_intensity_line_chart' && (
            <>
                <Grid item xs={12}>
                    <AutocompleteSelect
                        initCall={scopeAutocomplete.init}
                        multiple
                        autocompleteCall={scopeAutocomplete.search}
                        value={panelConfig?.scopes || []}
                        setValue={(ev) => {
                            setPanelConfig({ ...panelConfig, scopes: ev });
                        }}
                    >
                        <TextField label="Scopes" fullWidth />
                    </AutocompleteSelect>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        label="Aggregate selected scopes"
                        control={
                            <Checkbox
                                checked={panelConfig?.aggregated || false}
                                onChange={(e) =>
                                    setPanelConfig({
                                        ...panelConfig,
                                        aggregated: !!e.target.checked,
                                    })
                                }
                            />
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <AutocompleteSelect
                        initCall={intensityMetricsAutocomplete.init}
                        autocompleteCall={intensityMetricsAutocomplete.search}
                        value={panelConfig?.intensityMetric || ''}
                        setValue={(ev) => {
                            setPanelConfig({ ...panelConfig, intensityMetric: ev });
                        }}
                    >
                        <TextField label="Intensity metric" fullWidth />
                    </AutocompleteSelect>
                </Grid>
                <YearSelector
                    panelConfig={panelConfig}
                    setPanelConfig={setPanelConfig}
                    multiSelect
                />
            </>
        )) ||
        (selectedPanel === 'supplier_netzero_targets_bar_chart' && (
            <YearSelector panelConfig={panelConfig} setPanelConfig={setPanelConfig} />
        )) ||
        (selectedPanel === 'esg_question_bar_chart' && (
            <EsgQuestionSelector
                panelConfig={panelConfig}
                setPanelConfig={setPanelConfig}
                loading={loading}
                esgFields={esgFields}
            />
        ))
    );
};

export default ExtraFieldSelector;
