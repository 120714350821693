import {
    Checkbox,
    CircularProgress,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
} from '@mui/material';
import React from 'react';
import AutocompleteSelect from '../../atom/AutoCompleteSelect';
import { esgQuestionAutocomplete, siteAutocomplete } from '../../../autocomplete';
import YearSelector from './YearSelector';
import { filterJsonConstantsByMeasure } from '../../../helpers/esgUnitConversions';
import { useDarkMode } from '../../../hooks/useDarkMode';
import { groupQuestion } from '../../../helpers/groups';

const isChecked = (panelConfig, id) => {
    if (!panelConfig?.fields) {
        return false;
    }
    return panelConfig.fields.map((f) => f.id).includes(id);
};

const EsgQuestionSelector = ({ panelConfig, setPanelConfig, loading, esgFields }) => {
    const { darkMode } = useDarkMode();
    const darkModeColor = darkMode ? '#FFFFFF80' : '#00000061';

    return (
        <>
            <Grid item xs={12}>
                <AutocompleteSelect
                    grouping={groupQuestion}
                    initCall={esgQuestionAutocomplete.init}
                    autocompleteCall={esgQuestionAutocomplete.search}
                    value={panelConfig?.esgQuestion || ''}
                    searchParams={{
                        onlyNumeric: true,
                    }}
                    setValue={(ev, getItem) => {
                        const item = getItem(ev);
                        setPanelConfig({
                            ...panelConfig,
                            esgQuestion: item?.id,
                            fields:
                                item?.fields
                                    .filter((field) => field.type === 'number')
                                    .map((field) => ({
                                        id: field.id,
                                        measure: field.defaultMeasure,
                                    })) || [],
                        });
                    }}
                >
                    <TextField label="Esg question (only numeric types)" fullWidth />
                </AutocompleteSelect>
            </Grid>
            <Grid item xs={12}>
                <AutocompleteSelect
                    initCall={siteAutocomplete.init}
                    multiple
                    autocompleteCall={siteAutocomplete.search}
                    value={panelConfig?.sites || []}
                    setValue={(ev) => {
                        setPanelConfig({ ...panelConfig, sites: ev });
                    }}
                >
                    <TextField label="Sites" fullWidth />
                </AutocompleteSelect>
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel
                    label="Company wide total"
                    control={
                        <Checkbox
                            checked={panelConfig?.includeTotal || false}
                            onChange={(e) =>
                                setPanelConfig({
                                    ...panelConfig,
                                    includeTotal: !!e.target.checked,
                                })
                            }
                        />
                    }
                />
            </Grid>
            {loading ? (
                <Grid item xs={12}>
                    <CircularProgress size={15} />
                </Grid>
            ) : (
                <Grid item xs={12}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell width="20%">Show</TableCell>
                                <TableCell width="40%">Field</TableCell>
                                <TableCell width="40%">Unit</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {esgFields.map((field) => (
                                <TableRow key={field.id}>
                                    <TableCell>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={isChecked(panelConfig, field.id)}
                                                    onChange={(e) => {
                                                        const fields = panelConfig?.fields || [];
                                                        if (e.target.checked) {
                                                            fields.push({
                                                                id: field.id,
                                                                measure: field.defaultMeasure,
                                                            });
                                                        } else {
                                                            fields.splice(
                                                                fields.findIndex(
                                                                    (f) => f.id === field.id
                                                                ),
                                                                1
                                                            );
                                                        }
                                                        setPanelConfig({
                                                            ...panelConfig,
                                                            fields,
                                                        });
                                                    }}
                                                />
                                            }
                                        />
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            color: !isChecked(panelConfig, field.id)
                                                ? darkModeColor
                                                : '',
                                        }}
                                    >
                                        {field.label}
                                    </TableCell>
                                    <TableCell>
                                        <FormControl size="small" fullWidth>
                                            <InputLabel id="measure-select" />
                                            <Select
                                                disabled={!isChecked(panelConfig, field.id)}
                                                labelId="measure-select"
                                                className="question-field"
                                                value={
                                                    panelConfig.fields?.find(
                                                        (f) => f.id === field.id
                                                    )?.measure || field.defaultMeasure
                                                }
                                                size="small"
                                                onChange={(e) => {
                                                    const newValue = panelConfig.fields.map((f) => {
                                                        if (f.id === field.id) {
                                                            return {
                                                                ...f,
                                                                measure: e.target.value,
                                                            };
                                                        }
                                                        return f;
                                                    });
                                                    setPanelConfig({
                                                        ...panelConfig,
                                                        fields: newValue,
                                                    });
                                                }}
                                            >
                                                {filterJsonConstantsByMeasure(
                                                    panelConfig.fields?.find(
                                                        (f) => f.id === field.id
                                                    )?.measure || field.defaultMeasure
                                                ).map((item) => (
                                                    <MenuItem key={item.name} value={item.name}>
                                                        {item.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Grid>
            )}
            <YearSelector panelConfig={panelConfig} setPanelConfig={setPanelConfig} multiSelect />
        </>
    );
};

export default EsgQuestionSelector;
