import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import CustomTreeItem from '../../../../../components/atom/CustomTreeItem';

function TargetPickerCategoryLevel({ checkedData, category, scope, setCheckedData }) {
    return (
        <CustomTreeItem
            nodeId={`category - ${category.id}`}
            label={
                <FormControlLabel
                    label={category.name}
                    labelPlacement="end"
                    control={
                        <Checkbox
                            key={`${category.id} - ${category.name}`}
                            checked={
                                !!checkedData.find(
                                    (c) => c.id === category.id && c.type === 'category'
                                ) ||
                                // if scope is checked each category is selected
                                !!checkedData.find((c) => c.id === scope.id && c.type === 'scope')
                            }
                            // if scope is checked each category is selected and categories are disabled
                            disabled={
                                !!checkedData.find((c) => c.id === scope.id && c.type === 'scope')
                            }
                            onChange={() => {
                                setCheckedData((old) => {
                                    const findCurrent = old.find(
                                        (item) =>
                                            item.id === category.id && item.type === 'category'
                                    );
                                    if (findCurrent) {
                                        return old.filter((data) => data !== findCurrent);
                                    }

                                    return [
                                        ...old,
                                        {
                                            type: 'category',
                                            id: category.id,
                                            scopeId: scope.id,
                                        },
                                    ];
                                });
                            }}
                        />
                    }
                />
            }
        />
    );
}

export default TargetPickerCategoryLevel;
