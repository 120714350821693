import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useApi } from '../hooks/useApi';
import useNotify from '../hooks/useNotify';
import CommonButton from '../components/atom/CommonButton';
import { useAuth } from '../hooks/useAuth';
import { useGlobalFilters } from '../hooks/useGlobalFilters';
import AutocompleteSelect from '../components/atom/AutoCompleteSelect';
import {
    categoryAutocomplete,
    scopeAutocomplete,
    sourceAutocomplete,
    subcategoryAutocomplete,
} from '../autocomplete';
import LocalSiteFilter from '../pages/report/components/LocalSiteFilter';
import { groupCategory, groupEmissionSource, groupSubcategory } from '../helpers/groups';

function setDialogContent(isScenario) {
    return isScenario
        ? 'Select the type of the report that you would like to generate for this scenario.'
        : 'Optionally, you can use filters to narrow down the data that will be included in the report.';
}

function SelectReportModal({ showModal, MODAL_TYPE, isScenario = false, confirm }) {
    const { notifyError } = useNotify();
    const { user } = useAuth();
    const { availableYears } = useGlobalFilters();
    const closeAction = () => showModal(MODAL_TYPE.NONE);
    const reportTypes = ['XLSX', 'PDF', 'HTML'].filter((type) =>
        isScenario ? type !== 'XLSX' : type
    );
    const [reportType, setReportType] = useState(reportTypes[0]);
    const [selectTag, setSelectTag] = useState(false);
    const [siteIdList, setSiteIdList] = useState([]);
    const [reportTagId, setReportTagId] = useState(null);
    const [selectedScope, setSelectedScope] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedSubcategory, setSelectedSubcategory] = useState(null);
    const [selectedSource, setSelectedSource] = useState(null);
    const [year, setYear] = useState(
        parseInt(localStorage.getItem('year'), 10) || new Date().getFullYear() - 1
    );
    const [datalineTags, loading, error] = useApi('DatalineTags', 'getAllDatalineTags');

    useEffect(() => {
        if (error) {
            notifyError('Something went wrong');
        }
    }, [error, notifyError]);

    return (
        <Dialog
            aria-labelledby="select-tag-modal"
            open
            onClose={closeAction}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle>{`Generate ${isScenario ? 'Scenario' : 'Audit'} Report`}</DialogTitle>
            <DialogContent>
                <DialogContentText id="dialog-description">
                    {setDialogContent(isScenario)}
                </DialogContentText>
                {!isScenario && (
                    <>
                        <Grid item xs={12} sx={{ pt: 1 }}>
                            <Accordion defaultExpanded={false} sx={{ border: '1px solid grey' }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <InputLabel>Source filter</InputLabel>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid item xs={12}>
                                        <AutocompleteSelect
                                            initCall={scopeAutocomplete.init}
                                            autocompleteCall={scopeAutocomplete.search}
                                            value={selectedScope}
                                            setValue={(ev, getItem) => {
                                                const item = getItem(ev);
                                                if (!item) {
                                                    setSelectedScope(null);
                                                    setSelectedCategory(null);
                                                    setSelectedSubcategory(null);
                                                    setSelectedSource(null);
                                                } else {
                                                    setSelectedScope(item?.id || null);
                                                }
                                            }}
                                        >
                                            <TextField label="Scope" fullWidth size="small" />
                                        </AutocompleteSelect>
                                    </Grid>
                                    <Grid item xs={12} sx={{ pt: 1 }}>
                                        <AutocompleteSelect
                                            grouping={groupCategory}
                                            initCall={categoryAutocomplete.init}
                                            autocompleteCall={categoryAutocomplete.search}
                                            value={selectedCategory}
                                            setValue={(ev, getItem) => {
                                                const item = getItem(ev);
                                                if (!item) {
                                                    setSelectedCategory(null);
                                                    setSelectedSubcategory(null);
                                                    setSelectedSource(null);
                                                } else {
                                                    setSelectedScope(item?.scope_id || null);
                                                    setSelectedCategory(item?.id || null);
                                                }
                                            }}
                                            searchParams={{
                                                scope: selectedScope,
                                                sourceLimitation: true,
                                            }}
                                        >
                                            <TextField label="Category" fullWidth size="small" />
                                        </AutocompleteSelect>
                                    </Grid>
                                    <Grid item xs={12} sx={{ pt: 1 }}>
                                        <AutocompleteSelect
                                            grouping={groupSubcategory}
                                            initCall={subcategoryAutocomplete.init}
                                            autocompleteCall={subcategoryAutocomplete.search}
                                            value={selectedSubcategory}
                                            setValue={(ev, getItem) => {
                                                const item = getItem(ev);
                                                if (!item) {
                                                    setSelectedSubcategory(null);
                                                    setSelectedSource(null);
                                                } else {
                                                    setSelectedScope(item?.scope_id || null);
                                                    setSelectedCategory(item?.category_id || null);
                                                    setSelectedSubcategory(item?.id || null);
                                                }
                                            }}
                                            searchParams={{
                                                scope: selectedScope,
                                                category: selectedCategory,
                                                sourceLimitation: true,
                                            }}
                                        >
                                            <TextField label="Subcategory" fullWidth size="small" />
                                        </AutocompleteSelect>
                                    </Grid>
                                    <Grid item xs={12} sx={{ pt: 1 }}>
                                        <AutocompleteSelect
                                            grouping={groupEmissionSource}
                                            initCall={sourceAutocomplete.init}
                                            autocompleteCall={sourceAutocomplete.search}
                                            value={selectedSource}
                                            setValue={(ev, getItem) => {
                                                const item = getItem(ev);
                                                if (!item) {
                                                    setSelectedSource(null);
                                                } else {
                                                    setSelectedScope(item?.scope_id || null);
                                                    setSelectedCategory(item?.category_id || null);
                                                    setSelectedSubcategory(
                                                        item?.subcategory_id || null
                                                    );
                                                    setSelectedSource(item?.id || null);
                                                }
                                            }}
                                            searchParams={{
                                                scope: selectedScope,
                                                category: selectedCategory,
                                                subcategory: selectedSubcategory,
                                                year,
                                                sourceLimitation: true,
                                            }}
                                        >
                                            <TextField label="Source" fullWidth size="small" />
                                        </AutocompleteSelect>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        <Grid item xs={12} sx={{ pt: 1 }}>
                            <Accordion defaultExpanded={false} sx={{ border: '1px solid grey' }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    <InputLabel>Site filter</InputLabel>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <LocalSiteFilter
                                        siteIdList={siteIdList}
                                        setSiteIdList={setSiteIdList}
                                        companyId={user.company_id}
                                    />
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        <Grid item xs={12} sx={{ pt: 1 }}>
                            <FormControl fullWidth>
                                <InputLabel id="year-select">Year</InputLabel>
                                <Select
                                    fullWidth
                                    labelId="year-select"
                                    id="demo-simple-select"
                                    value={year}
                                    size="small"
                                    label="Year"
                                    onChange={(e) => {
                                        setYear(parseInt(e.target.value, 10));
                                    }}
                                >
                                    {availableYears.map((yearItem) => (
                                        <MenuItem key={yearItem.year} value={yearItem.year}>
                                            {yearItem.year}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sx={{ pt: 1 }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={selectTag}
                                        onChange={(e) => {
                                            if (selectTag) {
                                                setReportTagId(null);
                                            }
                                            setSelectTag(!!e.target.checked);
                                        }}
                                    />
                                }
                                label="Select a tag"
                                labelPlacement="end"
                            />
                        </Grid>
                    </>
                )}
                {selectTag && (
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="dataline-tag">Dataline tag</InputLabel>
                            {!loading ? (
                                <Select
                                    fullWidth
                                    labelId="dataline-tag"
                                    size="small"
                                    label="Filter by"
                                    value={reportTagId || ''}
                                    onChange={(e) => {
                                        setReportTagId(e.target.value);
                                    }}
                                >
                                    {datalineTags?.datalineTags.map((datalineTag) => {
                                        return (
                                            <MenuItem value={datalineTag.id} key={datalineTag.id}>
                                                {datalineTag.name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            ) : (
                                <CircularProgress size="1.5rem" />
                            )}
                        </FormControl>
                    </Grid>
                )}
                <Grid item xs={12} sx={{ pt: 1 }}>
                    <FormControl fullWidth>
                        <InputLabel id="report-type">Report type</InputLabel>
                        {!loading ? (
                            <Select
                                fullWidth
                                labelId="report-type"
                                size="small"
                                label="Report type"
                                value={reportType || ''}
                                onChange={(e) => {
                                    setReportType(e.target.value);
                                }}
                            >
                                {reportTypes.map((type) => {
                                    return (
                                        <MenuItem value={type} key={type}>
                                            {type}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        ) : (
                            <CircularProgress size="1.5rem" />
                        )}
                    </FormControl>
                </Grid>
            </DialogContent>
            <DialogActions>
                <CommonButton
                    onClick={() => showModal(MODAL_TYPE.NONE)}
                    label="Close"
                    buttonType="secondary"
                />
                <CommonButton
                    onClick={() => {
                        if (isScenario) {
                            confirm(reportType);
                        } else {
                            confirm(
                                reportTagId,
                                siteIdList,
                                reportType,
                                selectedScope,
                                selectedCategory,
                                selectedSubcategory,
                                selectedSource,
                                year
                            );
                        }
                        showModal(MODAL_TYPE.NONE);
                    }}
                    type="submit"
                    label="Save"
                    buttonType="primary"
                />
            </DialogActions>
        </Dialog>
    );
}

export default SelectReportModal;
