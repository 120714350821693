import React from 'react';
import { Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import ComponentTitle from '../../../components/ComponentTitle';
import { formatNumber } from '../../../helpers/convertUnits';
import SurveyProductTable from './SurveyProductTable';

const REPORT_TO_LABEL_MAPPING = {
    scope1Co2e: 'Scope 1',
    scope2Co2e: 'Scope 2',
    scope3Co2e: 'Scope 3',
};

/**
 * The emission is directly calculated by the company for the specific company
 */
function SurveyResultReport({ survey }) {
    return (
        <>
            <ComponentTitle
                variant="h5"
                title="Supplier directly calculated emission for the company"
            />
            <Grid item xs={12}>
                {survey?.details?.havePerScopeData === false && (
                    <Typography variant="body1">
                        The company did not provide the exact CO<sub>2</sub>e (mt) for each scope,
                        we have calculated it using a 15% - 15% - 70% (70% to Scope 3) split between
                        scopes.
                    </Typography>
                )}
            </Grid>
            <Grid item xs={12}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Scope</TableCell>
                            <TableCell align="right">CO2e (mt)</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.entries(REPORT_TO_LABEL_MAPPING).map(([key, label]) => {
                            return (
                                <TableRow key={key}>
                                    <TableCell>{label}</TableCell>
                                    {survey?.details?.havePerScopeData === false ? (
                                        <TableCell align="right">
                                            {key !== 'scope3Co2e'
                                                ? // 15-15-70% split
                                                  formatNumber(
                                                      survey?.details &&
                                                          parseFloat(survey?.details.totalCo2e) /
                                                              (100 / 15)
                                                  )
                                                : formatNumber(
                                                      survey?.details &&
                                                          parseFloat(survey?.details.totalCo2e) /
                                                              (100 / 70)
                                                  )}
                                        </TableCell>
                                    ) : (
                                        <TableCell align="right">
                                            {formatNumber(survey?.details && survey?.details[key])}
                                        </TableCell>
                                    )}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                    <TableHead>
                        <TableRow>
                            <TableCell>Total</TableCell>
                            <TableCell align="right">
                                {formatNumber(survey?.details && survey?.details.totalCo2e)}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                </Table>
            </Grid>
            <SurveyProductTable survey={survey} />
        </>
    );
}

export default SurveyResultReport;
