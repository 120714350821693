/*
 * This file contains the questions for the IMP form.
 */
const jsonConstants = [
    {
        name: 'Version Information',
        questions: [
            {
                q: 'Version Number of Inventory Management Plan',
                label: 'Version Number of Inventory Management Plan',
            },
            {
                q: 'Date Inventory Management Plan Completed',
                label: 'Date Inventory Management Plan Completed',
            },
        ],
    },
    {
        name: 'Organization Information',
        questions: [
            {
                q: 'Organization Name',
                label: 'Organization Name',
            },
            {
                q: 'Address',
                label: 'Address',
            },
            {
                q: 'Inventory Contact Name',
                label: 'Inventory Contact Name',
            },
            {
                q: 'Phone number',
                label: 'Phone number',
            },
            {
                q: 'Email address',
                label: 'Email address',
            },
        ],
    },
    {
        name: 'Boundary Conditions',
        questions: [
            {
                // this is a custom component
                q: 'Organizational Boundary',
                subheader: 'Select the organizational boundary approach used for GHG inventory.',
                label: 'Organizational Boundary',
                customComponent: 'organizationalBoundary',
                // multiple choice answers
                answers: [
                    'Equity Approach',
                    'Control Approach (Financial Control)',
                    'Control Approach (Operational Control)',
                ],
            },
            {
                q: 'Organizational Boundary Selection Process',
                subheader:
                    'Describe how the organizational boundary selection approach was chosen.',
                label: 'Organizational Boundary Selection Process',
            },
            {
                q: 'List of Facilities Included Under Selected Organizational Boundary',
                subheader:
                    'List all of the organization-wide facilities included under the selected organizational boundary and include the ownership status (owned or leased) for each facility.',
                label: 'List of Facilities Included Under Selected Organizational Boundary',
                // this is a custom component
                customComponent: 'listOfFacilities',
                columns: ['Facility Name', 'Ownership Status', 'Percentage Occupied/Owned'],
                status: ['Owned', 'Leased'],
            },
            {
                // this is a custom component
                q: 'List of Operations or Source Categories for each GHG',
                subheader:
                    'For each GHG, list the operation or source category that contributes to those emissions. For example:  Natural gas boilers or stationary combustion would be listed for CO2, CH4 and N2O.',
                customComponent: 'listOfOperations',
                // multiple choice answers
                ghg: [
                    'Carbon Dioxide (CO2)',
                    'Methane (CH4)',
                    'Nitrous Oxide (N2O)',
                    'Hydrofluorocarbons (HFCs)',
                    'Perfluorocarbons (PFCs)',
                    'Sulfur Hexafluoride (SF6)',
                    'Nitrogen Trifluoride (NF3)',
                ],
            },
            {
                q: 'Emission Source Identification Procedure',
                subheader:
                    'Describe the procedure used to identify each source of GHG emissions for the organization.',
                label: 'Emission Source Identification Procedure',
            },
            {
                q: 'Organization-wide Scope 1 Direct Sources of GHG Emissions',
                customComponent: 'listOfCategories',
                scope: 1,
                subheader: 'List the organization Scope 1 direct sources of GHG emissions.',
                label: 'Organization-wide Scope 1 Direct Sources of GHG Emissions',
            },
            {
                q: 'Organization-wide Scope 2 Indirect Sources of GHG Emissions',
                customComponent: 'listOfCategories',
                scope: 2,
                subheader: 'List the organization Scope 2 indirect sources of GHG emissions.',
                label: 'Organization-wide Scope 2 Indirect Sources of GHG Emissions',
            },
            {
                q: 'Organization-wide Scope 3 Indirect Sources of GHG Emissions',
                customComponent: 'listOfCategories',
                scope: 3,
                subheader: 'List the organization Scope 3 indirect sources of GHG emissions.',
                label: 'Organization-wide Scope 3 Indirect Sources of GHG Emissions',
            },
            {
                q: 'Use of RECs to reduce GHG emissions.',
                subheader:
                    'State whether the organization will use green power to reduce its Scope 2 indirect electricity emissions.',
                label: 'Use of RECs to reduce GHG emissions',
            },
            {
                q: 'Use of Offset Projects to reduce GHG emissions.',
                subheader:
                    'State whether the organization will use offsets to help achieve its GHG reduction goal.',
                label: 'Use of Offset Projects to reduce GHG emissions',
            },
        ],
    },
    {
        name: 'Emissions Quantification',
        questions: [
            {
                q: 'Quantification Method',
                customComponent: 'quantificationMethod',
                subheader:
                    'List the quantification method used to determine the organization GHG inventory. Default methods are provided. If other methods are used, list the methods.',
                label: 'Quantification Method',
            },
            {
                q: 'Emission Factors and Other Constants',
                subheader:
                    'List the source of emission factors and other constants used to develop the organization GHG inventory. Default methods are provided. If other methods are used, list the methods.',
                label: 'Emission Factors and Other Constants',
                default: 'U.S. EPA. GHG Emission Factors Hub.',
            },
        ],
    },
    {
        name: 'Data Management',
        questions: [
            {
                q: 'Activity Data',
                subheader:
                    'List the source of data used to determine the organization-wide GHG emissions for each category.',
                label: 'Activity Data',
                default:
                    'Stationary Sources: \n \n' +
                    'Mobile Sources: \n \n' +
                    'Refrigeration/AC Use: \n \n' +
                    'Indirect Electricity/Steam Purchases: \n \n' +
                    'Scope 3 Sources (list each source category):',
            },
            {
                q: 'Data Management',
                subheader:
                    'Describe the general process in place to gather data for the development of the GHG inventory.',
                label: 'Data Management',
            },
            {
                q: 'Data Collection Process – Quality Assurance',
                subheader:
                    'Describe the general process in place to assure the quality of the data gathered for the development of the GHG inventory.',
                label: 'Data Collection Process – Quality Assurance',
            },
            {
                q: 'Data Collection System Security',
                subheader:
                    'Describe the general process in place to assure the security of the data gathered for the development of the GHG inventory.',
                label: 'Data Collection System Security',
                default:
                    'The data collection system for the development of the GHG inventory employs a multi-layered approach to ensure security. User-submitted data is stored in S3, safeguarded by a firewall, and protected by access controls. Additionally, VPC and firewall configurations are in place along with Multi-Factor Authentication (MFA) for administrative accounts to prevent unauthorized access. \n' +
                    'Regular full backups, including incremental backups, are conducted to ensure data integrity and availability. This combination of measures forms a robust security framework for safeguarding the collected data.',
            },
            {
                q: 'Integrated Tools',
                subheader:
                    'Describe how the GHG inventory procedures are integrated into existing organization tools or procedures.',
                label: 'Integrated Tools',
            },
            {
                q: 'Frequency',
                subheader:
                    'Describe the frequency for data to be reported to the organization designated point of contact for the development of the GHG inventory.',
                label: 'Frequency',
            },
        ],
    },
    {
        name: 'Base Year',
        questions: [
            {
                q: 'Adjustment – Structural Changes',
                subheader:
                    'List the structural changes that will lead to an adjustment of the organization base year emissions. Default adjustments are provided. If other methods are also used, include the methods.',
                label: 'Adjustment – Structural Changes',
                default:
                    'Base year emissions will be adjusted only under the following conditions: \n' +
                    '• The acquisition of operations or facilities which existed prior to the organization base year. \n' +
                    '• The divestiture of operations or facilities. \n' +
                    '• If applicable, normalization factor for goal-tracking will also be adjusted.',
            },
            {
                q: 'Adjustment – Methodology Changes',
                subheader:
                    'List the methodology changes that will lead to an adjustment of the organization base year emissions. Default adjustments are provided. If other methods are also used, include the methods.',
                label: 'Adjustment – Methodology Changes',
                default:
                    'Base year emissions will be adjusted only under the following conditions: \n' +
                    '• Significant change (greater than 0.5% difference in total base year emissions) in emission factors, constants, or methodologies. \n' +
                    '• Errors are discovered in previously submitted data that significantly change (greater than 0.5% difference in total base year emissions) the base year emissions.',
            },
        ],
    },
    {
        name: 'Management Tools',
        questions: [
            {
                // this is a custom component
                q: 'Roles and Responsibilities',
                customComponent: 'listOfRoles',
                subheader:
                    'List roles and responsibilities of organization personnel involved with GHG inventory development.',
                label: 'Roles and Responsibilities',
                columns: ['Name', 'Responsibilities'],
            },
            {
                q: 'Training',
                subheader:
                    'List any training of organization personnel specific to the development of the organization-wide GHG inventory.',
                label: 'Training',
            },
            {
                q: 'Document Retention and Control Policy',
                subheader:
                    'List the organization retention and control policy for any documents related to the development of the GHG inventory.',
                label: 'Document Retention and Control Policy',
            },
        ],
    },
    {
        name: 'Auditing and Verification',
        questions: [
            {
                q: 'Internal Auditing',
                subheader: 'Internal procedures used to verify accuracy of GHG inventory.',
                label: 'Internal Auditing',
            },
            {
                q: 'External Validation and/or Verification',
                subheader:
                    'External procedures (i.e., 3rd party verifiers) used to verify accuracy of GHG inventory.',
                label: 'External Validation and/or Verification',
            },
            {
                q: 'Management Review',
                subheader: 'Management review process used to verify accuracy of GHG inventory.',
                label: 'Management Review',
            },
            {
                q: 'Corrective Action',
                subheader: 'Description of how corrective actions from reviews are implemented.',
                label: 'Corrective Action',
            },
        ],
    },
];
const impVersion = 1;
export default jsonConstants;
