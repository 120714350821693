import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import CustomTreeItem from '../../atom/CustomTreeItem';
import EsgDisclosureLevel from './EsgDisclosureLevel';
import transformTreeToFlat from '../helpers/transformTreeToFlat';

function EsgSubcategoryLevel({
    checkedData,
    esg_subcategory,
    setCheckedData,
    getCheckedChildrenNumber,
}) {
    const checkedChildren = getCheckedChildrenNumber(esg_subcategory);
    const esgSubcategoryLabel =
        checkedChildren === 0
            ? `${esg_subcategory.name}`
            : `${esg_subcategory.name} (+ ${checkedChildren} more)`;
    return (
        <CustomTreeItem
            nodeId={`esg_subcategory - ${esg_subcategory.id}`}
            label={
                <FormControlLabel
                    label={esgSubcategoryLabel}
                    labelPlacement="end"
                    control={
                        <Checkbox
                            key={`${esg_subcategory.id} - ${esg_subcategory.name}`}
                            checked={
                                !!checkedData.find(
                                    (c) => c.id === esg_subcategory.id && c.type === 'subcategory'
                                )
                            }
                            onChange={() => {
                                setCheckedData((old) => {
                                    const findCurrent = old.find(
                                        (item) =>
                                            item.id === esg_subcategory.id &&
                                            item.type === 'subcategory'
                                    );
                                    if (findCurrent) {
                                        // find current item's children
                                        const children = transformTreeToFlat(
                                            esg_subcategory.children
                                        );
                                        return old.filter(
                                            (data) =>
                                                data !== findCurrent &&
                                                !children.some(
                                                    (child) =>
                                                        child.id === data.id &&
                                                        child.type === data.type
                                                )
                                        );
                                    }
                                    return [
                                        ...old,
                                        {
                                            type: 'subcategory',
                                            id: esg_subcategory.id,
                                        },
                                        ...transformTreeToFlat(esg_subcategory.children),
                                    ];
                                });
                            }}
                        />
                    }
                />
            }
        >
            {esg_subcategory.children.map((esgDisclosure) => (
                <EsgDisclosureLevel
                    key={`${esgDisclosure.id} - ${esgDisclosure.name}`}
                    checkedData={checkedData}
                    esg_disclosure={esgDisclosure}
                    setCheckedData={setCheckedData}
                    getCheckedChildrenNumber={getCheckedChildrenNumber}
                />
            ))}
        </CustomTreeItem>
    );
}

export default EsgSubcategoryLevel;
