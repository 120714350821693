import React, { useState, useEffect } from 'react';
import { Autocomplete, CircularProgress } from '@mui/material';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { doSwaggerCall } from '../../hooks/useApi';

// Unlimited autocomplete select
const OPTION_LIMIT = 100000;

// https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript
// Give up on IE11 support for this feature
function stripDiacritics(string) {
    return typeof string.normalize !== 'undefined'
        ? string.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
        : string;
}

const filterOptions = (options, { inputValue, getOptionLabel }) => {
    const resultOptionIds = [];

    // add all children below an option to the resultOptionIds
    function findChildOptions(op) {
        const children = options.filter((o) => o.parentId === op.id);
        children.forEach((child) => {
            if (!resultOptionIds.includes(child.id)) {
                resultOptionIds.push(child.id);
            }
            findChildOptions(child);
        });
    }

    // add all parents of an option to the resultOptionIds
    function addOptionAndAllParents(op) {
        if (!resultOptionIds.includes(op.id)) {
            resultOptionIds.push(op.id);
        }
        if (op.parentId) {
            const parent = options.find((o) => o.id === op.parentId);
            addOptionAndAllParents(parent);
        }
    }

    const inputValueStripped = stripDiacritics(inputValue).toLowerCase();
    options.forEach((option) => {
        // the actual matching
        if (stripDiacritics(getOptionLabel(option)).toLowerCase().includes(inputValueStripped)) {
            addOptionAndAllParents(option);
            findChildOptions(option);
        }
    });
    return options.filter((option) => resultOptionIds.includes(option.id));
};

// Convert the list into a flat list of options for the autocomplete
const toOptions = (hierarchy, depth = 0, parentId = null) => {
    return hierarchy.flatMap((loc) => {
        const { id, name, children } = loc;
        const childrenList = children ? toOptions(children, depth + 1, id) : [];
        const option = {
            id,
            name,
            depth,
            parentId,
        };
        return [option].concat(childrenList);
    });
};

const AutoCompleteSelectForLocationsHierarchy = ({
    children,
    token,
    forceInputProps = {},
    value,
    setValue,
    ...autocompleteProps
}) => {
    const [locations, setLocations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchLocations = async () => {
            try {
                const response = await doSwaggerCall('Locations', 'getAllSurveyLocations', {
                    token,
                });
                setLocations(toOptions(response.locations));
                setLoading(false);
            } catch (e) {
                setError(e);
                setLoading(false);
            }
        };
        fetchLocations().catch(console.log);
    }, [token]);

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    return (
        <Autocomplete
            options={locations}
            getOptionLabel={(option) =>
                typeof option === 'object' ? `${option.name}` : `${option}`
            }
            value={locations.find((loc) => loc.id === value) || null}
            onChange={(event, newValue) => {
                setValue(newValue?.id);
            }}
            renderOption={(props, option, { inputValue }) => {
                const matches = match(option?.name, inputValue, {
                    insideWords: true,
                    findAllOccurrences: true,
                });
                const parts = parse(option?.name, matches);
                return (
                    <li {...props}>
                        <div style={{ marginLeft: `${2 * option.depth}rem` }}>
                            {parts.map((part, index) => (
                                <span
                                    key={index}
                                    style={{
                                        fontWeight: part.highlight ? 700 : 400,
                                    }}
                                >
                                    {part.text}
                                </span>
                            ))}
                        </div>
                    </li>
                );
            }}
            renderInput={(params) => {
                return React.cloneElement(children, {
                    ...params,
                    ...forceInputProps,
                    placeholder: 'Start typing to search...',
                    InputProps: {
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress color="inherit" size={15} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    },
                });
            }}
            {...autocompleteProps}
            filterOptions={filterOptions}
        />
    );
};

export default AutoCompleteSelectForLocationsHierarchy;
