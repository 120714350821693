// helper function to get how many checked children exists
export default function getCheckedChildrenNumberRecursive(checkedData, item) {
    let checkedChildrenNumber = 0;
    item.children.forEach((child) => {
        if (
            checkedData.filter((data) => data.type === child.type && data.id === child.id).length >
            0
        ) {
            checkedChildrenNumber += 1;
        }

        if (Array.isArray(child.children)) {
            checkedChildrenNumber += getCheckedChildrenNumberRecursive(checkedData, child);
        }
    });
    return checkedChildrenNumber;
}
