import React from 'react';
import { Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import ComponentTitle from '../../../components/ComponentTitle';
import { formatNumber } from '../../../helpers/convertUnits';

const CAT_TITLES = ['Category 1 - Purchased Goods and Services', 'Category 5 - Waste'];

function SurveyScopeThreeBreakdown({ survey }) {
    const cat_values = [survey?.details?.scope3Cat1, survey?.details?.scope3Cat5];
    const cat_used = [survey?.details?.hasScope3Cat1Report, survey?.details?.hasScope3Cat5Report];

    return (
        <>
            <ComponentTitle variant="h5" title="Supplier provided a scope 3 report of revenue" />
            <Grid item xs={12}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Category</TableCell>
                            <TableCell align="right">
                                Sum CO<sub>2</sub>e (mt)
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {CAT_TITLES.map((categoryName, idx) => {
                            return (
                                <TableRow key={categoryName}>
                                    <TableCell>{categoryName}</TableCell>
                                    <TableCell align="right">
                                        {cat_used[idx]
                                            ? formatNumber(cat_values[idx])
                                            : 'Not given'}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </Grid>
        </>
    );
}

export default SurveyScopeThreeBreakdown;
