import { TextField } from '@mui/material';
import React from 'react';
import { useGlobalFilters } from '../../hooks/useGlobalFilters';
import { categoryAutocomplete } from '../../autocomplete';
import AutocompleteSelect from '../atom/AutoCompleteSelect';
import { groupCategory } from '../../helpers/groups';

function GlobalCategoryFilter() {
    const { category, setSourceFilters, scope, setEdited } = useGlobalFilters();
    return (
        <AutocompleteSelect
            grouping={groupCategory}
            initCall={categoryAutocomplete.init}
            autocompleteCall={categoryAutocomplete.search}
            value={category}
            setValue={(ev, getItem) => {
                const item = getItem(ev);
                setSourceFilters({
                    scopeFilter: item?.scope_id,
                    scopeNameFilter: item?.scope_name || null,
                    categoryFilter: item?.id || null,
                    categoryNameFilter: item?.name || null,
                    subcategoryFilter: null,
                    emissionSourceFilter: null,
                });
                setEdited(false);
            }}
            searchParams={{
                scope,
                sourceLimitation: true,
            }}
        >
            <TextField label="Category" fullWidth size="small" />
        </AutocompleteSelect>
    );
}

export default GlobalCategoryFilter;
