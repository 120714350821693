export const sumOfSavings = (targetScopesHierarchy) => {
    let sum = 0;
    if (targetScopesHierarchy) {
        targetScopesHierarchy.forEach((item) => {
            Object.keys(item).forEach((key) => {
                if (key === 'cost') {
                    const value = item[key] || 0;
                    const { baseline, target } = item;
                    if (baseline === 0) {
                        sum += value;
                    } else if (baseline !== 0) {
                        sum += value * (1 - target / baseline);
                    }
                } else if (key === 'children') {
                    sum += sumOfSavings(item.children);
                }
            });
        });
    }
    return sum;
};

export const sumReductionCosts = (targetScopesHierarchy) => {
    let sum = 0;
    if (targetScopesHierarchy) {
        targetScopesHierarchy.forEach((item) => {
            if (Number.isNaN(item.reductionCost)) {
                return;
            }
            if (item.reductionCost !== undefined) {
                sum += item.reductionCost;
            }
            if (item.children) {
                sum += sumReductionCosts(item.children);
            }
        });
    }
    return sum;
};
