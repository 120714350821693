import React, { useState } from 'react';
import {
    DialogActions,
    DialogContentText,
    DialogContent,
    DialogTitle,
    Dialog,
    Grid,
} from '@mui/material';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import PanelListItem from './components/PanelListItem';
import CommonButton from '../components/atom/CommonButton';

const DragAndDropPanelModal = ({ title, content, panels, handleClose, showModal, MODAL_TYPE }) => {
    const [panelData, setPanelData] = useState(panels);

    const closeAction = () => {
        showModal(MODAL_TYPE.NONE);
    };

    const handleOnDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const items = [...panelData];
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setPanelData(items);
    };

    return (
        <Dialog
            open
            onClose={closeAction}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
                <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="characters">
                        {(provided) => (
                            <Grid sx={{ pt: 2 }}>
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    className="characters"
                                >
                                    {panelData.map(({ name, visual_type }, i) => (
                                        <Draggable key={i} draggableId={`${i}`} index={i}>
                                            {(prov) => (
                                                <PanelListItem
                                                    provided={prov}
                                                    name={name}
                                                    visual_type={visual_type}
                                                />
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            </Grid>
                        )}
                    </Droppable>
                </DragDropContext>
            </DialogContent>
            <DialogActions>
                <CommonButton
                    onClick={closeAction}
                    autoFocus
                    label="Cancel"
                    buttonType="secondary"
                />
                <CommonButton
                    onClick={() => {
                        handleClose(panelData);
                        showModal(MODAL_TYPE.NONE);
                    }}
                    label="Yes"
                    buttonType="primary"
                />
            </DialogActions>
        </Dialog>
    );
};

export default DragAndDropPanelModal;
