import React from 'react';
import { TableCell, TableHead, TableRow } from '@mui/material';

const SimpleHeader = ({ sourceLevel }) => {
    const nameTableCellWidth = sourceLevel ? '20%' : '30%';
    const tableCellWidth = sourceLevel ? '10%' : '15%';
    const reductionTableCellWidth = sourceLevel ? '30%' : '40%';
    return (
        <TableRow>
            <TableCell width={nameTableCellWidth}>Name</TableCell>
            <TableCell width={tableCellWidth}>
                Baseline CO<sub>2</sub>e (mt)
            </TableCell>
            <TableCell width={tableCellWidth}>
                Target CO<sub>2</sub>e (mt)
            </TableCell>
            {sourceLevel && (
                <>
                    <TableCell width="10%">Baseline base unit</TableCell>
                    <TableCell width="10%">Target base unit</TableCell>
                </>
            )}
            <TableCell colSpan="2" width={reductionTableCellWidth}>
                Reduction
            </TableCell>
        </TableRow>
    );
};

const DatalineLevelHeader = () => {
    return (
        <TableRow>
            <TableCell>Emission Description</TableCell>
            <TableCell>Site</TableCell>
            <TableCell>Baseline Year</TableCell>
            <TableCell>Target Year</TableCell>
            <TableCell>Cost</TableCell>
            <TableCell colSpan="2">Reduction</TableCell>
            <TableCell />
        </TableRow>
    );
};

function ScenarioLevelHeader({ sourceLevel, datalineLevel }) {
    return (
        <TableHead>
            {!datalineLevel ? <SimpleHeader sourceLevel={sourceLevel} /> : <DatalineLevelHeader />}
        </TableHead>
    );
}

export default ScenarioLevelHeader;
