import {
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip,
} from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SortableTableCell from '../../../components/atom/SortableTableCell';
import SkeletonTable from '../../../components/atom/SkeletonTable';
import { MODAL_TYPE, useModals } from '../../../hooks/useModal';
import { TABLE_ROW_COUNT_OPTIONS } from '../../../helpers/constans';
import { handleChangeRowsPerPage, handlePageChange } from '../../../helpers/handleTableFunctions';

const EsgFrameworkQuestionsTable = ({
    rows,
    limit,
    page,
    setParams,
    total,
    loading,
    sort,
    sortDir,
    setSorting,
    deleteRow,
}) => {
    const { showModal } = useModals();

    return (
        <Grid item xs={12}>
            <Table>
                <TableHead>
                    <TableRow>
                        <SortableTableCell
                            arrow={sort === 'name' ? sortDir : ''}
                            onClick={() => setSorting('name')}
                        >
                            Name
                        </SortableTableCell>
                        <SortableTableCell
                            arrow={sort === 'rank' ? sortDir : ''}
                            onClick={() => setSorting('rank')}
                        >
                            Rank
                        </SortableTableCell>
                        <SortableTableCell
                            arrow={sort === 'question' ? sortDir : ''}
                            onClick={() => setSorting('question')}
                        >
                            Esg question
                        </SortableTableCell>
                        <SortableTableCell
                            arrow={sort === 'hierarchy' ? sortDir : ''}
                            onClick={() => setSorting('hierarchy')}
                        >
                            Hierarchy
                        </SortableTableCell>
                        <SortableTableCell
                            arrow={sort === 'guide_note' ? sortDir : ''}
                            onClick={() => setSorting('guide_note')}
                        >
                            Guide note
                        </SortableTableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading ? (
                        <SkeletonTable rows={limit} cells={5} />
                    ) : (
                        rows?.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell>{row.name}</TableCell>
                                <TableCell>{row.rank}</TableCell>
                                <TableCell>{row.esg_question_name || '-'}</TableCell>
                                <TableCell>{row.hierarchy}</TableCell>
                                <TableCell
                                    sx={{
                                        maxWidth: '250px',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'pre-line',
                                    }}
                                >
                                    {row.guide_note}
                                </TableCell>
                                <TableCell align="right">
                                    <Link
                                        to={`/esgframeworks/${row.esg_framework_id}/esgframeworkquestions/edit/${row.id}`}
                                    >
                                        <Tooltip title="Edit">
                                            <IconButton>
                                                <EditIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Link>
                                    <IconButton
                                        onClick={() => {
                                            showModal(MODAL_TYPE.CONFIRMATION, {
                                                title: 'Are you sure you want to delete this ESG framework question?',
                                                content: 'This action is irreversible.',
                                                confirm: () => deleteRow(row.id),
                                            });
                                        }}
                                    >
                                        <Tooltip title="Delete">
                                            <DeleteIcon />
                                        </Tooltip>
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))
                    )}
                </TableBody>
            </Table>
            <TablePagination
                count={total || 0}
                rowsPerPage={limit}
                component="div"
                page={page}
                onPageChange={(_, newPage) => handlePageChange(newPage, setParams, limit)}
                rowsPerPageOptions={TABLE_ROW_COUNT_OPTIONS}
                onRowsPerPageChange={(event) => handleChangeRowsPerPage(event, setParams)}
            />
        </Grid>
    );
};

export default EsgFrameworkQuestionsTable;
