import { Grid, Typography } from '@mui/material';
import React from 'react';
import ComponentTitle from '../../../components/ComponentTitle';
import SurveyDatalines from './SurveyDatalines';

function SurveyResultDatalines({ survey }) {
    return (
        <>
            <ComponentTitle variant="h5" title="Supplier could only provide datalines" />
            <Grid item xs={12}>
                <Typography variant="body1">
                    Selected formula: <b>{survey.details.formula}</b>
                </Typography>
                <Typography variant="body1">
                    The supplier stated, that the percentage of revenue allocated to use is{' '}
                    <b>{survey?.details?.calculationPercentage}%</b>
                </Typography>
            </Grid>
            <SurveyDatalines survey={survey} />
        </>
    );
}

export default SurveyResultDatalines;
