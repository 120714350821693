import { Button, ButtonGroup, CircularProgress, Tooltip } from '@mui/material';
import React from 'react';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { doSwaggerCall } from '../../../hooks/useApi';
import { exportToXlsx } from '../../../helpers/excelExportHelpers';
import { convertUnitGToKg } from '../../../helpers/convertUnits';
import useNotify from '../../../hooks/useNotify';
import { MODAL_TYPE, useModals } from '../../../hooks/useModal';
import { useGlobalFilters } from '../../../hooks/useGlobalFilters';
import { WARNING_CONTENT_RECALCULATE_TEXT } from '../../../helpers/constans';

// TODO: list all location here
const sampleData = [
    {
        location: 'Budapest',
        co2_factor: '3.5',
        ch4_factor: '4.5',
        n2o_factor: '4',
        source: '-',
        marked_for_deletion: '',
    },
];

const EmissionFactorButtonGroup = ({
    emissionSourceId,
    year,
    setGeneratingXlsx,
    generatingXlsx,
    setImportingXlsx,
    importingXlsx,
    handleImport,
    reloadData,
}) => {
    const { notifyError, notifySuccess } = useNotify();
    const { showModal } = useModals();
    const { availableYears } = useGlobalFilters();

    const collectAllData = async () => {
        const res = await doSwaggerCall('EmissionFactors', 'getEmissionFactors', {
            id: emissionSourceId,
            year,
            // TODO: fix meeee
            limit: 10000,
            offset: 0,
        });

        return res.factors.map((d) => ({
            location: d.location_name,
            co2_factor: convertUnitGToKg(d.co2_factor),
            ch4_factor: parseFloat(d.ch4_factor),
            n2o_factor: parseFloat(d.n2o_factor),
            source: d.source,
        }));
    };

    const getEmissionSourceName = async () => {
        const emissionSource = await doSwaggerCall('EmissionSources', 'getEmissionSource', {
            id: emissionSourceId,
        });
        return emissionSource.name;
    };

    return (
        <ButtonGroup color="primary">
            <Tooltip title="Factor list sample">
                <Button
                    fullWidth
                    onClick={() => {
                        exportToXlsx(sampleData, 'samplefactorlist');
                    }}
                >
                    <FileDownloadIcon />
                </Button>
            </Tooltip>
            <Tooltip title="Import factors">
                <Button color="primary" fullWidth>
                    <input
                        onChange={(e) => {
                            if (e.target.files.length === 0) {
                                return;
                            }
                            setImportingXlsx(true);
                            handleImport(e).catch((err) => {
                                notifyError(err);
                            });
                            e.target.value = '';
                        }}
                        accept=".csv, .xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        id="factor-list-upload"
                        type="file"
                        style={{
                            opacity: 0,
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                        }}
                    />
                    {importingXlsx ? (
                        <CircularProgress color="inherit" size={20} />
                    ) : (
                        <FileUploadIcon />
                    )}
                </Button>
            </Tooltip>
            <Tooltip title="Copy from another year">
                <Button
                    fullWidth
                    onClick={() => {
                        showModal(MODAL_TYPE.COPY_FROM_ANOTHER_YEAR, {
                            title: `Are you sure you want to copy factors to ${year}?`,
                            content: 'This action is irreversible.',
                            descriptionType: 'factors',
                            warningContent: WARNING_CONTENT_RECALCULATE_TEXT,
                            emissionSourceId,
                            availableYears: availableYears?.map((y) => y.year),
                            year,
                            reloadData,
                        });
                    }}
                >
                    <ContentCopyIcon />
                </Button>
            </Tooltip>
            <Tooltip title="Export factors to XLSX">
                <Button
                    fullWidth
                    onClick={async () => {
                        setGeneratingXlsx(true);

                        let listToExport = [];
                        let emissionSourceName;
                        try {
                            listToExport = await collectAllData();
                            emissionSourceName = await getEmissionSourceName();
                        } catch (err) {
                            notifyError(err);
                        }
                        exportToXlsx(
                            listToExport,
                            `${emissionSourceName}_emission_local_factors_${year}`
                        );
                        setGeneratingXlsx(false);
                        notifySuccess('Emission factors exported');
                    }}
                >
                    {generatingXlsx ? (
                        <CircularProgress color="inherit" size={20} />
                    ) : (
                        <FileDownloadIcon />
                    )}
                </Button>
            </Tooltip>
        </ButtonGroup>
    );
};

export default EmissionFactorButtonGroup;
