import { Grid, Step, StepLabel, Stepper } from '@mui/material';
import React from 'react';

function ImpSurveyStepper({ IMP_SURVEY_STEPS, step, setStep }) {
    return (
        <Grid item xs={12} md={3}>
            <Stepper orientation="vertical">
                {IMP_SURVEY_STEPS.map((label, index) => (
                    <Step
                        key={label}
                        active={step === index + 1}
                        onClick={() => {
                            setStep(index + 1);
                        }}
                    >
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Grid>
    );
}
export default ImpSurveyStepper;
