import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { useParams } from 'react-router-dom';
import { doSwaggerCall } from '../../../hooks/useApi';
import DatalineFormulaPickerDropdown from '../../datalineedit/components/DatalineFormulaPickerDropdown';
import SkeletonTable from '../../../components/atom/SkeletonTable';
import useNotify from '../../../hooks/useNotify';
import {
    capitalizeFirstLetter,
    convertUnitGToKg,
    formatNumber,
    getLabelForFieldsFrontend,
} from '../../../helpers/convertUnits';
import { getSubTree } from '../../../helpers/rebalance';
import CommonButton from '../../../components/atom/CommonButton';

function DatalineCalculationSimulator({
    id,
    setTarget,
    targetScopesHierarchy,
    baseUnit,
    currentPath,
    datalineTagId,
}) {
    const { notifyError, notifySuccess } = useNotify();
    const { scenarioId } = useParams();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [resultCo2e, setResultCo2e] = useState(false);
    const [resultAmount, setResultAmount] = useState(false);
    const [calcLog, setCalcLog] = useState(null);

    useEffect(() => {
        setLoading(true);
        doSwaggerCall('Datalines', 'getDataline', { id })
            .then((res) => {
                // getDataline does not return the actual emission source any more
                return doSwaggerCall('EmissionSources', 'getEmissionSource', {
                    id: res.emission_source_id,
                }).then((fullSource) => {
                    setData({
                        id: res.id,
                        year: res.year,
                        emission_source_id: res.emission_source_id,
                        emission_source: fullSource,
                        emission_description: res.emission_description,
                        site_id: res.site_id === null ? 0 : res.site_id,
                        co2e: convertUnitGToKg(res.co2e),
                        calculation_type: res.calculation_type,
                        fields: res.fields,
                        amount: res.amount,
                        notes: res.notes,
                        is_estimated: res.is_estimated,
                        assigned_user: res.assignedUser,
                        owner_user: res.ownerUser,
                        modified_user: res.modifiedUser,
                        modified_at: res.modified_at,
                        documents: res.documents,
                        incorrect: res.incorrect,
                        datalineTags: res.datalineTags.map((d) => d.name),
                        from_date: res.from_date,
                        to_date: res.to_date,
                        cost: res.cost,
                    });
                    setLoading(false);
                });
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
                notifyError(err);
            });
    }, [id]);

    const onSubmit = async (values, { setSubmitting }) => {
        try {
            // grab the source id from currentPath + targetScopesHierarchy
            const subTree = getSubTree({ children: targetScopesHierarchy }, [...currentPath]);
            const res = await doSwaggerCall(
                'Datalines',
                'simulateCalculation',
                { id },
                {
                    calculationType: values.calculation_type,
                    fields: values.fields,
                    amount: parseFloat(values.amount),
                    emissionSourceId: subTree.id,
                    datalineTagId: parseInt(datalineTagId, 10),
                }
            );
            setSubmitting(false);
            setResultCo2e(res.co2e);
            setCalcLog(res.log);
            setResultAmount(res.amount);
        } catch (err) {
            notifyError(err);
            setSubmitting(false);
        }
    };

    const saveSimulationResult = async () => {
        try {
            await doSwaggerCall(
                'Scenarios',
                'editScenarioHierarchy',
                { id: scenarioId },
                {
                    targetScopesHierarchy,
                }
            );
            notifySuccess('Simulation result saved');
        } catch (err) {
            notifyError(err);
        }
    };

    return loading ? (
        <SkeletonTable rows={1} cells={8} />
    ) : (
        <Formik onSubmit={onSubmit} enableReinitialize initialValues={data}>
            {({ isSubmitting, values, setFieldValue }) => {
                const baselineFields = getLabelForFieldsFrontend({
                    amount: data?.amount,
                    base_unit: baseUnit,
                    calculation_type: data?.calculation_type,
                    inputFields: data?.fields,
                    unit_conversion_logic: data?.emission_source?.unit_conversion_logic,
                });
                return (
                    <Form>
                        <Grid container item xs={12} alignItems="flex-end">
                            <Grid item xs={8}>
                                <Typography variant="h5">Calculator</Typography>
                                <Typography variant="body1">
                                    Baseline:{' '}
                                    {Object.entries(baselineFields).map((field, idx) => (
                                        <Box key={idx} sx={{ pl: 1, display: 'inline-block' }}>
                                            <b>{capitalizeFirstLetter(field[0])}:</b>{' '}
                                            {field[1].displayValue}
                                        </Box>
                                    ))}
                                </Typography>
                            </Grid>
                            {resultCo2e !== false && (
                                <Grid item xs={4} textAlign="right">
                                    <Grid container justifyContent="flex-end">
                                        <Grid item>
                                            <Typography variant="h5">
                                                {formatNumber(resultAmount)} {baseUnit}
                                            </Typography>
                                            <small>
                                                {formatNumber(convertUnitGToKg(resultCo2e) / 1000)}{' '}
                                                CO
                                                <sub>2</sub>e (mt)
                                            </small>
                                            {calcLog && (
                                                <Grid item>
                                                    <small variant="body1">{calcLog}</small>
                                                </Grid>
                                            )}
                                        </Grid>
                                        <Grid item>
                                            <CommonButton
                                                type="submit"
                                                disabled={resultCo2e === false}
                                                onClick={() => {
                                                    // save simulation target co2e
                                                    setTarget(convertUnitGToKg(resultCo2e) / 1000);
                                                    saveSimulationResult();
                                                }}
                                                label="Save"
                                                buttonType="primary"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                            <Grid item xs={10}>
                                <DatalineFormulaPickerDropdown
                                    noBaseUnit={values?.emission_source?.has_children}
                                    conversionLogic={values?.emission_source?.unit_conversion_logic}
                                    calculationType={values?.calculation_type}
                                    setCalculationType={(newCalcType) => {
                                        setFieldValue('calculation_type', newCalcType);
                                    }}
                                    fields={values?.fields}
                                    setFieldValue={setFieldValue}
                                    baseUnit={values?.emission_source?.base_unit}
                                    amount={values?.amount}
                                    defaultField={values?.site_default_field}
                                    setAmount={(newAmount) => {
                                        setFieldValue('amount', newAmount);
                                    }}
                                    isAirportCalculatorVisible={false}
                                />
                            </Grid>
                            <Grid item xs={2} alignSelf="center" textAlign="center">
                                <CommonButton
                                    fullWidth
                                    type="sumbit"
                                    sx={{ mr: 0.625 }}
                                    disabled={isSubmitting}
                                    label="Calculate emission"
                                    buttonType="primary"
                                />
                            </Grid>
                        </Grid>
                    </Form>
                );
            }}
        </Formik>
    );
}

export default DatalineCalculationSimulator;
