import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';
import { useGlobalFilters } from '../../hooks/useGlobalFilters';
import { MODAL_TYPE, useModals } from '../../hooks/useModal';

const SiteAndTagFilter = () => {
    const { site, siteNames } = useGlobalFilters();
    const { showModal } = useModals();

    const siteList =
        siteNames.length > 2
            ? `${siteNames.slice(0, 2).join(', ')} and ${siteNames.length - 2} more`
            : siteNames.join(', ');

    const valueText = site.length === 0 ? 'All' : siteList;
    return (
        <Grid
            item
            onClick={() => {
                showModal(MODAL_TYPE.SITE_FILTER, {
                    title: `Site filter`,
                });
            }}
        >
            <FormControl fullWidth>
                <InputLabel id="site-select">Sites</InputLabel>
                <Select
                    fullWidth
                    labelId="site-select"
                    value={valueText}
                    size="small"
                    label="Sites"
                    open={false}
                >
                    <MenuItem value={valueText}>{valueText}</MenuItem>
                </Select>
            </FormControl>
        </Grid>
    );
};

export default SiteAndTagFilter;
