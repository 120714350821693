import {
    AccordionSummary,
    CircularProgress,
    Grid,
    Accordion,
    AccordionDetails,
    InputLabel,
} from '@mui/material';
import React, { useState, useCallback, useEffect } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeView from '@mui/lab/TreeView';
import useNotify from '../../../../../hooks/useNotify';
import { doSwaggerCall } from '../../../../../hooks/useApi';
import TargetPickerScopeLevel from './TargetPickerScopeLevel';
import recursiveTreeFilter from '../../../../../helpers/recursiveTreeFilter';
import SearchInput from '../../../../../components/atom/SearchInput';
import { transformHierarchyToItem } from '../../../../../helpers/groups';

const TargetPicker = ({ year, checkedData, setCheckedData }) => {
    const [dataGroups, setDataGroups] = useState([]);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const { notifyError } = useNotify();

    const loadHierarchy = useCallback((baselineYear) => {
        setLoading(true);
        doSwaggerCall('Hierarchy', 'getSourceHierarchy', {
            year: baselineYear,
            hasanydatalines: true, // show only data that have datalines
        })
            .then((hierarchy) => {
                const transformResult = hierarchy.map((e) => transformHierarchyToItem(e));
                setDataGroups(transformResult);
                setLoading(false);
            })
            .catch((error) => {
                notifyError(!baselineYear ? 'Baseline year is required' : error);
            });
    }, []);

    useEffect(() => {
        loadHierarchy(year);
    }, [year]);

    // search all hierarchy
    useEffect(() => {
        if (search === '') {
            setFilteredData(dataGroups);
            return;
        }
        setFilteredData(recursiveTreeFilter(dataGroups, search.toLowerCase()));
    }, [search, dataGroups]);

    return (
        <Grid>
            <Accordion defaultExpanded sx={{ border: '1px solid grey' }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <InputLabel>Target data</InputLabel>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid
                        container
                        item
                        xs={12}
                        justifyContent="space-between"
                        spacing={2}
                        sx={{ mt: 2 }}
                    />
                    <Grid container>
                        {loading && (
                            <Grid item xs={12}>
                                <CircularProgress size="1.5rem" />
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <SearchInput fullWidth search={search} setSearch={setSearch} />
                        </Grid>
                        {filteredData.map((scope) => (
                            <Grid item xs={12} key={`${scope.id} - ${scope.name}`}>
                                <TreeView
                                    key={`${scope.name} - ${scope.id}`}
                                    aria-label="icon expansion"
                                    defaultCollapseIcon={<ExpandMoreIcon />}
                                    defaultExpandIcon={<ChevronRightIcon />}
                                    defaultExpanded={[]}
                                >
                                    <TargetPickerScopeLevel
                                        key={`${scope.id} - ${scope.name}`}
                                        checkedData={checkedData}
                                        scope={scope}
                                        setCheckedData={setCheckedData}
                                    />
                                </TreeView>
                            </Grid>
                        ))}
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Grid>
    );
};

export default TargetPicker;
