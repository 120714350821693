import React from 'react';
import { Grid, Table, TableBody, TableCell, TableRow } from '@mui/material';
import { useLegends } from '../../../hooks/useLegends';
import { useDarkMode } from '../../../hooks/useDarkMode';

const PieLegendDisplay = () => {
    const { pieLegends } = useLegends();
    const { darkMode } = useDarkMode();

    // sort legends by value
    const sortedLegends = pieLegends?.sort((a, b) => {
        return b.value - a.value;
    });

    // TODO: span colors not visible in print mode
    return (
        <Grid>
            {sortedLegends?.map((e) => {
                return (
                    <Table>
                        <TableBody>
                            <TableRow key={e.id} sx={{ borderBottom: 'none' }}>
                                <TableCell
                                    sx={{
                                        borderBottom: 'none',
                                        background: darkMode ? '#0F304A' : 'white',
                                    }}
                                >
                                    <span
                                        style={{
                                            backgroundColor: e.color,
                                            width: '10px',
                                            height: '10px',
                                            marginRight: '8px',
                                            borderRadius: '50%',
                                            display: 'inline-block',
                                        }}
                                    />
                                    <span>{e.id}</span>
                                </TableCell>
                                <TableCell
                                    align="right"
                                    sx={{
                                        borderBottom: 'none',
                                        background: darkMode ? '#0F304A' : 'white',
                                    }}
                                >
                                    <span>{e.formattedValue}</span>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                );
            })}
        </Grid>
    );
};

export default PieLegendDisplay;
