import { read, utils } from 'xlsx';
import { doSwaggerCall } from '../hooks/useApi';
import { convertUnitKgToG } from './convertUnits';

// helper function to check rows
async function checkData(data) {
    const factorData = data;
    for (let i = 0; i < data.length; i++) {
        // check marked for deletion
        if (
            typeof data[i].Marked_for_deletion !== 'undefined' &&
            data[i].Marked_for_deletion !== ''
        ) {
            factorData[i].marked_for_deletion = true;
        }
        // check incorrect data (empty cells, wrong number)
        if (
            typeof data[i].Location === 'undefined' ||
            typeof data[i].CO2_factor === 'undefined' ||
            typeof data[i].CH4_factor === 'undefined' ||
            typeof data[i].N2O_factor === 'undefined' ||
            typeof data[i].Source === 'undefined' ||
            Number.isNaN(parseFloat(data[i].CO2_factor)) ||
            Number.isNaN(parseFloat(data[i].CH4_factor)) ||
            Number.isNaN(parseFloat(data[i].N2O_factor))
        ) {
            factorData[i].wrong = 'Wrong data';
        }
        // check existing locations
        try {
            const location = await doSwaggerCall('Locations', 'getLocationByName', {
                search: data[i].Location,
            });
            factorData[i].location_id = location?.id;
        } catch (error) {
            factorData[i].wrong = 'No location';
            console.log(`No location: ${data[i].Location}`);
        }
    }

    // check duplicates
    factorData.map((oneFactorData, index) => {
        factorData.find((fdata, idx) => {
            if (
                fdata.Location === oneFactorData.Location &&
                idx !== index &&
                typeof fdata.wrong === 'undefined' &&
                typeof oneFactorData.wrong === 'undefined' &&
                !fdata.marked_for_deletion &&
                !oneFactorData.marked_for_deletion
            ) {
                factorData[index].wrong = 'Duplicate';
                factorData[idx].wrong = 'Duplicate';
                return true;
            }
            return false;
        });
        return factorData;
    });

    const checkedData = factorData.map((row) => ({
        location_name: row.Location,
        location_id: row.location_id,
        co2_factor: convertUnitKgToG(row.CO2_factor),
        ch4_factor: parseFloat(row.CH4_factor),
        n2o_factor: parseFloat(row.N2O_factor),
        source: row.Source,
        marked_for_deletion: row.marked_for_deletion,
        wrong: row.wrong,
    }));

    return checkedData;
}

// step 1: check the whole xlsx file and state
export const parseXlsx = (file, cb, failCb = () => {}) => {
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);
    fileReader.onload = (e) => {
        const bufferArray = e?.target.result;
        const wb = read(bufferArray, { type: 'buffer' });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];

        const data = utils.sheet_to_json(ws);
        checkData(data)
            .then((checkedData) => {
                cb(checkedData);
            })
            .catch((err) => {
                console.log(err);
                failCb(err);
            });
    };
};

export const parseXlsxPromise = (file) => {
    return new Promise((resolve, reject) => {
        parseXlsx(file, resolve, reject);
    });
};

// step 2: confirmation modal
// step 3: import run
export const runImport = (parsedData, emissionSourceId, year) => {
    // filter marked for deletion, wrong data, duplicate and no location rows
    const filteredData = parsedData.filter(
        (d) => typeof d.marked_for_deletion === 'undefined' && typeof d.wrong === 'undefined'
    );

    // import only good rows
    try {
        doSwaggerCall(
            'EmissionFactors',
            'importEmissionFactors',
            { id: emissionSourceId, year },
            filteredData
        );
    } catch (error) {
        throw new Error(error);
    }
};
