import { Chip, Typography, Grid } from '@mui/material';
import React from 'react';
import { doSwaggerCall, uploadDocument, uploadEsgDocument } from '../../../hooks/useApi';
import useNotify from '../../../hooks/useNotify';
import { BACKEND_URL } from '../../../config';

export default function DocumentField({
    values,
    setFieldValue,
    basicModifyDatalinePermission,
    complexModifyDatalinePermission,
    systemManagedDataline,
    systemManagedEmissionSource,
    closedYear = false,
    isEsg = false,
}) {
    const { notifyInfo } = useNotify();
    const handleDelete = (chipToDelete) => () => {
        setFieldValue(
            'documents',
            values?.documents.filter((e) => e.id !== chipToDelete)
        );
    };

    const openDocument = async (documentId) => {
        const document = !isEsg
            ? await doSwaggerCall('Files', 'getDocument', {
                  id: documentId,
              })
            : await doSwaggerCall('Files', 'getEsgDocument', {
                  id: documentId,
              });
        const documentUrl = !document.url
            ? null
            : `${BACKEND_URL}/s3proxy?url=${encodeURIComponent(document.url)}`;
        await window.open(documentUrl);
    };

    const uploadEventHandler = async (event) => {
        if (!event.target.files || !event.target.files.length) {
            return;
        }
        const f = event.target.files[0];
        notifyInfo(`Upload started for ${f.name}`);
        const newDocument = !isEsg ? await uploadDocument(f) : await uploadEsgDocument(f);
        notifyInfo(`Upload finished for ${f.name}`);
        setFieldValue(
            'documents',
            Array.isArray(values?.documents) ? [...values?.documents, newDocument] : [newDocument]
        );
    };

    return (
        <Grid item xs={12}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="body3" fontWeight="bolder">
                        Documents
                    </Typography>
                </Grid>
                {values?.documents?.map((document) => (
                    <Grid item key={document.id}>
                        <Chip
                            label={document.name}
                            onDelete={handleDelete(document.id)}
                            onClick={() => {
                                openDocument(document.id).catch(console.log);
                            }}
                            disabled={
                                isEsg
                                    ? false
                                    : (!basicModifyDatalinePermission &&
                                          !complexModifyDatalinePermission) ||
                                      systemManagedDataline ||
                                      systemManagedEmissionSource ||
                                      closedYear
                            }
                        />
                    </Grid>
                ))}
                {(isEsg ||
                    ((basicModifyDatalinePermission || complexModifyDatalinePermission) &&
                        !systemManagedDataline &&
                        !systemManagedEmissionSource)) && (
                    <Grid item>
                        <span style={{ position: 'relative' }}>
                            <input
                                style={{
                                    opacity: 0,
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                }}
                                type="file"
                                onChange={(event) => uploadEventHandler(event).catch(console.log)}
                                disabled={closedYear && !isEsg}
                            />
                            <Chip
                                label="Upload new document"
                                color="primary"
                                disabled={closedYear && !isEsg}
                            />
                        </span>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
}
