const getScopes = () => {
    const scopes = [
        {
            id: 1,
            name: 'Scope 1',
            shortName: 'Scope 1',
            order: 1,
        },
        {
            id: 2,
            name: 'Scope 2 (Market-Based)',
            shortName: 'Scope 2 (M-B)',
            order: 2,
        },
        {
            id: 4,
            name: 'Scope 2 (Location-Based)',
            shortName: 'Scope 2 (L-B)',
            order: 3,
        },
        {
            id: 3,
            name: 'Scope 3',
            shortName: 'Scope 3',
            order: 4,
        },
        {
            id: 5,
            name: 'Short-Term Carbon Cycle',
            shortName: 'Short-Term C C',
            order: 5,
        },
    ];

    return scopes;
};

export default getScopes;
