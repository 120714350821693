import React, { useState } from 'react';
import {
    DialogActions,
    DialogContent,
    DialogTitle,
    Dialog,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    DialogContentText,
} from '@mui/material';
import { doSwaggerCall } from '../hooks/useApi';
import useNotify from '../hooks/useNotify';
import CommonButton from '../components/atom/CommonButton';

const CopyImpModal = ({
    title,
    content,
    year,
    availableYears,
    impId,
    reloadData,
    showModal,
    MODAL_TYPE,
}) => {
    const closeAction = () => showModal(MODAL_TYPE.NONE);
    const { notifySuccess, notifyError } = useNotify();
    const [copyToYear, setCopyToYear] = useState(availableYears[0]);

    const copyImpForm = async () => {
        try {
            await doSwaggerCall('ImpForms', 'copyImpForm', {
                id: impId,
                year: copyToYear,
            });
            notifySuccess(
                `Inventory management plan form copy successful from ${year} to ${copyToYear}`
            );
            reloadData();
        } catch (error) {
            notifyError(error);
        }
    };

    return (
        <Dialog
            open
            onClose={closeAction}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
                <Grid item xs={12} sx={{ mt: 2 }}>
                    <FormControl fullWidth>
                        <InputLabel id="year-select" required>
                            Copy to year
                        </InputLabel>
                        <Select
                            fullWidth
                            labelId="year-select"
                            id="demo-simple-select"
                            value={copyToYear}
                            size="small"
                            label="Copy from year"
                            onChange={(e) => {
                                setCopyToYear(e.target.value);
                            }}
                        >
                            {availableYears.map((y) => (
                                <MenuItem key={y} value={y}>
                                    {y}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </DialogContent>
            <DialogActions>
                <CommonButton
                    onClick={closeAction}
                    autoFocus
                    label="Cancel"
                    buttonType="secondary"
                />

                <CommonButton
                    onClick={() => {
                        copyImpForm();
                        showModal(MODAL_TYPE.NONE);
                    }}
                    label="Yes"
                    buttonType="primary"
                />
            </DialogActions>
        </Dialog>
    );
};

export default CopyImpModal;
