import React, { useEffect, useRef, useState } from 'react';
import {
    Button,
    Grid,
    IconButton,
    InputAdornment,
    TableCell,
    TextField,
    Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Edit } from '@mui/icons-material';
import FloatInput from '../../../components/atom/FloatInput';
import { formatMoney } from '../../../helpers/convertUnits';

// for cost
function CostLineEdit({
    reductionCostEdit,
    setReductionCost,
    setReductionCostEdit,
    id,
    reductionCost,
    setShowReductionCost,
    currentPath,
    idx,
    datalineNoteEdit,
}) {
    const reductionCostFieldRef = useRef();
    useEffect(() => {
        reductionCostFieldRef.current?.focus();
    }, [reductionCostEdit === id, reductionCostFieldRef.current]);

    return reductionCostEdit === id ? (
        <FloatInput
            inputRef={reductionCostFieldRef}
            name={reductionCost}
            size="small"
            type="number"
            style={{ width: '250px' }}
            label="Reduction CapEX investment"
            value={reductionCost || 0}
            InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            onChange={(ev) => {
                setReductionCost(currentPath, parseFloat(ev.target.rawValue), idx);
            }}
            onBlur={() => {
                if (
                    typeof reductionCost === 'undefined' ||
                    reductionCost === '' ||
                    Number.isNaN(reductionCost)
                ) {
                    setShowReductionCost(false);
                }
                setReductionCostEdit(false);
            }}
            disabled={reductionCostEdit !== id}
            sx={{
                ml: 2.75,
                mt: 1,
                mb: 0,
            }}
        />
    ) : (
        <>
            <Typography variant="body3" sx={{ ml: datalineNoteEdit ? 2 : 0 }}>
                Reduction CapEX investment: {formatMoney(reductionCost)}
            </Typography>
            <IconButton size="small" onClick={() => setReductionCostEdit(id)}>
                <Edit />
            </IconButton>
        </>
    );
}

// for note
function NoteLineEdit({
    datalineNoteEdit,
    setDatalineNote,
    setDatalineNoteEdit,
    id,
    datalineNote,
    setShowDatalineNote,
    currentPath,
    idx,
}) {
    const datalineNoteFieldRef = useRef();
    useEffect(() => {
        datalineNoteFieldRef.current?.focus();
    }, [datalineNoteEdit === id, datalineNoteFieldRef.current]);

    return datalineNoteEdit === id ? (
        <TextField
            inputRef={datalineNoteFieldRef}
            name={datalineNote}
            size="small"
            autoComplete="off"
            style={{ width: '500px' }}
            label="Note"
            value={datalineNote}
            onChange={(ev) => {
                setDatalineNote(currentPath, ev.target.value, idx);
            }}
            onBlur={() => {
                if (typeof datalineNote === 'undefined' || datalineNote === '') {
                    setShowDatalineNote(false);
                }
                setDatalineNoteEdit(false);
            }}
            disabled={datalineNoteEdit !== id}
            sx={{
                ml: 2.75,
                mt: 1,
                mb: 0,
            }}
        />
    ) : (
        <>
            <Typography variant="body3"> Note: {datalineNote}</Typography>
            <IconButton size="small" onClick={() => setDatalineNoteEdit(id)}>
                <Edit />
            </IconButton>
        </>
    );
}

function CostAndNoteLine({
    reductionCost,
    setReductionCost,
    datalineNote,
    setDatalineNote,
    id,
    currentPath,
    idx,
}) {
    const [showReductionCost, setShowReductionCost] = useState(!!reductionCost);
    const [showDatalineNote, setShowDatalineNote] = useState(!!datalineNote);
    const [reductionCostEdit, setReductionCostEdit] = useState(false);
    const [datalineNoteEdit, setDatalineNoteEdit] = useState(false);

    return (
        <TableCell colSpan={8}>
            <Grid
                item
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                sx={{
                    pl: reductionCostEdit === id || datalineNoteEdit === id ? 0 : 2.5,
                    pt: 2,
                }}
                style={{
                    height:
                        (reductionCostEdit === id && showReductionCost) ||
                        (datalineNoteEdit === id && showDatalineNote)
                            ? '70px'
                            : '35px',
                    alignContent: 'center',
                }}
            >
                {!showReductionCost ? (
                    <Button
                        variant="text"
                        size="small"
                        startIcon={<AddIcon />}
                        onClick={() => {
                            setShowReductionCost(true);
                            setReductionCostEdit(id);
                        }}
                    >
                        Reduction CapEX investment
                    </Button>
                ) : (
                    <CostLineEdit
                        reductionCostEdit={reductionCostEdit}
                        setReductionCost={setReductionCost}
                        setReductionCostEdit={setReductionCostEdit}
                        id={id}
                        reductionCost={reductionCost}
                        setShowReductionCost={setShowReductionCost}
                        currentPath={currentPath}
                        idx={idx}
                        datalineNoteEdit={datalineNoteEdit}
                    />
                )}
                {!showDatalineNote ? (
                    <Button
                        variant="text"
                        size="small"
                        startIcon={<AddIcon />}
                        onClick={() => {
                            setShowDatalineNote(true);
                            setDatalineNoteEdit(id);
                        }}
                    >
                        Note
                    </Button>
                ) : (
                    <NoteLineEdit
                        datalineNoteEdit={datalineNoteEdit}
                        setDatalineNote={setDatalineNote}
                        setDatalineNoteEdit={setDatalineNoteEdit}
                        id={id}
                        datalineNote={datalineNote}
                        setShowDatalineNote={setShowDatalineNote}
                        currentPath={currentPath}
                        idx={idx}
                    />
                )}
            </Grid>
        </TableCell>
    );
}

export default CostAndNoteLine;
