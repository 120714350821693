import { Avatar, TableCell, Tooltip } from '@mui/material';
import React from 'react';
import { BACKEND_URL } from '../../config';

function ResponsibilityTableCell({ row, responsibility, type }) {
    const imageUrl = !row?.profile_picture
        ? null
        : `${BACKEND_URL}/s3proxy?url=${encodeURIComponent(row.profile_picture)}`;
    return (
        responsibility !== type && (
            <TableCell>
                {row.user_name && (
                    <Tooltip title={row.user_name} placement="left">
                        <Avatar
                            style={{
                                height: '35px',
                                width: '35px',
                                marginRight: '10px',
                                marginLeft: '10px',
                            }}
                            src={imageUrl}
                        >
                            {row?.user_name?.substr(0, 1)?.toUpperCase()}
                        </Avatar>
                    </Tooltip>
                )}
            </TableCell>
        )
    );
}

export default ResponsibilityTableCell;
