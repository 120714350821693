// Helper function to calculate bar chart data for netzero targets
export function calculateBarData(netzeroTargets, perScopes, year) {
    // collect all years from netzeroTargets.scopes.target
    const allYears = [];
    netzeroTargets
        .map((item) => item.scopes.map((s) => s.target))
        .forEach((item) => {
            if (item.length > 0) {
                allYears.push(...item);
            }
        });
    const maxYear = Math.max(...allYears);

    // data for current year (should be the current value)
    const currentYearData = [];
    perScopes.forEach((supplier) => {
        supplier.scopes.forEach((scope) => {
            // sum current values with group by scope name
            const existingScope = currentYearData.find((item) => item.year === year);
            if (existingScope) {
                if (typeof existingScope[scope.name] === 'undefined') {
                    existingScope[scope.name] = parseFloat(scope.current).toFixed(2);
                } else {
                    const scopeData = existingScope[scope.name];
                    existingScope[scope.name] = (
                        parseFloat(scopeData) + parseFloat(scope.current)
                    ).toFixed(2);
                }
            } else {
                // find scope in perScopes
                const foundSupplier = perScopes.find((e) => e.name === supplier.name);
                const foundScopeInPerScope = foundSupplier.scopes.find(
                    (e) => e.name === scope.name
                );
                currentYearData.push({
                    year,
                    [scope.name]: parseFloat(foundScopeInPerScope.current).toFixed(2),
                });
            }
        });
    });
    const result = currentYearData;

    // fill result for all years with default 0 value for each scope
    const years = Array.from({ length: maxYear - year + 1 }, (_, i) => year + i);
    years.forEach((y) => {
        const existingYear = result.find((item) => item.year === y);
        if (!existingYear) {
            result.push({
                year: y,
                'Scope 1': 0,
                'Scope 2 (Market-Based)': 0,
                'Scope 3': 0,
            });
        }
    });

    perScopes.forEach((supplier) => {
        let i = 1; // year index for yearlyReduction
        years.forEach((y) => {
            const existingYear = result.find((item) => item.year === y);
            const existingYearIndex = result.findIndex((item) => item.year === y);
            // sum all scopes for this year
            supplier.scopes.forEach((scope) => {
                // find scope in netzeroTargets
                const foundSupplier = netzeroTargets.find((e) => e.name === supplier.name);
                const foundScopeInNetzeroTargets = foundSupplier.scopes.find(
                    (e) => e.name === scope.name
                );

                // each scope has different target year so we need to calculate yearlyReduction
                // if target is undefined --> yearlyReduction should be 0
                let yearlyReduction = 0;
                if (foundScopeInNetzeroTargets) {
                    yearlyReduction = 100 / (foundScopeInNetzeroTargets.target - year); // 100% reduction / targetYear - currentYear
                }

                let currentValue = scope.current;
                let calculatedYearValue = scope.current;
                if (scope.current !== 0) {
                    currentValue = parseFloat(scope.current).toFixed(2);
                    calculatedYearValue =
                        currentValue - ((i - 1) * (currentValue * yearlyReduction)) / 100;

                    if (y !== year) {
                        if (calculatedYearValue < 0) {
                            calculatedYearValue = 0;
                        } else {
                            const scopeData = existingYear[scope.name];
                            existingYear[scope.name] = (
                                parseFloat(scopeData) + parseFloat(calculatedYearValue)
                            ).toFixed(2);
                        }
                    }
                }
            });
            i += 1;
            // data back to result
            result[existingYearIndex] = existingYear;
        });
    });

    return result;
}
