import React from 'react';
import { Alert, Grid, Paper, Typography } from '@mui/material';

function ErrorComponent({ error }) {
    const closedSurvey = error === 'Survey already closed!';
    return (
        <Paper sx={{ p: 2 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid item xs={12}>
                        <Alert
                            severity={closedSurvey ? `info` : 'error'}
                            sx={{ textAlign: 'center' }}
                        >
                            <div>{error}</div>
                        </Alert>
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{ mt: 3 }}>
                    <Typography variant="h5" sx={{ textAlign: 'center' }}>
                        Please contact us for further assistance.
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    );
}

export default ErrorComponent;
