import React from 'react';
import { CircularProgress, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useApi } from '../../hooks/useApi';

const FilterComponent = ({ filterValue, setFilterValue, apiEndpoint, apiFunction, itemName }) => {
    const [data, loading, error] = useApi(apiEndpoint, apiFunction);

    if (loading) {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                }}
            >
                <CircularProgress size="1.5rem" />
            </div>
        );
    }

    if (error) {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                }}
            >
                <p>{`Error fetching ${itemName}`}</p>
            </div>
        );
    }

    return (
        <FormControl fullWidth>
            <InputLabel id="filter-by">Filter by</InputLabel>
            <Select
                fullWidth
                labelId="filter-by"
                value={filterValue}
                size="small"
                label="Filter by"
                onChange={(e) => {
                    setFilterValue(e.target.value);
                }}
            >
                <MenuItem value={0} key={0}>
                    All
                </MenuItem>
                {data[itemName].map((item) => (
                    <MenuItem value={item.id} key={item.id}>
                        {item.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default FilterComponent;
