import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import GlobalSiteFilter from '../components/header/GlobalSiteFilter';
import { useGlobalFilters } from '../hooks/useGlobalFilters';
import CommonButton from '../components/atom/CommonButton';

const SiteFilterModal = ({ title, showModal, MODAL_TYPE }) => {
    const { setSiteFilters, setSelectedSites, setSelectedTags } = useGlobalFilters();

    const closeAction = () => showModal(MODAL_TYPE.NONE);
    const handleClear = () => {
        setSiteFilters({ siteFilter: [], siteNamesFilter: [] });
        setSelectedTags([]);
        setSelectedSites([]);
    };

    return (
        <Dialog open onClose={closeAction} maxWidth="sm" fullWidth>
            <DialogTitle sx={{ alignSelf: 'center' }}>{title}</DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid item xs={12}>
                        <GlobalSiteFilter />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <CommonButton
                            onClick={closeAction}
                            fullWidth
                            label="Close"
                            buttonType="secondary"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <CommonButton
                            onClick={handleClear}
                            fullWidth
                            label="Clear"
                            buttonType="primary"
                        />
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};

export default SiteFilterModal;
