import { Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { doSwaggerCall } from '../../hooks/useApi';
import useNotify from '../../hooks/useNotify';
import useSorting from '../../hooks/useSorting';
import SearchInput from '../../components/atom/SearchInput';
import CommonGroup from '../../components/atom/CommonGroup';
import { useGlobalFilters } from '../../hooks/useGlobalFilters';
import NetZeroTargets from './components/NetZeroTargets';
import GroupByComponent from '../../components/atom/GroupByComponent';
import { SUPPLIER_GROUPBY_OPTIONS, SUPPLIER_GROUPS } from '../../helpers/groups';

const SuppliersPage = () => {
    const [search, setSearch] = useState('');
    const { notifyError } = useNotify();
    const [supplierGroups, setSupplierGroups] = useState([]);
    const [groupOpen, setGroupOpen] = useState([0]);
    const { year } = useGlobalFilters();
    const [groupby, setGroupby] = useState(SUPPLIER_GROUPS.NONE);
    const { sort, sortDir, setSorting } = useSorting(['created_at', 'ASC']);
    const [suppliersChanged, setSuppliersChanged] = useState(false);

    useEffect(() => {
        if (groupby === SUPPLIER_GROUPS.NONE) {
            const sectionHeader = 'Suppliers';
            setSupplierGroups([
                {
                    id: 0,
                    name: 'All suppliers',
                    section: { sectionHeader },
                    type: 'all',
                },
            ]);
            setGroupOpen(['0-all']);
            return;
        }
        if (groupby === SUPPLIER_GROUPS.INDUSTRY) {
            doSwaggerCall('Suppliers', 'getSuppliers', {
                search,
                // TODO: fix meeee
                limit: 1000,
                offset: 0,
                sort,
                sortDir,
                year,
            })
                .then((res) => {
                    const transformResult = [];
                    res.suppliers.forEach((supplier) => {
                        const sectionHeader = `${supplier.industry}`;
                        if (
                            !transformResult
                                .map((t) => t.section.sectionHeader.toLowerCase())
                                .includes(sectionHeader.toLowerCase())
                        ) {
                            transformResult.push({
                                id: supplier.id,
                                industry: supplier.industry,
                                section: { sectionHeader },
                                type: 'industry',
                                categoryId: supplier.categoryId,
                            });
                        }
                    });
                    setSupplierGroups(transformResult);
                    setGroupOpen(transformResult.map((t) => `${t.id}-${t.type}`));
                })
                .catch((err) => {
                    notifyError(err);
                });
            return;
        }
        if (groupby === SUPPLIER_GROUPS.LOCATION) {
            doSwaggerCall('Suppliers', 'getSuppliers', {
                search,
                offset: 0,
                limit: 1000,
                sort,
                sortDir,
                year,
            })
                .then((res) => {
                    const transformResult = [];
                    res.suppliers.forEach((supplier) => {
                        const sectionHeader = `${supplier.locationName}`;
                        if (
                            !transformResult
                                .map((t) => t.section.sectionHeader)
                                .includes(sectionHeader)
                        ) {
                            transformResult.push({
                                id: supplier.id,
                                locationName: supplier.locationName,
                                locationId: supplier.locationId,
                                section: {
                                    sectionHeader,
                                },
                                type: 'location',
                                categoryId: supplier.categoryId,
                            });
                        }
                    });
                    setSupplierGroups(transformResult);
                    setGroupOpen(transformResult.map((t) => `${t.id}-${t.type}`));
                })
                .catch((err) => {
                    notifyError(err);
                });
        }
    }, [groupby, notifyError, search, sort, sortDir]);

    useEffect(() => {
        setGroupby(SUPPLIER_GROUPS.NONE);
    }, [year]);

    return (
        <Grid container alignContent="flex-start">
            <Grid item xs={12}>
                <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end">
                    <Grid item xs={10} style={{ flex: '1 1 0' }}>
                        <SearchInput fullWidth search={search} setSearch={setSearch} />
                    </Grid>
                    <GroupByComponent
                        groupBy={groupby}
                        setGroupBy={setGroupby}
                        search={search}
                        groupByOptions={SUPPLIER_GROUPBY_OPTIONS}
                    />
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={0.5}>
                    {supplierGroups.map((item, idx) => {
                        return (
                            <CommonGroup
                                key={idx}
                                groupby={groupby}
                                search={search}
                                setGroupOpen={setGroupOpen}
                                groupOpen={groupOpen}
                                item={item}
                                typeFlag="supplier"
                                setSuppliersChanged={setSuppliersChanged}
                            />
                        );
                    })}
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h4">Net Zero Targets</Typography>
            </Grid>
            <Grid item xs={12}>
                <NetZeroTargets
                    year={year}
                    suppliersChanged={suppliersChanged}
                    setChartData={() => {}}
                />
            </Grid>
        </Grid>
    );
};

export default SuppliersPage;
