import { Typography } from '@mui/material';
import { format } from 'date-fns';
import React from 'react';

export default function LastModified({ modifiedUser, modifiedAt }) {
    return (
        <Typography variant="body3">
            <b>Last modified:</b> {modifiedUser.user_name} at{' '}
            {format(new Date(modifiedAt), 'MM-dd-yyyy h aa')}
        </Typography>
    );
}
