import React, { useCallback } from 'react';
import {
    DialogActions,
    DialogContentText,
    DialogContent,
    DialogTitle,
    Dialog,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TableContainer,
    Paper,
    Alert,
} from '@mui/material';
import { convertUnitGToKg } from '../helpers/convertUnits';
import { checkResultForRow } from '../helpers/modalHelper';
import CommonButton from '../components/atom/CommonButton';

const ConfirmationImportModal = ({
    title,
    content,
    data,
    confirm,
    close = false,
    warningContent = false,
    showModal,
    setImportingXlsx,
    MODAL_TYPE,
}) => {
    const closeAction = useCallback(
        close ||
            (() => {
                showModal(MODAL_TYPE.NONE);
                setImportingXlsx(false);
            }),
        [close]
    );

    // check sources length for Dialog maxWidth
    const checkDialogWidth = () => {
        let dialogWidth = 'md';
        data?.map((row) => {
            if (row.source) {
                if (row.source.length > 25 && row.source.length < 50) {
                    dialogWidth = 'lg';
                    return dialogWidth;
                }
                if (row.source.length > 50) {
                    dialogWidth = 'xl';
                    return dialogWidth;
                }
            }
            return dialogWidth;
        });
        return dialogWidth;
    };

    return (
        <Dialog
            open
            onClose={closeAction}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={checkDialogWidth()}
            fullWidth
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                {warningContent && (
                    <DialogContentText id="alert-dialog-warning">
                        <Alert sx={{ mt: 0.5, mb: 0.5 }} severity="warning">
                            {warningContent}
                        </Alert>
                    </DialogContentText>
                )}
                <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
                <DialogContentText id="alert-dialog-description">
                    Below are the results. Only rows with a check mark can be imported.
                </DialogContentText>
                <TableContainer component={Paper} style={{ maxHeight: '600px', minWidth: '500px' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Location</TableCell>
                                <TableCell align="right">
                                    CO<sub>2</sub> factor (kg / base unit)
                                </TableCell>
                                <TableCell align="right">
                                    CH<sub>4</sub> factor (g / base unit)
                                </TableCell>
                                <TableCell align="right">
                                    N<sub>2</sub>O factor (g / base unit)
                                </TableCell>
                                <TableCell align="left">Source</TableCell>
                                <TableCell align="right">Result</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data?.map((row) => (
                                <TableRow key={row.location_id}>
                                    <TableCell align="left">{row.location_name}</TableCell>
                                    <TableCell align="right">
                                        {convertUnitGToKg(row.co2_factor)}
                                    </TableCell>
                                    <TableCell align="right">
                                        {parseFloat(row.ch4_factor)}
                                    </TableCell>
                                    <TableCell align="right">
                                        {parseFloat(row.n2o_factor)}
                                    </TableCell>
                                    <TableCell align="left">{row.source}</TableCell>
                                    <TableCell align="right">{checkResultForRow(row)}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <CommonButton
                    onClick={closeAction}
                    autoFocus
                    label="Cancel"
                    buttonType="secondary"
                />
                <CommonButton
                    onClick={() => {
                        confirm();
                        showModal(MODAL_TYPE.NONE);
                    }}
                    label="Yes"
                    buttonType="primary"
                />
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationImportModal;
