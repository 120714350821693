import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import SourcePickerCategoryLevel from './SourcePickerCategoryLevel';
import CustomTreeItem from '../../../../components/atom/CustomTreeItem';

function SourcePickerScopeLevel({
    checkedData,
    scope,
    setCheckedData,
    setCheckedChildren,
    getCheckedChildrenNumber,
}) {
    const checkedChildren = getCheckedChildrenNumber(scope);
    const scopeLabel =
        checkedChildren === 0 ? `${scope.name}` : `${scope.name} (+ ${checkedChildren} more)`;
    return (
        <CustomTreeItem
            nodeId={`scope - ${scope.id}`}
            label={
                <FormControlLabel
                    label={scopeLabel}
                    labelPlacement="end"
                    control={
                        <Checkbox
                            key={`${scope.id} - ${scope.name}`}
                            checked={
                                !!checkedData.find((c) => c.id === scope.id && c.type === 'scope')
                            }
                            onChange={() => {
                                setCheckedData((old) => {
                                    const findCurrent = old.find(
                                        (item) => item.id === scope.id && item.type === 'scope'
                                    );
                                    if (findCurrent) {
                                        return old.filter((data) => data !== findCurrent);
                                    }
                                    const res = setCheckedChildren(scope, old);
                                    return [
                                        ...res,
                                        {
                                            type: 'scope',
                                            id: scope.id,
                                        },
                                    ];
                                });
                            }}
                        />
                    }
                />
            }
        >
            {scope.children.map((category) => (
                <SourcePickerCategoryLevel
                    key={`${category.id} - ${category.name}`}
                    checkedData={checkedData}
                    category={category}
                    scope={scope}
                    setCheckedData={setCheckedData}
                    setCheckedChildren={setCheckedChildren}
                    getCheckedChildrenNumber={getCheckedChildrenNumber}
                />
            ))}
        </CustomTreeItem>
    );
}

export default SourcePickerScopeLevel;
