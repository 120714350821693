import { Grid } from '@mui/material';
import DocumentIcon from '@mui/icons-material/Description';
import NoteIcon from '@mui/icons-material/Note';
import CalculateIcon from '@mui/icons-material/Calculate';
import React from 'react';
import BadgeWithIcon from '../../modals/components/BadgeWithIcon';

function TableDetails({ row, onlyDocs = false, onlyEstimated = false }) {
    const iconStyle = {
        height: '20px',
        width: '20px',
        opacity: 0.3,
    };
    return (
        <>
            {row?.documentsCount > 0 && onlyDocs && !onlyEstimated && (
                <Grid item>
                    <BadgeWithIcon
                        content={row?.documentsCount}
                        title={`${row?.documentsCount} document(s)`}
                    >
                        <DocumentIcon style={iconStyle} />
                    </BadgeWithIcon>
                </Grid>
            )}
            {row?.notes !== null && row?.notes !== '' && !onlyDocs && !onlyEstimated && (
                <Grid item>
                    <BadgeWithIcon title={row?.notes}>
                        <NoteIcon style={iconStyle} />
                    </BadgeWithIcon>
                </Grid>
            )}
            {row?.is_estimated
                ? !onlyDocs &&
                  onlyEstimated && (
                      <Grid item>
                          <BadgeWithIcon title="Estimated">
                              <CalculateIcon style={iconStyle} />
                          </BadgeWithIcon>
                      </Grid>
                  )
                : null}
        </>
    );
}

export default TableDetails;
