import React, { useContext, useState, useCallback, useMemo } from 'react';
import { useFormikContext } from 'formik';

const ImpSurveyStepContext = React.createContext();

export function ImpSurveyStepContextProvider({ setStep, step, children }) {
    const [fieldInStep, setFieldInStep] = useState({});

    /**
     * Add a field to the proper step, since we need to know which fields are in which step
     */
    const addFieldIntoStep = useCallback(
        (field, targetStep) => {
            setFieldInStep((old) => {
                const newFieldsInStep = { ...old };
                if (!newFieldsInStep[targetStep]) {
                    newFieldsInStep[targetStep] = [];
                }
                if (!newFieldsInStep[targetStep].includes(field)) {
                    newFieldsInStep[targetStep] = [...newFieldsInStep[targetStep], field];
                }
                return newFieldsInStep;
            });
        },
        [step]
    );

    const contextValue = useMemo(() => {
        return { setStep, step, addFieldIntoStep, fieldInStep };
    }, [setStep, step, addFieldIntoStep, fieldInStep]);
    return (
        <ImpSurveyStepContext.Provider value={contextValue}>
            {children}
        </ImpSurveyStepContext.Provider>
    );
}

ImpSurveyStepContext.displayName = 'ImpSurveyStepContext';

export function useImpSurveyStep() {
    return useContext(ImpSurveyStepContext);
}

export function withFormikContext(Component) {
    return (props) => {
        const { setFieldValue, values } = useFormikContext();
        return <Component setFieldValue={setFieldValue} values={values} {...props} />;
    };
}
