import React, { useEffect, useState } from 'react';
import {
    Button,
    ButtonGroup,
    CircularProgress,
    Grid,
    IconButton,
    Tooltip,
    Typography,
} from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AddIcon from '@mui/icons-material/Add';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { MODAL_TYPE, useModals } from '../../../hooks/useModal';
import useSorting from '../../../hooks/useSorting';
import { doSwaggerCall, useApi } from '../../../hooks/useApi';
import { useGlobalFilters } from '../../../hooks/useGlobalFilters';
import useNotify from '../../../hooks/useNotify';
import { TABLE_ROW_COUNT_DEFAULT } from '../../../helpers/constans';
import EsgsTable from './EsgsTable';
import { esgExport } from '../../../helpers/excelExportEsgsHelpers';
import { useEsgFrameworkFilters } from '../../../hooks/useEsgFrameworkFilters';
import InputButton from '../../../components/atom/InputButton';
import handleImport from '../../../helpers/excelImportEsgsHelpers';
import { useAuth } from '../../../hooks/useAuth';
import EsgHelperIcon from '../../../components/atom/EsgHelperIcon';

export default function EsgSection({
    search,
    site,
    responsibility,
    sectionHeader,
    esgCategory,
    esgSubcategory,
    esgDisclosure,
    esgQuestion,
    groupOpen,
    setGroupOpen,
    item,
    open,
    setOpen,
    groupby,
    edited,
    setEdited,
}) {
    const { notifyError, notifySuccess } = useNotify();
    const { year } = useGlobalFilters();
    const { user } = useAuth();
    const { esgFramework } = useEsgFrameworkFilters();
    const [{ limit, page }, setParams] = useState({ limit: TABLE_ROW_COUNT_DEFAULT, page: 0 });
    const { sort, sortDir, setSorting } = useSorting(['created_at', 'ASC']);
    const { showModal } = useModals();
    const [selectedEsgs, setSelectedEsgs] = useState([]);
    const [selectMode, setSelectMode] = useState(false);
    const [generatingXlsx, setGeneratingXlsx] = useState(false);
    const [importingXlsx, setImportingXlsx] = useState(false);
    const [data, loading, error, setData, reloadData] = useApi('Esgs', 'getEsgs', {
        year,
        esgCategoryId: esgCategory,
        esgSubcategoryId: esgSubcategory,
        esgDisclosureId: esgDisclosure,
        esgQuestionId: esgQuestion,
        esgFrameworkId: esgFramework,
        limit,
        offset: page * limit,
        search,
        sort,
        sortDir,
        // responsibility,
        siteIdList: site,
    });

    useEffect(() => {
        if (error) {
            notifyError(error);
        }
    }, [error, notifyError]);

    useEffect(() => {
        setParams({ page: 0, limit });
    }, [year, site, esgCategory, esgSubcategory, esgDisclosure, search, responsibility]);

    const deleteEsgsList = async () => {
        showModal(MODAL_TYPE.CONFIRMATION, {
            title: `Are you sure you want to remove esgs?`,
            content: 'This action is irreversible.',
            confirm: () => {
                doSwaggerCall('Esgs', 'deleteEsgsByList', {
                    idList: selectedEsgs.map((e) => e.id),
                })
                    .then(() => {
                        notifySuccess('Esgs deleted');
                        reloadData();
                        setSelectedEsgs([]);
                    })
                    .catch((err) => {
                        notifyError(err);
                    });
            },
        });
    };

    const handleImportEsgs = async (file) => {
        try {
            await handleImport(file, esgQuestion, year, true).then((res) => {
                showModal(MODAL_TYPE.CONFIRMATION_IMPORT_ESGS, {
                    title: 'Are you sure you want to import esgs from this xlsx?',
                    content: 'This action is irreversible.',
                    data: res,
                    setImportingXlsx,
                    confirm: async () => {
                        await handleImport(file, esgQuestion, year, false);
                        notifySuccess('Import esgs successful');
                        setImportingXlsx(false);
                        reloadData();
                    },
                });
            });
        } catch (err) {
            setImportingXlsx(false);
            notifyError(err);
        }
    };

    return (
        <>
            <Grid container item xs={12} justifyContent="space-between" spacing={0} sx={{ mt: 2 }}>
                <Grid item>
                    <Typography variant="h4">
                        {sectionHeader}
                        {item.guide_note && (
                            <EsgHelperIcon
                                title={item.name}
                                guideNote={item.guide_note}
                                esgQuestionId={item.id}
                            />
                        )}
                        {(groupby === 'HIERARCHY' && item.id !== 0) || groupby === 'SITE' ? (
                            <IconButton
                                onClick={() => {
                                    groupOpen.filter((idx) => idx !== item.id);
                                    setOpen(!open);
                                }}
                            >
                                <KeyboardArrowUpIcon />
                            </IconButton>
                        ) : null}
                    </Typography>
                </Grid>
                <Grid item sx={{ pb: '8px' }}>
                    <Grid
                        item
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        <Grid item>
                            {esgQuestion && (
                                <ButtonGroup color="primary">
                                    <Tooltip title="Add ESG">
                                        <Button
                                            onClick={() => {
                                                setEdited(`new - ${esgQuestion}`);
                                            }}
                                        >
                                            <AddIcon />
                                        </Button>
                                    </Tooltip>
                                    <Tooltip title="Export to xlsx">
                                        <Button
                                            onClick={async () => {
                                                setGeneratingXlsx(true);
                                                try {
                                                    await esgExport(
                                                        esgQuestion,
                                                        year,
                                                        site,
                                                        user.company_id
                                                    );
                                                } catch (err) {
                                                    notifyError(err);
                                                }
                                                setGeneratingXlsx(false);
                                                notifySuccess('Esgs exported');
                                            }}
                                        >
                                            {generatingXlsx ? (
                                                <CircularProgress color="inherit" size={20} />
                                            ) : (
                                                <FileDownloadIcon />
                                            )}
                                        </Button>
                                    </Tooltip>
                                    <InputButton
                                        id="esg-list-upload"
                                        accept=".csv, .xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                        onChange={(e) => {
                                            if (e.target.files.length === 0) {
                                                return;
                                            }
                                            setImportingXlsx(true);
                                            handleImportEsgs(e.target.files[0]).catch((err) => {
                                                notifyError(err);
                                            });
                                            e.target.value = '';
                                        }}
                                        type="file"
                                        title="Import from xlsx"
                                        loading={importingXlsx}
                                    />
                                </ButtonGroup>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <EsgsTable
                rows={data?.esgs}
                limit={limit}
                page={page}
                setParams={setParams}
                total={data?.total}
                loading={loading}
                reloadData={reloadData}
                sort={sort}
                sortDir={sortDir}
                setSorting={setSorting}
                responsibility={responsibility}
                edited={edited}
                setEdited={setEdited}
                selectMode={selectMode}
                setSelectMode={setSelectMode}
                selectedEsgs={selectedEsgs}
                setSelectedEsgs={setSelectedEsgs}
                deleteEsgsList={deleteEsgsList}
                esgQuestionId={esgQuestion}
                sites={site}
                setGroupOpen={setGroupOpen}
            />
        </>
    );
}
