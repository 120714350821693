import { Grid } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SearchInput from '../../components/atom/SearchInput';
import useSorting from '../../hooks/useSorting';
import { useApi } from '../../hooks/useApi';
import useNotify from '../../hooks/useNotify';
import ComponentTitle from '../../components/ComponentTitle';
import DashboardsTable from './components/DashboardsTable';
import { TABLE_ROW_COUNT_DEFAULT } from '../../helpers/constans';
import { MODAL_TYPE, useModals } from '../../hooks/useModal';
import CommonButton from '../../components/atom/CommonButton';

const DashboardTemplatesPage = () => {
    const navigate = useNavigate();
    const { showModal } = useModals();
    const { notifyError } = useNotify();
    const [search, setSearch] = useState('');
    const { sort, sortDir, setSorting } = useSorting(['created_at', 'ASC']);
    const [{ limit, page }, setParams] = useState({ limit: TABLE_ROW_COUNT_DEFAULT, page: 0 });
    const [data, loading, error, setData, reloadData] = useApi('Dashboards', 'getDashboards', {
        search,
        limit,
        offset: page * limit,
        sort,
        sortDir,
    });

    useEffect(() => {
        if (error) {
            notifyError(error);
        }
    }, [error, notifyError]);

    return (
        <Grid container alignContent="flex-start">
            <Grid container item xs={12} justifyContent="space-between" spacing={0}>
                <Grid item>
                    <ComponentTitle variant="h4" title="Dashboards" />
                </Grid>
                <Grid item>
                    <Grid
                        item
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        <Grid item>
                            <SearchInput
                                search={search}
                                setSearch={setSearch}
                                setParams={setParams}
                                limit={limit}
                            />
                        </Grid>
                        <Grid item>
                            <CommonButton
                                fullWidth
                                onClick={() => navigate(`/dashboardtemplates/new`)}
                                label="Add dashboard"
                                buttonType="primary"
                            />
                        </Grid>
                        <Grid item>
                            <CommonButton
                                fullWidth
                                onClick={() => {
                                    showModal(MODAL_TYPE.IMPORT_DASHBOARD_TEMPLATE, {
                                        reloadData,
                                    });
                                }}
                                label="Import from template"
                                buttonType="primary"
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <DashboardsTable
                rows={data?.dashboards}
                limit={limit}
                page={page}
                setParams={setParams}
                total={data?.total}
                loading={loading}
                reloadData={reloadData}
                sort={sort}
                sortDir={sortDir}
                setSorting={setSorting}
            />
        </Grid>
    );
};

export default DashboardTemplatesPage;
