/**
 * Formats unit convert gram to kilogram
 *
 * @param g gram amount
 */
export const convertUnitGToKg = (g) => {
    let parsedNum = g;
    if (typeof parsedNum !== 'number') {
        parsedNum = parseFloat(g);
        // cannot be parsed to float
        if (Number.isNaN(parsedNum)) {
            return '?';
        }
    }
    return parsedNum / 1000;
};

/**
 * Formats unit convert kilogram to gram
 *
 * @param kg kilogram amount
 */
export const convertUnitKgToG = (kg) => {
    let parsedNum = kg;
    if (typeof parsedNum !== 'number') {
        parsedNum = parseFloat(kg);
        // cannot be parsed to float
        if (Number.isNaN(parsedNum)) {
            return '?';
        }
    }
    return parsedNum * 1000;
};

/**
 * Display number gram to kg
 *
 * @param num gram amount
 */

export const formatNumber = (num) => {
    let parsedNum = num;
    if (typeof parsedNum !== 'number') {
        parsedNum = parseFloat(num);
        // cannot be parsed to float
        if (Number.isNaN(parsedNum)) {
            return '?';
        }
    }
    const re = `\\d(?=(\\d{${3}})+\\.)`;
    return `${parsedNum.toFixed(2).replace(new RegExp(re, 'g'), '$&,')}`;
};

export const formatMoney = (num) => {
    let parsedNum = num;
    if (typeof parsedNum !== 'number') {
        parsedNum = parseFloat(num);
        // cannot be parsed to float
        if (Number.isNaN(parsedNum)) {
            return '?';
        }
    }
    const re = `\\d(?=(\\d{${3}})+$)`;
    return `$${parsedNum.toFixed(0).replace(new RegExp(re, 'g'), '$&,')}`;
};
// helper function to get labels for fields
export const getLabelForFieldsFrontend = ({
    unit_conversion_logic,
    inputFields,
    calculation_type,
    base_unit,
    amount,
}) => {
    const logic = unit_conversion_logic?.find((e) => `${e.id}` === `${calculation_type}`);

    if (logic) {
        const fields = {};
        for (const [label, value] of Object.entries(inputFields)) {
            const fieldMeta = logic.fields.find((e) => `${e.field_id}` === `${label}`);
            // dropping field that don't fit the calculation logic
            if (!fieldMeta) {
                continue;
            }
            fields[fieldMeta.label] = {
                displayValue: `${fieldMeta.prefix ? `${fieldMeta.prefix} ` : ''}${formatNumber(
                    value
                )}${fieldMeta.postfix ? ` ${fieldMeta.postfix}` : ''}`,
                value,
            };
        }
        return fields;
    }

    return {
        [base_unit]: {
            displayValue: formatNumber(amount),
            value: amount,
        },
    };
};

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};
