import {
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip,
} from '@mui/material';
import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import SkeletonTable from '../../../components/atom/SkeletonTable';
import { MODAL_TYPE, useModals } from '../../../hooks/useModal';
import { doSwaggerCall } from '../../../hooks/useApi';
import useNotify from '../../../hooks/useNotify';
import SortableTableCell from '../../../components/atom/SortableTableCell';
import { useGlobalFilters } from '../../../hooks/useGlobalFilters';
import { TABLE_ROW_COUNT_OPTIONS } from '../../../helpers/constans';
import { handleChangeRowsPerPage, handlePageChange } from '../../../helpers/handleTableFunctions';

const EmissionSourcesTable = ({
    rows,
    limit,
    page,
    setParams,
    total,
    loading,
    reloadData,
    sort,
    sortDir,
    setSorting,
}) => {
    const { showModal } = useModals();
    const { notifyError, notifySuccess } = useNotify();
    const { setSourceFilters } = useGlobalFilters();

    const deleteEmissionSource = useCallback((id) => {
        doSwaggerCall('EmissionSources', 'deleteEmissionSource', { id })
            .then(() => {
                notifySuccess('Emission source deleted');
                setSourceFilters({
                    scopeFilter: null,
                    categoryFilter: null,
                    subcategoryFilter: null,
                    emissionSourceFilter: null,
                });
                reloadData();
            })
            .catch((err) => {
                notifyError(err);
            });
    }, []);

    return (
        <>
            <Table>
                <TableHead>
                    <TableRow>
                        <SortableTableCell
                            arrow={sort === 'subcategory' ? sortDir : ''}
                            onClick={() => setSorting('subcategory')}
                        >
                            Subcategory
                        </SortableTableCell>
                        <SortableTableCell
                            arrow={sort === 'emissionsource' ? sortDir : ''}
                            onClick={() => setSorting('emissionsource')}
                        >
                            Emission Source
                        </SortableTableCell>
                        <SortableTableCell
                            arrow={sort === 'source' ? sortDir : ''}
                            onClick={() => setSorting('source')}
                        >
                            Source
                        </SortableTableCell>
                        <SortableTableCell
                            arrow={sort === 'category' ? sortDir : ''}
                            onClick={() => setSorting('category')}
                        >
                            Category
                        </SortableTableCell>
                        <SortableTableCell
                            arrow={sort === 'note' ? sortDir : ''}
                            onClick={() => setSorting('note')}
                        >
                            Admin note
                        </SortableTableCell>
                        <SortableTableCell
                            arrow={sort === 'baseunit' ? sortDir : ''}
                            onClick={() => setSorting('baseunit')}
                        >
                            Base unit
                        </SortableTableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading ? (
                        <SkeletonTable rows={limit} cells={6} />
                    ) : (
                        rows.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell>{row.subcategory_name}</TableCell>
                                <Tooltip
                                    title={`${row.scope_name} > ${row.category_name} > ${row.subcategory_name}`}
                                    placement="left"
                                >
                                    <TableCell>{row.name}</TableCell>
                                </Tooltip>
                                <TableCell>{row.source}</TableCell>
                                <TableCell>{row.category_name}</TableCell>
                                <TableCell>{row.admin_note}</TableCell>
                                <TableCell>{row.base_unit}</TableCell>
                                <TableCell align="right" className="nowrap">
                                    <Link to={`/emissionsources/edit/${row.id}`}>
                                        <Tooltip title={!row?.system_managed ? `Edit` : 'View'}>
                                            <IconButton>
                                                {!row?.system_managed ? (
                                                    <EditIcon />
                                                ) : (
                                                    <RemoveRedEyeIcon />
                                                )}
                                            </IconButton>
                                        </Tooltip>
                                    </Link>
                                    {!row?.system_managed && (
                                        <IconButton
                                            onClick={() => {
                                                showModal(MODAL_TYPE.CONFIRMATION, {
                                                    title: `Are you sure you want to delete this emission source?`,
                                                    content:
                                                        'This action is irreversible and will remove connected datalines as well.',
                                                    confirm: () => deleteEmissionSource(row.id),
                                                });
                                            }}
                                        >
                                            <Tooltip title="Delete">
                                                <DeleteIcon />
                                            </Tooltip>
                                        </IconButton>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))
                    )}
                </TableBody>
            </Table>
            <TablePagination
                count={total || 0}
                rowsPerPage={limit}
                component="div"
                page={page}
                onPageChange={(_, newPage) => handlePageChange(newPage, setParams, limit)}
                rowsPerPageOptions={TABLE_ROW_COUNT_OPTIONS}
                onRowsPerPageChange={(event) => handleChangeRowsPerPage(event, setParams)}
            />
        </>
    );
};

export default EmissionSourcesTable;
