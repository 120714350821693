import React, { useEffect, useState } from 'react';
import { Grid, LinearProgress } from '@mui/material';
import useNotify from '../../../hooks/useNotify';
import { doSwaggerCall } from '../../../hooks/useApi';
import ScopeOrMetricLineChart from './charts/ScopeOrMetricLineChart';

const ByScope = ({ scopes, setChartData }) => {
    const { notifyError } = useNotify();
    const [scopeLineData, setScopeLineData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        doSwaggerCall('Dashboards', 'getDataForScopesLineChart', {
            scopes,
        })
            .then((res) => {
                setScopeLineData(res);
                setChartData(res.scopeData);
                setLoading(false);
            })
            .catch((error) => {
                notifyError(error);
            });
    }, [scopes, setLoading, setScopeLineData, setChartData]);

    return (
        <Grid item xs={12}>
            {scopeLineData?.length === 0 ? (
                <Grid item>
                    {loading ? <LinearProgress sx={{ mt: 0.25 }} /> : <h5>No data available</h5>}
                </Grid>
            ) : (
                <ScopeOrMetricLineChart data={scopeLineData.scopeData} />
            )}
        </Grid>
    );
};

export default ByScope;
