import {
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
} from '@mui/material';
import React, { useCallback } from 'react';
import Add from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

const ContactEditor = ({ values, setFieldValue }) => {
    const { contacts } = values;
    const setField = useCallback(
        (newValue) => {
            setFieldValue('contacts', newValue);
        },
        [setFieldValue]
    );
    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Phone</TableCell>
                    <TableCell width="4%">&nbsp;</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {contacts.map(({ id, name, email, phone }) => (
                    <TableRow key={id}>
                        <TableCell>
                            <TextField
                                fullWidth
                                type="text"
                                autoComplete="off"
                                value={name}
                                label="contact name"
                                required
                                onChange={(ev) => {
                                    setField(
                                        contacts.map((line) => {
                                            if (line.id === id) {
                                                return {
                                                    id,
                                                    name: ev.target.value,
                                                    email: line.email,
                                                    phone: line.phone,
                                                };
                                            }
                                            return line;
                                        })
                                    );
                                }}
                            />
                        </TableCell>
                        <TableCell>
                            <TextField
                                fullWidth
                                type="email"
                                autoComplete="off"
                                value={email}
                                label="contact email"
                                required
                                onChange={(ev) => {
                                    setField(
                                        contacts.map((line) => {
                                            if (line.id === id) {
                                                return {
                                                    id,
                                                    name: line.name,
                                                    email: ev.target.value,
                                                    phone: line.phone,
                                                };
                                            }
                                            return line;
                                        })
                                    );
                                }}
                            />
                        </TableCell>
                        <TableCell>
                            <TextField
                                fullWidth
                                type="text"
                                autoComplete="off"
                                value={phone}
                                label="contact phone"
                                onChange={(ev) => {
                                    setField(
                                        contacts.map((line) => {
                                            if (line.id === id) {
                                                return {
                                                    id,
                                                    name: line.name,
                                                    email: line.email,
                                                    phone: ev.target.value,
                                                };
                                            }
                                            return line;
                                        })
                                    );
                                }}
                            />
                        </TableCell>
                        <TableCell>
                            <IconButton
                                onClick={() => {
                                    setField(contacts.filter((e) => e.id !== id));
                                }}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                ))}
                <TableRow>
                    <TableCell colSpan={3} />
                    <TableCell>
                        <IconButton
                            onClick={() => {
                                setField([
                                    ...contacts,
                                    {
                                        id: `${Math.random()}`.substring(2),
                                        name: '',
                                        email: '',
                                        phone: '',
                                    },
                                ]);
                            }}
                        >
                            <Add />
                        </IconButton>
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
};

export default ContactEditor;
