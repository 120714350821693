import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import SubcategoryLevel from './SubcategoryLevel';
import CustomTreeItem from '../../atom/CustomTreeItem';
import transformTreeToFlat from '../../esgrequirementshierarchy/helpers/transformTreeToFlat';

function CategoryLevel({ checkedData, category, setCheckedData, getCheckedChildrenNumber }) {
    const checkedChildren = getCheckedChildrenNumber(category);
    const categoryLabel =
        checkedChildren === 0 ? `${category.name}` : `${category.name} (+ ${checkedChildren} more)`;
    return (
        <CustomTreeItem
            nodeId={`category - ${category.id}`}
            label={
                <FormControlLabel
                    label={categoryLabel}
                    labelPlacement="end"
                    control={
                        <Checkbox
                            key={`${category.id} - ${category.name}`}
                            checked={
                                !!checkedData.find(
                                    (c) => c.id === category.id && c.type === 'category'
                                )
                            }
                            onChange={() => {
                                setCheckedData((old) => {
                                    const findCurrent = old.find(
                                        (item) =>
                                            item.id === category.id && item.type === 'category'
                                    );
                                    if (findCurrent) {
                                        // find current item's children
                                        const children = transformTreeToFlat(category.children);
                                        return old.filter(
                                            (data) =>
                                                data !== findCurrent &&
                                                !children.some(
                                                    (child) =>
                                                        child.id === data.id &&
                                                        child.type === data.type
                                                )
                                        );
                                    }
                                    return [
                                        ...old,
                                        {
                                            type: 'category',
                                            id: category.id,
                                        },
                                        ...transformTreeToFlat(category.children),
                                    ];
                                });
                            }}
                        />
                    }
                />
            }
        >
            {category.children.map((subcategory) => (
                <SubcategoryLevel
                    key={`${subcategory.id} - ${subcategory.name}`}
                    checkedData={checkedData}
                    subcategory={subcategory}
                    setCheckedData={setCheckedData}
                    getCheckedChildrenNumber={getCheckedChildrenNumber}
                />
            ))}
        </CustomTreeItem>
    );
}

export default CategoryLevel;
