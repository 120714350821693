import React, { useEffect, useState } from 'react';
import { Grid, LinearProgress } from '@mui/material';
import useNotify from '../../../hooks/useNotify';
import { doSwaggerCall } from '../../../hooks/useApi';
import ScopeOrCategoryOrSubcatOrSitePieChart from './charts/ScopeOrCategoryOrSubcatOrSitePieChart';

const BySelectedCategory = ({ category, year, setChartData }) => {
    const { notifyError } = useNotify();
    const [categoryPieData, setCategoryPieData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        doSwaggerCall('Dashboards', 'getDataForCategoryPieChart', {
            category,
            year,
        })
            .then((res) => {
                setCategoryPieData(res);
                setChartData(res.categoryData);
                setLoading(false);
            })
            .catch((error) => {
                notifyError(error);
            });
    }, [category, year, setLoading, setCategoryPieData, setChartData]);

    return (
        <Grid item xs={12}>
            {categoryPieData?.length === 0 ? (
                <Grid item>
                    {loading ? <LinearProgress sx={{ mt: 0.25 }} /> : <h5>No data available</h5>}
                </Grid>
            ) : (
                <ScopeOrCategoryOrSubcatOrSitePieChart
                    data={categoryPieData.categoryData}
                    year={year}
                    type="category"
                />
            )}
        </Grid>
    );
};

export default BySelectedCategory;
