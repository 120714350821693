import { saveAs } from 'file-saver';
import { utils, write } from 'xlsx';
import { doSwaggerCall } from '../hooks/useApi';
import { ROLES } from './constans';
import { setComment } from './excelExportHelpers';

const DATALINE_EXPORT_LIMIT_CHUNK = 10000;

/**
 * Generate xlsx data from an array of objects
 * @param {array} data array of objects to convert to xlsx rows
 * @returns header fields from object keys and rows from values
 */
const generateDatalineXlsx = (data) => {
    if (!data || data.length === 0) {
        return [];
    }

    const newData = data;
    const headers = Object.keys(newData[0]).map((object) => object);

    const rows = newData.map((element) => {
        return Object.values(element).map((value) => {
            if (Array.isArray(value)) {
                return value.map((e) => e.name).join('\n');
            }
            return value;
        });
    });

    return [headers, ...rows];
};

/**
 * Helper function to check and truncate logic names if needed
 * @param {array} unitConversionLogics array of unit conversion logics
 * @returns modified logic names
 */
const checkAndTruncateLogicNames = (unitConversionLogics) => {
    const truncatedLogicNames = unitConversionLogics.map((l) => l.name.substring(0, 31));
    const duplicatedLogicNames = truncatedLogicNames.filter(
        (item, index) => truncatedLogicNames.indexOf(item) !== index
    );
    const resultSheetNameList = [];
    for (let i = 0; i < unitConversionLogics.length; i++) {
        // excel sheet name cannot contain '/' and cannot exceed 31 chars, so:
        // cannot exceed 31 chars, need truncate with substring
        let truncatedSheetName = unitConversionLogics[i].name;
        let newTruncatedSheetName = '';
        if (unitConversionLogics[i].name.length > 31) {
            truncatedSheetName = unitConversionLogics[i].name.substring(0, 31);
            // if we have duplicate logic names, need some extra changes
            if (duplicatedLogicNames.includes(truncatedSheetName)) {
                const words = unitConversionLogics[i].name.split(' ').filter((w) => w !== '');
                const duplicatedWordLength = duplicatedLogicNames[0].split(' ').length;
                words.forEach((word, wordIdx) => {
                    if (wordIdx < duplicatedWordLength - 1) {
                        newTruncatedSheetName += `${word[0]}`;
                    } else {
                        newTruncatedSheetName += ` ${word}`;
                    }
                });
                truncatedSheetName = newTruncatedSheetName;
            }
        }
        // check ' / ' in sheet name, need replace to '-'
        let replacedSheetName = truncatedSheetName;
        if (truncatedSheetName.includes(' / ')) {
            replacedSheetName = truncatedSheetName.replace(/ \/ /g, '-');
        }

        resultSheetNameList.push(replacedSheetName);
    }

    return resultSheetNameList;
};

// Helper function to create sheet from calculation logic
function createSheetFromCalcLogic(wb, sheetName, logic, datalines) {
    const data = [];

    if (datalines.length === 0) {
        const line = {
            id: '',
            description: '',
            site: '',
            notes: '',
            estimated: '',
            from_date: '',
            to_date: '',
            cost: '',
            assigned_to: '',
            owner: '',
        };
        line.tags = '';
        if (logic.base_unit) {
            line[logic.base_unit] = '';
        }
        if (logic.fields) {
            logic.fields.forEach((field) => {
                line[field] = '';
            });
        }
        line.Marked_for_deletion = '';
        data.push(line);
    }

    for (let i = 0; i < datalines.length; i++) {
        const d = datalines[i];
        const line = {
            id: d.id,
            description: d.emission_description,
            site: d.site_name || 'Corporate',
            notes: d.notes,
            estimated: d.is_estimated ? 'x' : '',
            from_date: d.from_date,
            to_date: d.to_date,
            cost: d.cost,
            assigned_to: d.assignedUser?.user_email,
            owner: d.ownerUser?.user_email,
        };
        line.tags = d.datalineTags.map((tag) => `${tag.name}: ${tag.percentage}%`).join('\n');
        if (logic.base_unit) {
            line[logic.base_unit] = d.amount;
        }
        if (logic.fields) {
            logic.fields.forEach((field) => {
                line[field] = d.fields[field]?.value;
            });
        }
        line.Marked_for_deletion = '';
        data.push(line);
    }

    const ws = utils.aoa_to_sheet(generateDatalineXlsx(data));

    setComment(ws, 'E1', 'Use the "x" character to fill');
    setComment(ws, 'I1', 'Use email address');
    setComment(ws, 'J1', 'Use email address');
    setComment(ws, 'K1', 'Use the following format:\ntag1: x%\ntag2: y%');
    // Marked for deletion is always the last header
    setComment(ws, `${ws['!ref'].split(':')[1][0]}1`, 'Use the "x" character to fill');

    utils.book_append_sheet(wb, ws, sheetName);
}

// Helper function to collect datalines for emission source
export async function getDatalinesForOneSource(year, emissionSource, siteIdList) {
    let datalines = [];
    let total = DATALINE_EXPORT_LIMIT_CHUNK + 1;

    for (let page = 0; page * DATALINE_EXPORT_LIMIT_CHUNK < total; page++) {
        const datalineChunk = await doSwaggerCall('Datalines', 'getDatalines', {
            year,
            emissionSourceId: emissionSource,
            limit: DATALINE_EXPORT_LIMIT_CHUNK,
            offset: page * DATALINE_EXPORT_LIMIT_CHUNK,
            siteIdList,
        });
        total = datalineChunk.total;
        datalines = [...datalines, ...datalineChunk.datalines];
    }
    return datalines;
}

// Helper function to filter calculation types
function filterByCalculationType(needCalculationType, datalines) {
    return datalines.filter(
        (e) =>
            `${e.calculation_type}` === `${needCalculationType}` ||
            (e.calculation_type === null && needCalculationType === 'base_unit')
    );
}

// Generate xlsx file and initiate download
export async function datalineExport(emissionSource, year, siteIdList, companyId) {
    const source = await doSwaggerCall('EmissionSources', 'getEmissionSource', {
        id: emissionSource,
    });
    // get sites, users and dataline tags for helper tab
    const sites = await doSwaggerCall('Sites', 'getSitesInCompany', {
        id: companyId,
        limit: 1000,
    });
    const users = await doSwaggerCall('Users', 'getAllUsers', { pageSize: 1000 });
    const datalineTags = await doSwaggerCall('DatalineTags', 'getAllDatalineTags', {
        pageSize: 1000,
    });

    const datalines = await getDatalinesForOneSource(year, emissionSource, siteIdList);

    const wb = utils.book_new();
    // if dataline is a parent, we don't need to create sheet for base unit
    if (source.has_children === false) {
        createSheetFromCalcLogic(
            wb,
            'Base unit',
            {
                base_unit: source.base_unit,
            },
            filterByCalculationType('base_unit', datalines)
        );
    }

    // check logic names, maybe truncate strings: excel sheet name cannot contain '/' and cannot exceed 31 chars
    const resultSheetNameList = checkAndTruncateLogicNames(source.unit_conversion_logic);

    for (let i = 0; i < source.unit_conversion_logic.length; i++) {
        const logic = source.unit_conversion_logic[i];
        createSheetFromCalcLogic(
            wb,
            resultSheetNameList[i], // truncated sheet name
            {
                fields: logic.fields.map((e) => e.label),
            },
            filterByCalculationType(logic.id, datalines)
        );
    }

    const sitesData = sites.sites
        .map((site) => ({ name: site.name }))
        .sort((a, b) => a.name.localeCompare(b.name))
        .concat({ name: 'Corporate' });

    const usersData = users.users
        .filter((user) => user.role !== ROLES.SUPERADMIN)
        .map((user) => ({
            name: user.name,
            email: user.email,
        }))
        .sort((a, b) => a.name.localeCompare(b.name));

    const datalineTagsData = datalineTags.datalineTags
        .map((tag) => ({ name: tag.name }))
        .sort((a, b) => a.name.localeCompare(b.name));

    const sheets = [
        {
            data: sitesData,
            name: 'Sites',
            helperText: 'This is a helper tab to list all available sites.',
        },
        {
            data: usersData,
            name: 'Users',
            helperText: 'This is a helper tab to list all available users.',
        },
        {
            data: datalineTagsData,
            name: 'Tags',
            helperText: 'This is a helper tab to list all available tags.',
        },
    ];

    sheets.forEach((sheet) => {
        const ws = utils.aoa_to_sheet(generateDatalineXlsx(sheet.data));
        utils.sheet_add_aoa(ws, [[sheet.helperText]], { origin: 'D1' });
        utils.book_append_sheet(wb, ws, sheet.name);
    });

    const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' });
    const file = new Blob([excelBuffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    });
    saveAs(file, `${source.name}.xlsx`);
}
