import React from 'react';
import { TableCell, Skeleton, TableRow } from '@mui/material';

const SkeletonTable = ({ rows, cells }) => {
    const skeletonRows = Array(rows).fill('');
    const skeletonCells = Array(cells).fill('');

    return skeletonRows.map((row, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <TableRow key={`${row}-${idx}`}>
            {skeletonCells.map((_, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <TableCell key={i}>
                    <Skeleton animation="pulse" />
                </TableCell>
            ))}
        </TableRow>
    ));
};

export default SkeletonTable;
