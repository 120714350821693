import {
    Checkbox,
    FormControlLabel,
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip,
} from '@mui/material';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import SortableTableCell from '../../../components/atom/SortableTableCell';
import SkeletonTable from '../../../components/atom/SkeletonTable';
import { useGlobalFilters } from '../../../hooks/useGlobalFilters';
import { doSwaggerCall } from '../../../hooks/useApi';
import useNotify from '../../../hooks/useNotify';
import { useAuth } from '../../../hooks/useAuth';
import { PERMISSIONS, TABLE_ROW_COUNT_OPTIONS } from '../../../helpers/constans';
import { handleChangeRowsPerPage, handlePageChange } from '../../../helpers/handleTableFunctions';

const TABLE_HEADERS = [
    { label: 'Description', sortKey: 'description' },
    { label: 'Site', sortKey: 'siteName' },
    { label: 'Source', sortKey: 'source' },
    { label: 'Amount', sortKey: 'baseUnit' },
    { label: 'Assignee', sortKey: 'assignee' },
    { label: 'Owner', sortKey: 'owner' },
    { label: 'Intentional Zero', sortKey: 'intentionalZero' },
];

const BlankDatalinesTable = ({
    rows,
    loading,
    sort,
    sortDir,
    setSorting,
    total,
    page,
    setParams,
    limit,
    reloadData,
}) => {
    const navigate = useNavigate();
    const { setSourceFilters, setSiteFilters, setSelectedSites } = useGlobalFilters();
    const { notifyError } = useNotify();
    const { userPermissions, user } = useAuth();

    const navigateByPermission = (row) => {
        const navigateWhenHaveData = () => {
            setSiteFilters({
                siteFilter: [row.site_id === null ? 0 : row.site_id],
                siteNamesFilter: [row.site_name],
            });
            setSelectedSites([row.site_id === null ? 0 : row.site_id]);
            if (userPermissions.includes(PERMISSIONS.VIEW_DATALINES)) {
                navigate('/datalines');
            } else if (
                userPermissions.includes(PERMISSIONS.VIEW_ASSIGNED_TO) &&
                userPermissions.includes(PERMISSIONS.VIEW_OWNED)
            ) {
                navigate(
                    `/datalines/${user.id === row.assignedto_id ? 'assignedtome' : 'ownedbyme'}`
                );
            } else if (userPermissions.includes(PERMISSIONS.VIEW_ASSIGNED_TO)) {
                navigate('/datalines/assignedtome');
            } else if (userPermissions.includes(PERMISSIONS.VIEW_OWNED)) {
                navigate('/datalines/ownedbyme');
            }
        };

        try {
            doSwaggerCall('EmissionSources', 'getEmissionSources', {
                idList: [row.emission_source_id],
            }).then(({ emissionSources }) => {
                setSourceFilters({
                    scopeFilter: emissionSources[0].scope_id,
                    scopeNameFilter: emissionSources[0].scope_name,
                    categoryFilter: emissionSources[0].category_id,
                    categoryNameFilter: emissionSources[0].category_name,
                    subcategoryFilter: emissionSources[0].subcategory_id,
                    subcategoryNameFilter: emissionSources[0].subcategory_name,
                    emissionSourceFilter: emissionSources[0].id,
                    emissionSourceNameFilter: emissionSources[0].name,
                });
                navigateWhenHaveData();
            });
        } catch (e) {
            notifyError(e);
        }
    };

    const modifyIntentionalZero = async (row) => {
        try {
            await doSwaggerCall(
                'Datalines',
                'editDataline',
                {
                    id: row.id,
                },
                {
                    intentionalZeroByProgressTracker: !row.intentional_zero,
                    emissionSourceId: row.emission_source_id,
                }
            );
            reloadData();
        } catch (e) {
            notifyError(e);
        }
    };

    return (
        <Grid item xs={12}>
            <TableContainer component={Paper}>
                <Table aria-label="custom pagination table">
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            {TABLE_HEADERS.map((header) => (
                                <SortableTableCell
                                    key={header.label}
                                    arrow={sort === header.sortKey ? sortDir : ''}
                                    onClick={() => setSorting(header.sortKey)}
                                >
                                    {header.label}
                                </SortableTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <SkeletonTable rows={10} cells={8} />
                        ) : (
                            rows?.map((row) => {
                                return (
                                    <TableRow
                                        key={`${row.levelType} - ${row.id}`}
                                        sx={
                                            row.intentional_zero
                                                ? { '& > *': { color: '#A9A9A9' } }
                                                : {}
                                        }
                                    >
                                        <TableCell>
                                            <Tooltip
                                                title="Jump to Emissions Data"
                                                placement="right"
                                            >
                                                <IconButton
                                                    onClick={() => navigateByPermission(row)}
                                                >
                                                    <CloseIcon color="error" />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell>{row.emission_description}</TableCell>
                                        <TableCell>{row.site_name || '-'}</TableCell>
                                        <TableCell>{row.emission_source_name}</TableCell>
                                        <TableCell>{`0 ${row.base_unit}`}</TableCell>
                                        <TableCell>{row.assignedto_name || '-'}</TableCell>
                                        <TableCell>{row.owner_name || '-'}</TableCell>
                                        <TableCell align="center">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={row.intentional_zero}
                                                        onChange={() => modifyIntentionalZero(row)}
                                                    />
                                                }
                                            />
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                count={total || 0}
                rowsPerPage={limit}
                component="div"
                page={page}
                onPageChange={(_, newPage) => handlePageChange(newPage, setParams, limit)}
                rowsPerPageOptions={TABLE_ROW_COUNT_OPTIONS}
                onRowsPerPageChange={(event) => handleChangeRowsPerPage(event, setParams)}
            />
        </Grid>
    );
};

export default BlankDatalinesTable;
