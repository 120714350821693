function isValidConfig(selectedPanel, panelConfig) {
    let validConfig = false;
    switch (selectedPanel) {
        // old chart types
        // case 'radial_bar':
        //     validConfig = true;
        //     break;
        case 'bar':
            validConfig = true;
            break;
        case 'pie':
            validConfig = true;
            break;
        case 'total_emissions':
            validConfig = true;
            break;
        // new chart types
        case 'scope_line_chart':
            if (panelConfig?.scopes?.length > 0) {
                validConfig = true;
            }
            break;
        case 'category_bar_chart':
            if (panelConfig?.categories?.length > 0 && panelConfig?.years?.length > 0) {
                validConfig = true;
            }
            break;
        case 'tag_bar_chart':
            if (panelConfig?.tags?.length > 0 && panelConfig?.years?.length > 0) {
                validConfig = true;
            }
            break;
        case 'scope_pie_chart':
            if (
                typeof panelConfig?.scope !== 'undefined' &&
                panelConfig?.scope !== null &&
                typeof panelConfig?.year !== 'undefined'
            ) {
                validConfig = true;
            }
            break;
        case 'category_pie_chart':
            if (
                typeof panelConfig?.category !== 'undefined' &&
                panelConfig?.category !== null &&
                typeof panelConfig?.year !== 'undefined'
            ) {
                validConfig = true;
            }
            break;
        case 'subcategory_pie_chart':
            if (
                typeof panelConfig?.subcategory !== 'undefined' &&
                panelConfig?.subcategory !== null &&
                typeof panelConfig?.year !== 'undefined'
            ) {
                validConfig = true;
            }
            break;
        case 'site_pie_chart':
            if (
                typeof panelConfig?.site !== 'undefined' &&
                panelConfig?.site !== null &&
                typeof panelConfig?.year !== 'undefined'
            ) {
                validConfig = true;
            }
            break;
        case 'site_bar_chart':
            if (
                panelConfig?.sites?.length > 0 &&
                (panelConfig?.scopes?.length > 0 || panelConfig?.categories?.length > 0) &&
                typeof panelConfig?.year !== 'undefined'
            ) {
                validConfig = true;
            }
            break;
        case 'scenario_line_chart':
            if (panelConfig?.scenarios?.length > 0) {
                validConfig = true;
            }
            break;
        case 'source_bar_chart':
            if (
                typeof panelConfig?.source !== 'undefined' &&
                panelConfig?.source !== null &&
                panelConfig?.years?.length > 0
            ) {
                validConfig = true;
            }
            break;
        case 'supplier_bar_chart':
            if (panelConfig?.suppliers?.length > 0 && panelConfig?.years?.length > 0) {
                validConfig = true;
            }
            break;
        case 'scopes_per_intensity_line_chart':
            if (
                panelConfig?.scopes?.length > 0 &&
                panelConfig?.years?.length > 0 &&
                typeof panelConfig?.intensityMetric !== 'undefined' &&
                panelConfig?.intensityMetric !== null
            ) {
                validConfig = true;
            }
            break;
        case 'supplier_netzero_targets_bar_chart':
            if (typeof panelConfig?.year !== 'undefined') {
                validConfig = true;
            }
            break;
        case 'esg_question_bar_chart':
            if (
                // sites validation is not necessary: "if left empty, all sites"
                typeof panelConfig?.esgQuestion !== 'undefined' &&
                panelConfig?.years?.length > 0 &&
                panelConfig?.fields?.length > 0
            ) {
                validConfig = true;
            }
            break;
        default:
            validConfig = false;
            break;
    }
    return validConfig;
}
module.exports.isValidConfig = isValidConfig;
