import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import React, { useEffect } from 'react';
import { useImpSurveyStep, withFormikContext } from './ImpSurveyStep';
import SurveyQuestionHeadline from '../../survey/components/SurveyQuestionHeadline';
import ImpSurveySection from './ImpSurveySection';
import SurveyQuestionSubheader from './SurveyQuestionSubheader';

function QOrganizationalBoundary({
    values,
    setFieldValue,
    sectionIndex,
    formikKey,
    answers,
    q,
    subheader,
}) {
    const { step, addFieldIntoStep } = useImpSurveyStep();
    useEffect(() => {
        addFieldIntoStep(`answers.${formikKey}`, sectionIndex + 1);
    }, [addFieldIntoStep, formikKey, sectionIndex]);

    if (step !== sectionIndex + 1) {
        return null;
    }

    const currentValue = typeof values.answers === 'object' ? values.answers[formikKey] : '';
    return (
        <ImpSurveySection>
            <Grid item xs={12}>
                <SurveyQuestionHeadline>{q}</SurveyQuestionHeadline>
                {subheader && <SurveyQuestionSubheader subheader={subheader} />}
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <InputLabel id="answer-select">Organizational Boundary</InputLabel>
                    <Select
                        fullWidth
                        labelId="answer-select"
                        id="demo-simple-select"
                        value={currentValue}
                        size="small"
                        onChange={(e) => {
                            setFieldValue(`answers.${formikKey}`, e.target.value);
                        }}
                    >
                        {answers.map((answer) => (
                            <MenuItem key={answer} value={answer}>
                                {answer}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
        </ImpSurveySection>
    );
}

export default withFormikContext(QOrganizationalBoundary);
