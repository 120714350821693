import React, { useEffect, useState } from 'react';
import { Grid, IconButton, TextField, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import { doSwaggerCall, useApi } from '../../hooks/useApi';
import useNotify from '../../hooks/useNotify';
import EmissionSourceList from './components/EmissionSourceList';
import ConversionLogicForm from './components/ConversionLogicForm';
import { useGlobalFilters } from '../../hooks/useGlobalFilters';

const ConversionLogicTemplateEditPage = () => {
    const { notifyError, notifySuccess } = useNotify();
    const { templateId } = useParams();
    const navigate = useNavigate();
    const { year } = useGlobalFilters();
    const [templateNameEdit, setTemplateNameEdit] = useState(false);
    const [data, loading, error, setData, reloadData] = useApi(
        'ConversionTemplates',
        'getConversionTemplate',
        {
            id: templateId,
        }
    );
    if (error) {
        notifyError(error);
        navigate('/conversionlogictemplates');
    }

    useEffect(() => {
        reloadData();
    }, [templateId]);

    const editTemplateName = async (name) => {
        if (name.trim() === '') {
            notifyError('Name cannot be empty');
            return;
        }
        try {
            doSwaggerCall(
                'ConversionTemplates',
                'editConversionTemplate',
                { id: data.id },
                {
                    conversionLogics: data.conversionLogics,
                    name,
                    baseUnit: data.baseUnit,
                }
            )
                .then(() => {
                    setTemplateNameEdit(false);
                    reloadData();
                    notifySuccess('Conversion logic template name edited');
                })
                .catch((err) => notifyError(err));
        } catch (err) {
            notifyError(err);
            setTemplateNameEdit(false);
        }
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Grid container justifyContent="space-between" spacing={0}>
                    {templateNameEdit ? (
                        <Grid item xs={6} sx={{ pb: 2 }}>
                            <TextField
                                fullWidth
                                autoComplete="off"
                                label="Name"
                                value={data?.name}
                                required
                                onChange={(ev) =>
                                    setData({
                                        ...data,
                                        name: ev.target.value,
                                    })
                                }
                                onBlur={() => {
                                    editTemplateName(data.name);
                                }}
                                autoFocus
                            />
                        </Grid>
                    ) : (
                        <Grid item xs={12} style={{ alignContent: 'center' }}>
                            <Typography
                                variant="h4"
                                style={{
                                    display: 'inline-block',
                                    fontWeight: 'bold',
                                }}
                            >
                                {`${data?.name || 'Loading...'}`}
                            </Typography>
                            <IconButton onClick={() => setTemplateNameEdit(true)}>
                                <EditIcon />
                            </IconButton>
                        </Grid>
                    )}
                </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
                <EmissionSourceList
                    data={data}
                    loading={loading}
                    reloadData={reloadData}
                    year={year}
                    templateId={templateId}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <ConversionLogicForm
                    data={data}
                    loading={loading}
                    reloadData={reloadData}
                    setData={setData}
                />
            </Grid>
        </Grid>
    );
};

export default ConversionLogicTemplateEditPage;
