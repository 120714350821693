export const AIRPORT_DISTANCE_CALCULATION = 'Passenger Mile';

const AVAILABLE_SOURCES = [
    'Air Travel - Medium Haul (>= 300 miles, < 2300 miles)',
    'Air Travel - Long Haul (>= 2300 miles)',
    'Air Travel - Short Haul (< 300 miles)',
];

const CATEGORIES = ['(Cat 6) Business Travel', '(Cat 7) Employee Commuting'];

// check if the airport calculator should be visible in DatalineFormulaPickerDropdown
export default function checkAirportCalculatorVisibility(
    scopeName,
    categoryName,
    subcategoryName,
    emissionsSourceName
) {
    if (
        scopeName === 'Scope 3' &&
        CATEGORIES.includes(categoryName) &&
        subcategoryName === 'Passenger-Miles' &&
        AVAILABLE_SOURCES.includes(emissionsSourceName)
    ) {
        return true;
    }
    return false;
}
