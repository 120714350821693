import React from 'react';
import QOrganizationalBoundary from './QOrganizationalBoundary';
import QListOfOperations from './QListOfOperations';
import QGenericQuestion from './QGenericQuestion';
import QListOfFacilities from './QListOfFacilities';
import QListOfCategories from './QListOfCategories';
import QListOfRoles from './QListOfRoles';
import QQuantificationMethod from './QQuantificationMethod';

function QQuestionList({ jsonConstants, sectionIndex, questions, categories }) {
    return (
        <React.Fragment key={`section_${sectionIndex}`}>
            {questions.map((_q, questionIndex) => {
                if (typeof jsonConstants[sectionIndex]?.questions[questionIndex] === 'string') {
                    return null;
                }
                const {
                    q,
                    label,
                    subheader,
                    status,
                    scope,
                    customComponent = false,
                } = jsonConstants[sectionIndex]?.questions[questionIndex] || {};
                const formikKey = `answer_${sectionIndex}_${questionIndex}`;

                switch (customComponent) {
                    case 'listOfOperations':
                        return (
                            <QListOfOperations
                                data={jsonConstants}
                                key={questionIndex}
                                formikKey={formikKey}
                                subheader={subheader}
                                q={q}
                                ghg={jsonConstants[sectionIndex]?.questions[questionIndex].ghg}
                                sectionIndex={sectionIndex}
                            />
                        );
                    case 'organizationalBoundary':
                        return (
                            <QOrganizationalBoundary
                                data={jsonConstants}
                                key={questionIndex}
                                formikKey={formikKey}
                                q={q}
                                answers={
                                    jsonConstants[sectionIndex]?.questions[questionIndex].answers
                                }
                                subheader={subheader}
                                sectionIndex={sectionIndex}
                            />
                        );
                    case 'listOfFacilities':
                        return (
                            <QListOfFacilities
                                key={questionIndex}
                                formikKey={formikKey}
                                q={q}
                                subheader={subheader}
                                columns={
                                    jsonConstants[sectionIndex]?.questions[questionIndex].columns
                                }
                                status={status}
                                sectionIndex={sectionIndex}
                            />
                        );
                    case 'listOfCategories':
                        return (
                            <QListOfCategories
                                key={questionIndex}
                                formikKey={formikKey}
                                q={q}
                                subheader={subheader}
                                scope={scope}
                                sectionIndex={sectionIndex}
                                categories={categories}
                            />
                        );
                    case 'listOfRoles':
                        return (
                            <QListOfRoles
                                key={questionIndex}
                                formikKey={formikKey}
                                q={q}
                                subheader={subheader}
                                columns={
                                    jsonConstants[sectionIndex]?.questions[questionIndex].columns
                                }
                                sectionIndex={sectionIndex}
                            />
                        );
                    case 'quantificationMethod':
                        return (
                            <QQuantificationMethod
                                key={questionIndex}
                                formikKey={formikKey}
                                q={q}
                                label={label}
                                sectionIndex={sectionIndex}
                                subheader={subheader}
                            />
                        );
                    default:
                        return (
                            <QGenericQuestion
                                key={questionIndex}
                                formikKey={formikKey}
                                q={q}
                                label={label}
                                sectionIndex={sectionIndex}
                                subheader={subheader}
                            />
                        );
                }
            })}
        </React.Fragment>
    );
}
export default QQuestionList;
