import { read, utils } from 'xlsx';
import { doSwaggerCall } from '../hooks/useApi';
import { PERMISSION_SET_NAMES } from './constans';

// helper function to check existing permissions
function checkExistingPermissions(permission) {
    let existingPermissionFlag = false;
    Object.values(PERMISSION_SET_NAMES).forEach((p) => {
        if (p === permission) {
            existingPermissionFlag = true;
        }
    });

    return existingPermissionFlag;
}

// helper function to check rows
// eslint-disable-next-line require-await
async function checkData(data) {
    const userData = data;
    for (let i = 0; i < data.length; i++) {
        // check incorrect data (empty cells)
        if (
            typeof data[i].Name === 'undefined' ||
            typeof data[i].Email === 'undefined' ||
            typeof data[i].Permission === 'undefined' ||
            checkExistingPermissions(data[i].Permission) === false
        ) {
            userData[i].wrong = 'Wrong data';
        }
    }

    // check duplicates
    userData.map((oneUserData, index) => {
        userData.find((udata, idx) => {
            if (
                udata.Name === oneUserData.Name &&
                udata.Email === oneUserData.Email &&
                idx !== index &&
                typeof udata.wrong === 'undefined' &&
                typeof oneUserData.wrong === 'undefined'
            ) {
                userData[index].wrong = 'Duplicate';
                userData[idx].wrong = 'Duplicate';
                return true;
            }
            return false;
        });
        return userData;
    });

    const checkedData = userData.map((row) => ({
        name: row.Name,
        email: row.Email.trim(),
        permission: row.Permission,
        wrong: row.wrong,
    }));

    return checkedData;
}

// Parse xlsx sheets
export const parseXlsxSheets = (fileName, cb, failCb = () => {}) => {
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(fileName);
    fileReader.onload = (e) => {
        const bufferArray = e?.target.result;
        const wb = read(bufferArray, { type: 'buffer' });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];

        const data = utils.sheet_to_json(ws);
        checkData(data)
            .then((checkedData) => {
                cb(checkedData);
            })
            .catch((err) => {
                console.log(err);
                failCb(err);
            });
    };
};

// Handle import
export const runImport = (parsedData, dryRun, companyId) => {
    let filteredUsers = parsedData;
    if (!dryRun) {
        // filter wrong data, duplicate and no location rows
        filteredUsers = parsedData.filter((d) => typeof d.wrong === 'undefined');
    }

    // import only good rows
    try {
        return doSwaggerCall(
            'Users',
            'importUsers',
            { dryRun },
            { filteredUsers, companyId: parseInt(companyId, 10) }
        );
    } catch (error) {
        throw new Error(error);
    }
};
