export const customNivoTheme = (darkMode) => {
    const colorWithDarkMode = darkMode ? '#ffffff' : '#666666';
    return {
        tooltip: {
            container: {
                fontSize: 12,
                color: '#666666',
            },
            text: {
                fontSize: 11,
            },
        },
        axis: {
            tickColor: colorWithDarkMode,
            ticks: {
                line: {
                    stroke: colorWithDarkMode,
                    strokeWidth: 2,
                },
                text: {
                    fill: colorWithDarkMode,
                    fontSize: 10,
                },
                domain: {
                    line: {
                        stroke: colorWithDarkMode,
                        strokeWidth: 2,
                    },
                },
            },
            legend: {
                text: {
                    fill: colorWithDarkMode,
                    fontSize: 14,
                },
            },
        },
        legends: {
            title: {
                text: {
                    fontSize: 18,
                    fill: colorWithDarkMode,
                },
            },
            text: {
                fontSize: 15,
                fill: colorWithDarkMode,
            },
        },
    };
};

export const customAxisColor = (darkMode) => {
    return darkMode ? '#ffffff' : '#616674';
};

export const LINECHART_PROPS = Object.freeze({
    colors: { scheme: 'category10' },
    curve: 'linear',
    lineWidth: 3,
    areaOpacity: 0.05,
    areaBlendMode: 'hard-light',
    enableArea: true,
    pointSize: 8,
    pointColor: { from: 'color', modifiers: [] },
    pointBorderColor: { from: 'color', modifiers: [] },
    pointBorderWidth: 2,
    axisBottom: {
        tickRotation: -39,
        legendOffset: -50,
        tickPadding: 5,
    },
    yFormat: ' >-,.2f',
});

export const BARCHART_PROPS = Object.freeze({
    colors: { scheme: 'category10' },
    enableLabel: true,
    borderRadius: 2,
    borderWidth: 1,
    labelSkipHeight: 2,
    labelSkipWidth: 12,
    borderColor: '#666666',
    labelTextColor: 'black',
    padding: 0.4,
    valueFormat: ' >-,',
});

export const PIECHART_PROPS = Object.freeze({
    colors: { scheme: 'category10' },
    activeOuterRadiusOffset: 10,
    activeInnerRadiusOffset: 10,
    innerRadius: 0.3,
    borderWidth: 2,
    padAngle: 1,
    cornerRadius: 2,
    borderColor: {
        from: 'color',
        modifiers: [['darker', '0.2']],
    },
    padding: 0.7,
    enableArcLabels: false,
});

export const formatChartNumber = (e) => {
    return Math.round(e.value)
        .toFixed()
        .replace(new RegExp(`\\d(?=(\\d{${3}})+$)`, 'g'), '$&,');
};
