import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import CustomTreeItem from '../../atom/CustomTreeItem';

function EsgQuestionLevel({ checkedData, esg_question, setCheckedData }) {
    return (
        <CustomTreeItem
            nodeId={`esg_question - ${esg_question.id}`}
            label={
                <FormControlLabel
                    label={esg_question.name}
                    labelPlacement="end"
                    control={
                        <Checkbox
                            checked={
                                !!checkedData.find(
                                    (c) => c.id === esg_question.id && c.type === 'question'
                                )
                            }
                            onChange={() => {
                                const removeReqQuestion = checkedData.find(
                                    (item) =>
                                        item.id === esg_question.id && item.type === 'question'
                                );

                                setCheckedData(
                                    removeReqQuestion
                                        ? checkedData.filter((data) => data !== removeReqQuestion)
                                        : [
                                              ...checkedData,
                                              {
                                                  type: 'question',
                                                  id: esg_question.id,
                                              },
                                          ]
                                );
                            }}
                        />
                    }
                />
            }
        />
    );
}

export default EsgQuestionLevel;
