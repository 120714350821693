import { Alert, Grid } from '@mui/material';
import React from 'react';
import SurveyQuestionHeadline from '../components/SurveyQuestionHeadline';
import { SURVEY_BUTTON_TYPES, SurveyButton } from '../components/SurveyButton';
import {
    withFormikContext,
    withStepAndValidation,
    withSurveySection,
} from '../components/SurveyStep';

function QReportHas({ values, setFieldValue, year, companyName }) {
    const haveExactData = values?.details?.haveExactData;
    const setHaveExactData = (newState) => setFieldValue('details.haveExactData', newState);
    // step 3 or step 4 is already filled out
    const alreadyFilledOutStep34 =
        values?.details?.hasScope12FullReport === true || values?.details?.datalines?.length > 0;

    return (
        <>
            <Grid item xs={12}>
                <SurveyQuestionHeadline>
                    Have you quantified the exact cradle-to-gate lifecycle CO2mte (carbon dioxide
                    metric tons equivalent) emissions associated with the products or services sold
                    to {companyName} in {year}?
                </SurveyQuestionHeadline>
            </Grid>
            {alreadyFilledOutStep34 && values?.details?.haveExactData === false && (
                <Grid item xs={12}>
                    <Alert sx={{ mt: 1 }} severity="warning">
                        You already provided information in the &quot;Company-Wide GHG
                        emissions&quot; or &quot;GHG Emissions calculator&quot; sections.
                        <br />
                        If you provide information here, those inputs will be ignored.
                    </Alert>
                </Grid>
            )}
            <Grid item xs={6}>
                <SurveyButton
                    type={SURVEY_BUTTON_TYPES.YES}
                    active={haveExactData === true}
                    onClick={() => {
                        setHaveExactData(true);
                        setFieldValue('details.hasScope12FullReport', false);
                    }}
                >
                    Yes
                </SurveyButton>
            </Grid>
            <Grid item xs={6}>
                <SurveyButton
                    type={SURVEY_BUTTON_TYPES.NO}
                    active={haveExactData === false}
                    onClick={() => {
                        setHaveExactData(false);
                    }}
                >
                    No
                </SurveyButton>
            </Grid>
        </>
    );
}

export default withStepAndValidation(
    withSurveySection(withFormikContext(QReportHas)),
    3,
    ['details.haveExactData'],
    // no validation
    (values, errors) => {
        if (typeof values.details.haveExactData === 'undefined') {
            // eslint-disable-next-line no-param-reassign
            errors['details.haveExactData'] = 'Please select an option.';
        }
        return errors;
    }
    // always active
);
