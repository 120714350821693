import React, { useEffect, useState } from 'react';
import { Grid, LinearProgress } from '@mui/material';
import useNotify from '../../../hooks/useNotify';
import { doSwaggerCall } from '../../../hooks/useApi';
import ScopeOrCategoryOrSubcatOrSitePieChart from './charts/ScopeOrCategoryOrSubcatOrSitePieChart';

const BySelectedSubcategory = ({ subcategory, year, setChartData }) => {
    const { notifyError } = useNotify();
    const [subcategoryPieData, setSubcategoryPieData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        doSwaggerCall('Dashboards', 'getDataForSubcategoryPieChart', {
            subcategory,
            year,
        })
            .then((res) => {
                setSubcategoryPieData(res);
                setChartData(res.subcategoryData);
                setLoading(false);
            })
            .catch((error) => {
                notifyError(error);
            });
    }, [subcategory, year, setLoading, setSubcategoryPieData, setChartData]);

    return (
        <Grid item xs={12}>
            {subcategoryPieData?.length === 0 ? (
                <Grid item>
                    {loading ? <LinearProgress sx={{ mt: 0.25 }} /> : <h5>No data available</h5>}
                </Grid>
            ) : (
                <ScopeOrCategoryOrSubcatOrSitePieChart
                    data={subcategoryPieData.subcategoryData}
                    year={year}
                    type="subcategory"
                />
            )}
        </Grid>
    );
};

export default BySelectedSubcategory;
