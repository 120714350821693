import { Checkbox, FormControl, FormControlLabel, Grid, Typography } from '@mui/material';
import React from 'react';

const CHECKBOX_DATA_FOR_DATALINES = [
    { label: 'Emission description', name: 'emission_description' },
    { label: 'Estimated', name: 'is_estimated' },
    { label: 'Actual values', name: 'actual_values' },
    { label: 'Notes', name: 'notes' },
    { label: 'Documents', name: 'documents' },
    { label: 'Assigned to', name: 'assignedto_id' },
    { label: 'Owner', name: 'owner_id' },
    { label: 'From and To date', name: 'date' },
    { label: 'Cost', name: 'cost' },
    { label: 'Tags', name: 'tags' },
];

const CHECKBOX_DATA_FOR_ESGS = [
    { label: 'Answers', name: 'values' },
    { label: 'Owner', name: 'owner_id' },
    { label: 'Assigned to', name: 'assignedto_id' },
    { label: 'Notes', name: 'notes' },
    { label: 'Mark completed', name: 'mark_completed' },
    { label: 'Documents', name: 'documents' },
];

const SettingsPanel = ({ settings, setSettings, isDataline }) => {
    const toggleSetting = (settingName) => {
        if (settings.includes(settingName)) {
            setSettings(settings.filter((e) => e !== settingName));
        } else {
            setSettings([...settings, settingName]);
        }
    };

    const renderCheckboxes = (checkboxData) => {
        return checkboxData.map(({ label, name }) => (
            <Grid item xs={12}>
                <FormControlLabel
                    label={label}
                    control={
                        <Checkbox
                            checked={settings.includes(name)}
                            onChange={() => toggleSetting(name)}
                        />
                    }
                />
            </Grid>
        ));
    };

    return (
        <FormControl fullWidth>
            <Grid container spacing={0}>
                <Grid item xs={12} sx={{ pt: 2, pb: 2 }}>
                    <Typography variant="h6">Included data</Typography>
                </Grid>
                <Grid>
                    <Typography variant="subtitle1" sx={{ pb: 2 }}>
                        Select the data that you would like to include in the copy.
                    </Typography>
                </Grid>
                {renderCheckboxes(
                    isDataline ? CHECKBOX_DATA_FOR_DATALINES : CHECKBOX_DATA_FOR_ESGS
                )}
            </Grid>
        </FormControl>
    );
};

export default SettingsPanel;
