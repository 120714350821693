import { Checkbox, Chip, FormControl, FormControlLabel, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useGlobalFilters } from '../../hooks/useGlobalFilters';
import { useApi } from '../../hooks/useApi';
import { useAuth } from '../../hooks/useAuth';
import SearchInput from '../atom/SearchInput';

function GlobalSiteFilter() {
    const {
        setSiteFilters,
        selectedSites,
        setSelectedSites,
        selectedTags,
        setSelectedTags,
        site: rawSelectedSiteIds,
        siteNames,
    } = useGlobalFilters();
    const [{ limit, page }, setParams] = useState({ limit: 1000, page: 0 });
    const { user } = useAuth();
    const [search, setSearch] = useState('');

    const [sitesData, loading] = useApi('Sites', 'getSitesInCompany', {
        id: user.company_id,
        limit,
        offset: page * limit,
    });

    let possibleTags = [];
    if (sitesData?.sites) {
        for (let i = 0; i < sitesData?.sites.length; i++) {
            for (let j = 0; j < sitesData?.sites[i].tags.length; j++) {
                possibleTags = [...possibleTags, sitesData?.sites[i].tags[j]];
            }
        }
        possibleTags = possibleTags.filter((item, i, ar) => ar.indexOf(item) === i);
    }

    useEffect(() => {
        if (loading === true) {
            return;
        }
        let allSelected = [...selectedSites];
        let allSelectedNames =
            sitesData?.sites
                ?.filter((site) => selectedSites.includes(site.id))
                .map((data) => data.name) || [];
        if (sitesData?.sites) {
            for (let i = 0; i < sitesData?.sites.length; i++) {
                for (let j = 0; j < selectedTags.length; j++) {
                    if (sitesData.sites[i].tags.includes(selectedTags[j])) {
                        allSelected.push(sitesData.sites[i].id);
                        continue;
                    }
                }
            }
        }
        allSelectedNames.push(...selectedTags);
        if (allSelected.includes(0)) {
            allSelectedNames.push('Corporate');
        }
        allSelected = [...new Set(allSelected)];
        allSelectedNames = [...new Set(allSelectedNames)];
        setSiteFilters({ siteFilter: allSelected, siteNamesFilter: allSelectedNames });
    }, [selectedTags, selectedSites, setSiteFilters, sitesData, loading]);

    if (loading) {
        return null;
    }

    const filteredSites = sitesData?.sites.filter((s) =>
        s.name.toLowerCase().includes(`${search}`.toLowerCase())
    );
    const filteredTags = possibleTags.filter((tag) =>
        tag.toLowerCase().includes(`${search}`.toLowerCase())
    );

    return (
        <FormControl fullWidth>
            <Grid container style={{ maxHeight: '80vh' }} spacing={0}>
                <Grid item xs={12} sx={{ pt: 2, pb: 1 }}>
                    <Typography variant="h6">Current selection</Typography>
                </Grid>
                <Grid item xs={12}>
                    {siteNames.map((e) => {
                        return (
                            <Chip key={e} sx={{ mr: 1 }} label={e} size="small" color="primary" />
                        );
                    })}
                    {siteNames.length === 0 ? (
                        <Chip
                            sx={{ mr: 1 }}
                            label="All sites and Corporate are selected"
                            size="small"
                            color="primary"
                        />
                    ) : (
                        ''
                    )}
                </Grid>
                <Grid item xs={12} sx={{ pt: 2, pb: 1 }}>
                    <Typography variant="h6">Change selection</Typography>
                </Grid>
                <Grid item xs={12} sx={{ mb: 2 }} style={{ textAlign: 'right' }}>
                    <SearchInput
                        search={search}
                        setSearch={setSearch}
                        setParams={setParams}
                        limit={limit}
                        fullWidth
                    />
                </Grid>
                <Grid key="corporate" xs={12} item>
                    <FormControlLabel
                        label="Corporate"
                        control={
                            <Checkbox
                                checked={selectedSites.includes(0)}
                                onChange={() => {
                                    setSelectedSites(
                                        selectedSites.includes(0)
                                            ? selectedSites.filter((e) => e !== 0)
                                            : [...selectedSites, 0]
                                    );
                                }}
                            />
                        }
                    />
                </Grid>
                {filteredTags.length > 0 && (
                    <FormControlLabel
                        label="All tags"
                        style={{ borderTop: '2px solid #eee', width: '100%' }}
                        control={
                            <Checkbox
                                checked={
                                    filteredTags.filter((e) => selectedTags.includes(e)).length ===
                                    filteredTags.length
                                }
                                indeterminate={
                                    filteredTags.filter((e) => selectedTags.includes(e)).length !==
                                        filteredTags.length &&
                                    filteredTags.filter((e) => selectedTags.includes(e)).length !==
                                        0
                                }
                                onChange={(e) => {
                                    if (!e.target.checked) {
                                        setSelectedTags((oldTags) =>
                                            oldTags.filter((o) => !filteredTags.includes(o))
                                        );
                                    } else {
                                        setSelectedTags((oldtags) => {
                                            return [...new Set([...oldtags, ...filteredTags])];
                                        });
                                    }
                                }}
                            />
                        }
                    />
                )}
                {filteredTags.map((tag) => {
                    return (
                        <Grid key={tag} xs={12} item sx={{ pl: 1.5 }}>
                            <FormControlLabel
                                label={tag}
                                control={
                                    <Checkbox
                                        checked={selectedTags.includes(tag)}
                                        onChange={() => {
                                            setSelectedTags(
                                                selectedTags.includes(tag)
                                                    ? selectedTags.filter((e) => e !== tag)
                                                    : [...selectedTags, tag]
                                            );
                                        }}
                                    />
                                }
                            />
                            {sitesData.sites
                                .filter((site) => site.tags.includes(tag))
                                .map((site) => {
                                    return (
                                        <Chip
                                            key={site.name}
                                            sx={{ mr: 1 }}
                                            label={site.name}
                                            size="small"
                                            color={
                                                rawSelectedSiteIds.includes(site.id) ||
                                                selectedTags.includes(tag)
                                                    ? 'primary'
                                                    : 'default'
                                            }
                                        />
                                    );
                                })}
                        </Grid>
                    );
                })}
                {filteredSites.length > 0 && (
                    <FormControlLabel
                        label="All sites"
                        sx={{ borderTop: '2px solid #eee', width: '100%' }}
                        control={
                            <Checkbox
                                checked={
                                    // not good atm
                                    filteredSites.filter(
                                        (site) =>
                                            selectedSites.includes(site.id) ||
                                            selectedTags.filter((tag) => site.tags.includes(tag))
                                                .length > 0
                                    ).length === filteredSites.length
                                }
                                indeterminate={
                                    // not good atm
                                    filteredSites.filter(
                                        (site) =>
                                            selectedSites.includes(site.id) ||
                                            selectedTags.filter((tag) => site.tags.includes(tag))
                                                .length > 0
                                    ).length !== filteredSites.length &&
                                    // not good atm
                                    filteredSites.filter(
                                        (site) =>
                                            selectedSites.includes(site.id) ||
                                            selectedTags.filter((tag) => site.tags.includes(tag))
                                                .length > 0
                                    ).length > 0
                                }
                                onChange={(e) => {
                                    if (!e.target.checked) {
                                        setSelectedSites((oldsites) => {
                                            return oldsites.filter(
                                                (o) => !filteredSites.map((f) => f.id).includes(o)
                                            );
                                        });
                                    } else {
                                        setSelectedSites((oldsites) => {
                                            return [
                                                ...new Set([
                                                    ...oldsites,
                                                    ...filteredSites.map((f) => f.id),
                                                ]),
                                            ];
                                        });
                                    }
                                }}
                            />
                        }
                    />
                )}
                {filteredSites.map((site) => {
                    return (
                        <Grid key={site.id} xs={12} item sx={{ pl: 1.5 }}>
                            <FormControlLabel
                                label={site.name}
                                control={
                                    <Checkbox
                                        checked={
                                            selectedSites.includes(site.id) ||
                                            selectedTags.filter((tag) => site.tags.includes(tag))
                                                .length > 0
                                        }
                                        onChange={() => {
                                            setSelectedSites(
                                                selectedSites.includes(site.id)
                                                    ? selectedSites.filter((e) => e !== site.id)
                                                    : [...selectedSites, site.id]
                                            );
                                        }}
                                        disabled={
                                            selectedTags.filter((tag) => site.tags.includes(tag))
                                                .length > 0
                                        }
                                    />
                                }
                            />
                            {site?.tags?.map((tag) => {
                                return (
                                    <Chip
                                        key={tag}
                                        sx={{ mr: 1 }}
                                        label={tag}
                                        size="small"
                                        color={selectedTags.includes(tag) ? 'primary' : 'default'}
                                    />
                                );
                            })}
                        </Grid>
                    );
                })}
            </Grid>
        </FormControl>
    );
}

export default GlobalSiteFilter;
