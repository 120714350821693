import { TextField } from '@mui/material';
import React from 'react';
import { useGlobalFilters } from '../../hooks/useGlobalFilters';
import { sourceAutocomplete } from '../../autocomplete';
import AutocompleteSelect from '../atom/AutoCompleteSelect';
import { groupEmissionSource } from '../../helpers/groups';

function GlobalEmissionSourceFilter() {
    const { year, emissionSource, setSourceFilters, subcategory, category, scope, setEdited } =
        useGlobalFilters();
    return (
        <AutocompleteSelect
            grouping={groupEmissionSource}
            initCall={sourceAutocomplete.init}
            autocompleteCall={sourceAutocomplete.search}
            value={emissionSource}
            setValue={(ev, getItem) => {
                const item = getItem(ev);
                setSourceFilters({
                    scopeFilter: item?.scope_id,
                    scopeNameFilter: item?.scope_name || null,
                    categoryFilter: item?.category_id,
                    categoryNameFilter: item?.category_name || null,
                    subcategoryFilter: item?.subcategory_id,
                    subcategoryNameFilter: item?.subcategory_name || null,
                    emissionSourceFilter: item?.id || null,
                    emissionSourceNameFilter: item?.name || null,
                });
                setEdited(false);
            }}
            searchParams={{
                scope,
                category,
                subcategory,
                year,
                sourceLimitation: true,
            }}
        >
            <TextField label="Source" fullWidth size="small" />
        </AutocompleteSelect>
    );
}

export default GlobalEmissionSourceFilter;
