import { ResponsivePie } from '@nivo/pie';
import React, { useEffect } from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import { convertUnitGToKg, formatNumber } from '../../../../helpers/convertUnits';
import { LegendsContextProvider, useLegends } from '../../../../hooks/useLegends';
import { PIECHART_PROPS, customNivoTheme } from '../../../../helpers/chartStyleHelper';
import { useDarkMode } from '../../../../hooks/useDarkMode';
import PieLegendDisplay from '../PieLegendDisplay';

const PieLegendSink = ({ dataWithArc }) => {
    const { setPieLegends } = useLegends();

    useEffect(() => {
        setPieLegends(dataWithArc);
    }, [setPieLegends, dataWithArc]);
    return null;
};

const PieChart = ({ data, type }) => {
    const { darkMode } = useDarkMode();
    // todo: check if we need to show data with 0 value or not
    // const filteredData = data.statistics.filter((f) => typeof f.sumOfCO2e !== 'undefined');
    const filteredData = data.statistics;
    const pieChartData = filteredData.map(({ emissionSource, sumOfCO2e }) => ({
        id: emissionSource,
        value: Number.isNaN(sumOfCO2e) ? 0 : sumOfCO2e.toFixed(2),
    }));
    const pieChartCategoryData = filteredData.map(({ subcategory, sumOfCO2e }) => ({
        id: subcategory,
        value: Number.isNaN(sumOfCO2e) ? 0 : sumOfCO2e.toFixed(2),
    }));

    return (
        <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <LegendsContextProvider>
                        <Paper sx={{ borderColor: 'transparent' }}>
                            <Grid item xs={12}>
                                <>
                                    {type === 'category' ? (
                                        <Typography variant="h5">
                                            {' '}
                                            Category: {data?.category}{' '}
                                        </Typography>
                                    ) : (
                                        <Typography variant="h5">
                                            {data?.category} &gt; {data?.subcategory}
                                        </Typography>
                                    )}
                                    <div style={{ display: 'flex' }}>
                                        <Typography variant="h4" fontWeight="bolder">
                                            {formatNumber(convertUnitGToKg(data?.sum) / 1000)}
                                        </Typography>
                                        <Typography
                                            variant="h4"
                                            fontWeight="bolder"
                                            style={{ marginLeft: '6px' }}
                                        >
                                            CO2e (mt)
                                        </Typography>
                                    </div>
                                </>
                            </Grid>
                            <Grid item xs={12} sx={{ aspectRatio: '2/1', flex: '1 1 0' }}>
                                <ResponsivePie
                                    data={type === 'category' ? pieChartCategoryData : pieChartData}
                                    theme={customNivoTheme(darkMode)}
                                    colors={PIECHART_PROPS.colors}
                                    arcLabelsTextColor={PIECHART_PROPS.arcLabelsTextColor}
                                    activeInnerRadiusOffset={PIECHART_PROPS.activeInnerRadiusOffset}
                                    activeOuterRadiusOffset={PIECHART_PROPS.activeOuterRadiusOffset}
                                    innerRadius={PIECHART_PROPS.innerRadius}
                                    borderWidth={PIECHART_PROPS.borderWidth}
                                    padAngle={PIECHART_PROPS.padAngle}
                                    cornerRadius={PIECHART_PROPS.cornerRadius}
                                    borderColor={PIECHART_PROPS.borderColor}
                                    padding={PIECHART_PROPS.padding}
                                    enableArcLabels={PIECHART_PROPS.enableArcLabels}
                                    animate={false}
                                    colorBy="value"
                                    indexBy="emission_source"
                                    margin={{ top: 15, right: 15, bottom: 15, left: 15 }}
                                    valueFormat={(value) =>
                                        `${Number(value / 1000 / 1000).toLocaleString('en-US', {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        })} - ${
                                            Number.isNaN((value / data.sum) * 100)
                                                ? 0
                                                : Math.round((value / data.sum) * 100)
                                        } %`
                                    }
                                    enableArcLinkLabels={false}
                                    motionConfig="stiff"
                                    layers={['arcLinkLabels', 'arcs', 'arcLabels', PieLegendSink]}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <PieLegendDisplay />
                            </Grid>
                        </Paper>
                    </LegendsContextProvider>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default PieChart;
