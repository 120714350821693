import React, { useContext, useState } from 'react';

const DarkModeContext = React.createContext();

export function DarkModeContextProvider({ children }) {
    const [darkMode, setDarkMode] = useState(false);

    return (
        <DarkModeContext.Provider value={{ darkMode, setDarkMode }}>
            {children}
        </DarkModeContext.Provider>
    );
}

DarkModeContext.displayName = 'DarkModeContext';

/**
 * Dark mode context
 * @returns {{darkMode, setDarkMode}|unknown}
 */
export function useDarkMode() {
    return useContext(DarkModeContext);
}
