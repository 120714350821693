// Helper function to resolve notification types
function notificationTypeMapping(notificationType, datalineName) {
    let resultNotification = '';
    switch (notificationType) {
        case 'dataline_assigned':
            resultNotification = `You have a new dataline assigned to you: ${datalineName}`;
            break;
        case 'dataline_owned':
            resultNotification = `You have a new dataline owned by you: ${datalineName}`;
            break;
        case 'dataline_assigned_remove':
            resultNotification = `You have been removed from dataline (assign): ${datalineName}`;
            break;
        case 'dataline_owned_remove':
            resultNotification = `You have been removed from dataline (owned): ${datalineName}`;
            break;
        case 'dataline_edited':
            resultNotification = `A dataline has been edited that is owned by you: ${datalineName}`;
            break;
        case 'dataline_deleted':
            resultNotification = `A dataline has been deleted that is owned by you: ${datalineName}`;
            break;
        default:
            break;
    }
    return resultNotification;
}
module.exports.notificationTypeMapping = notificationTypeMapping;
