import {
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Tooltip,
} from '@mui/material';
import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Edit from '@mui/icons-material/Edit';
import SkeletonTable from '../../../components/atom/SkeletonTable';
import AutocompleteSelect from '../../../components/atom/AutoCompleteSelect';
import FloatInput from '../../../components/atom/FloatInput';
import {
    TABLE_ROW_COUNT_OPTIONS,
    WARNING_CONTENT_RECALCULATE_TEXT,
} from '../../../helpers/constans';
import { handleChangeRowsPerPage, handlePageChange } from '../../../helpers/handleTableFunctions';
import { locationAutocomplete } from '../../../autocomplete';
import { MODAL_TYPE, useModals } from '../../../hooks/useModal';
import CommonButton from '../../../components/atom/CommonButton';

const isValidFactorEditForm = (v, idx) => {
    if (
        v.factors[idx].co2_factor === '' ||
        v.factors[idx].ch4_factor === '' ||
        v.factors[idx].n2oFactor === '' ||
        v.factors[idx].source === ''
    ) {
        return false;
    }
    return true;
};
const isValidFactorAddForm = (value) => {
    if (
        value.co2Factor === '' ||
        value.ch4Factor === '' ||
        value.n2oFactor === '' ||
        value.source === ''
    ) {
        return false;
    }
    return true;
};

const EmissionFactorsTable = ({
    loading,
    limit,
    values,
    setFieldValue,
    rowEdit,
    handleChange,
    onEdit,
    setRowEdit,
    addValue,
    setAddValue,
    isSubmitting,
    data,
    page,
    setParams,
    deleteFactor,
}) => {
    const { showModal } = useModals();

    return (
        <>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell width="23%">Location</TableCell>
                        <TableCell width="16%">
                            CO<sub>2</sub> factor <small>kg / base unit</small>
                        </TableCell>
                        <TableCell width="16%">
                            CH<sub>4</sub> factor <small>g / base unit</small>
                        </TableCell>
                        <TableCell width="16%">
                            N<sub>2</sub>O factor <small>g / base unit</small>
                        </TableCell>
                        <TableCell>Source</TableCell>
                        <TableCell width="10%">&nbsp;</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading ? (
                        <SkeletonTable rows={limit} cells={6} />
                    ) : (
                        <>
                            {values?.factors.map((row, idx) => (
                                <TableRow key={row.id}>
                                    <TableCell>
                                        <AutocompleteSelect
                                            initCall={locationAutocomplete.init}
                                            autocompleteCall={locationAutocomplete.search}
                                            value={row.location_id}
                                            setValue={(ev) =>
                                                setFieldValue(`factors[${idx}].location_id`, ev)
                                            }
                                            disabled={rowEdit !== row.id}
                                            forceInputProps={{
                                                label: '',
                                            }}
                                        >
                                            <TextField label="Location" required />
                                        </AutocompleteSelect>
                                    </TableCell>
                                    <TableCell>
                                        <FloatInput
                                            name={row.idx}
                                            fullWidth
                                            required
                                            type="number"
                                            value={row.co2_factor || 0}
                                            onChange={(ev) => {
                                                setFieldValue(
                                                    `factors[${idx}].co2_factor`,
                                                    ev.target.rawValue
                                                );
                                                handleChange(ev);
                                            }}
                                            positiveOnly={false}
                                            disabled={rowEdit !== row.id}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <FloatInput
                                            name={row.idx}
                                            fullWidth
                                            required
                                            type="number"
                                            value={row.ch4_factor || 0}
                                            onChange={(ev) => {
                                                setFieldValue(
                                                    `factors[${idx}].ch4_factor`,
                                                    ev.target.rawValue
                                                );
                                                handleChange(ev);
                                            }}
                                            positiveOnly={false}
                                            disabled={rowEdit !== row.id}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <FloatInput
                                            name={row.idx}
                                            fullWidth
                                            required
                                            type="number"
                                            value={row.n2o_factor || 0}
                                            onChange={(ev) => {
                                                setFieldValue(
                                                    `factors[${idx}].n2o_factor`,
                                                    ev.target.rawValue
                                                );
                                                handleChange(ev);
                                            }}
                                            positiveOnly={false}
                                            disabled={rowEdit !== row.id}
                                        />
                                    </TableCell>
                                    <TableCell colSpan={!values?.systemManaged ? 1 : 2}>
                                        {row.source ? (
                                            <Tooltip title={row.source}>
                                                <TextField
                                                    name={row.idx}
                                                    fullWidth
                                                    required
                                                    value={row.source}
                                                    onChange={(ev) => {
                                                        setFieldValue(
                                                            `factors[${idx}].source`,
                                                            ev.target.value
                                                        );
                                                        handleChange(ev);
                                                    }}
                                                    disabled={rowEdit !== row.id}
                                                />
                                            </Tooltip>
                                        ) : (
                                            <TextField
                                                name={row.idx}
                                                fullWidth
                                                required
                                                value={row.source}
                                                onChange={(ev) => {
                                                    setFieldValue(
                                                        `factors[${idx}].source`,
                                                        ev.target.value
                                                    );
                                                    handleChange(ev);
                                                }}
                                                disabled={rowEdit !== row.id}
                                            />
                                        )}
                                    </TableCell>
                                    {!values?.systemManaged && (
                                        <TableCell align="right">
                                            {rowEdit === row.id ? (
                                                <CommonButton
                                                    onClick={() => {
                                                        onEdit(values, idx);
                                                        setRowEdit(false);
                                                    }}
                                                    sx={{
                                                        mr: 0.625,
                                                    }}
                                                    disabled={
                                                        row.source === '' ||
                                                        row.co2_factor === '' ||
                                                        row.ch4_factor === '' ||
                                                        row.n2o_factor === ''
                                                    }
                                                    label="Save"
                                                    buttonType="primary"
                                                />
                                            ) : (
                                                <IconButton
                                                    disabled={
                                                        isSubmitting ||
                                                        !isValidFactorEditForm(values, idx)
                                                    }
                                                    onClick={() => setRowEdit(row.id)}
                                                >
                                                    <Edit />
                                                </IconButton>
                                            )}
                                            <IconButton
                                                onClick={() => {
                                                    showModal(MODAL_TYPE.CONFIRMATION, {
                                                        title: `Are you sure you want to remove this factor?`,
                                                        content: 'This action is irreversible.',
                                                        warningContent:
                                                            WARNING_CONTENT_RECALCULATE_TEXT,
                                                        confirm: () => deleteFactor(row.id),
                                                    });
                                                }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    )}
                                </TableRow>
                            ))}
                            {rowEdit === 'new' && (
                                <TableRow>
                                    <TableCell>
                                        <AutocompleteSelect
                                            initCall={locationAutocomplete.init}
                                            autocompleteCall={locationAutocomplete.search}
                                            value={addValue?.location_id}
                                            setValue={(ev) =>
                                                setAddValue(() => {
                                                    return {
                                                        ...addValue,
                                                        locationId: ev,
                                                    };
                                                })
                                            }
                                        >
                                            <TextField label="Location" required />
                                        </AutocompleteSelect>
                                    </TableCell>
                                    <TableCell>
                                        <FloatInput
                                            fullWidth
                                            required
                                            type="number"
                                            value={addValue?.co2Factor}
                                            onChange={(ev) =>
                                                setAddValue(() => {
                                                    return {
                                                        ...addValue,
                                                        co2Factor: ev.target.rawValue,
                                                    };
                                                })
                                            }
                                            positiveOnly={false}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <FloatInput
                                            fullWidth
                                            required
                                            type="number"
                                            value={addValue?.ch4Factor}
                                            onChange={(ev) =>
                                                setAddValue(() => {
                                                    return {
                                                        ...addValue,
                                                        ch4Factor: ev.target.rawValue,
                                                    };
                                                })
                                            }
                                            positiveOnly={false}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <FloatInput
                                            fullWidth
                                            required
                                            value={addValue?.n2oFactor}
                                            onChange={(ev) =>
                                                setAddValue(() => {
                                                    return {
                                                        ...addValue,
                                                        n2oFactor: ev.target.rawValue,
                                                    };
                                                })
                                            }
                                            positiveOnly={false}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            fullWidth
                                            required
                                            value={addValue?.source}
                                            onChange={(ev) =>
                                                setAddValue(() => {
                                                    return {
                                                        ...addValue,
                                                        source: ev.target.value,
                                                    };
                                                })
                                            }
                                        />
                                    </TableCell>
                                    <TableCell align="right">
                                        <CommonButton
                                            type="submit"
                                            sx={{
                                                mr: 0.625,
                                            }}
                                            label="Save"
                                            buttonType="primary"
                                        />
                                    </TableCell>
                                </TableRow>
                            )}
                            {!values?.systemManaged && (
                                <TableRow>
                                    <TableCell colSpan={5} />
                                    <TableCell align="right">
                                        <IconButton
                                            disabled={
                                                isSubmitting ||
                                                !isValidFactorAddForm(addValue) ||
                                                rowEdit === 'new'
                                            }
                                            onClick={() => setRowEdit('new')}
                                        >
                                            <AddIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            )}
                        </>
                    )}
                </TableBody>
            </Table>
            <TablePagination
                count={data?.total || 0}
                rowsPerPage={limit}
                component="div"
                page={page}
                onPageChange={(_, newPage) => handlePageChange(newPage, setParams, limit)}
                rowsPerPageOptions={TABLE_ROW_COUNT_OPTIONS}
                onRowsPerPageChange={(event) => handleChangeRowsPerPage(event, setParams)}
            />
        </>
    );
};

export default EmissionFactorsTable;
