import React, { useState } from 'react';
import { Paper, Table, TableBody, TableContainer, Grid, Typography } from '@mui/material';
import rebalanceTree, { getSubTree } from '../../../helpers/rebalance';
import { doSwaggerCall } from '../../../hooks/useApi';
import useNotify from '../../../hooks/useNotify';
import BreadCrumbTitle from './BreadCrumbTitle';
import ScenarioLevelHeader from './ScenarioLevelHeader';
import ScenarioLine from './ScenarioLine';
import BarChart from './BarChart';
import DatalineScenarioLine from './DatalineScenarioLine';
import CommonButton from '../../../components/atom/CommonButton';

export function getBreadcrumbFromPath(root, path) {
    const innerPath = [...path];
    const breadcrumb = [
        {
            name: 'Global',
            locked: false,
        },
    ];
    let pointer = root;
    let anyLocked = false;
    while (innerPath.length > 0) {
        pointer = pointer.children[innerPath.shift()];
        if (pointer.locked) {
            anyLocked = true;
        }
        breadcrumb.push({
            name: pointer.name || 'Global',
            locked: pointer.locked,
        });
    }
    return { breadcrumbList: breadcrumb, explicitLocked: anyLocked };
}

let lock = false;

function ScenarioForm({
    scenario,
    setScenario,
    showDistribution,
    setReductionCost,
    setDatalineNote,
    datalineTagId,
}) {
    const root = scenario.target_scopes_hierarchy;
    const [currentPath, setCurrentPath] = useState([]);
    const [currentPathIds, setCurrentPathIds] = useState([]);
    const { notifySuccess, notifyError } = useNotify();
    const currentLevel = getSubTree({ children: root }, currentPath);
    const { breadcrumbList, explicitLocked } = getBreadcrumbFromPath(
        { children: root },
        currentPath
    );
    const sourceLevel = breadcrumbList.length === 4;
    const datalineLevel = breadcrumbList.length === 5;

    const setLocked = (path, newState) => {
        const deepCopyTree = JSON.parse(JSON.stringify({ children: root }));
        const pointer = getSubTree(deepCopyTree, path);
        pointer.locked = newState;
        setScenario({ ...scenario, target_scopes_hierarchy: deepCopyTree.children });
    };

    const setTarget = (path, newTarget) => {
        if (lock === true) {
            return scenario;
        }
        lock = true;
        const result = {
            ...scenario,
            target_scopes_hierarchy: rebalanceTree(root, path, newTarget),
        };
        setScenario(result);
        lock = false;
        return result;
    };

    const setNote = (path, newNote) => {
        const deepCopyTree = JSON.parse(JSON.stringify({ children: root }));
        const pointer = getSubTree(deepCopyTree, path);
        pointer.note = newNote;
        setScenario({ ...scenario, target_scopes_hierarchy: deepCopyTree.children });
    };

    const saveHierarchy = async () => {
        try {
            await doSwaggerCall(
                'Scenarios',
                'editScenarioHierarchy',
                { id: scenario.id },
                {
                    targetScopesHierarchy: scenario.target_scopes_hierarchy,
                }
            );
            notifySuccess('Scenario hierarchy updated');
        } catch (err) {
            notifyError(err);
        }
    };

    return (
        <>
            {!datalineLevel && showDistribution && (
                <>
                    <Grid item>
                        <Typography
                            variant="h5"
                            sx={{
                                pb: 2,
                            }}
                            style={{
                                fontWeight: 'bold',
                            }}
                        >
                            Distribution chart
                        </Typography>
                    </Grid>
                    <BarChart data={currentLevel.children} />
                </>
            )}
            <Grid
                container
                item
                xs={12}
                justifyContent="space-between"
                spacing={0}
                sx={{ pb: 2, pt: 2 }}
                style={{ alignItems: 'center' }}
            >
                <BreadCrumbTitle
                    breadcrumbList={breadcrumbList}
                    path={currentPath}
                    setCurrentPath={setCurrentPath}
                    setCurrentPathIds={setCurrentPathIds}
                    currentPathIds={currentPathIds}
                    setLocked={setLocked}
                />
                <Grid item>
                    <CommonButton
                        fullWidth
                        onClick={() => saveHierarchy()}
                        label="Save hierarchy"
                        buttonType="primary"
                    />
                </Grid>
            </Grid>
            <TableContainer component={Paper}>
                <Table>
                    <ScenarioLevelHeader sourceLevel={sourceLevel} datalineLevel={datalineLevel} />
                    <TableBody>
                        {datalineLevel
                            ? currentLevel.children.map((item, idx) => {
                                  return (
                                      <DatalineScenarioLine
                                          item={item}
                                          idx={idx}
                                          locked={item.locked || explicitLocked}
                                          setLocked={(newState) => {
                                              if (explicitLocked) {
                                                  return;
                                              }
                                              setLocked([...currentPath, idx], newState);
                                          }}
                                          setTarget={(newValue) => {
                                              const newRoot = setTarget(
                                                  [...currentPath, idx],
                                                  newValue
                                              );
                                              const subTree = getSubTree(
                                                  { children: newRoot.target_scopes_hierarchy },
                                                  [...currentPath, idx]
                                              );
                                              return subTree.target;
                                          }}
                                          targetScopesHierarchy={scenario.target_scopes_hierarchy}
                                          baseUnit={currentLevel.base_unit}
                                          setReductionCost={setReductionCost}
                                          setDatalineNote={setDatalineNote}
                                          currentPath={currentPath}
                                          datalineTagId={datalineTagId}
                                      />
                                  );
                              })
                            : currentLevel.children.map((item, idx) => {
                                  return (
                                      <ScenarioLine
                                          key={`${item.id} - ${currentPath}`}
                                          scenarioItem={item}
                                          id={item.id}
                                          indexInTree={idx}
                                          baseline={item.baseline}
                                          target={item.target}
                                          locked={item.locked || explicitLocked}
                                          name={item.name}
                                          setLocked={(newState) => {
                                              if (explicitLocked) {
                                                  return;
                                              }
                                              setLocked([...currentPath, idx], newState);
                                          }}
                                          setTarget={(newValue) => {
                                              const newRoot = setTarget(
                                                  [...currentPath, idx],
                                                  newValue
                                              );
                                              const subTree = getSubTree(
                                                  { children: newRoot.target_scopes_hierarchy },
                                                  [...currentPath, idx]
                                              );
                                              return subTree.target;
                                          }}
                                          navigateToChildren={() => {
                                              setCurrentPath((old) => [...old, idx]);
                                              setCurrentPathIds((prev) => [...prev, item.id]);
                                          }}
                                          sourceLevel={sourceLevel}
                                          baselineUsage={item.baseline_usage}
                                          baseUnit={item.base_unit}
                                          note={item.note}
                                          setNote={(newValue) => {
                                              setNote([...currentPath, idx], newValue);
                                          }}
                                          currentPath={currentPath}
                                      />
                                  );
                              })}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

export default ScenarioForm;
