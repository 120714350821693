import { IconButton, Tooltip } from '@mui/material';
import React from 'react';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

const MultipleLineSelect = ({ setSelectMode }) => {
    return (
        <Tooltip title="Select multiple lines">
            <IconButton
                onClick={() => {
                    setSelectMode(true);
                }}
            >
                <FormatListBulletedIcon />
            </IconButton>
        </Tooltip>
    );
};

export default MultipleLineSelect;
