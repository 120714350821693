import { Menu, MenuItem } from '@mui/material';
import React from 'react';
import { MODAL_TYPE, useModals } from '../../../hooks/useModal';
import { AVAILABLE_OPTIONS_FOR_DATALINES } from '../../../helpers/constans';

const setTitle = (value) => {
    if (value === 'setEstimated') {
        return 'set estimated value';
    }
    if (value === 'addNote') {
        return 'add notes';
    }
    return 'assign tags';
};

const setContent = (value) => {
    if (value === 'setEstimated') {
        return 'New assignes override previous.';
    }
    if (value === 'addNote') {
        return 'New notes override previous.';
    }
    return null;
};

const TableSelectDropdown = ({
    open,
    anchorEl,
    onClose,
    reloadData,
    selectedDatalines,
    deleteDatalinesList,
    uploadEventHandler,
    setSelectedDatalines,
    permissions,
}) => {
    const { showModal } = useModals();

    const {
        basicModifyDatalinePermission,
        complexModifyDatalinePermission,
        manageDatalinePermission,
    } = permissions;

    // filter options based on user permissions
    const filteredAvailableOptions = AVAILABLE_OPTIONS_FOR_DATALINES.filter((option) => {
        switch (option.value) {
            case 'setOwner':
                return complexModifyDatalinePermission;
            case 'setAssignee':
                return complexModifyDatalinePermission;
            case 'setEstimated':
                return basicModifyDatalinePermission || complexModifyDatalinePermission;
            case 'addNote':
                return basicModifyDatalinePermission || complexModifyDatalinePermission;
            case 'assignTags':
                return basicModifyDatalinePermission || complexModifyDatalinePermission;
            case 'deleteDatalines':
                return manageDatalinePermission;
            default:
                return false;
        }
    });

    function menuClickHandler(value) {
        // always close menu when clicking
        onClose();
        switch (value) {
            case 'setOwner':
            case 'setAssignee':
                showModal(MODAL_TYPE.ASSIGN_MODAL, {
                    title: `Are you sure you want to assign datalines ${
                        value === 'setOwner' ? '(Owner)?' : '(Assigned user)?'
                    }`,
                    content: `New assignes override previous.`,
                    helperText: `If you want to unassign save without selecting a user.`,
                    reloadData,
                    selectedLines: selectedDatalines.map((e) => e.id),
                    setSelectedLines: setSelectedDatalines,
                    label: value === 'setOwner' ? 'Owner' : 'Assigned user',
                });
                break;
            case 'setEstimated':
            case 'addNote':
            case 'assignTags':
                showModal(MODAL_TYPE.BULK_EDIT_DATALINES, {
                    title: `Are you sure you want to ${setTitle(value)} to selected datalines?`,
                    content: setContent(value),
                    selectedLines: selectedDatalines.map((e) => e.id),
                    setSelectedLines: setSelectedDatalines,
                    reloadData,
                    type: value,
                });
                break;
            case 'deleteDatalines':
                deleteDatalinesList();
                break;
            default:
                // do nothing
                console.log('default');
                break;
        }
    }

    return (
        <Menu
            id="dropdown-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            aria-haspopup="true"
        >
            {(basicModifyDatalinePermission || complexModifyDatalinePermission) && (
                <MenuItem
                    key="uploadDocuments"
                    value="Upload Documents"
                    disabled={selectedDatalines.length === 0}
                >
                    <input
                        style={{
                            opacity: 0,
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                        }}
                        type="file"
                        onChange={(event) => {
                            onClose();
                            uploadEventHandler(event).catch(console.log);
                        }}
                    />
                    Upload Documents
                </MenuItem>
            )}
            {filteredAvailableOptions.map((option) => (
                <MenuItem
                    key={option.value}
                    value={option.value}
                    onClick={() => menuClickHandler(option.value)}
                    disabled={selectedDatalines.length === 0}
                >
                    {option.label}
                </MenuItem>
            ))}
        </Menu>
    );
};

export default TableSelectDropdown;
