import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';
import { useGlobalFilters } from '../../hooks/useGlobalFilters';
import { MODAL_TYPE, useModals } from '../../hooks/useModal';
import { useEsgFilters } from '../../hooks/useEsgFilters';
import { useEsgFrameworkFilters } from '../../hooks/useEsgFrameworkFilters';

const BreadcrumbComponent = ({ value, label, onClick }) => {
    return (
        <Grid item onClick={onClick} sx={{ minWidth: '125px' }}>
            <FormControl fullWidth>
                <InputLabel id="source-select">{label}</InputLabel>
                <Select
                    fullWidth
                    labelId="source-select"
                    value={value}
                    size="small"
                    label={label}
                    open={false}
                >
                    <MenuItem value={value}>{value}</MenuItem>
                </Select>
            </FormControl>
        </Grid>
    );
};

const BreadcrumbFilters = ({ isEsg }) => {
    const { scopeName, categoryName, subcategoryName, emissionSourceName } = useGlobalFilters();
    const { esgCategoryName, esgSubcategoryName, esgDisclosureName, esgQuestionName } =
        useEsgFilters();
    const { esgFrameworkName } = useEsgFrameworkFilters();

    const { showModal } = useModals();

    let valueText = [
        !scopeName ? 'Show everything' : scopeName,
        categoryName,
        subcategoryName,
        emissionSourceName,
    ]
        .filter(Boolean)
        .join(' > ');

    let esgValueText = [
        !esgCategoryName ? 'Show everything' : esgCategoryName,
        esgSubcategoryName,
        esgDisclosureName,
        esgQuestionName,
    ]
        .filter(Boolean)
        .join(' > ');

    let esgFrameworkValueText = !esgFrameworkName ? 'Show everything' : esgFrameworkName;

    valueText =
        valueText.length > 100
            ? `...${valueText.substring(valueText.length - 100, valueText.length)}`
            : valueText;

    esgValueText =
        esgValueText.length > 100
            ? `...${esgValueText.substring(esgValueText.length - 100, esgValueText.length)}`
            : esgValueText;

    esgFrameworkValueText =
        esgFrameworkValueText.length > 50
            ? `...${esgFrameworkValueText.substring(
                  esgFrameworkValueText.length - 50,
                  esgFrameworkValueText.length
              )}`
            : esgFrameworkValueText;

    return isEsg ? (
        <>
            <BreadcrumbComponent
                value={esgFrameworkValueText}
                label="Esg framework filter"
                onClick={() => {
                    showModal(MODAL_TYPE.ESG_FRAMEWORK_GLOBAL_FILTER, {
                        title: 'Esg framework filter',
                    });
                }}
            />
            <BreadcrumbComponent
                value={esgValueText}
                label="Esg filter"
                onClick={() => {
                    showModal(MODAL_TYPE.ESG_GLOBAL_FILTER, {
                        title: 'Esg filter',
                    });
                }}
            />
        </>
    ) : (
        <BreadcrumbComponent
            value={valueText}
            label="Source filter"
            onClick={() => {
                showModal(MODAL_TYPE.CATEGORY_FILTER, {
                    title: 'Filter',
                });
            }}
        />
    );
};

export default BreadcrumbFilters;
