import React from 'react';
import { Typography } from '@mui/material';

const SurveyQuestionSubheader = ({ subheader }) => {
    return (
        <Typography
            sx={{
                fontWeight: '300',
                fontSize: '85%',
            }}
        >
            {subheader}
        </Typography>
    );
};

export default SurveyQuestionSubheader;
