import React, { useEffect, useState } from 'react';
import { Grid, LinearProgress } from '@mui/material';
import useNotify from '../../../hooks/useNotify';
import { doSwaggerCall } from '../../../hooks/useApi';
import ScopeOrMetricLineChart from './charts/ScopeOrMetricLineChart';

const BySelectedScopesPerIntensity = ({
    scopes,
    years,
    intensityMetric,
    aggregated,
    setChartData,
}) => {
    const { notifyError } = useNotify();
    const [scopesLineData, setScopesLineData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        doSwaggerCall('Dashboards', 'getDataForScopesPerIntensityLineChart', {
            scopes,
            years,
            intensityMetric,
            aggregated,
        })
            .then((res) => {
                setScopesLineData(res);
                setChartData(res.scopePerIntensityData);
                setLoading(false);
            })
            .catch((error) => {
                notifyError(error);
            });
    }, [scopes, years, intensityMetric, aggregated, setLoading, setScopesLineData, setChartData]);

    return (
        <Grid item xs={12}>
            {scopesLineData?.length === 0 ? (
                <Grid item>
                    {loading ? <LinearProgress sx={{ mt: 0.25 }} /> : <h5>No data available</h5>}
                </Grid>
            ) : (
                <ScopeOrMetricLineChart
                    data={scopesLineData.scopePerIntensityData}
                    name={scopesLineData.name}
                />
            )}
        </Grid>
    );
};

export default BySelectedScopesPerIntensity;
