import { Grid, InputAdornment } from '@mui/material';
import React from 'react';
import SurveyQuestionHeadline from '../components/SurveyQuestionHeadline';
import {
    withFormikContext,
    withStepAndValidation,
    withSurveySection,
} from '../components/SurveyStep';
import FloatInput from '../../../components/atom/FloatInput';

function QReportPerScopeNumbers({ values, setFieldValue, companyName, year }) {
    return (
        <>
            <Grid item xs={12}>
                <SurveyQuestionHeadline>
                    Please enter a per scope breakdown of the CO2e (mt) of products/services sold to{' '}
                    {companyName} in {year}.
                </SurveyQuestionHeadline>
            </Grid>
            <Grid item xs={12}>
                <FloatInput
                    fullWidth
                    required
                    type="number"
                    label="Scope 1 sum CO2e (mt)"
                    value={values?.details?.scope1Co2e}
                    onChange={(ev) => {
                        setFieldValue('details.scope1Co2e', ev.target.rawValue);
                    }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">CO2e (mt)</InputAdornment>,
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <FloatInput
                    fullWidth
                    required
                    type="number"
                    label="Scope 2 sum CO2e (mt)"
                    value={values?.details?.scope2Co2e}
                    onChange={(ev) => {
                        setFieldValue('details.scope2Co2e', ev.target.rawValue);
                    }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">CO2e (mt)</InputAdornment>,
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <FloatInput
                    fullWidth
                    type="number"
                    label="Scope 3 sum CO2e (mt)"
                    value={values?.details?.scope3Co2e}
                    onChange={(ev) => {
                        setFieldValue('details.scope3Co2e', ev.target.rawValue);
                    }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">CO2e (mt)</InputAdornment>,
                    }}
                />
            </Grid>
        </>
    );
}

export default withStepAndValidation(
    withSurveySection(withFormikContext(QReportPerScopeNumbers)),
    3,
    ['details.scope1Co2e', 'details.scope2Co2e', 'details.scope3Co2e'],
    // validation
    (values, errors) => {
        const total = parseFloat(values?.details?.totalCo2e);
        const scope1 = parseFloat(values?.details?.scope1Co2e);
        const scope2 = parseFloat(values?.details?.scope2Co2e);
        const scope3 = parseFloat(values?.details?.scope3Co2e);
        if (total !== scope1 + scope2 + scope3) {
            // eslint-disable-next-line no-param-reassign
            errors['details.scope1Co2e'] = 'Total CO2e (mt) is not equal to the sum of scopes!';
        }

        return errors;
    }, // always active
    (values) =>
        values?.details?.haveExactData === true && values?.details?.havePerScopeData === true
);
