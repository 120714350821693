import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import CustomTreeItem from '../../../../components/atom/CustomTreeItem';

function SourcePickerSourceLevel({
    checkedData,
    scope,
    category,
    subcategory,
    source,
    setCheckedData,
}) {
    return (
        <CustomTreeItem
            nodeId={`source - ${source.id}`}
            label={
                <FormControlLabel
                    label={source.name}
                    labelPlacement="end"
                    control={
                        <Checkbox
                            checked={
                                !!checkedData.find(
                                    (c) => c.id === source.id && c.type === 'source'
                                ) ||
                                // if subcategory is checked each source is selected
                                !!checkedData.find(
                                    (c) => c.id === subcategory.id && c.type === 'subcategory'
                                ) ||
                                // if category is checked each source is selected
                                !!checkedData.find(
                                    (c) => c.id === category.id && c.type === 'category'
                                ) ||
                                // if scope is checked each source is selected
                                !!checkedData.find((c) => c.id === scope.id && c.type === 'scope')
                            }
                            // disable if one of the parent is selected
                            disabled={
                                !!checkedData.find(
                                    (c) => c.id === scope.id && c.type === 'scope'
                                ) ||
                                !!checkedData.find(
                                    (c) => c.id === category.id && c.type === 'category'
                                ) ||
                                !!checkedData.find(
                                    (c) => c.id === subcategory.id && c.type === 'subcategory'
                                )
                            }
                            onChange={() => {
                                setCheckedData((old) => {
                                    const findCurrent = old.find(
                                        (item) => item.id === source.id && item.type === 'source'
                                    );
                                    if (findCurrent) {
                                        return old.filter((data) => data !== findCurrent);
                                    }
                                    return [
                                        ...old,
                                        {
                                            type: 'source',
                                            id: source.id,
                                        },
                                    ];
                                });
                            }}
                        />
                    }
                />
            }
        />
    );
}

export default SourcePickerSourceLevel;
