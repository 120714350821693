import React, { useCallback, useEffect, useState } from 'react';
import { Box, Chip, CircularProgress } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

// Unlimited autocomplete select
const OPTION_LIMIT = 100000;

const filterOptions = createFilterOptions({
    matchFrom: 'any',
    limit: OPTION_LIMIT,
    stringify: (option) => {
        return JSON.stringify(option);
    },
});

/**
 * Check if a value is an object with id key exists or not
 * @param e
 * @returns {boolean}
 */
function isValueObjectWithId(e) {
    return typeof e === 'object' && e !== null && typeof e.id !== 'undefined';
}

/**
 * Map autocomplete values to ids
 * @param oneValue
 * @returns {*}
 */
function mapValueToId(oneValue) {
    return isValueObjectWithId(oneValue) ? oneValue.id : oneValue;
}

/**
 * Merge options + optionsCache with one list, where the ids are unique
 * @param options
 * @param optionsCache
 * @returns {*[]}
 */
function createUniqueOptionsFromOptionsAndCache(options, optionsCache) {
    // unique the options
    const uniqueIds = [];
    const uniqueOptions = [];
    options.forEach((e) => {
        if (uniqueIds.includes(e.id)) {
            return;
        }
        uniqueIds.push(e.id);
        uniqueOptions.push(e);
    });
    if (optionsCache !== null) {
        optionsCache.forEach((e) => {
            if (!e || uniqueIds.includes(e.id)) {
                return;
            }
            uniqueIds.push(e.id);
            uniqueOptions.push(e);
        });
    }
    return uniqueOptions;
}

/**
 * AutocompleteSelect for general use
 * @param value a list of IDs (or a single id)
 * @param setValue
 * @param autocompleteCall async function with (search, limit option)
 * @param initCall array or async function with list of ids
 * @param children
 * @param multiple true if multiple items can be selected
 * @param autocompleteProps
 * @returns {JSX.Element|null}
 * @constructor
 */
const AutocompleteSelect = ({
    value,
    setValue,
    autocompleteCall,
    initCall,
    children,
    multiple = false,
    forceInputProps = {},
    searchParams = false,
    grouping = false,
    ...autocompleteProps
}) => {
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState([]);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    // if initCall is array, use it as soon as we can
    const [optionsCache, setOptionsCache] = useState(Array.isArray(initCall) ? initCall : null);
    const [initCallDidRun, setInitCallDidRun] = useState(Array.isArray(initCall));

    // make sure we have initcall + autocomplete call
    if (!autocompleteCall) {
        console.error('You must specify an autocompleteCall for AutocompleteSelect');
    }
    if (!initCall) {
        console.error(
            'You must specify an initCall for AutocompleteSelect or provide a predefined list!'
        );
    }

    useEffect(() => {
        if (optionsCache && optionsCache.find((e) => `${e?.id}` === `${value}`)) {
            return;
        }
        setOptionsCache(null);

        // no need to resolve nothing
        if (multiple && Array.isArray(value) && value.length === 0) {
            return;
        }

        if (value === null || typeof value === 'undefined') {
            return;
        }

        // even if the call is sync, let's make it a promise
        Promise.resolve(initCall(multiple ? value : [value], searchParams || {}))
            .then(setOptionsCache)
            .catch(console.error);
    }, [value, setOptionsCache]);

    // on initial load try to resolve the already selected options
    const cacheLoaded = optionsCache !== null;
    useEffect(() => {
        // if we already have something in the cache, do not load it
        if (cacheLoaded || Array.isArray(initCall)) {
            return;
        }

        // no need to resolve nothing
        if (multiple && Array.isArray(value) && value.length === 0) {
            setInitCallDidRun(true);
            return;
        }

        if (value === null || typeof value === 'undefined') {
            setInitCallDidRun(true);
            return;
        }

        // even if the call is sync, let's make it a promise
        Promise.resolve(initCall(multiple ? value : [value], searchParams || {}))
            .then(setOptionsCache)
            .then(() => setInitCallDidRun(true))
            .catch(console.error);
    }, [cacheLoaded, initCall, setOptionsCache, setInitCallDidRun]);

    // on input change, make the search call
    useEffect(() => {
        // I know, we should not search for an empty string,
        // but it will load some values into the dropdown
        // uncomment if you think otherwise
        // if (inputValue===''){ return; }

        let stillValidCall = true;
        // even if the call is sync, let's make it a promise
        setLoading(true);
        Promise.resolve(autocompleteCall(inputValue, OPTION_LIMIT, searchParams || {}))
            .then((val) => {
                if (!stillValidCall) {
                    // drop the response
                    // console.log('Late response, not valid');
                    return;
                }
                setLoading(false);
                setOptions(val);
            })
            .catch(console.log);

        return () => {
            stillValidCall = false;
        };
    }, [inputValue, setOptions, JSON.stringify(searchParams), value]);

    // handle changes in the list of items (with cache fill up)
    const onChange = useCallback(
        (event, newValue) => {
            // define a temporary cache to provide it as helper function
            // since it might get updated in the next render cycle
            let newOptionCache = [];

            // from newValue create id list (or single id) + update option cache
            let mappedIds = null;
            if (!multiple) {
                mappedIds = mapValueToId(newValue);
                newOptionCache = isValueObjectWithId(newValue) ? [newValue] : [];
            } else {
                mappedIds = newValue.map(mapValueToId);
                newOptionCache = newValue
                    .map((e) => {
                        if (isValueObjectWithId(e)) {
                            return e;
                        }
                        if (optionsCache !== null) {
                            return optionsCache.find(
                                (c) => c && `${c.id}` === `${mapValueToId(e)}`
                            );
                        }
                        return null;
                    })
                    .filter((e) => e !== null);
            }
            setOptionsCache(newOptionCache);
            setValue(
                mappedIds,
                // helper function passed down as the 2nd parameter in setValue
                (id) => {
                    // as newOptionCache isn't coming from setOptionsCache, this can run before setOptionsCache resolves
                    const item = newOptionCache.find((e) => `${e.id}` === `${id}`);
                    return item;
                }
            );
        },
        [setOptionsCache, setValue, optionsCache]
    );

    if (!initCallDidRun) {
        // during the initial load we need to have something visible to the user, same width/height as the input
        // this prevents the input from jumping around
        return (
            <div className="MuiAutocomplete-root MuiAutocomplete-hasClearIcon MuiAutocomplete-hasPopupIcon">
                {React.cloneElement(children, {
                    placeholder: '',
                    disabled: true,
                    InputProps: {
                        endAdornment: (
                            <Box style={{ padding: '3.5px', display: 'inline-block' }}>
                                <CircularProgress color="inherit" size={15} />
                            </Box>
                        ),
                    },
                })}
            </div>
        );
    }

    const getOptionLabel = (option) => {
        // option can be an object that we did find
        if (typeof option === 'object') {
            return `${option.name}`;
        }

        // before the optionsCache is loaded, we can avoid displaying the id
        if (optionsCache === null) {
            return 'Loading...';
        }

        // can be just the id, on initial load, when we have some id as input, but cannot resolve from optionsCache
        const item = optionsCache && optionsCache.find((e) => `${e.id}` === `${option}`);
        if (item) {
            return item.name;
        }
        // or we are fighting a race condition during the first render
        if (Array.isArray(initCall) && !optionsCache) {
            const initItem = initCall.find((e) => `${e.id}` === `${option}`);
            if (initItem) {
                return initItem.name;
            }
        }
        // or something else...
        return `${option}`;
    };

    let autocompleteOptions = createUniqueOptionsFromOptionsAndCache(options, optionsCache);
    if (grouping) {
        autocompleteOptions = createUniqueOptionsFromOptionsAndCache(options, optionsCache).sort(
            (a, b) => grouping(a).localeCompare(grouping(b))
        );
    }

    return (
        <Autocomplete
            options={autocompleteOptions}
            getOptionLabel={getOptionLabel}
            filterOptions={filterOptions}
            value={value}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            isOptionEqualToValue={(option, currentValue) => `${option.id}` === `${currentValue}`}
            onChange={onChange}
            renderTags={(tags, getTagProps) =>
                tags.map((option, index) => {
                    // items should be in the cache after initial load
                    const itemInOptions =
                        optionsCache !== null &&
                        optionsCache.find((e) => e && `${e.id}` === `${option}`);
                    return (
                        <Chip
                            label={
                                itemInOptions ? (
                                    itemInOptions.name
                                ) : (
                                    <CircularProgress color="inherit" size={15} />
                                )
                            }
                            {...getTagProps({ index })}
                        />
                    );
                })
            }
            renderOption={(props, option, state) => {
                return (
                    <li {...props} key={`${props.key}${props.id}`}>
                        {getOptionLabel(option)}
                    </li>
                );
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            renderInput={(params) => {
                return React.cloneElement(children, {
                    ...params,
                    ...forceInputProps,
                    placeholder: 'Start typing to search...',
                    InputProps: {
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress color="inherit" size={15} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    },
                });
            }}
            // keep this one here so everything can be overwritten by props
            groupBy={grouping}
            {...autocompleteProps}
            multiple={multiple}
        />
    );
};

export default AutocompleteSelect;
