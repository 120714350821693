import { Grid } from '@mui/material';
import React from 'react';
import SurveyQuestionHeadline from '../components/SurveyQuestionHeadline';
import { SURVEY_BUTTON_TYPES, SurveyButton } from '../components/SurveyButton';
import {
    withFormikContext,
    withStepAndValidation,
    withSurveySection,
} from '../components/SurveyStep';

function QNetzeroTarget({ values, setFieldValue }) {
    return (
        <>
            <Grid item xs={12}>
                <SurveyQuestionHeadline>
                    Has your company set a Net Zero target?
                </SurveyQuestionHeadline>
            </Grid>
            <Grid item xs={6}>
                <SurveyButton
                    type={SURVEY_BUTTON_TYPES.YES}
                    active={values.netzero === true}
                    onClick={() => {
                        setFieldValue('netzero', true);
                    }}
                >
                    Yes
                </SurveyButton>
            </Grid>
            <Grid item xs={6}>
                <SurveyButton
                    type={SURVEY_BUTTON_TYPES.NO}
                    active={values.netzero === false}
                    onClick={() => {
                        setFieldValue('netzero', false);
                    }}
                >
                    No
                </SurveyButton>
            </Grid>
        </>
    );
}

export default withStepAndValidation(
    withSurveySection(withFormikContext(QNetzeroTarget)),
    2,
    ['netzero'],
    (values, errors) => {
        if (typeof values.netzero === 'undefined') {
            // eslint-disable-next-line no-param-reassign
            errors.netzero = 'Please select an option.';
        }
        return errors;
    }
    // always active, no need to set that check
);
