import { Grid, Typography } from '@mui/material';
import React from 'react';
import { useDarkMode } from '../../../hooks/useDarkMode';

function SurveyHeader({ companyName, year, impFlag = false }) {
    const { darkMode } = useDarkMode();
    const surveyTitle = impFlag
        ? `${companyName} Inventory Management Plan`
        : `${companyName} Sustainability Survey`;
    const surveyType = impFlag ? 'IMP' : 'Survey';

    return (
        <Grid
            container
            item
            xs={12}
            justifyContent="space-between"
            alignItems="center"
            spacing={0}
            sx={{ pb: 2 }}
        >
            <Typography
                variant="caption"
                sx={{
                    fontStyle: 'italic',
                    color: darkMode ? '#ffffff' : '#1E96FC',
                }}
            >
                Please complete the {surveyType}
            </Typography>
            <Typography variant="h4">{`${surveyTitle} - ${year}`}</Typography>
        </Grid>
    );
}

export default SurveyHeader;
