import React, { useEffect, useState } from 'react';
import { Grid, LinearProgress } from '@mui/material';
import useNotify from '../../../hooks/useNotify';
import { doSwaggerCall } from '../../../hooks/useApi';
import ScenarioLineChart from './charts/ScenarioLineChart';

const BySelectedScenarios = ({ scenarios, setChartData }) => {
    const { notifyError } = useNotify();
    const [scenarioLineData, setScenarioLineData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        doSwaggerCall('Dashboards', 'getDataForScenariosLineChart', {
            scenarioIdList: scenarios,
        })
            .then((res) => {
                setScenarioLineData(res);
                setChartData(res.scenarioData);
                setLoading(false);
            })
            .catch((error) => {
                notifyError(error);
            });
    }, [scenarios, setLoading, setScenarioLineData, setChartData]);

    return (
        <Grid item xs={12}>
            {scenarioLineData?.length === 0 ? (
                <Grid item>
                    {loading ? <LinearProgress sx={{ mt: 0.25 }} /> : <h5>No data available</h5>}
                </Grid>
            ) : (
                <ScenarioLineChart data={scenarioLineData.scenarioData} />
            )}
        </Grid>
    );
};

export default BySelectedScenarios;
