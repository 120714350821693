import { Alert, Menu, MenuItem } from '@mui/material';
import React from 'react';
import { MODAL_TYPE, useModals } from '../../../hooks/useModal';

const ActionsDropdown = ({
    open,
    anchorEl,
    setAnchorEl,
    onClose,
    hideExportButtons,
    onPanelUpdate,
    onPanelDelete,
    exortChartToPng,
    exortChartToSvg,
    exortChartToXlsx,
    panel,
    panelId,
    readOnly,
}) => {
    const { showModal } = useModals();
    const isChrome = navigator.userAgent.indexOf('Chrome') > -1;
    const id = open ? 'simple-popover' : undefined;
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Menu
            id="dropdown-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            aria-haspopup="true"
        >
            {!hideExportButtons && (
                <MenuItem
                    key={`${panelId}-png`}
                    value="png"
                    onClick={() => {
                        handleClose();
                        exortChartToPng(panel.name);
                    }}
                    aria-describedby={id}
                >
                    Export chart to png
                </MenuItem>
            )}
            {!hideExportButtons && (
                <MenuItem
                    key={`${panelId}-svg`}
                    value="svg"
                    onClick={() => {
                        handleClose();
                        exortChartToSvg(panel.name);
                    }}
                >
                    Export chart to svg
                </MenuItem>
            )}
            {!readOnly && (
                <MenuItem
                    key={`${panelId}-edit`}
                    value="edit"
                    onClick={() => {
                        handleClose();
                        showModal(MODAL_TYPE.ADD_DASHBOARD_PANEL, {
                            confirm: (updatePanel, updatePanelId) =>
                                onPanelUpdate(updatePanel, updatePanelId),
                            panel,
                            panelId,
                        });
                    }}
                >
                    Edit panel
                </MenuItem>
            )}
            {!readOnly && (
                <MenuItem
                    key={`${panelId}-delete`}
                    value="delete"
                    onClick={() => {
                        handleClose();
                        onPanelDelete(panelId);
                    }}
                >
                    Delete panel
                </MenuItem>
            )}
            {!hideExportButtons && (
                <MenuItem
                    key={`${panelId}-export`}
                    value="export"
                    onClick={() => {
                        handleClose();
                        exortChartToXlsx();
                    }}
                >
                    Export panel data to xlsx
                </MenuItem>
            )}
            {!isChrome && (
                <MenuItem key="chrome" disabled style={{ opacity: 1, padding: '0 8px', margin: 0 }}>
                    <Alert sx={{ mt: 0.5 }} severity="warning">
                        For optimal quality, we recommend using Chrome.
                    </Alert>
                </MenuItem>
            )}
        </Menu>
    );
};

export default ActionsDropdown;
