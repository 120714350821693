import React from 'react';
import { Skeleton, Grid } from '@mui/material';

const SkeletonForm = ({ rows }) => {
    const skeletonRows = Array(rows).fill('');

    return skeletonRows.map((_, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <Grid item xs={12} key={idx}>
            <Skeleton animation="pulse" />
        </Grid>
    ));
};

export default SkeletonForm;
