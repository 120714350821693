import {
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip,
} from '@mui/material';
import React, { useCallback } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import SecurityIcon from '@mui/icons-material/Security';
import SendIcon from '@mui/icons-material/Send';
import SkeletonTable from '../../../components/atom/SkeletonTable';
import { MODAL_TYPE, useModals } from '../../../hooks/useModal';
import { doSwaggerCall } from '../../../hooks/useApi';
import useNotify from '../../../hooks/useNotify';
import SortableTableCell from '../../../components/atom/SortableTableCell';
import { useAuth } from '../../../hooks/useAuth';
import { TABLE_ROW_COUNT_OPTIONS } from '../../../helpers/constans';
import { handleChangeRowsPerPage, handlePageChange } from '../../../helpers/handleTableFunctions';

const UsersTable = ({
    rows,
    loading,
    limit,
    setParams,
    total,
    page,
    reloadData,
    companyId,
    sort,
    sortDir,
    setSorting,
}) => {
    const { showModal } = useModals();
    const { notifyError, notifySuccess } = useNotify();
    const { user, setUser } = useAuth();

    const deleteUser = useCallback((id) => {
        doSwaggerCall('Users', 'deleteUserFromCompany', { id, companyId })
            .then(() => {
                notifySuccess('User removed from company');
                reloadData();
            })
            .catch((err) => {
                notifyError(err);
            });
    }, []);

    const resetTwoFactorAuth = useCallback((id) => {
        doSwaggerCall('Auth', 'resetTwoFactorAuth', { userId: id })
            .then(() => {
                notifySuccess('Two factor auth reseted.');
                reloadData();
            })
            .catch((err) => {
                notifyError(err);
            });
    }, []);

    const sendPasswordResetEmail = useCallback((email) => {
        doSwaggerCall('Auth', 'resetPasswordRequest', {}, { email })
            .then(() => {
                notifySuccess('Password reset email sent.');
            })
            .catch((err) => {
                notifyError(err);
            });
    }, []);

    const checkAuth = (row) => {
        return row.hasTwoFactorAuth === true ? (
            <Tooltip title="Active">
                <IconButton
                    onClick={() => {
                        showModal(MODAL_TYPE.CONFIRMATION, {
                            title: 'Are you sure you want to reset two factor authentication?',
                            content: 'This action is irreversible.',
                            confirm: () => resetTwoFactorAuth(row.id),
                        });
                    }}
                >
                    <SecurityIcon />
                </IconButton>
            </Tooltip>
        ) : (
            <Tooltip title="Inactive">
                <IconButton disabled>
                    <CloseIcon />
                </IconButton>
            </Tooltip>
        );
    };

    const changeRole = async (featureSet, userId) => {
        try {
            await doSwaggerCall(
                'Users',
                'editUser',
                { id: userId, companyId },
                {
                    featureSet,
                }
            );
            notifySuccess('User role changed!');
            reloadData();
        } catch (err) {
            notifyError(err);
        }
        if (
            parseInt(userId, 10) === parseInt(user.id, 10) &&
            parseInt(companyId, 10) === parseInt(user.company_id, 10)
        ) {
            // to refresh permissions
            setUser(user);
        }
    };

    return (
        <Grid item xs={12}>
            <TableContainer component={Paper}>
                <Table aria-label="custom table">
                    <TableHead>
                        <TableRow>
                            <SortableTableCell
                                arrow={sort === 'username' ? sortDir : ''}
                                onClick={() => setSorting('username')}
                            >
                                Name
                            </SortableTableCell>
                            <SortableTableCell
                                arrow={sort === 'email' ? sortDir : ''}
                                onClick={() => setSorting('email')}
                            >
                                Email
                            </SortableTableCell>
                            <SortableTableCell>MFA</SortableTableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <SkeletonTable rows={limit} cells={4} />
                        ) : (
                            rows?.map((row) => (
                                <TableRow>
                                    <TableCell>{row.name}</TableCell>
                                    <TableCell>{row.email}</TableCell>
                                    <TableCell>{checkAuth(row)}</TableCell>
                                    <TableCell align="right" width="10%">
                                        <IconButton
                                            onClick={() => {
                                                showModal(MODAL_TYPE.CHANGE_ROLE, {
                                                    title: `Are you sure you want to change ${row.name} role?`,
                                                    content:
                                                        'You can select predefined role or just select custom permissions.',
                                                    userFeatureSet: row.feature_set,
                                                    userRole: row.role,
                                                    confirm: (featureSet) =>
                                                        changeRole(featureSet, row.id),
                                                });
                                            }}
                                        >
                                            <Tooltip title="Edit">
                                                <EditIcon />
                                            </Tooltip>
                                        </IconButton>
                                        <IconButton
                                            onClick={() => {
                                                showModal(MODAL_TYPE.CONFIRMATION, {
                                                    title: `Are you sure you want send a password reset email to ${row.name}?`,
                                                    confirm: () =>
                                                        sendPasswordResetEmail(row.email),
                                                });
                                            }}
                                        >
                                            <Tooltip title="Send password reset email">
                                                <SendIcon />
                                            </Tooltip>
                                        </IconButton>
                                        <IconButton
                                            onClick={() => {
                                                showModal(MODAL_TYPE.CONFIRMATION, {
                                                    title: `Are you sure you want to remove the user from this company?`,
                                                    content: 'This action is irreversible.',
                                                    confirm: () => deleteUser(row.id),
                                                });
                                            }}
                                        >
                                            <Tooltip title="Delete">
                                                <DeleteIcon />
                                            </Tooltip>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                count={total || 0}
                rowsPerPage={limit}
                component="div"
                page={page}
                onPageChange={(_, newPage) => handlePageChange(newPage, setParams, limit)}
                rowsPerPageOptions={TABLE_ROW_COUNT_OPTIONS}
                onRowsPerPageChange={(event) => handleChangeRowsPerPage(event, setParams)}
            />
        </Grid>
    );
};

export default UsersTable;
