import {
    Grid,
    IconButton,
    InputAdornment,
    Slider,
    TableCell,
    TableRow,
    Tooltip,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import WarningIcon from '@mui/icons-material/Warning';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { LockOpen as LockOpenIcon, Lock as LockIcon } from '@mui/icons-material';
import { formatMoney, formatNumber } from '../../../helpers/convertUnits';
import DatalineCalculationSimulator from './DatalineCalculationSimulator';
import FloatInput from '../../../components/atom/FloatInput';
import CostAndNoteLine from './CostAndNoteLine';

const DatalineScenarioLine = ({
    item,
    idx,
    locked,
    setLocked,
    setTarget,
    targetScopesHierarchy,
    baseUnit,
    setReductionCost,
    setDatalineNote,
    currentPath,
    datalineTagId,
}) => {
    const [showSimulator, setShowSimulator] = useState(false);
    // Percentages are calculated here, since we only show this for the user.
    // Please keep it here, do not try to move it out from here :D
    const [percentage, setPercentage] = useState(() => {
        if (item.baseline === 0) {
            return 0;
        }
        return (item.target / item.baseline) * 100;
    });
    const [percentageSliderIntermediateValue, setPercentageSliderIntermediateValue] =
        useState(percentage);

    const [cost, setCost] = useState(item?.cost !== null ? (item.cost * percentage) / 100 : null);

    function setPercentageSliderIntermediateValueFromTarget(newTarget) {
        if (item.baseline === 0) {
            return setPercentageSliderIntermediateValue(0);
        }
        setPercentageSliderIntermediateValue((newTarget / item.baseline) * 100);
    }
    // on the target / baseline change, we should recalculate the percentage
    useEffect(() => {
        if (item.baseline === 0) {
            setPercentage(0);
            setPercentageSliderIntermediateValue(0);
            return;
        }
        setPercentageSliderIntermediateValue((item.target / item.baseline) * 100);
        return setPercentage((item.target / item.baseline) * 100);
    }, [item.target, item.baseline, setPercentage, setPercentageSliderIntermediateValue]);

    const targetUsage = (item.amount / item.baseline) * item.target || 0;
    return (
        <React.Fragment key={item.id}>
            <TableRow className="removeBorderBottom">
                <TableCell>
                    <Grid alignItems="center" sx={{ display: 'flex' }}>
                        <IconButton
                            onClick={() => {
                                setLocked(!locked);
                            }}
                            title={locked ? 'locked' : 'unlocked'}
                        >
                            {locked ? <LockIcon /> : <LockOpenIcon />}
                        </IconButton>
                        {item.incorrect === true && <WarningIcon sx={{ color: 'red', mr: 1 }} />}
                        {item.emission_description}
                        {100 - percentage < 0 && (
                            <Tooltip title="Reduction percentage is negative" placement="left">
                                <IconButton disableRipple>
                                    <WarningIcon sx={{ color: 'red' }} />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Grid>
                </TableCell>
                <TableCell>{item.site_name}</TableCell>
                <TableCell>
                    {`${formatNumber(item.amount)} ${baseUnit}`}
                    <br />
                    <small>
                        {formatNumber(item.baseline)} CO<sub>2</sub>e (mt)
                    </small>
                </TableCell>
                <TableCell>
                    {`${formatNumber(targetUsage)} ${baseUnit}`}
                    <br />
                    <small>
                        {formatNumber(item.target)} CO<sub>2</sub>e (mt)
                    </small>
                </TableCell>
                <TableCell>{typeof cost === 'number' ? formatMoney(cost) : null}</TableCell>
                <TableCell width="10%">
                    <FloatInput
                        fullWidth
                        label="Reduction"
                        type="number"
                        value={Math.round((100 - percentageSliderIntermediateValue) * 10) / 10}
                        positiveOnly={false}
                        onChange={(ev) => {
                            if (Number.isNaN(parseFloat(ev.target.rawValue))) {
                                return;
                            }
                            setPercentageSliderIntermediateValue(
                                100 - parseFloat(ev.target.rawValue)
                            );
                        }}
                        onBlur={() => {
                            const newTarget = setTarget(
                                (item.baseline * percentageSliderIntermediateValue) / 100
                            );
                            setCost(
                                item.cost === null
                                    ? null
                                    : (item.cost * percentageSliderIntermediateValue) / 100
                            );
                            setPercentageSliderIntermediateValueFromTarget(newTarget);
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            disabled: locked,
                        }}
                    />
                </TableCell>
                <TableCell sx={{ p: '20px 25px 20px 15px' }} width="20%">
                    <Slider
                        aria-label="Default"
                        valueLabelDisplay="auto"
                        value={100 - percentageSliderIntermediateValue}
                        valueLabelFormat={(value) => {
                            return Math.round(value);
                        }}
                        min={0}
                        max={100}
                        onChange={(ev) => {
                            setPercentageSliderIntermediateValue(100 - ev.target.value);
                        }}
                        onChangeCommitted={(ev, value) => {
                            const percentageFromBaseline = 100 - parseFloat(value);
                            // to avoid setting it from an effect, since it can lead to a loop
                            const newTarget = setTarget(
                                (item.baseline * percentageFromBaseline) / 100
                            );
                            setCost(
                                item.cost === null
                                    ? null
                                    : (item.cost * percentageFromBaseline) / 100
                            );
                            setPercentageSliderIntermediateValueFromTarget(newTarget);
                        }}
                        disabled={locked}
                    />
                </TableCell>
                <TableCell align="right">
                    <Tooltip title="Simulator">
                        <IconButton
                            onClick={() => {
                                setShowSimulator(showSimulator === item.id ? false : item.id);
                            }}
                        >
                            {showSimulator === item.id ? (
                                <ArrowDropUpIcon />
                            ) : (
                                <ArrowDropDownIcon />
                            )}
                        </IconButton>
                    </Tooltip>
                </TableCell>
            </TableRow>
            <TableRow className={showSimulator === item.id ? 'removeBorderBottom' : ''}>
                <CostAndNoteLine
                    reductionCost={item.reductionCost}
                    setReductionCost={setReductionCost}
                    datalineNote={item.datalineNote}
                    setDatalineNote={setDatalineNote}
                    id={item.id}
                    currentPath={currentPath}
                    idx={idx}
                />
            </TableRow>
            {showSimulator === item.id && (
                <TableRow>
                    <TableCell colSpan={8}>
                        <DatalineCalculationSimulator
                            id={item.id}
                            setTarget={setTarget}
                            targetScopesHierarchy={targetScopesHierarchy}
                            baseUnit={baseUnit}
                            currentPath={currentPath}
                            datalineTagId={datalineTagId}
                        />
                    </TableCell>
                </TableRow>
            )}
        </React.Fragment>
    );
};

export default DatalineScenarioLine;
