import { Button, ButtonGroup, CircularProgress, Grid, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import AddIcon from '@mui/icons-material/Add';
import ComponentTitle from '../../components/ComponentTitle';
import { useApi } from '../../hooks/useApi';
import UsersTable from './components/UsersTable';
import useNotify from '../../hooks/useNotify';
import useSorting from '../../hooks/useSorting';
import { useAuth } from '../../hooks/useAuth';
import {
    TABLE_ROW_COUNT_DEFAULT,
    PERMISSION_SET_NAMES,
    PERMISSION_SETS,
    ROLES,
} from '../../helpers/constans';
import { parseXlsxSheets, runImport } from '../../helpers/excelImportUsersHelpers';
import { MODAL_TYPE, useModals } from '../../hooks/useModal';
import { exportToXlsx, getAllUsersInCompany } from '../../helpers/excelExportHelpers';
import CommonButton from '../../components/atom/CommonButton';
import InputButton from '../../components/atom/InputButton';

const UsersPage = () => {
    const navigate = useNavigate();
    const { companyId } = useParams();
    const { sort, sortDir, setSorting } = useSorting(['name', 'ASC']);
    const [{ limit, page }, setParams] = useState({ limit: TABLE_ROW_COUNT_DEFAULT, page: 0 });
    const { notifyError, notifySuccess } = useNotify();
    const [generatingXlsx, setGeneratingXlsx] = useState(false);
    const [importingXlsx, setImportingXlsx] = useState(false);
    const { user } = useAuth();
    const { showModal } = useModals();

    const [usersData, loading, error, setData, reloadData] = useApi('Users', 'getUsersInCompany', {
        id: companyId,
        pageSize: limit,
        page,
        sort,
        sortDir,
    });

    useEffect(() => {
        if (error) {
            notifyError(error);
        }
    }, [error, notifyError]);

    const handleImport = async (e) => {
        try {
            return parseXlsxSheets(e.target.files[0], (parsedData) => {
                runImport(parsedData, true, companyId).then((dryRunResult) => {
                    const dryRunFlat = Object.entries(dryRunResult).reduce(
                        (sum, item) => [...sum, ...item[1]],
                        []
                    );
                    showModal(MODAL_TYPE.CONFIRMATION_IMPORT_USERS, {
                        title: `Are you sure you want to import this xlsx?`,
                        content: 'This action is irreversible.',
                        data: dryRunFlat,
                        setImportingXlsx,
                        confirm: async () => {
                            await runImport(parsedData, false, companyId);
                            notifySuccess('Import successful');
                            setImportingXlsx(false);
                            reloadData();
                        },
                    });
                });
            });
        } catch (err) {
            setImportingXlsx(false);
            notifyError(err);
        }
    };

    // helper function to get the permission by feature set of the user
    function getPermissionSetName(featureSet) {
        let permission = PERMISSION_SET_NAMES.CUSTOM;
        Object.entries(PERMISSION_SET_NAMES).forEach((permissionSet) => {
            // temporary filter for view_report, since it is not in the permissions, but in the user permissions in database
            if (
                JSON.stringify(
                    featureSet.filter((f) => f !== 'view_report').sort((a, b) => a.localeCompare(b))
                ) ===
                JSON.stringify(PERMISSION_SETS[permissionSet[1]].sort((a, b) => a.localeCompare(b)))
            ) {
                permission = PERMISSION_SET_NAMES[permissionSet[0]];
            }
        });
        return permission;
    }

    return (
        <Grid container alignContent="flex-start">
            <Grid container item xs={12} justifyContent="space-between" spacing={0}>
                <Grid item>
                    <ComponentTitle variant="h4" title="Users" />
                </Grid>
                <Grid item>
                    {user.role === ROLES.SUPERADMIN && (
                        <CommonButton
                            onClick={() => navigate(`/companies`)}
                            label="Back"
                            buttonType="secondary"
                            sx={{ mr: 0.625, mb: 1.625 }}
                        />
                    )}
                    <ButtonGroup color="primary">
                        <Tooltip title="Invite user">
                            <Button
                                fullWidth
                                onClick={() => navigate(`/companies/${companyId}/invite`)}
                            >
                                <AddIcon />
                            </Button>
                        </Tooltip>
                        <InputButton
                            id="user-list-upload"
                            onChange={(e) => {
                                if (e.target.files.length === 0) {
                                    return;
                                }
                                setImportingXlsx(true);
                                handleImport(e).catch((err) => {
                                    notifyError(err);
                                });
                                e.target.value = '';
                            }}
                            accept=".csv, .xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            title="Import from xlsx"
                            type="file"
                            loading={importingXlsx}
                            fullWidth
                        />
                        <Tooltip title="Export to xlsx">
                            <Button
                                fullWidth
                                onClick={async () => {
                                    setGeneratingXlsx(true);
                                    try {
                                        const listToExport = (
                                            await getAllUsersInCompany(companyId)
                                        ).map((u) => ({
                                            name: u.name,
                                            email: u.email,
                                            permission:
                                                u.role === 'superadmin'
                                                    ? 'Superadmin'
                                                    : getPermissionSetName(u.feature_set),
                                        }));
                                        exportToXlsx(
                                            listToExport,
                                            'users',
                                            false,
                                            false,
                                            false,
                                            false
                                        );
                                    } catch (err) {
                                        notifyError(err);
                                    }
                                    setGeneratingXlsx(false);
                                    notifySuccess('Users exported');
                                }}
                            >
                                {generatingXlsx ? (
                                    <CircularProgress color="inherit" size={20} />
                                ) : (
                                    <FileDownloadIcon />
                                )}
                            </Button>
                        </Tooltip>
                    </ButtonGroup>
                </Grid>
            </Grid>
            <UsersTable
                rows={usersData?.users}
                loading={loading}
                total={usersData?.total}
                limit={limit}
                page={page}
                reloadData={reloadData}
                setParams={setParams}
                companyId={companyId}
                sort={sort}
                sortDir={sortDir}
                setSorting={setSorting}
            />
        </Grid>
    );
};

export default UsersPage;
