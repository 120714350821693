import React from 'react';
import DataSaverOffIcon from '@mui/icons-material/DataSaverOff';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Grid, Paper, Typography } from '@mui/material';
import BarChartIcon from '@mui/icons-material/BarChart';
import PieChartIcon from '@mui/icons-material/PieChart';
import SsidChartIcon from '@mui/icons-material/SsidChart';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { DASHBOARD_VISUALIZATION_TYPES } from '../../helpers/constans';

// helper function to select which icon to display
const displayIcon = (type) => {
    if (
        type === DASHBOARD_VISUALIZATION_TYPES.PIE ||
        type === DASHBOARD_VISUALIZATION_TYPES.SITE_PIE_CHART ||
        type === DASHBOARD_VISUALIZATION_TYPES.SCOPE_PIE_CHART ||
        type === DASHBOARD_VISUALIZATION_TYPES.CATEGORY_PIE_CHART ||
        type === DASHBOARD_VISUALIZATION_TYPES.SUBCATEGORY_PIE_CHART
    ) {
        return <PieChartIcon fontSize="small" />;
    }
    if (
        type === DASHBOARD_VISUALIZATION_TYPES.BAR ||
        type === DASHBOARD_VISUALIZATION_TYPES.CATEGORY_BAR_CHART ||
        type === DASHBOARD_VISUALIZATION_TYPES.TAG_BAR_CHART ||
        type === DASHBOARD_VISUALIZATION_TYPES.SITE_BAR_CHART
    ) {
        return <BarChartIcon fontSize="small" />;
    }
    if (
        type === DASHBOARD_VISUALIZATION_TYPES.SCENARIO_LINE_CHART ||
        type === DASHBOARD_VISUALIZATION_TYPES.SCOPE_LINE_CHART ||
        type === DASHBOARD_VISUALIZATION_TYPES.SCOPES_PER_INTENSITY_LINE_CHART
    ) {
        return <SsidChartIcon fontSize="small" />;
    }
    // if (type === DASHBOARD_VISUALIZATION_TYPES.RADIAL_BAR) {
    //     return <DataSaverOffIcon fontSize="small" />;
    // }
    if (type === DASHBOARD_VISUALIZATION_TYPES.TOTAL_EMISSIONS) {
        return <ListAltIcon fontSize="small" />;
    }
};
const PanelListItem = ({ provided, name, visual_type }) => {
    return (
        <Paper
            sx={{
                '&:not(:last-child)': {
                    mb: 1,
                },
                p: 1,
                borderWidth: 1,
            }}
            variant="outlined"
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
        >
            <Grid container spacing={0}>
                <Grid
                    item
                    container
                    xs={10}
                    justifyContent="flex-start"
                    spacing={0}
                    alignItems="center"
                >
                    {displayIcon(visual_type)}
                    <Typography variant="body2">&nbsp; {name}</Typography>
                </Grid>
                <Grid
                    item
                    container
                    xs={2}
                    spacing={0}
                    justifyContent="flex-end"
                    alignItems="center"
                >
                    <MoreVertIcon fontSize="small" />
                </Grid>
            </Grid>
        </Paper>
    );
};

export default PanelListItem;
