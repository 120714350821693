import {
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip,
} from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import SkeletonTable from '../../../components/atom/SkeletonTable';
import { MODAL_TYPE, useModals } from '../../../hooks/useModal';
import { doSwaggerCall } from '../../../hooks/useApi';
import useNotify from '../../../hooks/useNotify';
import SortableTableCell from '../../../components/atom/SortableTableCell';
import {
    TABLE_ROW_COUNT_OPTIONS,
    WARNING_CONTENT_RECALCULATE_TEXT,
} from '../../../helpers/constans';
import { handleChangeRowsPerPage, handlePageChange } from '../../../helpers/handleTableFunctions';

const LocationsTable = ({
    rows,
    limit,
    page,
    setParams,
    total,
    loading,
    loadingParent,
    reloadData,
    parentId,
    parentLocationName,
    sort,
    sortDir,
    setSorting,
}) => {
    const { showModal } = useModals();
    const { notifyError, notifySuccess } = useNotify();

    useEffect(() => {
        setParams({ limit, page: 0 });
    }, [parentId]);

    const deleteLocation = useCallback((id) => {
        doSwaggerCall('Locations', 'deleteLocation', { id })
            .then(() => {
                notifySuccess('Location deleted');
                reloadData();
            })
            .catch((err) => {
                notifyError(err);
            });
    }, []);

    return (
        <Grid item xs={12}>
            <TableContainer component={Paper}>
                <Table aria-label="custom pagination table">
                    <TableHead>
                        <TableRow>
                            <SortableTableCell
                                arrow={sort === 'locationname' ? sortDir : ''}
                                onClick={() => setSorting('locationname')}
                            >
                                Location ({parentLocationName || ' - '})
                            </SortableTableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading || loadingParent ? (
                            <SkeletonTable rows={limit} cells={2} />
                        ) : (
                            rows?.map((row) => (
                                <TableRow key={row.id}>
                                    <TableCell>{row.name}</TableCell>
                                    <TableCell align="right">
                                        <Link to={`/locations/${row.id}`}>
                                            <Tooltip title="Locations">
                                                <IconButton>
                                                    <SubdirectoryArrowRightIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Link>
                                        <Link to={`/locations/${parentId}/edit/${row.id}`}>
                                            <Tooltip title="Edit">
                                                <IconButton>
                                                    <EditIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Link>
                                        <IconButton
                                            onClick={() => {
                                                showModal(MODAL_TYPE.CONFIRMATION, {
                                                    title: `Are you sure you want to delete this location?`,
                                                    content: 'This action is irreversible.',
                                                    warningContent:
                                                        WARNING_CONTENT_RECALCULATE_TEXT,
                                                    confirm: () => deleteLocation(row.id),
                                                });
                                            }}
                                        >
                                            <Tooltip title="Delete">
                                                <DeleteIcon />
                                            </Tooltip>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                count={total || 0}
                rowsPerPage={limit}
                component="div"
                page={page}
                onPageChange={(_, newPage) => handlePageChange(newPage, setParams, limit)}
                rowsPerPageOptions={TABLE_ROW_COUNT_OPTIONS}
                onRowsPerPageChange={(event) => handleChangeRowsPerPage(event, setParams)}
            />
        </Grid>
    );
};

export default LocationsTable;
