import React, { useState } from 'react';
import {
    DialogActions,
    DialogContent,
    DialogTitle,
    Dialog,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    DialogContentText,
    Tooltip,
    TextField,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import { doSwaggerCall } from '../hooks/useApi';
import useNotify from '../hooks/useNotify';
import AutocompleteSelect from '../components/atom/AutoCompleteSelect';
import { companyAutocomplete, siteCopyAutocomplete } from '../autocomplete';
import CommonButton from '../components/atom/CommonButton';

const CopyToAnotherYearModal = ({
    title,
    content,
    companyId,
    siteId,
    year,
    availableYears,
    reloadData,
    showModal,
    MODAL_TYPE,
}) => {
    const closeAction = () => showModal(MODAL_TYPE.NONE);
    const { notifySuccess, notifyError } = useNotify();
    const [copyToYear, setCopyToYear] = useState(new Date().getFullYear() - 1);
    const [copyToCompany, setCopyToCompany] = useState(parseInt(companyId, 10));
    const [copyToSites, setCopyToSites] = useState([]);
    const [checkedCorporate, setCheckedCorporate] = useState(false);
    const [checkedAllSites, setCheckedAllSites] = useState(false);
    const [manualSelect, setManualSelect] = useState(false);
    const [availableSelectedCompanyYears, setAvailableSelectedCompanyYears] =
        useState(availableYears);

    const disableCopy = (copyToSitesParam) => {
        return (
            typeof copyToCompany !== 'number' ||
            (manualSelect && copyToSitesParam.length === 0) ||
            (!manualSelect && !checkedAllSites && !checkedCorporate)
        );
    };

    const copyRequirements = async () => {
        try {
            // copy requirements for each site in case of manual select
            if (manualSelect) {
                for (let i = 0; i < copyToSites.length; i++) {
                    // eslint-disable-next-line no-await-in-loop
                    await doSwaggerCall('Requirements', 'copyRequirements', {
                        id: copyToCompany,
                        siteId: copyToSites[i],
                        year: copyToYear,
                        copyFromYear: year,
                        copyFromCompany: companyId,
                        copyFromSite: siteId || 0,
                    });
                }
            } else {
                // copy requirements for each site in case of checkboxes
                let allSites = [];
                const copyToSitesResult = [];
                if (checkedCorporate) {
                    copyToSitesResult.push(0);
                }
                if (checkedAllSites) {
                    allSites = await doSwaggerCall('Sites', 'getSitesForUsers', {
                        optionalCompanyId: copyToCompany,
                        copyFlag: true,
                        enableCorporate: false,
                    });
                    copyToSitesResult.push(...allSites.sites.map((e) => e.id));

                    // remove site (where we want to copy) from copyToSitesResult if the years are the same
                    if (siteId && year === copyToYear) {
                        copyToSitesResult.splice(
                            copyToSitesResult.findIndex((e) => e === parseInt(siteId, 10)),
                            1
                        );
                    }
                }
                for (let i = 0; i < copyToSitesResult.length; i++) {
                    // eslint-disable-next-line no-await-in-loop
                    await doSwaggerCall('Requirements', 'copyRequirements', {
                        id: copyToCompany,
                        siteId: copyToSitesResult[i],
                        year: copyToYear,
                        copyFromYear: year,
                        copyFromCompany: companyId,
                        copyFromSite: siteId || 0,
                    });
                }
            }
            notifySuccess(`Inventory Boundary copy successful`);
            reloadData();
        } catch (error) {
            notifyError(error);
        }
    };

    return (
        <Dialog
            open
            onClose={closeAction}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="xs"
            fullWidth
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
                <DialogContentText id="alert-dialog-description">
                    Existing requirements will be removed.
                </DialogContentText>
                <Grid item xs={12} sx={{ mt: 2 }}>
                    <AutocompleteSelect
                        size="small"
                        initCall={companyAutocomplete.init}
                        autocompleteCall={companyAutocomplete.search}
                        value={copyToCompany}
                        setValue={(ev, getItem) => {
                            const item = getItem(ev);
                            setAvailableSelectedCompanyYears(item?.company_years || []);
                            setCopyToCompany(ev);
                            setCopyToSites([]);
                        }}
                    >
                        <TextField label="Copy to Company" required />
                    </AutocompleteSelect>
                </Grid>
                <Grid item xs={12} sx={{ mt: 2 }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={checkedCorporate}
                                onChange={(e) => setCheckedCorporate(!!e.target.checked)}
                            />
                        }
                        label="Select Corporate"
                        labelPlacement="end"
                        disabled={manualSelect}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={checkedAllSites}
                                onChange={(e) => setCheckedAllSites(!!e.target.checked)}
                            />
                        }
                        label="Select all sites"
                        labelPlacement="end"
                        disabled={manualSelect}
                    />
                </Grid>
                <Grid item xs={12} sx={{ mt: 1 }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={manualSelect}
                                onChange={(e) => {
                                    setManualSelect(!!e.target.checked);
                                    if (manualSelect) {
                                        setCopyToSites([]);
                                    } else {
                                        setCheckedCorporate(false);
                                        setCheckedAllSites(false);
                                    }
                                }}
                            />
                        }
                        label="Manual select"
                        labelPlacement="end"
                    />
                </Grid>
                <Grid item xs={12}>
                    <AutocompleteSelect
                        size="small"
                        multiple
                        initCall={siteCopyAutocomplete.init}
                        autocompleteCall={siteCopyAutocomplete.search}
                        value={copyToSites}
                        setValue={(ev) => setCopyToSites(ev)}
                        searchParams={{
                            companyId: copyToCompany,
                        }}
                        disabled={!manualSelect}
                    >
                        <TextField label="Copy to Site" required />
                    </AutocompleteSelect>
                </Grid>
                <Grid item xs={12} sx={{ mt: 2 }}>
                    <FormControl fullWidth>
                        <InputLabel id="year-select" required>
                            Copy to year
                        </InputLabel>
                        <Select
                            fullWidth
                            labelId="year-select"
                            id="demo-simple-select"
                            value={copyToYear}
                            size="small"
                            label="Copy from year"
                            onChange={(e) => {
                                setCopyToYear(e.target.value);
                            }}
                        >
                            {availableSelectedCompanyYears.map((yearItem) => (
                                <MenuItem key={yearItem} value={yearItem}>
                                    {yearItem}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </DialogContent>
            <DialogActions>
                <CommonButton
                    onClick={closeAction}
                    autoFocus
                    label="Cancel"
                    buttonType="secondary"
                />
                {disableCopy(copyToSites) ? (
                    <Tooltip title="Pick a company and at least one site first!" placement="left">
                        <span>
                            <CommonButton
                                sx={{ mr: 1 }}
                                disabled
                                label="Yes"
                                buttonType="primary"
                            />
                        </span>
                    </Tooltip>
                ) : (
                    <CommonButton
                        onClick={() => {
                            copyRequirements();
                            showModal(MODAL_TYPE.NONE);
                        }}
                        label="Yes"
                        buttonType="primary"
                    />
                )}
            </DialogActions>
        </Dialog>
    );
};

export default CopyToAnotherYearModal;
