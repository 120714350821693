import { read, utils } from 'xlsx';
import { MULTIVARIABLE_EMISSION_SOURCES } from '../config';

const NEED_COLUMNS_IMPORT = [
    'source_name',
    'subcategory',
    'category',
    'scope',
    'base_unit',
    'co2',
    'co2_unit',
    'ch4',
    'ch4_unit',
    'n2o',
    'n2o_unit',
    'source',
    'admin_note',
    'location',
    'use_as_global',
];
if (MULTIVARIABLE_EMISSION_SOURCES) {
    NEED_COLUMNS_IMPORT.push('parent_id');
}

// Parse xlsx sheets
const parseXlsxSheets = (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);
        fileReader.onload = (e) => {
            const bufferArray = e?.target.result;
            const wb = read(bufferArray, { type: 'buffer' });

            // [ { sheet: 'name', data: [...] } ]
            const result = [];
            // skip helper sheet (Locations)
            const sheets = wb.SheetNames.filter((name) => name !== 'Locations');
            for (let i = 0; i < sheets.length; i++) {
                let replacedSheetName = sheets[i];
                // excel sheet name cannot contain '/' and cannot exceed 31 chars, so:
                // check '-' in sheet name, need replace to ' / '
                if (sheets[i].includes('-')) {
                    replacedSheetName = sheets[i].replace(/-/g, ' / ');
                }
                const sheetData = utils.sheet_to_json(wb.Sheets[sheets[i]], {
                    blankrows: false,
                    defval: '',
                });
                // check empty sheets
                if (sheetData.length === 1) {
                    let emptyRow = true;
                    Object.entries(sheetData[0]).forEach((attr) => {
                        if (attr[1] !== '') {
                            emptyRow = false;
                        }
                    });
                    if (emptyRow) {
                        continue;
                    }
                }
                result.push({
                    name: replacedSheetName,
                    data: sheetData,
                });
            }

            // check import_this
            const importThisTabs = result.filter(
                (item) => item.name === 'import_this' || item.name.startsWith('import_this')
            );
            if (importThisTabs.length === 0) {
                return reject(new Error(`No 'import_this' sheet in the excel!`));
            }
            const importThis = importThisTabs.reduce((sum, e) => [...sum, ...e.data], []);
            const sortedJsonHeaders = JSON.stringify(NEED_COLUMNS_IMPORT.sort());
            const mappedImportThis = importThis.map((line) =>
                MULTIVARIABLE_EMISSION_SOURCES
                    ? {
                          admin_note: line.admin_note,
                          base_unit: line.base_unit,
                          category: line.category,
                          ch4: line.ch4,
                          ch4_unit: line.ch4_unit,
                          co2: line.co2,
                          co2_unit: line.co2_unit,
                          location: line.location,
                          n2o: line.n2o,
                          n2o_unit: line.n2o_unit,
                          parent_id: line.parent_id,
                          scope: line.scope,
                          source: line.source,
                          source_name: line.source_name,
                          subcategory: line.subcategory,
                          use_as_global: line.use_as_global,
                      }
                    : {
                          admin_note: line.admin_note,
                          base_unit: line.base_unit,
                          category: line.category,
                          ch4: line.ch4,
                          ch4_unit: line.ch4_unit,
                          co2: line.co2,
                          co2_unit: line.co2_unit,
                          location: line.location,
                          n2o: line.n2o,
                          n2o_unit: line.n2o_unit,
                          scope: line.scope,
                          source: line.source,
                          source_name: line.source_name,
                          subcategory: line.subcategory,
                          use_as_global: line.use_as_global,
                      }
            );
            const allFine = mappedImportThis.filter(
                (e) => JSON.stringify(Object.keys(e).sort()) !== sortedJsonHeaders
            );
            if (allFine.length > 0) {
                return reject(
                    new Error(
                        `The columns in 'import_this' should be: ${NEED_COLUMNS_IMPORT.join(' , ')}`
                    )
                );
            }

            return resolve(importThis);
        };
    });
};

// Handle import
export default async function handleSourcesImport(file) {
    const xlsxFile = await parseXlsxSheets(file);
    return xlsxFile;
}
