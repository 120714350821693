import React, { useContext, useState } from 'react';

const LegendsContext = React.createContext();

export function LegendsContextProvider({ children }) {
    const [legends, setLegends] = useState([]);
    const [pieLegends, setPieLegends] = useState([]);
    const [lineLegends, setLineLegends] = useState([]);
    return (
        <LegendsContext.Provider
            value={{
                legends,
                setLegends,
                pieLegends,
                setPieLegends,
                lineLegends,
                setLineLegends,
            }}
        >
            {children}
        </LegendsContext.Provider>
    );
}

LegendsContext.displayName = 'LegendsContext';

export function useLegends() {
    return useContext(LegendsContext);
}
