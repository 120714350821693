import React, { useEffect } from 'react';
import {
    Button,
    Checkbox,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { useImpSurveyStep, withFormikContext } from './ImpSurveyStep';
import SurveyQuestionHeadline from '../../survey/components/SurveyQuestionHeadline';
import ImpSurveySection from './ImpSurveySection';
import SurveyQuestionSubheader from './SurveyQuestionSubheader';
import { MODAL_TYPE, useModals } from '../../../hooks/useModal';

function QListOfOperations({ values, setFieldValue, sectionIndex, formikKey, q, ghg, subheader }) {
    const { step, addFieldIntoStep } = useImpSurveyStep();
    const { showModal } = useModals();

    useEffect(() => {
        addFieldIntoStep(`answers.${formikKey}`, sectionIndex + 1);
    }, [addFieldIntoStep, formikKey, sectionIndex]);

    if (step !== sectionIndex + 1) {
        return null;
    }

    const deleteRow = (idx) => {
        const newFields = [...values.answers[formikKey].fields];
        newFields.splice(idx, 1);
        setFieldValue(`answers.${formikKey}`, {
            fields: newFields,
        });
    };

    return (
        <ImpSurveySection>
            <Grid item xs={12}>
                <SurveyQuestionHeadline>{q}</SurveyQuestionHeadline>
                {subheader && <SurveyQuestionSubheader subheader={subheader} />}
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell width="25%">Name</TableCell>
                            {ghg.map((column, i) => (
                                <Tooltip title={column}>
                                    <TableCell key={column} width="10%" align="center">
                                        {column
                                            .substring(column.indexOf('(') + 1, column.indexOf(')'))
                                            .toUpperCase()}
                                    </TableCell>
                                </Tooltip>
                            ))}
                            <TableCell width="5%" />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {values?.answers[formikKey].fields?.map((field, idx) => (
                            <TableRow key={idx}>
                                <TableCell>
                                    <TextField
                                        fullWidth
                                        autoComplete="off"
                                        value={field.name}
                                        multiline
                                        onChange={(e) => {
                                            setFieldValue(
                                                `answers.${formikKey}.fields[${idx}].name`,
                                                e.target.value
                                            );
                                        }}
                                    />
                                </TableCell>
                                {ghg.map((item, i) => (
                                    <TableCell key={item} align="center">
                                        <Checkbox
                                            checked={field.ghg[i] === 1}
                                            onChange={(e) => {
                                                const updatedFields = [
                                                    ...values.answers[formikKey].fields,
                                                ];
                                                updatedFields[idx].ghg[i] = e.target.checked
                                                    ? 1
                                                    : 0;
                                                setFieldValue(
                                                    `answers.${formikKey}.fields`,
                                                    updatedFields
                                                );
                                            }}
                                        />
                                    </TableCell>
                                ))}
                                <TableCell>
                                    <IconButton
                                        onClick={() => {
                                            showModal(MODAL_TYPE.CONFIRMATION, {
                                                title: `Are you sure you want to remove this operation?`,
                                                content: 'This action is irreversible.',
                                                confirm: () => deleteRow(idx),
                                            });
                                        }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                        <TableCell colSpan={ghg.length + 2}>
                            <Button
                                variant="text"
                                startIcon={<AddIcon />}
                                onClick={() => {
                                    setFieldValue(`answers.${formikKey}.fields`, [
                                        ...(values.answers[formikKey]?.fields ?? []),
                                        {
                                            name: '',
                                            ghg: Array(ghg.length).fill(0),
                                        },
                                    ]);
                                }}
                            >
                                Add Operation
                            </Button>
                        </TableCell>
                    </TableBody>
                </Table>
            </Grid>
        </ImpSurveySection>
    );
}

export default withFormikContext(QListOfOperations);
