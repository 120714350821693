import { Grid, IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useEsgFilters } from '../../../hooks/useEsgFilters';
import { tocToString } from '../../../helpers/groups';
import EsgSection from './EsgSection';
import EsgHelperIcon from '../../../components/atom/EsgHelperIcon';

function EsgGroup({ groupOpen, item, setGroupOpen, toc, edited, setEdited, ...restProps }) {
    const { esgCategory, esgSubcategory, esgDisclosure, esgQuestion } = useEsgFilters();
    const [filterCount, setFilterCount] = useState(
        [esgCategory, esgSubcategory, esgDisclosure, esgQuestion].filter((e) => e > 0).length
    );
    const open = groupOpen.includes(`${item.id}-${item.type}`);
    const sectionHeader =
        (restProps.groupby === 'HIERARCHY' && item.id !== 0 && item.type !== 'category') ||
        restProps.groupby === 'SITE'
            ? `${tocToString(toc)} ${item.section?.sectionHeader}`
            : `${item.section?.sectionHeader}`;

    const itemName =
        item.type === 'category' || restProps.groupby === 'SITE'
            ? `${tocToString(toc)} ${item.name}`
            : item.name;
    const typography = item.id !== 0 ? itemName : `${item.name}`;

    const setOpen = (newOpen) => {
        if (newOpen === true) {
            setGroupOpen((old) => [...old, `${item.id}-${item.type}`]);
        } else {
            setGroupOpen((old) => old.filter((e) => e !== `${item.id}-${item.type}`));
        }
    };

    useEffect(() => {
        setFilterCount(
            [esgCategory, esgSubcategory, esgDisclosure, esgQuestion].filter((e) => e > 0).length
        );
    }, [esgCategory, esgSubcategory, esgDisclosure, esgQuestion]);

    const paddingLeftBase = 15;
    let paddingLeftMultiplier = 0;
    let paddingLeft = '0px';
    switch (item.type) {
        case 'subcategory':
            paddingLeftMultiplier = 2;
            break;
        case 'disclosure':
            paddingLeftMultiplier = 3;
            break;
        case 'question':
            paddingLeftMultiplier = 4;
            break;
        default:
            paddingLeftMultiplier = 1;
            break;
    }
    paddingLeft = `${(paddingLeftMultiplier - (filterCount + 1)) * paddingLeftBase}px`;

    if (!open) {
        return (
            <Grid item xs={12}>
                <Typography
                    variant="h5"
                    sx={{
                        fontWeight: item.type === 'category' ? 'bold' : 'normal',
                        cursor: 'pointer',
                        userSelect: 'none',
                        paddingLeft,
                    }}
                    onClick={() => setOpen(!open)}
                >
                    {typography}
                    {item.guide_note && (
                        <EsgHelperIcon
                            title={item.name}
                            guideNote={item.guide_note}
                            esgQuestionId={item.id}
                        />
                    )}
                    <IconButton onClick={() => setOpen(!open)}>
                        <KeyboardArrowDownIcon />
                    </IconButton>
                </Typography>
            </Grid>
        );
    }

    if (item.section) {
        return (
            <EsgSection
                {...restProps}
                {...item.section}
                sectionHeader={item.section?.sectionHeader === '' ? '' : sectionHeader}
                groupOpen={groupOpen}
                setGroupOpen={setGroupOpen}
                item={item}
                open={open}
                setOpen={() => setOpen(!open)}
                edited={edited}
                setEdited={setEdited}
            />
        );
    }

    if (open) {
        return (
            <>
                <Grid item xs={12}>
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <Typography
                                variant="h5"
                                sx={{
                                    fontWeight: item.type === 'category' ? 'bold' : 'normal',
                                    cursor: 'pointer',
                                    userSelect: 'none',
                                    paddingLeft,
                                }}
                                onClick={() => setOpen(!open)}
                            >
                                {typography}
                                <IconButton
                                    onClick={() => {
                                        groupOpen.filter((idx) => idx !== item.id);
                                        setOpen(!open);
                                    }}
                                >
                                    <KeyboardArrowUpIcon />
                                </IconButton>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                {item.children && (
                    <Grid item xs={12}>
                        {item.children.map((subitem, idx) => {
                            return (
                                <Grid container spacing={0} key={idx}>
                                    <EsgGroup
                                        groupOpen={groupOpen}
                                        item={subitem}
                                        setGroupOpen={setGroupOpen}
                                        {...restProps}
                                        toc={[...toc, idx + 1]}
                                        edited={edited}
                                        setEdited={setEdited}
                                    />
                                </Grid>
                            );
                        })}
                    </Grid>
                )}
            </>
        );
    }
    return null;
}

export default EsgGroup;
