import { createTheme } from '@mui/material';
import { color } from 'd3-color';

const THEME_FONT = "'proxima-nova', sans-serif";

const theme = ({ darkMode }) => {
    // inspired by https://coolors.co/palette/072ac8-1e96fc-a2d6f9-fcf300-ffc600
    let BASE_COLORS = {
        DARK_BLUE_BACKGROUND: '#072AC8',
        MEDIUM_BLUE_BACKGROUND: '#1E96FC',
        LIGHT_BLUE_BACKGROUND: '#A2D6F9',
        WHITE_BLUE_BACKGROUND: '#f2faff',
        DARK_INPUT_TEXT: '#066387',
        DARK_HEADER_TEXT: '#201D1D',
        LIGHT_GREY_BACKGROUND: '#f7fafc',
        LIGHT_GREY_TEXT: 'rgba(0, 0, 0, 0.6)',
        LIGHT_BORDER_COLOR: 'rgba(0, 0, 0, 0.12)',
        PAPER_BACKGROUND: '#ffffff',
    };

    if (darkMode) {
        BASE_COLORS = {
            DARK_BLUE_BACKGROUND: '#152238',
            MEDIUM_BLUE_BACKGROUND: '#1e3246',
            LIGHT_BLUE_BACKGROUND: '#1d8bf1',
            WHITE_BLUE_BACKGROUND: '#152238',
            DARK_INPUT_TEXT: '#ffffff',
            DARK_HEADER_TEXT: '#f2f2f2',
            LIGHT_GREY_BACKGROUND: '#1f2c3b',
            LIGHT_GREY_TEXT: 'rgba(255, 255, 255, 0.6)',
            LIGHT_BORDER_COLOR: 'rgba(255, 255, 255, 0.12)',
            PAPER_BACKGROUND: '#0F304A',
        };
    }

    const t = createTheme({
        palette: {
            primary: {
                main: BASE_COLORS.DARK_BLUE_BACKGROUND,
            },
            background: {
                default: BASE_COLORS.LIGHT_GREY_BACKGROUND,
            },
            mode: darkMode ? 'dark' : 'light',
        },
        components: {
            MuiDrawerHeader: {
                styleOverrides: {
                    root: {
                        borderBottom: `1px solid ${color(BASE_COLORS.LIGHT_GREY_BACKGROUND).darker(
                            0.2
                        )}`,
                    },
                },
            },
            MuiDrawer: {
                styleOverrides: {
                    paper: {
                        backgroundColor: BASE_COLORS.LIGHT_GREY_BACKGROUND,
                        border: 'none',
                        '& hr': {
                            display: 'none',
                        },
                    },
                },
            },
            MuiTextField: {
                defaultProps: {
                    variant: 'standard',
                },
                styleOverrides: {
                    root: {
                        '& .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl':
                            {
                                '&.Mui-focused::after': {
                                    borderBottom: `1px solid ${
                                        darkMode
                                            ? BASE_COLORS.DARK_INPUT_TEXT
                                            : BASE_COLORS.DARK_BLUE_BACKGROUND
                                    }`,
                                },
                                '& input[type="date"]::-webkit-calendar-picker-indicator': {
                                    filter: darkMode ? 'invert(1)' : '',
                                },
                            },
                    },
                },
            },
            MuiSelect: {
                defaultProps: {
                    variant: 'standard',
                },
                styleOverrides: {
                    root: {
                        '&:after': {
                            borderBottom: `1px solid ${
                                darkMode
                                    ? BASE_COLORS.DARK_INPUT_TEXT
                                    : BASE_COLORS.DARK_BLUE_BACKGROUND
                            }`,
                        },
                        '&.question-field': {
                            '&:before': {
                                borderBottom: 'none',
                            },
                        },
                    },
                },
            },
            MuiChip: {
                defaultProps: {
                    variant: 'filled',
                },
                styleOverrides: {
                    root: {
                        lineHeight: '1.5rem',
                    },
                },
            },
            MuiDivider: {
                styleOverrides: {
                    root: {
                        '&:after, &:before': {
                            borderWidth: 8,
                            borderColor: BASE_COLORS.LIGHT_GREY_BACKGROUND,
                            top: -4,
                        },
                        '& .MuiChip-root': {
                            alignSelf: 'center',
                            aspectRatio: '1',
                        },
                    },
                },
            },
            MuiInputLabel: {
                styleOverrides: {
                    shrink: {
                        transform: 'translate(0px, 0px) scale(0.75)',
                        '&.MuiInputLabel-outlined.MuiInputLabel-sizeSmall': {
                            transform: 'translate(14px, -9px) scale(0.75)',
                        },
                    },
                    root: {
                        '&.Mui-focused': {
                            color: darkMode
                                ? BASE_COLORS.DARK_INPUT_TEXT
                                : BASE_COLORS.DARK_BLUE_BACKGROUND,
                        },
                    },
                },
            },
            MuiAppBar: {
                defaultProps: {
                    color: 'inherit',
                },
                styleOverrides: {
                    root: {
                        backgroundColor: BASE_COLORS.LIGHT_GREY_BACKGROUND,
                        backgroundImage: 'none',
                        color: BASE_COLORS.DARK_BLUE_BACKGROUND,
                        border: 'none',
                        '& a img': {
                            filter: 'contrast(0.5)',
                        },
                        '& .MuiInputLabel-root': {
                            color: `${
                                darkMode
                                    ? BASE_COLORS.DARK_INPUT_TEXT
                                    : BASE_COLORS.DARK_BLUE_BACKGROUND
                            } !important`,
                        },
                        '& .MuiInputBase-root': {
                            borderColor: darkMode
                                ? BASE_COLORS.DARK_INPUT_TEXT
                                : BASE_COLORS.DARK_BLUE_BACKGROUND,
                            color: darkMode
                                ? BASE_COLORS.DARK_INPUT_TEXT
                                : BASE_COLORS.DARK_BLUE_BACKGROUND,
                        },
                        '& .MuiSelect-icon': {
                            fill: darkMode
                                ? BASE_COLORS.DARK_INPUT_TEXT
                                : BASE_COLORS.DARK_BLUE_BACKGROUND,
                        },
                        '& .Mui-focused': {
                            color: darkMode
                                ? BASE_COLORS.DARK_INPUT_TEXT
                                : BASE_COLORS.DARK_BLUE_BACKGROUND,
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: `${BASE_COLORS.LIGHT_BORDER_COLOR} !important`,
                            backgroundColor: 'white',
                        },
                        '& .MuiChip-label': {
                            color: `${
                                darkMode
                                    ? BASE_COLORS.DARK_INPUT_TEXT
                                    : BASE_COLORS.DARK_BLUE_BACKGROUND
                            } !important`,
                        },
                        '& .MuiChip-root': {
                            height: 24,
                            border: `1px solid ${BASE_COLORS.DARK_BLUE_BACKGROUND} !important`,
                            margin: '0 3px !important',
                        },
                        '& .MuiChip-deleteIcon': {
                            marginRight: '0px !important',
                            opacity: 0,
                            transition: 'opacity 0.2s ease-in-out',
                        },
                        '& .MuiChip-root:hover .MuiChip-deleteIcon': {
                            opacity: 1,
                        },
                        '& .MuiSvgIcon-root': {
                            color: `${
                                darkMode
                                    ? BASE_COLORS.DARK_INPUT_TEXT
                                    : BASE_COLORS.DARK_BLUE_BACKGROUND
                            } !important`,
                        },
                        '& .MuiTypography-root': {
                            color: `${
                                darkMode
                                    ? BASE_COLORS.DARK_INPUT_TEXT
                                    : BASE_COLORS.DARK_BLUE_BACKGROUND
                            } !important`,
                        },
                        '& .MuiBreadcrumbs-root': {
                            color: `${
                                darkMode
                                    ? BASE_COLORS.DARK_INPUT_TEXT
                                    : BASE_COLORS.DARK_BLUE_BACKGROUND
                            } !important`,
                        },
                    },
                },
            },
            MuiToolbar: {
                styleOverrides: {
                    root: {
                        borderBottomWidth: 0,
                    },
                },
            },
            MuiAvatar: {
                styleOverrides: {
                    root: {
                        borderColor: BASE_COLORS.LIGHT_GREY_BACKGROUND,
                        borderWidth: 1,
                        borderStyle: 'solid',
                    },
                },
            },
            MuiGrid: {
                defaultProps: {
                    spacing: 2,
                },
            },
            MuiTable: {
                styleOverrides: {
                    root: {
                        backgroundColor: BASE_COLORS.LIGHT_GREY_BACKGROUND,
                    },
                },
            },
            MuiTableBody: {
                styleOverrides: {
                    root: {
                        background: darkMode ? BASE_COLORS.DARK_BLUE_BACKGROUND : 'white',
                    },
                },
            },
            MuiLinearProgress: {
                styleOverrides: {
                    root: {
                        borderRadius: '16px 16px 16px 16px',
                    },
                },
            },
            MuiDialogTitle: {
                styleOverrides: {
                    root: {
                        width: '100%',
                        borderRadius: '8px 8px 0 0',
                        fontWeight: '300',
                        fontSize: '2.157142857142857rem',
                        lineHeight: '1.235',
                    },
                },
            },
            MuiAutocomplete: {
                styleOverrides: {
                    groupLabel: {
                        lineHeight: 1.5,
                    },
                },
            },
            MuiBackdrop: {
                styleOverrides: {
                    root: {
                        backgroundColor: 'rgba(15, 36, 74, 0.5)',
                    },
                },
            },
            MuiDialog: {
                styleOverrides: {
                    paper: {
                        borderWidth: 0,
                        borderRadius: 0,
                    },
                },
            },
            MuiTableHead: {
                styleOverrides: {
                    root: {
                        backgroundColor: BASE_COLORS.LIGHT_GREY_BACKGROUND,
                        color: BASE_COLORS.DARK_BLUE_BACKGROUND,
                    },
                },
            },
            MuiTableRow: {
                styleOverrides: {
                    root: {
                        borderBottom: `4px solid ${BASE_COLORS.LIGHT_GREY_BACKGROUND}`,
                        '&.editedTableRow': {},
                        '&.removeBorderBottom': {
                            borderBottom: '0px',
                            '& .MuiTableCell-root': {
                                borderBottom: '0px',
                            },
                        },
                    },
                },
            },
            MuiTableCell: {
                styleOverrides: {
                    head: {
                        fontWeight: 'bold',
                        whiteSpace: 'nowrap',
                        padding: 8,
                    },
                    body: {
                        border: 'none',
                        padding: '8px',
                        '& p': {
                            margin: 0,
                        },
                        '&.nowrap': {
                            whiteSpace: 'nowrap',
                        },
                    },
                },
            },
            MuiButtonBase: {
                defaultProps: {
                    disableRipple: true,
                },
            },
            MuiIconButton: {
                defaultProps: {
                    variant: 'contained',
                },
                styleOverrides: {
                    root: {
                        borderRadius: '50%',
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    text: {
                        borderRadius: 8,
                        color: darkMode
                            ? BASE_COLORS.DARK_INPUT_TEXT
                            : BASE_COLORS.DARK_BLUE_BACKGROUND,
                        '&:hover': {
                            backgroundColor: darkMode ? 'transparent' : '',
                        },
                    },
                    root: {
                        textTransform: 'none',
                        borderRadius: 8,
                        borderColor: darkMode ? 'white' : '',
                        padding: '4px 10px',
                        letterSpacing: '0.5px',
                        '&:hover': {
                            backgroundColor: darkMode ? '#F7FAFC' : '',
                            borderColor: darkMode ? 'white' : '',
                        },
                    },
                    outlined: {
                        color: darkMode ? 'white' : BASE_COLORS.DARK_BLUE_BACKGROUND,
                        '&:hover': {
                            backgroundColor: darkMode ? 'transparent' : '',
                        },
                    },
                    contained: {
                        backgroundColor: darkMode
                            ? BASE_COLORS.DARK_INPUT_TEXT
                            : BASE_COLORS.DARK_BLUE_BACKGROUND,
                        color: darkMode ? '#0F304A' : 'white',
                    },
                },
                defaultProps: {
                    variant: 'contained',
                    color: 'primary',
                },
            },
            MuiButtonGroup: {
                styleOverrides: {
                    root: {
                        '& .MuiSvgIcon-root': {
                            color: darkMode ? 'white' : '',
                        },
                        '& .MuiButton-root': {
                            '&:hover': {
                                backgroundColor: darkMode ? 'white' : '',
                                boxShadow: darkMode ? '0 0 5px white' : '',
                            },
                            borderColor: darkMode ? 'white' : '',
                        },
                        '&.darkModeGroup': {
                            '& .MuiButton-root': {
                                '&:hover': {
                                    color: darkMode ? 'white' : '',
                                },
                            },
                        },
                    },
                    outlined: {
                        color: darkMode ? '0F304A' : BASE_COLORS.DARK_BLUE_BACKGROUND,
                        '& .MuiButton-root': {
                            '&:hover': {
                                backgroundColor: darkMode ? 'transparent' : '',
                                borderColor: darkMode ? 'white' : '',
                            },
                        },
                    },
                    contained: {
                        '& .MuiSvgIcon-root': {
                            color: darkMode ? BASE_COLORS.DARK_BLUE_BACKGROUND : '',
                        },
                    },
                },
            },
            MuiPopover: {
                styleOverrides: {
                    paper: {
                        border: `1px solid ${BASE_COLORS.DARK_BLUE_BACKGROUND}`,
                        borderTopLeftRadius: 0,
                        borderTopRightRadius: 0,
                        marginTop: -7,
                        borderTopWidth: 0,
                        marginLeft: 0.2,
                    },
                },
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        borderColor: BASE_COLORS.LIGHT_BORDER_COLOR,
                        borderWidth: 0,
                        borderStyle: 'solid',
                        backgroundColor: BASE_COLORS.PAPER_BACKGROUND,
                    },
                    rounded: {
                        borderRadius: 16,
                    },
                },
            },
            MuiStep: {
                styleOverrides: {
                    root: {
                        '&.MuiStep-vertical': {
                            '&.Mui-completed .MuiStepIcon-root': {
                                color: darkMode ? 'white' : '',
                            },
                        },
                    },
                },
            },
            MuiCheckbox: {
                styleOverrides: {
                    root: {
                        color: darkMode
                            ? BASE_COLORS.DARK_INPUT_TEXT
                            : BASE_COLORS.DARK_BLUE_BACKGROUND,
                        '&.Mui-checked': {
                            color: 'inherit',
                        },
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    notchedOutline: {
                        borderWidth: '1px !important',
                    },
                },
            },
            MuiFilledInput: {
                root: {
                    borderRadius: 4,
                    '&:before, &:after': {
                        display: 'none',
                    },
                },
            },
            MuiSlider: {
                styleOverrides: {
                    root: {
                        color: darkMode
                            ? BASE_COLORS.DARK_INPUT_TEXT
                            : BASE_COLORS.DARK_BLUE_BACKGROUND,
                    },
                },
            },
            MuiTab: {
                styleOverrides: {
                    root: {
                        '&.Mui-selected': {
                            color: darkMode
                                ? BASE_COLORS.DARK_INPUT_TEXT
                                : BASE_COLORS.DARK_BLUE_BACKGROUND,
                        },
                    },
                },
            },
            MuiTabs: {
                styleOverrides: {
                    indicator: {
                        backgroundColor: darkMode
                            ? BASE_COLORS.DARK_INPUT_TEXT
                            : BASE_COLORS.DARK_BLUE_BACKGROUND,
                    },
                },
            },
        },
        shape: {
            borderRadius: 8,
        },
        typography: {
            fontFamily: THEME_FONT,
            fontWeightLight: 300,
            fontWeightRegular: 400,
            fontWeightMedium: 500,
            htmlFontSize: 10,
            fontSize: 12,
            body1: {
                fontSize: '1.3rem',
                fontFamily: THEME_FONT,
                fontWeight: 400,
                lineHeight: 1.5,
            },
            body2: {
                fontSize: '1.3rem',
                fontFamily: THEME_FONT,
                fontWeight: 400,
                lineHeight: 1.43,
            },
            body3: {
                fontSize: '1rem',
                fontFamily: THEME_FONT,
                fontWeight: 300,
                color: BASE_COLORS.LIGHT_GREY_TEXT,
                lineHeight: 1.2,
                display: 'block',
            },
            fontWeightBold: 900,
            h1: {
                fontFamily: THEME_FONT,
                fontWeight: 300,
                fontSize: '6.914285714285715rem',
                lineHeight: 1.167,
            },
            h2: {
                fontFamily: THEME_FONT,
                fontWeight: 300,
                fontSize: '4.571428571428571rem',
                lineHeight: 1.2,
                color: darkMode ? BASE_COLORS.DARK_INPUT_TEXT : BASE_COLORS.MEDIUM_BLUE_BACKGROUND,
            },
            h3: {
                fontFamily: THEME_FONT,
                fontWeight: 300,
                fontSize: '3.457142857142857rem',
                lineHeight: 1.167,
                color: darkMode ? BASE_COLORS.DARK_INPUT_TEXT : BASE_COLORS.MEDIUM_BLUE_BACKGROUND,
            },
            h4: {
                fontFamily: THEME_FONT,
                fontWeight: 600,
                fontSize: '2.157142857142857rem',
                lineHeight: 1.235,
                color: darkMode ? BASE_COLORS.DARK_INPUT_TEXT : BASE_COLORS.DARK_BLUE_BACKGROUND,
            },
            h5: {
                fontFamily: THEME_FONT,
                fontWeight: 300,
                fontSize: '1.8285714285714286rem',
                lineHeight: 1.334,
                color: darkMode ? BASE_COLORS.DARK_INPUT_TEXT : BASE_COLORS.MEDIUM_BLUE_BACKGROUND,
            },
            h6: {
                fontFamily: THEME_FONT,
                fontWeight: 300,
                // grabbed from TableCell-root
                fontSize: '2.057142857142857rem',
                lineHeight: '2.057142857142857rem',
                color: darkMode ? BASE_COLORS.DARK_INPUT_TEXT : BASE_COLORS.MEDIUM_BLUE_BACKGROUND,
            },
            subtitle1: {
                fontFamily: THEME_FONT,
                fontWeight: 600,
                fontSize: '1.3rem',
                lineHeight: '2.057142857142857rem',
            },
            subtitle2: {
                fontFamily: THEME_FONT,
                fontWeight: 500,
                fontSize: '1.3rem',
                lineHeight: 1.57,
            },
            button: {
                fontFamily: THEME_FONT,
                fontWeight: 500,
                fontSize: '1.3rem',
                lineHeight: 1.75,
                textTransform: 'uppercase',
            },
            caption: {
                fontFamily: THEME_FONT,
                fontWeight: 400,
                fontSize: '1.1142857142857143rem',
                lineHeight: 1.66,
            },
            overline: {
                fontFamily: THEME_FONT,
                fontWeight: 400,
                fontSize: '1.1142857142857143rem',
                lineHeight: 2.66,
                textTransform: 'uppercase',
            },
        },
        shadows: Array(25).fill('none'),
        mixins: {
            toolbar: {
                minHeight: 56,
                borderBottom: `1px solid ${color(BASE_COLORS.LIGHT_GREY_BACKGROUND).darker(0.2)}`,
                '@media (min-width:0px)': {
                    '@media (orientation: landscape)': {
                        minHeight: 48,
                    },
                },
                '@media (min-width:600px)': {
                    minHeight: 64,
                },
            },
        },
    });
    return t;
};

export default theme;
