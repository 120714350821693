import React, { useCallback, useState, useEffect } from 'react';
import {
    DialogActions,
    DialogContentText,
    DialogContent,
    DialogTitle,
    Dialog,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TableContainer,
    Paper,
    Chip,
    Alert,
    LinearProgress,
    Typography,
    Tooltip,
} from '@mui/material';
import useNotify from '../hooks/useNotify';
import { useGlobalFilters } from '../hooks/useGlobalFilters';
import { convertUnitGToKg, formatNumber } from '../helpers/convertUnits';

import {
    doMasterImport,
    collectAllCategoriesAndSubcategories,
    getLocationMatrix,
    extendLinesWithIds,
} from '../helpers/masterSourceImport';
import CommonButton from '../components/atom/CommonButton';

const ConfirmationImportMasterSourcesModal = ({ data, finished, showModal, MODAL_TYPE }) => {
    const { year } = useGlobalFilters();
    const [subcatMatrix, setSubCatMatrix] = useState(false);
    const [locMatrix, setLocMatrix] = useState(false);
    const [importing, setImporting] = useState(false);
    const [importLog, setImportLog] = useState([]);
    const [mappedData, setMappedData] = useState([]);
    const [reloadTrigger, setReloadTrigger] = useState(0);
    const { notifyError } = useNotify();
    const closeAction = () => showModal(MODAL_TYPE.NONE);

    // loading category matrix
    useEffect(() => {
        collectAllCategoriesAndSubcategories(data).then(setSubCatMatrix).catch(notifyError);
    }, [data, setSubCatMatrix, reloadTrigger]);

    // loading location matrix
    useEffect(() => {
        getLocationMatrix(data).then(setLocMatrix).catch(notifyError);
    }, [data, setLocMatrix, reloadTrigger]);

    useEffect(() => {
        if (!subcatMatrix || !locMatrix) {
            return;
        }
        extendLinesWithIds(data, subcatMatrix, locMatrix, year)
            .then(setMappedData)
            .catch(notifyError);
    }, [data, subcatMatrix, locMatrix, reloadTrigger]);

    const appendLog = useCallback(
        (line) => {
            setImportLog((log) => [...log, line]);
        },
        [setImportLog]
    );

    const hasAnyErrors = !!mappedData.find((e) => e.error.length > 0);
    const getLevelHierarchy = (item) => {
        let result = 'No parent';
        if (
            item.parent_scope_name !== '' &&
            item.parent_category_name !== '' &&
            item.parent_subcategory_name !== '' &&
            item.parent_source_name !== ''
        ) {
            const levelHierarchy = `${item.parent_scope_name} > ${item.parent_category_name} > ${item.parent_subcategory_name}`;
            result = levelHierarchy;
        }
        return result;
    };

    return (
        <Dialog
            open
            onClose={importing ? () => {} : closeAction}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="xl"
            fullWidth
        >
            <DialogTitle id="alert-dialog-title">Importing master xls to year {year}</DialogTitle>
            <DialogContent>
                {importing && (
                    <>
                        <Typography variant="h5">Importing sources...</Typography>
                        <LinearProgress />
                        <Typography variant="body1" style={{ height: '40vh', overflowY: 'scroll' }}>
                            {importLog.map((e, idx) => {
                                return <div key={idx}>{e}</div>;
                            })}
                        </Typography>
                    </>
                )}
                {importLog.length > 0 && (
                    <>
                        <Typography variant="h5">Import finished!</Typography>
                        <Typography variant="body1" style={{ height: '40vh', overflowY: 'scroll' }}>
                            {importLog.map((e, idx) => {
                                return <div key={idx}>{e}</div>;
                            })}
                        </Typography>
                    </>
                )}
                {subcatMatrix === false && (
                    <DialogContentText id="alert-dialog-description">
                        Loading categories and subcategories...
                    </DialogContentText>
                )}
                {subcatMatrix !== false && !importing && importLog.length === 0 && (
                    <>
                        <DialogContentText id="alert-dialog-description">
                            Make sure all errors are fixed before you continue!
                        </DialogContentText>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Source</TableCell>
                                        <TableCell>Subcategory</TableCell>
                                        <TableCell>Category</TableCell>
                                        <TableCell>Scope</TableCell>
                                        <TableCell>Source of the data</TableCell>
                                        <TableCell>Admin note</TableCell>
                                        <TableCell>Guide note</TableCell>
                                        <TableCell>Parent name</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {mappedData.map((item, idx) => {
                                        return (
                                            <React.Fragment key={idx}>
                                                <TableRow>
                                                    <TableCell>
                                                        <Chip
                                                            size="small"
                                                            color={
                                                                !item.source_id
                                                                    ? 'primary'
                                                                    : 'default'
                                                            }
                                                            label={
                                                                !item.source_id
                                                                    ? 'New'
                                                                    : `#${item.source_id}`
                                                            }
                                                        />{' '}
                                                        {item.source_name}
                                                    </TableCell>
                                                    <TableCell>
                                                        <Chip
                                                            size="small"
                                                            color={
                                                                !item.subcategory_id
                                                                    ? 'primary'
                                                                    : 'default'
                                                            }
                                                            label={
                                                                !item.subcategory_id
                                                                    ? 'New'
                                                                    : `#${item.subcategory_id}`
                                                            }
                                                        />{' '}
                                                        {item.subcategory}
                                                    </TableCell>
                                                    <TableCell>
                                                        <Chip
                                                            size="small"
                                                            color={
                                                                !item.category_id
                                                                    ? 'primary'
                                                                    : 'default'
                                                            }
                                                            label={
                                                                !item.category_id
                                                                    ? 'New'
                                                                    : `#${item.category_id}`
                                                            }
                                                        />{' '}
                                                        {item.category}
                                                    </TableCell>
                                                    <TableCell>{item.scope}</TableCell>
                                                    <TableCell>{item.source}</TableCell>
                                                    <TableCell>{item.admin_note}</TableCell>
                                                    <TableCell>{item.guide_note}</TableCell>
                                                    <Tooltip
                                                        title={getLevelHierarchy(item)}
                                                        placement="left"
                                                    >
                                                        <TableCell>
                                                            {item.parent_source_name || '-'}
                                                        </TableCell>
                                                    </Tooltip>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell />
                                                    <TableCell colSpan={4}>
                                                        {item.error.length > 0 && (
                                                            <Alert severity="error" sx={{ mb: 1 }}>
                                                                {item.error.map((line, idx) => {
                                                                    return (
                                                                        <div key={idx}>{line}</div>
                                                                    );
                                                                })}
                                                            </Alert>
                                                        )}
                                                        <Table size="small">
                                                            <TableHead>
                                                                <TableCell>Location</TableCell>
                                                                <TableCell>
                                                                    CO
                                                                    <sub>2</sub> <small>kg</small>
                                                                </TableCell>
                                                                <TableCell>
                                                                    CH
                                                                    <sub>4</sub> <small>g</small>
                                                                </TableCell>
                                                                <TableCell>
                                                                    N<sub>2</sub>O <small>g</small>
                                                                </TableCell>
                                                            </TableHead>
                                                            <TableBody>
                                                                {Object.entries(item.locations).map(
                                                                    ([locId, locData]) => {
                                                                        return (
                                                                            <TableRow key={locId}>
                                                                                <TableCell>
                                                                                    {
                                                                                        locData.location
                                                                                    }
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    {formatNumber(
                                                                                        convertUnitGToKg(
                                                                                            locData.co2Factor.toFixed(
                                                                                                5
                                                                                            )
                                                                                        )
                                                                                    )}{' '}
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    {formatNumber(
                                                                                        locData.ch4Factor
                                                                                    )}
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    {formatNumber(
                                                                                        locData.n2oFactor
                                                                                    )}
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        );
                                                                    }
                                                                )}
                                                            </TableBody>
                                                        </Table>
                                                    </TableCell>
                                                </TableRow>
                                            </React.Fragment>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                )}
            </DialogContent>
            {importLog.length > 0 && (
                <DialogActions>
                    <CommonButton
                        onClick={closeAction}
                        autoFocus
                        label="Close"
                        buttonType="secondary"
                    />
                    <CommonButton
                        onClick={() => {
                            // reseting the state of the import window
                            setImportLog([]);
                            setLocMatrix(false);
                            setSubCatMatrix(false);
                            setReloadTrigger((e) => e + 1);
                        }}
                        label="Clear import result"
                        buttonType="primary"
                    />
                </DialogActions>
            )}
            {!importing && importLog.length === 0 && (
                <DialogActions>
                    <CommonButton
                        onClick={closeAction}
                        autoFocus
                        label="Cancel"
                        buttonType="secondary"
                    />
                    {!hasAnyErrors && subcatMatrix !== false && (
                        <CommonButton
                            onClick={() => {
                                setImporting(true);
                                doMasterImport(subcatMatrix, data, locMatrix, year, appendLog)
                                    .then(() => {
                                        setImporting(false);
                                        finished();
                                    })
                                    .catch((err) => {
                                        setImporting(false);
                                        notifyError(err);
                                    });
                            }}
                            label="Import master sheet"
                            buttonType="primary"
                        />
                    )}
                </DialogActions>
            )}
        </Dialog>
    );
};

export default ConfirmationImportMasterSourcesModal;
