import { Badge, Tooltip } from '@mui/material';
import React from 'react';

const BadgeWithIcon = ({ content = '', children, title }) => {
    return (
        <Tooltip title={title} placement="top">
            <Badge badgeContent={content}>{children}</Badge>
        </Tooltip>
    );
};

export default BadgeWithIcon;
