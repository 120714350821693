import React, { useCallback, useEffect, useState } from 'react';
import {
    Grid,
    TextField,
    Paper,
    InputLabel,
    IconButton,
    Typography,
    Container,
    InputAdornment,
} from '@mui/material';
import { Form, Formik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import SsidChartIcon from '@mui/icons-material/SsidChart';
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart';
import { doSwaggerCall } from '../../../hooks/useApi';
import useNotify from '../../../hooks/useNotify';
import ReductionChart from '../components/ReductionChart';
import ScenarioForm from '../components/ScenarioForm';
import { sumOfSavings, sumReductionCosts } from '../helpers/sumDifferentCosts';
import { getSubTree } from '../../../helpers/rebalance';
import { formatMoney } from '../../../helpers/convertUnits';

function EditScenarioPage() {
    const { scenarioId } = useParams();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [scenario, setScenario] = useState(null);
    const { notifyError, notifySuccess } = useNotify();
    const [scenarioEdit, setScenarioEdit] = useState(false);
    const [scenarioGoalEdit, setScenarioGoalEdit] = useState(false);
    const [showDistribution, setShowDistribution] = useState(false);
    const [showReduction, setShowReduction] = useState(false);
    const [targetScopesByCategories, setTargetScopesByCategories] = useState([]);
    const [targetCategoriesByScopes, setTargetCategoriesByScopes] = useState([]);

    const loadScenario = useCallback(() => {
        setLoading(true);
        doSwaggerCall('Scenarios', 'getScenario', { id: scenarioId })
            .then((res) => {
                setScenario({
                    id: res.id,
                    name: res.name,
                    company_id: res.company_id,
                    baseline_year: res.baseline_year,
                    target_year: res.target_year,
                    target_reduction: res.target_reduction,
                    target_scopes: res.target_scopes,
                    target_categories: res.target_categories,
                    target_scopes_hierarchy: res.target_scopes_hierarchy,
                    dataline_tag_id: res.dataline_tag_id,
                    site_filter: res.site_filter,
                    dataline_tag_name: res.dataline_tag_name,
                    site_names: res.site_names,
                });
                setTargetScopesByCategories(res.target_scopes_by_categories);
                setTargetCategoriesByScopes(res.target_categories_by_scopes);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                notifyError(err);
                navigate('/scenarios');
            });
    }, []);

    useEffect(() => {
        loadScenario();
    }, [scenarioId]);

    const onSubmit = async (values, editGoal = false) => {
        if (
            values.target_reduction > 100 ||
            values.target_reduction < 0 ||
            !values.target_reduction
        ) {
            notifyError('Goal should be between 0 - 100 %');
            return;
        }
        try {
            await doSwaggerCall(
                'Scenarios',
                'editScenario',
                { id: scenarioId },
                {
                    name: values.name,
                    baselineYear: parseInt(values.baseline_year, 10),
                    targetYear: parseInt(values.target_year, 10),
                    targetReduction: parseInt(values.target_reduction, 10),
                }
            );

            if (editGoal || values.name !== scenario.name) {
                loadScenario();
            }
            setScenarioEdit(false);
            setScenarioGoalEdit(false);
            notifySuccess('Scenario updated');
        } catch (err) {
            notifyError(err);
            setScenarioEdit(false);
        }
    };

    if (loading) {
        return true;
    }

    const setReductionCost = (path, newReductionCost, idx) => {
        const deepCopyTree = JSON.parse(
            JSON.stringify({ children: scenario.target_scopes_hierarchy })
        );
        const pointer = getSubTree(deepCopyTree, [...path, idx]);
        pointer.reductionCost = newReductionCost;
        setScenario({ ...scenario, target_scopes_hierarchy: deepCopyTree.children });
    };
    const setDatalineNote = (path, newDatalineNote, idx) => {
        const deepCopyTree = JSON.parse(
            JSON.stringify({ children: scenario.target_scopes_hierarchy })
        );
        const pointer = getSubTree(deepCopyTree, [...path, idx]);
        pointer.datalineNote = newDatalineNote;
        setScenario({ ...scenario, target_scopes_hierarchy: deepCopyTree.children });
    };

    const sumOfReductionCost = sumReductionCosts(scenario.target_scopes_hierarchy);
    const sumOfCosts = sumOfSavings(scenario.target_scopes_hierarchy);

    // target and baseline sum
    let actualReduction = '?';
    let actualReductionStyle = {};
    if (scenario?.target_scopes_hierarchy) {
        const baselineSum = scenario?.target_scopes_hierarchy.reduce(
            (sum, item) => sum + item.baseline,
            0
        );
        const targetSum = scenario?.target_scopes_hierarchy.reduce(
            (sum, item) => sum + item.target,
            0
        );
        if (baselineSum > 0) {
            actualReduction = Math.round((1 - targetSum / baselineSum) * 100 * 100) / 100;
        }

        if (actualReduction < scenario?.target_reduction - 3) {
            actualReductionStyle = {
                color: '#FE0101',
                fontWeight: 'bold',
            };
        } else if (actualReduction < scenario?.target_reduction) {
            actualReductionStyle = {
                color: '#FDCF17',
                fontWeight: 'bold',
            };
        } else {
            actualReductionStyle = {
                color: '#038F14',
                fontWeight: 'bold',
            };
        }
    }

    return (
        <Container>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Formik onSubmit={onSubmit} initialValues={scenario}>
                        {({ isSubmitting, values, setFieldValue, touched, errors }) => (
                            <Form>
                                <Grid container spacing={2}>
                                    <Grid
                                        item
                                        container
                                        spacing={2}
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                        xs={8}
                                        sx={{
                                            pl: scenarioEdit ? 0 : 2,
                                        }}
                                        style={{
                                            height: '70px',
                                        }}
                                    >
                                        {scenarioEdit ? (
                                            <Grid item xs={6} sx={{ pb: 2 }}>
                                                <TextField
                                                    fullWidth
                                                    label="Name"
                                                    autoComplete="off"
                                                    value={values.name}
                                                    required
                                                    onChange={(ev) =>
                                                        setFieldValue('name', ev.target.value)
                                                    }
                                                    onBlur={() => {
                                                        onSubmit(values);
                                                    }}
                                                    autoFocus
                                                    error={touched.name && Boolean(errors.name)}
                                                    helperText={touched.name ? errors.name : ''}
                                                />
                                            </Grid>
                                        ) : (
                                            <Grid item xs={12} style={{ alignContent: 'center' }}>
                                                <Typography
                                                    variant="h5"
                                                    style={{
                                                        display: 'inline-block',
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    {values.name}
                                                </Typography>
                                                <IconButton
                                                    disabled={isSubmitting}
                                                    onClick={() => setScenarioEdit(true)}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                            </Grid>
                                        )}
                                    </Grid>
                                    <Grid item xs={4} textAlign="right">
                                        <IconButton onClick={() => setShowReduction((e) => !e)}>
                                            <SsidChartIcon />
                                        </IconButton>
                                        <IconButton onClick={() => setShowDistribution((e) => !e)}>
                                            <StackedBarChartIcon />
                                        </IconButton>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid
                                            item
                                            container
                                            direction="row"
                                            justifyContent="flex-end"
                                            alignItems="center"
                                        >
                                            <Grid item xs={12}>
                                                <Paper sx={{ p: '16px 32px' }}>
                                                    <Grid
                                                        container
                                                        justifyContent="space-between"
                                                        alignItems="center"
                                                    >
                                                        <Grid item>
                                                            <InputLabel>Years</InputLabel>
                                                            {values.baseline_year} {'->'}
                                                            {values.target_year}
                                                        </Grid>
                                                        <Grid item>
                                                            <InputLabel>Scopes</InputLabel>
                                                            {values.target_scopes_hierarchy
                                                                .map((s) => s.name)
                                                                .join(', ')}
                                                        </Grid>
                                                        {values.dataline_tag_name && (
                                                            <Grid item>
                                                                <InputLabel>
                                                                    Dataline Tag
                                                                </InputLabel>
                                                                {values.dataline_tag_name}
                                                            </Grid>
                                                        )}
                                                        <Grid item>
                                                            <InputLabel>Costs / Savings</InputLabel>
                                                            {`${formatMoney(
                                                                sumOfReductionCost
                                                            )} / ${formatMoney(sumOfCosts)}`}
                                                        </Grid>
                                                        {scenarioGoalEdit ? (
                                                            <Grid item xs={2} sx={{ pb: 2 }}>
                                                                <TextField
                                                                    fullWidth
                                                                    required
                                                                    autoComplete="off"
                                                                    label="Goal"
                                                                    type="number"
                                                                    value={values.target_reduction}
                                                                    InputProps={{
                                                                        inputProps: {
                                                                            min: 0,
                                                                            max: 100,
                                                                        },
                                                                        endAdornment: (
                                                                            <InputAdornment position="end">
                                                                                %
                                                                            </InputAdornment>
                                                                        ),
                                                                    }}
                                                                    onChange={(ev) =>
                                                                        setFieldValue(
                                                                            'target_reduction',
                                                                            ev.target.value
                                                                        )
                                                                    }
                                                                    onBlur={() => {
                                                                        onSubmit(values, true);
                                                                    }}
                                                                    autoFocus
                                                                    disabled={!scenarioGoalEdit}
                                                                />
                                                            </Grid>
                                                        ) : (
                                                            <Grid item>
                                                                <InputLabel>Goal</InputLabel>
                                                                {values.target_reduction} %
                                                                <IconButton
                                                                    disabled={isSubmitting}
                                                                    onClick={() =>
                                                                        setScenarioGoalEdit(true)
                                                                    }
                                                                >
                                                                    <EditIcon />
                                                                </IconButton>
                                                            </Grid>
                                                        )}
                                                        <Grid
                                                            item
                                                            style={{
                                                                fontSize: '130%',
                                                                ...actualReductionStyle,
                                                            }}
                                                        >
                                                            <InputLabel>Forecast</InputLabel>
                                                            {actualReduction} %
                                                        </Grid>
                                                    </Grid>
                                                    {values.site_names.length > 0 && (
                                                        <Grid item>
                                                            <InputLabel>Sites</InputLabel>
                                                            {values.site_names.join(', ')}
                                                        </Grid>
                                                    )}
                                                </Paper>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Grid>
                {showReduction && (
                    <Grid item xs={12}>
                        <Grid container item xs={12} spacing={0} sx={{ pb: 2 }}>
                            <ReductionChart
                                scenarioName={scenario.name}
                                baselineYear={scenario.baseline_year}
                                targetYear={scenario.target_year}
                                reduction={scenario.target_reduction}
                                datalineTagId={scenario.dataline_tag_id}
                                siteFilter={scenario.site_filter}
                                categoryIdList={[
                                    ...scenario.target_categories,
                                    ...targetCategoriesByScopes,
                                ]}
                                selectedScopes={[
                                    ...scenario.target_scopes,
                                    ...targetScopesByCategories,
                                ]}
                                isMultiple={false}
                                targetYearData={scenario.target_scopes_hierarchy}
                            />
                        </Grid>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <ScenarioForm
                        setReductionCost={setReductionCost}
                        setDatalineNote={setDatalineNote}
                        scenario={scenario}
                        setScenario={setScenario}
                        showDistribution={showDistribution}
                        datalineTagId={scenario.dataline_tag_id}
                    />
                </Grid>
            </Grid>
        </Container>
    );
}

export default EditScenarioPage;
