import React, { useEffect, useState } from 'react';
import { Grid, LinearProgress } from '@mui/material';
import useNotify from '../../../hooks/useNotify';
import { doSwaggerCall } from '../../../hooks/useApi';
import CategoryOrTagOrSourceOrSupplierBarChart from './charts/CategoryOrTagOrSourceOrSupplierBarChart';

const BySelectedCategories = ({ categories, years, setChartData }) => {
    const { notifyError } = useNotify();
    const [categoryBarData, setCategoryBarData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        doSwaggerCall('Dashboards', 'getDataForCategoryBarChart', {
            categories,
            years,
        })
            .then((res) => {
                setCategoryBarData(res);
                setChartData(res.categoriesData);
                setLoading(false);
            })
            .catch((error) => {
                notifyError(error);
            });
    }, [categories, years, setLoading, setCategoryBarData, setChartData]);

    return (
        <Grid item xs={12}>
            {categoryBarData?.length === 0 ? (
                <Grid item>
                    {loading ? <LinearProgress sx={{ mt: 0.25 }} /> : <h5>No data available</h5>}
                </Grid>
            ) : (
                <CategoryOrTagOrSourceOrSupplierBarChart
                    data={categoryBarData.categoriesData}
                    keys={years}
                    indexBy="category"
                    axisLeftLegend="CO2e (mt)"
                />
            )}
        </Grid>
    );
};

export default BySelectedCategories;
