import React, { useEffect, useState } from 'react';
import { Grid, LinearProgress } from '@mui/material';
import useNotify from '../../../hooks/useNotify';
import { doSwaggerCall } from '../../../hooks/useApi';
import ScopeOrCategoryOrSubcatOrSitePieChart from './charts/ScopeOrCategoryOrSubcatOrSitePieChart';

const BySelectedSite = ({ site, year, setChartData }) => {
    const { notifyError } = useNotify();
    const [sitePieData, setSitePieData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        doSwaggerCall('Dashboards', 'getDataForSitePieChart', {
            site,
            year,
        })
            .then((res) => {
                setSitePieData(res);
                setChartData(res.siteData);
                setLoading(false);
            })
            .catch((error) => {
                notifyError(error);
            });
    }, [site, year, setLoading, setSitePieData, setChartData]);

    return (
        <Grid item xs={12}>
            {sitePieData?.length === 0 ? (
                <Grid item>
                    {loading ? <LinearProgress sx={{ mt: 0.25 }} /> : <h5>No data available</h5>}
                </Grid>
            ) : (
                <ScopeOrCategoryOrSubcatOrSitePieChart
                    data={sitePieData.siteData}
                    year={year}
                    type="site"
                />
            )}
        </Grid>
    );
};

export default BySelectedSite;
