import { doSwaggerCall } from '../hooks/useApi';

function getCategoryKeyForItem(item) {
    return `${item.scope}|||${item.category?.trim()}|||${item.subcategory?.trim()}`;
}

/**
 * Grab a category mapping using existing backend API endpoints
 * @param data
 * @returns {Promise<{}>}
 */
export async function collectAllCategoriesAndSubcategories(data) {
    const uniqueCategoryTree = {};
    const uniqueScopeAndCategory = {};
    const uniqueScope = {};

    // just groupby
    for (const item of data) {
        const key = getCategoryKeyForItem(item);
        if (uniqueCategoryTree[key]) {
            continue;
        }
        uniqueCategoryTree[key] = {
            category_id: false,
            subcategory_id: false,
            scope_id: false,
            scope: item.scope,
            category: item.category.trim(),
            subcategory: item.subcategory.trim(),
        };
    }

    // grab existing id's for scope
    for (const oneKey in uniqueCategoryTree) {
        // quick cache here
        const justScopeAndCategoryKey = getCategoryKeyForItem({
            scope: uniqueCategoryTree[oneKey].scope,
            category: uniqueCategoryTree[oneKey].category,
        });
        if (uniqueScope[justScopeAndCategoryKey]) {
            uniqueCategoryTree[oneKey].scope_id = uniqueScope[justScopeAndCategoryKey];
            continue;
        }
        const scope = await doSwaggerCall('Scopes', 'getAllScopes', {
            search: uniqueCategoryTree[oneKey].scope,
        });
        const existingScope = scope.scopes.find((e) => uniqueCategoryTree[oneKey].scope === e.name);
        // did not find
        if (!existingScope) {
            continue;
        }
        uniqueScope[justScopeAndCategoryKey] = existingScope.id;
        uniqueCategoryTree[oneKey].scope_id = existingScope.id;
    }

    // grab existing id's for category + subcategory and try to resolve them
    for (const oneKey in uniqueCategoryTree) {
        // quick cache here
        const justScopeAndCategoryKey = getCategoryKeyForItem({
            scope: uniqueCategoryTree[oneKey].scope,
            category: uniqueCategoryTree[oneKey].category,
        });
        if (uniqueScopeAndCategory[justScopeAndCategoryKey]) {
            uniqueCategoryTree[oneKey].category_id =
                uniqueScopeAndCategory[justScopeAndCategoryKey];
            continue;
        }
        const cat = await doSwaggerCall('Categories', 'getAllCategories', {
            search: uniqueCategoryTree[oneKey].category.trim(),
            scope: uniqueCategoryTree[oneKey].scope_id,
            limit: 1000,
        });
        const existingCat = cat.categories.find(
            (e) =>
                e.scope_id === uniqueCategoryTree[oneKey].scope_id &&
                uniqueCategoryTree[oneKey].category === e.name
        );
        // did not find
        if (!existingCat) {
            continue;
        }
        uniqueScopeAndCategory[justScopeAndCategoryKey] = existingCat.id;
        uniqueCategoryTree[oneKey].category_id = existingCat.id;
    }

    // grab subcategories for existing categories
    for (const oneKey in uniqueCategoryTree) {
        if (uniqueCategoryTree[oneKey].category_id === false) {
            continue;
        }
        const subcateg = await doSwaggerCall('Subcategories', 'getAllSubcategories', {
            search: uniqueCategoryTree[oneKey].subcategory.trim(),
            categoryId: uniqueCategoryTree[oneKey].category_id,
            scope: uniqueCategoryTree[oneKey].scope_id,
            limit: 1000,
        });
        const existingCat = subcateg.subcategories.find(
            (e) =>
                e.scope_id === uniqueCategoryTree[oneKey].scope_id &&
                e.category_id === uniqueCategoryTree[oneKey].category_id &&
                uniqueCategoryTree[oneKey].subcategory === e.name
        );
        // did not find
        if (!existingCat) {
            continue;
        }
        uniqueCategoryTree[oneKey].subcategory_id = existingCat.id;
    }

    return uniqueCategoryTree;
}

const WEIGHT_MULTIPLIERS = {
    lb: 0.45359237,
    lbs: 0.45359237,
    pound: 0.45359237,
    pounds: 0.45359237,
    'short ton': 907.18474,
    'us ton': 907.18474,
    'metric ton': 1000,
    tonne: 1000,
    'imperial ton': 1016.04691,
    'long ton': 1016.04691,
    'uk ton': 1016.04691,
    stone: 6.35029318,
    g: 0.001,
    gram: 0.001,
    ounce: 0.0283495231,
    kg: 1,
};
export function parseUnit(value, unit) {
    const lowercaseunit = unit.toLowerCase().trim();
    if (!WEIGHT_MULTIPLIERS[lowercaseunit]) {
        throw new Error(
            `Unknown unit: ${lowercaseunit}. Possible units: ${Object.keys(WEIGHT_MULTIPLIERS)}`
        );
    }
    const v = parseFloat(value);
    if (Number.isNaN(v)) {
        throw new Error(`Non-number input for factor: ${value}`);
    }
    return v * 1000 * WEIGHT_MULTIPLIERS[lowercaseunit];
}
export function parseFactors(item) {
    return {
        co2Factor: parseUnit(item.co2, item.co2_unit),
        ch4Factor: parseUnit(item.ch4, item.ch4_unit),
        n2oFactor: parseUnit(item.n2o, item.n2o_unit),
    };
}

export async function extendLinesWithIds(data, subcatMatrix, locMatrix, year) {
    const result = {};
    // just to stop before we loaded the matrix
    if (subcatMatrix === false) {
        return [];
    }

    // try to find sources within existing categories + subcategories, since new categories or subcategories will create new sources
    const sourceCache = {};
    for (const item of data) {
        const catKey = getCategoryKeyForItem(item);
        const cat = subcatMatrix[catKey];
        const loc = locMatrix[item.location];

        // check cache
        const key = `${catKey}|||${item.source_name.trim()}`;
        if (!sourceCache[key] && (cat.category_id === false || cat.subcategory_id === false)) {
            // we are sure it's not in cache, no cat / subcategory, nothing to do here
            sourceCache[key] = {
                ...cat,
                source_id: false,
                base_unit: item.base_unit,
            };
        }

        // let's grab from server if needed
        if (!sourceCache[key]) {
            // grab data
            const sources = await doSwaggerCall('EmissionSources', 'getEmissionSources', {
                search: item.source_name.trim(),
                subcategoryId: cat.subcategory_id,
                categoryId: cat.category_id,
                scope: cat.scope_id,
                includeChildren: true,
            });
            const existingSource = sources.emissionSources.find(
                (e) =>
                    e.name === item.source_name.trim() &&
                    e.scope_id === cat.scope_id &&
                    e.category_id === cat.category_id &&
                    e.subcategory_id === cat.subcategory_id
            );
            sourceCache[key] = {
                ...cat,
                source_id: existingSource ? existingSource.id : false,
                system_managed: existingSource ? existingSource.system_managed : false,
            };
            if (existingSource) {
                sourceCache[key].base_unit = existingSource.base_unit;
            }
        }

        // check if this source is already defined
        if (!result[key]) {
            result[key] = {
                // cat + subcat info
                scope: item.scope,
                scope_id: sourceCache[key].scope_id,
                category: item.category.trim(),
                category_id: sourceCache[key].category_id,
                subcategory: item.subcategory.trim(),
                subcategory_id: sourceCache[key].subcategory_id,
                // source info
                source_name: item.source_name.trim(),
                source_id: sourceCache[key].source_id,
                admin_note: item.admin_note,
                guide_note: item.guide_note,
                source: item.source,
                base_unit: item.base_unit,
                // parent info
                parent_scope_name: item.parent_scope_name.trim(),
                parent_subcategory_name: item.parent_subcategory_name.trim(),
                parent_category_name: item.parent_category_name.trim(),
                parent_source_name: item.parent_source_name.trim(),
                // locations
                locations: {},
                system_managed: sourceCache[key].system_managed,
                // error (if any)
                error: [],
            };
        }

        // check system managed
        if (sourceCache[key].system_managed === true) {
            result[key].error.push(`Source ${item.source_name.trim()} is system managed.`);
        }

        // check parent (existing parent, exclude circular reference for parent-child in import, etc.)
        // every parent level should be defined
        if (
            item.parent_scope_name !== '' &&
            item.parent_category_name !== '' &&
            item.parent_subcategory_name !== '' &&
            item.parent_source_name !== ''
        ) {
            // call getEmissionSources and check parent names of all levels with item's parent names
            const possibleParentSources = await doSwaggerCall(
                'EmissionSources',
                'getEmissionSources',
                {
                    search: item.parent_source_name.trim(),
                    includeChildren: true,
                }
            );
            // search that parent source in the list which has the same scope_name as item's parent_scope_name and so on
            const parentSource = possibleParentSources.emissionSources.find(
                (e) =>
                    e.name === item.parent_source_name.trim() &&
                    e.scope_name === item.parent_scope_name.trim() &&
                    e.category_name === item.parent_category_name.trim() &&
                    e.subcategory_name === item.parent_subcategory_name.trim()
            );
            // parent source is system managed
            if (parentSource?.system_managed === true) {
                result[key].error.push(
                    `Parent source ${item.parent_source_name.trim()} is system managed.`
                );
            }
            // if parent source is not found, or already a child throw error
            if (!parentSource) {
                result[key].error.push(
                    `Parent source ${item.parent_source_name.trim()} not found in the system. Please import parent source first.`
                );
            } else if (parentSource.parent_id !== null) {
                // parent's parent_id should be null, so circular reference is not possible
                result[key].error.push(
                    `Parent source ${item.parent_source_name.trim()} is already a child.`
                );
            } else {
                result[key].parent_id = parentSource.id;
            }
        }
        // else if any of the parent levels is defined, but not all of them, throw error
        else if (
            item.parent_scope_name !== '' ||
            item.parent_category_name !== '' ||
            item.parent_subcategory_name !== '' ||
            item.parent_source_name !== ''
        ) {
            result[key].error.push(
                `Parent source ${item.parent_source_name.trim()} is not defined properly.`
            );
        }

        // check base_unit difference
        if (sourceCache[key].base_unit && sourceCache[key].base_unit !== item.base_unit) {
            result[key].error.push(
                `Base unit difference for location ${item.location} between factors: ${sourceCache[key].base_unit} vs ${item.base_unit}`
            );
        }
        // check if source exists for factors
        if (sourceCache[key].source === '' || item.source === '') {
            result[key].error.push(`Source of the data is required`);
        }

        // handle factor parsing + location mapping
        try {
            const factors = parseFactors(item);

            // if use_as_global is defined, add it as location 0
            if (item.use_as_global !== '') {
                if (result[key].locations[1]) {
                    throw new Error('Global factor already defined in the same import!');
                }
                result[key].locations[1] = { location: 'World', ...factors };
            }

            // no location_id for this item
            if (loc === false) {
                throw new Error(`Location is not in the system: ${item.location}`);
            }
            if (result[key].locations[loc]) {
                throw new Error(`${item.location} factor already defined in the same import!`);
            }
            result[key].locations[loc] = { location: item.location, ...factors };
        } catch (err) {
            result[key].error.push(err.toString());
        }
    }

    return Object.values(result);
}

export async function getLocationMatrix(data) {
    // grab unique locations
    const locMatrix = {};
    const locations = [...new Set(data.map((e) => e.location))];
    if (data.filter((e) => e.use_as_global !== '') > 0) {
        // TODO: modify if the global name changes
        locMatrix.World = 1;
    }
    for (const loc of locations) {
        const serverLocations = await doSwaggerCall('Locations', 'getAllLocations', {
            search: loc,
            pageSize: 1000,
        });
        const existingLocation = serverLocations.locations.find((e) => e.name === loc);
        if (existingLocation) {
            locMatrix[loc] = existingLocation.id;
        } else {
            // no location found, note an error here.
            locMatrix[loc] = false;
        }
    }
    return locMatrix;
}

export async function doMasterImport(subcatMatrix, data, locMatrix, year, appendLog) {
    // add missing categories
    let subcatList = Object.values(subcatMatrix);
    for (let i = 0; i < subcatList.length; i++) {
        if (subcatList[i].category_id !== false) {
            // already has category id
            continue;
        }
        // new category
        const scope = parseInt(subcatList[i].scope_id, 10);
        const name = subcatList[i].category.trim();
        const { id } = await doSwaggerCall(
            'Categories',
            'addCategory',
            {},
            {
                name,
                scope,
            }
        );
        // update all matching
        subcatList = subcatList.map((e) =>
            e.category === name && parseInt(e.scope_id, 10) === scope
                ? { ...e, category_id: parseInt(id, 10) }
                : e
        );
        appendLog(`Created category ${name} in scope ${scope} with id: ${id}.`);
    }
    // add missing category
    for (let i = 0; i < subcatList.length; i++) {
        if (subcatList[i].subcategory_id !== false) {
            // already has subcategory id
            continue;
        }
        // new category
        const name = subcatList[i].subcategory.trim();
        const { id } = await doSwaggerCall(
            'Subcategories',
            'addSubcategory',
            { id: subcatList[i].category_id },
            {
                name,
            }
        );

        // update all matching
        subcatList = subcatList.map((e) =>
            parseInt(e.category_id, 10) === subcatList[i].category_id && e.subcategory === name
                ? { ...e, subcategory_id: parseInt(id, 10) }
                : e
        );
        appendLog(
            `Created subcategory ${name} in category ${subcatList[i].category} (#${subcatList[i].category_id}) with id: ${id}.`
        );
    }

    const newCategMatrix = {};
    subcatList.forEach((e) => {
        newCategMatrix[getCategoryKeyForItem(e)] = e;
    });

    // reload mappedData
    let mappedData = await extendLinesWithIds(data, newCategMatrix, locMatrix, year);

    // create sources that do not exist
    for (let i = 0; i < mappedData.length; i++) {
        if (mappedData[i].source_id !== false) {
            // source is already there
            continue;
        }
        const name = mappedData[i].source_name;
        const subcategoryId = parseInt(mappedData[i].subcategory_id, 10);
        const { id } = await doSwaggerCall(
            'EmissionSources',
            'addEmissionSource',
            {},
            {
                name,
                subcategoryId,
                source: mappedData[i].source,
                baseUnit: mappedData[i].base_unit,
                adminNote: mappedData[i].admin_note,
                guideNote: mappedData[i].guide_note,
                defaultCaltulationLogic: null,
                parentId: mappedData[i].parent_id || null,
            }
        );

        // update
        mappedData = mappedData.map((e) =>
            parseInt(e.subcategory_id, 10) === subcategoryId && e.source_name === name
                ? { ...e, source_id: parseInt(id, 10) }
                : e
        );
        appendLog(
            `Created source ${name} in subcategory ${mappedData[i].subcategory} (#${mappedData[i].subcategory_id}) with id: ${id}.`
        );
    }

    // updating factors
    for (let i = 0; i < mappedData.length; i++) {
        const sourceId = parseInt(mappedData[i].source_id, 10);
        const name = mappedData[i].source_name;
        const factors = Object.entries(mappedData[i].locations);
        for (let j = 0; j < factors.length; j++) {
            const [locId, factorData] = factors[j];
            await doSwaggerCall(
                'EmissionFactors',
                'addEmissionFactor',
                {},
                {
                    locationId: parseInt(locId, 10),
                    co2Factor: factorData.co2Factor,
                    ch4Factor: factorData.ch4Factor,
                    n2oFactor: factorData.n2oFactor,
                    year,
                    emissionSourceId: sourceId,
                    source: mappedData[i].source,
                    updateExisting: true,
                }
            );
            appendLog(`Update factor on source ${name} at location ${factorData.location}.`);
        }
    }
}
