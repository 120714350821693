import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Tooltip } from '@mui/material';
import GlobalCategoryFilter from '../components/header/GlobalCategoryFilter';
import GlobalEmissionSourceFilter from '../components/header/GlobalEmissionSourceFilter';
import GlobalScopeFilter from '../components/header/GlobalScopeFilter';
import GlobalSubcategoryFilter from '../components/header/GlobalSubcategoryFilter';
import { useGlobalFilters } from '../hooks/useGlobalFilters';
import { DATALINE_GROUPS } from '../helpers/groups';
import CommonButton from '../components/atom/CommonButton';

const CategoryFilterModal = ({ title, showModal, MODAL_TYPE, addDataline, setGroupby }) => {
    const { setSourceFilters, emissionSource, setEdited } = useGlobalFilters();

    const closeAction = () => showModal(MODAL_TYPE.NONE);

    const addAction = () => {
        showModal(MODAL_TYPE.NONE);
        setEdited(`new - ${emissionSource}`);
        setGroupby(DATALINE_GROUPS.NONE);
    };

    const handleClear = () => {
        setSourceFilters({
            scopeFilter: null,
            categoryFilter: null,
            subcategoryFilter: null,
            emissionSourceFilter: null,
        });
        setEdited(false);
    };

    return (
        <Dialog open onClose={closeAction} maxWidth="xs" fullWidth>
            <DialogTitle sx={{ alignSelf: 'center' }}>{title}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} sx={{ pt: 1 }}>
                    <Grid item xs={12}>
                        <GlobalScopeFilter />
                    </Grid>
                    <Grid item xs={12}>
                        <GlobalCategoryFilter />
                    </Grid>
                    <Grid item xs={12}>
                        <GlobalSubcategoryFilter />
                    </Grid>
                    <Grid item xs={12}>
                        <GlobalEmissionSourceFilter />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid container spacing={2}>
                    <Grid item xs={addDataline ? 4 : 6}>
                        <CommonButton
                            onClick={closeAction}
                            fullWidth
                            label="Close"
                            buttonType="secondary"
                        />
                    </Grid>
                    <Grid item xs={addDataline ? 4 : 6}>
                        <CommonButton
                            onClick={handleClear}
                            fullWidth
                            label="Clear"
                            buttonType="primary"
                        />
                    </Grid>
                    {addDataline && (
                        <Grid item xs={4}>
                            {!emissionSource ? (
                                <Tooltip title="Pick an emission source first!" placement="right">
                                    <span>
                                        <CommonButton
                                            fullWidth
                                            disabled
                                            label="Add dataline"
                                            buttonType="primary"
                                        />
                                    </span>
                                </Tooltip>
                            ) : (
                                <CommonButton
                                    onClick={addAction}
                                    fullWidth
                                    label="Add dataline"
                                    buttonType="primary"
                                />
                            )}
                        </Grid>
                    )}
                </Grid>
            </DialogActions>
        </Dialog>
    );
};

export default CategoryFilterModal;
