import React, { useCallback, useContext, useState } from 'react';

const EsgFilterContext = React.createContext();

export function EsgFilterContextProvider({ children }) {
    const [edited, setEdited] = useState(false);
    const [esgCategory, setEsgCategory] = useState(null);
    const [esgCategoryName, setEsgCategoryName] = useState(null);
    const [esgSubcategory, setEsgSubcategory] = useState(null);
    const [esgSubcategoryName, setEsgSubcategoryName] = useState(null);
    const [esgDisclosure, setEsgDisclosure] = useState(null);
    const [esgDisclosureName, setEsgDisclosureName] = useState(null);
    const [esgQuestion, setEsgQuestion] = useState(null);
    const [esgQuestionName, setEsgQuestionName] = useState(null);

    const setEsgFilters = useCallback(
        ({
            esgCategoryFilter,
            esgCategoryNameFilter,
            esgSubcategoryFilter,
            esgSubcategoryNameFilter,
            esgDisclosureFilter,
            esgDisclosureNameFilter,
            esgQuestionFilter,
            esgQuestionNameFilter,
        }) => {
            if (typeof esgCategoryFilter !== 'undefined') {
                setEsgCategory(esgCategoryFilter);
                setEsgCategoryName(esgCategoryNameFilter);
            }
            if (typeof esgSubcategoryFilter !== 'undefined') {
                setEsgSubcategory(esgSubcategoryFilter);
                setEsgSubcategoryName(esgSubcategoryNameFilter);
            }
            if (typeof esgDisclosureFilter !== 'undefined') {
                setEsgDisclosure(esgDisclosureFilter);
                setEsgDisclosureName(esgDisclosureNameFilter);
            }
            if (typeof esgQuestionFilter !== 'undefined') {
                setEsgQuestion(esgQuestionFilter);
                setEsgQuestionName(esgQuestionNameFilter);
            }
        },
        [setEsgCategory, setEsgSubcategory, setEsgDisclosure, setEsgQuestion]
    );

    return (
        <EsgFilterContext.Provider
            value={{
                esgCategory,
                esgCategoryName,
                esgSubcategory,
                esgSubcategoryName,
                esgDisclosure,
                esgDisclosureName,
                esgQuestion,
                esgQuestionName,
                setEsgFilters,
                edited,
                setEdited,
            }}
        >
            {children}
        </EsgFilterContext.Provider>
    );
}

EsgFilterContext.displayName = 'EsgFilterContext';

/**
 * Esg filters
 * @returns {{esgCategory, esgCategoryName, esgSubcategory, esgSubcategoryName, esgDisclosure, esgDisclosureName, esgQuestion, esgQuestionName, setEsgFilters: setEsgFilters, edited, setEdited}|unknown}
 */
export function useEsgFilters() {
    return useContext(EsgFilterContext);
}
