import { Grid, TextField } from '@mui/material';
import React, { useEffect } from 'react';
import { useImpSurveyStep, withFormikContext } from './ImpSurveyStep';
import SurveyQuestionHeadline from '../../survey/components/SurveyQuestionHeadline';
import ImpSurveySection from './ImpSurveySection';
import SurveyQuestionSubheader from './SurveyQuestionSubheader';
import { useApi } from '../../../hooks/useApi';
import { useGlobalFilters } from '../../../hooks/useGlobalFilters';

function QQuantificationMethod({
    values,
    setFieldValue,
    sectionIndex,
    formikKey,
    label,
    q,
    subheader,
}) {
    const { step, addFieldIntoStep } = useImpSurveyStep();
    const { year } = useGlobalFilters();
    const [data, loading, error] = useApi('Hierarchy', 'getSourceHierarchy', {
        year,
        hasanydatalines: true,
        hasFactor: true,
    });
    // get all categories and notes for corresponding sources
    const categories = data
        ?.flatMap((scope) => scope.children)
        .map((category) => ({
            name: category.name,
            notes: category.children
                .flatMap((subcat) => subcat.children)
                .flatMap((source) => source.admin_note)
                .filter(
                    (note, index, self) => note && self.indexOf(note) === index && note !== null
                ),
        }));

    useEffect(() => {
        addFieldIntoStep(`answers.${formikKey}`, sectionIndex + 1);
    }, [addFieldIntoStep, formikKey, sectionIndex]);

    if (step !== sectionIndex + 1) {
        return null;
    }

    const currentValue =
        values.answers[formikKey] === ''
            ? categories
                  ?.map((category) => `${category.name}: ${category.notes.join(', ')}`)
                  .join('\n')
            : values.answers[formikKey];

    return (
        <ImpSurveySection>
            <Grid item xs={12}>
                <SurveyQuestionHeadline>{q}</SurveyQuestionHeadline>
                {subheader && <SurveyQuestionSubheader subheader={subheader} />}
            </Grid>
            <Grid item xs={12}>
                {loading || error ? (
                    <div>{loading ? 'Loading...' : error}</div>
                ) : (
                    <TextField
                        fullWidth
                        autoComplete="off"
                        value={currentValue}
                        label={label}
                        onChange={(e) => {
                            setFieldValue(`answers.${formikKey}`, e.target.value);
                        }}
                        multiline
                    />
                )}
            </Grid>
        </ImpSurveySection>
    );
}

export default withFormikContext(QQuantificationMethod);
