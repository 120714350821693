import React, { useEffect, useState } from 'react';
import {
    DialogActions,
    DialogContentText,
    DialogContent,
    DialogTitle,
    Dialog,
    Grid,
    FormControlLabel,
    Checkbox,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Alert,
} from '@mui/material';
import {
    PERMISSIONS,
    PERMISSION_LABELS,
    PERMISSION_SETS,
    PERMISSION_SET_NAMES,
    ROLES,
} from '../helpers/constans';
import CommonButton from '../components/atom/CommonButton';

const ChangeRoleModal = ({
    title,
    content,
    userFeatureSet,
    userRole,
    confirm,
    showModal,
    MODAL_TYPE,
}) => {
    const closeAction = () => showModal(MODAL_TYPE.NONE);
    const [featureSet, setFeatureSet] = useState(userFeatureSet);
    const isSuperAdmin = userRole === ROLES.SUPERADMIN;
    const [predefinedRole, setPredefinedRole] = useState(PERMISSION_SET_NAMES.NORMAL_USER);

    useEffect(() => {
        let foundPermissionSet = false;
        Object.entries(PERMISSION_SET_NAMES).forEach((permissionSet) => {
            // temporary filter for view_report, since it is not in the permissions, but in the user permissions in database
            if (
                JSON.stringify(
                    featureSet.filter((f) => f !== 'view_report').sort((a, b) => a.localeCompare(b))
                ) ===
                JSON.stringify(PERMISSION_SETS[permissionSet[1]].sort((a, b) => a.localeCompare(b)))
            ) {
                foundPermissionSet = true;
                setPredefinedRole(PERMISSION_SET_NAMES[permissionSet[0]]);
            }
        });
        if (!foundPermissionSet) {
            setPredefinedRole(PERMISSION_SET_NAMES.CUSTOM);
        }
    }, [featureSet, setPredefinedRole]);

    return (
        <Dialog
            open
            onClose={closeAction}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <Grid item xs={12}>
                    <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
                </Grid>
                <Grid item xs={12} sx={{ mb: 2 }}>
                    <FormControl fullWidth>
                        <InputLabel>Predefined roles</InputLabel>
                        <Select
                            label="Roles"
                            value={predefinedRole}
                            onChange={(ev) => {
                                setFeatureSet(PERMISSION_SETS[ev.target.value]);
                                setPredefinedRole(ev.target.value);
                            }}
                            disabled={isSuperAdmin}
                        >
                            {Object.values(PERMISSION_SET_NAMES).map((preDefRole) => {
                                return <MenuItem value={preDefRole}>{preDefRole}</MenuItem>;
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sx={{ pb: 2 }}>
                    {isSuperAdmin && (
                        <Alert sx={{ mt: 0.5 }} severity="info">
                            This user is superadmin!
                        </Alert>
                    )}
                </Grid>
                <Grid item xs={12}>
                    Permissions:
                    {Object.values(PERMISSIONS).map((permission, idx) => (
                        <Grid item xs={12} key={idx}>
                            <FormControlLabel
                                label={PERMISSION_LABELS[permission]}
                                labelPlacement="end"
                                control={
                                    <Checkbox
                                        key={permission}
                                        checked={!!featureSet.find((c) => c === permission)}
                                        onChange={() => {
                                            setFeatureSet((old) => {
                                                const findCurrent = old.find(
                                                    (item) => item === permission
                                                );
                                                if (findCurrent) {
                                                    return old.filter(
                                                        (data) => data !== findCurrent
                                                    );
                                                }
                                                return [...old, permission];
                                            });
                                        }}
                                        disabled={isSuperAdmin}
                                    />
                                }
                            />
                        </Grid>
                    ))}
                    {(featureSet.includes(PERMISSIONS.MANAGE_DATALINES) ||
                        featureSet.includes(PERMISSIONS.BASIC_MODIFY_DATALINES) ||
                        featureSet.includes(PERMISSIONS.COMPLEX_MODIFY_DATALINES)) &&
                        !(
                            featureSet.includes(PERMISSIONS.VIEW_DATALINES) ||
                            featureSet.includes(PERMISSIONS.VIEW_OWNED) ||
                            featureSet.includes(PERMISSIONS.VIEW_ASSIGNED_TO)
                        ) && (
                            <Alert sx={{ mt: 0.5 }} severity="warning">
                                If you select a manage option, you should select a view option as
                                well!
                            </Alert>
                        )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <CommonButton
                    onClick={closeAction}
                    autoFocus
                    label="Cancel"
                    buttonType="secondary"
                />
                <CommonButton
                    onClick={() => {
                        confirm(featureSet);
                        showModal(MODAL_TYPE.NONE);
                    }}
                    label="Save"
                    buttonType="primary"
                />
            </DialogActions>
        </Dialog>
    );
};

export default ChangeRoleModal;
