import { InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { DATALINE_GROUPS } from '../../helpers/groups';

const SearchInput = ({
    search,
    setSearch,
    setParams,
    limit,
    fullWidth = false,
    groupby = false,
    setGroupby = false,
}) => {
    const handleClear = () => {
        setSearch('');
    };

    return (
        <TextField
            autoFocus
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment position="end">
                        <CloseIcon
                            size="small"
                            sx={{ visibility: search ? 'visible' : 'hidden', cursor: 'pointer' }}
                            onClick={handleClear}
                        />
                    </InputAdornment>
                ),
            }}
            placeholder="Search..."
            value={search || ''}
            size="small"
            autoComplete="off"
            onChange={(ev) => {
                if (setParams) {
                    setParams({ page: 0, limit });
                }
                setSearch(ev.target.value);
                if (setGroupby && groupby && groupby !== DATALINE_GROUPS.NONE) {
                    setGroupby(DATALINE_GROUPS.NONE);
                }
            }}
            fullWidth={fullWidth}
        />
    );
};
export default SearchInput;
