import { Alert, Grid, Paper } from '@mui/material';
import React from 'react';

export default function SurveySection({ active = true, validationErrors = [], children }) {
    const style = active
        ? { opacity: 1 }
        : { opacity: 0.5, filter: 'blur(4px)', maxHeight: 60, pointerEvents: 'none' };
    return (
        <Grid item xs={12} style={{ position: 'relative' }}>
            <Paper
                sx={{ p: 4 }}
                style={{
                    overflow: 'hidden',
                    border: validationErrors.length > 0 ? '1px solid #ff0000' : '1px solid #ccc',
                    boxShadow: '2px 2px 2px rgba(0,0,0,0.05)',
                }}
            >
                <Grid container style={{ ...style, transition: 'all 0.5s ease-in-out' }}>
                    {active ? children : null}
                </Grid>
            </Paper>
            {!active && (
                <Alert
                    severity="info"
                    size="small"
                    style={{
                        position: 'absolute',
                        top: '8px',
                        left: '48px',
                        zoom: '0.8',
                        opacity: 0.8,
                    }}
                >
                    This question is not relevant for your company.
                </Alert>
            )}
            {validationErrors.length > 0 && (
                <Alert
                    severity="error"
                    size="small"
                    style={{
                        position: 'absolute',
                        top: '18px',
                        right: '0px',
                        zoom: '0.8',
                        opacity: 0.8,
                        borderColor: 'red',
                    }}
                >
                    {validationErrors.map((error, idx) => {
                        // for space consideration, show only the first error
                        if (idx > 0) return null;
                        // eslint-disable-next-line react/no-array-index-key
                        return <div key={idx}>{error}</div>;
                    })}
                </Alert>
            )}
        </Grid>
    );
}
