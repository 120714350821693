import { Grid, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import CloseIcon from '@mui/icons-material/Close';
import { useAuth } from '../../hooks/useAuth';
import { useGuideBoxSidebar } from '../../hooks/useGuideBoxSidebar';
import { DrawerHeader } from './Drawer';
import BlockWithTitle from '../atom/BlockWithTitle';
import { useModals } from '../../hooks/useModal';
import { useDarkMode } from '../../hooks/useDarkMode';

const ModalGuideBox = ({ sidebarTitle, sidebarContent, closeSidebar }) => {
    const { darkMode } = useDarkMode();
    const color = darkMode ? '#ffffff' : '#072AC8';

    return (
        <Paper
            sx={{
                p: 2,
                position: 'fixed',
                height: '2O%',
                left: '10%',
                right: '10%',
                zIndex: 1301,
                bottom: '1%',
            }}
        >
            <Grid container item xs={12} justifyContent="space-between">
                <Grid item>
                    <Typography variant="subtitle1" sx={{ color, pb: 2 }}>
                        {sidebarTitle}
                    </Typography>
                </Grid>
                <Grid item>
                    <Tooltip title="Close guidance" placement="right">
                        <IconButton
                            disableRipple
                            onClick={() => closeSidebar()}
                            sx={{
                                color,
                                p: 0,
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
            <Grid
                item
                xs={12}
                style={{ maxHeight: '12vh', overflow: 'auto', scrollbarWidth: 'none' }}
            >
                {sidebarContent}
            </Grid>
        </Paper>
    );
};

export default function GuideBoxSidebar({ setMenuOpen, closeSidebar }) {
    const { user } = useAuth();
    const { sidebarTitle, sidebarContent, sidebarOpen } = useGuideBoxSidebar();
    const { currentlyShowingModal } = useModals();

    // closing the menu when the user clicks on the guide box
    useEffect(() => {
        if (sidebarOpen) {
            setMenuOpen(false);
        }
    }, [sidebarOpen, setMenuOpen]);

    return (
        <>
            <DrawerHeader
                style={{
                    borderBottom: '0',
                }}
            >
                <Grid
                    container
                    alignItems="end"
                    justifyContent="space-between"
                    flexDirection="column-reverse"
                >
                    <Grid item>
                        <Tooltip title="Close guidance sidebar" placement="right">
                            <IconButton onClick={() => closeSidebar()}>
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </DrawerHeader>
            {user.role && sidebarOpen && (
                <Grid
                    item
                    xs={12}
                    sx={{
                        flex: '1 1 0',
                        whiteSpace: 'pre-line',
                        wordBreak: 'break-word',
                    }}
                >
                    {currentlyShowingModal === 'ADD_DATALINE_WIZARD' ||
                    currentlyShowingModal === 'ESG_FILTER' ? (
                        createPortal(
                            <ModalGuideBox
                                closeSidebar={closeSidebar}
                                sidebarTitle={sidebarTitle}
                                sidebarContent={sidebarContent}
                            />,
                            document.body
                        )
                    ) : (
                        <BlockWithTitle title={sidebarTitle}>
                            <Grid item xs={12}>
                                {sidebarContent}
                            </Grid>
                        </BlockWithTitle>
                    )}
                </Grid>
            )}
        </>
    );
}
