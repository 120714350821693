import React, { useEffect, useState } from 'react';
import {
    Button,
    ButtonGroup,
    Grid,
    LinearProgress,
    Paper,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import useNotify from '../../../hooks/useNotify';
import { doSwaggerCall } from '../../../hooks/useApi';
import PieChart from './charts/PieChart';
import AutocompleteSelect from '../../../components/atom/AutoCompleteSelect';
import {
    categoryAutocomplete,
    categoryWithoutLimitationsAutocomplete,
} from '../../../autocomplete';
import { useGlobalFilters } from '../../../hooks/useGlobalFilters';
import getScopes from './helpers/getScopes';
import recursiveSumChildren from './helpers/recursiveSumChildren';

const equalArray = (a, b) => a.length === b.length && a.every((v, i) => v === b[i]);

function getScopeFromSelectedCategories(categoryId, allCategories) {
    const currentIdsSorted = [...categoryId].sort((a, b) => a - b);
    const scopeState = {};
    getScopes().forEach((scope) => {
        const filteredCategories = allCategories.filter((e) => e.scope_id === scope.id);
        const filteredCategoryIds = filteredCategories.map((e) => e.id);
        const filteredCurrentIds = currentIdsSorted.filter((id) =>
            filteredCategoryIds.includes(id)
        );
        const count = filteredCurrentIds.length;
        if (count === 0) {
            scopeState[scope.id] = 'none';
        } else if (count === filteredCategoryIds.length) {
            scopeState[scope.id] = 'all';
        } else {
            scopeState[scope.id] = 'some';
        }
        scopeState[`${scope.id}-count`] = count;
    });
    return scopeState;
}

const convertAndSetPiechart = (
    treeWithSum,
    idList,
    setPiechartCategoryData,
    setPiechartSubcategoryData
) => {
    const forPiechartCategory = [];
    const forPiechartSubcategory = [];

    treeWithSum.children?.forEach((scope) => {
        scope.children.forEach((category) => {
            const statisticsCategory = [];
            category.children
                .filter((e) => idList.includes(e.id))
                .forEach((subcategory) => {
                    statisticsCategory.push({
                        subcategory: subcategory.name,
                        sumOfCO2e: subcategory.sum,
                    });
                    const statisticsSubcategory = [];
                    subcategory.children.forEach((source) => {
                        statisticsSubcategory.push({
                            emissionSource: source.name,
                            sumOfCO2e: source.sum,
                        });
                    });
                    forPiechartSubcategory.push({
                        category: category.name,
                        subcategory: subcategory.name,
                        sum: category.sum,
                        statistics: statisticsSubcategory,
                    });
                });
            forPiechartCategory.push({
                category: category.name,
                sum: category.sum,
                statistics: statisticsCategory,
            });
        });
    });
    setPiechartCategoryData(forPiechartCategory.filter((e) => e.statistics.length !== 0));
    setPiechartSubcategoryData(forPiechartSubcategory.filter((e) => e.statistics.length !== 0));
};

const SubcategoryBreakdown = ({ tagFilter, yearSumData, fromOldDashboard = false }) => {
    const { notifyError } = useNotify();
    const { site } = useGlobalFilters();
    const [categoryIdList, setCategoryIdList] = useState([]);
    const [allCategories, setAllCategories] = useState([]);
    const [piechartCategoryData, setPiechartCategoryData] = useState([]);
    const [piechartSubcategoryData, setPiechartSubcategoryData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        doSwaggerCall('Categories', 'getAllCategories', {
            page: 0,
            pageSize: 1000,
        })
            .then((res) => {
                setAllCategories(res.categories.sort((a, b) => a.id - b.id));
            })
            .catch((error) => {
                notifyError(error);
            });
    }, []);

    const selectedCategories = getScopeFromSelectedCategories(categoryIdList, allCategories);

    const selectCategories = (scope) => {
        if (scope) {
            return allCategories.filter((e) => e.scope_id === scope).map((e) => e.id);
        }
        return allCategories.map((e) => e.id);
    };

    // Pie chart
    useEffect(() => {
        // extend the tree with sum
        const treeWithSum = recursiveSumChildren({ children: yearSumData });
        // use autocomplete to find which category need
        doSwaggerCall('Subcategories', 'getAllSubcategoriesByCategoryId', {
            idList: [categoryIdList],
            siteIdList: site,
        })
            .then((result) => {
                // convert and set PiechartData
                convertAndSetPiechart(
                    treeWithSum,
                    categoryIdList.length > 0 ? result.categoryIds : [], // category id list, empty array to init and reset state
                    setPiechartCategoryData,
                    setPiechartSubcategoryData
                );
            })
            .catch((error) => {
                notifyError(error);
            })
            .then(() => {
                setLoading(false);
            });
    }, [
        yearSumData,
        site,
        categoryIdList,
        setPiechartCategoryData,
        setPiechartSubcategoryData,
        setLoading,
        tagFilter,
    ]);

    return (
        <>
            <Grid item xs={12} md={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Paper>
                            <Grid container>
                                {fromOldDashboard && (
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle1">
                                            Subcategory breakdown
                                        </Typography>
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <AutocompleteSelect
                                        filterSelectedOptions
                                        initCall={categoryWithoutLimitationsAutocomplete.init}
                                        autocompleteCall={categoryAutocomplete.search}
                                        value={categoryIdList}
                                        setValue={(v) => setCategoryIdList(v)}
                                        multiple
                                        size="small"
                                    >
                                        <TextField label="Category" />
                                    </AutocompleteSelect>
                                </Grid>
                                <Grid item xs={12}>
                                    <ButtonGroup fullWidth className="darkModeGroup">
                                        <Button
                                            variant={
                                                equalArray(
                                                    categoryIdList.sort((a, b) => a - b),
                                                    allCategories
                                                        .sort((a, b) => a.id - b.id)
                                                        .map((e) => e.id)
                                                )
                                                    ? 'contained'
                                                    : 'outlined'
                                            }
                                            fullWidth
                                            onClick={() => {
                                                if (
                                                    equalArray(
                                                        categoryIdList.sort((a, b) => a - b),
                                                        allCategories.map((e) => e.id)
                                                    )
                                                )
                                                    setCategoryIdList([]);
                                                else {
                                                    setCategoryIdList(
                                                        allCategories.map((e) => e.id)
                                                    );
                                                }
                                            }}
                                        >
                                            All
                                        </Button>
                                        {getScopes()
                                            .sort((a, b) => a.order - b.order)
                                            .map((scope) => {
                                                return (
                                                    <Tooltip title={scope.name}>
                                                        <Button
                                                            variant={
                                                                selectedCategories[scope.id] ===
                                                                'all'
                                                                    ? 'contained'
                                                                    : 'outlined'
                                                            }
                                                            onClick={() => {
                                                                if (
                                                                    selectedCategories[scope.id] !==
                                                                    'all'
                                                                ) {
                                                                    setCategoryIdList([
                                                                        ...new Set([
                                                                            ...categoryIdList,
                                                                            ...selectCategories(
                                                                                scope.id
                                                                            ),
                                                                        ]),
                                                                    ]);
                                                                } else {
                                                                    setCategoryIdList([
                                                                        ...categoryIdList.filter(
                                                                            (e) =>
                                                                                !selectCategories(
                                                                                    scope.id
                                                                                ).includes(e)
                                                                        ),
                                                                    ]);
                                                                }
                                                            }}
                                                        >
                                                            {`${scope.shortName}`}
                                                            {selectedCategories[scope.id] === 'some'
                                                                ? ` (${
                                                                      selectedCategories[
                                                                          `${scope.id}-count`
                                                                      ]
                                                                  })`
                                                                : null}
                                                        </Button>
                                                    </Tooltip>
                                                );
                                            })}
                                    </ButtonGroup>
                                </Grid>
                            </Grid>
                        </Paper>
                        {loading ? <LinearProgress sx={{ mt: 1.25 }} /> : null}
                    </Grid>
                </Grid>
            </Grid>
            {piechartCategoryData.map((category, idx) => {
                return (
                    // eslint-disable-next-line react/no-array-index-key
                    <PieChart data={category} type="category" key={idx} />
                );
            })}
            {piechartSubcategoryData.map((subcategory, idx) => {
                return (
                    // eslint-disable-next-line react/no-array-index-key
                    <PieChart data={subcategory} type="subcategory" key={idx} />
                );
            })}
            <Grid container item xs={12} sx={{ m: 1, justifyContent: 'space-between' }}>
                <Typography variant="body2">
                    We do not display charts for subcategories with no data.
                </Typography>
                <Typography
                    variant="body2"
                    sx={{
                        fontStyle: 'italic',
                    }}
                >
                    All values are in CO2e (mt)
                </Typography>
            </Grid>
        </>
    );
};

export default SubcategoryBreakdown;
