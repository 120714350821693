import React, { useEffect, useState } from 'react';
import { Grid, LinearProgress, Paper, Typography } from '@mui/material';
import useNotify from '../../../hooks/useNotify';
import { doSwaggerCall } from '../../../hooks/useApi';
import { useGlobalFilters } from '../../../hooks/useGlobalFilters';
import LineChart from '../../dashboard/components/charts/LineChart';

const ReductionChart = ({
    scenarioName,
    baselineYear,
    targetYear,
    reduction,
    datalineTagId,
    siteFilter,
    categoryIdList,
    selectedScopes,
    isMultiple,
    targetYearData = false,
}) => {
    const { notifyError } = useNotify();
    const { year } = useGlobalFilters();
    const [linechartData, setLineChartData] = useState({ datalines: [] });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if ((baselineYear > 2000 && targetYear > baselineYear) || isMultiple) {
            doSwaggerCall('Scenarios', 'getScopesForScenario', {
                startYear: isMultiple ? baselineYear[0] : baselineYear,
                endYear: isMultiple ? targetYear[0] : targetYear,
                categoryIdList,
                datalineTagId: isMultiple ? datalineTagId[0] : datalineTagId,
                siteFilter: isMultiple ? siteFilter[0] : siteFilter,
            })
                .then((res) => {
                    // set linechartData
                    setLineChartData(res);
                    setLoading(false);
                })
                .catch((error) => {
                    notifyError(error);
                });
        }
    }, [
        year,
        baselineYear,
        targetYear,
        setLineChartData,
        setLoading,
        categoryIdList,
        datalineTagId,
        siteFilter,
    ]);

    return (
        <Grid container item xs={12} md={12} spacing={0} alignContent="flex-start">
            <Grid item xs={12}>
                <Paper sx={{ p: 2 }}>
                    <Grid item>
                        <Typography variant="h5">Reduction chart</Typography>
                    </Grid>
                    {linechartData?.datalines?.length === 0 ? (
                        <Grid item>
                            {loading ? (
                                <LinearProgress sx={{ mt: 0.25 }} />
                            ) : (
                                <h5>No data available</h5>
                            )}
                        </Grid>
                    ) : (
                        <LineChart
                            data={linechartData}
                            baselineYear={baselineYear}
                            targetYear={targetYear}
                            reduction={reduction}
                            selectedScopes={selectedScopes}
                            targetYearData={targetYearData}
                            scenarioName={scenarioName}
                            isMultiple={isMultiple}
                        />
                    )}
                </Paper>
            </Grid>
        </Grid>
    );
};

export default ReductionChart;
