import { Tooltip } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import React from 'react';
import { useDarkMode } from '../../hooks/useDarkMode';
import { useGuideBoxSidebar } from '../../hooks/useGuideBoxSidebar';
import { doSwaggerCall } from '../../hooks/useApi';
import useNotify from '../../hooks/useNotify';

const EsgHelperIcon = ({ title, guideNote, esgQuestionId }) => {
    const { darkMode } = useDarkMode();
    const { openSidebar } = useGuideBoxSidebar();
    const { notifyError } = useNotify();

    const handleClick = () => {
        try {
            doSwaggerCall('EsgQuestions', 'getEsgQuestionGuideNotes', {
                id: esgQuestionId,
            }).then((data) => {
                openSidebar({ title, content: data.guide_note || guideNote });
            });
        } catch (error) {
            notifyError(error);
        }
    };

    return (
        <sup>
            <Tooltip
                title="Click here to open guidance"
                sx={{ cursor: 'pointer' }}
                placement="right"
                onClick={handleClick}
            >
                <HelpOutlineIcon
                    style={{
                        fontSize: '15px',
                        color: !darkMode ? 'grey' : 'white',
                    }}
                />
            </Tooltip>
        </sup>
    );
};

export default EsgHelperIcon;
