import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Grid, Paper, Tooltip, Typography } from '@mui/material';
import { doSwaggerCall } from '../../hooks/useApi';
import PageWithTitle from '../../components/atom/PageWithTitle';
import useNotify from '../../hooks/useNotify';
import SurveyResultNetzero from './components/SurveyResultNetzero';
import SurveyResultScopes from './components/SurveyResultScopes';
import SurveyResultReport from './components/SurveyResultReport';
import SurveyResultDatalines from './components/SurveyResultDatalines';
import SurveyResultSummary from './components/SurveyResultSummary';

const MIN_SUPPORTED_SURVEY_VERSION_FOR_DETAILS = 3;

/** Survey results can be calculated in the following ways:
 *
 * - Zero target is totally independent from other survey results
 * 1. Supplier directly has company specific data (haveExactData === true)
 * 2. Supplier has report for scope 1 and 2 (hasScope12FullReport === true) but not or just partial for scope 3 (hasScope3Cat1Report === false || hasScope3Cat5Report === false)
 * 3. Supplier has nothing, we do calculate based on datalines (datalines.length > 0)
 */
function SupplierCo2Results({ survey }) {
    // 1. Supplier directly has company specific data (haveExactData === true)
    if (survey?.details?.haveExactData === true) {
        return <SurveyResultReport survey={survey} />;
    }
    // 2. Supplier has report for scope 3 (hasScope3Cat1Report === true && hasScope3Cat5Report === true OR datalines) and for scope 1 and 2 (hasScope12FullReport === true)
    if (
        survey?.details?.haveExactData === false &&
        survey?.details?.hasScope12FullReport === true
    ) {
        return <SurveyResultScopes survey={survey} />;
    }

    // Supplier has nothing, we do calculate based on datalines (datalines.length > 0)
    if (
        survey?.details?.haveExactData === false &&
        survey?.details?.hasScope12FullReport === false &&
        survey?.details?.datalines?.length > 0
    ) {
        return <SurveyResultDatalines survey={survey} />;
    }

    return <Grid item>Result has a strange combination, please contact support!</Grid>;
}

function SurveyDetailsPage() {
    const { surveyId } = useParams();
    const [loading, setLoading] = useState(true);
    const { notifyError, notifySuccess } = useNotify();
    const [survey, setSurvey] = useState(null);
    const [surveyClosed, setSurveyClosed] = useState(false);

    const loadSurvey = useCallback(() => {
        setLoading(true);
        setSurveyClosed(false);
        doSwaggerCall('Suppliers', 'getSupplierSurvey', { id: surveyId })
            .then((res) => {
                setSurvey({
                    id: res.id,
                    name: res.name,
                    year: res.year,
                    co2e: res.co2e,
                    netzero: res.netzero,
                    netzeroTargets: res.netzeroTargets,
                    details: res.details,
                    notes: res.notes,
                    step: res.details.step,
                    categoryId: res.categoryId,
                    reviewedBy: res.reviewedBy,
                    datalineId: res.datalineId,
                    // extras
                    locations: res.locations,
                    emissionSources: res.emissionSources,
                    // and everything else :D
                    ...res,
                });
                if (res.reviewedBy !== null && res.datalineId !== null) {
                    setSurveyClosed(true);
                }
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                notifyError(err);
            });
    }, [surveyId]);

    useEffect(() => {
        loadSurvey();
    }, [loadSurvey, surveyId]);

    // handle approve and close survey
    const handleApproveAndCloseSurvey = (id) => {
        setLoading(true);
        doSwaggerCall('Suppliers', 'approveAndCloseSurvey', { surveyId: id })
            .then(() => {
                setLoading(false);
                loadSurvey();
                notifySuccess('Survey approved and closed');
            })
            .catch((err) => {
                setLoading(false);
                notifyError(err);
            });
    };

    // handle reopen survey
    const handleReopenSurvey = (id) => {
        setLoading(true);
        doSwaggerCall('Suppliers', 'reopenSurvey', { surveyId: id })
            .then(() => {
                setLoading(false);
                loadSurvey();
                notifySuccess('Survey reopened and dataline deleted');
            })
            .catch((err) => {
                setLoading(false);
                notifyError(err);
            });
    };

    const checkClosedStatus = (surveyParam) => {
        if (
            surveyParam.reviewedBy &&
            surveyParam.datalineId &&
            surveyParam.reviewedBy !== null &&
            surveyParam.datalineId !== null
        ) {
            return 'closed';
        }
        return 'opened';
    };

    if (loading) {
        return true;
    }

    return (
        <PageWithTitle title={`Survey - ${survey.name} - ${survey.year}`}>
            {survey && (
                <>
                    {survey.details?.v < MIN_SUPPORTED_SURVEY_VERSION_FOR_DETAILS && (
                        <Grid item xs={12}>
                            <Typography variant="h4" color="error">
                                This survey was created with an old version of the survey tool and
                                might not be displayed correctly.
                            </Typography>
                        </Grid>
                    )}
                    <SurveyResultSummary survey={survey} status={checkClosedStatus(survey)} />
                    <Grid item xs={12}>
                        <Paper sx={{ p: 2, mt: 2 }}>
                            <Grid container spacing={2}>
                                <SurveyResultNetzero survey={survey} />
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper sx={{ p: 2, mt: 2 }}>
                            <Grid container spacing={2}>
                                <SupplierCo2Results survey={survey} />
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper sx={{ p: 2, mt: 2 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sx={{ pt: 4 }}>
                                    <Typography variant="subtitle1">Additional notes</Typography>
                                    {Object.entries(survey.notes).map((note) => (
                                        <Grid>
                                            {note[0][0].toUpperCase() + note[0].slice(1)}:
                                            <pre key={note}>{note[1] || '-'}</pre>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sx={{ pt: 2 }}>
                        {survey.categoryId === null || typeof survey.categoryId === 'undefined' ? (
                            <Tooltip title="The supplier category must be filled before the survey is approved and closed">
                                <span>
                                    <Button variant="contained" fullWidth disabled>
                                        {!surveyClosed
                                            ? 'Approve and close survey'
                                            : 'Reopen survey and delete dataline'}
                                    </Button>
                                </span>
                            </Tooltip>
                        ) : (
                            <Button
                                variant="contained"
                                fullWidth
                                onClick={() => {
                                    if (!surveyClosed) {
                                        handleApproveAndCloseSurvey(survey.id);
                                    } else {
                                        handleReopenSurvey(survey.id);
                                    }
                                }}
                            >
                                {!surveyClosed
                                    ? 'Approve and close survey'
                                    : 'Reopen survey and delete dataline'}
                            </Button>
                        )}
                    </Grid>
                </>
            )}
        </PageWithTitle>
    );
}

export default SurveyDetailsPage;
