import {
    Checkbox,
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip,
} from '@mui/material';
import React, { useCallback, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeselectIcon from '@mui/icons-material/Deselect';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import SkeletonTable from '../../../components/atom/SkeletonTable';
import { MODAL_TYPE, useModals } from '../../../hooks/useModal';
import { doSwaggerCall } from '../../../hooks/useApi';
import useNotify from '../../../hooks/useNotify';
import SortableTableCell from '../../../components/atom/SortableTableCell';
import { convertUnitGToKg, formatNumber } from '../../../helpers/convertUnits';
import { TABLE_ROW_COUNT_OPTIONS } from '../../../helpers/constans';
import { handleChangeRowsPerPage, handlePageChange } from '../../../helpers/handleTableFunctions';
import MultipleLineSelect from '../../../components/atom/MultipleLineSelect';
import TableSelectDropdown from './TableSelectDropdown';

const checkClosedStatus = (row) => {
    if (row.reviewedBy && row.datalineId && row.reviewedBy !== null && row.datalineId !== null) {
        return 'closed';
    }
    return 'opened';
};

const isNetZero = (netzero) => {
    if (netzero === 1) {
        return (
            <span style={{ display: 'flex', justifyContent: 'center' }}>
                <CheckIcon color="success" />
            </span>
        );
    }
    if (netzero === 0) {
        return (
            <span style={{ display: 'flex', justifyContent: 'center' }}>
                <CloseIcon color="error" />
            </span>
        );
    }
    return '?';
};

const SuppliersTable = ({
    rows,
    limit,
    page,
    setParams,
    total,
    loading,
    reloadData,
    sort,
    sortDir,
    setSorting,
    year,
    setSuppliersChanged,
}) => {
    const { showModal } = useModals();
    const { notifyError, notifySuccess } = useNotify();
    const [selectMode, setSelectMode] = useState(false);
    const navigate = useNavigate();
    const rowsIdList = rows?.map((r) => r.id);
    const [selectedSuppliers, setSelectedSuppliers] = useState([]);

    const handleSelectAll = (ev) => {
        if (ev.target.checked) {
            const newSelectedIds = rows.map((supplier) => supplier.id);
            setSelectedSuppliers(newSelectedIds);
            return;
        }
        setSelectedSuppliers([]);
    };

    const deleteSupplier = useCallback((id) => {
        setSuppliersChanged(false);
        doSwaggerCall('Suppliers', 'deleteSuppliersByList', { idList: [id] })
            .then(() => {
                notifySuccess('Supplier deleted');
                reloadData();
                setSuppliersChanged(true);
            })
            .catch((err) => {
                notifyError(err);
            });
    }, []);

    const sendSurveyIdList = async (supplierIdList) => {
        try {
            for (let i = 0; i < supplierIdList.length; i++) {
                // eslint-disable-next-line no-await-in-loop
                await doSwaggerCall(
                    'Surveys',
                    'sendSurvey',
                    {},
                    {
                        supplierId: supplierIdList[i],
                    }
                );
            }
            notifySuccess('The survey(s) has been successfully sent');
        } catch (error) {
            notifyError(error);
        }
    };

    const getContactsData = (selectedIds) => {
        const data = rows
            .filter((row) => selectedIds.includes(row.id))
            .map((row) => ({
                name: row.name,
                contacts: row.contacts,
            }));
        return data;
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Grid item xs={12}>
            <TableContainer component={Paper}>
                <Table aria-label="custom pagination table">
                    <TableHead>
                        <TableRow>
                            {selectMode && (
                                <TableCell>
                                    <Checkbox
                                        checked={
                                            rowsIdList?.length > 0 &&
                                            selectedSuppliers.length === rowsIdList.length
                                        }
                                        onChange={(ev) => handleSelectAll(ev)}
                                        indeterminate={
                                            selectedSuppliers.length > 0 &&
                                            selectedSuppliers.length < rowsIdList.length
                                        }
                                    />
                                </TableCell>
                            )}
                            <SortableTableCell
                                arrow={sort === 'name' ? sortDir : ''}
                                onClick={() => setSorting('name')}
                            >
                                Name
                            </SortableTableCell>
                            <SortableTableCell
                                arrow={sort === 'industry' ? sortDir : ''}
                                onClick={() => setSorting('industry')}
                            >
                                Industry
                            </SortableTableCell>
                            <SortableTableCell
                                arrow={sort === 'location' ? sortDir : ''}
                                onClick={() => setSorting('location')}
                            >
                                Location
                            </SortableTableCell>
                            <SortableTableCell
                                arrow={sort === 'co2e' ? sortDir : ''}
                                onClick={() => setSorting('co2e')}
                            >
                                CO<sub>2</sub>e (mt)
                            </SortableTableCell>
                            <SortableTableCell
                                arrow={sort === 'netzero' ? sortDir : ''}
                                onClick={() => setSorting('netzero')}
                            >
                                Netzero
                            </SortableTableCell>
                            <TableCell>Scopes</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell align="right">
                                {!selectMode ? (
                                    <MultipleLineSelect setSelectMode={setSelectMode} />
                                ) : (
                                    <div>
                                        <Tooltip title="Cancel selection">
                                            <IconButton
                                                onClick={() => {
                                                    setSelectMode(false);
                                                }}
                                            >
                                                <DeselectIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Select action">
                                            <IconButton onClick={handleClick}>
                                                <MoreHorizIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <TableSelectDropdown
                                            open={open}
                                            anchorEl={anchorEl}
                                            onClose={handleClose}
                                            reloadData={reloadData}
                                            selectedSuppliers={selectedSuppliers}
                                            setSelectedSuppliers={setSelectedSuppliers}
                                            setSuppliersChanged={setSuppliersChanged}
                                            sendSurveyIdList={sendSurveyIdList}
                                            year={year}
                                            rows={rows}
                                        />
                                    </div>
                                )}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <SkeletonTable rows={limit} cells={selectMode ? 9 : 8} />
                        ) : (
                            rows?.map((row) => (
                                <TableRow key={row.id}>
                                    {selectMode && (
                                        <TableCell>
                                            <Checkbox
                                                checked={selectedSuppliers.includes(row.id)}
                                                onChange={() =>
                                                    setSelectedSuppliers(
                                                        selectedSuppliers.includes(row.id)
                                                            ? selectedSuppliers.filter(
                                                                  (e) => e !== row.id
                                                              )
                                                            : [...selectedSuppliers, row.id]
                                                    )
                                                }
                                            />
                                        </TableCell>
                                    )}
                                    <TableCell>{row.name}</TableCell>
                                    <TableCell>{row.industry}</TableCell>
                                    <TableCell>{row.locationName}</TableCell>
                                    <TableCell>
                                        {formatNumber(row.co2e) === '?'
                                            ? '?'
                                            : formatNumber(convertUnitGToKg(row.co2e) / 1000)}
                                    </TableCell>
                                    <TableCell width="5%">{isNetZero(row.netzero)}</TableCell>
                                    <TableCell>
                                        {row.perScope
                                            ?.filter((s) => s.co2e > 0)
                                            .map((scope) => scope.scopeId)
                                            .join(', ')}
                                    </TableCell>
                                    <TableCell>{checkClosedStatus(row)}</TableCell>
                                    <TableCell align="right">
                                        <IconButton
                                            onClick={() => {
                                                showModal(MODAL_TYPE.CONFIRMATION_SEND_SURVEY, {
                                                    title: `Are you sure you want to send survey?`,
                                                    content: 'This action is irreversible.',
                                                    contactsData: getContactsData([row.id]),
                                                    confirm: () => sendSurveyIdList([row.id]),
                                                });
                                            }}
                                        >
                                            <Tooltip title="Send survey">
                                                <SendIcon />
                                            </Tooltip>
                                        </IconButton>
                                        <Link to={`/suppliers/edit/${row.id}`}>
                                            <Tooltip title="Edit">
                                                <IconButton>
                                                    <EditIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Link>
                                        <Tooltip title="Copy supplier">
                                            <IconButton
                                                onClick={() => {
                                                    showModal(MODAL_TYPE.COPY_SUPPLIER, {
                                                        title: `Copy and add as a new supplier`,
                                                        rows: [row],
                                                        year,
                                                        reloadData,
                                                    });
                                                }}
                                            >
                                                <ContentCopyIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <IconButton
                                            disabled={!row.co2e}
                                            onClick={() => {
                                                navigate(`/surveydetails/${row.id}`);
                                            }}
                                        >
                                            <Tooltip title="View survey">
                                                <RemoveRedEyeIcon />
                                            </Tooltip>
                                        </IconButton>
                                        <IconButton
                                            onClick={() => {
                                                showModal(MODAL_TYPE.CONFIRMATION, {
                                                    title: `Are you sure you want to delete this supplier?`,
                                                    content: 'This action is irreversible.',
                                                    confirm: () => deleteSupplier(row.id),
                                                });
                                            }}
                                        >
                                            <Tooltip title="Delete">
                                                <DeleteIcon />
                                            </Tooltip>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                count={total || 0}
                rowsPerPage={limit}
                component="div"
                page={page}
                onPageChange={(_, newPage) => handlePageChange(newPage, setParams, limit)}
                rowsPerPageOptions={TABLE_ROW_COUNT_OPTIONS}
                onRowsPerPageChange={(event) => handleChangeRowsPerPage(event, setParams)}
            />
        </Grid>
    );
};

export default SuppliersTable;
