import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import SourceLevel from './SourceLevel';
import CustomTreeItem from '../../atom/CustomTreeItem';
import transformTreeToFlat from '../../esgrequirementshierarchy/helpers/transformTreeToFlat';

function SubcategoryLevel({ checkedData, subcategory, setCheckedData, getCheckedChildrenNumber }) {
    const checkedChildren = getCheckedChildrenNumber(subcategory);
    const subcategoryLabel =
        checkedChildren === 0
            ? `${subcategory.name}`
            : `${subcategory.name} (+ ${checkedChildren} more)`;
    return (
        <CustomTreeItem
            nodeId={`subcategory - ${subcategory.id}`}
            label={
                <FormControlLabel
                    label={subcategoryLabel}
                    labelPlacement="end"
                    control={
                        <Checkbox
                            checked={
                                !!checkedData.find(
                                    (c) => c.id === subcategory.id && c.type === 'subcategory'
                                )
                            }
                            onChange={() => {
                                setCheckedData((old) => {
                                    const findCurrent = old.find(
                                        (item) =>
                                            item.id === subcategory.id &&
                                            item.type === 'subcategory'
                                    );
                                    if (findCurrent) {
                                        // find current item's children
                                        const children = transformTreeToFlat(subcategory.children);
                                        return old.filter(
                                            (data) =>
                                                data !== findCurrent &&
                                                !children.some(
                                                    (child) =>
                                                        child.id === data.id &&
                                                        child.type === data.type
                                                )
                                        );
                                    }
                                    return [
                                        ...old,
                                        {
                                            type: 'subcategory',
                                            id: subcategory.id,
                                        },
                                        ...transformTreeToFlat(subcategory.children),
                                    ];
                                });
                            }}
                        />
                    }
                />
            }
        >
            {subcategory.children.map((source) => (
                <SourceLevel
                    key={`${source.id} - ${source.name}`}
                    checkedData={checkedData}
                    source={source}
                    setCheckedData={setCheckedData}
                />
            ))}
        </CustomTreeItem>
    );
}

export default SubcategoryLevel;
