import React from 'react';
import {
    Grid,
    MenuItem,
    Select,
    TextField,
    FormControl,
    InputLabel,
    Tooltip,
    InputAdornment,
} from '@mui/material';
import {
    filterJsonConstantsByMeasure,
    unitConverter,
    WITHOUT_UNIT,
} from '../../../helpers/esgUnitConversions';

// check if the measure is visible, type is default to number since there is a case where we do not need this check
function isMeasureVisible(measure, defaultMeasure, type = 'number') {
    if (type === 'text') {
        return false;
    }
    if (measure && measure === WITHOUT_UNIT) {
        return false;
    }
    if (!measure && defaultMeasure === WITHOUT_UNIT) {
        return false;
    }
    return true;
}

const EsgQuestionField = ({ id, label, type, defaultMeasure, idx, values, setFieldValue }) => {
    return (
        <Grid item xs={12} key={id}>
            <TextField
                fullWidth
                autoComplete="off"
                label={label}
                type={type}
                value={values.values[idx]?.value || ''}
                onChange={(ev) => {
                    setFieldValue(`values[${idx}]`, {
                        id,
                        value: ev.target.value,
                        measure: values.values[idx]?.measure || defaultMeasure,
                    });
                }}
                InputProps={{
                    // if measure is text or without unit, do not show the endAdornment
                    endAdornment: isMeasureVisible(values.values[idx]?.measure, defaultMeasure) && (
                        <Grid item>
                            {!isMeasureVisible(
                                values.values[idx]?.measure,
                                defaultMeasure,
                                type
                            ) ? (
                                <Tooltip title="This unit is not editable" placement="top">
                                    <InputAdornment position="end">
                                        {values.values[idx]?.measure || defaultMeasure}
                                    </InputAdornment>
                                </Tooltip>
                            ) : (
                                <FormControl size="small" fullWidth>
                                    <InputLabel id="measure-select" />
                                    <Select
                                        labelId="measure-select"
                                        className="question-field"
                                        value={values.values[idx]?.measure || defaultMeasure}
                                        size="small"
                                        onChange={(e) => {
                                            setFieldValue(`values[${idx}]`, {
                                                id,
                                                value: unitConverter(
                                                    values.values[idx]?.value,
                                                    values.values[idx]?.measure || defaultMeasure,
                                                    e.target.value
                                                ),
                                                measure: e.target.value,
                                            });
                                        }}
                                    >
                                        {filterJsonConstantsByMeasure(
                                            values.values[idx]?.measure || defaultMeasure
                                        ).map((item) => (
                                            <MenuItem key={item.name} value={item.name}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}
                        </Grid>
                    ),
                }}
            />
        </Grid>
    );
};

export default EsgQuestionField;
