import { Button, Checkbox, FormControlLabel, Grid, TextField, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { KeyboardArrowLeft } from '@mui/icons-material';
import AddItemButton from '../../../components/atom/AddItemButton';
import AutocompleteSelect from '../../../components/atom/AutoCompleteSelect';
import { siteAutocomplete, userAutocomplete } from '../../../autocomplete';
import DocumentField from '../../../pages/datalineedit/components/DocumentField';
import { doSwaggerCall } from '../../../hooks/useApi';
import useNotify from '../../../hooks/useNotify';
import { useGlobalFilters } from '../../../hooks/useGlobalFilters';
import { ESG_GROUPS } from '../../../helpers/groups';
import { useEsgFilters } from '../../../hooks/useEsgFilters';
import CommonButton from '../../../components/atom/CommonButton';
import EsgQuestionField from './EsgQuestionField';
import EsgHelperIcon from '../../../components/atom/EsgHelperIcon';

function AddEsgForm({
    esgCategoryName,
    esgCategoryId,
    esgSubcategoryName,
    esgSubcategoryId,
    esgDisclosureName,
    esgDisclosureId,
    esgQuestionName,
    esgQuestionId,
    setFilterStep,
    closeAction,
    setGroupOpen,
    setGroupby,
}) {
    const { year } = useGlobalFilters();
    const { setEsgFilters } = useEsgFilters();

    const defaultEsg = {
        company_id: 1,
        year: 2022,
        esg_question_id: esgQuestionId,
        site_id: null,
        values: [],
        notes: null,
        assigned_user: null,
        owner_user: null,
        documents: null,
        guide_note: null,
        mark_completed: false,
    };

    const [showNotes, setShowNotes] = useState(false);
    const [showDocuments, setShowDocuments] = useState(false);
    const [showAssign, setShowAssign] = useState(false);
    const [showOwner, setShowOwner] = useState(false);
    const [loading, setLoading] = useState(true);
    const [esgQuestionValues, setEsgQuestionValues] = useState([]);
    const [esg, setEsg] = useState(defaultEsg);
    const { notifyError, notifySuccess } = useNotify();

    const addEsg = async (values, jump) => {
        const req = doSwaggerCall(
            'Esgs',
            'addEsg',
            {},
            {
                esgQuestionId,
                values: values.values,
                year,
                siteId: values.site_id,
                ownerUserId: values.owner_user?.user_id,
                assignedToUserId: values.assigned_user?.user_id,
                notes: values.notes,
                documentIdList: values.documents?.map((document) => document.id),
                markCompleted: values.mark_completed,
            }
        );
        req.then(() => {
            if (jump) {
                setEsgFilters({
                    esgCategoryFilter: esgCategoryId,
                    esgCategoryNameFilter: esgCategoryName,
                    esgSubcategoryFilter: esgSubcategoryId,
                    esgSubcategoryNameFilter: esgSubcategoryName,
                    esgDisclosureFilter: esgDisclosureId,
                    esgDisclosureNameFilter: esgDisclosureName,
                    esgQuestionFilter: esgQuestionId,
                    esgQuestionNameFilter: esgQuestionName,
                });
            }
            notifySuccess('Esg added successfully!');
            // remove this question from groupOpen, so reload problem is gone
            setGroupOpen((prev) => prev.filter((e) => e !== `${values.esg_question_id}-question`));
            setGroupby(ESG_GROUPS.NONE);
            closeAction();
        }).catch((err) => {
            notifyError(err);
        });
    };

    useEffect(() => {
        setLoading(true);
        doSwaggerCall('EsgQuestions', 'getEsgQuestion', { id: esgQuestionId })
            .then((res) => {
                // set esg's values to match the fields
                setEsg({
                    ...defaultEsg,
                    values: res.fields.map((field) => ({
                        id: field.id,
                        value: null,
                    })),
                });
                setEsgQuestionValues({
                    name: res.name,
                    fields: res.fields,
                    guide_note: res.guide_note,
                });
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                notifyError(err);
            });
    }, [esgQuestionId]);

    return (
        <Grid item>
            {!loading ? (
                <Formik onSubmit={addEsg} enableReinitialize initialValues={esg}>
                    {({ isSubmitting, values, setFieldValue, validateForm }) => (
                        <Form>
                            <Grid container spacing={2}>
                                <Grid item container spacing={2}>
                                    <Grid item sx={{ textAlign: 'center' }}>
                                        <Typography variant="h4" style={{ fontSize: '16px' }}>
                                            {`${esgCategoryName} - ${esgSubcategoryName} - ${esgDisclosureName} - ${esgQuestionName}`}
                                        </Typography>
                                    </Grid>
                                    <Grid item container justifyContent="space-between">
                                        <Grid item>
                                            <Grid item sx={{ pb: 1 }}>
                                                <Typography
                                                    variant="h4"
                                                    style={{ fontSize: '14px' }}
                                                >
                                                    Add new ESG
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="h6">
                                                    {esgQuestionName}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        {esgQuestionValues?.guide_note && (
                                            <Grid item>
                                                <EsgHelperIcon
                                                    title={esgQuestionValues.name}
                                                    guideNote={esgQuestionValues.guide_note}
                                                    esgQuestionId={esgQuestionId}
                                                />
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                                {esgQuestionValues?.fields?.map(
                                    ({ id, label, type, defaultMeasure }, idx) => (
                                        <EsgQuestionField
                                            id={id}
                                            label={label}
                                            type={type}
                                            defaultMeasure={defaultMeasure}
                                            idx={idx}
                                            values={values}
                                            setFieldValue={setFieldValue}
                                        />
                                    )
                                )}
                                <Grid item style={{ flex: '1 1 0' }}>
                                    <AutocompleteSelect
                                        size="small"
                                        initCall={siteAutocomplete.init}
                                        autocompleteCall={siteAutocomplete.search}
                                        value={values?.site_id}
                                        setValue={(ev) => {
                                            setFieldValue('site_id', ev);
                                        }}
                                    >
                                        <TextField label="Site" required />
                                    </AutocompleteSelect>
                                </Grid>
                                {showNotes && (
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            multiline
                                            autoComplete="off"
                                            name="notes"
                                            label="Notes"
                                            value={values.notes || ''}
                                            onChange={(ev) =>
                                                setFieldValue('notes', ev.target.value)
                                            }
                                        />
                                    </Grid>
                                )}
                                {showAssign && (
                                    <Grid item xs={6}>
                                        <AutocompleteSelect
                                            initCall={userAutocomplete.init}
                                            autocompleteCall={userAutocomplete.search}
                                            value={values.assigned_user?.user_id}
                                            setValue={(ev) => {
                                                setFieldValue('assigned_user.user_id', ev);
                                            }}
                                        >
                                            <TextField label="Assigned user" />
                                        </AutocompleteSelect>
                                    </Grid>
                                )}
                                {showOwner && (
                                    <Grid item xs={6}>
                                        <AutocompleteSelect
                                            initCall={userAutocomplete.init}
                                            autocompleteCall={userAutocomplete.search}
                                            value={values?.owner_user?.user_id}
                                            setValue={(ev) => {
                                                setFieldValue('owner_user.user_id', ev);
                                            }}
                                        >
                                            <TextField label="Owner" />
                                        </AutocompleteSelect>
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={!!values.mark_completed}
                                                onChange={(e) =>
                                                    setFieldValue(
                                                        'mark_completed',
                                                        !!e.target.checked
                                                    )
                                                }
                                            />
                                        }
                                        label="Mark completed"
                                        labelPlacement="end"
                                    />
                                </Grid>
                                {showDocuments && (
                                    <DocumentField
                                        values={values}
                                        setFieldValue={setFieldValue}
                                        isEsg
                                    />
                                )}
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        {!showNotes && (
                                            <AddItemButton
                                                label="Note"
                                                onClick={() => setShowNotes(true)}
                                            />
                                        )}
                                        {!showDocuments && (
                                            <AddItemButton
                                                label="Document"
                                                onClick={() => setShowDocuments(true)}
                                            />
                                        )}
                                        {!showAssign && (
                                            <AddItemButton
                                                label="Assign"
                                                onClick={() => setShowAssign(true)}
                                            />
                                        )}
                                        {!showOwner && (
                                            <AddItemButton
                                                label="Owner"
                                                onClick={() => setShowOwner(true)}
                                            />
                                        )}
                                    </Grid>
                                    <Grid container spacing={2} sx={{ pt: 2 }}>
                                        <Grid item xs={3}>
                                            <Button
                                                variant="outlined"
                                                fullWidth
                                                onClick={() => {
                                                    setFilterStep(true);
                                                }}
                                            >
                                                <KeyboardArrowLeft />
                                                Previous
                                            </Button>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <CommonButton
                                                fullWidth
                                                onClick={() => closeAction()}
                                                label="Cancel"
                                                buttonType="secondary"
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <CommonButton
                                                disabled={isSubmitting}
                                                onClick={(e) => {
                                                    validateForm()
                                                        .then(() => {
                                                            if (
                                                                e.target
                                                                    .closest('form')
                                                                    .checkValidity()
                                                            ) {
                                                                addEsg(values);
                                                            } else {
                                                                e.target
                                                                    .closest('form')
                                                                    .reportValidity();
                                                            }
                                                        })
                                                        .catch(() => {
                                                            e.target
                                                                .closest('form')
                                                                .reportValidity();
                                                        });
                                                }}
                                                fullWidth
                                                label="Save"
                                                buttonType="primary"
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <CommonButton
                                                // hack to validate form before submit
                                                onClick={(e) => {
                                                    validateForm()
                                                        .then(() => {
                                                            if (
                                                                e.target
                                                                    .closest('form')
                                                                    .checkValidity()
                                                            ) {
                                                                addEsg(values, true);
                                                            } else {
                                                                e.target
                                                                    .closest('form')
                                                                    .reportValidity();
                                                            }
                                                        })
                                                        .catch(() => {
                                                            e.target
                                                                .closest('form')
                                                                .reportValidity();
                                                        });
                                                }}
                                                fullWidth
                                                label="Save and jump to esg"
                                                buttonType="primary"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            ) : (
                <Typography>Loading...</Typography>
            )}
        </Grid>
    );
}

export default AddEsgForm;
