import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';
import React, { useState } from 'react';
import ExtraFieldSelector from '../components/extrafieldselector/ExtraFieldSelector';
import { isValidConfig } from '../helpers/dashboardPanelValidationHelper';
import CommonButton from '../components/atom/CommonButton';
import { DASHBOARD_MENU_ITEMS } from '../helpers/constans';

function AddDashboardPanelModal({
    showModal,
    MODAL_TYPE,
    confirm,
    panel = false,
    panelId = false,
}) {
    const closeAction = () => showModal(MODAL_TYPE.NONE);
    const [selectedPanel, setSelectedPanel] = useState(panel.visual_type);
    const [panelName, setPanelName] = useState(panel.name);
    const [panelDescription, setPanelDescription] = useState(panel.description);
    const [panelConfig, setPanelConfig] = useState(panel.config);

    return (
        <Dialog aria-labelledby="add-dashboard-panel-modal" open onClose={closeAction} fullWidth>
            <DialogTitle>{!panel ? 'Add' : 'Edit'} Dashboard Panel</DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid item xs={12}>
                        <DialogContentText id="alert-dialog-description">
                            Select a panel type that you want to display.
                        </DialogContentText>
                    </Grid>
                    <Grid container item xs={12}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                autoComplete="off"
                                required
                                value={panelName || ''}
                                label="Panel name"
                                onChange={(e) => {
                                    setPanelName(e.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                autoComplete="off"
                                value={panelDescription || ''}
                                label="Panel description"
                                onChange={(e) => {
                                    setPanelDescription(e.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth required>
                                <InputLabel id="simple-select-label">Panel type</InputLabel>
                                <Select
                                    labelId="simple-select-label"
                                    label="Panel type"
                                    value={selectedPanel || ''}
                                    onChange={(e) => {
                                        setSelectedPanel(e.target.value);
                                        setPanelConfig({});
                                    }}
                                >
                                    {DASHBOARD_MENU_ITEMS.map((item) => (
                                        <MenuItem key={item.value} value={item.value}>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                {item.icon}
                                                <div>&nbsp; {item.label}</div>
                                            </div>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <ExtraFieldSelector
                            selectedPanel={selectedPanel}
                            panelConfig={panelConfig}
                            setPanelConfig={setPanelConfig}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <CommonButton
                    onClick={() => showModal(MODAL_TYPE.NONE)}
                    label="Close"
                    buttonType="secondary"
                />
                <CommonButton
                    onClick={() => {
                        confirm(
                            {
                                name: panelName,
                                description: panelDescription,
                                visual_type: selectedPanel,
                                config: panelConfig,
                            },
                            panelId,
                            panelConfig,
                            setPanelConfig
                        );
                        showModal(MODAL_TYPE.NONE);
                    }}
                    type="submit"
                    disabled={
                        panelName === '' ||
                        typeof panelName === 'undefined' ||
                        !selectedPanel ||
                        !isValidConfig(selectedPanel, panelConfig)
                    }
                    label="Save"
                    buttonType="primary"
                />
            </DialogActions>
        </Dialog>
    );
}

export default AddDashboardPanelModal;
