import React, { useEffect, useState } from 'react';
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Divider,
    Grid,
    IconButton,
    TextField,
    Tooltip,
    MobileStepper,
    CardHeader,
    CircularProgress,
    InputAdornment,
} from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useApi } from '../../hooks/useApi';
import ComponentTitle from '../../components/ComponentTitle';
import useNotify from '../../hooks/useNotify';

const CarbonCreditPage = () => {
    const { notifyError } = useNotify();
    const [data, loading, error, reloadData] = useApi('CarbonCredit', 'getProducts', {});
    const navigate = useNavigate();
    const [quantity, setQuantity] = useState([]);
    const [activeSteps, setActiveSteps] = useState([]);

    useEffect(() => {
        if (data?.products) {
            setActiveSteps(data.products.map(() => 0));
        }
    }, [data]);

    const handleNext = (index) => {
        setActiveSteps((prevActiveSteps) =>
            prevActiveSteps.map((activeStep, i) => (i === index ? activeStep + 1 : activeStep))
        );
    };

    const handleBack = (index) => {
        setActiveSteps((prevActiveSteps) =>
            prevActiveSteps.map((activeStep, i) => (i === index ? activeStep - 1 : activeStep))
        );
    };

    // todo: check -> sometimes internal server error!
    useEffect(() => {
        if (error) {
            notifyError(error);
        }
    }, [error, notifyError]);

    return (
        <Grid container alignContent="flex-start">
            <Grid container item xs={12} justifyContent="space-between" spacing={2}>
                <Grid item>
                    <ComponentTitle variant="h4" title="Carbon credits" />
                </Grid>
                <Grid item container direction="row" alignItems="center">
                    {loading && (
                        <Grid item>
                            <CircularProgress color="primary" value={50} />
                        </Grid>
                    )}
                    {data?.products?.map((e, index) => (
                        <Grid item xs={6} md={6} key={e.id}>
                            <Card>
                                <CardHeader
                                    title={e.name}
                                    subheader={` ${e.credits.pricePerItem.amount} ${e.credits.pricePerItem.currency}`}
                                />
                                <CardMedia
                                    component="img"
                                    src={e.media.images[activeSteps[index]]?.url}
                                    height="200"
                                />
                                <CardContent>
                                    <MobileStepper
                                        style={{
                                            backgroundColor: 'transparent',
                                            border: 'none',
                                            justifyContent: 'space-between',
                                        }}
                                        variant="dots"
                                        steps={e.media.images.length}
                                        position="static"
                                        activeStep={activeSteps[index]}
                                        nextButton={
                                            <Button
                                                variant="text"
                                                size="small"
                                                onClick={() => handleNext(index)}
                                                disabled={
                                                    activeSteps[index] === e.media.images.length - 1
                                                }
                                            >
                                                Next
                                                <KeyboardArrowRight />
                                            </Button>
                                        }
                                        backButton={
                                            <Button
                                                size="small"
                                                variant="text"
                                                onClick={() => handleBack(index)}
                                                disabled={activeSteps[index] === 0}
                                            >
                                                <KeyboardArrowLeft />
                                                Back
                                            </Button>
                                        }
                                    />
                                </CardContent>
                                <Divider light />
                                <Grid container xs={12} style={{ alignItems: 'center' }}>
                                    <Grid item xs={6}>
                                        <CardContent>
                                            <b>In stock:</b>
                                            {` ${e.credits.stock.quantity} ${e.credits.stock.unit}`}
                                        </CardContent>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <CardActions>
                                            <Grid item xs={9}>
                                                <TextField
                                                    fullWidth
                                                    type="number"
                                                    label="Amount"
                                                    onChange={(ev) => {
                                                        const updatedQuantity = [...quantity];
                                                        const idx = updatedQuantity.findIndex(
                                                            (q) => q.id === e.id
                                                        );
                                                        if (idx === -1) {
                                                            updatedQuantity.push({
                                                                amount: ev.target.value,
                                                                id: e.id,
                                                            });
                                                        } else {
                                                            updatedQuantity[idx].amount =
                                                                ev.target.value;
                                                        }
                                                        setQuantity(updatedQuantity);
                                                    }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                {e.credits.stock.unit}
                                                            </InputAdornment>
                                                        ),
                                                        inputProps: {
                                                            min: 0,
                                                            max: parseInt(
                                                                e.credits.stock.quantity,
                                                                10
                                                            ),
                                                        },
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Tooltip title="Add to cart">
                                                    <IconButton
                                                        sx={{ ml: 2.25, mr: 1.5 }}
                                                        disabled={
                                                            !quantity.find((q) => q.id === e.id)
                                                                ?.amount ||
                                                            quantity.find((q) => q.id === e.id)
                                                                ?.amount < 1 ||
                                                            quantity.find((q) => q.id === e.id)
                                                                ?.amount >
                                                                parseInt(
                                                                    e.credits.stock.quantity,
                                                                    10
                                                                )
                                                        }
                                                        onClick={() => {
                                                            const amountData = quantity.find(
                                                                (q) => q.id === e.id
                                                            );
                                                            navigate(
                                                                `/carboncredit/buy?id=${
                                                                    e.id
                                                                }&amount=${parseInt(
                                                                    amountData.amount,
                                                                    10
                                                                )}`
                                                            );
                                                        }}
                                                    >
                                                        <ShoppingCartIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        </CardActions>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default CarbonCreditPage;
