import React, { useCallback, useEffect, useState } from 'react';
import {
    DialogActions,
    DialogContent,
    DialogTitle,
    Dialog,
    DialogContentText,
    Typography,
    CircularProgress,
    Box,
} from '@mui/material';
import { format } from 'date-fns';
import { doSwaggerCall } from '../hooks/useApi';
import useNotify from '../hooks/useNotify';
import CommonButton from '../components/atom/CommonButton';
import { useDarkMode } from '../hooks/useDarkMode';

const DialogHeader = ({ content }) => {
    const FIELDS = [
        {
            label: 'Date',
            value: ` ${format(new Date(content.created_at), 'MM/dd/yyyy HH:mm:ss a')}`,
        },
        { label: 'From', value: content.user_name || '-' },
        { label: 'To', value: content.to },
        { label: 'Subject', value: content.subject },
        { label: 'Result', value: content.result || '-' },
    ];
    return (
        <DialogContentText>
            {FIELDS.map(({ label, value }) => (
                <Typography key={label}>
                    <b>{label}:</b> {value}
                </Typography>
            ))}
        </DialogContentText>
    );
};

const EmailLogsModal = ({ title, content, reloadData, showModal, MODAL_TYPE }) => {
    const [loadingEmail, setLoadingEmail] = useState(true);
    const { notifyError, notifySuccess } = useNotify();
    const closeAction = () => showModal(MODAL_TYPE.NONE);
    const [email, setEmail] = useState(null);
    const [confirmState, setConfirmState] = useState(false);
    const { darkMode } = useDarkMode();
    const [sending, setSending] = useState(false);

    const loadEmail = useCallback(() => {
        setLoadingEmail(true);
        doSwaggerCall('Logs', 'getEmailLog', { id: content.id })
            .then((res) => {
                setEmail(res.html);
                setLoadingEmail(false);
            })
            .catch((err) => {
                setLoadingEmail(false);
                notifyError(err);
            });
    }, [content.id]);

    const resendEmail = async () => {
        setSending(true);
        try {
            await doSwaggerCall('Logs', 'resendEmail', { id: content.id });
            notifySuccess('Email has been sent again!');
            closeAction();
            reloadData();
            setSending(false);
        } catch (err) {
            notifyError(err);
            setSending(false);
        }
    };

    useEffect(() => {
        loadEmail();
    }, [content.id]);

    return (
        <Dialog
            open
            onClose={closeAction}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="xl"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogHeader content={content} />
                {loadingEmail ? (
                    <CircularProgress
                        style={{
                            position: 'absolute',
                            top: '80%',
                            left: '50%',
                            marginTop: '-20px',
                            marginLeft: '-20px',
                        }}
                    />
                ) : (
                    <iframe
                        title="HTML content for email"
                        style={{
                            width: '120vh',
                            height: '70vh',
                            border: '1px solid #D3D3D3',
                        }}
                        srcDoc={email}
                    />
                )}
            </DialogContent>
            <DialogActions>
                <CommonButton
                    onClick={closeAction}
                    autoFocus
                    label="Close"
                    buttonType="secondary"
                />
                <CommonButton
                    onClick={() => setConfirmState(true)}
                    label="Resend"
                    buttonType="primary"
                />
            </DialogActions>
            {confirmState && (
                <Box
                    sx={{
                        display: 'flex',
                        position: 'absolute',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: darkMode
                            ? 'rgba(4, 59, 92, .85)'
                            : 'rgba(232, 236, 241, .90)',
                        left: 0,
                        top: 0,
                        width: '100%',
                        height: '100%',
                    }}
                >
                    <Box textAlign="center">
                        <Typography variant="h6" sx={{ marginBottom: '2rem' }}>
                            Are you sure you want to resend this email?
                        </Typography>
                        <CommonButton
                            onClick={() => setConfirmState(false)}
                            label="Cancel"
                            buttonType="secondary"
                            sx={{ mr: '1rem' }}
                        />
                        <CommonButton
                            onClick={resendEmail}
                            disabled={sending}
                            label={sending ? 'Sending...' : 'Resend'}
                            buttonType="primary"
                        />
                    </Box>
                </Box>
            )}
        </Dialog>
    );
};

export default EmailLogsModal;
