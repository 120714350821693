import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { dashboardTemplateAutocomplete } from '../autocomplete';
import AutocompleteSelect from '../components/atom/AutoCompleteSelect';
import { doSwaggerCall } from '../hooks/useApi';
import useNotify from '../hooks/useNotify';
import CommonButton from '../components/atom/CommonButton';

function ImportDashboardTemplateModal({ reloadData, showModal, MODAL_TYPE }) {
    const closeAction = () => showModal(MODAL_TYPE.NONE);
    const { notifySuccess, notifyError } = useNotify();
    const [description, setDescription] = useState('');
    const [panels, setPanels] = useState([]);
    const navigate = useNavigate();

    const onSubmit = async (values) => {
        try {
            await doSwaggerCall(
                'Dashboards',
                'addDashboard',
                {},
                {
                    name: values.name,
                    description,
                    panels,
                }
            );
            navigate(`/dashboardtemplates`);
            notifySuccess('Dashboard imported!');
            showModal(MODAL_TYPE.NONE);
            reloadData();
        } catch (err) {
            notifyError(err);
        }
    };

    const validateSchema = Yup.object().shape({
        name: Yup.string().required('Required'),
        dashboard_id: Yup.string().required('Required'),
    });

    return (
        <Dialog
            aria-labelledby="import-dashboard-modal"
            open
            maxWidth="sm"
            onClose={closeAction}
            fullWidth
        >
            <DialogTitle>Import dashboard from template</DialogTitle>
            <Formik
                onSubmit={onSubmit}
                initialValues={{
                    name: '',
                    dashboard_id: '',
                }}
                validationSchema={validateSchema}
            >
                {({ isSubmitting, values, touched, errors, setFieldValue, isValid, dirty }) => (
                    <Form>
                        <Grid>
                            <DialogContent>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            autoComplete="off"
                                            label="Dashboard name"
                                            type="text"
                                            value={values.name}
                                            onChange={(ev) =>
                                                setFieldValue('name', ev.target.value)
                                            }
                                            error={touched.name && Boolean(errors.name)}
                                            helperText={touched.name ? errors.name : ''}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <AutocompleteSelect
                                            initCall={dashboardTemplateAutocomplete.init}
                                            autocompleteCall={dashboardTemplateAutocomplete.search}
                                            value={values.dashboard_id}
                                            // more than one setFieldValue is not working
                                            setValue={(ev, getItem) => {
                                                const item = getItem(ev);
                                                setFieldValue('dashboard_id', ev);
                                                // set dashboard description and panels from selected template
                                                setDescription(item?.description);
                                                setPanels(item?.panels);
                                            }}
                                            error={
                                                touched.dashboard_id && Boolean(errors.dashboard_id)
                                            }
                                            helperText={
                                                touched.dashboard_id ? errors.dashboard_id : ''
                                            }
                                        >
                                            <TextField label="Dashboard" />
                                        </AutocompleteSelect>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                        </Grid>
                        <DialogActions>
                            <CommonButton
                                onClick={() => showModal(MODAL_TYPE.NONE)}
                                label="Close"
                                buttonType="secondary"
                            />
                            <CommonButton
                                disabled={isSubmitting || !(isValid && dirty)}
                                type="submit"
                                label="Submit"
                                buttonType="primary"
                            />
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
}

export default ImportDashboardTemplateModal;
