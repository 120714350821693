import styled from '@emotion/styled';
import TreeItem, { useTreeItem } from '@mui/lab/TreeItem';
import { Typography } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

const CustomContentRoot = styled('div')(() => ({
    '&:hover, &.Mui-disabled, &.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused, &.Mui-selected:hover, &.MuiTreeItem-content:hover':
        {
            backgroundColor: 'transparent',
        },
}));

const CustomContent = React.forwardRef(function CustomContent(props, ref) {
    const { className, classes, label, nodeId, icon: iconProp, expansionIcon, displayIcon } = props;

    const { handleExpansion, handleSelection, preventSelection } = useTreeItem(nodeId);

    const icon = iconProp || expansionIcon || displayIcon;

    const handleMouseDown = (event) => {
        preventSelection(event);
    };

    const handleExpansionClick = (event) => handleExpansion(event);
    const handleClick = (event) => {
        handleSelection(event);
    };

    return (
        <CustomContentRoot
            className={clsx(className, classes.root)}
            onClick={handleClick}
            onMouseDown={handleMouseDown}
            ref={ref}
        >
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div onClick={handleExpansionClick} className={classes.iconContainer}>
                {icon}
            </div>
            <Typography component="div" className={classes.label}>
                {label}
            </Typography>
        </CustomContentRoot>
    );
});

const CustomTreeItem = (props) => <TreeItem ContentComponent={CustomContent} {...props} />;

export default CustomTreeItem;
