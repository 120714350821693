import React, { useEffect, useState } from 'react';
import { Grid, TextField, Paper, Container, Typography, IconButton, Tooltip } from '@mui/material';
import { Form, Formik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import * as Yup from 'yup';
import { doSwaggerCall } from '../../../hooks/useApi';
import useNotify from '../../../hooks/useNotify';
import { MODAL_TYPE, useModals } from '../../../hooks/useModal';
import { PERMISSION_SETS, PERMISSION_SET_NAMES } from '../../../helpers/constans';
import CommonButton from '../../../components/atom/CommonButton';

function CreateUserPage() {
    const navigate = useNavigate();
    const [permissions, setPermissions] = useState(
        PERMISSION_SETS[PERMISSION_SET_NAMES.NORMAL_USER]
    );
    const [predefinedRole, setPredefinedRole] = useState(PERMISSION_SET_NAMES.NORMAL_USER);
    const { notifySuccess, notifyError } = useNotify();
    const { companyId } = useParams();
    const { showModal } = useModals();

    const defaultUser = {
        name: '',
        email: '',
    };

    useEffect(() => {
        let foundPermissionSet = false;
        Object.entries(PERMISSION_SET_NAMES).forEach((permissionSet) => {
            if (
                JSON.stringify(permissions.sort((a, b) => a.localeCompare(b))) ===
                JSON.stringify(PERMISSION_SETS[permissionSet[1]].sort((a, b) => a.localeCompare(b)))
            ) {
                foundPermissionSet = true;
                setPredefinedRole(PERMISSION_SET_NAMES[permissionSet[0]]);
            }
        });
        if (!foundPermissionSet) {
            setPredefinedRole(PERMISSION_SET_NAMES.CUSTOM);
        }
    }, [setPredefinedRole, permissions]);

    const onSubmit = async (values) => {
        try {
            const user = await doSwaggerCall(
                'Users',
                'addUser',
                { id: companyId },
                {
                    name: values.name,
                    email: values.email,
                    featureSet: permissions,
                }
            );
            if (user.newUser) {
                await doSwaggerCall(
                    'Auth',
                    'resetDefaultPassword',
                    {},
                    {
                        email: values.email,
                        companyId,
                    }
                );
            }
            notifySuccess(
                'Invitation email has been sent, the user has been added to the company!'
            );
            navigate(`/companies/${companyId}/users`);
        } catch (error) {
            notifyError(error);
        }
    };

    const validateSchema = Yup.object().shape({
        name: Yup.string().required('Required'),
        email: Yup.string().email('Invalid email format').required('Required'),
    });

    return (
        <Formik
            onSubmit={onSubmit}
            validationSchema={validateSchema}
            initialValues={{ ...defaultUser }}
        >
            {({ isSubmitting, values, setFieldValue, touched, errors }) => (
                <Form>
                    <Container>
                        <Grid container>
                            <Grid item xs={6}>
                                <Paper sx={{ p: 2 }}>
                                    <Grid container>
                                        <Grid item>
                                            <Typography variant="h5">Invite new User</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                autoComplete="off"
                                                label="Name"
                                                value={values.name}
                                                onChange={(ev) =>
                                                    setFieldValue('name', ev.target.value)
                                                }
                                                error={touched.name && Boolean(errors.name)}
                                                helperText={touched.name ? errors.name : ''}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                autoComplete="off"
                                                label="Email"
                                                value={values.email}
                                                onChange={(ev) =>
                                                    setFieldValue('email', ev.target.value)
                                                }
                                                error={touched.email && Boolean(errors.email)}
                                                helperText={touched.email ? errors.email : ''}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            Role: {predefinedRole}
                                            <IconButton
                                                onClick={() => {
                                                    showModal(MODAL_TYPE.CHANGE_ROLE, {
                                                        title: `Select role for user`,
                                                        content:
                                                            'You can select predefined role or just select custom permissions.',
                                                        userFeatureSet: permissions,
                                                        userRole: 'user',
                                                        confirm: (featureSet) =>
                                                            setPermissions(featureSet),
                                                    });
                                                }}
                                            >
                                                <Tooltip title="Edit">
                                                    <EditIcon />
                                                </Tooltip>
                                            </IconButton>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <CommonButton
                                                fullWidth
                                                onClick={() =>
                                                    navigate(`/companies/${companyId}/users`)
                                                }
                                                label="Cancel"
                                                buttonType="secondary"
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <CommonButton
                                                fullWidth
                                                disabled={isSubmitting}
                                                type="submit"
                                                label="Send invitation"
                                                buttonType="primary"
                                            />
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Container>
                </Form>
            )}
        </Formik>
    );
}

export default CreateUserPage;
