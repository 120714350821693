import { TextField } from '@mui/material';
import React from 'react';
import { useGlobalFilters } from '../../hooks/useGlobalFilters';
import { subcategoryAutocomplete } from '../../autocomplete';
import AutocompleteSelect from '../atom/AutoCompleteSelect';
import { groupSubcategory } from '../../helpers/groups';

function GlobalSubcategoryFilter() {
    const { subcategory, setSourceFilters, scope, category, setEdited } = useGlobalFilters();
    return (
        <AutocompleteSelect
            grouping={groupSubcategory}
            initCall={subcategoryAutocomplete.init}
            autocompleteCall={subcategoryAutocomplete.search}
            value={subcategory}
            setValue={(ev, getItem) => {
                const item = getItem(ev);
                setSourceFilters({
                    scopeFilter: item?.scope_id,
                    scopeNameFilter: item?.scope_name || null,
                    categoryFilter: item?.category_id,
                    categoryNameFilter: item?.category_name || null,
                    subcategoryFilter: item?.id || null,
                    subcategoryNameFilter: item?.name || null,
                    emissionSourceFilter: null,
                });
                setEdited(false);
            }}
            searchParams={{
                scope,
                category,
                sourceLimitation: true,
            }}
        >
            <TextField label="Subcategory" fullWidth size="small" />
        </AutocompleteSelect>
    );
}

export default GlobalSubcategoryFilter;
