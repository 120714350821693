import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import DatalineSection from './DatalineSection';
import { tocToString } from '../../../helpers/groups';
import { useGlobalFilters } from '../../../hooks/useGlobalFilters';
import { TypographyAndGuideNote } from './TypographyAndGuideNote';
// import RequirementList from './RequirementList';

function DatalineGroup({ groupOpen, item, setGroupOpen, toc, edited, setEdited, ...restProps }) {
    const { scope, category, subcategory, emissionSource } = useGlobalFilters();
    const [filterCount, setFilterCount] = useState(
        [scope, category, subcategory, emissionSource].filter((e) => e > 0).length
    );
    const open = groupOpen.includes(`${item.id}-${item.type}`);
    const sectionHeader =
        (restProps.groupby === 'CATEGORY' && item.id !== 0 && item.type !== 'source') ||
        restProps.groupby === 'SITE'
            ? `${tocToString(toc)} ${item.section?.sectionHeader}`
            : `${item.section?.sectionHeader}`;

    const itemName =
        item.type === 'scope' || restProps.groupby === 'SITE'
            ? `${tocToString(toc)} ${item.name}`
            : item.name;
    const typography = item.id !== 0 ? itemName : `${item.name}`;

    const setOpen = (newOpen) => {
        if (newOpen === true) {
            setGroupOpen((old) => [...old, `${item.id}-${item.type}`]);
        } else {
            setGroupOpen((old) => old.filter((e) => e !== `${item.id}-${item.type}`));
        }
    };

    useEffect(() => {
        setFilterCount([scope, category, subcategory, emissionSource].filter((e) => e > 0).length);
    }, [scope, category, subcategory, emissionSource]);

    if (!open) {
        return (
            <Grid item xs={12}>
                <TypographyAndGuideNote
                    item={item}
                    typography={typography}
                    filterCount={filterCount}
                    open={open}
                    setOpen={setOpen}
                    onClick={() => setOpen(!open)}
                />
                {/* <RequirementList requirements={item.requirements} /> */}
            </Grid>
        );
    }

    if (item.section) {
        return (
            <DatalineSection
                {...restProps}
                {...item.section}
                sectionHeader={item.section?.sectionHeader === '' ? '' : sectionHeader}
                requirements={item.requirements}
                groupOpen={groupOpen}
                setGroupOpen={setGroupOpen}
                item={item}
                open={open}
                setOpen={() => setOpen(!open)}
                edited={edited}
                setEdited={setEdited}
            />
        );
    }

    if (open) {
        return (
            <>
                <Grid item xs={12}>
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <TypographyAndGuideNote
                                item={item}
                                typography={typography}
                                filterCount={filterCount}
                                open={open}
                                setOpen={setOpen}
                                onClick={() => {
                                    groupOpen.filter((idx) => idx !== item.id);
                                    setOpen(!open);
                                }}
                            />
                        </Grid>
                    </Grid>
                    {/* <RequirementList requirements={item.requirements} /> */}
                </Grid>
                {item.children && (
                    <Grid item xs={12}>
                        {item.children.map((subitem, idx) => {
                            return (
                                <Grid container spacing={0} key={idx}>
                                    <DatalineGroup
                                        groupOpen={groupOpen}
                                        item={subitem}
                                        setGroupOpen={setGroupOpen}
                                        {...restProps}
                                        toc={[...toc, idx + 1]}
                                        edited={edited}
                                        setEdited={setEdited}
                                    />
                                </Grid>
                            );
                        })}
                    </Grid>
                )}
            </>
        );
    }
    return null;
}

export default DatalineGroup;
