import {
    Box,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Tooltip,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import DataArrayIcon from '@mui/icons-material/DataArray';
import Co2Icon from '@mui/icons-material/Co2';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import BusinessIcon from '@mui/icons-material/Business';
import BarChartIcon from '@mui/icons-material/BarChart';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CategoryIcon from '@mui/icons-material/Category';
import PlaceIcon from '@mui/icons-material/Place';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import StoreIcon from '@mui/icons-material/Store';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import CalculateIcon from '@mui/icons-material/Calculate';
import GroupIcon from '@mui/icons-material/Group';
import HomeIcon from '@mui/icons-material/Home';
import StyleIcon from '@mui/icons-material/Style';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import DifferenceIcon from '@mui/icons-material/Difference';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import MessageIcon from '@mui/icons-material/Message';
import MailIcon from '@mui/icons-material/Mail';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import InventoryIcon from '@mui/icons-material/Inventory';
import { useAuth } from '../../hooks/useAuth';
import { PERMISSIONS, ROLES } from '../../helpers/constans';
import { CARBON_CREDIT_ENABLED, ESG_FEATURE_ENABLED, SUPPLIER_FEATURE_ENABLED } from '../../config';

function SiderbarMenuItem({ icon, title, open, ...otherProps }) {
    return (
        <ListItem disablePadding sx={{ display: 'block' }}>
            <Tooltip title={open ? '' : title} placement="right">
                <ListItemButton
                    {...otherProps}
                    sx={{
                        minHeight: 48,
                        px: 2.5,
                    }}
                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: 2,
                            justifyContent: 'center',
                        }}
                    >
                        {icon}
                    </ListItemIcon>
                    <ListItemText
                        primary={title}
                        sx={{ opacity: open ? 1 : 0, transition: 'opacity 0.3s' }}
                    />
                </ListItemButton>
            </Tooltip>
        </ListItem>
    );
}

export default function SidebarMenu({ open }) {
    const navigate = useNavigate();
    const { user, userPermissions } = useAuth();
    const dashboardAndReportPermission = userPermissions.includes(PERMISSIONS.VIEW_DASHBOARD);
    const datalinesPermission = userPermissions.includes(PERMISSIONS.VIEW_DATALINES);
    const assignedToPermission = userPermissions.includes(PERMISSIONS.VIEW_ASSIGNED_TO);
    const ownedPermission = userPermissions.includes(PERMISSIONS.VIEW_OWNED);
    const requirementsPermission = userPermissions.includes(PERMISSIONS.VIEW_REQUIREMENTS);
    const scenariosPermission = userPermissions.includes(PERMISSIONS.MANAGE_SCENARIOS);
    const companyPermission =
        userPermissions.includes(PERMISSIONS.MANAGE_COMPANY) && user.role === ROLES.USER;
    const companyPermissionOrSuperadmin = userPermissions.includes(PERMISSIONS.MANAGE_COMPANY);
    const suppliersPermission = userPermissions.includes(PERMISSIONS.MANAGE_SUPPLIERS);
    const esgsPermission = userPermissions.includes(PERMISSIONS.MANAGE_ESGS);

    return (
        <List style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
            {user.role && (
                <>
                    {dashboardAndReportPermission && (
                        <SiderbarMenuItem
                            open={open}
                            title="Dashboard"
                            icon={<BarChartIcon />}
                            onClick={() => {
                                navigate('/dashboard');
                            }}
                        />
                    )}
                    {datalinesPermission && (
                        <SiderbarMenuItem
                            open={open}
                            title="Enter Emissions Data"
                            icon={<DataArrayIcon />}
                            onClick={() => {
                                navigate('/datalines');
                            }}
                        />
                    )}
                    {assignedToPermission && (
                        <SiderbarMenuItem
                            open={open}
                            title="Emissions Data Assigned to Me"
                            icon={<AssignmentIcon />}
                            onClick={() => {
                                navigate('/datalines/assignedtome');
                            }}
                        />
                    )}
                    {ownedPermission && (
                        <SiderbarMenuItem
                            open={open}
                            title="Emissions Data Owned by Me"
                            icon={<AssignmentIcon />}
                            onClick={() => {
                                navigate('/datalines/ownedbyme');
                            }}
                        />
                    )}
                    {dashboardAndReportPermission && (
                        <SiderbarMenuItem
                            open={open}
                            title="Reporting"
                            icon={<FeedOutlinedIcon />}
                            onClick={() => {
                                navigate('/report');
                            }}
                        />
                    )}
                    {scenariosPermission && (
                        <SiderbarMenuItem
                            open={open}
                            title="Targets and planning"
                            icon={<TrendingDownIcon />}
                            onClick={() => {
                                navigate('/scenarios');
                            }}
                        />
                    )}
                    {companyPermission && (
                        <>
                            <SiderbarMenuItem
                                open={open}
                                title="Edit company"
                                icon={<BusinessIcon />}
                                onClick={() => {
                                    navigate(`/companies/edit/${user.company_id}`);
                                }}
                            />
                            <SiderbarMenuItem
                                open={open}
                                title="Edit company sites"
                                icon={<HomeIcon />}
                                onClick={() => {
                                    navigate(`/companies/${user.company_id}/sites`);
                                }}
                            />
                            <SiderbarMenuItem
                                open={open}
                                title="Edit company users"
                                icon={<GroupIcon />}
                                onClick={() => {
                                    navigate(`/companies/${user.company_id}/users`);
                                }}
                            />
                            <SiderbarMenuItem
                                open={open}
                                title="Variance tracker"
                                icon={<DifferenceIcon />}
                                onClick={() => {
                                    navigate(`/companies/${user.company_id}/variancetracker`);
                                }}
                            />
                        </>
                    )}
                    {companyPermissionOrSuperadmin && (
                        <SiderbarMenuItem
                            open={open}
                            title="Inventory Management Plan"
                            icon={<InventoryIcon />}
                            onClick={() => {
                                navigate(`/impsurveys`);
                            }}
                        />
                    )}
                    {requirementsPermission && (
                        <SiderbarMenuItem
                            open={open}
                            title="Progress Tracker"
                            icon={<AnnouncementIcon />}
                            onClick={() => {
                                navigate('/progresstracker');
                            }}
                        />
                    )}
                    {suppliersPermission && SUPPLIER_FEATURE_ENABLED && (
                        <SiderbarMenuItem
                            open={open}
                            title="Suppliers"
                            icon={<LocalShippingIcon />}
                            onClick={() => {
                                navigate('/suppliers');
                            }}
                        />
                    )}
                    {user.role === ROLES.SUPERADMIN && (
                        <>
                            <SiderbarMenuItem
                                open={open}
                                title="Companies"
                                icon={<BusinessIcon />}
                                onClick={() => {
                                    navigate('/companies');
                                }}
                            />
                            <SiderbarMenuItem
                                open={open}
                                title="Variance tracker"
                                icon={<DifferenceIcon />}
                                onClick={() => {
                                    navigate(`/companies/${user.company_id}/variancetracker`);
                                }}
                            />
                            <SiderbarMenuItem
                                open={open}
                                title="Company Tags"
                                icon={<StyleIcon />}
                                onClick={() => {
                                    navigate('/companytags');
                                }}
                            />
                            <SiderbarMenuItem
                                open={open}
                                title="Emission Sources"
                                icon={<Co2Icon />}
                                onClick={() => {
                                    navigate('/emissionsources');
                                }}
                            />
                            <SiderbarMenuItem
                                open={open}
                                title="Conversion logic templates"
                                icon={<CalculateIcon />}
                                onClick={() => {
                                    navigate('/conversionlogictemplates');
                                }}
                            />
                            <SiderbarMenuItem
                                open={open}
                                title="Edit Categories"
                                icon={<CategoryIcon />}
                                onClick={() => {
                                    navigate('/categories');
                                }}
                            />
                            <SiderbarMenuItem
                                open={open}
                                title="Edit Locations"
                                icon={<PlaceIcon />}
                                onClick={() => {
                                    navigate('/locations/1');
                                }}
                            />
                            <SiderbarMenuItem
                                open={open}
                                title="Audit logs"
                                icon={<MessageIcon />}
                                onClick={() => {
                                    navigate('/auditlogs');
                                }}
                            />
                            <SiderbarMenuItem
                                open={open}
                                title="Email logs"
                                icon={<MailIcon />}
                                onClick={() => {
                                    navigate('/emaillogs');
                                }}
                            />
                            {CARBON_CREDIT_ENABLED && (
                                <SiderbarMenuItem
                                    open={open}
                                    title="Carbon Credits"
                                    icon={<StoreIcon />}
                                    onClick={() => {
                                        navigate('/carboncredit');
                                    }}
                                />
                            )}
                            {ESG_FEATURE_ENABLED && (
                                <SiderbarMenuItem
                                    open={open}
                                    title="Esg Editor"
                                    icon={<CategoryOutlinedIcon />}
                                    onClick={() => {
                                        navigate('/esgcategories');
                                    }}
                                />
                            )}
                        </>
                    )}
                    {esgsPermission && ESG_FEATURE_ENABLED && (
                        <SiderbarMenuItem
                            open={open}
                            title="Esg"
                            icon={<QuestionAnswerIcon />}
                            onClick={() => {
                                navigate('/esg');
                            }}
                        />
                    )}
                    {esgsPermission && ESG_FEATURE_ENABLED && (
                        <SiderbarMenuItem
                            open={open}
                            title="Esg Progress Tracker"
                            icon={<AnnouncementIcon />}
                            onClick={() => {
                                navigate('/esgprogresstracker');
                            }}
                        />
                    )}
                    <Box style={{ flexGrow: 1 }} />
                    <SiderbarMenuItem
                        open={open}
                        title="Help"
                        icon={<HelpOutlineIcon />}
                        onClick={() => {
                            document.getElementById('zsiq_float').click();
                        }}
                    />
                </>
            )}
        </List>
    );
}
