import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ComponentTitle from '../../components/ComponentTitle';
import { useApi } from '../../hooks/useApi';
import useNotify from '../../hooks/useNotify';
import useSorting from '../../hooks/useSorting';
import NotificationsTable from './components/NotificationsTable';
import { TABLE_ROW_COUNT_DEFAULT } from '../../helpers/constans';

const NotificationsPage = () => {
    const { sort, sortDir, setSorting } = useSorting(['created_at', 'DESC']);
    const [{ limit, page }, setParams] = useState({ limit: TABLE_ROW_COUNT_DEFAULT, page: 0 });
    const { notifyError } = useNotify();

    const [data, loading, error, setData, reloadData] = useApi(
        'Notifications',
        'getNotifications',
        {
            pageSize: limit,
            page,
            sort,
            sortDir,
        }
    );

    useEffect(() => {
        if (error) {
            notifyError(error);
        }
    }, [error, notifyError]);

    return (
        <Grid container alignContent="flex-start">
            <Grid container item xs={12} justifyContent="space-between" spacing={0}>
                <Grid item>
                    <ComponentTitle variant="h4" title="Notifications" />
                </Grid>
            </Grid>
            <NotificationsTable
                rows={data?.notifications}
                loading={loading}
                total={data?.total}
                limit={limit}
                page={page}
                reloadData={reloadData}
                setParams={setParams}
                sort={sort}
                sortDir={sortDir}
                setSorting={setSorting}
            />
        </Grid>
    );
};

export default NotificationsPage;
