import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import CustomTreeItem from '../../../../components/atom/CustomTreeItem';
import SourcePickerSubcategoryLevel from './SourcePickerSubcategoryLevel';

function SourcePickerCategoryLevel({
    checkedData,
    category,
    scope,
    setCheckedData,
    setCheckedChildren,
    getCheckedChildrenNumber,
}) {
    const checkedChildren = getCheckedChildrenNumber(category);
    const categoryLabel =
        checkedChildren === 0 ? `${category.name}` : `${category.name} (+ ${checkedChildren} more)`;
    return (
        <CustomTreeItem
            nodeId={`category - ${category.id}`}
            label={
                <FormControlLabel
                    label={categoryLabel}
                    labelPlacement="end"
                    control={
                        <Checkbox
                            key={`${category.id} - ${category.name}`}
                            checked={
                                !!checkedData.find(
                                    (c) => c.id === scope.id && c.type === 'scope'
                                ) || // if scope is checked each category is selected
                                !!checkedData.find(
                                    (c) => c.id === category.id && c.type === 'category'
                                )
                            }
                            // if scope is checked each category is selected and categories are disabled
                            disabled={
                                !!checkedData.find((c) => c.id === scope.id && c.type === 'scope')
                            }
                            onChange={() => {
                                setCheckedData((old) => {
                                    const findCurrent = old.find(
                                        (item) =>
                                            item.id === category.id && item.type === 'category'
                                    );
                                    if (findCurrent) {
                                        return old.filter((data) => data !== findCurrent);
                                    }
                                    const res = setCheckedChildren(category, old);
                                    return [
                                        ...res,
                                        {
                                            type: 'category',
                                            id: category.id,
                                        },
                                    ];
                                });
                            }}
                        />
                    }
                />
            }
        >
            {category.children.map((subcategory) => (
                <SourcePickerSubcategoryLevel
                    key={`${subcategory.id} - ${subcategory.name}`}
                    scope={scope}
                    category={category}
                    subcategory={subcategory}
                    checkedData={checkedData}
                    setCheckedData={setCheckedData}
                    setCheckedChildren={setCheckedChildren}
                    getCheckedChildrenNumber={getCheckedChildrenNumber}
                />
            ))}
        </CustomTreeItem>
    );
}

export default SourcePickerCategoryLevel;
