import React, { useEffect, useState } from 'react';
import { useQueryParam, withDefault, StringParam } from 'use-query-params';
import { Grid } from '@mui/material';
import ScenariosTable from '../components/ScenariosTable';
import { doSwaggerCall } from '../../../hooks/useApi';
import useNotify from '../../../hooks/useNotify';
import useSorting from '../../../hooks/useSorting';
import ReductionChart from '../components/ReductionChart';

function MultipleScenarioPage() {
    const { notifyError } = useNotify();
    const [loading, setLoading] = useState(true);
    const [scenarios, setScenarios] = useState([]);
    const { sort, sortDir, setSorting } = useSorting(['created_at', 'ASC']);
    const [idList] = useQueryParam('id', withDefault(StringParam, ''));

    useEffect(() => {
        const scenarioIdNumbers = idList
            .split(',')
            .map((e) => parseInt(e, 10))
            .filter((e) => !Number.isNaN(e));
        doSwaggerCall('Scenarios', 'getScenarios', {
            // TODO: fix limit later
            limit: 1000,
            offset: 0,
            sort,
            sortDir,
            scenarioIdList: scenarioIdNumbers,
        })
            .then((res) => {
                setScenarios(res);
                setLoading(false);
            })
            .catch((error) => {
                notifyError(error);
            });
    }, [setScenarios, setLoading, idList, sort, sortDir, notifyError]);

    return (
        <Grid item xs={12} sx={{ p: '16px 24px 8px' }}>
            <Grid item sx={{ mb: 2 }}>
                {!loading && (
                    <ReductionChart
                        scenarioName={scenarios?.scenarios?.map((e) => e.name)}
                        baselineYear={scenarios?.scenarios?.map((e) => e.baseline_year)}
                        targetYear={scenarios?.scenarios?.map((e) => e.target_year)}
                        reduction={scenarios?.scenarios?.map((e) => e.target_reduction)}
                        datalineTagId={scenarios?.scenarios?.map((e) => e.dataline_tag_id)}
                        siteFilter={scenarios?.scenarios?.map((e) => e.site_filter)}
                        categoryIdList={scenarios?.scenarios?.map((e) => [
                            ...e.target_categories,
                            ...e.target_categories_by_scopes,
                        ])}
                        selectedScopes={scenarios?.scenarios?.map((e) => [
                            ...e.target_scopes,
                            ...e.target_scopes_by_categories,
                        ])}
                        isMultiple
                        targetYearData={scenarios?.scenarios?.map((e) => e.target_scopes_hierarchy)}
                    />
                )}
            </Grid>
            <ScenariosTable
                rows={scenarios?.scenarios}
                total={scenarios?.total}
                loading={loading}
                selectMode={false}
                setSelectMode={false}
                selectedScenarios={false}
                setSelectedScenarios={false}
                sort={sort}
                sortDir={sortDir}
                setSorting={setSorting}
                multipleMode
            />
        </Grid>
    );
}

export default MultipleScenarioPage;
