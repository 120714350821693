import React, { useEffect, useState } from 'react';
import { hotjar } from 'react-hotjar';
import { useTheme } from '@emotion/react';
import { HOTJAR_ID, HOTJAR_SV } from '../../config';

// #Source https://bit.ly/2neWfJ2
const parseCookie = (str) =>
    str
        .split(';')
        .map((v) => v.split('='))
        .reduce((acc, v) => {
            if (v.length !== 2) {
                return acc;
            }
            acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim());
            return acc;
        }, {});

function injectDoneCookieTool(policy_url, color, cookieGroups) {
    window.DONEGDPRParams = {
        lang: 'en',
        policy_url,
        color,
        groups: cookieGroups,
    };

    const jsUrl = 'https://cdn.thisisdone.com/gdpr/1.3.latest/cookie.min.js';
    if (!document.querySelector(`script[src*="${jsUrl}"]`)) {
        const a = document.getElementsByTagName('head')[0];
        const r = document.createElement('script');
        r.async = 1;
        r.src = jsUrl;
        a.appendChild(r);
    }

    // hide cookie tool on print
    const style = document.createElement('style');
    style.setAttribute('type', 'text/css');
    style.innerHTML = `
        @media print {
            .done-gdpr-alert-box-wrapper {
                display: none;
            }
        }
    `;
    document.head.appendChild(style);
}
const CookieBar = () => {
    // local storage change listener for cookie tool
    const [concent, setConcet] = useState({});
    useEffect(() => {
        if (Object.keys(concent).length > 0) {
            return;
        }
        let checker = null;
        function checkCookie() {
            const cookies = parseCookie(document.cookie);
            if (cookies && cookies.DONEGDPRConsent) {
                try {
                    setConcet(JSON.parse(cookies.DONEGDPRConsent));
                } catch (err) {
                    // nothing
                }
                clearInterval(checker);
                return true;
            }
            return false;
        }
        if (checkCookie()) {
            return;
        }
        checker = setInterval(checkCookie, 500);
        return () => {
            clearInterval(checker);
        };
    }, [concent, setConcet]);

    // TODO: config list of cookies
    const theme = useTheme();
    injectDoneCookieTool(
        `https://www.northstarcarbon.com/privacy-policy/`,
        theme.palette.primary.main,
        {
            always_active: [{ name: 'Page Cookie Consent' }],
            performance: [{ name: 'Sentry' }], // TODO: double check this
            functional: [{ name: 'Hotjar' }, { name: 'LS_CSRF_TOKEN' }],
            targeting: [{ name: '_zldp' }, { name: '_zldt' }],
        }
    );

    // handle cookie settings
    if (concent && concent.functional === true && HOTJAR_ID && HOTJAR_SV) {
        hotjar.initialize(HOTJAR_ID, HOTJAR_SV);
    }

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
};

export default CookieBar;
