import React, { useEffect } from 'react';
import { Alert, Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import EmissionSourceForm from './components/EmissionSourceForm';
import ConversionLogicForm from './components/ConversionLogicForm';
import EmissionFactorForm from './components/EmissionFactorForm';
import { doSwaggerCall, useApi } from '../../hooks/useApi';
import useNotify from '../../hooks/useNotify';
import PageWithTitle from '../../components/atom/PageWithTitle';
import { MODAL_TYPE, useModals } from '../../hooks/useModal';
import ConversionLogicTemplates from './components/ConversionLogicTemplates';
import { WARNING_CONTENT_RECALCULATE_TEXT } from '../../helpers/constans';

/**
 * Merge data from parent and child conversion logic
 * @param parentConversionLogic
 * @param childConversionLogic
 * @returns {*}
 */
function mergeData(parentConversionLogic, childConversionLogic) {
    return parentConversionLogic.map((parentLogic) => {
        const childLogic = childConversionLogic.find((u) => u.name === parentLogic.name);

        if (!childLogic) {
            return { ...parentLogic };
        }

        const fieldsMatch =
            JSON.stringify(parentLogic.fields) === JSON.stringify(childLogic.fields);

        if (!fieldsMatch) {
            return {
                ...parentLogic,
                admin_note: childLogic.admin_note,
                formula: childLogic.formula,
                source: childLogic.source,
            };
        }
        return childLogic;
    });
}

/**
 * Merge data from parent and child conversion logic, which internal loading state
 * @param values
 * @param setFieldValue
 * @param notifyError
 * @returns {null}
 * @constructor
 */
function ParentDataMerger({ values, setFieldValue, notifyError }) {
    // Trigger a perent data load if the parent_id changes
    useEffect(() => {
        const parent = values?.parent_id;
        if (!parent) {
            return;
        }
        setFieldValue('unit_conversion_logic_loading', true);
        doSwaggerCall('EmissionSources', 'getEmissionSource', {
            id: parent,
        })
            .then((res) => {
                setFieldValue(
                    'unit_conversion_logic',
                    mergeData(res?.unit_conversion_logic || [], values?.unit_conversion_logic || [])
                );
                setFieldValue('unit_conversion_logic_loading', false);
            })
            .catch((err) => {
                notifyError(err);
                setFieldValue('unit_conversion_logic_loading', false);
            });
    }, [setFieldValue, values?.parent_id]);
    return null;
}

const validateSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    base_unit: Yup.string().required('Required'),
    unit_conversion_logic: Yup.array().of(
        Yup.object().shape({
            name: Yup.string().required('Required'),
            formula: Yup.string().required('Required'),
            fields: Yup.array().of(
                Yup.object().shape({
                    field_id: Yup.string().required('Required'),
                    label: Yup.string().required('Required'),
                })
            ),
        })
    ),
});

const trimFieldIds = (unitConversionLogic) => {
    const trimmedUnitConversionLogic = unitConversionLogic;
    trimmedUnitConversionLogic.map((logic, logicIdx) => {
        logic.fields.map((field, fieldIdx) => {
            trimmedUnitConversionLogic[logicIdx].fields[fieldIdx].field_id =
                field?.field_id?.trim();
            return true;
        });
        return true;
    });
    return trimmedUnitConversionLogic;
};

const EmissionSourceEditPage = () => {
    const { notifyError, notifySuccess } = useNotify();
    const { showModal } = useModals();
    const { emissionSourceId } = useParams();
    const navigate = useNavigate();
    const [data, loading, error, setData, reloadData] = useApi(
        'EmissionSources',
        'getEmissionSource',
        {
            id: emissionSourceId,
        }
    );
    if (error) {
        notifyError(error);
        navigate('/emissionsources');
    }

    // TODO: This one is just a dirty fix for some horrible timing issues
    if (loading) {
        return null;
    }

    const onSubmit = async (values) => {
        const req = doSwaggerCall(
            'EmissionSources',
            'editEmissionSource',
            { id: emissionSourceId },
            {
                name: values.name.trim(),
                subcategoryId: parseInt(values.subcategory_id, 10),
                source: values.source,
                adminNote: values.admin_note,
                guideNote: values.guide_note,
                baseUnit: values.base_unit,
                unitConversionLogic: values.unit_conversion_logic,
                defaultCalculationLogic:
                    values.default_calculation_logic === 0
                        ? null
                        : values.default_calculation_logic,
                parentId: values.parent_id,
            }
        );
        req.then(() => {
            reloadData();
            notifySuccess('Emission source edited');
        }).catch((err) => notifyError(err));
    };

    const addNewConversionLogic = async (values) => {
        const newEmptyLogic = {
            id: parseInt(Math.floor(Math.random() * 1000), 10),
            name: 'New calculation',
            source: null,
            admin_note: null,
            formula: null,
            fields: [],
        };
        const editUnitConversionLogic = [...values.unit_conversion_logic, newEmptyLogic];

        doSwaggerCall(
            'EmissionSources',
            'editEmissionSourceConversionLogic',
            { id: emissionSourceId },
            {
                unitConversionLogic: editUnitConversionLogic,
                parentId: values.parent_id,
            }
        )
            .then(() => {
                reloadData();
                notifySuccess('Emission source conversion logic added');
            })
            .catch((err) => notifyError(err));
    };

    const removeConversionLogic = (values, idx) => {
        const editedFields = [...values.unit_conversion_logic];
        editedFields.splice(idx, 1);
        const trimmedUnitConversionLogic = trimFieldIds(editedFields);
        showModal(MODAL_TYPE.CONFIRMATION, {
            title: `Are you sure you want to remove this logic?`,
            content: 'This action is irreversible.',
            warningContent: WARNING_CONTENT_RECALCULATE_TEXT,
            confirm: () => {
                doSwaggerCall(
                    'EmissionSources',
                    'editEmissionSourceConversionLogic',
                    { id: emissionSourceId },
                    {
                        unitConversionLogic: trimmedUnitConversionLogic,
                        parentId: values.parent_id,
                    }
                )
                    .then(() => {
                        reloadData();
                        notifySuccess('Emission source conversion logic removed');
                    })
                    .catch((err) => notifyError(err));
            },
        });
    };

    return (
        <PageWithTitle title={`${data?.name || 'Loading...'}`}>
            <Formik
                onSubmit={onSubmit}
                enableReinitialize
                initialValues={data}
                validationSchema={validateSchema}
            >
                {({ isSubmitting, values, touched, errors, setFieldValue }) => (
                    <Form>
                        <ParentDataMerger
                            values={values}
                            setFieldValue={setFieldValue}
                            notifyError={notifyError}
                        />
                        <Grid container spacing={2} sx={{ mt: 1 }}>
                            {Object.keys(errors).length > 0 && (
                                <Grid item xs={12}>
                                    <Alert severity="error">
                                        There are errors in the form!
                                        {/* TODO: better error handling */}
                                        <br />
                                        <div>{JSON.stringify(errors)}</div>
                                    </Alert>
                                </Grid>
                            )}
                            <Grid item xs={6}>
                                <EmissionSourceForm
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    loading={loading}
                                    touched={touched}
                                    isSubmitting={isSubmitting}
                                    errors={errors}
                                    isConnectedToTemplate={values?.existing_template_connection}
                                />
                            </Grid>
                            {!values?.system_managed && (
                                <Grid item xs={6}>
                                    <ConversionLogicForm
                                        loading={
                                            loading ||
                                            values?.unit_conversion_logic_loading === true
                                        }
                                        addNewConversionLogic={addNewConversionLogic}
                                        removeConversionLogic={removeConversionLogic}
                                        values={values}
                                        setFieldValue={setFieldValue}
                                        isChild={values?.parent_id !== null}
                                        isConnectedToTemplate={values?.existing_template_connection}
                                    />
                                    {!(values?.parent_id !== null) &&
                                        !values?.existing_template_connection && (
                                            // not child and not connected to template
                                            <ConversionLogicTemplates
                                                reloadData={reloadData}
                                                conversionLogics={
                                                    values?.unit_conversion_logic || []
                                                }
                                                baseUnit={values?.base_unit || ''}
                                            />
                                        )}
                                </Grid>
                            )}
                        </Grid>
                    </Form>
                )}
            </Formik>
            <Grid item xs={12}>
                <EmissionFactorForm />
            </Grid>
        </PageWithTitle>
    );
};

export default EmissionSourceEditPage;
