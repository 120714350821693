import React, { useEffect } from 'react';
import { ResponsiveLine } from '@nivo/line';
import { Grid } from '@mui/material';
import { useDarkMode } from '../../../../hooks/useDarkMode';
import { useLegends, LegendsContextProvider } from '../../../../hooks/useLegends';
import {
    LINECHART_PROPS,
    customAxisColor,
    customNivoTheme,
} from '../../../../helpers/chartStyleHelper';
import LineLegendDisplay from '../LineLegendDisplay';
import { formatNumber } from '../../../../helpers/convertUnits';
import getPaperBottom from '../helpers/getPaperBottom';

const LineLegendSink = ({ series }) => {
    const { setLineLegends } = useLegends();
    useEffect(() => {
        setLineLegends(series);
    }, [setLineLegends, series]);
    return null;
};

const ScenarioLineChart = ({ data }) => {
    const { darkMode } = useDarkMode();

    // set paper bottom margin
    const lengthConditions = [15, 12, 9, 6, 3];
    const paperBottomValues = ['14vh', '12vh', '10vh', '8vh', '4vh', '2vh'];
    const paperBottom = getPaperBottom(data, lengthConditions, paperBottomValues);

    return (
        <Grid
            item
            xs={12}
            style={{
                height: '40vh',
                marginBottom: paperBottom,
                marginRight: '-25px',
                marginLeft: '5px',
            }}
        >
            <LegendsContextProvider>
                <ResponsiveLine
                    data={data}
                    theme={customNivoTheme(darkMode)}
                    curve={LINECHART_PROPS.curve}
                    areaBlendMode={LINECHART_PROPS.areaBlendMode}
                    areaOpacity={LINECHART_PROPS.areaOpacity}
                    enableArea={LINECHART_PROPS.enableArea}
                    pointSize={LINECHART_PROPS.pointSize}
                    pointColor={LINECHART_PROPS.pointColor}
                    pointBorderWidth={LINECHART_PROPS.pointBorderWidth}
                    axisBottom={LINECHART_PROPS.axisBottom}
                    colors={LINECHART_PROPS.colors}
                    pointBorderColor={LINECHART_PROPS.pointBorderColor}
                    lineWidth={LINECHART_PROPS.lineWidth}
                    margin={{ top: 50, right: 60, bottom: 50, left: 85 }}
                    xScale={{ type: 'point' }}
                    yScale={{
                        type: 'linear',
                        min: 0,
                        max: 'auto',
                        reverse: false,
                    }}
                    axisLeft={{
                        legend: 'CO2e (mt)',
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legendPosition: 'middle',
                        legendOffset: -75,
                        renderTick: (props) => {
                            return (
                                <text
                                    style={{
                                        fontWeight: 'bold',
                                    }}
                                    textAnchor="end"
                                    fill={customAxisColor(darkMode)}
                                    fontSize={12}
                                    x={props.x - 5}
                                    y={props.y + 4}
                                >
                                    {formatNumber(props.value)}
                                </text>
                            );
                        },
                    }}
                    enableSlices="x"
                    yFormat={LINECHART_PROPS.yFormat}
                    axisTop={null}
                    axisRight={null}
                    pointLabelYOffset={-12}
                    animate={false}
                    layers={[
                        'grid',
                        'markers',
                        'axes',
                        'areas',
                        'crosshair',
                        'lines',
                        'points',
                        'slices',
                        'mesh',
                        LineLegendSink,
                    ]}
                />
                <LineLegendDisplay />
            </LegendsContextProvider>
        </Grid>
    );
};

export default ScenarioLineChart;
