import {
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from '@mui/material';
import React from 'react';
import { convertUnitGToKg, formatNumber } from '../../../helpers/convertUnits';
import SkeletonTable from '../../../components/atom/SkeletonTable';
import SortableTableCell from '../../../components/atom/SortableTableCell';
import { TABLE_ROW_COUNT_OPTIONS } from '../../../helpers/constans';
import { handleChangeRowsPerPage, handlePageChange } from '../../../helpers/handleTableFunctions';

const ReportTable = ({
    rows,
    loading,
    limit,
    page,
    setParams,
    total,
    sort,
    sortDir,
    setSorting,
}) => {
    return (
        <Grid item xs={12}>
            <TableContainer component={Paper}>
                <Table aria-label="custom table">
                    <TableHead>
                        <TableRow>
                            <SortableTableCell
                                arrow={sort === 'scope' ? sortDir : ''}
                                onClick={() => setSorting('scope')}
                            >
                                Scope
                            </SortableTableCell>
                            <SortableTableCell
                                arrow={sort === 'category' ? sortDir : ''}
                                onClick={() => setSorting('category')}
                            >
                                Category
                            </SortableTableCell>
                            <SortableTableCell
                                arrow={sort === 'subcategory' ? sortDir : ''}
                                onClick={() => setSorting('subcategory')}
                            >
                                Subcategory
                            </SortableTableCell>
                            <SortableTableCell
                                arrow={sort === 'emissionsource' ? sortDir : ''}
                                onClick={() => setSorting('emissionsource')}
                            >
                                Emission source
                            </SortableTableCell>
                            <SortableTableCell
                                arrow={sort === 'site' ? sortDir : ''}
                                onClick={() => setSorting('site')}
                                width="10%"
                            >
                                Site
                            </SortableTableCell>
                            <SortableTableCell
                                arrow={sort === 'emission' ? sortDir : ''}
                                onClick={() => setSorting('emission')}
                                width="15%"
                            >
                                Emission (CO<sub>2</sub>e mt)
                            </SortableTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <SkeletonTable rows={10} cells={6} />
                        ) : (
                            rows?.map((row, idx) => (
                                <TableRow key={idx}>
                                    <TableCell>{row.scope_name}</TableCell>
                                    <TableCell>{row.category}</TableCell>
                                    <TableCell>{row.subcategory}</TableCell>
                                    <TableCell>{row.emission_source}</TableCell>
                                    <TableCell>{row.siteName}</TableCell>
                                    <TableCell>
                                        {formatNumber(convertUnitGToKg(row.sumOfCO2e) / 1000)}
                                    </TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                count={total || 0}
                rowsPerPage={limit}
                component="div"
                page={page}
                onPageChange={(_, newPage) => handlePageChange(newPage, setParams, limit)}
                rowsPerPageOptions={TABLE_ROW_COUNT_OPTIONS}
                onRowsPerPageChange={(event) => handleChangeRowsPerPage(event, setParams)}
            />
        </Grid>
    );
};

export default ReportTable;
