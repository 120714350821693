import { Grid, InputAdornment, Tooltip } from '@mui/material';
import React from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SurveyQuestionHeadline from '../components/SurveyQuestionHeadline';
import {
    withFormikContext,
    withStepAndValidation,
    withSurveySection,
} from '../components/SurveyStep';
import FloatInput from '../../../components/atom/FloatInput';

function QScopePercentage({ values, setFieldValue, companyName }) {
    return (
        <>
            <Grid item xs={12}>
                <SurveyQuestionHeadline>
                    Please enter the allocated percentage of your Company&apos;s total emissions.
                </SurveyQuestionHeadline>
            </Grid>
            <Grid item xs={12}>
                <FloatInput
                    fullWidth
                    required
                    label={
                        <>
                            Percentage of total emissions allocated to {companyName}{' '}
                            <Tooltip
                                title={
                                    <div style={{ whiteSpace: 'pre-line' }}>
                                        Percentage of your company&apos;s total revenue that is made
                                        up of revenue from this customer (e.g. this customer makes
                                        up 1% of your total revenue, so 1% of total emissions would
                                        be allocated to them)
                                    </div>
                                }
                                sx={{ cursor: 'pointer' }}
                                placement="right"
                            >
                                <InfoOutlinedIcon />
                            </Tooltip>
                        </>
                    }
                    type="number"
                    value={values?.details?.scopePercentage}
                    onChange={(ev) => {
                        setFieldValue('details.scopePercentage', ev.target.rawValue);
                    }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                />
            </Grid>
        </>
    );
}

export default withStepAndValidation(
    withSurveySection(withFormikContext(QScopePercentage)),
    4,
    ['details.scopePercentage'], // no validation
    (values, errors) => {
        const perc = parseFloat(values?.details?.scopePercentage);
        if (perc < 0 || perc > 100 || Number.isNaN(perc)) {
            // eslint-disable-next-line no-param-reassign
            errors['details.scopePercentage'] = 'Please enter a value between 0 and 100.';
        }
        return errors;
    },
    (values) => values?.details?.hasScope12FullReport === true
);
