import { Menu, MenuItem } from '@mui/material';
import React from 'react';
import { MODAL_TYPE, useModals } from '../../../hooks/useModal';
import { AVAILABLE_OPTIONS_FOR_ESGS } from '../../../helpers/constans';

const TableSelectDropdown = ({
    open,
    anchorEl,
    onClose,
    reloadData,
    selectedEsgs,
    deleteEsgsList,
    uploadEventHandler,
    setSelectedEsgs,
}) => {
    const { showModal } = useModals();

    function menuClickHandler(value) {
        // always close menu when clicking
        onClose();
        switch (value) {
            case 'setAssignee':
            case 'setOwner':
                showModal(MODAL_TYPE.ASSIGN_MODAL, {
                    title: `Are you sure you want to assign esgs ${
                        value === 'setOwner' ? '(Owner)?' : '(Assigned user)?'
                    }`,
                    content: `New assignes override previous.`,
                    helperText: `If you want to unassign save without selecting a user.`,
                    reloadData,
                    selectedLines: selectedEsgs.map((e) => e.id),
                    setSelectedLines: setSelectedEsgs,
                    label: value === 'setOwner' ? 'Owner' : 'Assigned user',
                    isEsg: true,
                });
                break;
            case 'deleteEsgs':
                deleteEsgsList();
                break;
            default:
                // do nothing
                console.log('default');
                break;
        }
    }

    return (
        <Menu
            id="dropdown-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            aria-haspopup="true"
        >
            <MenuItem
                key="uploadDocuments"
                value="Upload Documents"
                disabled={selectedEsgs.length === 0}
            >
                <input
                    style={{
                        opacity: 0,
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                    }}
                    type="file"
                    onChange={(event) => {
                        onClose();
                        uploadEventHandler(event).catch(console.log);
                    }}
                />
                Upload Documents
            </MenuItem>
            {AVAILABLE_OPTIONS_FOR_ESGS.map((option) => (
                <MenuItem
                    key={option.value}
                    value={option.value}
                    onClick={() => menuClickHandler(option.value)}
                    disabled={selectedEsgs.length === 0}
                >
                    {option.label}
                </MenuItem>
            ))}
        </Menu>
    );
};

export default TableSelectDropdown;
