import { Button, CircularProgress, Tooltip } from '@mui/material';
import React from 'react';
import FileUploadIcon from '@mui/icons-material/FileUpload';

// import button type that contains a hidden input
const InputButton = ({ id, onChange, accept, title, type, loading = false, fullWidth = false }) => {
    return (
        <Tooltip title={title}>
            <Button fullWidth={fullWidth}>
                <input
                    onChange={onChange}
                    accept={accept}
                    id={id}
                    type={type}
                    style={{
                        opacity: 0,
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                    }}
                />
                {loading ? <CircularProgress color="inherit" size={20} /> : <FileUploadIcon />}
            </Button>
        </Tooltip>
    );
};

export default InputButton;
