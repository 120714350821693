import { IconButton, Tooltip } from '@mui/material';
import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';

export const checkResultForRow = (row) => {
    let result = '';
    if (row.markedForDeletion || row.marked_for_deletion) {
        result = 'Marked for deletion.';
    }
    if (row.error || row.wrong) {
        result += ` ${row.error || row.wrong}.`;
    }
    if (row.markedForDeletion || row.error || row.wrong || row.marked_for_deletion) {
        return <p style={{ color: 'red' }}>{result}</p>;
    }

    return (
        <Tooltip title="Ok">
            <IconButton>
                <CheckIcon color="success" />
            </IconButton>
        </Tooltip>
    );
};

export const iconByType = (type) => {
    let icon = '';
    switch (type) {
        case 'create':
            icon = <AddIcon />;
            break;
        case 'update':
            icon = <EditIcon />;
            break;
        case 'delete':
            icon = <CloseIcon />;
            break;
        default:
            break;
    }
    return icon;
};
