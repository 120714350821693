import { ResponsiveLine } from '@nivo/line';
import React from 'react';
import { useDarkMode } from '../../../hooks/useDarkMode';

const data = [
    {
        id: 'Device1',
        color: 'hsl(219, 70%, 42%)',
        data: [
            {
                x: 'Jan',
                y: 188,
            },
            {
                x: 'Feb',
                y: 109,
            },
            {
                x: 'Mar',
                y: 32,
            },
            {
                x: 'Apr',
                y: 234,
            },
            {
                x: 'May',
                y: 60,
            },
            {
                x: 'Jun',
                y: 167,
            },
        ],
    },
    {
        id: 'Device 2',
        color: 'hsl(173, 65%, 55%)',
        data: [
            {
                x: 'Jan',
                y: 228,
            },
            {
                x: 'Feb',
                y: 102,
            },
            {
                x: 'Mar',
                y: 140,
            },
            {
                x: 'Apr',
                y: 262,
            },
            {
                x: 'May',
                y: 53,
            },
            {
                x: 'Jun',
                y: 181,
            },
        ],
    },
    {
        id: 'Device3',
        color: 'hsl(273, 65%, 55%)',
        data: [
            {
                x: 'Jan',
                y: 259,
            },
            {
                x: 'Feb',
                y: 218,
            },
            {
                x: 'Mar',
                y: 127,
            },
            {
                x: 'Apr',
                y: 139,
            },
            {
                x: 'May',
                y: 17,
            },
            {
                x: 'Jun',
                y: 222,
            },
        ],
    },
];

const TestResponsiveLine = () => {
    const { darkMode } = useDarkMode();
    const colorWithDarkMode = darkMode ? '#ffffff' : '#066387';
    return (
        <div
            style={{
                height: '30vh',
                marginBottom: '20px',
                marginRight: '-65px',
                marginLeft: '5px',
            }}
        >
            <ResponsiveLine
                theme={{
                    axis: {
                        tickColor: colorWithDarkMode,
                        ticks: {
                            line: {
                                stroke: colorWithDarkMode,
                            },
                            text: {
                                fill: colorWithDarkMode,
                            },
                        },
                        legend: {
                            text: {
                                fill: colorWithDarkMode,
                            },
                        },
                    },
                    tooltip: {
                        container: {
                            color: '#066387',
                        },
                    },
                }}
                data={data}
                colors={(d) => d.color}
                margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
                xScale={{ type: 'point' }}
                animate={false}
                yScale={{
                    type: 'linear',
                    min: 'auto',
                    max: 'auto',
                    stacked: true,
                    reverse: false,
                }}
                yFormat=" >-,.2f"
                curve="monotoneX"
                axisTop={null}
                axisRight={null}
                enablePoints={false}
                enableGridX={false}
                enableGridY={false}
                pointSize={10}
                pointColor={{ theme: 'background' }}
                pointBorderWidth={2}
                pointBorderColor={{ from: 'serieColor' }}
                pointLabelYOffset={-12}
                useMesh
                legends={[
                    {
                        anchor: 'top-left',
                        direction: 'row',
                        justify: false,
                        translateX: -40,
                        translateY: -40,
                        itemsSpacing: 0,
                        itemDirection: 'left-to-right',
                        itemWidth: 80,
                        itemHeight: 20,
                        // itemOpacity: 0.75,
                        symbolSize: 12,
                        symbolShape: 'circle',
                        symbolBorderColor: 'rgba(0, 0, 0, .5)',
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemBackground: 'rgba(0, 0, 0, .03)',
                                    itemOpacity: 1,
                                },
                            },
                        ],
                    },
                ]}
            />
        </div>
    );
};

export default TestResponsiveLine;
