import { Button, Grid } from '@mui/material';
import React from 'react';
import AddIcon from '@mui/icons-material/Add';

const AddItemButton = ({ label, onClick }) => {
    return (
        <Grid item>
            <Button variant="text" size="small" startIcon={<AddIcon />} onClick={onClick}>
                {label}
            </Button>
        </Grid>
    );
};

export default AddItemButton;
