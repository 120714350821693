import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ComponentTitle from '../../components/ComponentTitle';
import { useApi } from '../../hooks/useApi';
import SubcategoriesTable from './components/SubcategoriesTable';
import useNotify from '../../hooks/useNotify';
import useSorting from '../../hooks/useSorting';
import { TABLE_ROW_COUNT_DEFAULT } from '../../helpers/constans';
import CommonButton from '../../components/atom/CommonButton';

const SubcategoriesPage = () => {
    const navigate = useNavigate();
    const { categoryId } = useParams();
    const [{ limit, page }, setParams] = useState({ limit: TABLE_ROW_COUNT_DEFAULT, page: 0 });
    const { notifyError } = useNotify();
    const { sort, sortDir, setSorting } = useSorting(['created_at', 'ASC']);

    const [usersData, loading, error, setData, reloadData] = useApi(
        'Subcategories',
        'getSubcategories',
        {
            id: categoryId,
            limit,
            offset: page * limit,
            sort,
            sortDir,
        }
    );

    useEffect(() => {
        if (error) {
            notifyError(error);
        }
    }, [error, notifyError]);

    return (
        <Grid container alignContent="flex-start">
            <Grid container item xs={12} justifyContent="space-between" spacing={0}>
                <Grid item>
                    <ComponentTitle variant="h4" title="Subcategories" />
                </Grid>
                <Grid>
                    <CommonButton
                        onClick={() => navigate(`/categories`)}
                        label="Back"
                        buttonType="secondary"
                        sx={{ mr: 0.625 }}
                    />
                    <CommonButton
                        onClick={() => navigate(`/categories/${categoryId}/subcategories/new`)}
                        sx={{ mr: 0.625 }}
                        label="Add new subcategory"
                        buttonType="primary"
                    />
                </Grid>
            </Grid>
            <SubcategoriesTable
                rows={usersData?.subcategories}
                loading={loading}
                total={usersData?.total}
                limit={limit}
                page={page}
                reloadData={reloadData}
                setParams={setParams}
                categoryId={categoryId}
                sort={sort}
                sortDir={sortDir}
                setSorting={setSorting}
            />
        </Grid>
    );
};

export default SubcategoriesPage;
