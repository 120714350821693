import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { hotjar } from 'react-hotjar';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Providers from './Providers';
import { SENTRY_DSN, HOTJAR_ID, HOTJAR_SV, SENTRY_ENV } from './config';
import CookieBar from './components/cookie/CookieBar';

const root = ReactDOM.createRoot(document.getElementById('root'));

if (HOTJAR_ID && HOTJAR_SV) {
    const id = parseInt(HOTJAR_ID, 10);
    const sv = parseInt(HOTJAR_SV, 10);
    if (!Number.isNaN(id) && !Number.isNaN(sv)) {
        hotjar.initialize(id, sv);
    }
}

if (SENTRY_DSN === 'nosentry') {
    // no sentry inside the system
    root.render(
        <Providers>
            <CookieBar />
            <App />
        </Providers>
    );
} else {
    Sentry.init({
        dsn: SENTRY_DSN,
        environment: SENTRY_ENV,
        integrations: [new BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
        // Or however deep you want your state context to be.
        normalizeDepth: 12,
        // https://github.com/getsentry/sentry-javascript/issues/3440
        ignoreErrors: ['Non-Error promise rejection captured'],
        beforeSend(event) {
            // was: beforeSend(event, hint)
            // Check if it is an exception, and if so, show the report dialog
            if (event.exception) {
                Sentry.showReportDialog({ eventId: event.event_id });
            }
            return event;
        },
    });

    try {
        /* REMOVE ZOHO WIDGET - Mike request 2023-09-07
         *  RE-ADD ZOHO WIDGET - Mike request 2023-09-21 */
        window.$zoho = window.$zoho || {};
        window.$zoho.salesiq = window.$zoho.salesiq || {
            widgetcode:
                'siqdd8d857d3267b234a31194a0cad6803b1d19116ec7c8bbdd74198e59d5fae9cd6f896ba830a9e0bcacdaae0a15e61841',
            values: {},
            ready() {},
        };
        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.id = 'zsiqscript';
        s.defer = true;
        s.src = 'https://salesiq.zoho.com/widget';
        const t = document.getElementsByTagName('script')[0];
        t.parentNode.insertBefore(s, t);
    } catch (err) {
        console.log(err);
    }

    root.render(
        <Sentry.ErrorBoundary>
            <Providers>
                <CookieBar />
                <App />
            </Providers>
        </Sentry.ErrorBoundary>
    );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
