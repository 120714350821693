import React, { useCallback, useContext, useState } from 'react';

const EsgFrameworkFilterContext = React.createContext();

export function EsgFrameworkFilterContextProvider({ children }) {
    const [edited, setEdited] = useState(false);
    const [esgFramework, setEsgFramework] = useState(null);
    const [esgFrameworkName, setEsgFrameworkName] = useState(null);

    const setEsgFrameworkFilters = useCallback(
        ({ esgFrameworkFilter, esgFrameworkNameFilter }) => {
            if (typeof esgFrameworkFilter !== 'undefined') {
                setEsgFramework(esgFrameworkFilter);
                setEsgFrameworkName(esgFrameworkNameFilter);
            }
        },
        [setEsgFramework]
    );

    return (
        <EsgFrameworkFilterContext.Provider
            value={{
                esgFramework,
                esgFrameworkName,
                setEsgFrameworkFilters,
                edited,
                setEdited,
            }}
        >
            {children}
        </EsgFrameworkFilterContext.Provider>
    );
}

EsgFrameworkFilterContext.displayName = 'EsgFrameworkFilterContext';

/**
 * Esg framework filters
 * @returns {{esgFramework, esgFrameworkName, setEsgFrameworkFilters: setEsgFrameworkFilters, edited, setEdited}|unknown}
 */
export function useEsgFrameworkFilters() {
    return useContext(EsgFrameworkFilterContext);
}
