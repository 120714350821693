import { Box, Chip, Grid, Typography } from '@mui/material';
import React from 'react';
import { uploadImage } from '../../hooks/useApi';
import useNotify from '../../hooks/useNotify';
import { BACKEND_URL } from '../../config';

const UploadImageInput = ({ value, setFieldValue, isProfile = false, picture }) => {
    const { notifyInfo } = useNotify();

    // value is an s3 direct url, needs to be proxied using the backend
    const imageUrl = !value ? null : `${BACKEND_URL}/s3proxy?url=${encodeURIComponent(value)}`;

    return (
        <Grid item xs={12}>
            {isProfile && <Typography variant="body1">Profile picture</Typography>}
            <span style={{ position: 'relative' }}>
                {imageUrl && (
                    <Box sx={{ p: 1 }} style={{ maxWidth: '400px' }}>
                        <img
                            src={imageUrl}
                            alt=""
                            style={{
                                maxWidth: '100%',
                                maxHeight: '400px',
                                display: 'block',
                            }}
                        />
                    </Box>
                )}
                <input
                    style={{
                        opacity: 0,
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                    }}
                    type="file"
                    accept="image/*"
                    onChange={async (event) => {
                        if (!event.target.files || !event.target.files.length) {
                            return;
                        }
                        const f = event.target.files[0];
                        notifyInfo(`Upload started for ${f.name}`);
                        const uploadImg = await uploadImage(f);
                        notifyInfo(`Upload finished for ${f.name}`);
                        setFieldValue(picture, uploadImg);
                    }}
                />
                <Chip label={isProfile ? 'Change image' : 'Change logo'} color="primary" />
            </span>
            {value && (
                <Chip
                    sx={{ ml: 1 }}
                    label={isProfile ? 'Remove image' : 'Remove logo'}
                    color="primary"
                    onClick={() => setFieldValue(picture, null)}
                />
            )}
        </Grid>
    );
};

export default UploadImageInput;
