import { ResponsiveBar } from '@nivo/bar';
import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useDarkMode } from '../../../../hooks/useDarkMode';
import {
    BARCHART_PROPS,
    customAxisColor,
    customNivoTheme,
} from '../../../../helpers/chartStyleHelper';
import { formatNumber } from '../../../../helpers/convertUnits';

const NetzeroTargetsStackedBarChart = ({
    data,
    keys,
    axisLeftLegend,
    format,
    label,
    indexBy,
    year,
}) => {
    const { darkMode } = useDarkMode();

    return (
        <>
            <Grid item xs={12}>
                <Typography variant="h5">Year: {year}</Typography>
            </Grid>
            <Grid
                item
                xs={12}
                style={{
                    height: '40vh',
                    marginBottom: '2vh',
                    marginRight: '-25px',
                    marginLeft: '5px',
                }}
            >
                <ResponsiveBar
                    theme={customNivoTheme(darkMode)}
                    colors={BARCHART_PROPS.colors}
                    enableLabel={BARCHART_PROPS.enableLabel}
                    borderRadius={BARCHART_PROPS.borderRadius}
                    borderWidth={BARCHART_PROPS.borderWidth}
                    labelSkipHeight={BARCHART_PROPS.labelSkipHeight}
                    borderColor={BARCHART_PROPS.borderColor}
                    labelTextColor={BARCHART_PROPS.labelTextColor}
                    padding={BARCHART_PROPS.padding}
                    labelSkipWidth={BARCHART_PROPS.labelSkipWidth}
                    data={data}
                    keys={keys}
                    groupMode="stacked"
                    indexBy={indexBy}
                    valueFormat={BARCHART_PROPS.valueFormat}
                    margin={{ right: 60, bottom: 60, left: 75, top: 20 }}
                    label={label}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                    }}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: axisLeftLegend,
                        legendPosition: 'middle',
                        legendOffset: -65,
                        format,
                        renderTick: (props) => {
                            return (
                                <text
                                    style={{
                                        fontWeight: 'bolder',
                                    }}
                                    textAnchor="end"
                                    fill={customAxisColor(darkMode)}
                                    fontSize={12}
                                    x={props.x - 4}
                                    y={props.y + 4}
                                >
                                    {formatNumber(props.value)}
                                </text>
                            );
                        },
                    }}
                    valueScale={{ type: 'linear' }}
                />
            </Grid>
        </>
    );
};

export default NetzeroTargetsStackedBarChart;
