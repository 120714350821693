import React, { useCallback, useEffect, useState } from 'react';
import { ButtonGroup, Grid, Tab, Tabs, Tooltip } from '@mui/material';
import { Form, Formik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { doSwaggerCall } from '../../../hooks/useApi';
import useNotify from '../../../hooks/useNotify';
import { MODAL_TYPE, useModals } from '../../../hooks/useModal';
import { useGlobalFilters } from '../../../hooks/useGlobalFilters';
import RequirementsHierarchy from '../../../components/requirementshierarchy/RequirementsHierarchy';
import { convertFieldToLines, convertLinesToField } from '../../../helpers/defaultFieldHelpers';
import BlockWithTitle from '../../../components/atom/BlockWithTitle';
import PageWithTitle from '../../../components/atom/PageWithTitle';
import { useAuth } from '../../../hooks/useAuth';
import navigateByPermission from '../../../helpers/navigateByPermission';
import { ROLES, WARNING_CONTENT_RECALCULATE_TEXT } from '../../../helpers/constans';
import IntensityMetricEditor from '../components/IntensityMetricEditor';
import YearsOfReportingEditor from '../components/YearsOfReportingEditor';
import BasicSettings from '../components/BasicSettings';
import CommonButton from '../../../components/atom/CommonButton';
import EsgRequirementsHierarchy from '../../../components/esgrequirementshierarchy/EsgRequirementsHierarchy';

const validateSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    flag_limit: Yup.number(),
});

function EditCompanyPage({ newCompany }) {
    const { setSourceFilters, availableYears } = useGlobalFilters();
    const { companyId } = useParams();
    const { showModal } = useModals();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [company, setCompany] = useState(null);
    const { notifyError, notifySuccess } = useNotify();
    const [checkedRequirementsData, setCheckedRequirementsData] = useState([]);
    const [checkedEsgRequirementsData, setCheckedEsgRequirementsData] = useState([]);
    const { user, setUser } = useAuth();
    const navigateUrl = navigateByPermission();
    const isSuperAdmin = user.role === ROLES.SUPERADMIN;
    const [activeTab, setActiveTab] = useState(0);
    const [companyYears, setCompanyYears] = useState([]);
    const [localCompanyYear, setLocalCompanyYear] = useState(
        availableYears[0]?.year || new Date().getFullYear() - 1
    );
    const [formulaEditorValues, setFormulaEditorValues] = useState(null);
    const [defaultFormula, setDefaultFormula] = useState(null);
    const [showHelp, setShowHelp] = useState(false);

    const calendarYear = new Date().getFullYear(); // always put the actual year to the calendar
    const defaultCo2LogicOverride = [
        { id: `${Math.random()}`.substring(2), year: '2013', logic: 'SAR' },
        { id: `${Math.random()}`.substring(2), year: '2014', logic: 'AR4' },
        { id: `${Math.random()}`.substring(2), year: '2015', logic: 'AR5' },
        { id: `${Math.random()}`.substring(2), year: '2016', logic: 'AR5' },
        { id: `${Math.random()}`.substring(2), year: '2017', logic: 'AR5' },
        { id: `${Math.random()}`.substring(2), year: '2018', logic: 'AR5' },
        { id: `${Math.random()}`.substring(2), year: '2019', logic: 'AR5' },
        { id: `${Math.random()}`.substring(2), year: '2020', logic: 'AR5' },
        { id: `${Math.random()}`.substring(2), year: '2021', logic: 'AR5' },
        { id: `${Math.random()}`.substring(2), year: '2022', logic: 'AR5' },
        { id: `${Math.random()}`.substring(2), year: '2023', logic: 'AR6' },
    ];

    const defaultCompany = {
        name: 'New Company',
        location_id: 1,
        co2e_logic: 'AR5',
        co2e_logic_override: defaultCo2LogicOverride,
        fiscal_year_starts_at: `${calendarYear}-01-01`,
        default_field: [],
        company_tags: [],
        flag_limit: 10,
        two_factor_auth_setting: 'not_available',
        notification_cadence: '0',
        logo: null,
    };

    const loadCompany = useCallback(() => {
        setLoading(true);
        doSwaggerCall('Companies', 'getCompany', { id: companyId, year: localCompanyYear })
            .then((res) => {
                doSwaggerCall('CompanyTags', 'getCompanyTagList').then((data) => {
                    const tagFilter = data.companyTags;
                    setCompany({
                        id: res.id,
                        name: res.name,
                        location_id: res.location_id,
                        co2e_logic: res.co2e_logic,
                        co2e_logic_override: res.co2e_logic_override,
                        fiscal_year_starts_at: res.fiscal_year_starts_at,
                        default_field: convertFieldToLines(res.default_field),
                        intensity_metrics: res.intensity_metrics,
                        company_tags: res.company_tags.filter((e) => tagFilter.includes(e)),
                        flag_limit: res.flag_limit,
                        two_factor_auth_setting: res.two_factor_auth_setting,
                        logo: res.logo,
                        notification_cadence: res.notification_cadence,
                        allowed_esg_frameworks: res.allowed_esg_frameworks,
                    });
                    setCheckedRequirementsData(res.requirements);
                    setCheckedEsgRequirementsData(res.esg_requirements);
                    setCompanyYears(res.company_years);
                    setLoading(false);
                    setFormulaEditorValues({
                        ...formulaEditorValues,
                        ...res.co2e_logic_override,
                    });
                    setDefaultFormula(res.co2e_logic);
                });
            })
            .catch((err) => {
                setLoading(false);
                navigate(isSuperAdmin ? '/companies' : navigateUrl);
                notifyError(err);
            });
    }, [companyId, localCompanyYear]);

    useEffect(() => {
        if (!newCompany) {
            loadCompany();
        } else {
            setLoading(false);
        }
    }, [companyId, localCompanyYear]);

    const onEdit = (values) => {
        showModal(MODAL_TYPE.CONFIRMATION, {
            title: `Are you sure you want to edit this company?`,
            warningContent: WARNING_CONTENT_RECALCULATE_TEXT,
            confirm: () => {
                doSwaggerCall(
                    'Companies',
                    'editCompany',
                    { id: companyId },
                    {
                        name: values.name,
                        locationId: parseInt(values.location_id, 10),
                        co2eLogic: values.co2e_logic,
                        fiscalYear: values.fiscal_year_starts_at.substring(5, 10) || `01-01`,
                        year: localCompanyYear,
                        requirements: checkedRequirementsData,
                        esgRequirements: checkedEsgRequirementsData,
                        defaultField: convertLinesToField(values.default_field),
                        companyTags: values.company_tags,
                        flagLimit: parseInt(values.flag_limit, 10) || 10,
                        twoFactorAuthSetting: values.two_factor_auth_setting,
                        logo: values.logo,
                        notificationCadence: values.notification_cadence,
                        allowedEsgFrameworks: values.allowed_esg_frameworks,
                    }
                )
                    .then(() => {
                        loadCompany();
                        setSourceFilters({
                            scopeFilter: null,
                            categoryFilter: null,
                            subcategoryFilter: null,
                            emissionSourceFilter: null,
                        });
                        setUser({
                            ...user,
                            companyLogo: company?.logo || null,
                        });
                        navigate(isSuperAdmin ? '/companies' : navigateUrl);
                        notifySuccess('Company updated');
                    })
                    .catch((err) => {
                        notifyError(err);
                    });
            },
        });
    };

    const onSubmit = async (values) => {
        if (!newCompany) {
            return onEdit(values);
        }
        try {
            await doSwaggerCall(
                'Companies',
                'addCompany',
                {},
                {
                    name: values.name,
                    locationId: parseInt(values.location_id, 10),
                    co2eLogic: values.co2e_logic,
                    fiscalYear: values.fiscal_year_starts_at.substring(5, 10) || `01-01`,
                    year: localCompanyYear,
                    defaultField: convertLinesToField(values.default_field),
                    companyTags: values.company_tags,
                    flagLimit: parseInt(values.flag_limit, 10) || 10,
                    twoFactorAuthSetting: values.two_factor_auth_setting,
                    logo: values.logo,
                    notificationCadence: values.notification_cadence,
                    allowedEsgFrameworks: values.allowed_esg_frameworks,
                }
            );
            setSourceFilters({
                scopeFilter: null,
                categoryFilter: null,
                subcategoryFilter: null,
                emissionSourceFilter: null,
            });
            navigate('/companies');
            notifySuccess('Company added');
        } catch (error) {
            notifyError(error);
        }
    };

    if (loading) {
        return true;
    }

    return (
        <Formik
            onSubmit={onSubmit}
            initialValues={company || { ...defaultCompany }}
            validationSchema={validateSchema}
        >
            {({ isSubmitting, values, setFieldValue, touched, errors }) => (
                <Form>
                    <PageWithTitle title={newCompany ? 'Add new Company' : 'Edit Company'}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Tabs
                                    value={activeTab}
                                    onChange={(event, newValue) => setActiveTab(newValue)}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    centered
                                >
                                    <Tab label="Basic settings" />
                                    <Tab label="Inventory Boundary" disabled={newCompany} />
                                    <Tab label="ESG Inventory Boundary" disabled={newCompany} />
                                    <Tab label="Intensity metrics" disabled={newCompany} />
                                    <Tab label="Years of reporting" disabled={newCompany} />
                                </Tabs>
                            </Grid>
                            {activeTab !== 4 && activeTab !== 3 && (
                                <Grid item container xs={12} justifyContent="flex-end">
                                    <Grid item xs={2} md={2}>
                                        <CommonButton
                                            fullWidth
                                            onClick={() =>
                                                navigate(isSuperAdmin ? '/companies' : navigateUrl)
                                            }
                                            label="Cancel"
                                            buttonType="secondary"
                                        />
                                    </Grid>
                                    <Grid item xs={2} md={2}>
                                        <CommonButton
                                            fullWidth
                                            disabled={isSubmitting}
                                            type="submit"
                                            label="Save all changes"
                                            buttonType="primary"
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                        {activeTab === 0 && (
                            <BasicSettings
                                values={values}
                                setFieldValue={setFieldValue}
                                touched={touched}
                                errors={errors}
                                calendarYear={calendarYear}
                                isSuperAdmin={isSuperAdmin}
                            />
                        )}
                        {activeTab === 1 && (
                            <RequirementsHierarchy
                                localCompanyYear={localCompanyYear}
                                setLocalCompanyYear={setLocalCompanyYear}
                                availableYears={companyYears}
                                checkedData={checkedRequirementsData}
                                setCheckedData={setCheckedRequirementsData}
                                reloadData={() => loadCompany()}
                                newCompany={newCompany}
                            />
                        )}
                        {activeTab === 2 && (
                            <EsgRequirementsHierarchy
                                localCompanyYear={localCompanyYear}
                                setLocalCompanyYear={setLocalCompanyYear}
                                availableYears={companyYears}
                                checkedData={checkedEsgRequirementsData}
                                setCheckedData={setCheckedEsgRequirementsData}
                                reloadData={() => loadCompany()}
                                newCompany={newCompany}
                            />
                        )}
                        {activeTab === 3 && (
                            <Grid item xs={8}>
                                <IntensityMetricEditor
                                    companyId={companyId}
                                    siteId={0}
                                    localCompanyYear={localCompanyYear}
                                    setLocalCompanyYear={setLocalCompanyYear}
                                    availableYears={companyYears}
                                />
                            </Grid>
                        )}
                        {activeTab === 4 && (
                            <>
                                <Grid item xs={12}>
                                    <Grid container justifyContent="flex-end">
                                        <Grid item xs={5}>
                                            <ButtonGroup fullWidth>
                                                <CommonButton
                                                    fullWidth
                                                    onClick={() => {
                                                        showModal(MODAL_TYPE.COPY_DATASET, {
                                                            title: 'Copy GHG datalines',
                                                            companyId,
                                                            isDataline: true,
                                                        });
                                                    }}
                                                    label="Copy GHG datalines to another year"
                                                    buttonType="outlined"
                                                />
                                                <CommonButton
                                                    fullWidth
                                                    onClick={() => {
                                                        showModal(MODAL_TYPE.COPY_DATASET, {
                                                            title: 'Copy ESG datalines',
                                                            companyId,
                                                            isDataline: false,
                                                        });
                                                    }}
                                                    label="Copy ESG datalines to another year"
                                                    buttonType="outlined"
                                                />
                                            </ButtonGroup>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <BlockWithTitle title="Years of reporting">
                                        <Grid item xs={12}>
                                            <Tooltip
                                                title={`Click here to ${
                                                    showHelp ? 'close' : 'open'
                                                } guidance`}
                                                placement="right"
                                            >
                                                <HelpOutlineIcon
                                                    sx={{
                                                        cursor: 'pointer',
                                                        fontSize: '15px',
                                                        mb: 1,
                                                    }}
                                                    onClick={() => setShowHelp(!showHelp)}
                                                />
                                            </Tooltip>
                                            <YearsOfReportingEditor
                                                showHelp={showHelp}
                                                companyId={companyId}
                                                defaultFormula={defaultFormula}
                                                formulaEditorValues={formulaEditorValues}
                                                setFormulaEditorValues={setFormulaEditorValues}
                                                setCompanyYears={setCompanyYears}
                                            />
                                        </Grid>
                                    </BlockWithTitle>
                                </Grid>
                            </>
                        )}
                    </PageWithTitle>
                </Form>
            )}
        </Formik>
    );
}

export default EditCompanyPage;
