/**
 * Helper recursive function to extend data with sum for all level
 *
 * @param item sourceHierarchy
 */
const recursiveSumChildren = (item) => {
    if (typeof item.sum === 'number') {
        return item;
    }

    // don't have children
    if (!Array.isArray(item.children)) {
        return { ...item, sum: 0 };
    }

    const newChildren = item.children.map((e) => recursiveSumChildren(e));

    const sumIncludedTotal = newChildren.reduce((a, b) => {
        if (b.included_total === 1) {
            return a + b.sum;
        }
        return a;
    }, 0);

    return {
        ...item,
        children: newChildren,
        sum: sumIncludedTotal,
    };
};

export default recursiveSumChildren;
