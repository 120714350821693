import { ResponsiveRadialBar } from '@nivo/radial-bar';
import React from 'react';

const data = [
    {
        id: 'Connected',
        data: [
            {
                x: 'Connected',
                y: 154,
            },
        ],
    },
    {
        id: 'Disconnected',
        data: [
            {
                x: 'Disconnected',
                y: 63,
            },
        ],
    },
    {
        id: 'Unknown',
        data: [
            {
                x: 'Unknown',
                y: 12,
            },
        ],
    },
];
const data2 = [
    {
        id: 'Connected',
        data: [
            {
                x: 'Connected',
                y: 154,
            },
        ],
    },
    {
        id: 'Disconnected',
        data: [
            {
                x: 'Disconnected',
                y: 63,
            },
        ],
    },
];

const TestResponsiveRadialBar = ({ testStatus }) => {
    return (
        <div
            style={{
                height: '30vh',
                marginBottom: '0vh',
                marginRight: '-25px',
                marginLeft: '5px',
            }}
        >
            <ResponsiveRadialBar
                data={testStatus ? data : data2}
                valueFormat=">-.2f"
                endAngle={360}
                padding={0.4}
                cornerRadius={2}
                margin={{ top: 40, right: 120, bottom: 40, left: 40 }}
                colors={{ scheme: 'paired' }}
                enableRadialGrid={false}
                enableCircularGrid={false}
                radialAxisStart={null}
                circularAxisOuter={null}
                isInteractive={false}
                animate={false}
                legends={[
                    {
                        anchor: 'right',
                        direction: 'column',
                        justify: false,
                        translateX: 100,
                        translateY: 0,
                        itemsSpacing: 6,
                        itemDirection: 'left-to-right',
                        itemWidth: 100,
                        itemHeight: 18,
                        itemTextColor: '#999',
                        symbolSize: 18,
                        symbolShape: 'square',
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemTextColor: '#000',
                                },
                            },
                        ],
                    },
                ]}
            />
        </div>
    );
};

export default TestResponsiveRadialBar;
