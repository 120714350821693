import React from 'react';
import { DialogContentText, Grid, IconButton, Tooltip } from '@mui/material';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

const ImportResultsHeader = ({
    correctItemsLength,
    showCorrectItems,
    setShowCorrectItems,
    sumTitle,
}) => {
    return (
        <DialogContentText id="alert-dialog-description">
            Below are the results. Only rows with a check mark can be imported.
            <Grid item container direction="row" justifyContent="flex-end" alignItems="center">
                {`${sumTitle}: ${correctItemsLength}`}
                <Tooltip title={`${showCorrectItems ? 'Hide' : 'Show'} correct rows`}>
                    <IconButton
                        onClick={() => setShowCorrectItems(!showCorrectItems)}
                        sx={{ ml: 0.625 }}
                    >
                        {showCorrectItems ? <VisibilityOffIcon /> : <RemoveRedEyeIcon />}
                    </IconButton>
                </Tooltip>
            </Grid>
        </DialogContentText>
    );
};

export default ImportResultsHeader;
