import React, { useCallback, useContext, useState } from 'react';

const GuideBoxSidebarContext = React.createContext();

export function GuideBoxSidebarContextProvider({ children }) {
    const [sidebarOpen, setSidebarOpen] = useState(null);
    const [sidebarTitle, setSidebarTitle] = useState(null);
    const [sidebarContent, setSidebarContent] = useState(null);

    const openSidebar = useCallback(
        ({ title, content }) => {
            setSidebarTitle(title);
            setSidebarContent(content);
            setSidebarOpen(true);
        },
        [setSidebarTitle, setSidebarContent, setSidebarOpen]
    );

    const closeSidebar = useCallback(() => {
        setSidebarTitle(null);
        setSidebarContent(null);
        setSidebarOpen(false);
    }, [setSidebarOpen]);

    return (
        <GuideBoxSidebarContext.Provider
            value={{
                sidebarOpen,
                setSidebarOpen,
                sidebarTitle,
                setSidebarTitle,
                sidebarContent,
                setSidebarContent,
                openSidebar,
                closeSidebar,
            }}
        >
            {children}
        </GuideBoxSidebarContext.Provider>
    );
}

GuideBoxSidebarContext.displayName = 'GuideBoxSidebarContext';

/**
 * Guide box sidebar
 * @returns {{sidebarOpen, setSidebarOpen: setSidebarOpen, sidebarTitle, setSidebarTitle: setSidebarTitle, sidebarContent, setSidebarContent: setSidebarContent, openSidebar, setTitleAndContent, closeSidebar}|unknown}
 */
export function useGuideBoxSidebar() {
    return useContext(GuideBoxSidebarContext);
}
