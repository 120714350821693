import React, { useCallback } from 'react';
import {
    DialogActions,
    DialogContentText,
    DialogContent,
    DialogTitle,
    Dialog,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TableContainer,
    Paper,
    IconButton,
    Alert,
} from '@mui/material';
import { checkResultForRow, iconByType } from '../helpers/modalHelper';
import CommonButton from '../components/atom/CommonButton';

const ConfirmationImportLocationsModal = ({
    title,
    content,
    data,
    confirm,
    close = false,
    warningContent = false,
    showModal,
    setImportingXlsx,
    MODAL_TYPE,
}) => {
    const closeAction = useCallback(
        close ||
            (() => {
                showModal(MODAL_TYPE.NONE);
                setImportingXlsx(false);
            }),
        [close]
    );

    return (
        <Dialog
            open
            onClose={closeAction}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="md"
            fullWidth
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                {warningContent && (
                    <DialogContentText id="alert-dialog-warning">
                        <Alert sx={{ mt: 0.5, mb: 0.5 }} severity="warning">
                            {warningContent}
                        </Alert>
                    </DialogContentText>
                )}
                <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
                <DialogContentText id="alert-dialog-description">
                    Below are the results. Only rows with a check mark can be imported.
                </DialogContentText>
                <TableContainer component={Paper} style={{ maxHeight: '600px', minWidth: '500px' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="left" width="15%">
                                    Type
                                </TableCell>
                                <TableCell align="left">Name</TableCell>
                                <TableCell align="left">Parent Name</TableCell>
                                <TableCell align="right">Result</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data?.map((row) => (
                                <TableRow key={row.location_id}>
                                    <TableCell align="left">
                                        <IconButton disabled>
                                            {iconByType(row.locationType)}
                                        </IconButton>
                                        {row.locationType}
                                    </TableCell>
                                    <TableCell align="left">{row.name}</TableCell>
                                    <TableCell align="left">{row.parent_name}</TableCell>
                                    <TableCell align="right">{checkResultForRow(row)}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <CommonButton
                    onClick={closeAction}
                    autoFocus
                    label="Cancel"
                    buttonType="secondary"
                />
                <CommonButton
                    onClick={() => {
                        confirm();
                        showModal(MODAL_TYPE.NONE);
                    }}
                    label="Yes"
                    buttonType="primary"
                />
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationImportLocationsModal;
