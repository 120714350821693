import React from 'react';
import { Box, Stack, Tooltip, Typography } from '@mui/material';

// https://stackoverflow.com/questions/3942878/how-to-decide-font-color-in-white-or-black-depending-on-background-color
function whiteOrBlackFont({ r, g, b }) {
    if (r * 0.299 + g * 0.587 + b * 0.114 > 150) return '#000000';
    return '#ffffff';
}

function hexToRgb(hex) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
        ? {
              r: parseInt(result[1], 16),
              g: parseInt(result[2], 16),
              b: parseInt(result[3], 16),
          }
        : // default color if no color is given
          { r: 80, g: 80, b: 80 };
}

const BLUE_COLORS = [
    '#005a80',
    '#005173',
    '#004866',
    '#003e59',
    '#00364c',
    '#196a8c',
    '#327a99',
    '#4c8ba6',
    '#669cb2',
    '#7facbf',
];

const BarChart = ({ data }) => {
    let sumOfTarget;
    if (data.length === 1) {
        sumOfTarget = data[0].target;
    } else {
        sumOfTarget = data.map((e) => e.target).reduce((a, b) => a + b);
    }

    const childrens = { children: data };

    function getAllChildrenOnTheNthLayer(root, level, array, i) {
        if (!root.children && level !== 1) {
            return;
        }
        if (root.children) {
            const tmpArray = array;
            root.children.forEach((child) => {
                if (level === i) {
                    // TODO: quick fix for filter dataline level in chart
                    if (child.name) {
                        tmpArray.push({
                            id: child.id,
                            target: child.target,
                            name: child.name,
                        });
                    }
                }
                getAllChildrenOnTheNthLayer(child, level, tmpArray, i + 1);
            });
            return tmpArray;
        }
    }

    const barChartData = [];
    for (let i = 1; i < 5; i++) {
        const res = getAllChildrenOnTheNthLayer(childrens, i, [], 1);
        barChartData.push(res);
    }

    return barChartData.map((value) => {
        return (
            <Stack direction="row" spacing="0px" sx={{ mb: 0.25 }}>
                {value.map((v, index) => {
                    let perc = v.target / sumOfTarget;
                    if (sumOfTarget === 0) {
                        perc = 1 / value.length;
                    }
                    return (
                        <Tooltip key={index} title={`${v.name}: ${Math.round(perc * 100)}%`}>
                            <Box
                                sx={{
                                    width: `${perc * 100}%`,
                                    height: 35,
                                    backgroundColor: BLUE_COLORS[index % BLUE_COLORS.length],
                                    '&:hover': {
                                        opacity: [0.9, 0.8, 0.7],
                                    },
                                }}
                                whiteSpace="nowrap"
                                overflow="hidden"
                            >
                                <Typography
                                    variant="subtitle2"
                                    sx={{ p: '7px 13px' }}
                                    color={whiteOrBlackFont(hexToRgb(BLUE_COLORS))}
                                >
                                    {`${v.name}`}
                                </Typography>
                            </Box>
                        </Tooltip>
                    );
                })}
            </Stack>
        );
    });
};

export default BarChart;
