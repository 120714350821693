import React, { useEffect, useState } from 'react';
import { Grid, LinearProgress } from '@mui/material';
import useNotify from '../../../hooks/useNotify';
import { doSwaggerCall } from '../../../hooks/useApi';
import CategoryOrTagOrSourceOrSupplierBarChart from './charts/CategoryOrTagOrSourceOrSupplierBarChart';

const BySelectedTags = ({ tags, years, setChartData }) => {
    const { notifyError } = useNotify();
    const [tagBarData, setTagBarData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        doSwaggerCall('Dashboards', 'getDataByTagsForBarChart', {
            tags,
            years,
        })
            .then((res) => {
                setTagBarData(res);
                setChartData(res.tagData);
                setLoading(false);
            })
            .catch((error) => {
                notifyError(error);
            });
    }, [tags, years, setLoading, setTagBarData, setChartData]);

    return (
        <Grid item xs={12}>
            {tagBarData?.length === 0 ? (
                <Grid item>
                    {loading ? <LinearProgress sx={{ mt: 0.25 }} /> : <h5>No data available</h5>}
                </Grid>
            ) : (
                <CategoryOrTagOrSourceOrSupplierBarChart
                    data={tagBarData.tagData}
                    keys={years}
                    indexBy="tag"
                    axisLeftLegend="CO2e (mt)"
                />
            )}
        </Grid>
    );
};

export default BySelectedTags;
