import React, { useCallback, useEffect, useState } from 'react';
import { Grid, IconButton, TextField, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import SortIcon from '@mui/icons-material/Sort';
import useNotify from '../../hooks/useNotify';
import { doSwaggerCall } from '../../hooks/useApi';
import DashboardBuilder from './components/DashboardBuilder';
import { MODAL_TYPE, useModals } from '../../hooks/useModal';
import { useDarkMode } from '../../hooks/useDarkMode';
import CommonButton from '../../components/atom/CommonButton';

const DashboardTemplateEditPage = ({ newDashboard, readOnly = false }) => {
    const { dashboardId } = useParams();
    const navigate = useNavigate();
    const { notifySuccess, notifyError } = useNotify();
    const [loading, setLoading] = useState(true);
    const [dashboardNameEdit, setDashboardNameEdit] = useState(false);
    const [dashboardDescriptionEdit, setDashboardDescriptionEdit] = useState(false);
    const { showModal } = useModals();
    const { darkMode } = useDarkMode();

    const [dashboard, setDashboard] = useState({
        name: 'New dashboard',
        description: '',
        panels: [],
    });

    const loadDashboardTemplate = useCallback(() => {
        setLoading(true);
        doSwaggerCall('Dashboards', 'getDashboard', { id: dashboardId })
            .then((res) => {
                setDashboard({
                    id: res.id,
                    name: res.name,
                    description: res.description,
                    panels: res.panels,
                });
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                navigate(`/dashboardtemplates`);
                notifyError(err);
            });
    }, []);

    const updateDashboardPanels = (panels = null) => {
        if (!dashboard) {
            return;
        }
        let newPanels = [];
        if (!panels) {
            newPanels = [...dashboard.panels];
        } else {
            newPanels = [...panels];
        }
        newPanels.forEach((panel) => {
            if (!panel.name) {
                panel.name = '';
            }
            if (!panel.description) {
                panel.description = '';
            }
            if (!panel.visual_type) {
                panel.visual_type = 'bar';
            }
            if (!panel.config) {
                panel.config = {};
            }
        });
        dashboard.panels = newPanels;
        setDashboard({ ...dashboard });
    };

    const addDashboard = async () => {
        try {
            await doSwaggerCall(
                'Dashboards',
                'addDashboard',
                {},
                {
                    name: dashboard.name,
                    description: dashboard.description,
                    panels: dashboard.panels,
                }
            );
            notifySuccess('Dashboard added');
            navigate(`/dashboardtemplates`);
        } catch (err) {
            notifyError(err);
        }
    };

    const editDashboard = async () => {
        try {
            await doSwaggerCall(
                'Dashboards',
                'editDashboard',
                { id: dashboardId },
                {
                    name: dashboard.name,
                    description: dashboard.description,
                    panels: dashboard.panels,
                }
            );
            notifySuccess('Dashboard edited');
            navigate(`/dashboardtemplates`);
        } catch (err) {
            notifyError(err);
        }
    };

    const onSubmit = async () => {
        if (newDashboard) {
            await addDashboard();
        } else {
            await editDashboard();
        }
    };

    const handleClose = useCallback(
        (items) => {
            setDashboard((prevValues) => ({ ...prevValues, panels: items }));
        },
        [setDashboard]
    );

    useEffect(() => {
        if (!newDashboard) {
            loadDashboardTemplate();
        } else {
            setLoading(false);
        }
    }, [dashboardId]);

    if (loading) {
        return true;
    }

    const darkModeColor = darkMode ? '#1f2c3b' : '#f7fafc';

    return (
        <Grid container alignContent="flex-start">
            <Grid container item xs={12} justifyContent="space-between" spacing={0}>
                <Grid
                    container
                    item
                    xs={12}
                    sx={{
                        position: 'sticky',
                        top: '64px',
                        zIndex: 1000,
                        backgroundColor: !readOnly ? darkModeColor : '',
                    }}
                >
                    {!readOnly &&
                        (dashboardNameEdit ? (
                            <Grid item xs={10} sx={{ pb: 2 }}>
                                <TextField
                                    fullWidth
                                    autoComplete="off"
                                    required
                                    label="Dashboard name"
                                    value={dashboard.name}
                                    onChange={(e) => {
                                        dashboard.name = e.target.value;
                                        setDashboard({ ...dashboard });
                                    }}
                                    onBlur={() => setDashboardNameEdit(false)}
                                    autoFocus
                                />
                            </Grid>
                        ) : (
                            <Grid item xs={10} style={{ alignContent: 'center' }}>
                                <Typography
                                    variant="h5"
                                    style={{
                                        display: 'inline-block',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {dashboard.name}
                                </Typography>
                                <IconButton onClick={() => setDashboardNameEdit(true)}>
                                    <EditIcon />
                                </IconButton>
                                <IconButton>
                                    <SortIcon
                                        onClick={() => {
                                            showModal(MODAL_TYPE.DRAG_AND_DROP_PANEL, {
                                                panels: dashboard.panels,
                                                handleClose,
                                                title: `Order panels`,
                                                content: `You can change panels order by dragging and dropping them.`,
                                            });
                                        }}
                                    />
                                </IconButton>
                            </Grid>
                        ))}
                    {!readOnly && (
                        <Grid item xs={2}>
                            <Grid item xs={12}>
                                <CommonButton
                                    fullWidth
                                    onClick={() => onSubmit()}
                                    label="Save"
                                    buttonType="primary"
                                />
                            </Grid>
                        </Grid>
                    )}
                </Grid>
                {!readOnly &&
                    (dashboardDescriptionEdit ? (
                        <Grid item xs={12} sx={{ pb: 2 }}>
                            <TextField
                                fullWidth
                                autoComplete="off"
                                required
                                label="Dashboard description"
                                value={dashboard.description}
                                onChange={(e) => {
                                    dashboard.description = e.target.value;
                                    setDashboard({ ...dashboard });
                                }}
                                onBlur={() => setDashboardDescriptionEdit(false)}
                                autoFocus
                            />
                        </Grid>
                    ) : (
                        <Grid item xs={12} sx={{ alignContent: 'center', pb: 2 }}>
                            <Typography
                                variant="body1"
                                style={{
                                    display: 'inline-block',
                                    fontWeight: 'bold',
                                }}
                            >
                                Description: {dashboard.description}
                            </Typography>
                            <IconButton onClick={() => setDashboardDescriptionEdit(true)}>
                                <EditIcon />
                            </IconButton>
                        </Grid>
                    ))}
                <DashboardBuilder
                    name={dashboard?.name}
                    description={dashboard?.description}
                    panels={dashboard?.panels}
                    updatePanels={updateDashboardPanels}
                    loading={loading}
                    readOnly={readOnly}
                />
            </Grid>
        </Grid>
    );
};

export default DashboardTemplateEditPage;
