import { read, utils } from 'xlsx';
import { doSwaggerCall } from '../hooks/useApi';

// check existing site name
async function checkExistingSite(name, locationName, companyId) {
    let existingSiteFlag = true;
    let existingSite = null;
    try {
        existingSite = await doSwaggerCall('Sites', 'getSitesInCompany', {
            id: companyId,
            search: name,
            limit: 1000,
        });
    } catch (error) {
        existingSiteFlag = false;
    }
    // check exact name and location name (only one site name can exist with same company and location)
    const existingSiteId = existingSite?.sites.find(
        (site) => site.name === name && site.location_name === locationName
    )?.id;
    existingSiteFlag = !!existingSiteId;
    return { existingSiteFlag, existingSiteId };
}

// helper function to check rows
async function checkData(data, companyId) {
    const siteData = data;
    for (let i = 0; i < data.length; i++) {
        // check marked for deletion
        if (
            typeof data[i].Marked_for_deletion !== 'undefined' &&
            data[i].Marked_for_deletion !== ''
        ) {
            siteData[i].marked_for_deletion = true;
        }
        // check incorrect data (empty cells)
        if (typeof data[i].Location === 'undefined' || typeof data[i].Name === 'undefined') {
            siteData[i].wrong = 'Wrong data';
        }
        // check existing locations
        try {
            const location = await doSwaggerCall('Locations', 'getLocationByName', {
                search: data[i].Location,
            });
            siteData[i].location_id = location?.id;
        } catch (error) {
            siteData[i].wrong = 'No location';
            console.log(`No location: ${data[i].Location}`);
        }

        // check existing site name
        if (
            typeof data[i].Marked_for_deletion === 'undefined' ||
            data[i].Marked_for_deletion === ''
        ) {
            const { existingSiteFlag, existingSiteId } = await checkExistingSite(
                data[i].Name,
                data[i].Location,
                companyId
            );
            if (
                existingSiteFlag &&
                (siteData[i].Id === '' || typeof siteData[i].Id === 'undefined')
            ) {
                // create new site
                siteData[i].wrong = 'Existing site for this location';
                console.log(`Existing site for this location: ${data[i].Name}`);
            } else if (existingSiteFlag && existingSiteId !== siteData[i].Id) {
                // update site
                siteData[i].wrong = 'Existing site for this location';
                console.log(`Existing site for this location: ${data[i].Name}`);
            }
        }
    }

    // check duplicates
    siteData.map((oneFactorData, index) => {
        siteData.find((fdata, idx) => {
            if (
                fdata.Name === oneFactorData.Name &&
                fdata.Location === oneFactorData.Location &&
                idx !== index &&
                typeof fdata.wrong === 'undefined' &&
                typeof oneFactorData.wrong === 'undefined' &&
                !fdata.marked_for_deletion &&
                !oneFactorData.marked_for_deletion
            ) {
                siteData[index].wrong = 'Duplicate';
                siteData[idx].wrong = 'Duplicate';
                return true;
            }
            return false;
        });
        return siteData;
    });

    const checkedData = siteData.map((row) => ({
        id: row.Id,
        location_name: row.Location,
        location_id: row.location_id,
        name: row.Name,
        tags: row.Tags,
        marked_for_deletion: row.marked_for_deletion,
        wrong: row.wrong,
    }));

    return checkedData;
}

// Parse xlsx sheets
export const parseXlsxSheets = (fileName, companyId, cb, failCb = () => {}) => {
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(fileName);
    fileReader.onload = (e) => {
        const bufferArray = e?.target.result;
        const wb = read(bufferArray, { type: 'buffer' });
        // skip helper sheet (Locations)
        const wsname = wb.SheetNames.filter((name) => name !== 'Locations');
        const ws = wb.Sheets[wsname];

        const data = utils.sheet_to_json(ws);
        checkData(data, companyId)
            .then((checkedData) => {
                cb(checkedData);
            })
            .catch((err) => {
                console.log(err);
                failCb(err);
            });
    };
};

// Handle import
export const runImport = (parsedData, dryRun, companyId) => {
    let filteredSites = parsedData;
    if (!dryRun) {
        // filter wrong data, duplicate and no location rows
        filteredSites = parsedData.filter((d) => typeof d.wrong === 'undefined');
    }

    // import only good rows
    try {
        return doSwaggerCall(
            'Sites',
            'importSites',
            { dryRun },
            { filteredSites, companyId: parseInt(companyId, 10) }
        );
    } catch (error) {
        throw new Error(error);
    }
};
