import { Button, ButtonGroup, CircularProgress, Grid, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import AddIcon from '@mui/icons-material/Add';
import {
    exportToXlsx,
    getAllLocations,
    getSitesForOneCompany,
} from '../../helpers/excelExportHelpers';
import useNotify from '../../hooks/useNotify';
import InputButton from './InputButton';
import { doSwaggerCall } from '../../hooks/useApi';

const ImportExportButtonGroup = ({
    type,
    sitePage = false,
    parentLocation,
    companyId,
    handleImport,
    importingXlsx,
    setImportingXlsx,
    navigate,
}) => {
    const { notifyError, notifySuccess } = useNotify();
    const [generatingXlsx, setGeneratingXlsx] = useState(false);

    // Helper function to get all locations for export
    const getAllLocationsForExport = async () => {
        const locations = await doSwaggerCall('Locations', 'getAllLocations', {
            pageSize: 10000,
            page: 0,
        });
        return locations.locations.map((l) => ({ name: l.name }));
    };

    const handleClick = async () => {
        setGeneratingXlsx(true);
        let listToExport = [];
        let locationsList = null;
        try {
            if (sitePage) {
                locationsList = await getAllLocationsForExport();
                listToExport = (await getSitesForOneCompany(companyId)).map((s) => ({
                    id: s.id,
                    name: s.name,
                    location: s.location_name,
                    tags: s.tags.map((t) => t).join(', '),
                    marked_for_deletion: '',
                }));
            } else {
                listToExport = (await getAllLocations())
                    .map((l) => ({
                        id: l.id,
                        name: l.name,
                        parent_name: l.parent_name,
                        marked_for_deletion: '',
                    }))
                    .sort((a, b) => a.id - b.id);
            }
            exportToXlsx(listToExport, ...[type, false, false, false, sitePage, locationsList]);
        } catch (err) {
            notifyError(err);
        }
        setGeneratingXlsx(false);
        notifySuccess(sitePage ? 'Sites exported' : 'Locations exported');
    };

    return (
        <Grid item>
            <ButtonGroup color="primary">
                <Tooltip title={`Add new ${type}`}>
                    <Button
                        fullWidth
                        onClick={navigate}
                        disabled={type === 'locations' && !parentLocation}
                    >
                        <AddIcon />
                    </Button>
                </Tooltip>
                <Tooltip title="Export to xlsx">
                    <Button fullWidth onClick={handleClick}>
                        {generatingXlsx ? (
                            <CircularProgress color="inherit" size={20} />
                        ) : (
                            <FileDownloadIcon />
                        )}
                    </Button>
                </Tooltip>
                <InputButton
                    id="list-upload"
                    onChange={(e) => {
                        if (e.target.files.length === 0) {
                            return;
                        }
                        setImportingXlsx(true);
                        handleImport(e).catch((err) => {
                            notifyError(err);
                        });
                        e.target.value = '';
                    }}
                    accept=".csv, .xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    title="Import from xlsx"
                    type="file"
                    loading={importingXlsx}
                    fullWidth
                />
            </ButtonGroup>
        </Grid>
    );
};
export default ImportExportButtonGroup;
