import React, { useEffect, useState } from 'react';
import { Checkbox, FormControl, FormControlLabel, Grid, Typography } from '@mui/material';
import { useGlobalFilters } from '../../hooks/useGlobalFilters';
import { useApi } from '../../hooks/useApi';
import SearchInput from '../../components/atom/SearchInput';

const SiteFilter = ({ setSiteIdList, companyId }) => {
    const { site, setSite, setSiteFilters } = useGlobalFilters();
    const [{ limit, page }, setParams] = useState({ limit: 1000, page: 0 });
    const [search, setSearch] = useState('');

    const [sitesData, loading] = useApi('Sites', 'getSitesInCompany', {
        id: companyId,
        limit,
        offset: page * limit,
    });

    const filteredSites = sitesData?.sites.filter((s) =>
        s.name.toLowerCase().includes(`${search}`.toLowerCase())
    );

    useEffect(() => {
        if (loading === true) {
            return;
        }
        let allSelected = [...site];
        let allSelectedNames =
            sitesData?.sites?.filter((s) => site.includes(s.id)).map((data) => data.name) || [];

        if (allSelected.includes(0)) {
            allSelectedNames.push('Corporate');
        }
        allSelected = [...new Set(allSelected)];
        allSelectedNames = [...new Set(allSelectedNames)];
        setSiteIdList(allSelected);
        setSiteFilters({ siteFilter: allSelected, siteNamesFilter: allSelectedNames });
    }, [site, setSiteIdList, setSiteFilters, sitesData, loading]);

    if (loading) {
        return null;
    }

    return (
        <FormControl fullWidth>
            <Grid container spacing={0}>
                <Grid item xs={12} sx={{ pt: 2, pb: 1 }}>
                    <Typography variant="h6">Sites</Typography>
                </Grid>
                <Grid item xs={12} sx={{ mb: 2 }} style={{ textAlign: 'right' }}>
                    <SearchInput
                        search={search}
                        setSearch={setSearch}
                        setParams={setParams}
                        limit={limit}
                        fullWidth
                    />
                </Grid>
                <Grid key="corporate" xs={12} item>
                    <FormControlLabel
                        label="Corporate"
                        control={
                            <Checkbox
                                checked={site.includes(0)}
                                onChange={() => {
                                    setSite(
                                        site.includes(0)
                                            ? site.filter((e) => e !== 0)
                                            : [...site, 0]
                                    );
                                }}
                            />
                        }
                    />
                </Grid>
                {filteredSites.length > 0 && (
                    <FormControlLabel
                        label="All sites"
                        sx={{ borderTop: '2px solid #eee', width: '100%' }}
                        control={
                            <Checkbox
                                checked={
                                    filteredSites.filter((s) => site.includes(s.id)).length ===
                                    filteredSites.length
                                }
                                indeterminate={
                                    filteredSites.filter((s) => site.includes(s.id)).length !==
                                        filteredSites.length &&
                                    filteredSites.filter((s) => site.includes(s.id)).length > 0
                                }
                                onChange={(e) => {
                                    if (!e.target.checked) {
                                        setSite((oldsites) => {
                                            return oldsites.filter(
                                                (o) => !filteredSites.map((f) => f.id).includes(o)
                                            );
                                        });
                                    } else {
                                        setSite((oldsites) => {
                                            return [
                                                ...new Set([
                                                    ...oldsites,
                                                    ...filteredSites.map((f) => f.id),
                                                ]),
                                            ];
                                        });
                                    }
                                }}
                            />
                        }
                    />
                )}
                {filteredSites.map((filteredSite) => {
                    return (
                        <Grid key={filteredSite.id} xs={12} item sx={{ pl: 1.5 }}>
                            <FormControlLabel
                                label={filteredSite.name}
                                control={
                                    <Checkbox
                                        checked={site.includes(filteredSite.id)}
                                        onChange={() => {
                                            setSite(
                                                site.includes(filteredSite.id)
                                                    ? site.filter((e) => e !== filteredSite.id)
                                                    : [...site, filteredSite.id]
                                            );
                                        }}
                                    />
                                }
                            />
                        </Grid>
                    );
                })}
            </Grid>
        </FormControl>
    );
};

export default SiteFilter;
