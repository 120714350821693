import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';
import { useGlobalFilters } from '../../hooks/useGlobalFilters';

function GlobalYearFilter() {
    const { year, setYear, availableYears } = useGlobalFilters();
    return (
        <FormControl fullWidth>
            <InputLabel id="year-select">Year</InputLabel>
            <Select
                fullWidth
                labelId="year-select"
                id="demo-simple-select"
                value={year}
                size="small"
                label="Year"
                onChange={(e) => {
                    setYear(parseInt(e.target.value, 10));
                }}
            >
                {availableYears.map((yearItem) => (
                    <MenuItem key={yearItem.year} value={yearItem.year}>
                        {yearItem.year}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default GlobalYearFilter;
