import {
    Checkbox,
    CircularProgress,
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip,
} from '@mui/material';
import React, { useCallback, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import { Link, useNavigate } from 'react-router-dom';
import SsidChartIcon from '@mui/icons-material/SsidChart';
import DeselectIcon from '@mui/icons-material/Deselect';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SkeletonTable from '../../../components/atom/SkeletonTable';
import useNotify from '../../../hooks/useNotify';
import { MODAL_TYPE, useModals } from '../../../hooks/useModal';
import { doSwaggerCall, doSwaggerCallForPdf } from '../../../hooks/useApi';
import SortableTableCell from '../../../components/atom/SortableTableCell';
import { BACKEND_URL } from '../../../config';
import { sumOfSavings, sumReductionCosts } from '../helpers/sumDifferentCosts';
import { formatMoney } from '../../../helpers/convertUnits';
import { TABLE_ROW_COUNT_OPTIONS } from '../../../helpers/constans';
import { handleChangeRowsPerPage, handlePageChange } from '../../../helpers/handleTableFunctions';
import MultipleLineSelect from '../../../components/atom/MultipleLineSelect';

const ScenariosTable = ({
    rows,
    limit,
    page,
    setParams,
    total,
    loading,
    reloadData,
    sort,
    sortDir,
    setSorting,
    selectMode,
    setSelectMode,
    setSelectedScenarios,
    selectedScenarios,
    multipleMode,
    groupby,
}) => {
    const { showModal } = useModals();
    const navigate = useNavigate();
    const { notifySuccess, notifyError } = useNotify();
    const rowsIdList = rows?.map((r) => r.id);
    const [generatingId, setGeneratingId] = useState(null);
    const [generatingPdf, setGeneratingPdf] = useState(false);

    const deleteScenario = useCallback((id) => {
        doSwaggerCall('Scenarios', 'deleteScenario', { id })
            .then(() => {
                notifySuccess('Scenario deleted');
                reloadData();
            })
            .catch(() => {
                notifyError('Cannot delete scenario');
            });
    }, []);

    const scenarioExport = async (id) => {
        try {
            const reportRequest = await doSwaggerCall('Reports', 'scenarioReportRequest', {
                scenarioId: id,
            });
            window.open(
                `${BACKEND_URL}/1.0/reports/scenariodownload?token=${reportRequest.token}`,
                '_blank',
                'noopener,noreferrer'
            );
        } catch (err) {
            notifyError(err);
        }
    };

    const pdfExport = async (id) => {
        setGeneratingPdf(true);
        setGeneratingId(id);
        let reportRequest;
        try {
            reportRequest = await doSwaggerCallForPdf('Reports', 'scenarioReportRequestPdf', {
                scenarioId: id,
            });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(reportRequest);
            link.download = `scenario_${id}.pdf`;
            link.click();
        } catch (err) {
            notifyError(err);
            setGeneratingId(null);
            setGeneratingPdf(false);
        } finally {
            setGeneratingId(null);
            setGeneratingPdf(false);
        }
    };

    const handleSelectAll = (ev) => {
        if (ev.target.checked) {
            const newSelectedIds = rows.map((scenario) => scenario.id);
            setSelectedScenarios(newSelectedIds);
            return;
        }
        setSelectedScenarios([]);
    };

    // different confirm function for report by reportType
    const confirmFunction = (reportType, scenarioId) => {
        switch (reportType) {
            case 'PDF':
                return pdfExport(scenarioId).catch((err) => {
                    notifyError(err);
                });
            case 'HTML':
                return scenarioExport(scenarioId).catch((err) => {
                    notifyError(err);
                });
            default:
                return () => {
                    notifyError('Something went wrong');
                };
        }
    };

    const assignedSkeleton = !multipleMode ? 9 : 8;

    return (
        <Grid item xs={12}>
            <TableContainer component={Paper}>
                <Table aria-label="custom pagination table">
                    <TableHead>
                        <TableRow>
                            {!multipleMode && selectMode && (
                                <TableCell>
                                    <Checkbox
                                        checked={
                                            rowsIdList?.length > 0 &&
                                            selectedScenarios.length === rowsIdList.length
                                        }
                                        onChange={(ev) => handleSelectAll(ev)}
                                        indeterminate={
                                            selectedScenarios.length > 0 &&
                                            selectedScenarios.length < rowsIdList.length
                                        }
                                    />
                                </TableCell>
                            )}
                            <SortableTableCell
                                arrow={sort === 'name' ? sortDir : ''}
                                onClick={() => setSorting('name')}
                            >
                                Name
                            </SortableTableCell>
                            <SortableTableCell
                                arrow={sort === 'target_reduction' ? sortDir : ''}
                                onClick={() => setSorting('target_reduction')}
                            >
                                Target reduction goal
                            </SortableTableCell>
                            <SortableTableCell
                                arrow={sort === 'target_year' ? sortDir : ''}
                                onClick={() => setSorting('target_year')}
                            >
                                Target year
                            </SortableTableCell>
                            <TableCell>Target scope</TableCell>
                            <TableCell>Costs / Savings</TableCell>
                            <SortableTableCell
                                arrow={sort === 'baseline_year' ? sortDir : ''}
                                onClick={() => setSorting('baseline_year')}
                            >
                                Baseline year
                            </SortableTableCell>
                            <TableCell>Site names</TableCell>
                            <TableCell>Dataline tag</TableCell>
                            {!multipleMode && groupby === 'TARGET' && (
                                <TableCell align="right">
                                    {!selectMode ? (
                                        <MultipleLineSelect setSelectMode={setSelectMode} />
                                    ) : (
                                        <>
                                            <Tooltip title="Cancel selection">
                                                <IconButton
                                                    onClick={() => {
                                                        setSelectMode(false);
                                                    }}
                                                >
                                                    <DeselectIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Plot selected scenarios">
                                                <IconButton
                                                    disabled={selectedScenarios.length === 0}
                                                    onClick={() => {
                                                        navigate(
                                                            `/scenarios/scenariocompare?id=${selectedScenarios.join(
                                                                ','
                                                            )}`
                                                        );
                                                    }}
                                                >
                                                    <SsidChartIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </>
                                    )}
                                </TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <SkeletonTable
                                rows={10}
                                cells={selectMode ? assignedSkeleton + 1 : assignedSkeleton}
                            />
                        ) : (
                            rows?.map((row) => {
                                return (
                                    <TableRow key={row.id}>
                                        {selectMode && (
                                            <TableCell>
                                                <Checkbox
                                                    checked={selectedScenarios.includes(row.id)}
                                                    onChange={() =>
                                                        setSelectedScenarios(
                                                            selectedScenarios.includes(row.id)
                                                                ? selectedScenarios.filter(
                                                                      (e) => e !== row.id
                                                                  )
                                                                : [...selectedScenarios, row.id]
                                                        )
                                                    }
                                                />
                                            </TableCell>
                                        )}
                                        <TableCell>{row.name}</TableCell>
                                        <TableCell>{row.target_reduction} %</TableCell>
                                        <TableCell>{row.target_year}</TableCell>
                                        <TableCell>{row.target_scopes_header}</TableCell>
                                        <TableCell>
                                            {`${formatMoney(
                                                sumReductionCosts(row.target_scopes_hierarchy)
                                            )} / ${formatMoney(
                                                sumOfSavings(row.target_scopes_hierarchy)
                                            )}`}
                                        </TableCell>
                                        <TableCell>{row.baseline_year}</TableCell>
                                        <TableCell>{row.site_names.join(', ')}</TableCell>
                                        <TableCell>{row.dataline_tag_name}</TableCell>
                                        {!multipleMode && (
                                            <TableCell align="right" width="20%">
                                                <Tooltip title="Copy scenario">
                                                    <IconButton
                                                        onClick={() => {
                                                            showModal(MODAL_TYPE.COPY_SCENARIO, {
                                                                title: `Copy and add as a new scenario`,
                                                                row,
                                                                reloadData,
                                                            });
                                                        }}
                                                        fullWidth
                                                    >
                                                        <ContentCopyIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Download report">
                                                    <IconButton
                                                        onClick={() => {
                                                            showModal(MODAL_TYPE.SELECT_REPORT, {
                                                                isScenario: true,
                                                                confirm: (reportType) => {
                                                                    confirmFunction(
                                                                        reportType,
                                                                        row.id
                                                                    ).catch((err) => {
                                                                        notifyError(err);
                                                                    });
                                                                },
                                                            });
                                                        }}
                                                        sx={{ mr: 0.625 }}
                                                        disabled={generatingPdf}
                                                    >
                                                        {generatingPdf &&
                                                        generatingId === row.id ? (
                                                            <CircularProgress
                                                                color="inherit"
                                                                size={20}
                                                            />
                                                        ) : (
                                                            <FeedOutlinedIcon />
                                                        )}
                                                    </IconButton>
                                                </Tooltip>
                                                <Link to={`/scenarios/edit/${row.id}`}>
                                                    <Tooltip title="Edit">
                                                        <IconButton>
                                                            <EditIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Link>
                                                <IconButton
                                                    onClick={() => {
                                                        showModal(MODAL_TYPE.CONFIRMATION, {
                                                            title: `Are you sure you want to remove scenario?`,
                                                            content: 'This action is irreversible.',
                                                            confirm: () => deleteScenario(row.id),
                                                        });
                                                    }}
                                                >
                                                    <Tooltip title="Delete">
                                                        <DeleteIcon />
                                                    </Tooltip>
                                                </IconButton>
                                            </TableCell>
                                        )}
                                    </TableRow>
                                );
                            })
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {!multipleMode && (
                <TablePagination
                    count={total || 0}
                    rowsPerPage={limit}
                    component="div"
                    page={page}
                    onPageChange={(_, newPage) => handlePageChange(newPage, setParams, limit)}
                    rowsPerPageOptions={TABLE_ROW_COUNT_OPTIONS}
                    onRowsPerPageChange={(event) => handleChangeRowsPerPage(event, setParams)}
                />
            )}
        </Grid>
    );
};

export default ScenariosTable;
