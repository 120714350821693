import {
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import SkeletonTable from '../../../components/atom/SkeletonTable';
import SortableTableCell from '../../../components/atom/SortableTableCell';

const VarianceTrackerTable = ({ rows, loading, sort, sortDir, setSorting }) => {
    const [sortedRows, setSortedRows] = useState(rows);

    const sortRows = () => {
        let sortedDifferentData = rows;
        switch (sort) {
            case 'levelType':
                if (sortDir === 'ASC') {
                    sortedDifferentData = rows.sort(
                        (a, b) => (a.levelType > b.levelType) - (a.levelType < b.levelType)
                    );
                } else {
                    sortedDifferentData = rows.sort(
                        (a, b) => (a.levelType < b.levelType) - (a.levelType > b.levelType)
                    );
                }
                break;
            case 'levelName':
                if (sortDir === 'ASC') {
                    sortedDifferentData = rows?.sort(
                        (a, b) => (a.levelName > b.levelName) - (a.levelName < b.levelName)
                    );
                } else {
                    sortedDifferentData = rows?.sort(
                        (a, b) => (a.levelName < b.levelName) - (a.levelName > b.levelName)
                    );
                }
                break;
            default:
                if (sortDir === 'ASC') {
                    sortedDifferentData = rows?.sort((a, b) => (a.id < b.id) - (a.id > b.id));
                } else {
                    sortedDifferentData = rows?.sort((a, b) => (a.id > b.id) - (a.id < b.id));
                }
                break;
        }
        setSortedRows(sortedDifferentData);
    };

    useEffect(() => {
        sortRows();
    }, [rows, sort, sortDir]);

    return (
        <Grid item xs={12}>
            <TableContainer component={Paper}>
                <Table aria-label="custom pagination table">
                    <TableHead>
                        <TableRow>
                            <SortableTableCell
                                arrow={sort === 'levelType' ? sortDir : ''}
                                onClick={() => setSorting('levelType')}
                            >
                                Level
                            </SortableTableCell>
                            <SortableTableCell
                                arrow={sort === 'levelName' ? sortDir : ''}
                                onClick={() => setSorting('levelName')}
                            >
                                Name
                            </SortableTableCell>
                            <TableCell>
                                Different CO<sub>2</sub>e (mt)
                            </TableCell>
                            <TableCell>Variance tracker</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <SkeletonTable rows={5} cells={4} />
                        ) : (
                            sortedRows?.map((row) => (
                                <TableRow key={row.id}>
                                    <TableCell>{row.levelType}</TableCell>
                                    <Tooltip title={row.levelHierarchy} placement="left">
                                        <TableCell>{row.levelName}</TableCell>
                                    </Tooltip>
                                    <TableCell>
                                        <p>
                                            {row.year}: {row.sumOfCO2e} mt
                                        </p>
                                        <p>
                                            {row.yearPrev}: {row.sumOfCO2ePrev} mt
                                        </p>
                                    </TableCell>
                                    <TableCell>
                                        <p>
                                            {!row.positive && row.percentage && '- '}
                                            {row.percentage ? `${row.percentage} %` : ' '}
                                        </p>
                                    </TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    );
};

export default VarianceTrackerTable;
