import { Button } from '@mui/material';
import React from 'react';

export const SURVEY_BUTTON_TYPES = {
    YES: 'yes',
    NO: 'no',
    OTHER: 'other',
};

export function SurveyButton({
    type = SURVEY_BUTTON_TYPES.OTHER,
    active,
    onClick,
    children,
    disabled = false,
}) {
    const otherProps = {
        variant: 'outlined',
        fullWidth: true,
        color: 'primary',
    };

    if (active === true) {
        otherProps.variant = 'contained';
    }

    if (type === SURVEY_BUTTON_TYPES.YES && active === true) {
        otherProps.color = 'success';
    }

    return (
        <Button {...otherProps} onClick={onClick} disabled={disabled}>
            {children}
        </Button>
    );
}
