import { Chip, Divider, Grid, IconButton, TextField } from '@mui/material';
import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import AutocompleteSelect from '../../../components/atom/AutoCompleteSelect';
import { sourceForSurveyAutocomplete } from '../../../autocomplete';
import SurveyDatalineFormulaPickerDropdown from './SurveyDatalineFormulaPickerDropdown';
import { MODAL_TYPE, useModals } from '../../../hooks/useModal';
import SurveySection from './SurveySection';
import AutoCompleteSelectForLocationsHierarchy from '../../../components/atom/AutoCompleteSelectForLocationsHierarchy';
import { groupEmissionSource } from '../../../helpers/groups';

function FourthStepRow({
    idx,
    datalines,
    id,
    calculationType,
    fields,
    amount,
    emissionSourceId,
    locationId,
    emissionSource,
    setFieldValue,
    searchParamMemo,
    categoryId,
}) {
    const { showModal } = useModals();
    return (
        <Grid sx={{ mt: 2, mb: 2 }}>
            <Divider sx={{ mt: 2, mb: 2, ml: -3, mr: -3 }}>
                <Chip label={idx + 1} />
            </Divider>
            <Grid
                container
                item
                xs={12}
                spacing={2}
                sx={{
                    alignItems: 'center',
                }}
            >
                <SurveySection>
                    <Grid item xs={6}>
                        <AutocompleteSelect
                            grouping={groupEmissionSource}
                            initCall={sourceForSurveyAutocomplete.init}
                            autocompleteCall={sourceForSurveyAutocomplete.search}
                            value={emissionSourceId}
                            setValue={(ev) => {
                                setFieldValue(
                                    'details.datalines',
                                    datalines?.map((line) => {
                                        if (line.id === id) {
                                            return {
                                                ...line,
                                                emissionSourceId: ev,
                                                // reset all fields on change
                                                fields: [],
                                                amount: 0,
                                                calculationType: null,
                                            };
                                        }
                                        return line;
                                    })
                                );
                            }}
                            searchParams={{ ...searchParamMemo, categoryId }}
                        >
                            <TextField label="Source" required />
                        </AutocompleteSelect>
                    </Grid>
                    <Grid item xs={5}>
                        <AutoCompleteSelectForLocationsHierarchy
                            token={searchParamMemo}
                            value={locationId}
                            setValue={(ev) => {
                                setFieldValue(
                                    'details.datalines',
                                    datalines?.map((line) => {
                                        if (line.id === id) {
                                            return {
                                                ...line,
                                                locationId: ev,
                                            };
                                        }
                                        return line;
                                    })
                                );
                            }}
                        >
                            <TextField label="Location" required />
                        </AutoCompleteSelectForLocationsHierarchy>
                    </Grid>
                    <Grid item xs={1} textAlign="right">
                        <IconButton
                            onClick={() => {
                                showModal(MODAL_TYPE.CONFIRMATION, {
                                    title: `Are you sure you want to delete this row?`,
                                    content: 'This action is irreversible.',
                                    confirm: () => {
                                        setFieldValue(
                                            'details.datalines',
                                            datalines.filter((e) => e.id !== id)
                                        );
                                    },
                                });
                            }}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Grid>
                    {emissionSource && (
                        <Grid item xs={12}>
                            <SurveyDatalineFormulaPickerDropdown
                                conversionLogic={emissionSource?.unit_conversion_logic}
                                fields={fields}
                                calculationType={calculationType}
                                setCalculationType={(newCalcType) => {
                                    setFieldValue(
                                        'details.datalines',
                                        datalines?.map((line) => {
                                            if (line.id === id) {
                                                return {
                                                    ...line,
                                                    calculationType: newCalcType,
                                                    // reset fields
                                                    fields: {},
                                                    amount: 0,
                                                };
                                            }
                                            return line;
                                        })
                                    );
                                }}
                                baseUnit={emissionSource?.base_unit}
                                amount={amount}
                                setAmount={(amountParam) => {
                                    setFieldValue(
                                        'details.datalines',
                                        datalines?.map((line) => {
                                            if (line.id === id) {
                                                return {
                                                    ...line,
                                                    amount: amountParam,
                                                };
                                            }
                                            return line;
                                        })
                                    );
                                }}
                                setFields={(newFields) => {
                                    setFieldValue(
                                        'details.datalines',
                                        datalines?.map((line) => {
                                            if (line.id === id) {
                                                return {
                                                    ...line,
                                                    fields: newFields,
                                                };
                                            }
                                            return line;
                                        })
                                    );
                                }}
                                noBaseUnit={emissionSource?.has_children}
                            />
                        </Grid>
                    )}
                </SurveySection>
            </Grid>
        </Grid>
    );
}

export default FourthStepRow;
