/**
 * Transforms a tree structure to a flat array of objects.
 * @param {Array} tree - The tree structure to transform.
 * @returns {Array} - The flat array of objects.
 * */
export default function transformTreeToFlat(tree) {
    const result = [];
    tree.forEach((item) => {
        result.push(item);
        if (item.children) {
            result.push(...transformTreeToFlat(item.children));
        }
    });
    return result.map((e) => ({
        id: e.id,
        type: e.type,
    }));
}
