import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import useNotify from '../../../hooks/useNotify';
import { doSwaggerCall } from '../../../hooks/useApi';
import NetzeroTargetsStackedBarChart from '../../dashboard/components/charts/NetzeroTargetsStackedBarChart';
import { calculateBarData } from './helpers/calculateBarData';

const NetZeroTargets = ({ year, suppliersChanged, setChartData }) => {
    const { notifyError } = useNotify();
    const [supplierBarData, setSupplierBarData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        doSwaggerCall('Suppliers', 'getNetzeroTargets', {
            year,
        })
            .then((res) => {
                setSupplierBarData(calculateBarData(res.netzeroTargets, res.perScopes, year));
                setChartData(calculateBarData(res.netzeroTargets, res.perScopes, year));
                setLoading(false);
            })
            .catch((error) => {
                notifyError(error);
            });
    }, [year, suppliersChanged, setLoading, setSupplierBarData, setChartData]);

    return loading || supplierBarData.length === 0 ? (
        <Grid item>
            <h5>No data available</h5>
        </Grid>
    ) : (
        <Grid item xs={12}>
            <NetzeroTargetsStackedBarChart
                data={supplierBarData}
                keys={['Scope 1', 'Scope 2 (Market-Based)', 'Scope 3']}
                year={year}
                indexBy="year"
                axisLeftLegend="CO2e (mt)"
            />
        </Grid>
    );
};

export default NetZeroTargets;
