import {
    Alert,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    LinearProgress,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import TreeView from '@mui/lab/TreeView/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { doSwaggerCall } from '../hooks/useApi';
import useNotify from '../hooks/useNotify';
import { transformHierarchyToItemWithoutSystemManaged } from '../helpers/groups';
import SearchInput from '../components/atom/SearchInput';
import recursiveTreeFilter from '../helpers/recursiveTreeFilter';
import getCheckedChildrenNumberRecursive from '../pages/companies/components/helpers/getCheckedChildrenNumberRecursive';
import SourcePickerScopeLevel from '../pages/companytags/availablesources/components/SourcePickerScopeLevel';
import setCheckedChildren from '../pages/companies/components/helpers/setCheckedChildren';
import CommonButton from '../components/atom/CommonButton';

function transformTreeToFlat(tree) {
    const result = [];
    tree.forEach((item) => {
        result.push(item);
        if (item.children) {
            result.push(...transformTreeToFlat(item.children));
        }
    });
    return result;
}

function extendToSources(hierarchy, checkedData) {
    const flatHierarchy = transformTreeToFlat(hierarchy);
    // resolve scope to categories
    checkedData.forEach((item) => {
        // item is like: {id: 1, type: 'scope'}
        if (item.type !== 'scope') {
            return;
        }
        const scope = hierarchy.find((e) => e.id === item.id && e.type === 'scope');
        checkedData.push(...scope.children.map((e) => ({ id: e.id, type: 'category' })));
    });

    // resolve categories to subcategories
    checkedData.forEach((item) => {
        // item is like: {id: 1, type: 'category'}
        if (item.type !== 'category') {
            return;
        }
        const category = flatHierarchy.find((e) => e.id === item.id && e.type === 'category');
        checkedData.push(...category.children.map((e) => ({ id: e.id, type: 'subcategory' })));
    });

    // resolve subcategories to sources
    checkedData.forEach((item) => {
        // item is like: {id: 1, type: 'subcategory'}
        if (item.type !== 'subcategory') {
            return;
        }
        const subcategory = flatHierarchy.find((e) => e.id === item.id && e.type === 'subcategory');
        checkedData.push(...subcategory.children.map((e) => ({ id: e.id, type: 'source' })));
    });

    return [
        ...new Set(checkedData.filter((item) => item.type === 'source').map((item) => item.id)),
    ];
}

function AddConversionLogicTemplateEmissionSourceModal({
    showModal,
    MODAL_TYPE,
    unitConversionLogic,
    reloadData,
    year,
    initiallyChecked,
    conversionTemplateId,
    title,
    content,
    warningContent,
}) {
    const [checkedData, setCheckedData] = useState(initiallyChecked);
    const { notifySuccess, notifyError } = useNotify();
    const closeAction = () => showModal(MODAL_TYPE.NONE);
    const [search, setSearch] = useState('');
    const [dataGroups, setDataGroups] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [saveInProgress, setSaveInProgress] = useState(false);
    const [saveProgress, setSaveProgress] = useState(0);

    const loadHierarchy = useCallback((targetYear) => {
        setLoading(true);
        doSwaggerCall('Hierarchy', 'getSourceHierarchy', {
            year: targetYear,
            hasanydatalines: false,
            hasFactor: false,
        })
            .then((hierarchy) => {
                const transformResult = hierarchy.map((e) =>
                    transformHierarchyToItemWithoutSystemManaged(e)
                );
                setDataGroups(transformResult);
                setLoading(false);
            })
            .catch((error) => {
                notifyError(error);
            });
    }, []);

    useEffect(() => {
        loadHierarchy(year);
    }, [year]);

    // search all hierarchy
    useEffect(() => {
        if (search === '') {
            setFilteredData(dataGroups);
            return;
        }
        setFilteredData(recursiveTreeFilter(dataGroups, search.toLowerCase()));
    }, [search, dataGroups]);

    const getCheckedChildrenNumber = useCallback(
        (item) => {
            return getCheckedChildrenNumberRecursive(checkedData, item);
        },
        [checkedData]
    );

    const onSubmit = async (checkedDataParam) => {
        setSaveInProgress(true);
        const sourceIdList = extendToSources(dataGroups, [...checkedDataParam]);
        try {
            // add connections and update emission sources
            for (let i = 0; i < sourceIdList.length; i++) {
                setSaveProgress((100 * (i + 1)) / sourceIdList.length);
                // if sourceIdList[i] contains initiallyChecked, skip it
                if (
                    initiallyChecked.find(
                        (e) => e.id === sourceIdList[i] && e.type === 'source'
                    ) !== undefined
                ) {
                    continue;
                }
                // eslint-disable-next-line no-await-in-loop
                await doSwaggerCall(
                    'EmissionSources',
                    'editEmissionSourceConversionLogic',
                    { id: sourceIdList[i] },
                    {
                        unitConversionLogic,
                        conversionTemplateId: parseInt(conversionTemplateId, 10),
                    }
                );
            }
            // remove connections
            for (let i = 0; i < initiallyChecked.length; i++) {
                setSaveProgress((100 * (i + 1)) / sourceIdList.length);
                // if sourceIdList does not contains initiallyChecked[i] we need to delete connection
                if (
                    sourceIdList.find(
                        (e) => e === initiallyChecked[i].id && initiallyChecked[i].type === 'source'
                    ) !== undefined
                ) {
                    continue;
                }
                // eslint-disable-next-line no-await-in-loop
                await doSwaggerCall('EmissionSources', 'removeEmissionSourceConversionTemplate', {
                    id: initiallyChecked[i].id,
                    conversionTemplateId: parseInt(conversionTemplateId, 10),
                });
            }
        } catch (error) {
            notifyError(error);
        }
        reloadData();
        notifySuccess('Emission source conversion logic(s) updated');
        setSaveInProgress(false);
        showModal(MODAL_TYPE.NONE);
    };

    const isValid = () => {
        return checkedData.length > 0;
    };

    return (
        <Dialog
            aria-labelledby="add-emission-source-modal"
            open
            onClose={closeAction}
            maxWidth="sm"
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent sx={{ pb: 0 }}>
                <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
                {warningContent && (
                    <DialogContentText id="alert-dialog-warning">
                        <Alert sx={{ mt: 1 }} severity="warning">
                            {warningContent}
                        </Alert>
                    </DialogContentText>
                )}
            </DialogContent>
            <Grid>
                {saveInProgress && (
                    <DialogContent>
                        <Grid container>
                            <Grid item xs={12}>
                                <LinearProgress value={saveProgress} variant="determinate" />
                            </Grid>
                        </Grid>
                    </DialogContent>
                )}
                {!saveInProgress && (
                    <DialogContent>
                        <Grid container>
                            {loading && (
                                <Grid item xs={12}>
                                    <CircularProgress size="1.5rem" />
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <SearchInput fullWidth search={search} setSearch={setSearch} />
                            </Grid>
                            {filteredData.map((scope) => (
                                <Grid item xs={12} key={`${scope.id} - ${scope.name}`}>
                                    <TreeView
                                        key={`${scope.name} - ${scope.id}`}
                                        aria-label="icon expansion"
                                        defaultCollapseIcon={<ExpandMoreIcon />}
                                        defaultExpandIcon={<ChevronRightIcon />}
                                        defaultExpanded={[]}
                                    >
                                        <SourcePickerScopeLevel
                                            key={`${scope.id} - ${scope.name}`}
                                            checkedData={checkedData}
                                            scope={scope}
                                            setCheckedData={setCheckedData}
                                            setCheckedChildren={setCheckedChildren}
                                            getCheckedChildrenNumber={getCheckedChildrenNumber}
                                        />
                                    </TreeView>
                                </Grid>
                            ))}
                        </Grid>
                    </DialogContent>
                )}
            </Grid>
            {!saveInProgress && (
                <DialogActions>
                    <CommonButton
                        onClick={() => showModal(MODAL_TYPE.NONE)}
                        label="Close"
                        buttonType="secondary"
                    />
                    <CommonButton
                        disabled={!isValid}
                        onClick={() => onSubmit(checkedData)}
                        label="Submit"
                        buttonType="primary"
                    />
                </DialogActions>
            )}
        </Dialog>
    );
}

export default AddConversionLogicTemplateEmissionSourceModal;
