// function to filter out categories that have a selected scope
export function filterTargetData(data, targetType) {
    const scopeIds = [];
    data.forEach((item) => {
        if (item.type === 'scope') {
            scopeIds.push(item.id);
        }
    });
    const filteredData = data.filter((item) => {
        return !(item.type === 'category' && scopeIds.includes(item.scopeId));
    });

    return filteredData.filter((e) => e.type === targetType).map((i) => i.id);
}

// function to keep only the scopeId for categories
export function filterTargetDataToScope(data) {
    const scopeIds = [];
    data.forEach((item) => {
        if (item.type === 'scope') {
            scopeIds.push(item.id);
        }
        if (item.type === 'category') {
            scopeIds.push(item.scopeId);
        }
    });
    return [...new Set(scopeIds)];
}

export function treeCut(tree, selectedTargetData) {
    const selectedScopes = selectedTargetData
        .filter((item) => item.type === 'scope')
        .map((item) => item.id);
    const implicitSelectedScopes = selectedTargetData
        .filter((item) => item.type === 'category')
        .map((item) => item.scopeId);
    const selectedCategories = selectedTargetData
        .filter((item) => item.type === 'category')
        .map((item) => item.id);

    // cut the top level, keep items that are in selectedTargetData AND type scope
    let newTree = tree.filter((item) => {
        // keep whole scope
        return (
            selectedScopes.includes(item.id) ||
            // keep scope if we have any categories in it that are selected
            implicitSelectedScopes.includes(item.id)
        );
    });
    // cut the categories, keep items that are in selectedTargetData AND type category
    newTree = newTree.map((item) => {
        // whole scope is selected
        if (selectedScopes.includes(item.id)) {
            return item;
        }
        // only some categories are selected
        const newItem = {
            ...item,
            children: item.children.filter((category) => selectedCategories.includes(category.id)),
        };
        // recalculate sum for the scope, since we have removed some categories
        newItem.baseline = newItem.children.reduce((acc, curr) => {
            return acc + curr.baseline;
        }, 0);
        newItem.target = newItem.children.reduce((acc, curr) => {
            return acc + curr.target;
        }, 0);
        return newItem;
    });
    return newTree;
}
