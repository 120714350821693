import {
    ButtonGroup,
    CircularProgress,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    TextField,
} from '@mui/material';
import React, { useState, useCallback, useEffect } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeView from '@mui/lab/TreeView';
import { useParams } from 'react-router-dom';
import { doSwaggerCall } from '../../hooks/useApi';
import ComponentTitle from '../ComponentTitle';
import { useModals, MODAL_TYPE } from '../../hooks/useModal';
import useNotify from '../../hooks/useNotify';
import SearchInput from '../atom/SearchInput';
import recursiveTreeFilter from '../../helpers/recursiveTreeFilter';
import getCheckedChildrenNumberRecursive from '../../pages/companies/components/helpers/getCheckedChildrenNumberRecursive';
import CommonButton from '../atom/CommonButton';
import { transformHierarchyToItem } from '../../helpers/groups';
import EsgSubcategoryLevel from './components/EsgSubcategoryLevel';
import AutocompleteSelect from '../atom/AutoCompleteSelect';
import { esgFrameworkAutocomplete } from '../../autocomplete';

const EsgRequirementsHierarchy = ({
    localCompanyYear,
    setLocalCompanyYear,
    availableYears,
    checkedData,
    setCheckedData,
    reloadData,
    newCompany,
    newSite,
}) => {
    const { companyId, siteId } = useParams();
    const [dataGroups, setDataGroups] = useState([]);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const { showModal } = useModals();
    const { notifyError } = useNotify();
    const [esgFramework, setEsgFramework] = useState(null);

    const loadHierarchy = useCallback((targetYear, targetEsgFramework) => {
        setLoading(true);
        doSwaggerCall('EsgQuestions', 'getEsgQuestionHierarchy', {
            year: targetYear,
            hasanyesgs: false,
            esgFrameworkId: targetEsgFramework,
        })
            .then((hierarchy) => {
                const transformResult = hierarchy.map((e) => transformHierarchyToItem(e));
                setDataGroups(transformResult);
                setLoading(false);
            })
            .catch((error) => {
                notifyError(error);
            });
    }, []);

    useEffect(() => {
        loadHierarchy(localCompanyYear, esgFramework);
    }, [localCompanyYear, esgFramework]);

    // search all hierarchy
    useEffect(() => {
        if (search === '') {
            setFilteredData(dataGroups);
            return;
        }
        setFilteredData(recursiveTreeFilter(dataGroups, search.toLowerCase()));
    }, [search, dataGroups]);

    const getCheckedChildrenNumber = useCallback(
        (item) => {
            return getCheckedChildrenNumberRecursive(checkedData, item);
        },
        [checkedData]
    );

    return (
        <Grid item xs={12} sx={{ pt: 2 }}>
            <Paper sx={{ p: 2, width: '100%' }}>
                <Grid
                    container
                    item
                    xs={12}
                    justifyContent="space-between"
                    spacing={2}
                    sx={{ pb: 2 }}
                >
                    <Grid item style={{ alignSelf: 'center' }}>
                        <ComponentTitle variant="h5" title="ESG Inventory Boundary (Hierarchy)" />
                    </Grid>
                    <Grid item>
                        <FormControl fullWidth>
                            <InputLabel id="year-select">Year</InputLabel>
                            <Select
                                fullWidth
                                labelId="year-select"
                                id="demo-simple-select"
                                value={localCompanyYear}
                                size="small"
                                label="Year"
                                onChange={(e) => {
                                    setLocalCompanyYear(parseInt(e.target.value, 10));
                                }}
                            >
                                {availableYears.map((yearItem) => (
                                    <MenuItem key={yearItem} value={yearItem}>
                                        {yearItem}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <ButtonGroup>
                            <CommonButton
                                onClick={() => {
                                    showModal(MODAL_TYPE.COPY_ESG_TO_ANOTHER_YEAR, {
                                        title: `Are you sure you want to copy ESG inventory boundary from ${localCompanyYear}?`,
                                        content: 'This action is irreversible.',
                                        companyId,
                                        siteId,
                                        year: localCompanyYear,
                                        availableYears,
                                        reloadData: () => reloadData(),
                                    });
                                }}
                                disabled={newCompany || newSite}
                                label="Copy to ..."
                                buttonType="outlined"
                            />
                            <CommonButton
                                onClick={() => {
                                    showModal(MODAL_TYPE.COPY_ESG_FROM_ANOTHER_YEAR, {
                                        title: `Are you sure you want to copy ESG inventory boundary to ${localCompanyYear}?`,
                                        content: 'This action is irreversible.',
                                        companyId,
                                        siteId,
                                        year: localCompanyYear,
                                        availableYears,
                                        reloadData: () => reloadData(),
                                    });
                                }}
                                disabled={newCompany || newSite}
                                label="Copy from ..."
                                buttonType="outlined"
                            />
                        </ButtonGroup>
                    </Grid>
                </Grid>
                <Grid container>
                    {loading && (
                        <Grid item xs={12}>
                            <CircularProgress size="1.5rem" />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <AutocompleteSelect
                            initCall={esgFrameworkAutocomplete.init}
                            autocompleteCall={esgFrameworkAutocomplete.search}
                            value={esgFramework}
                            setValue={(ev) => {
                                setEsgFramework(ev);
                            }}
                        >
                            <TextField label="Esg Framework" fullWidth size="small" />
                        </AutocompleteSelect>
                    </Grid>
                    <Grid item xs={12}>
                        <SearchInput fullWidth search={search} setSearch={setSearch} />
                    </Grid>
                    {filteredData.map((esgCategory) => (
                        <Grid item xs={12} key={`${esgCategory.id} - ${esgCategory.name}`}>
                            {esgCategory.name}
                            {esgCategory.children.map((esgSubcategory) => (
                                <TreeView
                                    key={`${esgCategory.name} - ${esgSubcategory.id} - ${esgSubcategory.name}`}
                                    aria-label="icon expansion"
                                    defaultCollapseIcon={<ExpandMoreIcon />}
                                    defaultExpandIcon={<ChevronRightIcon />}
                                    defaultExpanded={[]}
                                >
                                    <EsgSubcategoryLevel
                                        key={`${esgSubcategory.id} - ${esgSubcategory.name}`}
                                        checkedData={checkedData}
                                        esg_subcategory={esgSubcategory}
                                        setCheckedData={setCheckedData}
                                        getCheckedChildrenNumber={getCheckedChildrenNumber}
                                    />
                                </TreeView>
                            ))}
                        </Grid>
                    ))}
                </Grid>
            </Paper>
        </Grid>
    );
};

export default EsgRequirementsHierarchy;
