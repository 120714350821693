import { FormControl, Grid, TextField, Autocomplete, InputAdornment } from '@mui/material';
import React, { useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import useEgridData from '../../hooks/useEgridData';
import PageWithTitle from '../../components/atom/PageWithTitle';
import BlockWithTitle from '../../components/atom/BlockWithTitle';

const EgridPickerPage = () => {
    const [uniqueStates, stateToSubgrid, zipToSubgrid] = useEgridData();
    const [zip, setZip] = useState('');
    const [state, setState] = useState('');

    return (
        <PageWithTitle title="eGRID Subregions">
            <Grid item xs={4}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <BlockWithTitle title="State based search">
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        options={uniqueStates}
                                        fullWidth
                                        renderInput={(params) => (
                                            <TextField {...params} label="State" />
                                        )}
                                        onChange={(_, newValue) => {
                                            setState(newValue);
                                        }}
                                        value={state}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <b>Subregions:</b>
                                <br />
                                {stateToSubgrid[state]?.map((s) => {
                                    return <Grid key={s}>{s}</Grid>;
                                })}
                            </Grid>
                        </BlockWithTitle>
                    </Grid>
                    <Grid item xs={12}>
                        <BlockWithTitle title="Zip code based search">
                            <Grid item xs={12}>
                                <Grid item xs={12} sx={{ mb: 2 }} style={{ textAlign: 'right' }}>
                                    <TextField
                                        autoFocus
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        placeholder="Search..."
                                        value={zip || ''}
                                        size="small"
                                        onChange={(ev) => {
                                            setZip(ev.target.value);
                                        }}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <b>Subregions:</b>
                                <br />
                                {zipToSubgrid[zip]?.map((s) => {
                                    return <Grid key={s}>{s}</Grid>;
                                })}
                            </Grid>
                        </BlockWithTitle>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={8}>
                <img
                    src="/assets/map_usa_zip.png"
                    alt="map"
                    style={{ maxWidth: '550px', maxHeight: '550px' }}
                />
            </Grid>
        </PageWithTitle>
    );
};

export default EgridPickerPage;
