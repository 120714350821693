import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import React from 'react';

function SortIndicator({ arrow }) {
    if (arrow === 'ASC') {
        return <ArrowDropUpIcon />;
    }
    if (arrow === 'DESC') {
        return <ArrowDropDownIcon />;
    }
    return null;
}
export default SortIndicator;
