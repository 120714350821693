import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ComponentTitle from '../../components/ComponentTitle';
import { useApi } from '../../hooks/useApi';
import SitesTable from './components/SitesTable';
import useNotify from '../../hooks/useNotify';
import SearchInput from '../../components/atom/SearchInput';
import { MODAL_TYPE, useModals } from '../../hooks/useModal';
import { parseXlsxSheets, runImport } from '../../helpers/excelImportSitesHelpers';
import { useAuth } from '../../hooks/useAuth';
import {
    ROLES,
    TABLE_ROW_COUNT_DEFAULT,
    WARNING_CONTENT_RECALCULATE_TEXT,
} from '../../helpers/constans';
import useSorting from '../../hooks/useSorting';
import CommonButton from '../../components/atom/CommonButton';
import ImportExportButtonGroup from '../../components/atom/ImportExportButtonGroup';

const SitesPage = () => {
    const navigate = useNavigate();
    const { companyId } = useParams();
    const [search, setSearch] = useState('');
    const [{ limit, page }, setParams] = useState({ limit: TABLE_ROW_COUNT_DEFAULT, page: 0 });
    const { notifyError, notifySuccess } = useNotify();
    const { sort, sortDir, setSorting } = useSorting(['created_at', 'ASC']);
    const [importingXlsx, setImportingXlsx] = useState(false);
    const { showModal } = useModals();
    const { user } = useAuth();

    const [sitesData, loading, error, setData, reloadData] = useApi('Sites', 'getSitesInCompany', {
        id: companyId,
        limit,
        offset: page * limit,
        search,
        sort,
        sortDir,
    });

    useEffect(() => {
        if (error) {
            notifyError(error);
        }
    }, [error, notifyError]);

    const handleImport = async (e) => {
        try {
            return parseXlsxSheets(e.target.files[0], companyId, (parsedData) => {
                runImport(parsedData, true, companyId).then((dryRunResult) => {
                    const dryRunFlat = Object.entries(dryRunResult).reduce(
                        (sum, item) => [...sum, ...item[1]],
                        []
                    );
                    showModal(MODAL_TYPE.CONFIRMATION_IMPORT_SITES, {
                        title: `Are you sure you want to import this xlsx?`,
                        content: 'This action is irreversible.',
                        warningContent: WARNING_CONTENT_RECALCULATE_TEXT,
                        data: dryRunFlat,
                        setImportingXlsx,
                        confirm: async () => {
                            await runImport(parsedData, false, companyId);
                            notifySuccess('Import successful');
                            setImportingXlsx(false);
                            reloadData();
                        },
                    });
                });
            });
        } catch (err) {
            setImportingXlsx(false);
            notifyError(err);
        }
    };

    return (
        <Grid container alignContent="flex-start">
            <Grid container item xs={12} justifyContent="space-between" spacing={0}>
                <Grid item>
                    <ComponentTitle variant="h4" title="Sites" />
                </Grid>
                <Grid item>
                    <Grid
                        item
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        <Grid item>
                            <SearchInput
                                search={search}
                                setSearch={setSearch}
                                setParams={setParams}
                                limit={limit}
                            />
                        </Grid>
                        {user.role === ROLES.SUPERADMIN && (
                            <Grid item>
                                <CommonButton
                                    onClick={() => navigate(`/companies`)}
                                    label="Back"
                                    buttonType="secondary"
                                />
                            </Grid>
                        )}
                        <ImportExportButtonGroup
                            type="sites"
                            companyId={companyId}
                            sitePage
                            handleImport={handleImport}
                            importingXlsx={importingXlsx}
                            setImportingXlsx={setImportingXlsx}
                            navigate={async () => navigate(`/companies/${companyId}/sites/new`)}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <SitesTable
                rows={sitesData?.sites}
                loading={loading}
                total={sitesData?.total}
                limit={limit}
                page={page}
                companyId={companyId}
                reloadData={reloadData}
                setParams={setParams}
                sort={sort}
                sortDir={sortDir}
                setSorting={setSorting}
            />
        </Grid>
    );
};

export default SitesPage;
