import {
    Checkbox,
    CircularProgress,
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DeselectIcon from '@mui/icons-material/Deselect';
import WarningIcon from '@mui/icons-material/Warning';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { MODAL_TYPE, useModals } from '../../../hooks/useModal';
import { doSwaggerCall, uploadDocumentToMultipleDatalines } from '../../../hooks/useApi';
import useNotify from '../../../hooks/useNotify';
import SkeletonTable from '../../../components/atom/SkeletonTable';
import { convertUnitGToKg, formatNumber } from '../../../helpers/convertUnits';
import SortableTableCell from '../../../components/atom/SortableTableCell';
import EditDatalinePage from '../../datalineedit/EditDatalinePage';
import TableDetails from '../../../components/atom/TableDetails';
import { TABLE_ROW_COUNT_OPTIONS } from '../../../helpers/constans';
import { handleChangeRowsPerPage, handlePageChange } from '../../../helpers/handleTableFunctions';
import MultipleLineSelect from '../../../components/atom/MultipleLineSelect';
import ResponsibilityTableCell from '../../../components/atom/ResponsibilityTableCell';
import TableSelectDropdown from './TableSelectDropdown';
import checkAirportCalculatorVisibility from '../../../helpers/checkAirportCalculatorVisibility';

const DatalinesTable = ({
    rows,
    limit,
    page,
    setParams,
    total,
    loading,
    reloadData,
    sort,
    sortDir,
    setSorting,
    responsibility,
    edited,
    setEdited,
    selectMode,
    setSelectMode,
    setSelectedDatalines,
    selectedDatalines,
    deleteDatalinesList,
    emissionSourceId,
    sites,
    manageDatalinePermission,
    basicModifyDatalinePermission,
    complexModifyDatalinePermission,
    closedYear,
    setGroupOpen,
}) => {
    const { showModal } = useModals();
    const { notifyError, notifySuccess, notifyInfo } = useNotify();
    const [datalineHierarchy, setDatalineHierarchy] = useState(null);
    const [loadingDatalineHierarchy, setLoadingDatalineHierarchy] = useState(true);

    const deleteDataline = useCallback((id) => {
        doSwaggerCall('Datalines', 'deleteDatalinesByList', { idList: [id] })
            .then(() => {
                notifySuccess('Dataline deleted');
                reloadData();
            })
            .catch((err) => {
                notifyError(err);
            });
    }, []);

    const handleSelectAll = (ev) => {
        if (ev.target.checked) {
            const newSelectedDatalines = rows.filter((dataline) => !dataline.system_managed);
            setSelectedDatalines(newSelectedDatalines);
            return;
        }
        setSelectedDatalines([]);
    };

    const assignedSkeleton = !responsibility ? 11 : 10;
    const rowsIdList = rows?.filter((dataline) => !dataline.system_managed).map((r) => r.id);

    // TODO: cant upload the same docs again
    const uploadEventHandler = async (event) => {
        if (!event.target.files || !event.target.files.length) {
            return;
        }
        const f = event.target.files[0];
        notifyInfo(`Upload started for ${f.name}`);
        await uploadDocumentToMultipleDatalines(
            f,
            selectedDatalines.map((e) => e.id)
        );
        notifyInfo(`Upload finished for ${f.name}`);
        reloadData();
        setSelectedDatalines([]);
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if (emissionSourceId) {
            setLoadingDatalineHierarchy(true);
            doSwaggerCall('EmissionSources', 'getEmissionSources', { idList: [emissionSourceId] })
                .then((res) => {
                    setDatalineHierarchy({
                        scopeName: res.emissionSources[0].scope_name,
                        categoryName: res.emissionSources[0].category_name,
                        subcategoryName: res.emissionSources[0].subcategory_name,
                        emissionSourceName: res.emissionSources[0].name,
                    });
                    setLoadingDatalineHierarchy(false);
                })
                .catch((err) => {
                    setLoadingDatalineHierarchy(false);
                    notifyError(err);
                });
        }
    }, [emissionSourceId]);

    return (
        <Grid item xs={12}>
            {edited === `new - ${emissionSourceId}` && (
                <Paper sx={{ mb: 5, p: 2, border: '2px solid #0f304a' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {loadingDatalineHierarchy ? (
                                <CircularProgress size={20} />
                            ) : (
                                <EditDatalinePage
                                    newDataline
                                    sites={sites}
                                    reloadData={reloadData}
                                    setEdited={setEdited}
                                    emissionSourceId={emissionSourceId}
                                    setGroupOpen={setGroupOpen}
                                    isAirportCalculatorVisible={checkAirportCalculatorVisibility(
                                        datalineHierarchy.scopeName,
                                        datalineHierarchy.categoryName,
                                        datalineHierarchy.subcategoryName,
                                        datalineHierarchy.emissionSourceName
                                    )}
                                />
                            )}
                        </Grid>
                    </Grid>
                </Paper>
            )}
            <TableContainer>
                <Table aria-label="custom pagination table">
                    <TableHead>
                        <TableRow>
                            {selectMode &&
                                (basicModifyDatalinePermission ||
                                    complexModifyDatalinePermission ||
                                    manageDatalinePermission) &&
                                !closedYear && (
                                    <TableCell>
                                        <Checkbox
                                            checked={
                                                rowsIdList?.length > 0 &&
                                                selectedDatalines.length === rowsIdList.length
                                            }
                                            onChange={(ev) => handleSelectAll(ev)}
                                            indeterminate={
                                                selectedDatalines.length > 0 &&
                                                selectedDatalines.length < rowsIdList.length
                                            }
                                        />
                                    </TableCell>
                                )}
                            <SortableTableCell
                                arrow={sort === 'emission_description' ? sortDir : ''}
                                onClick={() => setSorting('emission_description')}
                            >
                                Emission Description
                            </SortableTableCell>
                            <SortableTableCell
                                arrow={sort === 'source' ? sortDir : ''}
                                onClick={() => setSorting('source')}
                                width="20%"
                            >
                                Source
                            </SortableTableCell>
                            <SortableTableCell
                                arrow={sort === 'sitename' ? sortDir : ''}
                                onClick={() => setSorting('sitename')}
                                width="10%"
                            >
                                Site
                            </SortableTableCell>
                            <SortableTableCell
                                arrow={sort === 'amount' ? sortDir : ''}
                                onClick={() => setSorting('amount')}
                                width="20%"
                            >
                                Amount (fields)
                            </SortableTableCell>
                            <SortableTableCell
                                arrow={sort === 'co2e' ? sortDir : ''}
                                onClick={() => setSorting('co2e')}
                                width="10%"
                            >
                                CO<sub>2</sub>e (mt)
                            </SortableTableCell>
                            {responsibility !== 'assignedToMe' && (
                                <SortableTableCell
                                    arrow={sort === 'assignee' ? sortDir : ''}
                                    onClick={() => setSorting('assignee')}
                                    width="5%"
                                    justifyContent="center"
                                >
                                    Assignee
                                </SortableTableCell>
                            )}
                            {responsibility !== 'ownedByMe' && (
                                <SortableTableCell
                                    arrow={sort === 'owner' ? sortDir : ''}
                                    onClick={() => setSorting('owner')}
                                    width="5%"
                                    justifyContent="center"
                                >
                                    Owner
                                </SortableTableCell>
                            )}
                            <SortableTableCell
                                arrow={sort === 'documents' ? sortDir : ''}
                                onClick={() => setSorting('documents')}
                                width="6%"
                            >
                                Doc.
                            </SortableTableCell>
                            <SortableTableCell
                                arrow={sort === 'isestimated' ? sortDir : ''}
                                onClick={() => setSorting('isestimated')}
                                width="6%"
                            >
                                Est.
                            </SortableTableCell>
                            <TableCell>Notes</TableCell>
                            {!closedYear && (
                                <TableCell align="right">
                                    {(basicModifyDatalinePermission ||
                                        complexModifyDatalinePermission ||
                                        manageDatalinePermission) &&
                                        (!selectMode ? (
                                            <MultipleLineSelect setSelectMode={setSelectMode} />
                                        ) : (
                                            <div>
                                                <Tooltip title="Cancel selection">
                                                    <IconButton
                                                        onClick={() => {
                                                            setSelectMode(false);
                                                        }}
                                                    >
                                                        <DeselectIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Select action">
                                                    <IconButton onClick={handleClick}>
                                                        <MoreHorizIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <TableSelectDropdown
                                                    open={open}
                                                    anchorEl={anchorEl}
                                                    onClose={handleClose}
                                                    reloadData={reloadData}
                                                    selectedDatalines={selectedDatalines}
                                                    setSelectedDatalines={setSelectedDatalines}
                                                    deleteDatalinesList={deleteDatalinesList}
                                                    uploadEventHandler={uploadEventHandler}
                                                    permissions={{
                                                        basicModifyDatalinePermission,
                                                        complexModifyDatalinePermission,
                                                        manageDatalinePermission,
                                                    }}
                                                />
                                            </div>
                                        ))}
                                </TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <SkeletonTable
                                rows={limit}
                                cells={selectMode ? assignedSkeleton + 1 : assignedSkeleton}
                            />
                        ) : (
                            rows?.map((row) => (
                                <React.Fragment key={row.id}>
                                    <TableRow style={edited === row.id ? { display: 'none' } : {}}>
                                        {selectMode && (
                                            <TableCell>
                                                <Checkbox
                                                    disabled={row.system_managed}
                                                    checked={selectedDatalines
                                                        .map((e) => e.id)
                                                        ?.includes(row.id)}
                                                    onChange={() =>
                                                        setSelectedDatalines(
                                                            selectedDatalines
                                                                ?.map((e) => e.id)
                                                                .includes(row.id)
                                                                ? selectedDatalines.filter(
                                                                      (e) => e.id !== row.id
                                                                  )
                                                                : [
                                                                      ...selectedDatalines,
                                                                      {
                                                                          id: row.id,
                                                                          system_managed:
                                                                              row.system_managed,
                                                                      },
                                                                  ]
                                                        )
                                                    }
                                                />
                                            </TableCell>
                                        )}
                                        <TableCell>
                                            <Grid alignItems="center" style={{ display: 'flex' }}>
                                                {row.incorrect === true && (
                                                    <WarningIcon
                                                        sx={{ mr: 1 }}
                                                        style={{ color: 'red' }}
                                                    />
                                                )}
                                                {row.emission_description}
                                            </Grid>
                                        </TableCell>
                                        <Tooltip
                                            title={`${row.scope_name} > ${row.category} > ${row.subcategory}`}
                                            placement="left"
                                        >
                                            <TableCell>{row.emission_source}</TableCell>
                                        </Tooltip>
                                        <TableCell>
                                            {row.site_id === null ? 'Corporate' : row.site_name}
                                        </TableCell>
                                        <TableCell>
                                            {Object.entries(row.fields).length > 0
                                                ? Object.entries(row.fields).map((field) => (
                                                      <p>
                                                          {field[0]}: {field[1].displayValue}
                                                      </p>
                                                  ))
                                                : `${formatNumber(row.amount)} ${row.base_unit}`}
                                        </TableCell>
                                        <TableCell>
                                            {formatNumber(convertUnitGToKg(row.co2e) / 1000)}
                                        </TableCell>
                                        <ResponsibilityTableCell
                                            row={row.assignedUser}
                                            responsibility={responsibility}
                                            type="assignedToMe"
                                        />
                                        <ResponsibilityTableCell
                                            row={row.ownerUser}
                                            responsibility={responsibility}
                                            type="ownedByMe"
                                        />
                                        <TableCell>
                                            <Grid container spacing={1} flexWrap="nowrap">
                                                <Grid item />
                                                <TableDetails
                                                    row={row}
                                                    onlyDocs
                                                    onlyEstimated={false}
                                                />
                                            </Grid>
                                        </TableCell>
                                        <TableCell>
                                            <Grid container spacing={1} flexWrap="nowrap">
                                                <Grid item />
                                                <TableDetails
                                                    row={row}
                                                    onlyDocs={false}
                                                    onlyEstimated
                                                />
                                            </Grid>
                                        </TableCell>
                                        <TableCell>
                                            <Grid
                                                container
                                                spacing={1}
                                                alignItems="center"
                                                flexWrap="nowrap"
                                            >
                                                <Grid item />
                                                <TableDetails
                                                    row={row}
                                                    onlyDocs={false}
                                                    onlyEstimated={false}
                                                />
                                            </Grid>
                                        </TableCell>
                                        <TableCell align="right" width="10%">
                                            <Tooltip title={row.system_managed ? 'View' : 'Edit'}>
                                                <span>
                                                    <IconButton
                                                        onClick={() => {
                                                            setEdited(row.id);
                                                        }}
                                                        disabled={selectMode}
                                                    >
                                                        {!row?.system_managed && !closedYear ? (
                                                            <EditIcon />
                                                        ) : (
                                                            <RemoveRedEyeIcon />
                                                        )}
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                            {manageDatalinePermission &&
                                                !row?.system_managed &&
                                                !closedYear && (
                                                    <IconButton
                                                        onClick={() => {
                                                            showModal(MODAL_TYPE.CONFIRMATION, {
                                                                title: `Are you sure you want to remove dataline?`,
                                                                content:
                                                                    'This action is irreversible.',
                                                                confirm: () =>
                                                                    deleteDataline(row.id),
                                                            });
                                                        }}
                                                        disabled={selectMode}
                                                    >
                                                        <Tooltip title="Delete">
                                                            <DeleteIcon />
                                                        </Tooltip>
                                                    </IconButton>
                                                )}
                                        </TableCell>
                                    </TableRow>
                                    {edited === row.id && (
                                        <TableRow
                                            className={edited === row.id ? 'editedTableRow' : ''}
                                        >
                                            <TableCell colSpan={selectMode ? 12 : 11}>
                                                <EditDatalinePage
                                                    newDataline={false}
                                                    datalineIdParam={row.id}
                                                    reloadData={reloadData}
                                                    setEdited={setEdited}
                                                    emissionSourceId={emissionSourceId}
                                                    setGroupOpen={setGroupOpen}
                                                    isAirportCalculatorVisible={checkAirportCalculatorVisibility(
                                                        row.scope_name,
                                                        row.category,
                                                        row.subcategory,
                                                        row.emission_source
                                                    )}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </React.Fragment>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                count={total || 0}
                rowsPerPage={limit}
                component="div"
                page={page}
                onPageChange={(_, newPage) => handlePageChange(newPage, setParams, limit)}
                rowsPerPageOptions={TABLE_ROW_COUNT_OPTIONS}
                onRowsPerPageChange={(event) => handleChangeRowsPerPage(event, setParams)}
            />
        </Grid>
    );
};

export default DatalinesTable;
