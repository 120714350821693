import React, { useEffect, useState } from 'react';
import {
    DialogActions,
    DialogContentText,
    DialogContent,
    DialogTitle,
    Dialog,
    TextField,
    Grid,
    CircularProgress,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { doSwaggerCall, useApi } from '../hooks/useApi';
import useNotify from '../hooks/useNotify';
import CommonButton from '../components/atom/CommonButton';

const AddLogicTemplateModal = ({ title, content, showModal, MODAL_TYPE }) => {
    const closeAction = () => showModal(MODAL_TYPE.NONE);
    const [newTemplateName, setNewTemplateName] = useState('');
    const [newBaseUnitName, setNewBaseUnitName] = useState('');
    const { notifyError, notifySuccess } = useNotify();
    const navigate = useNavigate();
    const [templates, loading, error] = useApi('ConversionTemplates', 'getConversionTemplates');
    const saveButtonDisabledCondition =
        newTemplateName === '' ||
        newBaseUnitName === '' ||
        templates?.templates.map((template) => template.name).includes(newTemplateName);

    const addConversionTemplate = async () => {
        doSwaggerCall(
            'ConversionTemplates',
            'addConversionTemplate',
            {},
            {
                name: newTemplateName,
                conversionLogics: [],
                baseUnit: newBaseUnitName,
            }
        )
            .then((res) => {
                navigate(`conversionlogictemplates/edit/${res.id}`);
                notifySuccess('Conversion template added');
            })
            .catch((err) => notifyError(err));
    };

    useEffect(() => {
        if (error) {
            notifyError('Something went wrong');
        }
    }, [error, notifyError]);

    return (
        <Dialog
            open
            maxWidth="xs"
            fullWidth
            onClose={closeAction}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
                <Grid>
                    {!loading ? (
                        <>
                            <TextField
                                fullWidth
                                required
                                value={newTemplateName}
                                label="Template name"
                                autoComplete="off"
                                onChange={(e) => {
                                    setNewTemplateName(e.target.value);
                                }}
                            />
                            <TextField
                                fullWidth
                                required
                                autoComplete="off"
                                value={newBaseUnitName}
                                label="Base unit name"
                                onChange={(e) => {
                                    setNewBaseUnitName(e.target.value);
                                }}
                            />
                        </>
                    ) : (
                        <CircularProgress size="1.5rem" />
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <CommonButton
                    onClick={closeAction}
                    autoFocus
                    label="Cancel"
                    buttonType="secondary"
                />
                <CommonButton
                    onClick={() => {
                        addConversionTemplate();
                        showModal(MODAL_TYPE.NONE);
                    }}
                    style={{ color: saveButtonDisabledCondition ? 'grey' : 'red' }} // TODO: should do inside common button component
                    disabled={saveButtonDisabledCondition}
                    label="Save"
                    buttonType="primary"
                />
            </DialogActions>
        </Dialog>
    );
};

export default AddLogicTemplateModal;
