import { Grid, Paper, Typography } from '@mui/material';
import React from 'react';
import { convertUnitGToKg, formatNumber } from '../../helpers/convertUnits';

const EmissionBox = ({ title, co2 }) => {
    return (
        <Grid item style={{ flex: '1 1 0' }}>
            <Paper sx={{ p: 2 }}>
                <Typography variant="subtitle1">{title}</Typography>
                <Typography variant="h4" fontWeight="bolder">
                    {formatNumber(convertUnitGToKg(co2) / 1000)}
                </Typography>
            </Paper>
        </Grid>
    );
};
export default EmissionBox;
