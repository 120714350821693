import React, { useEffect, useState } from 'react';
import { Grid, LinearProgress } from '@mui/material';
import useNotify from '../../../hooks/useNotify';
import { doSwaggerCall } from '../../../hooks/useApi';
import CategoryOrTagOrSourceOrSupplierBarChart from './charts/CategoryOrTagOrSourceOrSupplierBarChart';

const BySelectedSuppliers = ({ suppliers, years, setChartData }) => {
    const { notifyError } = useNotify();
    const [supplierBarData, setSupplierBarData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        doSwaggerCall('Dashboards', 'getDataBySuppliersForBarChart', {
            suppliers,
            years,
        })
            .then((res) => {
                setSupplierBarData(res);
                setChartData(res.suppliersData);
                setLoading(false);
            })
            .catch((error) => {
                notifyError(error);
            });
    }, [suppliers, years, setLoading, setSupplierBarData, setChartData]);

    return (
        <Grid item xs={12}>
            {supplierBarData?.length === 0 ? (
                <Grid item>
                    {loading ? <LinearProgress sx={{ mt: 0.25 }} /> : <h5>No data available</h5>}
                </Grid>
            ) : (
                <CategoryOrTagOrSourceOrSupplierBarChart
                    data={supplierBarData.suppliersData}
                    keys={years}
                    indexBy="name"
                    axisLeftLegend="CO2e (mt)"
                    legendOffset={-55}
                />
            )}
        </Grid>
    );
};

export default BySelectedSuppliers;
