import { saveAs } from 'file-saver';
import { utils, write } from 'xlsx';

/**
 * Generate xlsx data from an array of objects or a single object
 * @param {array|object} data array of objects or a single object to convert to xlsx rows
 * @returns header fields from object keys and rows from values
 */
export const generateXlsxData = (data) => {
    const headers = Object.keys(data[0]);
    const rows = data.map((element) => {
        return headers.map((header) => {
            let value = element[header];
            if (Array.isArray(value)) {
                value = value
                    .map((e) => {
                        if (typeof e === 'object') {
                            return JSON.stringify(e);
                        }
                        return e;
                    })
                    .join('\n');
            } else if (typeof value === 'object') {
                value = JSON.stringify(value);
            }
            return value;
        });
    });

    return [headers, ...rows];
};

function mapObjectWithKeyTransform(obj, transform) {
    return Object.fromEntries(
        Object.entries(obj).map(([k, v]) => {
            return [transform(k), v];
        })
    );
}

function flattenObject(obj, prefix = '') {
    const flatObject = {};
    let inputObject = JSON.parse(JSON.stringify(obj));

    if (Array.isArray(inputObject)) {
        while (true) {
            let didTransform = false;
            Object.keys(inputObject).forEach((key) => {
                // list + object
                if (
                    typeof inputObject[key] === 'object' &&
                    obj[key] !== null &&
                    !Array.isArray(obj[key])
                ) {
                    didTransform = true;
                    inputObject = {
                        ...inputObject,
                        ...mapObjectWithKeyTransform(inputObject[key], (k) => `${key}.${k}`),
                    };
                    delete inputObject[key];
                }
            });
            if (didTransform === false) {
                break;
            }
        }
    }

    Object.keys(inputObject).forEach((key) => {
        // list + object
        if (typeof inputObject[key] === 'object' && inputObject[key] !== null) {
            return;
        }
        flatObject[prefix ? `${prefix}.${key}` : key] = inputObject[key];
    });

    let listOfLists = [];
    Object.keys(inputObject).forEach((key) => {
        if (typeof inputObject[key] !== 'object') {
            return;
        }
        // explosion
        if (Array.isArray(inputObject[key])) {
            // grab each element individually
            const allElements = inputObject[key].reduce(
                (acc, e) => [...acc, ...flattenObject(e, prefix ? `${prefix}.${key}` : key)],
                []
            );
            listOfLists = [
                ...listOfLists,
                ...allElements.map((e) => {
                    return { ...flatObject, ...e };
                }),
            ];
        }
    });
    if (listOfLists.length === 0) {
        listOfLists.push(flatObject);
    }
    return listOfLists;
}

/**
 * Generate xlsx file and initiate download
 * @param {array} data array of objects to convert to xlsx rows
 */
export const exportToXlsx = (data, name, year) => {
    if (!Array.isArray(data)) {
        data = [data];
    }
    let flattedData = data.reduce((acc, e) => [...acc, ...flattenObject(e)], []);
    // magic to remove data. prefix
    flattedData = flattedData.map((line) =>
        mapObjectWithKeyTransform(line, (k) => k.replaceAll('data.', ''))
    );

    if (flattedData?.length === 0) {
        throw new Error('Empty charts cannot be exported');
    }

    const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    let ws = {};
    ws = utils.aoa_to_sheet(generateXlsxData(flattedData));
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'SheetJS');
    const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' });

    const file = new Blob([excelBuffer], { type: fileType });
    const fileName = year ? `${name}_${year}${fileExtension}` : `${name}${fileExtension}`;
    saveAs(file, fileName);
};
